import {Component} from '@angular/core';
import {OffersTemplateComponent} from '../offers-template.component';
import {Group} from '../../../../../models/offer/group';

@Component({
    selector: 'app-offers-template2',
    templateUrl: './offers-template2.component.html',
    styleUrls: ['./offers-template2.component.scss']
})
export class OffersTemplate2Component implements OffersTemplateComponent {

    group: Group;

    getBackgroundImage(): string {
        return this.group.image;
    }

    getClass(): string {
        if (this.group.getOffers().length < 4) {
            return 'col-md-4';
        } else {
            return 'col-md-6 col-xl-3 mb-5 mb-xl-0';
        }
    }
}
