import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Album} from '../../../../models/profile/album';

@Component({
    selector: 'app-upload-photo-create',
    templateUrl: './upload-photo-create.component.html',
    styleUrls: ['./upload-photo-create.component.scss']
})
export class UploadPhotoCreateComponent {

    @Input() data: any;
    @Input() albumType: any;
    @Input() album: any;
    @Output() imageChangedEvent = new EventEmitter<number>();

    constructor() {}

    fileChangeEvent(event: any): void {
        if (event.target.files && event.target.files[0]) {
            this.imageChangedEvent.emit(event);
        } else {
            console.error('error with fileChangeEvent', event);
        }
    }

    isCover() {
        return (this.album && this.album.name)
            ? this.album.isDefaultCover()
            : (this.albumType == Album.DEFAULT_COVER);
    }
}
