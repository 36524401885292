<div class="container mb-5 pb-5 upsell-xp upsell-xp-2" id="upsell">
    <div class="row">
        <div class="col-12 text-center">
            <h2 class="my-3 d-none d-lg-block">
                <span class="text-primary text-uppercase"><span>🍆🍆🍆</span> Envie de choper à coup sûr ? <span>🍆🍆🍆</span></span><br>
                <small>Découvrez la méthode Un Lover !<br><span class="bg-primary text-white text-uppercase px-2">Accédez à la méthode qui vous livre tous les secrets ! *</span></small>
            </h2>
            <h2 class="my-3 d-block d-lg-none">
                <span class="text-primary text-uppercase"><span>🍆</span> Envie de choper ? <span>🍆</span></span><br>
                <small>Découvrez la méthode Un Lover !<br><span class="bg-primary text-white text-uppercase px-2">Accédez à tous les secrets ! *</span></small>
            </h2>
        </div>
        <div class="col mx-auto text-center">
            <ng-content></ng-content>

            <br><br>

            <div class="row mt-4 mb-5">
                <div class="col-sm-6 col-md-3 text-center mb-4 mb-md-0">
                    <h6 class="font-weight-bold"><i class="fas fa-gift text-primary" aria-hidden="true"></i><br>
                        100% GRATUIT</h6>
                    <p class="mb-0">
                        L'offre que nous te proposons est gratuite pendant 24 heures. Tu peux consulter et télécharger tout ce dont tu as besoin dans la méthode sans aucune limitation pour être certain de choper.</p>
                </div>
                <div class="col-sm-6 col-md-3 text-center mb-4 mb-md-0">
                    <h6 class="font-weight-bold"><i class="fas fa-star text-primary" aria-hidden="true"></i><br>
                        MÉTHODE EFFICACE</h6>
                    <p class="mb-0">
                        La méthode de séduction que nous te proposons est absolument incroyable. Elle livre des secrets encore jamais révélés pour mettre à coup sûr, n'importe quelle  femme dans ton lit.</p>
                </div>
                <div class="col-sm-6 col-md-3 text-center mb-4 mb-sm-0">
                    <h6 class="font-weight-bold"><i class="fas fa-thumbs-up text-primary" aria-hidden="true"></i><br>
                        ACCÈS ANONYME</h6>
                    <p class="mb-0">
                        L'accès que nous te proposons d'obtenir est sécurisé, anonyme et confidentiel. Personne ne peut savoir ce que tu consultes. Le seul risque que tu prends, c'est d'être débordé par tes conquêtes.</p>
                </div>
                <div class="col-sm-6 col-md-3 text-center">
                    <h6 class="font-weight-bold"><i class="fas fa-smile text-primary" aria-hidden="true"></i><br>
                        SANS ENGAGEMENT</h6>
                    <p class="mb-0">
                        Si la méthode que nous te proposons de découvrir ne te convient pas, pour quelque raison que ce soit, tu peux la quitter et cesser de l'utiliser car tu ne t'engages absolument à rien.</p>
                </div>
            </div>

            <br><br><br><br>

            <div class="alert alert-light mt-5" *ngIf="showTerms">
                <p class="text-justify text-muted mb-0">L'éditeur du service {{ getSiteName() }} vous propose de découvrir la méthode UN LOVER gratuitement pendant 24 heures. Pour votre confort, si la méthode vous intéresse et que vous souhaitez poursuivre son utilisation, vous n'avez rien à faire. Votre accès sera reconduit tous les 14 jours au prix de 26 euros. Si au contraire la méthode ne vous donne pas satisfaction ou qu'elle ne vous intéresse plus, vous pouvez suspendre son utilisation ainsi que votre accès personnel. Pour cela, quitter le site n'est pas suffisant. Vous devez suivre la procédure indiquée dans l'email qui vous est envoyé avec votre nom d'utilisateur et votre mot de passe juste après avoir confirmé votre volonté de bénéficier d'un accès gratuit. {{ getSiteName() }} précise qu'il s'agit d'un service externe. En cas de problème ou de question contactez le service client de UN LOVER.</p>
            </div>
        </div>
    </div>
</div>
