<a (click)="favorite()" [matTooltip]="'contact.add_to_favorite' | translate" class="btn btn-light btn-sm d-inline-block btn-fonction mr-1">
    <i class="fas fa-star" [class.text-warning]="profile.relationship.favorite"></i>
</a>
<a (click)="kiss()" [matTooltip]="(profile.relationship.kissed ? 'contact.kiss_already_sent' : 'contact.send_kiss') | translate" [class.active]="profile.relationship.kissed" class="btn btn-light btn-sm d-inline-block btn-fonction mr-1">
    <i [class.text-muted]="profile.relationship.kissed" class="fas fa-kiss-wink-heart"></i>
</a>

<a *ngIf="!profile.relationship.addedToFriend()" (click)="friend()" [matTooltip]="'contact.add_friend' | translate" class="btn btn-light btn-sm d-inline-block btn-fonction mr-1">
    <i class="fas fa-user-plus"></i>
</a>
<a *ngIf="profile.relationship.addedToFriend()" (click)="friend()" [matTooltip]="'contact.remove_from_friends' | translate" class="btn btn-light btn-sm d-inline-block btn-fonction mr-1">
    <i class="fas fa-user-minus"></i>
</a>

<a (click)="report()" [matTooltip]="'contact.report_profile' | translate" class="btn btn-light text-danger btn-sm d-inline-block btn-fonction mr-1">
    <i class="fas fa-flag"></i>
</a>
<a (click)="block()" [matTooltip]="(profile.relationship.blocked ? 'unblock' : 'block') | translate" class="btn btn-light text-danger btn-sm d-inline-block btn-fonction">
    <i class="fas fa-ban"></i>
</a>
