import {Component, Input} from '@angular/core';
import {Profile} from '../../../../models/profile/profile';
import {ActionService} from '../../../../services/profile/action/action.service';
import {ModalService} from '../../../../services/modal/modal.service';

@Component({
    selector: 'app-privacy-information',
    templateUrl: './privacy-information.component.html'
})
export class PrivacyInformationComponent {

    @Input() profile: Profile;
    @Input() category: string;

    constructor(
        protected actionService: ActionService,
        protected modal: ModalService
    ) {}

    friend() {
        const isFriend = this.profile.getRelationship().addedToFriend();
        const method = isFriend ? 'removeFriend' : 'addFriend';
        const text = isFriend ? 'unfriend' : 'friend';

        this.openModal('contact.header.friends', `contact.modal_content_${text}`, true, 'fa-user-plus')
            .afterClosed().subscribe(result => {
            if (result) {
                this.profile.relationship.friend = isFriend ? null : 0;
                this.actionService[method](this.profile.getId()).subscribe(() => {
                    this.openModal('contact.header.friends', `contact.modal_content_${text}ed`, null, 'fa-user-plus');
                });
            }
        });
    }

    private openModal(title, question, needAnswer = null, faClass = null) {
        const responses = needAnswer ? [{
            text: 'yes', result: true
        }, {
            text: 'no', result: false
        }] : [{text: 'ok'}];

        return this.modal.openConfirmation({
            data: {
                title,
                question,
                responses,
                faClass,
                profile: this.profile
            }
        });
    }
}
