<div *ngIf="camModel" class="modal-content">
    <div class="modal-header">
        <h5 class="modal-titre text-truncate"><i class="fas fa-info-circle"></i> {{ 'information' | translate }} {{ camModel.getNickname() }}</h5>
        <button [mat-dialog-close]="true" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6 mb-4 mb-sm-0">
                <img class="img-fluid w-100" [src]="camModel.getDetailPhotos()[currentPhotoIndex]">
                <div class="d-none d-sm-block">
                    <div class="row mt-2">
                        <ng-container *ngFor="let photo of camModel.getDetailPhotos(); let i = index">
                            <div *ngIf="i !== currentPhotoIndex" (click)="currentPhotoIndex = i" class="col-sm-4 cursor-pointer">
                                <img class="img-fluid" [src]="photo"/>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 limited-height-sm">
                <h2 class="bg-light p-1"><i class="fas fa-user mr-2 text-primary"></i>{{ 'menu.profile' | translate }}</h2>
                <div class="row mt-2 mb-4">
                    <div class="col-6 font-weight-bold">
                        <span class="text-capitalize">{{ 'profile_questions.ideal_age' | translate }} :</span><br>
                        <span class="text-capitalize">{{ 'astrological_sign' | translate }} :</span><br>
                        <span class="text-capitalize">{{ 'profile_questions.ethnic_origins' | translate }} :</span><br>
                        <ng-container *ngIf="camModel.country"><span class="text-capitalize">{{ 'country' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.region"><span class="text-capitalize">{{ 'region' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.language"><span class="text-capitalize">{{ 'profile_questions.spoken_languages' | translate }} :</span><br></ng-container>
                        <span class="text-capitalize">{{ 'new_model' | translate }} :</span><br>
                        <span class="text-capitalize">{{ 'model_of_the_month' | translate }} :</span><br>
                    </div>
                    <div class="col-6">
                        {{ camModel.getNickname() }}<br>
                        {{ camModel.getAge() }}<br>
                        {{ getZodiacSign() }}<br>
                        {{ getOrigin() | translate }}<br>
                        <ng-container *ngIf="camModel.country">{{ getCountry() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.region">{{ camModel.region }}<br></ng-container>
                        <ng-container *ngIf="camModel.language">{{ getTranslatedLanguages() }}<br></ng-container>
                        {{ (camModel.new === 'Y' ? 'yes' : 'no') | translate }}<br>
                        {{ (camModel.modelOfTheMonth === 'Y' ? 'yes' : 'no') | translate }}<br>
                    </div>
                </div>

                <h2 *ngIf="getPresentations() && (getPresentations().description || getPresentations().online)" class="bg-light p-1">
                    <i class="fas fa-file-alt mr-2 text-primary"></i>{{ 'presentation' | translate }}
                </h2>
                <div *ngIf="getPresentations() && (getPresentations().description || getPresentations().online)" class="row mt-2 mb-4">
                    <div class="col-12">
                        <p *ngIf="getPresentations().description" id="description">{{ getPresentations().description }}</p>
                        <p *ngIf="getPresentations().online" class="mt-2 mb-0">{{ 'availability' | translate }} : {{ getPresentations().online }}</p>
                    </div>
                </div>

                <h2 class="bg-light p-1"><i class="fas fa-address-card mr-2 text-primary"></i>{{ 'characteristics' | translate }}</h2>
                <div class="row mt-2 mb-4">
                    <div class="col-6 font-weight-bold">
                        <ng-container *ngIf="camModel.figure && getFigure()"><span class="text-capitalize">{{ 'profile_questions.silhouette' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.cup"><span class="text-capitalize">{{ 'cup_size' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.eyesColor && getEyes()"><span class="text-capitalize">{{ 'profile_questions.eye_color' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.hair && getHair()"><span class="text-capitalize">{{ 'profile_questions.hair_color' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.hairType && getHaircut()"><span class="text-capitalize">{{ 'profile_questions.haircut' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.piercing"><span class="text-capitalize">{{ 'profile_answers.piercing' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.tattoo"><span class="text-capitalize">{{ 'profile_answers.tattoo' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.pregnant"><span class="text-capitalize">{{ 'pregnant' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.sexpref && getSexuality()"><span class="text-capitalize">{{ 'profile_questions.sexuality' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.stockings"><span class="text-capitalize">{{ 'stockings' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.cockDescription !== 'not_applicable'"><span class="text-capitalize">{{ 'penis_description' | translate }} :</span><br></ng-container>
                        <ng-container *ngIf="camModel.cockSize !== 'not_applicable'"><span class="text-capitalize">{{ 'penis_size' | translate }} :</span><br></ng-container>
                    </div>
                    <div class="col-6">
                        <ng-container *ngIf="camModel.figure && getFigure()">{{ getFigure() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.cup">{{ camModel.cup }}<br></ng-container>
                        <ng-container *ngIf="camModel.eyesColor && getEyes()">{{ getEyes() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.hair && getHair()">{{ getHair() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.hairType && getHaircut()">{{ getHaircut() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.piercing">{{ (camModel.hasPiercing() ? 'yes' : 'no') | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.tattoo">{{ (camModel.hasTattoo() ? 'yes' : 'no') | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.pregnant">{{ (camModel.pregnant === 'Y' ? 'yes' : 'no') | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.sexpref && getSexuality()">{{ getSexuality() | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.stockings">{{ (camModel.stockings === 'Y' ? 'yes' : 'no') | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.cockDescription !== 'not_applicable'">{{ (camModel.cockDescription + '_penis') | translate }}<br></ng-container>
                        <ng-container *ngIf="camModel.cockSize !== 'not_applicable'">{{ camModel.cockSize }}<br></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
