<app-loader></app-loader>
<ng-template #templateHost></ng-template>
<app-payment-page
    *ngIf="canLoadPaymentPage()"
    [template]="getPaymentPageTemplate()"
    [offer]="getPaymentOffer()"
    [groupOffer]="getGroupOffer()"
    [groupTemplateId]="getGroupTemplateId()"
    [displayRedirect]="false">
</app-payment-page>
