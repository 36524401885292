import {Component, Input} from '@angular/core';
import {ChatService} from '../../../services/chat/chat.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {Room} from '../../../models/chat/room/room';
import {TranslateService} from '@ngx-translate/core';
import {CurrentUserService} from '../../../services/user/current-user.service';

@Component({
    selector: 'app-chat-rooms-list',
    templateUrl: './chat-rooms-list.component.html',
    styleUrls: ['./chat-rooms-list.component.scss']
})
export class ChatRoomsListComponent {

    @Input() isMobile = false;
    @Input() limit: number = null;

    constructor(
        public chatService: ChatService,
        public chatRoomService: ChatRoomService,
        public chatUserService: ChatUserService,
        private currentUserService: CurrentUserService,
        private translate: TranslateService
    ) {
    }

    getRoomDate(room: Room) {
        if (!room.getLastMessage()) {
            return '';
        }

        const roomDate = room.getLastMessageTime();
        const d = new Date();
        d.setHours(0, 0, 0, 0);

        if (roomDate >= d) {
            return roomDate.getHours().toString().padStart(2, '0')
                + ':'
                + roomDate.getMinutes().toString().padStart(2, '0');
        }

        d.setDate(d.getDate() - 1);

        if (roomDate >= d) {
            return this.translate.instant('chat.yesterday');
        }

        d.setDate(d.getDate() - 1);
        if (roomDate >= d) {
            return this.translate.instant('chat.before_yesterday');
        }

        d.setDate(d.getDate() - 4);
        if (roomDate >= d) {
            switch (roomDate.getDay()) {
                case 0:
                    return this.translate.instant('chat.sunday');
                case 1:
                    return this.translate.instant('chat.monday');
                case 2:
                    return this.translate.instant('chat.tuesday');
                case 3:
                    return this.translate.instant('chat.wednesday');
                case 4:
                    return this.translate.instant('chat.thursday');
                case 5:
                    return this.translate.instant('chat.friday');
                case 6:
                    return this.translate.instant('chat.saturday');
            }
        }

        return roomDate.getDate().toString().padStart(2, '0')
            + '/'
            + (roomDate.getMonth() + 1).toString().padStart(2, '0')
            + '/'
            + roomDate.getFullYear().toString().padStart(2, '0');
    }

    isCurrentRoom(room: Room) {
        if (this.chatRoomService.getCurrentRoom()) {
            return this.chatRoomService.getCurrentRoom().getId() === room.getId();
        }

        return false;
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }
}
