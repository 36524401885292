import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';

@Component({
    selector: 'app-support-faq-component',
    templateUrl: './support-faq.component.html',
    styleUrls: ['./support-faq.component.scss']
})
export class SupportFaqComponent implements OnInit {

    faq: any[] = [];

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private site: SiteService
    ) {}

    ngOnInit() {
        this.fetchFaq();
        this.translate.onLangChange.subscribe(() => {
            this.fetchFaq();
        });
    }

    fetchFaq() {
        this.http.get(`${environment.backendUrl}/support/faq?lang=${this.translate.currentLang}`).subscribe((result: any) => {
            if (result.success && result.data) {
                this.faq = result.data;
            }
        });
    }

    getDefaultPhoneNumber() {
        for (const phoneSupport of this.site.getSite().getPhoneSupport()) {
            if (phoneSupport.default) {
                return {
                    openingHour: this.convertHour(phoneSupport.openingHour),
                    closingHour: this.convertHour(phoneSupport.closingHour)
                };
            }
        }

        return {openingHour: this.convertHour(9), closingHour: this.convertHour(17)};
    }

    private convertHour(hour) {
        if (this.translate.currentLang === 'en') {
            if (hour.includes('h') || hour.includes(':')) {
                const hours = hour.split(hour.includes('h') ? 'h' : ':');
                if (+hours[0] > 12) {
                    return (hours[0] - 12) + ':' + hours[1] + ' p.m.';
                } else {
                    return hours[0] + ':' + hours[1] + ' a.m.';
                }
            } else if (+hour > 12) {
                return (hour - 12) + ' p.m.';
            } else {
                return hour + ' a.m.';
            }
        } else if (hour.includes(':')) {
            return hour.replace(':', 'h');
        } else {
            return hour.includes('h') ? hour : hour + 'h';
        }
    }
}
