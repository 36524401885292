import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Answer implements Deserializable {
    id: number;
    name: string;
    isExclusive: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    exclusive(): boolean {
        return this.isExclusive;
    }
}
