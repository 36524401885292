import {Injectable} from '@angular/core';
import {PendingActionService} from '../pending-action/pending-action.service';
import {PendingAction} from '../../models/pending-action/pending-action';
import {ActionService} from '../profile/action/action.service';
import {ChatMessageService} from '../chat/chat-message/chat-message.service';
import {Room} from '../../models/chat/room/room';
import {User} from '../../models/chat/user/user';
import {CurrentUserService} from '../user/current-user.service';
import {CoinService} from '../coin/coin.service';

@Injectable({
    providedIn: 'root'
})
export class PendingActionResolverService {

    private resolving = false;
    private pendingActions: PendingAction[] = [];
    private cursor = 0;

    constructor(
        private service: PendingActionService,
        private actionService: ActionService,
        private chatMessageService: ChatMessageService,
        private currentUserService: CurrentUserService,
        private coinService: CoinService
    ) {}

    resolve() {
        if (this.resolving) {
            return;
        }
        this.resolving = true;

        /* @feature "pending actions" disabled
        this.service.getCollection().subscribe((response: any) => {
            if (Array.isArray(response.data) && response.data.length) {
                const pendingActions: PendingAction[] = [];
                for (const data of response.data) {
                    pendingActions.push(new PendingAction().deserialize(data));
                }
                this.cursor = 0;
                this.pendingActions = pendingActions;

                this.processPendingAction();
            } else {
                this.resolving = false;
            }
        });
        */
    }

    private nextProcess() {
        ++this.cursor;
        if (this.pendingActions.length > this.cursor) {
            setTimeout(() => {this.processPendingAction()}, 500);
        } else {
            this.resolving = false;
        }
    }

    private processPendingAction() {
        const pendingAction = this.pendingActions[this.cursor];

        if (!pendingAction) {
            this.resolving = false;
            return;
        }

        switch (pendingAction.type) {
            case PendingAction.MESSAGE_TYPE:
                if (!this.coinService.getMessageOffer() ||
                    !this.coinService.getMessageOffer().isAffordable(this.currentUserService.getAccount().getCoins())) {
                    this.resolving = false;
                    break;
                }

                const user = new User().deserialize({id: pendingAction.getChatUserId()});
                const room = new Room().setFriendUser(user);

                this.service.delete(pendingAction).subscribe(() => {
                    this.chatMessageService.sendMessage(room, pendingAction.getMessage());
                    this.nextProcess();
                });
                break;
            case PendingAction.KISS_TYPE:
                if (!this.coinService.getKissOffer() ||
                    !this.coinService.getKissOffer().isAffordable(this.currentUserService.getAccount().getCoins())) {
                    this.resolving = false;
                    break;
                }

                this.actionService.addKiss(pendingAction.getToProfile().getId()).subscribe((kiss: any) => {
                    if (kiss && kiss && kiss.data.profileId) {
                        this.service.delete(pendingAction).subscribe(() => {
                            this.nextProcess();
                        });
                    }
                });
                break;
        }
    }
}
