<div class="container cover-pdp" [ngStyle]="{'background-image': 'url(' + getBackgroundImage() + ')'}">
</div>

<div class="container pdp-new pdp-new-2 pdp-new-3">
    <div class="row">

        <div class="col-12">
            <h1 class="text-white">
                {{ 'payment.become_vip' | translate }}
            </h1>

            <div class="blocked mb-4">
                <span class="mr-2 ico-blocked"><i class="fas fa-lock"></i></span>
                <span class="d-none d-md-inline-block text-white" [innerHTML]="group.description | nl2br"></span>
                <span class="d-inline-block d-md-none text-white" [innerHTML]="group.descriptionMobile | nl2br"></span>
            </div>
        </div>

        <div [ngClass]="getClass()" *ngFor="let offer of group.getOffers(); let index = index">
            <a [routerLink]="['/payment/offer/', offer.id]" [queryParams]="{tpl: 2}"
               [queryParamsHandling]="'merge'" class="offer-link d-block h-100">
                <div class="card border-0 shadow-sm offer h-100">
                    <div class="card-header bg-dark border-0">
                        <i *ngIf="offer.icon"
                           class="mr-1"
                           [ngClass]="offer.icon"></i>
                        <span [innerHTML]="offer.name | formatHtml"></span>
                    </div>
                    <div class="card-body text-center">
                        <div class="duration text-primary" [class.hidden]="!offer.formula"
                             [innerHTML]="offer.formula | formatHtml"></div>
                        <div class="price" [class.hidden]="!offer.price" [innerHTML]="offer.price | formatHtml">
                        </div>
                        <div class="discount text-primary" [class.hidden]="!offer.discount"
                             [innerHTML]="offer.discount | formatHtml"></div>
                        <span
                            class="btn-primary btn btn-sm rounded-pill text-uppercase mt-3">{{ 'payment.select' | translate }}
                            <i
                                class="fas fa-angle-right"></i></span><br>
                        <ul class="offer-list p-0 mt-4">
                            <li *ngFor="let benefit of offer.getBenefitsList()">
                                <i class="fas fa-check text-success mr-2"></i> <span
                                [innerHTML]="benefit | formatHtml"></span></li>
                        </ul>
                    </div>
                </div>
            </a>
        </div>

        <div class="col-12">
            <hr class="my-5">
            <h2>{{ 'payment.become_vip_content.title' | translate }}</h2>
            <div class="row mt-3">
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-comment text-primary ico-pdp"></i> {{ 'payment.become_vip_content.unlimited_chat' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-map-marker-alt text-primary ico-pdp"></i> {{ 'payment.become_vip_content.geolocation' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i class="fas fa-eye text-primary ico-pdp"></i>
                    {{ 'payment.become_vip_content.search_appearance' | translate }}</h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-user text-primary ico-pdp"></i> {{ 'payment.become_vip_content.all_profile_access' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-search text-primary ico-pdp"></i> {{ 'payment.become_vip_content.affinity_search' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-smile text-primary ico-pdp"></i> {{ 'payment.become_vip_content.no_ads' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-images text-primary ico-pdp"></i> {{ 'payment.become_vip_content.all_photo_access' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-book text-primary ico-pdp"></i> {{ 'payment.become_vip_content.ebooks' | translate }}
                </h6></div>
                <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                    class="fas fa-headset text-primary ico-pdp"></i> {{ 'payment.become_vip_content.premium_customer_service' | translate }}
                </h6></div>
            </div>
        </div>
    </div>
</div>
