<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center mb-0">
        <h1 class="mb-0"><i class="far fa-check-circle text-success" aria-hidden="true"></i>
            Bravo ! Votre profil est certifié et vérifié !<br>
            Pour vous remercier, nous avons une offre à vous proposer.<br>
        </h1>
    </div>
</div>

<app-live-layout id="upsell" class="upsell-xp">
    <div class="card border-0 rounded shadow-lg my-5">
        <i class="far fa-arrow-alt-circle-down text-primary fleche-upsell" aria-hidden="true"></i>
        <div class="card-body px-4 pb-4 px-md-5 pb-md-5 pt-4">
            <h3 class="mb-0"><b class="text-primary">15% de réduction immédiate</b> pour vous 🔥</h3>
            <div class="row mt-4">
                <div class="col-lg-6 d-none d-lg-block">
                    <div class="position-relative big-cam">
                        <img src="assets/img/live/camgirl06.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl06.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <span class="badge badge-danger font-weight-light text-uppercase">aujourd'hui seulement</span>
                    <div class="card border-0 shadow-sm rounded-left h-100">
                        <div class="card-body">
                            <div class="offer">
                                <div class="number-offer bg-dark rounded-left"><i class="fas fa-thumbs-up mr-1"
                                                                                  aria-hidden="true"></i> OFFRE
                                    INCROYABLE
                                </div>
                                <div class="price mt-4">575 LOVE COINS</div>
                                <div class="duration mt-2 d-flex justify-content-center align-items-center col">
                                    <div>
                                        <del class="text-muted">57.50€</del>
                                    </div>
                                    <div class="mx-2"><b><i class="fas fa-angle-double-right"
                                                            aria-hidden="true"></i></b></div>
                                    <div><b class="text-success">50€</b></div>
                                </div>
                                <div class="reduc bg-primary mt-2 px-2 py-1">= 15% de réduction immédiate <span
                                    class="d-none d-sm-inline-block">pour vous</span><br>
                                    = 15% de temps en plus avec des filles super chaudes !
                                </div>
                                <div class="discount">-15%</div>
                                <a
                                    class="btn btn-lg rounded-pill py-3 btn-success btn-block shadow-sm text-uppercase font-weight-bold mt-3"
                                    (click)="accept()"><i
                                    class="fas fa-hand-point-right mr-2" aria-hidden="true"></i> J'en
                                    profite !</a>
                            </div>
                        </div>
                    </div>
                </div>


                <a class="text-center mt-5 h6 d-block w-100 text-center no" (click)="refuse()">
                    Non merci, pas maintenant...<br>
                    Je veux retourner sur le service de rencontres.<br>
                </a>
            </div>
        </div>
    </div>
</app-live-layout>
