import {Component, Input} from '@angular/core';
import {Message} from '../../../models/chat/message/message';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {TranslateService} from '@ngx-translate/core';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {PendingActionService} from '../../../services/pending-action/pending-action.service';
import {PendingAction} from '../../../models/pending-action/pending-action';

@Component({
    selector: 'app-room-messages',
    templateUrl: './room-messages.component.html',
    styleUrls: ['./room-messages.component.scss']
})
export class RoomMessagesComponent {

    @Input() isMobile = false;
    private messageDividerDate;

    constructor(
        public chatUserService: ChatUserService,
        private chatRoomService: ChatRoomService,
        private currentUserService: CurrentUserService,
        private translate: TranslateService,
        private pendingActionService: PendingActionService
    ) {}

    getPendingActions(): PendingAction[] {
        return this.pendingActionService.pendingActions;
    }

    isMyMessage(message: Message): boolean {
        return message.getUser() ? this.currentUserService.isCurrentUser(message.getUser().getUid()) : false;
    }

    hasDividerDate(message: Message) {
        const isSameDay = (
            this.messageDividerDate
            && this.messageDividerDate.getDate() === message.getTime().getDate()
            && this.messageDividerDate.getMonth() === message.getTime().getMonth()
            && this.messageDividerDate.getFullYear() === message.getTime().getFullYear()
        );

        this.messageDividerDate = message.getTime();

        return !isSameDay;
    }

    getDividerDate(message: Message) {
        const day = message.getTime().getDate();
        const month = message.getTime().getMonth() + 1;
        const year = message.getTime().getFullYear();

        const translatedMonth = this.translate.instant('month_list.' + month);

        return day + ' ' + translatedMonth + ' ' + year;
    }

    getMessages(): Message[] {
        let messages = [];

        if (this.chatRoomService.getCurrentRoom()) {
            messages = this.chatRoomService.getCurrentRoom().getMessages();
        }

        return messages;
    }

    // Simplest way to show the user his current requested nickname without messing with the server.
    getUsername(message: Message) {
        return this.isMyMessage(message) ?
            this.currentUserService.getAccount().getProfile().getNickname() :
            message.getUser().getUsername();
    }

    getCity(message: Message) {
        return this.isMyMessage(message) ?
            this.currentUserService.getAccount().getProfile().getCity() :
            message.getUser().getCity();
    }

    hasRule(rule: string, message: Message): boolean {
        return this.currentUserService.hasRule(rule, message.getUser().getUid());
    }
}
