import {Component, Inject, Optional} from '@angular/core';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Site} from '../../../models/site/site';
import {SiteService} from '../../../services/site/site.service';

@Component({
    selector: 'app-full-screen-confirmation',
    templateUrl: './full-screen-confirmation.component.html',
    styleUrls: ['./full-screen-confirmation.component.scss']
})
export class FullScreenConfirmationComponent extends ConfirmationComponent {

    waitingAnimation: boolean;
    strongQuestion: boolean;
    validationCallback: any;
    asyncValidationCallback: any;

    constructor(
        public dialogRef: MatDialogRef<FullScreenConfirmationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private siteService: SiteService
    ) {
        super(dialogRef, data);

        this.strongQuestion = data.strongQuestion || false;
        this.validationCallback = data.validationCallback || null;
        this.asyncValidationCallback = data.asyncValidationCallback || null;
        this.waitingAnimation = data.waitingAnimation || false;
    }

    get site(): Site {
        return this.siteService.getSite();
    }

    async validation(response) {
        if (this.validationCallback && typeof this.validationCallback === 'function') {

            if (this.validationCallback(response)) {
                this.confirm(response);
                return true;
            }

            return false;
        } else if (this.asyncValidationCallback && typeof this.asyncValidationCallback === 'function') {
            const success = await this.asyncValidationCallback(response);

            if (success) {
                this.confirm(response);
                return true;
            }

            return false;
        }

        return true;
    }
}
