import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Album} from '../../../models/profile/album';
import {SiteService} from '../../../services/site/site.service';
import {Site} from '../../../models/site/site';
import {Profile} from '../../../models/profile/profile';

@Component({
    selector: 'app-album-modal',
    templateUrl: './album.component.html',
    styleUrls: ['./album.component.scss']
})
export class AlbumModalComponent {

    title: string;
    site: Site;
    album: Album;
    profile: Profile;
    update: boolean;

    constructor(
        private siteService: SiteService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.album = data.album ? data.album : new Album();
        this.profile = data.profile ? data.profile : null;
        this.update = data.update ? data.update : false;
        this.site = siteService.getSite();
    }
}
