import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class CoinOffer implements Deserializable {

    static readonly BOOSTER_TYPE = 'booster';
    static readonly MESSAGE_TYPE = 'message';
    static readonly KISS_TYPE = 'kiss';
    static readonly LIVE_TYPE = 'live';

    id: number;
    price: number;
    name: string;
    options = {};
    type: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getId(): number {
        return this.id;
    }

    isAffordable(currentCoins): boolean {
        return currentCoins >= this.price;
    }

    getPrice(): number {
        return this.price;
    }

    getType(): string {
        return this.type;
    }

    getName(): string {
        return this.name;
    }

    getOption(key: string): any {
        return key in this.options ? this.options[key] : null;
    }

    getOptions(): any {
        return this.options;
    }

    isBoosterType(): boolean {
        return this.type === CoinOffer.BOOSTER_TYPE;
    }

    isMessageType(): boolean {
        return this.type === CoinOffer.MESSAGE_TYPE;
    }

    isKissType(): boolean {
        return this.type === CoinOffer.KISS_TYPE;
    }

    isLiveType(): boolean {
        return this.type === CoinOffer.LIVE_TYPE;
    }

    getMessageCount(): number {
        return this.getOption('messageCount') || 1;
    }
}
