<li class="d-block d-xl-none mt-2" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a (click)="goTo('/home')" class="nav-link">
        <i class="fas fa-home"></i>
        <span>{{ 'menu.home' | translate }}</span>
    </a>
</li>
<li class="d-block d-xl-none" id="liProfileMobile" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a (click)="goTo('/profile')" class="nav-link">
        <i class="fas fa-user"></i>
        <span>{{ 'menu.my_profile' | translate }}</span>
    </a>
</li>
<li class="d-block d-xl-none" id="liTchatMobile" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a (click)="goToChat()" class="nav-link">
        <i class="fas fa-comment"></i>
        <span>{{ 'menu.chat' | translate }}</span>
    </a>
</li>
<li class="d-block d-xl-none" id="liContactsMobile" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a [routerLink]="['contacts']" class="nav-link">
        <i class="fas fa-address-book"></i>
        <span>{{ 'menu.my_contacts' | translate }}</span>
    </a>
</li>

<!--<li *ngIf="canShowWebcamMenu()" class="d-block d-lg-none" data-toggle="collapse" data-target="#navbarSupportedContent">-->
<!--    <a [routerLink]="['sex-cams']" class="nav-link">-->
<!--        <i class="far fa-eye"></i>-->
<!--        <span>Sex Cams</span>-->
<!--    </a>-->
<!--</li>-->

<li *ngIf="canShowWebcamMenu()" class="d-block d-lg-none" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a href="https://liveparadise.com/" target="_blank" class="nav-link">
        <i class="far fa-eye"></i>
        <span>Sex Cams</span>
    </a>
</li>

<li *ngIf="hasUpsellUnlover()" class="d-block d-lg-none" id="liVideosMobile" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a (click)="openUpsellXponsorUnlover()" class="nav-link">
        <i class="fas fa-film"></i>
        <span>{{ 'menu.flirting_tips' | translate }}</span>
    </a>
</li>

<li *ngIf="showEbooks()" class="d-block d-xl-none" id="liEbooksMobile" data-toggle="collapse" data-target="#navbarSupportedContent">
    <a [routerLink]="['ebooks']" class="nav-link">
        <i class="fas fa-book"></i>
        <span>{{ 'menu.e_books' | translate }}</span>
    </a>
</li>
