import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PendingAction} from '../../../models/pending-action/pending-action';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Profile} from '../../../models/profile/profile';
import {ModalService} from '../../../services/modal/modal.service';
import {PendingActionService} from '../../../services/pending-action/pending-action.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../../modal/coin-payment/coin-payment.component';

@Component({
    selector: 'app-pending-message',
    templateUrl: './pending-message.component.html',
    styleUrls: ['./pending-message.component.scss']
})
export class PendingMessageComponent {

    @Input() pendingMessage: PendingAction;
    @Input() isMobile: boolean;
    deleting = false;

    constructor(
        private currentUserService: CurrentUserService,
        private pendingActionService: PendingActionService,
        private modalService: ModalService,
        private dialog: MatDialog
    ) {}

    getProfile(): Profile {
        return this.currentUserService.getAccount().getProfile();
    }

    openLoveCoinModal() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'pending_message'}
        });
    }

    delete() {
        if (this.deleting) {
            return;
        }
        this.deleting = true;

        this.pendingActionService.delete(this.pendingMessage).subscribe(() => {
            const index = this.pendingActionService.pendingActions
                .findIndex((data: PendingAction) => data.getId() === this.pendingMessage.getId());

            this.pendingActionService.pendingActions.splice(index, 1);
        });
    }
}
