<div class="col-12 position-relative p-0 cadre-lover" style="overflow:hidden;">
    <img [src]="getImage()" class="img-fluid w-100" alt="">
    <div class="position-absolute text-white h1 texte xponsor-modal-text1">
        {{ 'xponsor.become_a' | translate }}
        <span style="color:#70cbf6">{{ 'xponsor.true_lover' | translate }}</span><br>
        <small><i>{{ 'xponsor.not_hard' | translate }}</i></small>
    </div>
</div>

<h5 class="text-uppercase mb-0 font-weight-bold mt-3 h5">
    {{ 'xponsor.seduction_pro' | translate }}
    <br/>
    <small>
        {{ 'xponsor.enjoy' | translate }}
        <span class="text-success">{{ 'xponsor.trial_period' | translate }}</span>
    </small>
</h5>

<div class="custom-control custom-checkbox my-3">
    <input [(ngModel)]="checked" type="checkbox" class="custom-control-input checkbox" id="conditions">
    <label class="custom-control-label" for="conditions">
        {{ 'adult_agreement' | translate }}
        <a href="https://www.un-lover.com/conditions-utilisation/" target="_blank" class="text-dark">
            {{ 'cgu' | translate }}
        </a>
    </label>
</div>

<small class="alert alert-danger mt-2 ml-2 mr-2" *ngIf="errorMessage" style="width: 100%; text-align: center;">
    {{errorMessage}}
</small>

<button (click)="activateAccess(true)" class="btn btn-success btn-lg btn-block font-weight-bold text-uppercase">
    <i class="fa fa-crown" aria-hidden="true"></i>
    {{ 'xponsor.activate_access' | translate }}
</button>
