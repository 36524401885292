<app-modal>
    <div class="modal-body">
        <div *ngIf="step === 1" class="container">
            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'unsubscribe.why' | translate }}</span> {{ 'unsubscribe.why_reason' | translate }}
            </p>

            <div *ngFor="let reason of reasons" class="custom-control custom-checkbox mb-2">
                <input (click)="switchReason(reason)" [checked]="isChecked(reason)" type="checkbox" class="custom-control-input" [id]="reason" [name]="reason">
                <label class="custom-control-label font-weight-normal" [for]="reason">{{ 'reason_list.' + reason | translate }}</label>
                <textarea *ngIf="reason == 'other_reason_justify'" [(ngModel)]="comment" class="form-control mb-2" style="min-height:90px;"></textarea>
            </div>
        </div>
        <div *ngIf="step === 2" class="container">
            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'unsubscribe.confirmed' | translate }}</span> {{ 'unsubscribe.expiration' | translate }}
            </p>
        </div>
    </div>

    <div *ngIf="step === 1" class="modal-footer">
        <button data-dismiss="modal" [mat-dialog-close]="true" class="btn btn-dark btn-sm mr-2 text-uppercase">{{ 'cancel' | translate }}</button>
        <a (click)="unsubscribe()" data-toggle="modal" data-dismiss="modal" class="btn btn-primary btn-sm text-uppercase">
            {{ 'confirm' | translate }}</a>
    </div>
    <div *ngIf="step === 2" class="modal-footer">
        <a [mat-dialog-close]="true" class="btn btn-primary btn-sm text-uppercase">{{ 'ok' | translate }}</a>
    </div>
</app-modal>
