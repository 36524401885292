import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Rules implements Deserializable {

    requireSubscription = false;
    chatBlur = false;
    photoBlur = false;
    nicknameBlur = false;
    detailBlur = false;
    blockProfileAccess = false;
    footerAlert = false;

    deserialize(data: any): this {
        Object.assign(this, data);

        return this;
    }
}
