<div *ngIf="isDisconnected()" class="alert alert-info notification-confirmation">
    <div class="container-fluid">
        <a class="close" data-dismiss="alert" aria-label="close" title="close">
            <i class="fas fa-times"></i>
        </a>
        <div class="row no-gutters">
            <a href="" data-toggle="modal" class="mr-2">
                <i class="fas fa-exclamation-circle"></i>
                <span (click)="reconnect()" class="picto-margin">{{ 'chat_notif.reconnect' | translate }}</span>
            </a>
            <span *ngIf="isAutoReconnect()">{{ 'chat_notif.reconnect_ongoing' | translate }}</span>
            <span *ngIf="!isAutoReconnect()">{{ 'chat_notif.disconnected' | translate }}</span>
        </div>
    </div>
</div>
