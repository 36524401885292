<div [hidden]="!loaded" class="margin-mobile">
    <iframe #iframe
        id="redirectPdp"
        [src]="paymentUrl | sanitize"
        sandbox="allow-forms allow-scripts allow-popups allow-same-origin allow-modals allow-top-navigation allow-top-navigation-by-user-activation"
        width="100%"
        height="100%"
        data-scrolling="no"
        scrolling="no"
        style="overflow:hidden; display:block;  height: 100%; border: none; margin-top: -120px;"
    ></iframe>
</div>

<div [hidden]="loaded" id="error403">
    <h2 class="well text-center">
        {{ 'secure_payment_redirecting' | translate }}
    </h2>

    <div class="modal-body text-center">
        <div id="spin">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>

        <p class="mt-5">
            {{ 'if_payment_page_broken' | translate }},
            <a [href]="paymentDirectLink | sanitize" target="_blank">
                {{ 'click_here_for_secured_page' | translate }}
            </a>
        </p>
    </div>
</div>
