<div class="mb-2 row d-flex justify-content-between">
    <div class="col-12 breadcrumb-album">
        <a [routerLink]="['/profile' + (personalProfile ? '' : '/' + profile.getId())]">
            <span [class.blurred]="hasRule('nicknameBlur')">{{ personalProfile ? ('menu.my_profile' | translate) : profile.getNickname() }}</span>
        </a> › {{ 'album' | translate }}
    </div>
</div>

<div class="demo-gallery">
    <ul *ngIf="profile" id="lightgallery" class="list-unstyled row">
        <li *ngFor="let album of getAlbums(profile)" class="col-xl-2 col-md-3 col-sm-4 col-6">
            <div class="card card-profile text-center">
                <div *ngIf="!album.isPrivate() || album.getPhotosLength() > 0 || personalProfile" class="profil wrapper">
                    <div class="wrappercontent">
                        <a (click)="selectAlbum(album)" class="itemsprofil">
                            <img class="img-fluid w-100" [src]="album.getFirstPhotoUrl(profile.getGender())">
                            <span class="badge nb-photos"><i class="fas fa-camera" aria-hidden="true"></i> {{ album.getTotalPhoto() }}</span>
                        </a>
                        <div *ngIf="personalProfile && !album.isDefaultProfile() && !album.isDefaultCover()" class="edit-photo text-right">
                            <span [matTooltip]="'albums.modal_album.title_edit' | translate"
                                  (click)="openAlbumModal(album, true)">
                                <i class="fas fa-edit"></i>
                            </span>
                            <span *ngIf="!album.hasProfilePicture(profile.photo)" [matTooltip]="'albums.delete_album_title' | translate" (click)="removeAlbum(album)">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                        </div>
                        <div *ngIf="album.isPrivate()" class="badge badge-warning badge-status">{{ 'albums.lock_private' | translate }}</div>
                    </div>
                </div>

                <div *ngIf="album.isPrivate() && album.getPhotosLength() === 0 && !personalProfile" class="row d-flex justify-content-center align-items-center request no-gutters">
                    <div class="badge badge-warning badge-status">{{ 'albums.lock_private' | translate }}</div>
                    <div class="col-12 text-center">
                        <i class="fas fa-lock text-primary"></i><br>
                        <div (click)="openAccessRequestModal(album)" class="btn btn-sm btn-primary">
                            <span class="titre-desktop">{{ 'albums.access_request' | translate }}</span>
                            <span class="titre-mobile">{{ 'add_more_photo_mobile' | translate }}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="album.isPrivate() && album.getPhotosLength() === 0 && !personalProfile" class="profil">
                    <img class="img-fluid w-100" src="assets/img/250x250/default_user_{{ profile.getGender() }}.png">
                    <span class="badge nb-photos"><i class="fas fa-camera" aria-hidden="true"></i> {{ album.getTotalPhoto() }}</span>
                </div>

                <div *ngIf="!album.isPrivate() || album.getPhotosLength() > 0 || personalProfile" class="card-body">
                    <a (click)="selectAlbum(album)"><h4 class="card-title mb-0 text-truncate">{{ album.getTranslatedName() | translate }}</h4></a>
                </div>
            </div>
        </li>

        <li class="col-xl-2 col-md-3 col-sm-4 col-6">
            <a class="nogal" data-toggle="modal" data-target="#ajouter-album">
                <div class="card card-profile text-center">
                    <div *ngIf="!personalProfile" class="row d-flex justify-content-center align-items-center request more no-gutters">
                        <div class="col-12 text-center">
                            <i class="fas fa-camera text-primary plus-photos"></i><br>
                            <div (click)="openAddMorePhotoModal()" class="btn btn-sm btn-primary">
                                <span class="d-none d-lg-block">{{ 'albums.add_more_photo' | translate }}</span>
                                <span class="d-block d-lg-none">{{ 'albums.add_more_photo_mobile' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="personalProfile" class="row d-flex justify-content-center align-items-center request more no-gutters">
                        <div class="col-12 text-center">
                            <i class="fas fa-image text-primary plus-photos"></i><br>
                            <div (click)="openAlbumModal(null, false)" class="btn btn-sm btn-primary">
                                <span class="d-none d-lg-block">{{ 'albums.add_album' | translate }}</span>
                                <span class="d-block d-lg-none">{{ 'albums.add_photo_mobile' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="profil wrapper">
                        <div class="wrappercontent">
                            <div class="itemsprofil">
                                <img class="img-fluid w-100" alt="" src="assets/img/common/blank.gif">
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>
