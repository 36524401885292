<div [class.input-group]="marker" [class.w-100]="marker" [class.display-from-wall]="displayFromWall">
    <span *ngIf="marker && !displayFromWall" class="input-group-text" style="min-width:42px;">
        <i class="fas fa-map-marker-alt mx-auto"></i>
    </span>

    <input [(ngModel)]="currentCity" (keyup)="cityAutoCompletion(false)" (blur)="checkCity()" [placeholder]="this.marker ? ('on_boarding.your_city' | translate) : ''"
           class="city-input form-control" type="text" name="ville" autocomplete="off"/>
    <button *ngIf="useGeolocation"
            (click)="geolocation()"
            [matTooltip]="'on_boarding.city_geolocation' | translate"
            [matTooltipDisabled]="!geolocTooltip"
            [ngClass]="{'btn-secondary': displayFromWall, 'btn-dark': !displayFromWall}"
            type="button"
            class="btn ml-2 btn-sm btn-geoloc">
        <i class="fas fa-{{ geolocIcon }}"></i> <span *ngIf="!geolocTooltip">{{ 'on_boarding.city_geolocation' | translate }}</span>
    </button>
    <div class="city-autocomplete" *ngIf="currentCity && currentCity.trim().length > 1 && cities.length > 0">
        <ul>
            <li (click)="setCity(city)" *ngFor="let city of cities">
                {{city.getName()}} ({{city.getPostalCode()}}, {{city.getCountry()}})
            </li>
            <li *ngIf="canLoadMoreCities" (click)="cityAutoCompletion(true)">{{ 'on_boarding.more_cities' | translate }}</li>
        </ul>
    </div>

    <div class="d-block w-100" *ngIf="!(currentCity && currentCity.trim().length > 1 && cities.length > 0) && !errorUnpicked && profile">
        <small class="fileHelp form-text text-muted text-left">{{ 'on_boarding.please_enter_your_city_or_postal_code' | translate }}</small>
    </div>
    <div class="city-error d-block w-100">
        <small class="text-left" *ngIf="errorUnpicked">{{ 'on_boarding.city_unpicked' | translate }}</small>
    </div>
    <div class="d-block w-100 mt-2 mb-2" id="spin" *ngIf="canShowLoader()">
        <i class="fa fa-spinner fa-pulse fa-2x"></i>
    </div>
</div>
