<div class="container-fluid cover-upsell px-0">
</div>

<app-virilblue-layout class="upsell-2">
    <i class="far fa-check-circle text-success fleche-upsell" aria-hidden="true"></i>
    <div class="card-body p-4 p-sm-5 text-center">
        <h3 class="mb-0"><span class="text-success">Paiement accepté !</span><br>
            Indiquez à quelle adresse vous souhaitez recevoir votre commande :
        </h3>
        <form (ngSubmit)="submit()" [formGroup]="form" class="mt-3 mt-sm-4">
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-user" aria-hidden="true"></i></span>
                    <input formControlName="lastname" class="form-control form-control-lg" placeholder="Nom"
                           type="text"
                           [ngClass]="{'is-invalid':lastname.invalid && submitted}">
                </div>
                <div *ngIf="lastname.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="lastname.errors?.required">
                        Le nom est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-user" aria-hidden="true"></i></span>
                    <input formControlName="firstname" class="form-control form-control-lg" placeholder="Prénom"
                           type="text" [ngClass]="{'is-invalid':firstname.invalid && submitted}">
                </div>
                <div *ngIf="firstname.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="firstname.errors?.required">
                        Le prénom est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-map-marker-alt"
                                                               aria-hidden="true"></i></span>
                    <input formControlName="address" class="form-control form-control-lg" placeholder="Adresse"
                           type="text" [ngClass]="{'is-invalid':address.invalid && submitted}">
                </div>
                <div *ngIf="address.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="address.errors?.required">
                        L'adresse est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-map-marker-alt"
                                                               aria-hidden="true"></i></span>
                    <input formControlName="postalCode" class="form-control form-control-lg" placeholder="Code postal"
                           type="text" [ngClass]="{'is-invalid':postalCode.invalid && submitted}">
                </div>
                <div *ngIf="postalCode.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="postalCode.errors?.required">
                        Le code postal est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-map-marker-alt"
                                                               aria-hidden="true"></i></span>
                    <input formControlName="city" class="form-control form-control-lg" placeholder="Ville" type="text"
                           [ngClass]="{'is-invalid':city.invalid && submitted}">
                </div>
                <div *ngIf="city.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="city.errors?.required">
                        La ville est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-map-marker-alt"
                                                               aria-hidden="true"></i></span>
                    <select formControlName="country" class="custom-select custom-select-lg form-control"
                            [ngClass]="{'is-invalid':country.invalid && submitted}">
                        <option disabled value="">Pays de livraison</option>
                        <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
                    </select>
                </div>
                <div *ngIf="country.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="country.errors?.required">
                        Le pays est obligatoire.
                    </span>
                </div>
            </div>
            <div class="form-group text-left">
                <div class="input-group">
                    <span class="input-group-text border-0"><i class="fas fa-phone-alt" aria-hidden="true"></i></span>
                    <input formControlName="phone" class="form-control form-control-lg" placeholder="Téléphone *"
                           type="text" [ngClass]="{'is-invalid':phone.invalid && submitted}">
                </div>
                <div *ngIf="phone.invalid && submitted"
                     class="text-danger form-error">
                    <span *ngIf="phone.errors?.required">
                        Le téléphone est obligatoire.
                    </span>
                </div>
                <small class="form-text text-muted">* pour la livraison dans un colis discret, ne sera communiqué à
                    personne</small>
            </div>
            <button
                class="btn btn-success btn-block btn-lg rounded-pill font-weight-bold shadow-sm py-3 text-uppercase">
                <i class="fas fa-hand-point-right mr-2" aria-hidden="true"></i>Confirmer <span
                class="d-none d-sm-inline-block">cette adresse</span>
            </button>
        </form>
    </div>
    <div class="card-footer text-center text-muted"><i class="fas fa-star mr-1" aria-hidden="true"></i><i
        class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i> Satisfait ou
        remboursé <i class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star mr-1" aria-hidden="true"></i><i
            class="fas fa-star" aria-hidden="true"></i></div>
</app-virilblue-layout>
