import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification/notification.service';
import {Notification} from '../../models/notification/notification';
import {CurrentUserService} from '../../services/user/current-user.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    constructor(private notificationService: NotificationService, private currentUserService: CurrentUserService) {}

    ngOnInit() {
        this.notificationService.findAll(true);
    }

    getNotifications(): Notification[] {
        if (this.currentUserService.refreshPage.notification) {
            this.currentUserService.refreshPage.notification = false;
            this.notificationService.findAll(true);
        }
        return this.notificationService.getNotifications();
    }
}
