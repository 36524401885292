import {Injectable} from '@angular/core';
import {SocketService} from '../../socket/socket.service';
import {ChatRoomService} from '../chat-room/chat-room.service';

@Injectable({
    providedIn: 'root'
})
export class ChatTypingService {

    private userTypingTimer;

    private userTyping = false;

    private userTypingRoomId: number;

    constructor(private socketService: SocketService, private chatRoomService: ChatRoomService) {
    }

    onTyping() {
        this.socketService.on('notify_user_typing', (data: any) => {
            const roomId = data.roomId;
            const typing = data.typing;

            const room = this.chatRoomService.getRoomById(roomId);

            if (room) {
                room.setTyping(typing);
                this.chatRoomService.scrollTopMobile();
            }
        });
    }

    startTyping(event) {
        const message = event.target.value;

        if (typeof event === 'undefined' || typeof message === 'undefined') {
            return;
        }

        const messageText = message.toString().trim();
        const typing = messageText ? messageText.length > 0 : false;

        const typingChanged = typing !== this.userTyping;
        const roomChanged = this.chatRoomService.getCurrentRoom().getId() !== this.userTypingRoomId;

        // Nothing change
        if (!typingChanged && !roomChanged) {
            if (typing) {
                this.typingTimeout(this.userTypingRoomId);
            }
            return;
        }

        if (typing) {
            if (roomChanged) {
                // Room changed, notify old room
                this.clearTyping();
            }

            this.socketService.emit('notify_user_typing', {
                typing: true,
                roomId: this.chatRoomService.getCurrentRoom().getId()
            }).then();

            this.userTypingRoomId = this.chatRoomService.getCurrentRoom().getId();
            this.userTyping = true;

            this.typingTimeout(this.userTypingRoomId);
        } else {
            this.clearTyping();

            this.userTypingRoomId = this.chatRoomService.getCurrentRoom().getId();
            this.userTyping = false;
        }
    }

    clearTyping() {
        if (this.userTypingTimer) {
            clearTimeout(this.userTypingTimer);
            this.userTypingTimer = null;
        }

        if (this.userTyping && this.chatRoomService.getCurrentRoom()) {
            this.socketService.emit('notify_user_typing', {
                typing: false,
                roomId: this.chatRoomService.getCurrentRoom().getId()
            }).then();

            this.userTyping = false;
        }
    }

    typingTimeout(roomId) {
        if (this.userTypingTimer) {
            clearTimeout(this.userTypingTimer);
            this.userTypingTimer = null;
        }

        this.userTypingTimer = setTimeout(() => {
            this.socketService.emit('notify_user_typing', {
                typing: false,
                roomId
            }).then();

            this.userTyping = false;

            clearTimeout(this.userTypingTimer);
            this.userTypingTimer = null;
        }, 5000);
    }
}
