import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {User} from '../user/user';
import {Message} from '../message/message';

@Injectable({
    providedIn: 'root'
})
export class Room implements Deserializable {

    private currentProfileId: number;

    private id: number;

    private lastMessage: string;

    private lastMessageTime: Date;

    private lastMessageId: number;

    private lastMessageUserId: number;

    private lastReadAt: string;

    private unreadCount: number;

    private friendUser: User;

    private currentUser: User;

    private typing = false;

    private messages = [];

    private opened = false;

    private enabled = false;

    private loaded = false;

    private infiniteScroll = false;

    private scrollToBottom = false;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.user1.getUid() === this.currentProfileId) {

            this.friendUser = input.user2;

            this.currentUser = input.user1;
        } else if (input.user2.getUid() === this.currentProfileId) {
            this.friendUser = input.user1;

            this.currentUser = input.user2;
        }

        const message = new Message().deserialize({
            user: (this.lastMessageUserId === this.currentUser.getId() ? this.currentUser : this.friendUser),
            room: this,
            messageId: this.lastMessageId,
            message: this.lastMessage,
            time: new Date(this.lastMessageTime)
        });

        this.addMessage(message);

        return this;
    }

    getId(): number {
        return this.id;
    }

    setId(id: number) {
        this.id = id;
    }

    getLastMessage(): string {
        return this.lastMessage;
    }

    setLastMessage(lastMessage: string) {
        this.lastMessage = lastMessage;
    }

    getLastMessageTime(): Date {
        return this.lastMessageTime;
    }

    setLastMessageTime(messageTime: Date) {
        this.lastMessageTime = messageTime;
    }

    getLastMessageId(): number {
        return this.lastMessageId;
    }

    setLastMessageId(messageId: number) {
        this.lastMessageId = messageId;
    }

    getLastMessageUserId(): number {
        return this.lastMessageUserId;
    }

    setLastMessageUserId(lastMessageUserId: number) {
        this.lastMessageUserId = lastMessageUserId;
    }

    getLastReadAt(): string {
        return this.lastReadAt;
    }

    getUnreadCount(): number {
        return this.unreadCount;
    }

    getCurrentUser(): User {
        return this.currentUser;
    }

    setCurrentUser(currentUser: User) {
        this.currentUser = currentUser;
    }

    getFriendUser(): User {
        return this.friendUser;
    }

    setFriendUser(friendUser: User): this {
        this.friendUser = friendUser;

        return this;
    }

    isTyping(): boolean {
        return this.typing;
    }

    setTyping(typing: boolean) {
        this.typing = typing;
    }

    getMessages(): Message[] {
        return this.messages;
    }

    addMessage(message: Message, incrementUnreadCount = false) {
        // si l'id du message n'existe pas dans la liste, alors l'ajouter
        if (!this.messages.find(m => m.getMessageId() === message.getMessageId())) {
            // Ajoute le message
            this.messages.push(message);

            // Tri les messages par date
            this.messages.sort((a, b) => {
                return a.getTime().getTime() - b.getTime().getTime();
            });

            // Les messages envoyés sont exclus des notifications de nouveaux messages
            if ((message.getUser() && message.isNotify())) {
                this.unreadCount++;
            }
            message.setRoom(this);
        }
    }

    sortMessages() {
        this.messages.sort((a, b) => {
            return a.messageId - b.messageId;
        });
    }

    setOpened(opened: boolean) {
        this.opened = opened;
    }

    isEnabled() {
        return this.enabled;
    }

    setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    setInfiniteScroll(infiniteScroll: boolean) {
        this.infiniteScroll = infiniteScroll;
    }

    hasInfiniteScroll(): boolean {
        return this.infiniteScroll;
    }

    resetNewMessagesCount() {
        this.unreadCount = 0;
    }

    deleteMessage(id: number) {
        const messageIndex = this.messages.findIndex((m) => {
            return m && m.messageId === id;
        });

        if (messageIndex !== -1) {
            this.messages.splice(messageIndex, 1);
        }

        this.sortMessages();
    }

    setScrollToBottom(scrollToBottom: boolean) {
        this.scrollToBottom = scrollToBottom;
    }

    isScrollToBottom(): boolean {
        return this.scrollToBottom;
    }
}
