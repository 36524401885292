import {Component, OnInit} from '@angular/core';
import {BillingService} from '../../../../services/billing/billing.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../services/loader/loader.service';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

    constructor(
        private billingService: BillingService,
        private router: Router,
        private loaderService: LoaderService) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(true);

        this.billingService.isOneClickAvailable().subscribe((available) => {
            this.loaderService.setLoading(false);
            if (available) {
                this.router.navigate(['/upsell/live/offer']).then();
            } else {
                this.router.navigate(['/upsell/live/offer-cb']).then();
            }
        }, (error) => {
            this.loaderService.setLoading(false);
            console.error(error);
            this.router.navigate(['/home']).then();
        });
    }
}
