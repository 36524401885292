<div *ngIf="selectedCountry" class="form-group">
    <label>{{ 'mobile_form.select_country' | translate }}</label>

    <div (keydown)="enableFilter()" (click)="showList()" (blur)="selecting = false" class="dd ddcommon borderRadius" id="countries_msdd" tabindex="0" style="width: 99.5%;">
        <div class="ddTitle borderRadiusTp">
            <span class="divider"></span>
            <span class="ddArrow arrowoff"></span>
            <span class="ddTitleText form-control ng-binding ng-scope" id="countries_title">
                <img src="assets/img/common/blank.gif" class="flag float-none" [ngClass]="selectedCountry.getCode().toLowerCase()" alt="Country"/>
                <span class="ddlabel">{{ selectedCountry.getName() }}</span>
                <span class="description" style="display: none;"></span>
            </span>
        </div>

        <input *ngIf="showFilter" [(ngModel)]="countryFilter" id="countries_titleText" type="text" autocomplete="off" class="text shadow borderRadius" ngAutofocus/>

        <div [class.d-block]="selecting || showFilter" class="ddChild ddchild_ border shadow" id="countries_child"
             style="z-index: 1; position: absolute; visibility: visible; height: 220px; top: 42px;">
            <ul>
                <li (click)="setCurrentCountry(country)" *ngFor="let country of (countryService.getEuropeanCountries() | filterCountry:countryFilter)" class="enabled" [class.selected]="isSiteCountry(country)" [title]="country.getName()">
                    <img src="assets/img/common/blank.gif" class="flag float-none" [ngClass]="country.getCode().toLowerCase()" alt="Country"/>
                    <span class="ddlabel">{{ country.getName() }}</span>
                    <div class="clear"></div>
                </li>
                <li disabled="" class="text-center">--------------------------------------------------------------------------</li>
                <li (click)="setCurrentCountry(country)" *ngFor="let country of (countryService.getCountriesDialingCodes() | filterCountry:countryFilter)" class="enabled" [class.selected]="isSiteCountry(country)" [title]="country.getName()">
                    <img src="assets/img/common/blank.gif" class="flag float-none" [ngClass]="country.getCode().toLowerCase()" alt="Country"/>
                    <span class="ddlabel">{{ country.getName() }}</span>
                    <div class="clear"></div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div *ngIf="selectedCountry" class="form-group">
    <label>{{ 'mobile_form.input_phone' | translate }}</label>
    <div class="input-group">
        <span class="input-group-text monemail"><i class="fas fa-mobile-alt"></i></span>
        <input [ngModel]="selectedCountry.indicative" type="text" class="form-control col-2 pre-tel" disabled/>

        <input (keydown)="checkNumberFormat($event)" (keyup)="updateMobile()" [(ngModel)]="mobile" [class.invalid]="mobileValid" type="text" class="form-control mr-1" placeholder="X.XX.XX.XX.XX"/>
    </div>
</div>
