import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class AskedMorePhotoNotificationType extends AbstractNotificationType {

    static readonly TYPE = 11;

    protected buttons:NotificationButton[] = [
        new NotificationButton('view_profile', () => {
            this.goTo('profile', this.notification.getFromProfile().getId());
        })
    ];
}
