import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Question} from './question';
import {Profile} from '../profile/profile';

@Injectable({
    providedIn: 'root'
})
export class QuestionsAnswers implements Deserializable {

    tabs: string[];
    categories = {};
    questions = {};
    questionsByName = {};

    deserialize(questions: any): this {
        this.tabs = Object.keys(questions);

        for (const tab of this.tabs) {
            this.categories[tab] = Object.keys(questions[tab]);
            this.questions[tab] = {};
            for (const category in questions[tab]) {
                if (questions[tab].hasOwnProperty(category)) {
                    this.questions[tab][category] = {};
                    for (const index in questions[tab][category]) {
                        if (questions[tab][category].hasOwnProperty(index)) {
                            const question = new Question().deserialize(questions[tab][category][index]);
                            this.questions[tab][category][index] = question;
                            this.questionsByName[questions[tab][category][index].name] = question;
                        }
                    }
                }
            }
        }

        return this;
    }

    getTabs(): string[] {
        return this.tabs;
    }

    getCategories(tab: string): string[] {
        return this.questions[tab] ? Object.keys(this.questions[tab]): [];
    }

    hasCategory(tab: string, category: string): boolean {
        return this.categories[tab] ? this.categories[tab].includes(category) : false;
    }

    getQuestions(tab: string, category: string): string[] {
        return (this.questions[tab] && this.questions[tab][category]) ? Object.keys(this.questions[tab][category]) : [];
    }

    getQuestion(tab: string, category: string, questionName: string): Question | null {
        return this.questions[tab][category][questionName] || null;
    }

    hasQuestion(tab: string, category: string, questionName: string): boolean {
        return !!this.questions[tab][category][questionName];
    }

    getAnswer(profile: Profile, questionName) {
        if (!this.questionsByName[questionName]) {
            return null;
        }
        const questionId = this.questionsByName[questionName].getId();
        return profile.getAnswers()[questionId] ?? null;
    }
}
