<div class="row main profile">
    <div class="col-12 text-center mb-4">
        <div class="cover">
            <app-card-component [profile]="profile" [currentProfile]="isCurrentProfile()"></app-card-component>
        </div>

        <ul class="nav nav-tabs onglets-profils btn-resp-profil" id="tab">
            <ng-container *ngFor="let tabName of questionsAnswers.getTabs()">
                <li class="nav-item d-none d-xl-block">
                    <a class="nav-link" [class.active]="tab === tabName" (click)="setTab(tabName)">
                        {{ ('profile_question_tab.' + (isCurrentProfile() ? 'edit.' : 'view.') + tabName) | translate }}
                    </a>
                </li>
                <li class="nav-item px-1 d-block d-xl-none col-6 col-md-3 col-lg-auto mb-1">
                    <a class="btn btn-light btn-sm d-inline-block w-100" [class.active]="tab === tabName" (click)="setTab(tabName)">
                        {{ ('profile_question_tab.' + (isCurrentProfile() ? 'edit.' : 'view.') + tabName) | translate }}
                    </a>
                </li>
            </ng-container>

            <li class="nav-item d-none d-xl-block">
                <a class="nav-link" [class.active]="tab === 'albums'" (click)="setTab('albums')">
                    {{ (isCurrentProfile() ? 'my_albums' : 'their_albums') | translate }}
                    <span class="badge badge-secondary ml-2">{{ getAlbums().length }}</span>
                </a>
            </li>
            <li class="nav-item px-1 d-block d-xl-none col-6 col-md-3 col-lg-auto mb-1">
                <a class="btn btn-light off btn-sm d-inline-block w-100" [class.active]="tab === 'albums'" (click)="setTab('albums')">
                    {{ (isCurrentProfile() ? 'my_albums' : 'their_albums') | translate }}
                    <span class="badge badge-secondary ml-2">{{ getAlbums().length }}</span>
                </a>
            </li>

            <li class="nav-item d-block d-xl-none w-100 mb-1" *ngIf="!isCurrentProfile()">
                <app-profile-action-mobile [profile]="profile"></app-profile-action-mobile>
            </li>

            <li class="nav-item ml-auto li-compte-access" *ngIf="!isCurrentProfile()">
                <app-profile-action [profile]="profile"></app-profile-action>
            </li>

            <li class="nav-item px-1 ml-auto li-compte-access" *ngIf="isCurrentProfile()">
                <a [routerLink]="['/account']" class="btn btn-light btn-sm d-inline-block btn-compte mr-1">
                    <i class="fas fa-cog"></i> {{ 'menu.account_settings' | translate }}
                </a>
                <a *ngIf="!account.activeSubscription() && account.getRuleSet().isSubscription()" [routerLink]="['/payment']" [queryParams]="{action: 'profile_edit'}" class="btn btn-success btn-sm btn-access d-none d-md-inline-block">
                    <i class="fas fa-crown"></i> {{ 'search_appearance.activate_access' | translate }}
                </a>
            </li>
            <li  *ngIf="!account.activeSubscription() && account.getRuleSet().isSubscription()" class="nav-item px-1 d-block d-md-none mt-2 w-100">
                <a [routerLink]="['/payment']" [queryParams]="{action: isCurrentProfile() ? 'profile_edit' : 'profile_view'}"
                   class="btn btn-success btn-sm d-inline-block btn-access btn-block">
                    <i class="fas fa-crown"></i> {{ 'search_appearance.activate_access' | translate }}
                </a>
            </li>
        </ul>
    </div>

    <div class="col-12">
        <div class="row">
            <div *ngIf="canViewCertification()" class="col-lg-4 d-none d-lg-block">
                <app-certification-component></app-certification-component>
            </div>

            <div *ngIf="tab !== 'albums'" [ngClass]="{'col-lg-8': canViewCertification(), 'col-lg-12': !canViewCertification()}">
                <app-profile-tab-component (save)="onSave()" [tab]="tab" [profile]="profile" [currentProfile]="isCurrentProfile()"></app-profile-tab-component>
            </div>

            <div class="col-lg-12">
                <div *ngIf="tab === 'albums'">
                    <app-albums [profile]="profile"></app-albums>
                </div>
            </div>
        </div>
    </div>
</div>
