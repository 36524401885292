import {AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
// import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {PendingAction} from '../../../models/pending-action/pending-action';
// import {PendingActionService} from '../../../services/pending-action/pending-action.service';
import {AbstractPaymentComponent} from '../../payment/abstract-payment.component';
import {ActivatedRoute, Router} from '@angular/router';
import {WINDOW} from '../../../app.config';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {InitService} from '../../../services/init/init.service';
import {ChatService} from '../../../services/chat/chat.service';
import {BillingService} from '../../../services/billing/billing.service';
// import {CoinService} from '../../../services/coin/coin.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-coin-payment',
    templateUrl: './coin-payment.component.html',
    styleUrls: ['./coin-payment.component.scss']
})
export class CoinPaymentComponent extends AbstractPaymentComponent implements OnInit, AfterViewChecked {

    @ViewChild('iframe') iframe: ElementRef;
    /* @feature "pending actions" disabled
    @ViewChild('slick') slick: SlickCarouselComponent;
    @ViewChild('sizer') sizer: ElementRef;

    slideConfig = {
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    coinsRequired = 0;
    deletedId: number = null;
    */
    pendingActions: PendingAction[] = [];

    constructor(
        protected router: Router,
        @Inject(WINDOW) protected window: Window,
        protected currentUserService: CurrentUserService,
        protected activatedRoute: ActivatedRoute,
        protected initService: InitService,
        protected chatService: ChatService,
        protected billingService: BillingService,
        protected dialog: MatDialog,
        // private pendingActionService: PendingActionService,
        // private coinService: CoinService
    ) {
        super(router, window, currentUserService, activatedRoute, initService, chatService, billingService, dialog);
    }

    ngOnInit() {
        /* @feature "pending actions" disabled
        this.pendingActionService.getCollection().subscribe((response: any) => {
            this.pendingActions = [];
            this.coinsRequired = 0;
            if (Array.isArray(response.data)) {
                let coinsRequired = 0;
                for (const data of response.data) {
                    switch (data.type) {
                        case PendingAction.MESSAGE_TYPE:
                            if (!this.coinService.getMessageOffer()) {
                                continue;
                            }
                            coinsRequired += this.coinService.getMessageOffer().getPrice();
                            break;
                        case PendingAction.KISS_TYPE:
                            if (!this.coinService.getKissOffer()) {
                                continue;
                            }
                            coinsRequired += this.coinService.getKissOffer().getPrice();
                            break;
                    }
                    this.pendingActions.push(new PendingAction().deserialize(data));
                }
                this.coinsRequired = coinsRequired;
            }

            this.iFrameEvent();

            this.currentUserService.coinsPayment().subscribe((coinResponse: any) => {
                if (coinResponse.success) {
                    this.setPaymentUrl(coinResponse.data.paymentUrl);
                }
            });
        });
        */

        this.fetchPayment();
    }

    fetchPayment(lastOfferId: number = null) {
        this.iFrameEvent();

        this.currentUserService.coinsPayment(lastOfferId).subscribe((coinResponse: any) => {
            if (coinResponse.success) {
                this.setPaymentUrl(coinResponse.data.paymentUrl);
            }
        });
    }

    close() {
        this.dialog.closeAll();
    }

    ngAfterViewChecked() {
        // @feature "pending actions" disabled
        // this.slideConfig.centerMode = this.pendingActions.length > this.slideConfig.slidesToShow;
    }

    setPaymentUrl(url: string) {
        super.setPaymentUrl(url);
        if (this.iframe.nativeElement && this.iframe.nativeElement.contentWindow) {
            this.iframe.nativeElement.contentWindow.location.replace(url);
        }
    }

    /* @feature "pending actions" disabled
    delete(pendingAction: PendingAction) {
        if (this.deletedId) {
            return;
        }
        this.deletedId = pendingAction.getId();
        this.pendingActionService.delete(pendingAction).subscribe(() => {
            const index = this.pendingActions.findIndex((action: PendingAction) => action.getId() === pendingAction.getId());
            this.pendingActions.splice(index, 1);
            let coinsRequired = 0;
            for (const data of this.pendingActions) {
                switch (data.type) {
                    case PendingAction.MESSAGE_TYPE:
                        coinsRequired += this.coinService.getMessageOffer().getPrice();
                        break;
                    case PendingAction.KISS_TYPE:
                        coinsRequired += this.coinService.getKissOffer().getPrice();
                        break;
                }
            }
            this.coinsRequired = coinsRequired;
            this.deletedId = null;
        });
    }
    */

    step(action) {
        this.isPurchaseStep = action === 'next';
        const iframe = document.getElementById('redirectPdp');
        if (iframe instanceof HTMLIFrameElement && iframe.contentEditable) {
            iframe.contentWindow.postMessage({action}, '*');
        }
    }

    tryAgain() {
        this.loaded = false;
        this.isPurchaseStep = true;
        this.showModalFooter = true;
        this.paymentStatus = null;
        this.fetchPayment(this.lastOfferId);
    }
}
