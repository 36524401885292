import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupOffer} from '../../models/offer/group-offer';

@Injectable({
    providedIn: 'root'
})
export class BillingService {

    constructor(private http: HttpClient) {
    }

    paymentPreAuth(params: any) {
        const queryParams = new URLSearchParams();
        queryParams.append('action', params.action);
        queryParams.append('_ga', params.ga);

        return this.http.get(environment.backendUrl + `/billing/pre-auth?` + queryParams.toString());
    }

    payment(params: any) {
        const queryParams = new URLSearchParams();
        queryParams.append('mode', params.mode);
        queryParams.append('action', params.action);
        queryParams.append('templateId', params.templateId);
        queryParams.append('_ga', params.ga);

        if (params.profileId) {
            queryParams.append('profileId', params.profileId);
        }

        if (params.offerId) {
            queryParams.append('offerId', params.offerId);
        }

        if (params.offerGroupId) {
            queryParams.append('offerGroupId', params.offerGroupId);
        }

        if (params.offerGroupTemplateId) {
            queryParams.append('offerGroupTemplateId', params.offerGroupTemplateId);
        }

        if (params.returnUrlAccepted) {
            queryParams.append('returnUrlAccepted', params.returnUrlAccepted);
        }

        if (params.returnUrlRefused) {
            queryParams.append('returnUrlRefused', params.returnUrlRefused);
        }

        return this.http.get(environment.backendUrl + `/billing?` + queryParams.toString());
    }

    isOneClickAvailable(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + `/billing/one-click/status`).subscribe((response: any) => {
                observer.next(response.data.available);
            }, (error) => {
                observer.error(error.message);
            });
        });
    }

    oneClickPayment(offerId: number): Observable<any> {
        return new Observable<any>(observer => {
            this.http.post(environment.backendUrl + `/billing/one-click/payment`, {offerId})
                .subscribe((response: any) => {
                    observer.next(response.data);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    upsellVirilblue(returnUrlAccepted: string = null, returnUrlRefused: string = null): Observable<any> {
        return new Observable<any>(observer => {
            this.http.post(environment.backendUrl + `/billing/upsell/virilblue`, {
                returnUrlAccepted,
                returnUrlRefused
            })
                .subscribe((response: any) => {
                    observer.next(response.data);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    upsellVirilblueForm(form: any): Observable<any> {
        return new Observable<any>(observer => {
            this.http.post(environment.backendUrl + `/billing/upsell/virilblue/form`, form)
                .subscribe((response: any) => {
                    observer.next(response.data);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    canUpsellVirilblue(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + `/billing/upsell/virilblue/status`, {})
                .subscribe((response: any) => {
                    observer.next(response.data.available);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    getUpsellVirilbluePaymentPageUrl(returnUrlAccepted: string = null, returnUrlRefused: string = null): Observable<string> {
        return new Observable<string>(observer => {
            const queryParams = new URLSearchParams();

            if (returnUrlAccepted) {
                queryParams.append('returnUrlAccepted', returnUrlAccepted);
            }

            if (returnUrlRefused) {
                queryParams.append('returnUrlRefused', returnUrlRefused);
            }

            this.http.get(environment.backendUrl + `/billing/upsell/virilblue/payment?` + queryParams.toString(), {})
                .subscribe((response: any) => {
                    observer.next(response.data.paymentUrl);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    upsellLive(returnUrlAccepted: string = null, returnUrlRefused: string = null): Observable<any> {
        return new Observable<any>(observer => {
            this.http.post(environment.backendUrl + `/billing/upsell/live`, {
                returnUrlAccepted,
                returnUrlRefused
            }).subscribe((response: any) => {
                observer.next(response.data);
            }, (error) => {
                observer.error(error.message);
            });
        });
    }

    canUpsellLive(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + `/billing/upsell/live/status`, {})
                .subscribe((response: any) => {
                    observer.next(response.data.available);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    canUpsellUnlover(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + `/billing/upsell/unlover/status`, {})
                .subscribe((response: any) => {
                    observer.next(response.data.available);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }

    upsellUnlover(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + `/billing/upsell/unlover`)
                .subscribe((response: any) => {
                    observer.next(response.success);
                }, (error) => {
                    observer.error(error.message);
                });
        });
    }
}
