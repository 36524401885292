import {Component, OnInit} from '@angular/core';
import {InitService} from '../../../services/init/init.service';
import {SocketService} from '../../../services/socket/socket.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatAutoReconnectService} from '../../../services/chat/chat-auto-reconnect/chat-auto-reconnect.service';

@Component({
    selector: 'app-chat-alert',
    templateUrl: './chat-alert.component.html',
    styleUrls: ['./chat-alert.component.scss']
})
export class ChatAlertComponent implements OnInit {

    reconnectFastOnce: boolean;

    constructor(
        private socketService: SocketService,
        private initService: InitService,
        private currentUserService: CurrentUserService,
        private chatAutoReconnectService: ChatAutoReconnectService
    ) {
        this.reconnectFastOnce = true;
    }

    ngOnInit() {
        this.autoReconnect();
    }

    autoReconnect() {
        setTimeout(() => {
            if (this.canReconnect()) {
                this.reconnect();
            }
            this.autoReconnect();
        }, this.reconnectFastOnce ? 100 : 60000);
        this.reconnectFastOnce = false;
    }

    canReconnect(): boolean {
        return this.isDisconnected() && this.isAutoReconnect();
    }

    isAutoReconnect(): boolean {
        return this.chatAutoReconnectService.isAutoReconnect();
    }

    isDisconnected(): boolean {
        return this.socketService.isDisconnected();
    }

    reconnect() {
        this.initService.loadSocket();
    }
}
