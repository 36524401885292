<div *ngIf="isVisible()" id="filter-panel" class="filter-panel collapse show">
    <a class="close cursor-pointer" (click)="hide()" aria-expanded="true" data-toggle="collapse">
        <i class="fas fa-times"></i>
    </a>

    <div class="card">
        <div class="card-body">
            <form (ngSubmit)="submit()">
                <div class="row no-gutters mr--10">
                    <div class="col-12">
                        <h6>{{ 'languages' | translate }}</h6>
                    </div>

                    <div id="filter-container" class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('FR')" [checked]="getFilters().languages.includes('FR')"
                                           value="FR" id="filter-lang-fr" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-fr">
                                        <img src="assets/img/common/blank.gif" class="flag fr float-none"/> <span>FR</span>
                                    </label>
                                </div>

                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('EN')" [checked]="getFilters().languages.includes('EN')"
                                           value="EN" id="filter-lang-en" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-en">
                                        <img src="assets/img/common/blank.gif" class="flag england float-none"/> <span>EN</span>
                                    </label>
                                </div>

                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('ES')" [checked]="getFilters().languages.includes('ES')"
                                           value="ES" id="filter-lang-es" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-es">
                                        <img src="assets/img/common/blank.gif" class="flag es float-none"/> <span>ES</span>
                                    </label>
                                </div>

                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('IT')" [checked]="getFilters().languages.includes('IT')"
                                           value="IT" id="filter-lang-it" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-it">
                                        <img src="assets/img/common/blank.gif" class="flag it float-none"/> <span>IT</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('DE')" [checked]="getFilters().languages.includes('DE')"
                                           value="DE" id="filter-lang-de" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-de">
                                        <img src="assets/img/common/blank.gif" class="flag de float-none"/> <span>DE</span>
                                    </label>
                                </div>

                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('NL')" [checked]="getFilters().languages.includes('NL')"
                                           value="NL" id="filter-lang-nl" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-nl">
                                        <img src="assets/img/common/blank.gif" class="flag nl float-none"/> <span>NL</span>
                                    </label>
                                </div>

                                <div class="custom-control custom-checkbox mb-2">
                                    <input (change)="changeFlag('TR')" [checked]="getFilters().languages.includes('TR')"
                                           value="TR" id="filter-lang-tr" type="checkbox" class="custom-control-input" name="filter-language">
                                    <label class="custom-control-label" for="filter-lang-tr">
                                        <img src="assets/img/common/blank.gif" class="flag tr float-none"/> <span>TR</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="form-group position-relative d-flex align-items-center">
                            <label class="h6">HD :</label>
                            <div class="form-check">
                                <label class="form-check-label form-check-toggle">
                                    <input [(ngModel)]="getFilters().hd" class="form-check-input" name="languages" type="checkbox" checked="checked"><span></span>
                                </label>
                            </div>
                        </div>

                        <hr>

                        <div class="form-group position-relative">
                            <label class="h6 w-100">Hashtags :</label>
                            <a (click)="getFilters().setPrivateShow()"
                               [class.active]="getFilters().isPrivateShow()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'peeping_mode' | translate }}</a>

                            <a (click)="getFilters().setVIPShow()"
                               [class.active]="getFilters().isVIPShow()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'vip_show' | translate }}</a>

                            <a (click)="getFilters().togglePiercing()"
                               [class.active]="getFilters().hasPiercing()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.piercing' | translate }}</a>

                            <a (click)="getFilters().toggleTattoo()"
                               [class.active]="getFilters().hasTattoo()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.tattoo' | translate }}</a>

                            <a (click)="getFilters().setBlondeHair()"
                               [class.active]="getFilters().hasBlondeHair()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.blonde_hair' | translate }}</a>

                            <a (click)="getFilters().setBrownHair()"
                               [class.active]="getFilters().hasBrownHair()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.brown_hair' | translate }}</a>

                            <a (click)="getFilters().setRedHair()"
                               [class.active]="getFilters().hasRedHair()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.red_hair' | translate }}</a>

                            <a (click)="getFilters().setBlackHair()"
                               [class.active]="getFilters().hasBlackHair()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.black_hair' | translate }}</a>

                            <a (click)="getFilters().setSmallTit()"
                               [class.active]="getFilters().hasSmallTit()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'small_tits' | translate }}</a>

                            <a (click)="getFilters().setBigTit()"
                               [class.active]="getFilters().hasBigTit()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'big_tits' | translate }}</a>

                            <a (click)="getFilters().setSlimBody()"
                               [class.active]="getFilters().hasSlimBody()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.slender_body' | translate }}</a>

                            <a (click)="getFilters().setPlumpBody()"
                               [class.active]="getFilters().hasPlumpBody()"
                               class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">#{{ 'profile_answers.curvy_body' | translate }}</a>

                            <ng-container *ngFor="let age of getFilterList('ages')">
                                <a (click)="getFilters().addAge(age)"
                                   [class.active]="getFilters().hasAge(age)"
                                   class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2">
                                    #{{ age.replace('*', '-') }} {{ 'years_old' | translate }}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row ok-filtres">
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary w-100 btn-sm mt-3">{{ 'search_filter.apply' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
