import {Injectable} from '@angular/core';
import {Activity} from './activity';
import {Gender} from './gender';

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractUser {

    photo: string;
    gender: number;
    activity: Activity = null;
    distance: number = null;

    abstract getNickname(): string;

    getPhoto(): string {
        return this.photo;
    }

    setPhoto(photo: string) {
        this.photo = photo;
    }

    setActivity(status: number) {
        this.activity = new Activity(status);
    }

    getActivity(): Activity {
        return this.activity;
    }

    getGender(): number {
        return this.gender;
    }

    setGender(gender: number) {
        this.gender = gender;
    }

    isMale(): boolean {
        return this.getGender() === Gender.MALE;
    }

    isFemale(): boolean {
        return this.getGender() === Gender.FEMALE;
    }

    isCouple(): boolean {
        return this.getGender() === Gender.COUPLE;
    }

    getGenderLabel(): string {
        switch (this.gender) {
            case Gender.MALE:
                return Gender.MALE_LABEL;
            case Gender.FEMALE:
                return Gender.FEMALE_LABEL;
            case Gender.COUPLE:
                return Gender.COUPLE_LABEL;
        }
    }

    getOppositeGender(): number {
        return this.isFemale() ? Gender.MALE : Gender.FEMALE;
    }

    getDistance(): number {
        return this.distance;
    }
}
