import {Injectable} from '@angular/core';
import {Account} from '../../models/account/account';
import {SiteService} from '../site/site.service';

declare let zE: any;

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    static readonly CHAT_ONLINE = 'online';

    chatIsLoaded: boolean;
    chatIsOnline: boolean;
    chatIsOpen: boolean;

    constructor(
        private siteService: SiteService
    ) {
        this.chatIsLoaded = false;
        this.chatIsOnline = false;
        this.chatIsOpen = false;
    }

    initChat() {
        const theme = this.siteService.getSite().getCss()['mainColor'];

        window['zESettings'] = {
            webWidget: {
                color: {
                    theme
                },
                chat: {
                    departments: {
                        enabled: []
                    }
                }
            }
        };

        // load zendesk script for the chat support
        zE('webWidget', 'hide');
        zE('webWidget:on', 'chat:status', (status: string) => {
            this.chatIsOnline = status === SupportService.CHAT_ONLINE;
            this.chatIsLoaded = true;
            this.initChatModalEvent();
        });
    }

    initChatModalEvent() {
        // open and close modal events
        zE('webWidget:on', 'open', () => {
            this.chatIsOpen = true;
            zE('webWidget', 'show');
        });
        zE('webWidget:on', 'close', () => {
            this.chatIsOpen = false;
            zE('webWidget', 'hide');
        });
    }

    openChat(account: Account) {
        // open chat modal (only if chat support is online)
        if (this.chatIsOnline) {
            zE('webWidget', 'identify', {
                name: account.getProfile().getNickname(),
                email: account.getEmail(),
                phone: account.getMobile()
            });

            zE('webWidget', 'setLocale', account.getProfile().getLanguage());
            zE('webWidget', 'open');
        }
    }

    closeChat() {
        // close chat modal
        zE('webWidget', 'close');
    }
}
