import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../models/chat/user/user';

@Component({
    selector: 'app-translate',
    templateUrl: './translate.component.html',
    styleUrls: []
})
export class TranslateComponent implements OnInit {

    @Input() text: string;
    @Input() profile: Profile = null;
    @Input() user: User = null;
    @Input() extraData: any = null;
    @Input() linkNickname = true;
    @Input() format: any = null;

    translations: string[] = null;
    private profileId: number = null;
    private nickname: string = null;

    constructor(private currentUserService: CurrentUserService, private translateService: TranslateService) {}

    ngOnInit(): void {
        const translation: string = this.translateService.instant(this.text, this.extraData ? this.extraData : {});

        if (translation.includes('[nickname]')) {
            // nickname and profileId will be deducted from a Profile or User model depending on the context.
            this.nickname = this.profile ? this.profile.getNickname() : this.user.getUsername();
            this.profileId = this.profile ? this.profile.getId() : this.user.getUid();

            this.translations = translation.split('[nickname]');
            this.translations.splice(1, 0, this.nickname);
        } else {
            this.translations = [translation];
        }
    }

    isNickname(translation: string): boolean {
        return this.nickname === translation;
    }

    formatNickname(): string {
        const nickname = this.currentUserService.hasRule('nicknameBlur', this.profileId) ?
            `<span class="blurred">${this.nickname}</span>` :
            this.nickname;

        if (!this.format || !this.format.nickname) {
            return nickname;
        }
        switch (this.format.nickname) {
            case 'action':
                const icon: string = (this.profile ? this.profile.isMale() : this.user.isMale()) ?
                    'fa-mars icon-male' :
                    'fa-venus icon-female';

                return `<i class="fas ${icon}"></i> <b>${nickname}</b>`;
        }
        return nickname;
    }
}
