<div class="container">
    <div class="row">
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="col-6 mx-auto">

            <h1 class="mt-5">Login page</h1>

            <div class="form-group mt-5">
                <label>Email :</label>
                <input type="text" class="form-control" formControlName="username">
            </div>
            <div class="form-group">
                <label>Mot de passe</label>
                <input type="password" class="form-control" formControlName="password">
            </div>
            <button type="submit" class="btn btn-primary"><i class="fa fa-sign-in-alt mr-3"></i>Sign in</button>
        </form>
    </div>
</div>
