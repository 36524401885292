import {Component, DoCheck, Input, OnInit,} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {Question} from '../../../models/site/question';
import {HelperService} from '../../../services/helper/helper.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-selected-choice',
    templateUrl: './selected-choice.component.html'
})
export class SelectedChoiceComponent implements OnInit, DoCheck {

    @Input() question: Question;
    @Input() profile: Profile;
    @Input() currentProfile = false;

    @Input() accordion = false;

    private valueList = [];
    private previousValue: string | [];

    constructor(private helper: HelperService, private translate: TranslateService) {}

    ngOnInit() {
        this.previousValue = this.profile.getAnswers()[this.question.getId()] || null;
        this.init();
    }

    ngDoCheck(): void {
        const currentValue = this.profile.getAnswers()[this.question.getId()] || null;
        if (this.previousValue !== currentValue || this.compareArray(this.previousValue, currentValue)) {
            this.init();
        }
    }

    getValueList() {
        return this.valueList;
    }

    translateGender(translationIndex: string): string {
        return this.helper.translateGender(this.profile, translationIndex);
    }

    isSliderType(): boolean {
        return this.question.getType() === 'slider' || this.question.getType() === 'range-slider';
    }

    private compareArray(a: string | [], b: string | []): boolean {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    private init() {
        this.valueList = [];

        const noData = this.currentProfile ? (this.accordion ? 'select_an_answer' : 'choose_an_answer') : 'choice_list.no_data';
        const answers: any = this.profile.getAnswers() ? this.profile.getAnswers()[this.question.getId()] : [];
        const isArrayOfAnswer = Array.isArray(answers);

        /** No answer found so we just display the default label. */
        if (!answers || (isArrayOfAnswer && !answers.length)) {
            this.valueList = [noData];
        /** Slider type have a simple inline answer. */
        } else if (this.isSliderType()) {
            if (this.question.getType() === 'slider') {
                this.valueList.push(answers + ' ');
            } else {
                this.valueList.push(this.translate.instant('between'));
                this.valueList.push(' ' + answers[0] + ' ');
                this.valueList.push(this.translate.instant('and'));
                this.valueList.push(' ' + answers[1] + ' ');
            }

            this.valueList.push('profile_answers.' + this.question.getAnswers()[0].name);
        /** For other type, we loop through each answer and check if it exists in the given list. */
        } else  {
            const values = isArrayOfAnswer ? answers: [answers];

            for (const value of values) {
                if (this.question.hasAnswer(value)) {
                    this.valueList.push('profile_answers.' + this.question.getAnswer(value).name);
                } else {
                    this.valueList = [noData];
                    break;
                }
            }
        }
    }
}
