import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Ebook implements Deserializable {

    id: number;
    title: string;
    author: string;
    date: Date;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getId(): number {
        return this.id;
    }

    getTitle(): string {
        return this.title;
    }

    getAuthor(): string {
        return this.author;
    }

    getDate(): Date {
        return this.date;
    }
}
