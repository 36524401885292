import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {ActionService} from '../../../services/profile/action/action.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {CoinService} from '../../../services/coin/coin.service';
import {Account} from '../../../models/account/account';
// import {PendingActionService} from '../../../services/pending-action/pending-action.service';
// import {PendingAction} from '../../../models/pending-action/pending-action';
import {CoinWarningComponent} from '../../modal/coin-warning/coin-warning.component';
import {MatDialog} from '@angular/material/dialog';
import {SocketService} from '../../../services/socket/socket.service';

@Component({
    selector: 'app-profile-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionComponent {

    @Input() profile: Profile;
    @Output() profileChange = new EventEmitter();

    alreadyKissed = false;
    alreadyFavorite = false;

    constructor(
        protected actionService: ActionService,
        protected modal: ModalService,
        protected dialog: MatDialog,
        protected translate: TranslateService,
        protected toastr: ToastrService,
        protected currentUserService: CurrentUserService,
        protected coinService: CoinService,
        // protected pendingActionService: PendingActionService,
        protected socketService: SocketService
    ) {}

    kiss() {
        if (this.profile.getRelationship().getKissed() || this.alreadyKissed) {
            this.toastr.error(this.translate.instant('contact.kiss_already_sent'));
            return false;
        }

        if (!this.canSendKiss()) {
            this.dialog.open(CoinWarningComponent, {data: {offer: this.coinService.getKissOffer()}});
            /* @feature "pending actions" disabled
            this.pendingActionService.create(PendingAction.KISS_TYPE, this.profile.getId()).subscribe(() => {
                this.dialog.open(CoinWarningComponent, {data: {offer: this.coinService.getKissOffer()}});
            }, () => {
                this.dialog.open(CoinWarningComponent, {data: {offer: this.coinService.getKissOffer()}});
            });
            */
            return false;
        }

        this.confirm('contact.kiss.send_kiss', 'contact.how_it_work', 'contact.kiss.text', this.translate.instant('contact.kiss.send_kiss'), 'fa-kiss-wink-heart')
            .afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }

            this.alreadyKissed = true;
            this.actionService.addKiss(this.profile.getId()).subscribe((result: any) => {
                if (!result.success) {
                    return;
                }

                if (result.data) {
                    if (result.data.ruleSet) {
                        this.currentUserService.updateRuleSet(result.data.ruleSet);
                    }
                    if (result.data.coins) {
                        this.currentUserService.setCoins(result.data.coins);
                        this.socketService.emit('update_coins', {coins: this.currentUserService.getAccount().getCoins()});
                    }
                }

                this.profile.relationship.kissed = true;
                this.alreadyKissed = false;
                this.profileChange.emit();
                this.openModal('contact.kiss.send_kiss', 'contact.kiss.kiss_done', 'fa-kiss-wink-heart');
            });
        });
    }

    block() {
        const isBlocked = this.profile.getRelationship().getBlocked();
        const method = isBlocked ? 'removeBlocked' : 'addBlocked';
        const text = isBlocked ? 'unblock' : 'block';
        const question = (!isBlocked) ? 'contact.how_it_work' : null;

        this.confirm(`contact.block.${text}_a_profile`, question, `contact.block.text_${text}`, `contact.block.${text}`, 'fa-ban')
            .afterClosed().subscribe(result => {
            if (result) {
                this.profile.relationship.blocked = !isBlocked;
                this.actionService[method](this.profile.getId()).subscribe(() => {
                    this.profileChange.emit();
                    this.openModal(`contact.block.${text}_a_profile`, `contact.block.${text}_done`, 'fa-ban');
                });
            }
        });
    }

    report() {
        this.modal.openReportProfile(this.profile);
    }

    favorite() {
        if (this.profile.getRelationship().getFavorite()) {
            this.deleteFavorite();
        } else if (!this.alreadyFavorite) {
            this.addFavorite();
        }
    }

    addFavorite() {
        this.confirm('contact.favorite.add_to_favorite', 'contact.how_it_work', 'contact.favorite.text_add', 'contact.favorite.add_to_my_favorite', 'fa-star')
            .afterClosed().subscribe((response: boolean) => {
            if (!response) {
                return;
            }
            this.alreadyFavorite = true;
            this.actionService.addFavorite(this.profile.getId()).subscribe(() => {
                this.profileChange.emit();
                this.profile.relationship.favorite = true;
                this.alreadyFavorite = false;
                this.openModal('contact.favorite.add_to_favorite', 'contact.favorite.add_done', 'fa-star');
            });
        });
    }

    deleteFavorite() {
        this.confirm('contact.favorite.delete_to_my_favorite', null, 'contact.favorite.text_delete', 'contact.favorite.delete_to_my_favorite', 'fa-star')
            .afterClosed().subscribe(result => {
            if (result) {
                this.profile.relationship.favorite = false;
                this.actionService.removeFavorite(this.profile.getId()).subscribe(() => {
                    this.profileChange.emit();
                    this.openModal('contact.favorite.delete_to_my_favorite', 'contact.favorite.delete_done', 'fa-star');
                });
            }
        });
    }

    friend() {
        const isFriend = this.profile.getRelationship().addedToFriend();
        const method = isFriend ? 'removeFriend' : 'addFriend';
        const text = isFriend ? 'unfriend' : 'friend';
        const question = (!isFriend) ? 'contact.how_it_work' : null;
        const icon = isFriend ? 'fa-user-minus' : 'fa-user-plus';

        this.confirm(`contact.friend.${text}`, question, `contact.friend.text_${text}`, `contact.friend.send_${text}`, icon)
            .afterClosed().subscribe(result => {
            if (result) {
                this.profile.relationship.friend = isFriend ? null : 0;
                this.actionService[method](this.profile.getId()).subscribe(() => {
                    this.profileChange.emit();
                    this.openModal(`contact.friend.${text}`, `contact.friend.${text}_done`, 'fa-user-plus');
                });
            }
        });
    }

    getAccount(): Account {
        return this.currentUserService.getAccount();
    }

    private canSendKiss(): boolean {
        if (this.getAccount().activeSubscription() || this.getAccount().getRuleSet().isSubscription()) {
            return true;
        }

        return this.coinService.getKissOffer().isAffordable(this.currentUserService.getAccount().getCoins());
    }

    private confirm(title, question, sampleText, confirm, faClass = null) {
        return this.modal.openConfirmation({
            data: {
                title,
                question: question || null,
                sampleText: sampleText || null,
                responses: [
                    {
                        text: 'cancel', result: false
                    },
                    {
                        text: confirm, result: true
                    }
                ],
                faClass,
                profile: this.profile
            }
        });
    }

    private openModal(title, sampleText, faClass = null) {
        return this.modal.openConfirmation({
            data: {
                title,
                sampleText,
                responses: [{
                    text: 'close'
                }],
                faClass,
                format: {
                    nickname: 'action'
                },
                profile: this.profile
            }
        });
    }
}
