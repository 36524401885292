<div [id]="modalId" [ngClass]="modalClass" class="lightbox show" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" id="modalcontent">
            <div class="modal-header" [class.no-title]="title === '' && titleMobile === ''">
                <h5 *ngIf="title !== ''" class="modal-titre d-none d-lg-block text-truncate">
                    <i class="fas {{ faClass }}"></i>
                    <app-translate [text]="title" [profile]="profile" [linkNickname]="false"></app-translate>
                </h5>
                <h5 *ngIf="titleMobile !== ''" class="modal-titre d-block d-lg-none text-truncate">
                    <i class="fas {{ faClass }}"></i>
                    <app-translate [text]="titleMobile" [profile]="profile" [linkNickname]="false"></app-translate>
                </h5>
                <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close" [mat-dialog-close]="true">
                    <i class="fas fa-times-circle"></i>
                </button>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
