import {Component} from '@angular/core';
import {Group} from '../../../../../models/offer/group';
import {OffersTemplateComponent} from '../offers-template.component';

@Component({
    selector: 'app-offers-template1',
    templateUrl: './offers-template1.component.html',
    styleUrls: ['./offers-template1.component.scss']
})
export class OffersTemplate1Component implements OffersTemplateComponent {

    group: Group;

    getBackgroundImage(): string {
        return this.group.image;
    }
}
