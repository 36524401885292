import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {SiteService} from '../../../services/site/site.service';
import {QuestionsAnswers} from '../../../models/site/questions-answers';
import {HelperService} from '../../../services/helper/helper.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-question-group',
    templateUrl: './question-group.component.html'
})
export class QuestionGroupComponent implements OnInit {

    @Input() tab: string;
    @Input() category: string;
    @Input() profile: Profile;

    sliderQuestions = ['height', 'ideal_height', 'ideal_age', 'ideal_age_range'];
    sliderConfig = {
        height: {
            min: 150,
            max: 240
        },
        ideal_height: {
            min: 150,
            max: 240
        },
        ideal_age: {
            min: 18,
            max: 100
        },
        ideal_age_range: {
            min: 18,
            max: 100
        }
    };
    sliderModel = {
        height: 150,
        ideal_height: [150, 240],
        ideal_age: [18, 100],
        ideal_age_range: [18, 100]
    };

    constructor(private siteService: SiteService, private helper: HelperService) {
    }

    ngOnInit() {
        for (const questionName of this.sliderQuestions) {
            const question = this.questionsAnswers.getQuestion(this.tab, this.category, questionName);
            if (question) {
                const defaultAnswer = question.getType() === 'slider' ?
                    this.sliderConfig[questionName].min :
                    [this.sliderConfig[questionName].min, this.sliderConfig[questionName].max];

                this.sliderModel[questionName] = this.profile.getAnswers()[question.getId()] || defaultAnswer;
            }
        }
    }

    get questionsAnswers(): QuestionsAnswers {
        return this.siteService.getQuestionsAnswers();
    }

    translateGender(translationIndex: string): string {
        return this.helper.translateGender(this.profile, translationIndex);
    }

    sliderUpdate(questionName) {
        const question = this.questionsAnswers.getQuestion(this.tab, this.category, questionName);
        const selector = $(`#detail-${questionName} .noUi-handle.noUi-handle-lower`);
        if (selector.is(':visible')) {
            this.profile.getAnswers()[question.getId()] = this.sliderModel[questionName];
        }

        selector.attr('data-value', this.sliderModel[questionName]);
    }

    rangeSliderUpdate(questionName) {
        const question = this.questionsAnswers.getQuestion(this.tab, this.category, questionName);

        this.profile.getAnswers()[question.getId()] = this.sliderModel[questionName];

        $(`#detail-${questionName} .noUi-handle.noUi-handle-lower`).attr('data-value', this.sliderModel[questionName][0]);
        $(`#detail-${questionName} .noUi-handle.noUi-handle-upper`).attr('data-value', this.sliderModel[questionName][1]);
    }
}
