import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CoinOffer} from '../../../../models/offer/coin-offer';
import {BoosterComponent} from '../booster.component';
import {CoinService} from '../../../../services/coin/coin.service';
import {CoinPaymentComponent} from '../../coin-payment/coin-payment.component';

@Component({
    selector: 'app-booster-list',
    templateUrl: './booster-list.component.html'
})
export class BoosterListComponent {

    hasClick = false;
    @Output() setOffer = new EventEmitter<CoinOffer>();

    constructor(
        private dialogRef: MatDialogRef<BoosterComponent>,
        private router: Router,
        private coinService: CoinService,
        private dialog: MatDialog
    ) {}

    getBooster12(): CoinOffer {
        return this.coinService.getBooster12();
    }

    getBooster24(): CoinOffer {
        return this.coinService.getBooster24();
    }

    getBooster48(): CoinOffer {
        return this.coinService.getBooster48();
    }

    sendOffer(offer: CoinOffer) {
        if (!this.hasClick) {
            this.hasClick = true;
            this.setOffer.emit(offer);
        }
    }

    goPayment() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'wall_booster'}
        });
        this.dialogRef.close();
    }
}
