import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class BoosterExpiredNotificationType extends AbstractNotificationType {

    static readonly TYPE = 64;

    protected buttons:NotificationButton[] = [
        new NotificationButton('reactivate', () => {
            return this.router.navigate(['home'], {queryParams: {expired: 1}});
        })
    ];

    getMetaData(): {} {
        return {
            boosterName: this.notification.getMetaData().boosterName
        };
    }

    getIcon(): string {
        return 'fa-rocket';
    }
}
