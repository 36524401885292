import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractPaymentComponent} from '../abstract-payment.component';

@Component({
    selector: 'app-pre-authorization',
    templateUrl: './pre-authorization.component.html',
    styleUrls: ['./pre-authorization.component.scss']
})
export class PreAuthorizationComponent extends AbstractPaymentComponent implements OnInit {

    @ViewChild('iframe') iframe: ElementRef;

    private action: string;

    ngOnInit() {
        if (this.currentUserService.getAccount().getProfile().isCertified()) {
            this.router.navigateByUrl('/account');
            return;
        }
        this.iFrameEvent();

        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.action = params.get('action') || 'certification';

            this.billingService.paymentPreAuth({
                mode: 'freemium',
                action: this.action,
                ga: this.currentUserService.getGa()
            }).subscribe((response: any) => {
                if (response.success) {
                    this.setPaymentUrl(response.data.paymentUrl);
                }
            });
        });
    }

    setPaymentUrl(url: string) {
        super.setPaymentUrl(url);
        if (this.iframe.nativeElement && this.iframe.nativeElement.contentWindow) {
            this.iframe.nativeElement.contentWindow.location.replace(url);
        }
    }
}
