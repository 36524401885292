import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {FaqQuestion} from './faq-question';

@Injectable({
    providedIn: 'root'
})
export class FaqTheme implements Deserializable {

    id: number;
    name: string;
    icon: string;
    order: number;
    questions: FaqQuestion[] = [];

    deserialize(input: any): this {
        Object.assign(this, input);

        for (const question of input.faq) {
            this.questions.push(new FaqQuestion().deserialize(question));
        }
        return this;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getIcon(): string {
        return this.icon;
    }

    getOrder(): number {
        return this.order;
    }

    getQuestions(): FaqQuestion[] {
        return this.questions;
    }
}
