import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Offer implements Deserializable {

    static readonly COIN_SUBTYPE = 'lovecoin';

    static readonly FREE_TRIAL = 1;
    static readonly TRIAL = 2;
    static readonly UNIQUE_PAYMENT = 6;
    static readonly PRE_AUTH = 9;
    static readonly IS_TRIAL = [Offer.FREE_TRIAL, Offer.TRIAL];

    id: number;
    price: number;
    renewalPeriod: number;
    trialPeriod: number;
    alternativePayments: boolean;
    type: number;
    subtype: string;
    currency: string;
    coinAmount: number;
    coinFreeAmount: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getId(): number {
        return this.id;
    }

    getPrice(): number {
        return this.price;
    }

    getType(): number {
        return this.type;
    }

    isAlternativePayment(): boolean {
        return this.alternativePayments;
    }

    isLoveCoin(): boolean {
        return this.subtype === Offer.COIN_SUBTYPE;
    }

    getFormattedPrice(): string {
        const currency = ' ' + this.currency;
        let value: any = parseFloat(this.price + '');

        if (isNaN(value)) {
            return this.price + currency;
        }
        value = value.toFixed(2);

        const x = value.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1\'$2');
        }

        return (x1 + x2 + currency).replace('-', '');
    }

    getRenewalPeriod(): number {
        return this.renewalPeriod / 30;
    }

    getTrialPeriod(): number {
        return this.trialPeriod;
    }

    isTrial(): boolean {
        return Offer.IS_TRIAL.includes(this.type);
    }

    isPreAuth(): boolean {
        return this.type === Offer.PRE_AUTH;
    }

    getCoinAmount(): number {
        return this.coinAmount;
    }

    getCoinFreeAmount(): number {
        return this.coinFreeAmount;
    }
}
