import {Component} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from '../../../models/account/subscription';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {

    reasons = ['not_interested', 'service_too_expensive', 'no_dating', 'not_satisfied', 'other_reason_justify', 'dont_want_to_answer'];
    reasonSelected: string = '';
    comment: string;
    step: number = 1;
    subscription: Subscription;

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
        private currentUser: CurrentUserService
    ) {
        this.subscription = this.currentUser.getAccount().getSubscription();
    }

    switchReason(reasonSelected: string) {
        this.reasonSelected = reasonSelected;
    }

    isChecked(reason: string) {
        return this.reasonSelected == reason;
    }

    unsubscribe() {
        let reason: string = (this.reasonSelected !== 'other_reason_justify')
            ? this.translate.instant('reason_list.' + this.reasonSelected)
            : this.comment;

        this.currentUser.unsubscribe(reason).subscribe((response: any) => {
            if (response && !response.success) {
                this.toastr.error(this.translate.instant('error_list.default'));
            } else {
                this.subscription.setUnsubscribed(true);
                this.step = 2;
            }
        }, () => {
            this.toastr.error(this.translate.instant('error_list.default'));
        });
    }
}
