import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal/modal.service';
import {SearchComponent} from '../search/search.component';
import {CurrentUserService} from '../../services/user/current-user.service';
import {ProfileService} from '../../services/profile/profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FiltersService} from '../../services/filters/filters.service';
import {SiteService} from '../../services/site/site.service';
import {ChatUserService} from '../../services/chat/chat-user/chat-user.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinWarningComponent} from '../modal/coin-warning/coin-warning.component';

@Component({
    templateUrl: '../search/search.component.html',
    styleUrls: ['../search/search.component.scss']
})
export class HomeComponent extends SearchComponent implements OnInit {

    constructor(
        protected currentUser: CurrentUserService,
        protected filtersService: FiltersService,
        protected profileService: ProfileService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected siteService: SiteService,
        protected modalService: ModalService,
        protected dialog: MatDialog,
        protected chatUserService: ChatUserService
    ) {
        super(currentUser, filtersService, profileService, router, route, siteService, modalService, dialog, chatUserService);
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe((params) => {
            this.setTab(params.get('tab') || 'online');
            this.cleanAndSearchProfiles();
        });
        if (this.route.snapshot.queryParams['booster'] && this.route.snapshot.queryParams['booster'] === '1') {
            this.modalService.openBooster().afterClosed().subscribe(result => {
                if (result && result.offer) {
                    this.dialog.open(CoinWarningComponent, {data: {offer: result.offer}});
                }
            });
        }
        if (!this.currentUser.freemiumProfileIsConfirmed()) {
            this.modalService.openFinishSignup().afterClosed().subscribe(() => {
                // Refresh the wall
                this.refreshProfiles();

                if (this.currentUser.getAccount().showHomepageUpsellUnlover()) {
                    this.modalService.openUpsellXponsorUnlover();
                    this.currentUser.getAccount().setHomepageUpsellUnlover(false);
                }
            });
        } else if (this.currentUser.getAccount().showHomepageUpsellUnlover() && !this.currentUser.isHardBounced()) {
            this.modalService.openUpsellXponsorUnlover();
            this.currentUser.getAccount().setHomepageUpsellUnlover(false);
        }
    }
}
