import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {WINDOW} from '../../app.config';
import {Offer} from '../../models/offer/offer';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../modal/coin-payment/coin-payment.component';

@Component({
    selector: 'app-payment-refused',
    templateUrl: './payment-refused.component.html',
    styleUrls: ['./payment-refused.component.scss']
})
export class PaymentRefusedComponent extends BaseComponent implements OnInit {

    offer: Offer;
    isFreeTrial = false;
    isPreAuthorization = false;

    constructor(
        injector: Injector,
        @Inject(WINDOW) private window: Window,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router
    ) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const freeTrial = params.has('freetrial') ? params.get('freetrial') : '0';
            this.isFreeTrial = freeTrial === '1';
            this.isPreAuthorization = params.has('preauth') && params.get('preauth') === '1';

            if (window.top.location !== location) {
                const preAuthQueryParam = this.isPreAuthorization ? '&preauth=1' : '';
                this.window.parent.postMessage({
                    action: 'redirect',
                    url: `paymentRefused?id_offer=${params.get('id_offer')}&freetrial=${freeTrial}${preAuthQueryParam}`
                }, '*');
            }

            this.siteService.getOffer(+params.get('id_offer')).subscribe((response: any) => {
                if (response.success) {
                    this.offer = new Offer().deserialize(response.data);
                }
            });
        });
    }

    goToPayment() {
        if (this.offer && this.offer.isLoveCoin()) {
            this.dialog.open(CoinPaymentComponent, {
                panelClass: 'modal-box',
                maxWidth: '100%',
                data: {action: 'payment_refused'}
            });
        } else if (this.offer.isPreAuth()) {
            this.router.navigate(['/payment/certification'], {
                queryParams: {
                    action: 'payment_refused'
                }
            });
        } else {
            this.router.navigate(['/payment'], {
                queryParams: {
                    action: 'payment_refused'
                }
            });
        }
    }

    getType(): string {
        if (this.isPreAuthorization) {
            return 'preauth';
        }

        if (this.isFreeTrial) {
            return 'trial';
        }

        return 'payment';
    }

    getUpsellRoute() {
        return ['/upsell/live'];
    }
}
