<div id="chat-zm" class="container-fluid main-section m-0 w-100">
    <div class="row">
        <div [hidden]="collapsed.navLeft" class="col-md-3 col-12 sidebar discussions" id="sidebar-left">
            <div class="connectes" style="height: calc(98vh - 131px)" (scroll)="onScrollRooms($event)">
                <ul>
                    <li class="pt-2">
                        <div class="post-divider">
                            <hr>
                            <span>{{ 'chat.talk' | translate }}
                                ({{ getCurrentRoomsCount() + '/' + chatRoomService.getRoomsCount() }})</span>
                        </div>
                    </li>
                </ul>
                <app-chat-rooms-list [limit]="maxRooms"></app-chat-rooms-list>
            </div>
        </div>

        <div class="col chatroom" id="content">
            <div class="row">
                <!-- Onglets -->
                <div class="utilisateur">
                    <div *ngIf="chatRoomService.hasCurrentRoom()">
                        <div class="users-img">
                            <app-user-photo [user]="getCurrentRoom().getFriendUser()"></app-user-photo>
                            <span class="fa-stack"
                                  [ngClass]="getCurrentRoom().getFriendUser().getActivity().getDynamicClass()"></span>
                        </div>
                    </div>
                    <div class="users-detail text-truncate">
                        <a>
                            <p *ngIf="chatRoomService.hasCurrentRoom()">
                                <span
                                    [class.blurred]="hasRule('nicknameBlur')">{{getCurrentRoom().getFriendUser().getUsername()}}</span>
                                <i class="fas pl-2" [ngClass]="{
                                    'fa-mars hom': getCurrentRoom().getFriendUser().getGender() === 1,
                                    'fa-venus fem': getCurrentRoom().getFriendUser().getGender() === 2,
                                    'fa-venus-mars cpl': getCurrentRoom().getFriendUser().getGender() === 3
                                }"></i>
                                <br>
                                <small>
                                    <span>{{ getCurrentRoom().getFriendUser().getActivity().getDynamicText() | translate }}</span>
                                </small>
                            </p>
                            <p *ngIf="!chatRoomService.hasCurrentRoom()">
                                {{ 'chat.no_talk' | translate }}
                            </p>
                        </a>
                        <div class="icons text-right" *ngIf="chatRoomService.hasCurrentRoom()">
                            <button id="button-action-conversation" (click)="toggleActionConversation()"
                                    class="btn mr-2"><i class="fas fa-ellipsis-h"></i></button>
                            <div (clickOutside)="shutdownActionConversation($event)" *ngIf="showActionConversation"
                                 id="menu-user-dial3" class="menu-user-dial in profile-action"
                                 style="height: auto;">
                                <app-chat-user-action [user]="getCurrentRoom().getFriendUser()"></app-chat-user-action>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row disable-select" (contextmenu)="onContextMenu($event)">
                <div class="noRoom h-100 w-100 text-center"
                     *ngIf="!chatRoomService.hasCurrentRoom() && !this.chatRoomService.hasRoomsToDisplay()">
                    <div class="col-12">
                        <h2 class="h1 mt-5 pr-0">
                            <i class="far fa-comment ico-status"></i><br>
                            {{ 'chat.no_discussion' | translate }}
                        </h2>
                        <p>{{ 'chat.browse_online_members_to_open_discussion' | translate }}</p>
                    </div>
                </div>
                <div *ngIf="chatRoomService.hasCurrentRoom()" class="col-md-12 dialogue"
                     style="height: calc(98vh - 340px);"
                     id="messages" #messageContainer (scroll)="onScrollMessages($event)">

                    <div class="loadmoreusers-container" *ngIf="hasMoreMessages()">
                        <a [hidden]="loadMoreMessagesLoading" (click)="loadMoreMessages()"
                           class="loadmoreusers">{{ 'chat.load_more_messages' | translate }}</a>
                        <span [hidden]="!loadMoreMessagesLoading" class="loadmoreusersloader"><i
                            class="fa fa-spinner fa-pulse"></i></span>
                    </div>

                    <app-room-messages></app-room-messages>
                </div>
            </div>
            <div *ngIf="chatRoomService.hasCurrentRoom()" class="row mt-2">
                <div class="col-6 text-left">
                    <span *ngIf="getCurrentRoom().isTyping()" class="wow animated fadeIn infinite d-block animated"
                          style="margin-top: 20px; color: rgb(189, 189, 194); visibility: visible; animation-name: fadeIn;">
                        <span
                            [class.blurred]="hasRule('nicknameBlur')">{{ getCurrentRoom().getFriendUser().getUsername() }}</span> {{ 'chat.is_writing' | translate }}
                    </span>
                </div>
                <div class="col-12 text-right">
                    <div class="btn-group" role="group" aria-label="Controls">
                        <button (click)="toggleCollapse('navLeft')" type="button"
                                class="btn btn-default toggle-sidebar-left"><i
                            class="fas fa-chevron-circle-left"></i></button>
                        <button (click)="toggleCollapse('navRight')" type="button"
                                class="btn btn-default toggle-sidebar-right ml-1"><i
                            class="fas fa-chevron-circle-right"></i></button>
                        <button (click)="toggleEmojis()" class="btn btn-default ml-1" type="button"><i
                            class="far fa-smile fa-2x"></i></button>
                    </div>
                    <app-emoji [toggleEvent]="toggleEmojisSubject.asObservable()"></app-emoji>
                </div>
            </div>

            <app-restriction-banner *ngIf="chatRoomService.hasCurrentRoom()"></app-restriction-banner>

            <div *ngIf="chatRoomService.hasCurrentRoom() && !useBanner()" class="row pt-3 pb-3">
                <div class="col-12">
                    <app-chat-message-form></app-chat-message-form>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-12 sidebar" [hidden]="collapsed.navRight" id="sidebar-right">
            <div class="input-group searchbox">
                <input [(ngModel)]="search" (keyup)="updateSearch()" class="form-control bg-white"
                       autocomplete="off" [placeholder]="'chat.search' | translate" name="search" id="srch-term"
                       type="text">
                <div class="input-group-btn">
                    <button class="btn btn-default search-icon" type="submit"><i class="fa fa-search"></i></button>
                </div>

                <div class="ml-1">
                    <button id="button-action-change-status" (click)="toggleChangeStatus()"
                            class="btn bg-white collapsed" type="button"><i class="fa fa-cog"></i></button>
                    <div class="filter-action">
                        <div *ngIf="showChangeStatus" (clickOutside)="shutdownChangeStatus($event)"
                             class="card card-body options">
                            <form>
                                <div class="form-group text-left">
                                    <label for="user_status"
                                           class="font-weight-bold">{{ 'my_status' | translate }}</label>
                                    <select [(ngModel)]="status" name="status" (change)="changeStatus()"
                                            class="form-control" id="user_status">
                                        <option [ngValue]="1" selected="selected">{{ 'online' | translate }}</option>
                                        <option [ngValue]="3">{{ 'do_not_disturb' | translate }}</option>
                                        <option [ngValue]="4">{{ 'away' | translate }}</option>
                                    </select>
                                </div>

                                <div *ngIf="showFilterGender" class="form-group text-left">
                                    <label class="font-weight-bold">{{ 'chat.filter_users' | translate }}</label>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                               type="checkbox" id="defaultCheck1">
                                        <label class="form-check-label" for="defaultCheck1">
                                            {{ 'chat.display_women' | translate }}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                               type="checkbox" id="defaultCheck2">
                                        <label class="form-check-label" for="defaultCheck2">
                                            {{ 'chat.display_men' | translate }}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input" type="checkbox"
                                            id="defaultCheck3">
                                        <label class="form-check-label" for="defaultCheck3">
                                            {{ 'chat.display_couples' | translate }}
                                        </label>
                                    </div>
                                </div>
                                <button *ngIf="showFilters"
                                        class="btn btn-primary btn-lg btn-block p-3 text-white collapsed" type="button"
                                        data-toggle="collapse" data-target="#collapseExample"
                                        aria-expanded="false"
                                        aria-controls="collapseExample">{{ 'validate' | translate }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="connectes" style="height: calc(98vh - 190px);" (scroll)="onScrollUsers($event)">
                <app-chat-users-connected *ngIf="getFilteredContacts().length > 0"
                                          title="{{ 'chat.online_contacts' | translate }}"
                                          [users]="getFilteredContacts()"></app-chat-users-connected>
                <app-chat-users-connected title="{{ 'chat.online_users' | translate }}"
                                          [users]="getFilteredUsers()"></app-chat-users-connected>
                <div class="loadmoreusers-container">
                    <a [hidden]="loadMoreUsersLoading" (click)="loadMoreUsers()"
                       class="loadmoreusers">{{ 'chat.load_more_users' | translate }}</a>
                    <span [hidden]="!loadMoreUsersLoading" class="loadmoreusersloader"><i
                        class="fa fa-spinner fa-pulse"></i></span>
                </div>
            </div>
        </div>

        <div id="panel-mobile" class="container main-section nav-chat-resp text-center mt-1 p-2">
            <a href=""><i class="fas fa-users fa-2x mr-5 text-dark"></i></a>
            <a href=""><i class="fas fa-comment fa-2x text-dark"></i></a>
        </div>
    </div>
</div>
