<div *ngIf="isVisible()" id="filter-panel" class="filter-panel collapse show">
    <a class="close" (click)="hide()" aria-expanded="true" data-toggle="collapse">
        <i class="fas fa-times"></i>
    </a>
    <div class="card">
        <div class="card-body">
            <form (ngSubmit)="submit()">
                <div class="row no-gutters filtres-recherche">
                    <div class="col-12">
                        <h6>{{ 'advanced_search' | translate }}</h6>
                        <div class="form-group">
                            <input [(ngModel)]="getFilters().nickname" name="pseudo" type="text"
                                   [placeholder]="'search_filter.input_pseudo' | translate" class="form-control"/>
                        </div>
                        <div class="form-group w-100">
                            <div class="custom-control custom-checkbox mb-2">
                                <input [(ngModel)]="getFilters().withPhoto" type="checkbox" class="custom-control-input"
                                       id="avecphoto" name="avecphoto"/>
                                <label class="custom-control-label"
                                       for="avecphoto">{{ 'search_filter.photo' | translate }}</label>
                            </div>
                            <!--
                            <div class="custom-control custom-checkbox mb-2">
                                <input type="checkbox" class="custom-control-input" id="avecvideo" name="avecvideo">
                                <label class="custom-control-label" for="avecvideo">Avec vidéo</label>
                            </div>
                            -->
                        </div>

                        <hr>

                        <h6>{{ 'ideal_partner_is' | translate: translateGender() }}</h6>

                        <div class="form-group mt-4">
                            <label>{{ 'aged_between' | translate: translateGender() }}</label>

                            <div class="row">
                                <div class="col-12">
                                    <nouislider (update)="rangeUpdate()" name="age" [connect]="true" [min]="18"
                                        [max]="100" [step]="1" [(ngModel)]="getFilters().age"></nouislider>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <label>{{ 'search_filter.localisation' | translate }}</label>

                            <div class="row">
                                <div class="col-12 search-filter-city">
                                    <app-city-typeahead [selectedCityObject]="getFilters().city" [useLoader]=true
                                                        [useGeolocation]=true [geolocIcon]="'map-marker-alt'"
                                                        [geolocTooltip]=true (onSelectCity)="onSelectCity($event)"
                                                        (onErrorUnpicked)="onCityErrorUnpicked($event)"
                                                        (onUnselectCity)="onUnselectCity()"></app-city-typeahead>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-4" *ngIf="getFilters().city">
                            <label>{{ 'search_filter.distance' | translate }}:</label>
                            <select class="form-control" name="distance" [(ngModel)]="getFilters().distance">
                                <option value="">{{ 'whatever' | translate }}</option>
                                <option [value]="distance" *ngFor="let distance of getDistanceOptions()">{{ distance }} km</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row ok-filtres">
                    <div class="col-12">
                        <button [disabled]="!canSubmit" type="submit"
                                class="btn btn-primary w-100 btn-sm mt-3">{{ 'search_filter.apply' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
