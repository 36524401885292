<div id="error403">
    <h2 class="well text-center">
        {{ 'secure_payment_redirecting' | translate }}
    </h2>

    <div class="modal-body">
        <div id="spin">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <p *ngIf="redirectUrl">
            {{ 'if_payment_page_broken' | translate }},
            <a [href]="redirectUrl | sanitize" target="_blank">
                {{ 'click_here_unlimited_access' | translate }}
            </a>
        </p>
    </div>
</div>
