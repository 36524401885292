import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {CamModel} from '../../../models/cam-model/cam-model';
import {SexCamsService} from '../../../services/sex-cams/sex-cams.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DnxService} from '../../../services/dnx/dnx.service';
import {SexCamsFilters} from '../../../models/account/settings/sex-cams-filters';
import {CamMessage} from '../../../models/sex-cams/cam-message/cam-message';
import {ModalService} from '../../../services/modal/modal.service';
import {HelperService} from '../../../services/helper/helper.service';
import * as $ from 'jquery';
import {environment} from '../../../../environments/environment';
import {SexCamShowStepService} from '../../../services/sex-cam-show-step/sex-cam-show-step.service';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../../modal/coin-payment/coin-payment.component';
declare function Connector(): any;

@Component({
    selector: 'app-cam-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss']
})
export class ShowComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    private lastScrollHeight: number = null;
    private errorSubscriber: Subscription;

    /**
     * If the show changes from FREE to VIP, from VIP to PEEPING or from FREE to PEEPING:
     * Show a popup asking the user before starting the billing.
     */
    requireConfirmation = false;

    sound = true;
    expandVideo = false;

    @ViewChild('messageContainer') messageContainer: ElementRef;

    constructor(
        private sexCamsService: SexCamsService,
        private dnxService: DnxService,
        private currentUser: CurrentUserService,
        private modalService: ModalService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private helperService: HelperService,
        public showStep: SexCamShowStepService
    ) {
        this.errorSubscriber = this.dnxService.onErrorObservable.subscribe((message: string) => {
            if (!this.getCamModel()) {
                return;
            }

            switch (message) {
                case 'going_private':
                    if (!this.getCamModel().canPayPeepingShow(this.currentUser.getAccount().getCoins())) {
                        return this.openSexCamCoinWarning();
                    }
                    if (!this.showStep.requireConfirmationModal()) {
                        this.showStep.setLoading(false);
                        return this.getCamModel().setPrivateShow(true);
                    }

                    this.showStep.setLoading(true);
                    this.showStep.setPendingConfirmation(true);
                    this.sexCamsService.stopCamShow(true);
                    this.modalService.openSexCamConfirmation({
                        data: {
                            faClass: 'fa-eye',
                            title: 'peeping_mode',
                            message: 'is_in_private_show',
                            question: 'switch_to_peeping_mode',
                            detail: 'peeping_show_price',
                            camModel: this.getCamModel(),
                            callback: (result) => {
                                this.modalService.closeSexCamConfirmation();
                                this.showStep.setPendingConfirmation(false);
                                if (result) {
                                    this.showStep.requestPayedShow();
                                    this.getCamModel().setPrivateShow(true);
                                    return this.fetchModel(this.getCamModel().getId());
                                    // this.showStep.setLoading(false);
                                    // return this.getCamModel().setPrivateShow(true);
                                }
                                this.backToWall();
                            }
                        }
                    });
                    break;
                case 'private_stopped':
                    if (this.getCamModel().isPrivateShow()) {
                        this.getCamModel().setVIPShow();
                    }
                    break;
                case 'private_started':
                    this.getCamModel().setPrivateShow(false);
                    break;
                case 'refresh_hash':
                    if (this.showStep.isPendingConfirmation()) {
                        return;
                    }

                    if (this.getCamModel()) {
                        this.sexCamsService.refreshHash().subscribe(() => {
                            this.fetchModel(this.getCamModel().getId());
                        });
                    } else {
                        this.backToWall();
                    }
                    break;
                case 'retry':
                    if (this.showStep.isPendingConfirmation()) {
                        break;
                    }

                    if (this.getCamModel()) {
                        if (!this.getCamModel().canPayShow(this.currentUser.getAccount().getCoins())) {
                            this.openSexCamCoinWarning(true);
                            this.sexCamsService.stopCamShow();
                        } else {
                            this.fetchModel(this.getCamModel().getId());
                        }
                    } else {
                        this.backToWall();
                    }
                    break;
                case 'offline':
                    this.modalService.openSexCamConfirmation({
                        data: {
                            faClass: 'fa-sad-tear',
                            title: 'Oups...',
                            message: this.getCamModel().isFemale() ? 'was_disconnected_female' : 'was_disconnected',
                            question: 'go_to_next_cam_model',
                            camModel: this.getCamModel(),
                            callback: (result) => {
                                this.modalService.closeSexCamConfirmation();
                                if (result) {
                                    if (environment.dnx.testAccount) {
                                        return this.router.navigate(['/sex-cams', environment.dnx.testAccount]);
                                    }

                                    const nextModel = this.sexCamsService.findNext(this.getCamModel());
                                    if (nextModel) {
                                        return this.router.navigate(['/sex-cams', nextModel.getId()]);
                                    }
                                }
                                this.backToWall();
                            }
                        }
                    });
                    break;
                case 'going_vip':
                    if (!this.getCamModel().canPayVIPShow(this.currentUser.getAccount().getCoins())) {
                        return this.openSexCamCoinWarning();
                    }

                    if (!this.showStep.requireConfirmationModal()) {
                        this.showStep.setLoading(false);
                        return this.joinVipShow();
                    }

                    this.showStep.setLoading(true);
                    this.showStep.setPendingConfirmation(true);
                    this.modalService.openSexCamConfirmation({
                        data: {
                            faClass: 'fa-star',
                            title: 'vip_mode',
                            message: 'is_in_vip_show',
                            question: 'switch_to_vip',
                            detail: 'vip_show_price',
                            camModel: this.getCamModel(),
                            callback: (result) => {
                                this.modalService.closeSexCamConfirmation();
                                this.showStep.setPendingConfirmation(false);
                                if (result) {
                                    this.showStep.requestPayedShow();
                                    this.showStep.setLoading(false);
                                    return this.joinVipShow();
                                }
                                this.backToWall();
                            }
                        }
                    });
                    break;
                case 'update_cam_model':
                    this.sexCamsService.fetchByAccount(this.getCamModel().getId()).subscribe((result: any) => {
                        if (this.sexCamsService.currentCamModel) {
                            this.sexCamsService.currentCamModel.coinsByMinute = result.data.model.coinsByMinute;
                            this.sexCamsService.currentCamModel.ALLOW_VOYEURS = result.data.model.ALLOW_VOYEURS;
                        }
                    }, () => {
                        this.backToWall();
                    });
                    break;
            }
        });

        this.route.paramMap.subscribe((param) => {
            this.requireConfirmation = false;
            this.showStep.init();
            this.fetchModel(environment.dnx.testAccount ? environment.dnx.testAccount : param.get('account'));
        });
    }

    getCamModel(): CamModel {
        return this.sexCamsService.currentCamModel;
    }

    getSexCamsService(): SexCamsService {
        return this.sexCamsService;
    }

    getMessages(): CamMessage[] {
        return this.dnxService.messages;
    }

    getUnreadMessage(): string|number {
        const count = this.dnxService.unreadMessage;
        return count > 9 ? '9+' : count;
    }

    getVisitorCount(): number {
        return this.dnxService.visitorCount;
    }

    getFilters(): SexCamsFilters {
        return this.currentUser.getAccount().getSettings().sexCamFilters;
    }

    sendMessage() {
        if (this.isPrivateChatRoom() || this.getCamModel().isPrivateShow()) {
            Connector().sendPrivateText(this.sexCamsService.chatMessage);
        } else {
            Connector().sendPublicText(this.sexCamsService.chatMessage);
        }
        this.sexCamsService.chatMessage = '';
    }

    isPrivateChatRoom(): boolean {
        return this.dnxService.privateChatRoom;
    }

    setPrivateChatRoom(status: boolean) {
        if (this.dnxService.privateChatRoom !== status) {
            this.dnxService.unreadMessage = 0;
            this.dnxService.privateChatRoom = status;
        }
    }

    showMessage(message: CamMessage): boolean {
        if ((this.isPrivateChatRoom() || this.getCamModel().isPrivateShow()) && message.isPrivate()) {
            return true;
        }

        return !this.isPrivateChatRoom() && !this.getCamModel().isPrivateShow() && message.isPublic();
    }

    toggleFavorite() {
        if (this.getCamModel().isFavorite) {
            this.getCamModel().isFavorite = false;
            this.sexCamsService.removeFromFavorite(this.getCamModel());
        } else {
            this.getCamModel().isFavorite = true;
            this.sexCamsService.addToFavorite(this.getCamModel());
        }
    }

    joinVipShow(click = false) {
        if (click) {
            this.showStep.requestPayedShow();
        }
        if (this.getCamModel().canPayVIPShow(this.currentUser.getAccount().getCoins())) {
            Connector().enterGroup();
            this.getCamModel().setVIPShow();
            this.showStep.setLoading(false);
        } else {
            this.openSexCamCoinWarning();
        }
    }

    joinPrivateShow() {
        this.showStep.requestPayedShow();
        if (this.getCamModel().canPayPrivateShow(this.currentUser.getAccount().getCoins())) {
            Connector().enterPrivate();
            this.showStep.setLoading(false);
        } else {
            this.openSexCamCoinWarning();
        }
    }

    toggleSound() {
        const video = $('#rtc-video');
        if (video) {
            video[0].muted = this.sound;
        }
        this.sound = !this.sound;
    }

    toggleFullscreen() {
        this.expandVideo = !this.expandVideo;
    }

    openDetailModal() {
        this.modalService.openCamModelDetail(this.getCamModel());
    }

    ngAfterViewInit() {
        const video = $('#rtc-video')[0];

        video.muted = !this.sound;

        video.onplaying = _ => {
            this.showStep.nextStep();
            setTimeout(() => this.updateLastVideoFrame(), 2000);
        };
        video.onresume = _ => {
            this.showStep.setPlaying(true);
        };
        video.onpause = _ => {
            this.showStep.setPlaying(false);
        };
        video.onclose = _ => {
            this.showStep.setPlaying(false);
        };
        video.onerror = _ => {
            this.showStep.setPlaying(false);
        };
    }

    ngAfterViewChecked(): void {
        /**
         * Auto scroll to bottom when height changed
         */
        if (this.messageContainer) {
            if (this.lastScrollHeight !== this.messageContainer.nativeElement.scrollHeight) {
                this.lastScrollHeight = this.messageContainer.nativeElement.scrollHeight;
                this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
            }
        }
    }

    private fetchModel(account: string = null) {
        this.showStep.setPlaying(false);

        if (!this.getCamModel() || this.getCamModel().getId() !== account) {
            this.dnxService.visitorCount = 1;
            this.dnxService.messages = [];
        } else {
            this.showStep.setLoading(true);
        }

        this.sexCamsService.fetchByAccount(account).subscribe((result: any) => {
            this.sexCamsService.currentCamModel = new CamModel().deserialize(result.data.model);
            const coins = this.currentUser.getAccount().getCoins();

            if ((this.getCamModel().isVIPShow() && !this.getCamModel().canPayVIPShow(coins)) ||
                (this.getCamModel().isPrivateShow() && !this.getCamModel().canPayPeepingShow(coins))) {
                this.openSexCamCoinWarning();
                this.sexCamsService.stopCamShow();

                return this.backToWall();
            }

            if (!this.dnxService.connectModel(this.getCamModel(), result.data.visitorHash)) {
                return this.backToWall();
            }

            this.sexCamsService.startCamShow();

            this.showStep.setLoading(false);
        }, () => {
            this.backToWall();
        });
    }

    openLoveCoinModal() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'sex_cams'}
        });
    }

    private openSexCamCoinWarning(videoOngoingContext = false) {
        const modal = this.modalService.openSexCamCoinWarning(this.getCamModel(), videoOngoingContext);
        if (!modal) {
            return;
        }

        modal.afterClosed().subscribe((result) => {
            if (result || videoOngoingContext) {
                this.router.navigateByUrl('/sex-cams').then(() => {
                    if (result) {
                        this.dialog.open(CoinPaymentComponent, {
                            panelClass: 'modal-box',
                            maxWidth: '100%',
                            data: {action: 'coin_warning'}
                        });
                    }
                    setTimeout(() => {
                        window.scroll(0, 0);
                    }, 500);
                });
            }
            this.modalService.closeSexCamConfirmation();
        });
    }

    private updateLastVideoFrame() {
        if (this.getCamModel() && this.showStep.isPlaying()) {
            this.sexCamsService.lastVideoFrame = this.helperService.getVideoSnapshotData('rtc-video');
            if (this.sexCamsService.lastVideoFrame) {
                setTimeout(() => this.updateLastVideoFrame(), 2000);
            }
        }
    };

    private backToWall() {
        this.router.navigateByUrl('/sex-cams');
    }

    ngOnDestroy() {
        this.errorSubscriber.unsubscribe();
    }
}
