import {Component, Inject, OnInit, Optional} from '@angular/core';
import {StorageService} from '../../../../../services/storage/storage.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../../../services/profile/profile.service';

@Component({
    selector: 'app-xponsor-unlover',
    templateUrl: './unlover.component.html',
    styleUrls: ['./unlover.component.scss']
})
export class UpsellXponsorUnloverComponent implements OnInit {

    title: string;
    faClass: string;
    show: boolean;
    success: boolean;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private storageService: StorageService,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<UpsellXponsorUnloverComponent>,
        private profileService: ProfileService
    ) {
        this.show = true;
        this.success = false;
    }

    ngOnInit() {
        this.dialogRef.beforeClosed().subscribe(() => {
            const now = new Date();
            this.storageService.set('upsellXponsorUnlover', now.toString());
        });
    }

    successEmit(success: boolean) {
        this.success = success;
        this.show = false;
    }

    onRefuse() {
        this.profileService.putProfile({
            unloverDisplayPopup: false
        }).subscribe();
    }
}
