<!-- Lightbox finish-signup -->
<div id="finish-pseudo" class="finish lightbox wow animated fadeIn show" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <ul class="progressbar d-none d-md-block">

            <li *ngIf="fieldsStatus.gender !== 'provided'" [class.active]="onboardingStep >= 1">{{ genderStepLabel }}</li>
            <li *ngIf="fieldsStatus.searchedGender !== 'provided'" [class.active]="onboardingStep >= 2">{{ searchedGenderStepLabel }}</li>
            <li *ngIf="fieldsStatus.nickname !== 'provided'" [class.active]="onboardingStep >= 3">{{ nicknameStepLabel }}</li>
            <li *ngIf="fieldsStatus.password !== 'provided'" [class.active]="onboardingStep >= 4">{{ passwordStepLabel }}</li>
            <li *ngIf="fieldsStatus.birthDate !== 'provided'" [class.active]="onboardingStep >= 5">{{ birthdateStepLabel }}</li>
            <li *ngIf="fieldsStatus.city !== 'provided' || fieldsStatus.postalCode !== 'provided'" [class.active]="onboardingStep >= 6">{{ localisationStepLabel }}</li>
            <li *ngIf="!hasPhoto" [class.active]="onboardingStep >= 7">{{ photoStepLabel }}</li>

            <li [class.active]="onboardingStep === 8"><i class="fas fa-check"></i></li>
        </ul>
        <div class="modal-content d-flex justify-content-center">

            <div class="modal-header">
                <h5 class="modal-titre text-truncate">
                    <i class="fas fa-user-lock"></i> {{ 'on_boarding.finalization_registration' | translate }}
                </h5>
            </div>

            <app-finish-signup-gender *ngIf="showGenderStep()" (nextStep)="next()"></app-finish-signup-gender>
            <app-finish-signup-searched-gender [currentStep]="searchedGenderStepLabel" *ngIf="showSearchGenderStep()" (previousStep)="previous()" (nextStep)="next()"></app-finish-signup-searched-gender>
            <app-finish-signup-nickname [stepCacheData]="stepCacheData" (setStepCache)="setStepCache($event)" [currentStep]="nicknameStepLabel" *ngIf="showNicknameStep()" (previousStep)="previous()" (nextStep)="next()"></app-finish-signup-nickname>
            <app-finish-signup-password [stepCacheData]="stepCacheData" (setStepCache)="setStepCache($event)" [currentStep]="passwordStepLabel" *ngIf="showPasswordStep()" (previousStep)="previous()" (nextStep)="next()"></app-finish-signup-password>
            <app-finish-signup-birthdate [stepCacheData]="stepCacheData" (setStepCache)="setStepCache($event)" [currentStep]="birthdateStepLabel" *ngIf="showBirthdateStep()" (previousStep)="previous()" (nextStep)="next()"></app-finish-signup-birthdate>
            <app-finish-signup-location [stepCacheData]="stepCacheData" (setStepCache)="setStepCache($event)" [currentStep]="localisationStepLabel" *ngIf="showLocalisationStep()" (previousStep)="previous()" (nextStep)="next()"></app-finish-signup-location>
            <app-finish-signup-photo [currentStep]="photoStepLabel" *ngIf="showPhotoStep()" (previousStep)="previous()" (finalStep)="showFinalStep()"></app-finish-signup-photo>

            <app-finish-signup-confirmation *ngIf="showConfirmation()"></app-finish-signup-confirmation>
        </div>
    </div>
</div>

