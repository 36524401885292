import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {Rules} from './rules';
import {Banner} from './banner';

@Injectable({
    providedIn: 'root'
})
export class RuleSet implements Deserializable {

    static readonly COIN_MODE = 'coin';
    static readonly SUBSCRIPTION_MODE = 'subscription';

    mode = RuleSet.SUBSCRIPTION_MODE;
    rules: Rules;
    banner: Banner = null;
    footerAlert: Banner = null;

    deserialize(data: any): this {
        Object.assign(this, data);

        if (data && data.rules) {
            this.rules = new Rules().deserialize(data.rules);
        }

        if (data && data.banner) {
            this.banner = new Banner().deserialize(data.banner);
        }

        if (data && data.footerAlert) {
            this.footerAlert = new Banner().deserialize(data.footerAlert);
        }

        return this;
    }

    getRules(): Rules {
        return this.rules;
    }

    getBanner(): Banner {
        return (this.rules && this.rules.requireSubscription && this.banner) ? this.banner : null;
    }

    getFooterAlert(): Banner {
        return (this.rules && this.rules.footerAlert && this.footerAlert) ? this.footerAlert : null;
    }

    isCoin(): boolean {
        return this.mode === RuleSet.COIN_MODE;
    }

    isSubscription(): boolean {
        return this.mode === RuleSet.SUBSCRIPTION_MODE;
    }

    hasSameRulesAs(ruleSet): boolean {
        if (!ruleSet) {
            return true;
        }

        if (ruleSet.mode !== this.mode) {
            return false;
        }

        if (!ruleSet.rules || !this.rules) {
            return false;
        }

        if (ruleSet.rules.requireSubscription !== this.rules.requireSubscription) {
            return false;
        }
        if (ruleSet.rules.chatBlur !== this.rules.chatBlur) {
            return false;
        }
        if (ruleSet.rules.photoBlur !== this.rules.photoBlur) {
            return false;
        }
        if (ruleSet.rules.nicknameBlur !== this.rules.nicknameBlur) {
            return false;
        }
        if (ruleSet.rules.detailBlur !== this.rules.detailBlur) {
            return false;
        }
        if (ruleSet.rules.blockProfileAccess !== this.rules.blockProfileAccess) {
            return false;
        }

        return ruleSet.rules.footerAlert === this.rules.footerAlert;
    }
}
