import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class AcceptedPhotoNotificationType extends AbstractNotificationType {

    static readonly TYPE = 21;

    protected buttons:NotificationButton[] = [
        new NotificationButton('my_albums', () => {
            this.goTo('profile', null, 'albums');
        })
    ];

    getIcon(): string {
        return 'fa-images';
    }
}
