import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {WINDOW} from '../../app.config';

@Component({
  selector: 'app-payment-already-accepted',
  templateUrl: './payment-already-accepted.component.html',
  styleUrls: ['./payment-already-accepted.component.scss']
})
export class PaymentAlreadyAcceptedComponent extends BaseComponent implements OnInit {

    constructor(injector: Injector, @Inject(WINDOW) private window: Window) {
        super(injector);
    }

    ngOnInit() {
        if (window.top.location !== location) {
            this.window.parent.postMessage({action: 'redirect', url: 'paymentAlreadyAccepted'}, '*');
        }
    }
}
