<div class="card border-0 box mb-4 h-100">
    <div class="card-header">
        <h2 class="d-none d-lg-block pr-0"><i class="fas fa-comment mr-2 text-primary"></i> {{ 'support_chat.title' | translate }}</h2>
        <h2 class="d-block d-lg-none pr-0"><i class="fas fa-comment mr-2 text-primary"></i> {{ 'support_chat.title_mobile' | translate }}</h2>
    </div>
    <div class="card-body">
        <p>{{ getSupportChatContact() }}</p>

        <div class="w-100 text-right mt-2">
            <a *ngIf="!chatIsOpen()" (click)="openChat()" class="btn btn-sm btn-primary same-w" [class.chat-disabled]="!chatIsOnline()">
                {{ 'support_chat.start' | translate }}
            </a>
            <a *ngIf="chatIsOpen()" (click)="closeChat()" class="btn btn-sm btn-primary same-w" [class.chat-disabled]="!chatIsOnline()">
                {{ 'support_chat.end' | translate }}
            </a>

<!--            <button type="submit" class="btn btn-sm btn-primary same-w">Lancer le tchat</button>-->
        </div>
    </div>
</div>







<!--<div class="row">-->
<!--    <div class="col-12 wow fadeInUp">-->
<!--        <h3><i class="fa fa-comments ico ico4 mr-2"></i><span>{{ 'support_chat.contact_us' | translate }}</span></h3>-->
<!--        <hr>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="row text-center">-->
<!--    <div class="col-12 wow fadeInUp" id="chat">-->
<!--        <div class="card">-->
<!--            <div class="card-body p-5">-->
<!--                <span *ngIf="!chatIsLoaded()" class="chat-loading">-->
<!--                    <i class="fa fa-spinner fa-pulse fa-4x"></i>-->
<!--                </span>-->
<!--                <span *ngIf="chatIsLoaded()">-->
<!--                    <i *ngIf="chatIsOnline()" class="fa fa-circle alert-success p-2">-->
<!--                        <span>{{ 'online' | translate }}</span>-->
<!--                    </i>-->
<!--                    <i *ngIf="!chatIsOnline()" class="fa fa-circle alert-danger p-2">-->
<!--                        <span>{{ 'offline' | translate }}</span>-->
<!--                    </i>-->
<!--                    <p class="description">{{ 'support_chat.contact_desc' | translate }}</p>-->
<!--                    <a *ngIf="!chatIsOpen()" (click)="openChat()" class="btn btn-primary btn-lg" [ngClass]="{'chat-disabled' : (!chatIsOnline()) }">-->
<!--                        {{ 'support_chat.start' | translate }}-->
<!--                    </a>-->
<!--                    <a *ngIf="chatIsOpen()" (click)="closeChat()" class="btn btn-primary btn-lg" [ngClass]="{'chat-disabled' : !chatIsOnline() }">-->
<!--                        {{ 'support_chat.end' | translate }}-->
<!--                    </a>-->
<!--                </span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
