<ng-container *ngIf="profile && profileDraft">

    <!-- ----------------------------- -->
    <!-- Looping through each category -->
    <!-- ----------------------------- -->
    <ng-container *ngFor="let category of getCategories()">
        <app-privacy-information *ngIf="!currentProfile && !profile.resolvePrivacy(category)" [profile]="profile" [category]="category"></app-privacy-information>

        <div *ngIf="currentProfile || profile.resolvePrivacy(category)" class="card border-0 box mb-4">
            <div class="card-header">
                <h2>{{ getCategoryTitle(category) }}</h2>

                <app-edit-privacy *ngIf="currentProfile && category !== 'description'" [group]="category" [profile]="profile"></app-edit-privacy>

                <a *ngIf="currentProfile" (click)="init(category)"
                   [attr.aria-controls]="category + '-view ' + category + '-edit'" [attr.data-target]="'.multi-collapse-' + category"
                   data-toggle="collapse" aria-expanded="false" class="btn-edit text-muted cursor-pointer collapsed">
                    <i class="fas fa-edit" [matTooltip]="'change' | translate"></i>
                </a>
            </div>

            <!-- ----------- -->
            <!-- View / Read -->
            <!-- ----------- -->
            <div class="card-body">
                <div [id]="category + '-view'" [ngClass]="'multi-collapse-' + category" class="collapse show">
                    <div class="row">
                        <ng-container *ngIf="tab === 'about' && category === 'description'">
                            <div class="form-group col-lg-6">
                                <label>{{ 'on_boarding.i_am' | translate }}</label>
                                <span>{{ (profile.isMale() ? 'man' : 'woman') | translate }}</span>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>{{ 'profile.about.birthdate' | translate }}</label>
                                <span *ngIf="profile.birthDate">{{ profile.birthDate | date: 'dd/MM/yyyy' }}</span>
                                <span *ngIf="!profile.birthDate">{{ 'choose_an_answer' | translate }}</span>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>{{ 'profile.about.my_postal_code' | translate }}</label>
                                <span *ngIf="profile.postalCode" [class.blurred]="hasRule('detailBlur')">{{ profile.postalCode }}</span>
                                <span *ngIf="!profile.postalCode">{{ 'choose_an_answer' | translate }}</span>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>{{ 'profile.about.my_city' | translate }}</label>
                                <span *ngIf="profile.city" [class.blurred]="hasRule('detailBlur')">{{ profile.city }}</span>
                                <span *ngIf="!profile.city">{{ 'choose_an_answer' | translate }}</span>
                            </div>

                            <div class="form-group col-lg-12">
                                <label>{{ getPresentationLabel() }}</label>
                                <span *ngIf="profile.presentation" [class.blurred]="hasRule('detailBlur')">{{ profile.presentation }}</span>
                                <span *ngIf="!profile.presentation">{{ 'profile.about.no_desc' | translate }}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="tab === 'searching' && category === 'search_criteria'">
                            <div class="form-group col-lg-6">
                                <label>{{ 'you_search' | translate }}</label>
                                <span>{{ (profile.isSearchedGenderMale() ? 'man' : 'woman') | translate }}</span>
                            </div>
                        </ng-container>

                        <app-selected-choice-group [tab]="tab" [category]="category" [profile]="profile" [currentProfile]="currentProfile" class="col-12"></app-selected-choice-group>
                    </div>
                </div>

                <!-- ------- -->
                <!-- Edition -->
                <!-- ------- -->
                <div [id]="category + '-edit'" [ngClass]="'multi-collapse-' + category" class="collapse">
                    <div class="row">
                        <ng-container *ngIf="tab === 'about' && category === 'description'">
                            <div class="form-group col-lg-6">
                                <label>{{ 'on_boarding.i_am' | translate }}</label>
                                <div class="row">
                                    <div class="col-6">
                                        <div [class.disable]="profileDraft.isFemale()" class="custom-radio">
                                            <input [disabled]="profileDraft.isFemale()"  [checked]="profileDraft.isMale()" value="1" type="radio" id="gender-man" name="gender" class="custom-control-input">
                                            <label class="custom-control-label font-weight-normal" for="gender-man">{{ 'searchedGender_list.1' | translate }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="!site.isGay()" class="col-6">
                                        <div [class.disable]="profileDraft.isMale()" class="custom-radio">
                                            <input [disabled]="profileDraft.isMale()" [checked]="profileDraft.isFemale()" value="2" type="radio" id="gender-woman" name="gender" class="custom-control-input">
                                            <label class="custom-control-label font-weight-normal" for="gender-woman">{{ 'searchedGender_list.2' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!profile.getBirthDate()" class="form-group col-lg-6">
                                <label>{{ 'profile.about.birthdate' | translate }}</label>
                                <select [(ngModel)]="birthDate.day" name="birth_day" class="form-control custom-select birth" id="birthDateDetail">
                                    <option *ngIf="!birthDate.day" disabled></option>
                                    <option *ngFor="let day of getDays()" [value]="day">
                                        {{day}}
                                    </option>
                                </select>
                                <select [(ngModel)]="birthDate.month" id="birth_month" name="birth_month" class="form-control custom-select birth">
                                    <option *ngIf="!birthDate.month" disabled></option>
                                    <option *ngFor="let month of getMonths()" [value]="month">{{'month_list.' + month | translate}}</option>
                                </select>
                                <select [(ngModel)]="birthDate.year" id="birth_year" name="birth_year" class="form-control custom-select birth">
                                    <option *ngIf="!birthDate.year" disabled></option>
                                    <option *ngFor="let year of getYears()" [value]="year">{{year}}</option>
                                </select>
                            </div>
                            <div *ngIf="profile.getBirthDate()" class="form-group col-lg-6">
                                <label>{{ 'profile.about.birthdate' | translate }}</label>
                                <span>{{ profile.birthDate | date: 'dd/MM/yyyy' }}</span>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>{{ 'profile.about.my_postal_code' | translate }}</label>
                                <input readonly [(ngModel)]="profileDraft.postalCode" type="text" class="form-control" id="editZipCode" name="postalCode">
                            </div>
                            <div class="form-group col-lg-6">
                                <label>{{ 'profile.about.my_city' | translate }}</label>
                                <app-city-typeahead [profile]="profileDraft" [currentCityString]="profileDraft.city"></app-city-typeahead>
                            </div>

                            <div class="form-group col-lg-12">
                                <label>{{ getPresentationLabel() }}</label>
                                <textarea [(ngModel)]="profileDraft.presentation" class="form-control" rows="3" name="presentation" id="description" style="height: 90px;"></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="tab === 'searching' && category === 'search_criteria'">
                            <div class="form-group col-lg-6">
                                <label>{{ 'you_search' | translate }}</label>
                                <div class="row">
                                    <div class="col-6">
                                        <div [class.disable]="profileDraft.isSearchedGenderFemale() && !site.isFriend()" class="custom-radio">
                                            <input [disabled]="profileDraft.isSearchedGenderFemale() && !site.isFriend()" [(ngModel)]="profileDraft.searchedGender" [value]=1 type="radio" id="searched-gender-man" name="searched-gender" class="custom-control-input">
                                            <label class="custom-control-label font-weight-normal" for="searched-gender-man">{{ 'searchedGender_list.1' | translate }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="!site.isGay()" class="col-6">
                                        <div [class.disable]="profileDraft.isSearchedGenderMale() && !site.isFriend()" class="custom-radio">
                                            <input [disabled]="profileDraft.isSearchedGenderMale() && !site.isFriend()" [(ngModel)]="profileDraft.searchedGender" [value]=2 type="radio" id="searched-gender-woman" name="searched-gender" class="custom-control-input">
                                            <label class="custom-control-label font-weight-normal" for="searched-gender-woman">{{ 'searchedGender_list.2' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <app-question-group [tab]="tab" [category]="category" [profile]="profileDraft" class="col-12"></app-question-group>
                    </div>

                    <!-- ----------- -->
                    <!-- Save button -->
                    <!-- ----------- -->
                    <div *ngIf="currentProfile" class="w-100 text-right mt-2">
                        <cancel-button [dataTarget]="'.multi-collapse-' + category" [ariaControls]="category + '-view ' + category + '-edit'"></cancel-button>
                        <app-save-button (click)="onSave(category)" [dataTarget]="'.multi-collapse-' + category" [ariaControls]="category + '-view ' + category + '-edit'"></app-save-button>
                    </div>
                </div>
            </div>
        </div>

        <app-photo-slider *ngIf="showPhotoSlider(category)" [profile]="profile"></app-photo-slider>
    </ng-container>
</ng-container>
