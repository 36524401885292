<div class="row">
    <div class="col-lg-4">
        <app-certification-component></app-certification-component>
    </div>
    <div class="col-lg-8">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2>
                    <i class="fas fa-key mr-2 text-primary"></i>
                    <span>{{ 'settings.service_access' | translate }}</span>
                </h2>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="form-inline no-gutters">
                        <div class="col-sm-3"> <label class="text-left">{{ 'settings.my_pseudo' | translate }}</label></div>
                        <div class="col-sm-9">
                            <div class="input-group w-100">
                                <span class="input-group-text" style="min-width:42px;"><i
                                        class="fas fa-user mx-auto"></i></span>
                                <input type="text" class="form-control disabled" [placeholder]="account.getProfile().getNickname()" disabled="">
                                <button (click)="openNicknameModification()" type="submit" class="btn btn-sm btn-primary ml-2 btn-acces">{{ 'change' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-inline no-gutters">
                        <div class="col-sm-3"> <label class="text-left">{{ 'my_email' | translate }}</label></div>
                        <div class="col-sm-9">
                            <div class="input-group w-100">
                                <span class="input-group-text" style="min-width:42px;"><i class="fas fa-envelope mx-auto"></i></span>
                                <input type="text" class="form-control disabled" [placeholder]="account.getEmail()" disabled="">
                                <button (click)="openEmailModification()" type="submit" class="btn btn-sm btn-primary ml-2 btn-acces">{{ 'change' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-inline no-gutters">
                        <div class="col-sm-3"> <label class="text-left">{{ 'my_password' | translate }}</label></div>
                        <div class="col-sm-9">
                            <div class="input-group w-100">
                                <span class="input-group-text" style="min-width:42px;"><i class="fas fa-lock mx-auto"></i></span>
                                <input [type]="passwordInputType" class="form-control disabled" placeholder="*****" disabled="">
                                <button (click)="openPasswordModification()" type="submit" class="btn btn-sm btn-primary ml-2 btn-acces">{{ 'change' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-inline no-gutters">
                        <div class="col-sm-3"> <label class="text-left">{{ 'my_number' | translate }}</label></div>
                        <div class="col-sm-9">
                            <div class="input-group w-100">
                                <span class="input-group-text" style="min-width:42px;"><i class="fas fa-mobile-alt mx-auto"></i></span>
                                <input type="text" class="form-control disabled" [placeholder]="account.getMobile() || ''" disabled="">
                                <button (click)="openMobileModification()" type="submit" class="btn btn-sm btn-primary ml-2 btn-acces">{{ 'change' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-suspension></app-suspension>

        <app-suppression></app-suppression>
    </div>
</div>
