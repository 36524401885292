import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login/login.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {LoaderService} from '../../services/loader/loader.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        private loginService: LoginService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private loader: LoaderService,
        private title: Title
    ) {
    }

    public loginForm;

    ngOnInit() {
        this.title.setTitle('Login');

        this.loader.setLoading(false);
        if (this.loginService.isConnected()) {
            this.router.navigate(['/home']).then();
        } else if (!environment.hasLoginPage) {
            this.loginService.navigateToLogin();
        }

        this.loginForm = new FormGroup({
            username: new FormControl(),
            password: new FormControl(),
        });
    }

    login() {
        this.loader.setMaxOpacity(true);
        this.loader.setLoading(true);

        this.loginService.removeAuthentication();

        this.loginService.loginUserByUsernamePassword(this.loginForm.get('username').value, this.loginForm.get('password').value)
            .then(() => {
                window.location.href = '/home';
            })
            .catch(() => {
                this.toastr.error(this.translate.instant('login.bad_credentials'));
                this.loader.setMaxOpacity(false);
                this.loader.setLoading(false);
            });
    }
}
