import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-full-access',
    templateUrl: './full-access.component.html',
    styleUrls: ['./full-access.component.scss']
})
export class FullAccessComponent {

    constructor() {
    }

}
