import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class PaymentTransactionNotificationType extends AbstractNotificationType {

    static readonly TYPE = 10;

    protected buttons:NotificationButton[] = [
        new NotificationButton('view', () => {
            this.goTo('account', null, 'purchase');
        })
    ];

    getIcon(): string {
        return 'fa-shopping-cart';
    }
}
