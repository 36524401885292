import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractPaymentComponent} from '../abstract-payment.component';

@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent extends AbstractPaymentComponent implements OnInit, OnChanges {
    @Input() displayRedirect = true;
    @Input() template = 256;
    @Input() offer = null;
    @Input() groupOffer = null;
    @Input() groupTemplateId = null;
    @Input() returnUrlAccepted = null;
    @Input() returnUrlRefused = null;
    @Input() url = null;
    @Input() urlMode = false;
    @ViewChild('iframe') iframe: ElementRef;

    private action: string;
    private profileId: string;

    ngOnInit(): void {
        this.iFrameEvent();

        this.activatedRoute.queryParamMap.subscribe((params) => {
            if (!this.urlMode) {
                this.action = params.get('action') || 'default';
                this.profileId = params.get('profileId');

                this.billingService.payment({
                    mode: 'freemium',
                    action: this.action,
                    profileId: this.profileId,
                    templateId: this.template,
                    offerId: this.offer,
                    offerGroupId: this.groupOffer ? this.groupOffer.getGroupId() : null,
                    offerGroupTemplateId: this.groupTemplateId,
                    ga: this.currentUserService.getGa(),
                    returnUrlAccepted: this.returnUrlAccepted,
                    returnUrlRefused: this.returnUrlRefused
                }).subscribe((response: any) => {
                    if (response.success) {
                        this.setPaymentUrl(response.data.paymentUrl);
                    }
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.url && changes.url.currentValue) {
            this.setPaymentUrl(changes.url.currentValue);
        }
    }

    setPaymentUrl(url: string) {
        super.setPaymentUrl(url);
        if (this.iframe.nativeElement && this.iframe.nativeElement.contentWindow) {
            this.iframe.nativeElement.contentWindow.location.replace(url);
        }
    }
}
