<div [class.alert-warning]="!notification.getWasRead()" [class.bg-white]="notification.getWasRead()" class="alert notif">
    <button (click)="delete()" type="button" class="close">
        <span aria-hidden="true">×</span>
    </button>
    <span *ngIf="!notification.getWasRead()" class="fa-stack cloche">
        <i class="fas fa-circle fa-stack-2x text-warning"></i>
        <i class="fas fa-bell fa-stack-1x fa-inverse"></i>
    </span>
    <div class="row align-items-center">
        <div *ngIf="notificationType" class="col-12 text-left">
            <div *ngIf="notification.fromProfile && !notificationType.getIcon() && !notificationType.getImage()"
                 [routerLink]="['/profile', notification.fromProfile.getId()]"
                 class="img-container">
                <img *ngIf="notification.fromProfile.photo"
                     [src]="notification.fromProfile | userImage: '250x250'"
                     class="img-fluid img-alert">
                <img *ngIf="!notification.fromProfile.photo" src="assets/img/150x150/default_user_{{ notification.fromProfile.gender }}.png" class="img-fluid img-alert">
            </div>

            <div *ngIf="notificationType.getImage()" class="img-container">
                <img [src]="notificationType.getImage()" class="img-fluid img-alert">
            </div>

            <div *ngIf="notificationType.getIcon()" class="img-alert d-flex align-items-center justify-content-center">
                <i class="fas" [ngClass]="notificationType.getIcon()">
                    <span *ngIf="getMetaData().boosterName" class="iconBannerName">{{ getMetaData().boosterName }}</span>
                </i>
            </div>

            <span class="titre-alert">
                <ng-container *ngFor="let translation of translations">
                    <ng-container *ngIf="!notification.getFromProfile() || notification.getFromProfile().getNickname() !== translation">{{ translation }}</ng-container>
                    <a *ngIf="notification.getFromProfile() && notification.getFromProfile().getNickname() === translation"
                       [routerLink]="['/profile', notification.fromProfile.getId()]"
                       [class.blurred]="hasRule('nicknameBlur')">{{ translation }}</a>
                </ng-container><br>
                <small>{{ notification.createdAt | date: 'dd/MM/yyyy' }} à {{ notification.createdAt | date: 'HH:mm' }}</small>
            </span>
        </div>
        <div class="col-12 text-right alert-buttons">
            <ng-container *ngFor="let button of notificationType.getButtons(); let i = index">
                <a *ngIf="notification.showButton(i)" (click)="btnClick(button)" [ngClass]="btnClass()" class="btn btn-sm ml-2">
                    {{ button.label | translate }}
                </a>
            </ng-container>
        </div>
    </div>
</div>
