<div class="row mt-4 main profile no-gutters">
    <div class="col-12">
        <h1 *ngIf="!getTheme()" class="mb-3">
            <i class="far fa-question-circle text-primary"></i> {{ 'faq_title' | translate }}
        </h1>
        <h1 *ngIf="getTheme()" class="mb-3">
            <i [ngClass]="getTheme().getIcon()" class="text-primary"></i> {{ getTheme().getName() }}
        </h1>
    </div>

    <div class="col-12 border p-4 text-justify">
        <i class="fas fa-info-circle icon-cadre-infos"></i>
        {{ 'faq_description' | translate }}
    </div>

    <div *ngIf="getTheme()" class="col-12 faq-nav mt-4">
        <div class="row">
            <div class="col-6">
                <a [routerLink]="['/faq']" class="btn btn-sm btn-primary">
                    <i class="fas fa-arrow-left mr-1"></i>{{ 'back' | translate }}
                </a>
            </div>
            <div *ngIf="currentTheme < (themes.length - 1)" class="col-6 text-right">
                <a [routerLink]="next()" class="btn btn-sm btn-primary">
                    {{ 'next_theme' | translate }}<i class="fas fa-arrow-right ml-1"></i>
                </a>
            </div>
        </div>
    </div>

    <div *ngIf="!loading && !getTheme()" class="col-12 mt-4" id="faq">
        <div class="card border-0 box mb-4 p-0">
            <div class="row text-center no-gutters">
                <div *ngFor="let theme of themes" class="col-sm-6">
                    <a [routerLink]="['/faq', theme.getId()]" class="theme border-right h-100">
                        <i [ngClass]="theme.icon" class="text-primary"></i>{{ theme.name }}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!loading && getTheme()" class="col-12 mt-4">
        <div class="card box border-0 p-md-4">
            <div *ngFor="let question of getTheme().getQuestions()" class="card">
                <div (click)="setCurrentQuestion(question)" [class.active]="currentQuestion === question.getId()" class="card-header">
                    <div class="row no-gutters">
                        <div class="col-auto pr-3">
                            <h5>{{ 'question_initial_letter' | translate }}.</h5>
                        </div>
                        <div class="col">
                            <h5>{{ question.getQuestion() }}</h5>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentQuestion === question.getId()" class="card-body">
                    <div class="row no-gutters">
                        <div class="col-auto pr-3">
                            <h5>{{ 'answer_initial_letter' | translate }}.</h5>
                        </div>
                        <div class="col">
                            <p [innerHTML]="getAnswer(question) | formatHtml"></p>
                            <div class="useful-answer text-right mt-4 pt-3 border-top">
                                <span class="useful-answer-title">{{ 'faq.useful_answer' | translate }}</span>
                                <a (click)="usefulAnswer('click_no')" class="useful-answer-choice btn btn-dark btn-sm mr-2">{{ 'no' | translate }}</a>
                                <a (click)="usefulAnswer('click_yes')" class="useful-answer-choice btn btn-dark btn-sm">{{ 'yes' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="getTheme()" class="col-12 faq-nav mt-4">
        <div class="row">
            <div class="col-6">
                <a [routerLink]="['/faq']" class="btn btn-sm btn-primary">
                    <i class="fas fa-arrow-left mr-1"></i>{{ 'back' | translate }}
                </a>
            </div>
            <div *ngIf="currentTheme < (themes.length - 1)" class="col-6 text-right">
                <a [routerLink]="next()" class="btn btn-sm btn-primary">
                    {{ 'next_theme' | translate }}<i class="fas fa-arrow-right ml-1"></i>
                </a>
            </div>
        </div>
    </div>
</div>
