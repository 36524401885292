<div class="card border-0 box mb-4">
    <div class="card-header">
        <h2>
            <i class="fas fa-trash-alt mr-2 text-primary"></i>
            <span class="d-none d-xl-inline-block">{{ 'account.my_account.delete_account.title' | translate }}</span>
            <span class="d-inline-block d-xl-none">{{ 'account.my_account.delete_account.title_mobile' | translate }}</span>
        </h2>
    </div>
    <div class="card-body">
        <p class="text-justify">{{ 'account.my_account.delete_account.description' | translate }}</p>
        <form class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>{{ 'account.my_account.delete_account.reason' | translate }}</label>
                    <select [(ngModel)]="form.reason" name="reason" class="form-control custom-select">
                        <option [value]="reason" *ngFor="let reason of reasonList">{{ ('reason_list.' + reason) | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 text-right">
                <button (click)="submit()" [disabled]="form.reason === 'choose'" type="button" class="btn btn-primary btn-sm">
                    <span class="d-none d-xl-inline-block">{{ 'account.my_account.delete_account.start_process' | translate }}</span>
                    <span class="d-inline-block d-xl-none">{{ 'account.my_account.delete_account.delete' | translate }}</span>
                </button>
            </div>
        </form>
    </div>
</div>
