import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChatAutoReconnectService {
    private autoReconnect = true;

    constructor() {
    }

    isAutoReconnect(): boolean {
        return this.autoReconnect;
    }

    setAutoReconnect(enabled: boolean) {
        this.autoReconnect = enabled;
    }
}
