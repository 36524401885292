import {Injectable} from '@angular/core';
import {UploadPhotoComponent} from '../../components/modal/upload-photo/upload-photo.component';
import {EmailConfirmationComponent} from '../../components/modal/email-confirmation/email-confirmation.component';
import {MobileConfirmationComponent} from '../../components/modal/mobile-confirmation/mobile-confirmation.component';
import {ConfirmationComponent} from '../../components/modal/confirmation/confirmation.component';
import {FullScreenConfirmationComponent} from '../../components/modal/full-screen-confirmation/full-screen-confirmation.component';
import {ModificationSettingsComponent} from '../../components/modal/modification-settings/modification-settings.component';
import {UnsubscribeComponent} from '../../components/modal/unsubscribe/unsubscribe.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UpsellXponsorUnloverComponent} from '../../components/modal/upsell/xponsor/unlover/unlover.component';
import {FinishSignupComponent} from '../../components/modal/finish-signup/finish-signup.component';
import {ReportProfileComponent} from '../../components/modal/report-profile/report-profile.component';
import {AlbumModalComponent} from '../../components/modal/album/album.component';
import {Profile} from '../../models/profile/profile';
import {Album} from '../../models/profile/album';
import {PanicComponent} from '../../components/panic/panic.component';
import {BoosterComponent} from '../../components/modal/booster/booster.component';
import {CamModel} from '../../models/cam-model/cam-model';
import {CamModelDetailComponent} from '../../components/modal/cam-model-detail/cam-model-detail.component';
import {SexCamConfirmationComponent} from '../../components/modal/sex-cam-confirmation/sex-cam-confirmation.component';
import {SexCamsCoinWarningComponent} from '../../components/modal/sex-cams-coin-warning/sex-cams-coin-warning.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private static sexCamConfirmationOpened = false;

    config: any = {
        panelClass: 'modal-box',
        maxWidth: '100%'
    };

    constructor(public dialog: MatDialog) {
    }

    openUploadPhoto(album: Album = null, data: object = {}) {
        this.config.data = {
            album,
            isMain: false,
            albumType: Album.CUSTOM,
            pickPhotoOnAlbum: false,
            title: 'albums.add_photo',
            faClass: 'fa-image'
        };
        this.config.data = {...this.config.data, ...data};

        return this.dialog.open(UploadPhotoComponent, this.config);
    }

    openEmailConfirmation() {
        this.config.data = {
            modalId: 'box',
            faClass: 'fa-envelope',
            title: 'email_conf.verif',
            titleMobile: 'email_conf.verif_short'
        };
        return this.dialog.open(EmailConfirmationComponent, this.config);
    }

    openMobileConfirmation(editionMode, data: object = {}): MatDialogRef<MobileConfirmationComponent> {
        this.config.data = {
            modalId: 'modiftel',
            faClass: 'fa-mobile-alt',
            title: (editionMode) ? 'settings.mobile_change' : 'mobile_conf.verif',
            titleMobile: (editionMode) ? 'settings.mobile_change_short' : 'mobile_conf.verif_short',
            editionMode
        };
        this.config.data = {...this.config.data, ...data};
        return this.dialog.open(MobileConfirmationComponent, this.config);
    }

    openSexCamConfirmation(data: object): MatDialogRef<SexCamConfirmationComponent> {
        if (ModalService.sexCamConfirmationOpened) {
            return;
        }
        ModalService.sexCamConfirmationOpened = true;
        data = {...this.config, ...data, ...{disableClose: true}};
        return this.dialog.open(SexCamConfirmationComponent, data);
    }

    closeSexCamConfirmation() {
        ModalService.sexCamConfirmationOpened = false;
    }

    openConfirmation(data: object): MatDialogRef<ConfirmationComponent> {
        data = {...this.config, ...data};
        return this.dialog.open(ConfirmationComponent, data);
    }

    openFullScreenConfirmation(data: object): MatDialogRef<FullScreenConfirmationComponent> {
        data = {...this.config, ...{data}};
        return this.dialog.open(FullScreenConfirmationComponent, data);
    }

    openModificationSettings(field, icon, data: object = {}) {
        this.config.data = {
            modalId: 'modifpseudo',
            faClass: 'fa-edit',
            title: 'settings.' + field + '_change',
            titleMobile: 'settings.' + field + '_change_short',
            field,
            icon
        };
        this.config.data = {...this.config.data, ...data};
        return this.dialog.open(ModificationSettingsComponent, this.config);
    }

    openBooster() {
        this.config.data = {
            modalId: 'booster',
            faClass: '',
            title: '',
            titleMobile: '',
        };
        this.config.data = {...this.config.data};

        return this.dialog.open(BoosterComponent, this.config);
    }

    openUnsubscribe() {
        const config = {
            ...this.config, ...{
                data: {
                    faClass: 'fa-hand-paper',
                    title: 'unsubscribe.termination',
                    titleMobile: 'unsubscribe.termination_mobile'
                }
            }
        };

        return this.dialog.open(UnsubscribeComponent, config);
    }

    openUpsellXponsorUnlover(data: object = {}) {
        this.config.data = {
            modalClass: 'unlover',
            faClass: 'fa-crown',
            title: 'xponsor.modal_title'
        };
        this.config.data = {...this.config.data, ...data};
        return this.dialog.open(UpsellXponsorUnloverComponent, this.config);
    }

    openFinishSignup() {
        const config = {
            ...this.config, ...{
                disableClose: true // do not allow closing modal by ESC key
            }
        };
        return this.dialog.open(FinishSignupComponent, config);
    }

    openReportProfile(profile: Profile, data: object = {}) {
        this.config.data = {
            modalId: 'signaler-profil',
            faClass: 'fa-flag',
            title: 'report_nickname',
            profile
        };
        this.config.data = {...this.config.data, ...data};
        return this.dialog.open(ReportProfileComponent, this.config);
    }

    openAlbum(data: object) {
        data = {...this.config, ...data};
        return this.dialog.open(AlbumModalComponent, data);
    }

    openPanicMode() {
        const config = {
            ...this.config, ...{
                width: '100%',
                disableClose: true // do not allow closing modal by ESC key
            }
        };
        return this.dialog.open(PanicComponent, config);
    }

    openSexCamCoinWarning(camModel: CamModel, videoOngoingContext = false) {
        if (ModalService.sexCamConfirmationOpened) {
            return false;
        }
        ModalService.sexCamConfirmationOpened = true;

        return this.dialog.open(SexCamsCoinWarningComponent, {
            panelClass: 'modal-box',
            data: {
                title: 'Oups...',
                faClass: 'fa-exclamation-triangle',
                nickname: camModel.getNickname(),
                message: videoOngoingContext ? 'you_dont_have_any_coins_left' : 'you_dont_have_enough_coins',
                detail: videoOngoingContext ?
                    (camModel.isPrivateShow() ? 'credit_to_continue_private_show' : 'credit_to_continue_vip_show') :
                    (camModel.isPrivateShow() ? 'credit_account_before_private_show' : 'credit_account_before_vip_show')
            }}
        );
    }

    openCamModelDetail(camModel: CamModel) {
        return this.dialog.open(CamModelDetailComponent, {
            panelClass: 'modal-box',
            width: '100%',
            maxWidth: '1024px',
            data: {camModel}
        });
    }
}
