import {Component, Input} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {AbstractUser} from '../../../models/user/abstract-user';

@Component({
    selector: 'app-user-photo',
    templateUrl: './user-photo.component.html',
    styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent {

    @Input() user: AbstractUser = null;
    @Input() alt: string = null;

    constructor(private currentUserService: CurrentUserService) {
    }

    getAlt(): string {
        if (this.alt) {
            return this.alt;
        }

        return this.user ? this.user.getNickname() : '';
    }

    isBlurred(): boolean {
        return this.currentUserService.hasRule('photoBlur');
    }
}
