<label>{{ translateGender('profile_questions.' + question.getName()) }}</label>

<div (clickOutside)="shutdown()" class="panel-group accordion-wrapper" role="tablist" aria-multiselectable="true">
    <div class="panel form-control">
        <div (click)="toggle()">
            <div class="panel-heading" role="tab">
                <p *ngIf="!hasSelectedValue()" class="panel-values">{{ 'select_an_answer' | translate }}</p>
                <p *ngIf="hasSelectedValue()" class="panel-values">
                    {{ getTranslatedValueList() }}
                </p>
            </div>
        </div>

        <div *ngIf="!collapsed" class="panel-collapse">
            <div class="panel-body row">
                <div *ngFor="let data of question.getAnswers()" class="col-sm-6">
                    <label [class.disabled]="!canPickMore() && !isChecked(data.id)">
                        <input type="checkbox" [disabled]="!canPickMore() && !isChecked(data.id)"
                               (change)="updateModel($event)" [value]="data.id" [checked]="isChecked(data.id)"/>
                        {{ translateGender('profile_answers.' + data.name) }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
