import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit() {
    }

    isLoading() {
        return this.loaderService.isLoading();
    }

    hasMaxOpacity() {
        return this.loaderService.hasMaxOpacity();
    }
}
