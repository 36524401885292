<div style="margin-top: 5px;">
    <div style="float: left;">
        <img style="margin-left: 60px; width: 40px;" src="img/common/warning.png" alt="Warning"/>
    </div>
    <div style="font-size: 14px; font-weight: bold; color: red; text-align: center;">
        <span>{{ 'chat_webcam.beware' | translate }}</span>
        <br/>
        <span>{{ 'chat_webcam.please_report' | translate }}</span>
    </div>
</div>
<div id="chat">
    <!-- <tchat fcurl="fcurl"></tchat>-->
</div>
