import {Component, Injector, Input, OnInit} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {Album} from '../../../models/profile/album';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {LoaderService} from '../../../services/loader/loader.service';
import {ProfileService} from '../../../services/profile/profile.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../../services/modal/modal.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent extends BaseComponent implements OnInit {

    tab: string;
    @Input() profile: Profile;
    @Input() currentProfile: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private injector: Injector,
        private loaderService: LoaderService,
        private profileService: ProfileService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private modalService: ModalService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.setTab(params.get('tab') || 'about', true);
        });
    }

    setTab(tab, init = false) {
        if (this.tab !== tab && !init) {
            this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {tab},
                    queryParamsHandling: 'merge'
                }
            );
        }
        this.tab = tab;
    }

    isCurrentProfile() {
        return this.currentProfile;
    }

    canViewCertification() {
        return this.tab !== 'albums' && this.isCurrentProfile();
    }

    getAlbums(): Album[] {
        return Object.values(this.profile.getAlbums());
    }

    onSave() {
        if (this.loaderService.isLoading()) {
            return;
        }

        this.loaderService.setLoading(true);

        this.profileService.collapseAccordions();

        this.profileService.update().subscribe((response: any) => {
            this.loaderService.setLoading(false);
            if (response && !response.success) {
                this.toastr.error(
                    this.translate.instant('error_list.default')
                );
            } else {
                this.currentUserService.getAccount().getProfile().completionPercentage = response.data.completionPercentage;
                this.modalService.openConfirmation({
                    data: {
                        title: 'success_profile',
                        question: 'profile_updated',
                        responses: [
                            {text: 'ok'}
                        ]
                    }
                });
            }
        });
    }
}
