import {Component, Input} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import * as $ from 'jquery';
import {CurrentUserService} from '../../../services/user/current-user.service';

@Component({
    selector: 'app-thumb-profile',
    templateUrl: './thumb-profile.component.html',
    styleUrls: ['./thumb-profile.component.scss']
})
export class ThumbProfileComponent {

    constructor(private router: Router, private http: HttpClient, private currentUserService: CurrentUserService) {
    }

    @Input() profile: Profile;
    @Input() showVisited = false;
    @Input() booster = false;

    onLoadImage() {
        const profile = $('app-thumb-profile .card').not('.in').get(0);

        $(profile).addClass('in');
    }

    goTo(page: string) {
        if (this.profile.getWebcamId()) {
            this.router.navigate(['/sex-cams', this.profile.getWebcamId()]);
        } else if (this.profile.getId()) {
            this.router.navigate(['/' + page, this.profile.getId()]);
        }
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }
}
