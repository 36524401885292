<div class="container ok-ko contenu mt-4">
    <div class="row">
        <div class="col-lg-8 mx-auto text-center">
            <h1>
                <i class="far fa-smile text-success smiley"></i><br>
                <div [ngSwitch]="getType()">
                    <span *ngSwitchCase="'payment'">{{ 'post_payment.payment_accepted' | translate }}</span>
                    <span *ngSwitchCase="'trial'">{{ 'post_payment.try_accepted' | translate }}</span>
                    <span *ngSwitchCase="'preauth'">{{ 'post_payment.preauth_accepted' | translate }}</span>
                </div>
            </h1>
            <div [ngSwitch]="getType()">
                <p *ngSwitchCase="'payment'">
                    {{ 'post_payment.thanks_for_trust' | translate }}<br>
                    {{ 'post_payment.payment_in_history' | translate }}
                </p>
                <p *ngSwitchCase="'trial'">
                    {{ 'post_payment.wish_happy_encounter' | translate }}<br>
                    {{ 'post_payment.benefit_unlimited_access' | translate }}
                </p>
                <p *ngSwitchCase="'preauth'">
                    {{ 'post_payment.thanks_for_authorization' | translate }}<br>
                    {{ 'post_payment.benefit_unlimited_access' | translate }}
                </p>
            </div>
            <a [routerLink]="['/home']"
               class="btn btn-sm btn-primary d-block mx-auto">{{ 'post_payment.back_homepage' | translate }}</a>
        </div>
    </div>
</div>
