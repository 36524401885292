import {Injectable} from '@angular/core';
import {ChatMessageService} from './chat-message/chat-message.service';
import {ChatRoomService} from './chat-room/chat-room.service';
import {ChatUserService} from './chat-user/chat-user.service';
import {SocketService} from '../socket/socket.service';
import {UserMessage} from '../../models/chat/user-message/user-message';
import {ChatTypingService} from './chat-typing/chat-typing.service';
import {Room} from '../../models/chat/room/room';
import {ChatRuleSetService} from './chat-rule-set/chat-rule-set.service';
import {User} from '../../models/chat/user/user';
import {PendingActionService} from '../pending-action/pending-action.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    mobileTab = 'rooms';
    mobileTabFrom = '';
    private connected = false;
    private connectionFailed = false;
    private userMessages = {};
    public messages = {};

    constructor(
        private chatMessageService: ChatMessageService,
        private chatRoomService: ChatRoomService,
        private chatTypingService: ChatTypingService,
        private chatUserService: ChatUserService,
        private socketService: SocketService,
        private chatRuleService: ChatRuleSetService,
        private pendingActionService: PendingActionService
    ) {}

    onConnect() {
        this.connected = true;
        this.connectionFailed = false;

        this.chatRoomService.onPrivateRooms();
        this.chatMessageService.onPrivateMessage();
        this.chatMessageService.onDeletedMessage(() => {
            if (this.mobileTab === 'chat') {
                // If the room is opened, send back on the room list
                this.mobileTab = 'rooms';
            }
        });
        this.chatRuleService.onUpdateRuleSet();
        this.chatTypingService.onTyping();
        this.chatUserService.onUsers();
        this.chatUserService.onUserDisconnected();
        this.chatUserService.onSessionKilled();
        this.onDisconnect();
        this.onWarningUserMessage();
    }

    onConnectionFailed() {
        console.error('connection failed');
        this.connected = false;
        this.connectionFailed = true;
    }

    /**
     * Event when user is disconnected by the server (when an operator ban or suspend a user )
     */
    onDisconnect() {
        this.socketService.on('logout', (data: any) => {
            this.disconnect();
            const userMessageData = {
                type: data.message,
                dateEnd: data.endDate,
                context: UserMessage.CONTEXT_ALL
            };
            const userMessage = new UserMessage().deserialize(userMessageData);
            userMessage.setContext(UserMessage.CONTEXT_CHAT);

            this.addUserMessage(userMessage);
        });
    }

    disconnect() {
        this.socketService.disconnect();
        this.connected = false;
        this.reset();
    }

    reset() {
        this.chatRoomService.reset();
        this.chatUserService.reset();
    }

    /**
     * Event when a user receive a warning User Message
     */
    onWarningUserMessage() {
        this.socketService.on('user_messages', (data: any) => {

            for (const messageData of data) {
                const userMessage = new UserMessage().deserialize(messageData);
                userMessage.setContext(UserMessage.CONTEXT_CHAT);
                this.addUserMessage(userMessage);
            }
        });
    }

    isConnected(): boolean {
        return this.connected;
    }

    isConnectionfailed(): boolean {
        return this.connectionFailed;
    }

    getUserMessages() {
        return this.userMessages;
    }

    addUserMessage(userMessage: UserMessage) {
        this.userMessages[userMessage.getId()] = userMessage;
    }

    deleteUserMessage(userMessage: UserMessage) {
        delete this.userMessages[userMessage.getId()];
    }

    shiftUserMessage() {
        const firstKey = Object.keys(this.userMessages)[0];
        const userMessage: UserMessage = this.userMessages[firstKey];

        return userMessage;
    }

    getChatRoomService() {
        return this.chatRoomService;
    }

    getSocketService() {
        return this.socketService;
    }

    getMessageId(room: Room) {
        return (room.getId() > 0)
            ? room.getId()
            : room.getFriendUser().getUsername();
    }

    logout(expressReload = false) {
        if (!this.socketService.isDisconnected()) {
            this.socketService.emit('logout', {}).then();
            if (expressReload) {
                this.disconnect();
            }
        }
    }

    openRoom(room: Room) {
        this.mobileTab = 'chat';
        this.chatRoomService.scrollReset()
        this.chatRoomService.openRoom(room);
        // @feature "pending actions" disabled
        // this.pendingActionService.storeMessageCollectionForCurrentChatUser(room.getFriendUser().getUid());
        this.chatMessageService.loadMessages(room).then(() => {
            if (this.chatRoomService.getCurrentRoom() && !this.messages[this.chatRoomService.getCurrentRoom().getId()]) {
                this.messages[this.chatRoomService.getCurrentRoom().getId()] = '';
            }

            if (room.isScrollToBottom()) {
                this.chatRoomService.scrollTopMobile();
            }
        });
    }

    createRoom(friendUserId: number) {
        const room = this.chatRoomService.getRoomByFriendUserId(friendUserId);

        if (room) {
            this.openRoom(room);
        } else {
            const user = this.chatUserService.getUserById(friendUserId);

            if (!user) {
                this.chatUserService.loadUserByProfileId(friendUserId).then((friendUser: any) => {
                    if (friendUser) {
                        return this.createRoom(friendUser.getId());
                    }
                });
            } else {
                const newRoom = new Room();
                newRoom.setFriendUser(this.chatUserService.getUserById(friendUserId));
                this.openRoom(newRoom);
            }
        }
    }

    setMobileTabFromOthersPage() {
        this.mobileTabFrom = 'others';
    }

    setMobileTabFromRooms() {
        this.mobileTabFrom = 'rooms';
    }

    setMobileTabFromConnectedUsers() {
        this.mobileTabFrom = 'users';
    }

    isOpenedFromOtherPage(): boolean {
        return this.mobileTabFrom === 'others';
    }

    isOpenedFromRoomsTab(): boolean {
        return this.mobileTabFrom === 'rooms';
    }

    isOpenedFromConnectedUsersTab(): boolean {
        return this.mobileTabFrom === 'users';
    }

    setMe(me: User) {
        this.chatUserService.setMe(me);
    }
}
