<div class="modal-body step-searched-gender">
    <div class="container text-center">
        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/jesuis.png"><br>
        <h1>{{ 'on_boarding.i_search' | translate }}</h1>
        <p class="infos w-100 text-center">
            {{ 'on_boarding.you_search_man_or_women' | translate }}<br class="br-mobile">
            <span [innerHTML]="'on_boarding.click_on_gender_image' | translate"></span></p>
        <div class="form-group mb-0">
            <div class="form-row">
                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                    <div class="col-6 text-right">
                        <label class="btn btn-dark homme" [class.active]="isMale()">
                            <i class="fas fa-male icogenre"></i><br>
                            <input disabled type="radio" name="options" autocomplete="off" checked="">{{ 'on_boarding.a_man' | translate }}
                        </label>
                    </div>
                    <div class="col-6 text-left">
                        <label class="btn btn-dark femme" [class.active]="isFemale()">
                            <i class="fas fa-female icogenre"></i><br>
                            <input disabled type="radio" name="options" autocomplete="off" checked="">{{ 'on_boarding.a_women' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton()" (click)="previous()" class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="next()" class="btn btn-primary btn-sm text-uppercase">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
