import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Banner implements Deserializable {

    title: string;
    content: string;
    button: string;

    deserialize(data: any): this {
        Object.assign(this, data);

        return this;
    }
}
