import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class MobileChangeNotificationType extends AbstractNotificationType {

    static readonly TYPE = 45;

    protected buttons:NotificationButton[] = [
        new NotificationButton('phone_confirm', () => {
            this.modalService.openMobileConfirmation(false);
        })
    ];

    getIcon(): string {
        return 'fa-mobile-alt';
    }
}
