import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-xponsor-unlover-choice',
    templateUrl: './unlover-choice.component.html',
    styleUrls: ['./unlover-choice.component.scss']
})
export class UpsellXponsorUnloverChoiceComponent extends BaseComponent implements OnInit {

    @Output() onSuccess = new EventEmitter<boolean>();
    images: any;
    image: string;
    checked: boolean;
    errorMessage: string;

    constructor(
        private translate: TranslateService,
        injector: Injector
    ) {
        super(injector);
        this.checked = false;
        this.images = {
            1: [// Love
                'unlover01.jpg',
                'unlover02.jpg',
                'unlover03.jpg',
                'unlover04.jpg',
                'unlover05.jpg'
            ],
            2: [// Casual
                'unlover06.jpg',
                'unlover07.jpg',
                'unlover08.jpg',
                'unlover09.jpg',
                'unlover10.jpg'
            ],
            3: [// Sexy
                'unlover06.jpg',
                'unlover07.jpg',
                'unlover08.jpg',
                'unlover09.jpg',
                'unlover10.jpg'
            ]
        };
        this.image = '';
        this.errorMessage = '';
    }

    ngOnInit() {
        const imageRandom = Math.floor(Math.random() * this.images[this.site.getSegmentId()].length);
        this.image = this.images[this.site.getSegmentId()][imageRandom];
    }

    getImage() {
        return 'assets/img/xponsor/' + this.image;
    }

    activateAccess(access: boolean) {
        if (access && !this.checked) {
            this.errorMessage = this.translate.instant('accept_cgv');
            return;
        }

        this.currentUserService.upsellUnlover().subscribe((response: any) => {
            if (response.success) {
                if (typeof this.account.upsell.unlover.displayPopup !== 'undefined') {
                    this.account.upsell.unlover.displayPopup = false;
                }
                this.successEmit(response.success);
            }
        }, (error: any) => {
            this.successEmit(false);
        });
    }

    successEmit(success: boolean) {
        this.onSuccess.emit(success);
    }
}
