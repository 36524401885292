import {Component, Input} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {Account} from '../../../models/account/account';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent {

    @Input() account: Account;

    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        private currentUser: CurrentUserService,
        private profileService: ProfileService
    ) {
    }

    changeLandingPageAuthorization() {
        this.account.landingPageAuthorized = !this.account.landingPageAuthorized;
        this.profileService.putProfile({
            landingPageAuthorized: this.account.landingPageAuthorized
        }).subscribe();
    }

    changeSettings(index) {
        this.currentUser.updateNotificationSettings(index).subscribe();
    }

    updatePanicMode() {
        const newValue = !this.account.hasPanicMode();

        this.account.setPanicMode(newValue);

        this.profileService.putProfile({
            panicMode: newValue
        }).subscribe();
    }
}
