import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupOffer} from '../../models/offer/group-offer';
import {Group} from '../../models/offer/group';

@Injectable({
    providedIn: 'root'
})
export class OfferService {

    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Get group
     * @param groupId
     */
    getGroup(groupId: number): Observable<Group> {
        return new Observable<Group>(observer => {
            this.http.get(environment.backendUrl + '/offer-group/' + groupId).subscribe((response: any) => {
                if (response.success) {
                    observer.next(new Group().deserialize(response.data));
                } else {
                    observer.error(response.message);
                }
            }, (error) => {
                observer.error(error);
            });
        });
    }

    /**
     * Get groups
     */
    getGroups(): Observable<Group[]> {
        return new Observable<Group[]>(observer => {
            this.http.get(environment.backendUrl + '/offer-group').subscribe((response: any) => {
                if (response.success) {
                    observer.next(response.data.map(data => new Group().deserialize(data)));
                } else {
                    observer.error(response.message);
                }
            }, (error) => {
                observer.error(error);
            });
        });
    }

    /**
     * Get offers
     * @param groupId
     */
    getGroupOffers(groupId: number): Observable<GroupOffer[]> {
        return new Observable<GroupOffer[]>(observer => {
            this.http.get(environment.backendUrl + '/offer-group/offer/?groupId=' + groupId).subscribe((response: any) => {
                if (response.success) {
                    observer.next(response.data.map(data => new GroupOffer().deserialize(data)));
                } else {
                    observer.error(response.message);
                }
            }, (error) => {
                observer.error(error);
            });
        });
    }

    /**
     * Get offer
     * @param offerId
     */
    getGroupOffer(offerId: number): Observable<GroupOffer> {
        return new Observable<GroupOffer>(observer => {
            this.http.get(environment.backendUrl + '/offer-group/offer/' + offerId).subscribe((response: any) => {
                if (response.success) {
                    observer.next(new GroupOffer().deserialize(response.data));
                } else {
                    console.log(response.message);
                    observer.error(response.message);
                }
            }, (error) => {
                console.log(error);
                observer.error(error);
            });
        });
    }

    hitOffer(group: Group, offer: GroupOffer, templateId: number, action: string): Observable<object> {
        return this.http.post(
            environment.backendUrl + '/billing/hit/offer',
            {
                groupId: group.id,
                offerId: offer ? offer.id : null,
                templateId,
                action
            }
        );
    }
}
