import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {ProfileService} from '../../../../services/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {Profile} from '../../../../models/profile/profile';
import {BaseComponent} from '../../../base/base.component';
import {City} from '../../../../models/city/city';

@Component({
    selector: 'app-finish-signup-location',
    templateUrl: './finish-signup-location.component.html',
    styleUrls: ['./finish-signup-location.component.scss']
})
export class FinishSignupLocationComponent extends BaseComponent implements OnInit {

    @Output() nextStep = new EventEmitter<number>();
    @Output() previousStep = new EventEmitter<number>();
    @Output() setStepCache = new EventEmitter<any>();
    @Input() stepCacheData;
    @Input() currentStep: number;

    errorMessage = '';
    citySelected = false;

    selectedCityObject: City;

    constructor(
        private currentUser: CurrentUserService,
        private profileService: ProfileService,
        private translate: TranslateService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        if (typeof this.stepCacheData !== 'undefined' && typeof this.stepCacheData.location !== 'undefined') {
            this.errorMessage = this.stepCacheData.location.errorMessage;
            this.citySelected = this.stepCacheData.location.citySelected;
            this.selectedCityObject = this.stepCacheData.location.selectedCityObject;
        }
    }

    updateCacheData() {
        let cacheData: any = this.stepCacheData;

        if (typeof cacheData === 'undefined') {
            cacheData = {};
        }

        cacheData.location = {
            errorMessage: this.errorMessage,
            citySelected: this.citySelected,
            selectedCityObject: this.selectedCityObject
        };

        this.setStepCache.emit(cacheData);
    }

    getProfile(): Profile {
        return this.currentUser.account.profile;
    }

    next() {
        this.errorMessage = '';
        this.currentUserService.getAccount().getProfile().getFieldsStatus().city = 'provided';
        this.currentUserService.getAccount().getProfile().getFieldsStatus().postalCode = 'provided';
        this.nextStep.emit();
    }

    previous() {
        this.updateCacheData();
        this.errorMessage = '';
        this.previousStep.emit();
    }

    /**
     * Update user city
     */
    updateCity() {
        if (this.citySelected) {
            const account = this.currentUser.getAccount();
            const profile = account.getProfile();

            this.profileService.putProfile({
                city: profile.getCity(),
                postalCode: profile.getPostalCode(),
                country: profile.getCountry()
            }).subscribe((response: any) => {
                if (response && !response.success) {
                    this.errorMessage = this.translate.instant('error_list.technical_issue');
                } else {
                    // Update long/lat
                    profile.setLatitude(response.data.latitude);
                    profile.setLongitude(response.data.longitude);

                    // Update search filters
                    account.getSettings().getSearchFilters().city = new City().deserialize({
                        name: profile.getCity(),
                        postalCode: profile.getPostalCode(),
                        country: profile.getCountry(),
                        latitude: profile.getLatitude(),
                        longitude: profile.getLongitude()
                    });

                    // Save settings
                    this.currentUser.updateSettings();

                    this.updateCacheData();
                    this.next();
                }
            }, () => {
                this.errorMessage = this.translate.instant('error_list.technical_issue');
            });
        }
    }

    onSelectCity(city: City) {
        this.selectedCityObject = city;
        this.citySelected = true;
    }

    onUnselectCity() {
        this.selectedCityObject = null;
        this.citySelected = false;
    }

    isSelectedCity(): boolean {
        return this.citySelected;
    }

    getDescription() {
        let description = this.translate.instant('on_boarding.meet_people');
        description += '<br class="br-mobile"> ';
        description += this.translate.instant('on_boarding.use_form_below');

        return description;
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }
}
