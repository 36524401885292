import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-live-cb',
    templateUrl: './live-cb.component.html',
    styleUrls: ['./live-cb.component.scss']
})
export class LiveCbComponent implements OnInit {

    constructor(
        private currentUserService: CurrentUserService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (!this.currentUserService.getAccount().hasUpsellVirilblue()) {
            this.router.navigate(['/home']).then();
        }
    }

    getTemplate(): number {
        return this.currentUserService.getAccount().getUpsellLiveTemplateId();
    }

    getOffer(): number {
        return this.currentUserService.getAccount().getUpsellLiveOfferId();
    }

    getReturnUrlAccepted(): string {
        return location.origin + '/upsell/virilblue/payment-accepted';
    }

    getReturnUrlRefused(): string {
        return location.origin + '/upsell/virilblue/payment-refused';
    }

    refuse() {
        this.router.navigate(['/home']).then();
    }
}
