import {Component, DoCheck, HostListener, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatService} from '../../../services/chat/chat.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatTypingService} from '../../../services/chat/chat-typing/chat-typing.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import * as $ from 'jquery';
import {User} from '../../../models/chat/user/user';
import {Room} from '../../../models/chat/room/room';
import {Message} from '../../../models/chat/message/message';
import {Location} from '@angular/common';
import {SocketService} from '../../../services/socket/socket.service';
import {ChatMessageService} from '../../../services/chat/chat-message/chat-message.service';

@Component({
    selector: 'app-chat-mobile',
    templateUrl: './chat-mobile.component.html',
    styleUrls: ['../chat.component.scss', './chat-mobile.component.scss']
})
export class ChatMobileComponent implements DoCheck, OnInit {

    messages = {};
    friends: number[];
    filteredUsers: User[];
    filteredContacts: User[];
    loadMoreUsersLoading = false;
    private currentRoomMessagesDiffer: KeyValueDiffer<string, any>;
    private lastScrollMessagesPosition: number = null;
    loadMoreMessagesLoading = false;

    constructor(
        private differs: KeyValueDiffers,
        protected route: ActivatedRoute,
        protected router: Router,
        public chatService: ChatService,
        public chatRoomService: ChatRoomService,
        public chatUserService: ChatUserService,
        protected chatMessageService: ChatMessageService,
        protected currentUserService: CurrentUserService,
        protected chatTypingService: ChatTypingService,
        protected modalService: ModalService,
        protected translate: TranslateService,
        private location: Location
    ) {
    }

    ngOnInit() {
        this.filteredUsers = [];
        this.filteredContacts = [];
        this.friends = [];
        this.currentRoomMessagesDiffer = this.differs.find(this.getCurrentRoomMessages()).create();
    }

    ngDoCheck(): void {
        const currentRoomMessagesChanges = this.currentRoomMessagesDiffer.diff(this.getCurrentRoomMessages());
        this.filterUsers();

        const room: Room = this.chatRoomService.getCurrentRoom();

        if (this.mobileTabIs('chat') && currentRoomMessagesChanges && room && room.isScrollToBottom()) {
            this.chatRoomService.scrollTopMobile();
        }
    }

    getUnreadCount() {
        const unreadCount: number = this.chatService.getChatRoomService().countTotalMessages();

        return (unreadCount > 9) ? '9+' : unreadCount;
    }

    setMobileTab(index) {
        this.chatService.mobileTab = index;
        $('html')[0].scrollTop = 0;
    }

    mobileTabIs(...indexes) {
        return [...indexes].includes(this.chatService.mobileTab);
    }

    filterUsers() {
        const users = [];
        const contacts = [];
        for (const user of this.chatUserService.getUsers()) {
            if (!this.currentUserService.isCurrentUser(user.getUid())
                && !user.getActivity().isOffline()
                && this.currentUserService.getAccount().getProfile().getGender() !== user.getGender()
            ) {
                (this.friends.includes(user.getUid())) ? contacts.push(user) : users.push(user);
            }
        }

        users.sort((a, b) => {
            return a.getDistance() - b.getDistance();
        });
        contacts.sort((a, b) => {
            return a.getUsername().localeCompare(b.getUsername());
        });
        this.filteredUsers = users;
        this.filteredContacts = contacts;
    }

    getFilteredUsers() {
        return this.filteredUsers;
    }

    getFilteredContacts() {
        return this.filteredContacts;
    }

    getCurrentRoom(): Room {
        return this.chatRoomService.getCurrentRoom();
    }

    getCurrentRoomMessages(): Message[] {
        return this.getCurrentRoom() ? this.getCurrentRoom().getMessages() : [];
    }

    typing(event) {
        this.chatTypingService.startTyping(event);
    }

    onContextMenu(event: MouseEvent) {
        // do not allow right click
        event.preventDefault();
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }

    useBanner(): boolean {
        return this.currentUserService.useBanner();
    }

    backClick() {
        if (this.chatService.isOpenedFromRoomsTab()) {
            this.setMobileTab('rooms');
        } else if (this.chatService.isOpenedFromConnectedUsersTab()) {
            this.setMobileTab('users');
        } else {
            this.location.back();
        }
    }

    onScrollUsers(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadMoreUsers();
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event) {
        if (this.mobileTabIs('users')) {
            if (document.documentElement.clientHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight) {
                this.loadMoreUsers();
            }
        }

        if (this.mobileTabIs('chat')) {
            if (document.documentElement.scrollTop === 0) {
                this.loadMoreMessages();
            }
        }
    }

    onScrollMessages(event) {
        if (event.target.scrollTop === 0 && this.lastScrollMessagesPosition > 0) {
            this.loadMoreMessages();
        }

        this.lastScrollMessagesPosition = event.target.scrollTop;
    }

    hasMoreMessages(): boolean {
        const room: Room = this.chatRoomService.getCurrentRoom();
        return room ? room.hasInfiniteScroll() : false;
    }

    loadMoreMessages() {
        const room: Room = this.chatRoomService.getCurrentRoom();
        if (room === null) {
            return;
        }

        if (this.loadMoreMessagesLoading) {
            return;
        }

        this.loadMoreMessagesLoading = true;

        const count = room.getMessages().length;
        this.chatMessageService.loadMessages(room, count, 10).then(() => {
            this.loadMoreMessagesLoading = false;
        });
    }

    loadMoreUsers() {
        if (this.loadMoreUsersLoading) {
            return;
        }

        this.loadMoreUsersLoading = true;
        this.chatUserService.loadMoreUsers().then(() => {
            setTimeout(() => {
                this.loadMoreUsersLoading = false;
            }, 1000);
        });
    }
}
