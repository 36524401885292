import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-coaching-seduction-component',
    templateUrl: './coaching-seduction.component.html',
    styleUrls: ['./coaching-seduction.component.scss']
})
export class CoachingSeductionComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
