<div class="monprofil">
    <div class="cover-header"
         [style.background-image]="'url(' + profile.getCoverPhoto() + ')'"
         [style.background-color]="(!profile.getCoverPhoto()) ? 'mediumpurple' : ''"
    >
        <div class="buttons-cover" *ngIf="isCurrentProfile()">
            <!-- TODO: implement this part when a solution is decided for the printing of the view
            <a href="#" class="btn btn-secondary edit-cover mr-1">
                <span class="d-none d-xl-block"><i class="fas fa-eye mr-2"></i> {{ 'card.view_my_profile' | translate }}</span>
                <span class="d-block d-xl-none" [matTooltip]="'card.view_my_profile' | translate">
                    <i class="fas fa-eye"></i>
                </span>
            </a>
            -->
            <a (click)="openUploadPhoto(true)" class="btn btn-secondary edit-cover" data-target="#changer-couverture" data-toggle="modal">
                <span class="d-none d-xl-block"><i class="fa fa-camera mr-2" aria-hidden="true"></i> {{ 'card.update_photo_cover' | translate }}</span>
                <span class="d-block d-xl-none" [matTooltip]="'card.update_photo_cover' | translate">
                    <i class="fas fa-edit"></i>
                </span>
            </a>
        </div>
    </div>

    <span *ngIf="!isCurrentProfile() && profile.isCertified()" class="icon-certified"><i class="fas fa-certificate"></i><i class="fas fa-check"></i></span>

    <div class="photo-profil">
        <a data-target="#changerphoto" data-toggle="modal">
            <div [class.profil-moderation]="!profile.photoIsModerated && isCurrentProfile()"
                 [class.profile-certified]="!isCurrentProfile() && profile.isCertified()"
                 class="profil wrapper">
                <div *ngIf="!profile.photoIsModerated && isCurrentProfile() && profile.getPhoto()" class="moderation">{{ 'card.waiting_moderation' | translate }}</div>

                <div class="wrappercontent">
                    <div class="itemsprofil">
                        <div *ngIf="!isCurrentProfile() && profile.isCertified()"
                             class="certified text-uppercase text-white">{{ 'certified_profile' | translate }}</div>

                        <img *ngIf="profile.getPhoto()"
                             [src]="profile.getPhoto()"
                             [error-src]="profile.getGender()"
                             [error-src-size]="250"
                             class="img-fluid w-100 card-img-profile"
                             alt="Photo"/>
                        <img *ngIf="!profile.getPhoto()"
                             class="img-fluid w-100 card-img-profile"
                             src="assets/img/250x250/default_user_{{ profile.getGender() }}.png"
                             alt="Photo"/>
                    </div>
                </div>
            </div>
            <span (click)="openUploadPhoto(false)" [matTooltip]="'card.profile_photo' | translate" class="btn-changer-photo cursor-pointer" *ngIf="isCurrentProfile()">
                <i class="fa fa-camera" aria-hidden="true"></i>
            </span>
        </a>
    </div>

    <h1>
        <i class="fas" [ngClass]="{
            'fa-mars icon-male': profile.isMale(),
            'fa-venus icon-female': profile.isFemale()
        }"></i> <span [class.blurred]="hasRule('nicknameBlur')" class="nickname">{{ profile.getNickname() }}</span>
        <small [class.blurred]="hasRule('detailBlur')" class="text-muted">
            <ng-container *ngIf="profile.getAge()">{{ profile.getAge() }} {{ 'years_old' | translate }}</ng-container>
            <i class="fas fa-map-marker-alt ml-2"></i> <small *ngIf="profile.getCity()" class="d-inline-block pl-1 mb-0">{{ profile.getCity() }}</small>
            <br *ngIf="profile.isCertified()"/>
            <small *ngIf="!isCurrentProfile() && profile.isCertified()" style="font-size: .8rem;">{{ 'profile_certified_since' | translate }} {{ profile.certifiedAt | date: 'dd/MM/yyyy'}}</small>
        </small>
    </h1>
</div>
