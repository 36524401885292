import {Component, OnInit} from '@angular/core';
import {SiteService} from '../../services/site/site.service';
import {LoaderService} from '../../services/loader/loader.service';

@Component({
    selector: 'app-template-style-component',
    template: ''
})
export class TemplateStyleComponent implements OnInit {

    constructor(
        private siteService: SiteService,
        private loaderService: LoaderService
    ) {}

    ngOnInit() {
        const customCss = {
            css: this.siteService.getSite().getCss(),
            getHex(index) {
                return '#' + this.css[index] + ';';
            },
            getHexImportant(index, opacity = '') {
                return '#' + this.css[index] + opacity + ' !important;';
            },
            get(index) {
                return this.css[index];
            }
        };

        this.applyCustomsCss(customCss);
    }

    private applyCustomsCss(customCss) {
        const style = document.createElement('style');
        style.innerHTML = `
            .pict .btn-group button,
            .btn-active,
            .btn-active:hover,
            .btn-dark:hover,
            .btn-light:hover {
                color: ${customCss.getHexImportant('btnColor')};
            }
            .card-header.active {
                background-color: ${customCss.getHex('linkColor')};
            }
            .btn-primaire,
            .btn-primaire:hover,
            .btn-upload,
            .btn-upload:hover{
                background-color: ${customCss.getHexImportant('btnColor')};
                border-color: ${customCss.getHexImportant('btnBorderColor')};
            }
            #onboarding .text-primary{
                color: ${customCss.getHexImportant('linkColor')};
            }
            #onboarding .progressbar li.active::before, #onboarding .progressbar li.active::after {
                background: ${customCss.getHex('linkColor')};
            }
            .carousel-indicators .indicator-contacts.active {
                background-color: ${customCss.getHexImportant('btnColor')};
            }
            sidebar .progress .progress-bar {
                background-color: ${customCss.getHexImportant('btnColor')};
            }
            .carousel-indicators .indicator-contacts,
            .carousel-indicators .indicator-contacts[disabled]{
                border-color: ${customCss.getHexImportant('btnBorderColor')};
            }

            .btn-primary,
            .btn-primary:focus,
            .btn-primary.disabled,
            .btn-primary:disabled,
            .badge-primary,
            .badge-primary:focus,
            .newPagination .active,
            .newPagination .active:focus,
            .noUi-base .noUi-handle,
            .noUi-base .noUi-handle:focus,
            [type=radio]:checked + label:after,
            .custom-control-input:checked ~ .custom-control-label::before {
                color: #fff !important;
                background-color: ${customCss.getHexImportant('linkColor')};
                border-color: ${customCss.getHexImportant('linkColor')};
            }

            .vip-show .card-header, .vip-show .card-footer {
                color: #fff !important;
                background-color: ${customCss.getHexImportant('linkColor')};
            }

            .bg-primary-light {
                background-color: ${customCss.getHexImportant('linkColor', '12')};
            }

            .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
                border-color: ${customCss.getHexImportant('linkColor')};
                background-color: ${customCss.getHexImportant('linkColor')};
            }

            #footer a:hover, a,
            #nvlAlbum .caption h3 span,
            a:hover,
            .pagination li a,
            .pagination li a:hover,
            sidebar certifications ul li a span,
            .nav-message a:hover,
            .color-zm
            .card-body a:hover,
            .card-links a:hover,
            .pdp a {
                color: ${customCss.getHex('linkColor')};
            }

            #chat-zm .discussions .mess {
                background-color: ${customCss.getHexImportant('mainColor')};
            }

            #menuZm .badge,
            menu-messagerie .badge,
            .pagination li.active a,
            .pagination li.active a:hover,
            #envoieMessage ul li.active a,
            #modalPremiereVisite ul.steps li[selected],
            #sliderDist .noUi-base,
            .noUi-connect,
            .iconBannerName {
                background-color: ${customCss.getHexImportant('linkColor')};
            }

            .search-icon {
                background-color: ${customCss.getHexImportant('linkColor')};
                border-color: ${customCss.getHexImportant('linkColor')};
            }

            #menuZm menu ul li.selected a, #menuZm menu ul li.selected a:hover,
            #menuZm menu ul li.selected a, #menuZm menu ul li a:hover{
                background-color: rgba(${customCss.get('linkColorRGB')},0.5);
                opacity: 1;
            }
            .dropdown-menu .dropdown-item.selected {
                background-color: ${customCss.getHexImportant('linkColor')};
                color: ${customCss.getHex('menuTextColor')};
            }
            .table-primary, .table-primary > td, .table-primary > th,
            .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th,
            #photos .carousel-indicators .active,
            .pagination .active,
            .pdp .custom-control-input:checked ~ .pdp .custom-control-label::before,
            .pdp .custom-checkbox .custom-control-input:checked ~ .pdp .custom-control-label::before,
            .pdp .badge-dark {
                background-color: ${customCss.getHexImportant('linkColor')};
            }

            .messages menu-messagerie .well li > a:hover {
                color: ${customCss.getHex('linkColor')};
            }

            .text-primary, a.text-primary, a.text-primary:hover, i.text-primary, .text-primary.credit {
                color: ${customCss.getHexImportant('linkColor')};
            }

            .bb-primary {
                border-bottom-color: ${customCss.getHexImportant('linkColor')};
            }

            .text-hover-primary:hover {
                color: ${customCss.getHexImportant('linkColor')};
            }

            .small-colored-hr {
                border-color: ${customCss.getHex('linkColor')};
            }

            certifications .wel .progress .niveau:hover,
            .messages menu-messagerie .well li.active a,
            .hearder-body sup,
            .modalBlock .text-primary,
            .img-alert,
            .cadre_niveau .card-header i {
                color: ${customCss.getHexImportant('linkColor')};
            }

            menu-messagerie .nav-message .active::before {
                background-color: rgba(${customCss.get('linkColorRGB')},0.2);
            }

            no-gutters .btn-primary,
            #lieu .card .btn-primary {
                background-color: rgba(${customCss.get('linkColorRGB')},0.2);
                border-color: rgba(${customCss.get('linkColorRGB')},0.2);
            }

            /********
             * Main *
             ********/

            .navbar.secondary{
                background-color: ${customCss.getHex('menuColor')};
            }

            /********
            * Fond *
            ********/

            body{
                background-color: #f7f7f7;
            }

            /**********
            * Titres *
            **********/

            #titre,
            .page-header,
            .page-header > span,
            .modal-header h2{
                color: ${customCss.getHex('titleColor')};
            }

            #menuZm  ul li a{
                color: ${customCss.getHex('menuTextColor')};
            }

            /**
             * Header
             */
            #menuZm .navbar-default {
                background-color: ${customCss.getHexImportant('mainColor')};
                border: none;
                border-radius: 0;
                position: fixed;
                width: 100%;
                top: 0;
                min-height: 70px;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            /**
             * Header menu (opened on mobile)
             */
            #menuZm nav .navbar-collapse,
            #top {
                background-color: ${customCss.getHex('mainColor')};
            }

            /**
             * Profile
             */
            .contenu .nav-tabs li a,
            .contenu .nav-tabs li a.active:hover {
                color: #000;
            }
            .contenu .nav-tabs li a:hover {
                color: ${customCss.getHex('linkColor')};
            }

            .box-disabled {
                top: 0;
                left: 0;
                width: 100%;
                position: fixed;
                background: #696363e0;
                height: 100%;
                z-index: 1000;
            }
            @media(max-width:568px) {
                .navbar-brand {
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                    padding-right: 15px!important;
                }

                .navbar-brand img.maxi {
                    height: 25px!important;
                }

                .navbar-brand img.d-sm-none {
                    height: auto!important;
                    max-width: 40px!important;
                    max-height: 40px! important;
                }
            }
            /**
             * Static Page
             */
            .zm-background-color {
                background-color: ${customCss.getHexImportant('linkColor')};
            }
            .zm-title-color {
                color: ${customCss.getHex('titleColor')};
            }
            .zm-border-color {
                border-color: ${customCss.getHexImportant('linkColor')};
            }
        `;
        document.head.appendChild(style);

        this.loaderService.setLoaded('css');
    }
}
