<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center mb-0">
        <h1 class="mb-0"><i class="far fa-check-circle text-success" aria-hidden="true"></i>
            Merci ! La vérification est terminée.<br>
            Votre profil dispose à présent du statut vérifié.<br>
        </h1>
    </div>
</div>

<app-unlover-layout [showTerms]="true">
    <div class="card border-0 rounded shadow-lg mt-4 mb-5">
        <i class="far fa-arrow-alt-circle-down text-primary fleche-upsell" aria-hidden="true"></i>
        <div class="card-body px-4 pb-4 px-md-5 pb-md-5 pt-4">
            <h3 class="mb-0">*** Un Lover ***</h3>
            <img src="assets/img/unlover/unlover06.jpg" alt="" class="img-fluid w-100">
            <p class="lead mb-4">Accès à la méthode et aux dossiers<br>
                Des secrets encore jamais révélés...<br>
                Consultez les fiches coaching<br>

            </p>
            <div class="custom-control custom-checkbox mb-2 text-center d-inline-block">
                <input type="checkbox" class="custom-control-input checkbox" id="conditions" checked=""
                       [(ngModel)]="terms">
                <label class="custom-control-label" for="conditions"> J'accepte les conditions générales</label>
            </div>
            <a (click)="accept()"
               class="btn btn-lg rounded-pill py-3 btn-success btn-block shadow-sm text-uppercase font-weight-bold"><i
                class="fas fa-hand-point-right mr-2" aria-hidden="true"></i> <span class="d-none d-md-inline-block">Accepter cette offre promotionnelle</span><span
                class="d-inline-block d-md-none">Valider cette offre</span></a>
            <a (click)="refuse()" class="text-center mt-3 h6 d-block no">
                Non merci, pas maintenant...<br>
                Je veux retourner sur le service de rencontres.<br>
            </a>
        </div>
    </div>
</app-unlover-layout>
