export class Activity {

    private status: number;

    constructor(status: number) {
        this.status = status;
    }

    /**
     * Status
     */
    static get OFFLINE() {
        return 0;
    }

    static get ACTIVE() {
        return 1;
    }

    static get INVISIBLE() {
        return 2;
    }

    static get DND() {
        return 3;
    }

    static get AWAY() {
        return 4;
    }

    static get INACTIVE() {
        return 5;
    }

    setStatus(status: number) {
        this.status = status;
    }

    getStatus(): number {
        return this.status;
    }

    isActive(): boolean {
        return this.status === Activity.ACTIVE;
    }

    isDnd(): boolean {
        return this.status === Activity.DND;
    }

    isAway(): boolean {
        return this.status === Activity.AWAY;
    }

    isOffline(): boolean {
        return this.status === Activity.OFFLINE;
    }

    isInactive(): boolean {
        return this.status === Activity.INACTIVE;
    }

    isInvisible(): boolean {
        return this.status === Activity.INVISIBLE;
    }

    getDynamicClass(): object {
        return {
            'bg-success': this.isActive(),
            'bg-secondary': this.isOffline() || this.isInactive() || this.isInvisible(),
            'bg-warning': this.isAway(),
            'bg-danger': this.isDnd()
        };
    }

    getDynamicText(): string {
        switch (this.status) {
            case Activity.ACTIVE:
                return 'available';
            case Activity.INVISIBLE:
            case Activity.OFFLINE:
                return 'offline';
            case Activity.DND:
                return 'do_not_disturb';
            case Activity.INACTIVE:
            case Activity.AWAY:
                return 'away';
        }
    }
}
