<div class="row">
    <div class="col-lg-4 order-2 order-lg-1">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="pr-0"><i class="fas fa-info-circle text-primary"></i> Aide</h2>
            </div>

            <div class="card-body">
                <p>Vous ne trouvez pas de réponse à votre question dans la FAQ ? Contactez le service client.</p>
                <a [routerLink]="['/customer-service-contact']" class="btn btn-sm btn-primary float-right">Service client</a>
            </div>
        </div>
    </div>
    <div class="col-lg-8 order-1 order-lg-2">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="d-none d-lg-block pr-0"><i class="fa fa-question-circle mr-2 text-primary"></i>Foire aux questions</h2>
                <h2 class="d-block d-lg-none pr-0"><i class="fa fa-question-circle mr-2 text-primary"></i>Foire aux questions</h2>
            </div>

            <div class="card-body">
                <div id="accordion">
                    <div *ngFor="let data of faq; let i = index" [attr.data-index]="i" class="card mb-2">
                        <div class="card-header p-0" id="heading{{ i }}">
                            <h5 class="mb-0">
                                <button class="btn btn-link btn-block text-left faq"
                                        data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="false"
                                        [attr.aria-controls]="'collapse' + i">
                                    {{ data.question }}
                                    <i class="fas fa-angle-down fleche-collapse fleche-faq"></i>
                                    <i class="fas fa-angle-up fleche-collapse fleche-faq"></i>
                                    <!--                                    <i class="fa fleche-collapse fleche-faq" aria-hidden="true"></i>-->
                                </button>
                            </h5>
                        </div>
                        <div id="collapse{{ i }}" class="collapse" [attr.aria-labelledby]="'heading' + i" data-parent="#accordion">
                            <div class="card-body text-left">
                                {{ data.answer }}

                                <div class="text-right mt-4">
                                    <span>{{ 'faq.useful_answer' | translate }}</span>
                                    <a href="" class="btn btn-dark btn-sm text-uppercase ml-2 mr-1">{{ 'no' | translate }}</a>
                                    <a href="" class="btn btn-primary text-uppercase btn-sm">{{ 'yes' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!--<div>-->
<!--    <h2><i class="fa fa-question-circle ico ico1 mr-2"></i><span>{{ 'faq.title' | translate }}</span></h2>-->
<!--    <hr>-->
<!--</div>-->
<!--<div class="row text-center">-->
<!--    <div class="col-12 wow fadeInUp" id="faq" style="text-align: justify">-->
<!--        <div id="accordion" class="pb-5">-->
<!--            <div *ngFor="let data of faq; let i = index" [attr.data-index]="i" class="card mb-2">-->
<!--                <div class="card-header p-0" id="heading{{ i }}">-->
<!--                    <h5 class="mb-0">-->
<!--                        <button class="btn btn-link btn-block text-left faq collapsed wrap"-->
<!--                                data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="false"-->
<!--                                [attr.aria-controls]="'collapse' + i">-->
<!--                            {{ data.question }}-->
<!--                        </button>-->
<!--                    </h5>-->
<!--                </div>-->
<!--                <div id="collapse{{ i }}" class="collapse" [attr.aria-labelledby]="'heading' + i" data-parent="#accordion">-->
<!--                    <div class="card-body">-->
<!--                        {{ data.answer }}-->
<!--                        <hr class="faq-hr">-->
<!--                        <div class="text-right">-->
<!--                            <span>{{ 'faq.useful_answer' | translate }}</span>-->
<!--                            <a href="" class="btn btn-secondary text-uppercase">{{ 'yes' | translate }}</a>-->
<!--                            <a href="" class="btn btn-secondary text-uppercase">{{ 'no' | translate }}</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <p class="mt-4 text-muted"> {{ 'faq.no_answer_to_question' | translate: getDefaultPhoneNumber() }}</p>-->
<!--            <br>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
