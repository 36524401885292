import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {ProfileService} from '../../../../services/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-finish-signup-password',
    templateUrl: './finish-signup-password.component.html',
    styleUrls: ['./finish-signup-password.component.scss']
})
export class FinishSignupPasswordComponent implements OnInit {

    @Output() nextStep = new EventEmitter<number>();
    @Output() previousStep = new EventEmitter<number>();
    @Output() setStepCache = new EventEmitter<any>();
    @Input() stepCacheData;
    @Input() currentStep: number;

    password: string;
    passwordType = 'password';
    errorMessage = '';
    requestPassword = undefined;

    constructor(
        private currentUser: CurrentUserService,
        private profileService: ProfileService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        if (typeof this.stepCacheData !== 'undefined' && typeof this.stepCacheData.password !== 'undefined') {
            this.password = this.stepCacheData.password.value;
            this.errorMessage = this.stepCacheData.password.errorMessage;
        }
    }

    updateCacheData() {
        let cacheData: any = this.stepCacheData;

        if (typeof cacheData === 'undefined') {
            cacheData = {};
        }

        cacheData.password = {
            value: this.password,
            errorMessage: this.errorMessage,
        };

        this.setStepCache.emit(cacheData);
    }

    next() {
        this.errorMessage = '';
        this.currentUser.getAccount().getProfile().getFieldsStatus().password = 'provided';
        this.updateCacheData();
        this.nextStep.emit();
    }

    previous() {
        this.updateCacheData();
        this.errorMessage = '';
        this.previousStep.emit();
    }

    toggleVisibility() {
        if (this.passwordType === 'password') {
            this.passwordType = 'text';
        } else {
            this.passwordType = 'password';
        }
    }

    checkPassword(update: boolean = false) {
        if (!this.password || this.password === '') {
            return false;
        }

        if (this.requestPassword !== undefined) {
            this.requestPassword.unsubscribe();
        }
        this.requestPassword = this.currentUser.validPassword(this.password).subscribe((response: any) => {
            if (response.data.error === 'lowercase') {
                this.errorMessage = this.translate.instant('on_boarding.invalid_password_lowercase');
                return false;
            }
            if (response.data.error === 'uppercase') {
                this.errorMessage = this.translate.instant('on_boarding.invalid_password_uppercase');
                return false;
            }
            if (response.data.error === 'number') {
                this.errorMessage = this.translate.instant('on_boarding.invalid_password_number');
                return false;
            }
            if (response.data.error === 'special') {
                this.errorMessage = this.translate.instant('on_boarding.invalid_password_special');
                return false;
            }
            if (response.data.error === 'length') {
                this.errorMessage = this.translate.instant('on_boarding.invalid_password_length');
                return false;
            } else if (update) {
                this.update();
            }
            this.errorMessage = '';
        }, () => {
            this.errorMessage = this.translate.instant('error_list.technical_issue');
        });
    }

    update() {
        this.currentUser.updatePasswordFinish(this.password, () => {
            this.next();
        });
    }

    getDescription() {
        let description = this.translate.instant('on_boarding.password_desc');
        description += '<br class="br-mobile"> ';
        description += this.translate.instant('on_boarding.use_password_form');

        return description;
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }
}
