<div class="card border-0 box mb-4">
    <div class="card-header">
        <h2>{{ 'his_photos' | translate }}</h2>
    </div>
    <div class="card-body">
        <div #sizer>
            <div class="d-none d-lg-block" data-size="lg"></div>
            <div class="d-none d-md-block d-lg-none" data-size="md"></div>
            <div class="d-block d-md-none" data-size="sm"></div>
        </div>
        <div #lightGallery>
            <ngx-slick-carousel class="carousel"
                                #slick
                                #slickModal="slick-carousel"
                                [config]="slideConfig"
                                (window:resize)="initSlickConfig()">
                <div ngxSlickItem *ngFor="let photo of photos" class="slide gallery" [attr.data-src]="photo.url" [attr.data-sub-html]="photo.name">
                    <div>
                        <div class="gal w-100 d-inline-block">
                            <a (click)="openGallery()" tabindex="0">
                                <img src="{{ photo.url }}" [alt]="photo.name" class="w-100"/>
                            </a>
                        </div>
                    </div>
                </div>

                <div ngxSlickItem class="slide">
                    <div class="card card-profile text-center mb-0 nogal">
                        <a class="nogal" tabindex="-1">
                            <div class="row d-flex justify-content-center align-items-center request more no-gutters">
                                <div class="col-12 text-center">
                                    <i class="fas fa-camera text-primary plus-photos"></i><br>
                                    <div (click)="openAddMorePhotoModal()" class="btn btn-sm btn-primary">
                                        <span class="d-none d-lg-block">{{ 'albums.add_more_photo' | translate }}</span>
                                        <span class="d-block d-lg-none">{{ 'albums.add_more_photo_mobile' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
