<!-- step 4 -->
<div class="modal-body step-confirmation">
    <div class="container text-center">
        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/confirmation.png"><br>
        <h1>{{ 'on_boarding.confirmation' | translate }}</h1>
        <p>
            {{ 'on_boarding.profile_complete' | translate }}
            <br class="br-mobile"> {{ 'on_boarding.start_browsing' | translate }}
        </p>
        <p class="my-2 finish-confirmation-check-email">
            {{ 'on_boarding.profile_pending' | translate }}
            <br class="br-mobile">
            {{ 'on_boarding.remember_check_email' | translate }}
        </p>
    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a (click)="close()" data-toggle="modal" data-dismiss="modal" class="btn btn-primary btn-sm text-uppercase">
        {{ 'on_boarding.finish' | translate }}
    </a>
</div>
