<div class="modal-body step-birthdate">

    <div class="container text-center">

        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/naissance.png"><br>
        <h1>{{ 'on_boarding.birthdate_title' | translate }}</h1>
        <p class="infos w-100 text-center">
            {{ 'on_boarding.birthdate_gift' | translate }}<br class="br-mobile">
            <span [innerHTML]="'on_boarding.birthdate_please-complete_form' | translate "></span></p>
        <div class="form-group mb-0">
            <div class="form-row d-flex align-items-center justify-content-center">
                <div class="col">
                    <input #birthdateDayField [(ngModel)]="birthdateDay" maxlength="2" (keyup)="onDayKeyPress()" (blur)="checkDayFormat()" class="form-control form-control-lg" placeholder="{{ 'on_boarding.day' | translate }}" type="text">
                </div>
                <div class="col-1">
                    <span class="sep-form">/</span>
                </div>
                <div class="col">
                    <input #birthdateMonthField [(ngModel)]="birthdateMonth" maxlength="2" (keyup)="onMonthKeyPress()" (blur)="checkMonthFormat()" class="form-control form-control-lg" placeholder="{{ 'on_boarding.month' | translate }}" type="text">
                </div>
                <div class="col-1">
                    <span class="sep-form">/</span>
                </div>
                <div class="col-5">
                    <input #birthdateYearField [(ngModel)]="birthdateYear" maxlength="4" class="form-control form-control-lg" placeholder="{{ 'on_boarding.year' | translate }}" type="text">
                </div>
            </div>

        </div>

        <div class="errorSpace mt-2">
            <small class="alert alert-danger mt-2" *ngIf="hasCompletedBirthDate() && !isCorrectBirthDate()">
                {{ 'on_boarding.birthdate_error' | translate }}
            </small>

            <small class="alert alert-danger mt-2" *ngIf="hasCompletedBirthDate() && isCorrectBirthDate() && !isAdult()">
                {{ 'on_boarding.must_be_adult_to_use_service' | translate }}
            </small>
        </div>
    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton()" (click)="previous()" class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="next()" class="btn btn-primary btn-sm text-uppercase" [ngClass]="{'disabled': !hasCompletedBirthDate() || !isAdult() || !isCorrectBirthDate()}">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
