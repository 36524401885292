<div *ngIf="getCamModel() && !showStep.isLoading()" class="row d-flex align-items-center">
    <div class="col-lg-6">
        <h1 class="h5 font-weight-bold my-1">
            <div class="d-lg-none row">
                <div id="mobile-nickname" class="col">{{ getCamModel().getNickname() }}</div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <i (click)="openDetailModal()" class="fas fa-info-circle"></i>
                        </div>
                        <div class="col">
                            <i (click)="toggleFavorite()" [class.text-warning]="getCamModel().isFavorite" class="fas fa-heart"></i>
                        </div>
                        <div class="col">
                            <span (click)="toggleSound()">
                                <i *ngIf="!sound" class="fas fa-fw fa-lg fa-slash"></i>
                                <i class="fas fa-volume-up fa-fw"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <span *ngIf="!getCamModel().isPrivateShow()" class="d-none d-lg-inline-block">{{ 'you_are_live_with' | translate }} {{ getCamModel().getNickname() }}</span>
            <span *ngIf="getCamModel().isPrivateShow() && !getCamModel().isPeepingShow()"
                  class="d-none d-lg-inline-block">{{ 'you_are_alone_with' | translate }} {{ getCamModel().getNickname() }}</span>
            <span *ngIf="getCamModel().isPrivateShow() && getCamModel().isPeepingShow()"
                  class="d-none d-lg-inline-block">{{ 'peeping_mode_with' | translate }} {{ getCamModel().getNickname() }}</span>
        </h1>
    </div>
</div>

<div [class.d-none]="showStep.isLoading()"
     [class.vip-show]="getCamModel() && getCamModel().isVIPShow()"
     [class.private-show]="getCamModel() && getCamModel().isPrivateShow()" class="row mt-0 mt-lg-2">
    <div class="col-12">
        <div class="card border-0 card-live">
            <div class="card-body">
                <div class="row">

                    <div [class.col]="getCamModel() && getCamModel().isPeepingShow()"
                         [class.col-lg-6]="!expandVideo && (!getCamModel() || !getCamModel().isPeepingShow())"
                         [class.col-lg-8]="expandVideo && (!getCamModel() || !getCamModel().isPeepingShow())">
                        <div class="profil">
                            <div *ngIf="getCamModel() && getCamModel().isOnline() && getCamModel().isFreeShow()" class="badge badge-success enligne">{{ 'available_model' | translate }}</div>
                            <div *ngIf="getCamModel() && getCamModel().isOnline() && getCamModel().isVIPShow()"
                                 class="badge badge-primary enligne">{{ 'vip_show' | translate }}</div>
                            <div *ngIf="getCamModel() && getCamModel().isOnline() && getCamModel().isPrivateShow() && !getCamModel().isPeepingShow()"
                                 class="badge badge-warning enligne">{{ 'private_show' | translate }}</div>
                            <div *ngIf="getCamModel() && getCamModel().isOnline() && getCamModel().isPrivateShow() && getCamModel().isPeepingShow()"
                                 class="badge badge-success enligne">{{ 'peeping_mode' | translate }}</div>
                            <div *ngIf="getCamModel() && !getCamModel().isOnline()" class="badge badge-secondary enligne">{{ 'offline' | translate }}</div>

                            <div id="videoElements">
                                <div [class.d-none]="showStep.isPlaying()">
                                    <img *ngIf="getCamModel()"
                                         [src]="getSexCamsService().lastVideoFrame ? getSexCamsService().lastVideoFrame : getCamModel().getPhoto()"
                                         class="w-100"/>
                                    <i id="video-loader" class="fa fa-5x fa-spinner fa-spin position-absolute"></i>
                                </div>
                                <div [class.d-none]="!showStep.isPlaying()" class="w-100" id="video">
                                    <video class='w-100' id='rtc-video' playsinline autoplay></video>
                                </div>
                            </div>

                            <div *ngIf="getCamModel() && getCamModel().hasHD()" class="badge badge-info hd">HD</div>

                            <div *ngIf="getCamModel() && !getCamModel().isFreeShow()" class="live-price">
                                <div *ngIf="getCamModel().isPrivateShow() && !getCamModel().isPeepingShow() && getCamModel().getCoinsByMinute('O2O')" class="badge badge-dark mb-1">
                                    <span class="text-uppercase">{{ 'private_show' | translate }}</span> : {{ getCamModel().getCoinsByMinute('O2O') }} LC / mn
                                </div>
                                <div *ngIf="getCamModel().isVIPShow() && getCamModel().getCoinsByMinute('O2M')" class="badge badge-dark mb-1">
                                    <span class="text-uppercase">{{ 'vip_show' | translate }}</span> : {{ getCamModel().getCoinsByMinute('O2M') }} LC / mn
                                </div>
                                <div *ngIf="getCamModel().isPeepingShow() && getCamModel().getCoinsByMinute('V')" class="badge badge-dark mb-1">
                                    <span class="text-uppercase">{{ 'peeping_show' | translate }}</span> : {{ getCamModel().getCoinsByMinute('V') }} LC / mn
                                </div>
                            </div>
                        </div>
                        <span *ngIf="getCamModel()" class="input-group-btn d-none d-lg-flex btn-live mx-2 mx-lg-0">
                            <a (click)="openDetailModal()" class="btn btn-dark mt-2 mr-1" data-toggle="modal">
                                <i class="fas fa-info-circle" [matTooltip]="'model_information' | translate"></i>
                            </a>
                            <a (click)="toggleFavorite()" [class.text-warning]="getCamModel().isFavorite"
                               [matTooltip]="(getCamModel().isFavorite ? 'contact.remove_from_favorite' : 'contact.add_to_favorite') | translate"
                               class="btn btn-dark mt-2 mr-1">
                                <i class="fas fa-heart"></i>
                            </a>
                            <a (click)="toggleSound()" [matTooltip]="(sound ? 'turn_sound_off' : 'turn_sound_on') | translate" class="btn btn-dark mt-2 mr-1">
                                <i *ngIf="!sound" class="fas fa-fw fa-lg fa-slash"></i>
                                <i class="fas fa-volume-up fa-fw"></i>
                            </a>
                            <a (click)="toggleFullscreen()" class="btn btn-dark mt-2 mr-1 d-none d-sm-block">
                                <i [class.fa-expand]="!expandVideo" [class.fa-compress]="expandVideo" class="fas fa-fw"></i>
                            </a>
                            <a (click)="openLoveCoinModal()" class="btn btn-secondary text-uppercase mt-2 d-flex w-100">
                                <i class="fas fa-coins pt-1 mr-2"></i>
                                <span class="d-xl-none mx-auto">{{ 'lovecoins.credit' | translate }}</span>
                                <span class="d-none d-xl-inline mx-auto">{{ 'booster.credit_my_account' | translate }}</span>
                            </a>
                        </span>

                        <div *ngIf="getCamModel() && !getCamModel().isPrivateShow()" class="row mx-2 mx-lg-0">
                            <div *ngIf="getCamModel() && getCamModel().isFreeShow()" [class.col-md-12]="!getCamModel().allowsPrivate()" [class.pr-1]="!getCamModel().allowsPrivate()" class="col pl-0 pr-0">
                                <a (click)="joinVipShow(true)" class="btn btn-primary text-uppercase mt-1 btn-block btn-cam">
                                    <img [matTooltip]="'menu.chat' | translate" class="d-none d-md-inline" alt="webcam" src="assets/img/common/webcam.png">
                                    {{ 'join_vip' | translate }} <small>({{ getCamModel().getCoinsByMinute('O2M') }} LC / mn)</small>
                                    <i [matTooltip]="'vip_bubble_info' | translate" class="fas fa-info-circle live-infos d-none d-md-inline"></i>
                                </a>
                            </div>

                            <div *ngIf="getCamModel().allowsPrivate()" [class.col-md-12]="getCamModel().isVIPShow()"
                                 [class.col]="getCamModel().isFreeShow()"
                                 [class.pl-1]="getCamModel().isFreeShow()" class="pr-0 pl-0">
                                <a (click)="joinPrivateShow()" class="btn btn-warning text-uppercase mt-1 btn-block btn-cam">
                                    <img [matTooltip]="'menu.chat' | translate" class="d-none d-md-inline" alt="webcam" src="assets/img/common/webcam.png">
                                    {{ 'join_private' | translate }} <small>({{ getCamModel().getCoinsByMinute('O2O') }} LC / mn)</small>
                                    <i [matTooltip]="'private_bubble_info' | translate" class="fas fa-info-circle live-infos d-none d-md-inline"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="getCamModel() && !getCamModel().isPeepingShow()"
                         [class.col-lg-6]="!expandVideo" [class.col-lg-4]="expandVideo"
                         class="mt-2 mt-lg-0 col-chat">
                        <div [class.bg-primary-light]="getCamModel().isVIPShow()" [class.bg-light]="getCamModel().isFreeShow()"
                             class="pt-4 pb-4 pl-4 h-100 d-flex align-items-start flex-column chat">
                            <div class="card-header text-center d-flex align-items-center">
                                <span *ngIf="getCamModel().isFreeShow() || getCamModel().isVIPShow()">
                                    <span class="text-uppercase">{{ (getCamModel().isFreeShow() ? 'menu.chat' : 'vip_chat') | translate }}</span> 
                                    <span class="text-lowercase d-none d-lg-inline">({{ getVisitorCount() }} {{ (getVisitorCount() > 1 ? 'chat.online_users' : 'chat.online_user') | translate }})</span>
                                    <span class="text-lowercase d-lg-none font-size-10px">({{ getVisitorCount() }} {{ (getVisitorCount() > 1 ? 'users' : 'user') | translate }})</span>
                                </span>
                                <span *ngIf="getCamModel().isPrivateShow()" class="text-uppercase">{{ 'private_chat' | translate }}</span>

                                <ul *ngIf="!getCamModel().isPrivateShow()" class="nav nav-tabs ml-auto border-0 room" role="tablist">
                                    <li (click)="setPrivateChatRoom(false)" class="nav-item">
                                        <span [class.active]="!isPrivateChatRoom()"
                                              [class.bb-primary]="!isPrivateChatRoom()"
                                              [class.text-primary]="getCamModel().isVIPShow() && !isPrivateChatRoom()"
                                              [class.text-hover-primary]="!getCamModel().isVIPShow()"
                                              class="nav-chat-icon">
                                            <i class="far fa-comment"></i><br>
                                            <span *ngIf="isPrivateChatRoom() && getUnreadMessage()" class="chat-counter bg-dark">{{ getUnreadMessage() }}</span>
                                        </span>
                                    </li>
                                    <li (click)="setPrivateChatRoom(true)" class="nav-item">
                                        <span [class.active]="isPrivateChatRoom()"
                                              [class.bb-primary]="isPrivateChatRoom()"
                                              [class.text-primary]="getCamModel().isVIPShow() && isPrivateChatRoom()"
                                              [class.text-hover-primary]="!getCamModel().isVIPShow()"
                                              class="nav-chat-icon">
                                            <i class="far fa-comment"></i>
                                            <span class="private-room text-uppercase">{{ 'albums.lock_private' | translate }}</span>
                                            <span *ngIf="!isPrivateChatRoom() && getUnreadMessage()" class="chat-counter bg-dark">{{ getUnreadMessage() }}</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div #messageContainer class="chat-message-container mb-auto w-100 pr-2">
                                <p *ngFor="let message of getMessages()" class="mb-1">
                                    <ng-container *ngIf="showMessage(message)">
                                        <span *ngIf="message.isText()" [class.text-primary]="!message.isVisitor">
                                            <b>{{ message.nickname }} :</b> <span [innerHTML]="message.message | formatHtml"></span>
                                        </span>
                                        <span *ngIf="!message.isText()" class="text-danger"><b>{{ message.message | translate }}</b></span>
                                    </ng-container>
                                </p>
                            </div>

                            <div class="card-footer d-none d-lg-inline-block">
                                <form class="navbar-form">
                                    <div class="input-group">
                                        <input [(ngModel)]="getSexCamsService().chatMessage"
                                               [placeholder]="'chat.set_message' | translate"
                                               class="form-control form-control-lg border-0" name="chat-message" type="text" autocomplete="off"/>

                                        <span class="input-group-btn">
                                            <!--
                                            <button (click)="toggleEmojis()" class="btn btn-light border-0 ml-1">
                                                <i class="far fa-smile" aria-hidden="true"></i>
                                            </button>
                                            -->
                                            <button (click)="sendMessage()"
                                                    [class.btn-primary]="!getCamModel().isVIPShow()" [class.btn-primaire]="getCamModel().isVIPShow()"
                                                    class="btn border-0 ml-1">
                                                <i class="fa fa-paper-plane text-white" aria-hidden="true"></i>
                                            </button>
                                        </span>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="getCamModel() && !getCamModel().isPeepingShow()" class="card-footer d-block d-lg-none">
        <form class="navbar-form">
            <div class="input-group">
                <input [(ngModel)]="getSexCamsService().chatMessage"
                       [placeholder]="'chat.set_message' | translate"
                       class="form-control form-control-lg border-0" name="chat-message" type="text"/>

                <span class="input-group-btn">
                    <!--
                    <button (click)="toggleEmojis()" class="btn btn-light border-0 ml-1">
                        <i class="far fa-smile" aria-hidden="true"></i>
                    </button>
                    -->
                    <button (click)="sendMessage()" [class.btn-primary]="!getCamModel().isVIPShow()" [class.btn-primaire]="getCamModel().isVIPShow()" class="btn border-0 ml-1">
                        <i class="fa fa-paper-plane text-white" aria-hidden="true"></i>
                    </button>
                </span>
            </div>
        </form>
    </div>
</div>
