import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Photo} from './photo';
import {User} from '../chat/user/user';
import {Gender} from '../user/gender';

@Injectable({
    providedIn: 'root'
})
export class Album implements Deserializable {

    static readonly DEFAULT_PROFILE = 1;
    static readonly DEFAULT_COVER = 2;
    static readonly CUSTOM = 3;

    static readonly EVERYONE = 0; // public privacy
    static readonly ON_REQUEST = 1; // private privacy
    static readonly FRIEND_ONLY = 2;

    id: number;
    name: string;
    updatedAt: Date;
    privacy = Album.EVERYONE;
    photos = {};
    profile: boolean;
    totalPhoto = 0;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.photos) {
            this.setPhotos(input.photos);
        }

        return this;
    }

    getId(): number {
        return this.id;
    }

    setId(id: number) {
        this.id = id;
    }

    getName(): string {
        return this.name;
    }

    setName(name: string) {
        this.name = name;
    }

    setNameFromType(type: number) {
        if (type === Album.DEFAULT_PROFILE) {
            this.name = 'DEFAULT_PROFILE_ALBUM';
        } else if (type === Album.DEFAULT_COVER) {
            this.name = 'COVER_ALBUM';
        }
    }

    getDate(): Date {
        return this.updatedAt;
    }

    setDate(date: Date) {
        this.updatedAt = date;
    }

    getPrivacy() {
        return this.privacy;
    }

    setPrivacy(lock) {
        this.privacy = lock;
    }

    getPhotos() {
        return this.photos;
    }

    /**
     * This methods count how many photos were really returned.
     */
    getPhotosLength(): number {
        return Object.keys(this.photos).length;
    }

    unsetMainPhoto() {
        const keys = Object.keys(this.photos);
        for (const key of keys) {
            this.photos[key].setMain(false);
        }
    }

    setPhotos(photosData) {
        const photos = {};

        for (const photoData of photosData) {
            let photo = new Photo();
            photo = photo.deserialize(photoData);
            photos[photo.getId()] = photo;
        }

        this.photos = photos;
    }

    addPhoto(photo: Photo) {
        this.photos[photo.getId()] = photo;
        this.totalPhoto++;
    }

    removePhoto(photo: Photo) {
        delete this.photos[photo.getId()];
        this.totalPhoto--;
    }

    getFirstPhotoUrl(gender = Gender.MALE): string {
        const keys = Object.keys(this.photos);
        return keys.length > 0 ? this.photos[keys[0]].getUrl() : `assets/img/250x250/default_user_${gender}.png`;
    }

    hasProfilePicture(profilePicture: string) {
        const photos: Photo[] = Object.values(this.photos);
        for (const photo of photos) {
            if (photo.getUrl() === profilePicture) {
                return true;
            }
        }

        return false;
    }

    isDefault(): boolean {
        return (this.isDefaultCover() || this.isDefaultProfile());
    }

    isDefaultProfile(): boolean {
        return this.name === 'DEFAULT_PROFILE_ALBUM';
    }

    isDefaultCover(): boolean {
        return this.name === 'COVER_ALBUM';
    }

    getType(): number {
        if (this.isDefaultProfile()) {
            return Album.DEFAULT_PROFILE;
        } else if (this.isDefaultCover()) {
            return Album.DEFAULT_COVER;
        }
        return Album.CUSTOM;
    }

    getTranslatedName(): string {
        if (this.isDefaultProfile()) {
            return 'albums.default_profile';
        } else if (this.isDefaultCover()) {
            return 'albums.default_cover';
        }

        return this.name;
    }

    /**
     * This method returns the real number of photo.
     */
    getTotalPhoto(): number {
        return this.totalPhoto;
    }

    isPrivate(): boolean {
        return this.privacy === Album.ON_REQUEST;
    }

    isFriendOnly(): boolean {
        return this.privacy === Album.FRIEND_ONLY;
    }
}
