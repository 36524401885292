import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class ReceivedFriendRequestNotificationType extends AbstractNotificationType {

    static readonly TYPE = 4;

    protected buttons:NotificationButton[] = [
        new NotificationButton('accept', () => {
            this.friendStatus(1);
        }),
        new NotificationButton('refuse', () => {
            this.friendStatus(2);
        }),
    ];

    getText() {
        return this.notification ?
            'notification_type.4_' + this.notification.getFromProfile().getGender() :
            'notification_type.4_1';
    }

    friendStatus(status: number) {
        this.actionService.friendStatus(this.notification.fromProfile.id, status).subscribe(() => {
            this.notification.delete();
        });
    }
}
