import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/chat/user/user';
import {ChatService} from '../../../services/chat/chat.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
    selector: 'app-chat-users-connected',
    templateUrl: './chat-users-connected.component.html',
    styleUrls: ['./chat-users-connected.component.scss']
})
export class ChatUsersConnectedComponent {

    public showActionConnected: object = {};

    @Input() title: string;
    @Input() users: User[];

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        public chatService: ChatService,
        public chatRoomService: ChatRoomService,
        public chatUserService: ChatUserService,
        protected currentUserService: CurrentUserService,
        protected modalService: ModalService,
        protected translate: TranslateService,
        protected profileService: ProfileService
    ) {
    }

    shutdownActionConnected(user, event: Event) {
        const target = event.target as HTMLElement;

        if (target.parentElement && target.parentElement.id !== 'button-action-connected-' + user.getUid()) {
            this.showActionConnected[user.getUid()] = false;
        }
    }

    toggleActionConnected(user: User) {
        this.showActionConnected[user.getUid()] = this.showActionConnected[user.getUid()] ? !this.showActionConnected[user.getUid()] : true;
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }

    getSortedUsers(): User[] {
        return this.users.sort((a, b) => {
            return a.getDistance() - b.getDistance();
        });
    }
}
