<app-modal>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
        <div class="modal-body">
            <div class="container ">
                <div class="form-group">
                    <label>{{ ('settings.input_new_' + field) | translate }}</label>
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-text"><i class="fas fa-{{ icon }}"></i></span>
                            <input [type]="getInputType(field)" name="newField" ngModel required #newField="ngModel" class="form-control form-control-lg mr-1" placeholder="{{ ('settings.new_' + field) | translate }}">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-text monemail"><i class="fas fa-{{ icon }}"></i></span>
                        <input [type]="getInputType(field)" name="newFieldConfirm" ngModel required #newFieldConfirm="ngModel" class="form-control form-control-lg mr-1" placeholder="{{ ('settings.confirm_new_' + field) | translate }}">
                    </div>
                </div>
                <small *ngIf="messageError" class="alert alert-danger mt-2">
                    {{ messageError }}
                </small>
            </div>
        </div>

        <div class="modal-footer">
            <button data-dismiss="modal" class="btn btn-dark btn-sm mr-2 text-uppercase" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
            <button type="submit" class="btn btn-primary btn-sm text-uppercase">{{ 'save' | translate }}</button>
        </div>
    </form>
</app-modal>
