import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, of, throwError, timer} from 'rxjs';
import {StorageService} from '../services/storage/storage.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../services/login/login.service';
import {catchError, concatMap, delayWhen, retryWhen} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CurrentUserService} from '../services/user/current-user.service';
import {ModalService} from '../services/modal/modal.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private storageService: StorageService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private loginService: LoginService,
        private currentUserService: CurrentUserService,
        private modalService: ModalService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storageService.get('token');

        if (!token) {
            return next.handle(req);
        }

        const req1 = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
        });

        return next.handle(req1).pipe(retryWhen(errorNotifier =>
            errorNotifier.pipe(
                concatMap((error, count) => {
                    if (count <= 2 && error.status === 504 && error.url.includes('/assets/i18n/')) {
                        return of(error);
                    }
                    return throwError(error);
                }),
                delayWhen(count => timer(count * 500))
            )
        ), catchError((response: HttpErrorResponse) => this.errorHandler(response, next)));
    }

    errorHandler(response: HttpErrorResponse, next: HttpHandler) {
        // no error message to process
        if (!response) {
            return throwError('Request failed. No error response.');
        } else if (response.status === 0 || (!response.error && (!response.status || !response.url))) {
            return EMPTY;
        } else if (!response.error) {
            if (response.status === 504 && response.url.includes('/assets/i18n/')) {
                return EMPTY;
            } else {
                return throwError(`Request failed [${response.status}]. No error message for url ${response.url}.`);
            }
        }

        const data = response.error.data;
        if (data && data.error && this.translateError('api_error_code.' + data.error.code, data.error.parameters)) {
            return throwError(data.error);
        }

        const errorMessage = response.error.message || response.statusText;

        switch (response.status) {
            case 400:
                if (response.url.includes(`mobile/send-code`)) {
                    return throwError(response.message);
                }
                return throwError(response);
            case 401:
                this.loginService.removeAuthentication();
                if (!this.router.url.startsWith('/login')) {
                    this.loginService.logout();
                }
                break;
            case 403:
                if (response.url.includes('/profile/' + this.currentUserService.profileId)) {
                    this.loginService.logout();
                }

                if (this.toastr && !response.url.includes(environment.backendUrl)) {
                    this.toastr.error(
                        this.translate.instant('error_list.unauthorized') + response.url
                    );
                }
                this.router.navigate(['/home']);
                break;
            case 404:
                if (response.url.includes(`/profile/${this.currentUserService.profileId}/suspend`)) {
                    this.tokenExpired('suspension', 'suspend_account');
                }
                if (response.url.includes(`/profile/${this.currentUserService.profileId}/delete`)) {
                    this.tokenExpired('deletion','delete_account');
                }
                if (response.url.includes('/site/content/static-page')) {
                    this.router.navigate(['home']);
                }

                return throwError(response);
            case 409:
                this.translateError('error_list.' + errorMessage);
                break;
            case 500:
                if (this.toastr && !response.url.includes(environment.backendUrl)) {
                    this.toastr.error(
                        this.translate.instant('error') + ' ' + response.status + ' : ' + errorMessage
                    );
                }

                return throwError('v2 Error 500: request failed for url ' + response.url);
        }

        return EMPTY;
    }

    protected tokenExpired(action: string, index: string) {
        this.modalService.openFullScreenConfirmation({
            title: `account.my_account.${index}.modal.title`,
            faClass: action === 'deletion' ? 'fas fa-trash-alt' : 'fas fa-hand-paper',
            strongQuestion: true,
            sampleText: 'token_expired.' + index,
            question: 'token_expired.question_retry',
            responses: [
                {
                    text: 'token_expired.answer_retry',
                    className: 'btn-primary',
                    result: true
                },
                {
                    text: 'token_expired.cancel_request',
                    className: 'btn-light',
                    result: false
                }
            ],
            asyncValidationCallback: async (result) => {
                if (result) {
                    const subject = this.currentUserService.retryStatusRequest(action);
                    if (subject !== false) {
                        subject.subscribe();
                        this.router.navigate(['/pending-confirmation/' + action]);
                    }
                } else {
                    this.router.navigate(['/home']);
                }
                return true;
            }
        });
    }

    protected translateError(index, parameters = {}): boolean {
        const translatedError = this.translate.instant(index, parameters);

        if (this.toastr && translatedError !== index) {
            this.toastr.error(translatedError);
            return true;
        }
        return false;
    }
}
