<!-- step 1 -->
<div class="modal-body step-nickname">

    <div class="container text-center">

        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/motdepasse.png"><br>
        <h1>{{ 'on_boarding.choose_password' | translate }}</h1>
        <p class="infos w-100 text-center" [innerHTML]="getDescription()"></p>
        <div class="form-group mb-0">
            <div class="input-group w-100">
                <span class="input-group-text" style="min-width:42px;"><i class="fas fa-user mx-auto"></i></span>
                <input (keyup)="checkPassword()" [type]="passwordType" [(ngModel)]="password" name="password" autocomplete="false" class="form-control"
                       placeholder="{{ 'on_boarding.choose_password_input' | translate }}">
                <button class="voirpass" (click)="toggleVisibility()"><i class="fas fa-eye"></i></button>
            </div>
            <div class="errorSpace mt-2">
                <small class="fileHelp form-text text-muted text-left">{{ 'on_boarding.good_password' | translate }}</small>
                <small class="alert alert-danger m-0" *ngIf="errorMessage !== ''">
                    {{errorMessage}}
                </small>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton()" (click)="previous()" class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="checkPassword(true)"
       class="btn btn-primary btn-sm text-uppercase" [class.disabled]="errorMessage !== ''">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
