import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../environments/environment';
import {AbstractUser} from '../models/user/abstract-user';

@Pipe({
    name: 'userImage'
})
export class UserImagePipe implements PipeTransform {

    transform(user: AbstractUser, size): any {

        const m = size.match(/([0-9]+)x([0-9]+)/);
        const dims = m.length === 3 ? `${m[1]}x${m[2]}` : '250x250';

        if (user === null) {
            return `assets/img/${dims}/default_user_1.png`;
        }

        if (user && user.getPhoto() === null) {
            if (user.isMale()) {
                return `assets/img/${dims}/default_user_1.png`;
            } else {
                return `assets/img/${dims}/default_user_2.png`;
            }
        }

        if (user && user.getPhoto() && size) {
            return environment.imageTools + dims + '.jpg?' + user.getPhoto();
        }

        return user ? user.getPhoto() : false;
    }
}
