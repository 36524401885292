import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {Country} from '../../../models/country/country';
import {CountryService} from '../../../services/country/country.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {SiteService} from '../../../services/site/site.service';

@Component({
    selector: 'app-mobile-form',
    templateUrl: './mobile-form.component.html',
    styleUrls: ['./mobile-form.component.scss']
})
export class MobileFormComponent implements OnInit {

    @Input() mobile: string;
    @Input() formDisabled: boolean
    @Output() form = new EventEmitter<object>();
    mobileValid: boolean;

    selecting = false;
    showFilter = false;
    countryFilter = '';

    selectedCountry: Country;

    constructor(
        public countryService: CountryService,
        private siteService: SiteService,
        private currentUserService: CurrentUserService
    ) {}

    ngOnInit(): void {
        this.selectedCountry = this.countryService.getCountryDialingByCode(
            this.currentUserService.getAccount().getSession().country
        );

        if (!this.selectedCountry) {
            this.selectedCountry = this.countryService.getCountryDialingByCode(
                this.siteService.getSite().getLocale()
            );
        }

        if (!this.selectedCountry) {
            this.selectedCountry = this.countryService.getCountryDialingByCode(
                this.currentUserService.getAccount().getProfile().getCountry()
            );
        }

        if (!this.selectedCountry) {
            this.selectedCountry = this.countryService.getCountryDialingByCode('FR');
        }

        const selectedIndicative = '+' + this.selectedCountry.indicative;

        if (this.mobile && this.mobile.startsWith(selectedIndicative)) {
            this.mobile = this.mobile.replace(selectedIndicative, '');
        }
    }

    enableFilter() {
        this.showFilter = true;
    }

    showList() {
        this.selecting = !this.selecting;
        if (this.showFilter) {
            this.countryFilter = '';
            this.showFilter = false;
        }
    }

    setCurrentCountry(country) {
        this.selectedCountry = new Country().deserialize(country);
        this.countryFilter = '';
        this.showFilter = false;

        this.updateMobile();

        setTimeout(() => {
            this.selecting = false;
        }, 100);
    }

    checkNumberFormat($event) {
        if ($event.key === 'Backspace') {
            // autoriser la suppression
            return true;
        } else if ($event.key && $event.key.match(/[^0-9]/i)) {
            // ne pas autoriser les caractères autres que les chiffres
            $event.preventDefault();
            return false;
        } else if  (this.mobile && this.mobile.toString().trim().length >= 9) {
            // ne pas autoriser plus de 10 caractères (9 existants + 1 en keydown)
            $event.preventDefault();
            return false;
        }
    }

    updateMobile() {
        if (this.formDisabled) {
            return;
        }

        if (this.mobile && this.mobile.toString().trim() !== '') {
            if (!this.mobile.toString().match(/^\+?[0-9]{1,10}$/i)) {
                // if number is not valid
                this.form.emit(null);
            } else {
                this.form.emit({
                    indicative: this.selectedCountry.indicative,
                    mobile: this.mobile
                });
            }
        } else {
            this.form.emit(null);
        }
    }

    isSiteCountry(country: Country): boolean {
        return country && country.getCode().toLocaleLowerCase() === this.siteService.getSite().getLocale();
    }
}
