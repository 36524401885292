<div class="row">
    <div *ngFor="let questionName of questionsAnswers.getQuestions(tab, category)" class="form-group col-lg-6">
        <ng-container *ngIf="questionsAnswers.getQuestion(tab, category, questionName).getType() === 'choice-list'">
            <app-choice-list [question]="questionsAnswers.getQuestion(tab, category, questionName)" [profile]="profile"></app-choice-list>
        </ng-container>

        <ng-container *ngIf="questionsAnswers.getQuestion(tab, category, questionName).getType() === 'accordion-responses'">
            <app-accordion-responses [question]="questionsAnswers.getQuestion(tab, category, questionName)" [profile]="profile"></app-accordion-responses>
        </ng-container>

        <ng-container *ngIf="questionsAnswers.getQuestion(tab, category, questionName).getType() === 'slider'">
            <label>{{ translateGender('profile_questions.' + questionName) }}</label>
            <div [id]="'detail-' + questionName" class="col-sm-12">
                <nouislider (update)="sliderUpdate(questionName)"
                            [(ngModel)]="sliderModel[questionName]"
                            [min]="sliderConfig[questionName].min" [max]="sliderConfig[questionName].max" [step]="1"></nouislider>
            </div>
        </ng-container>

        <ng-container *ngIf="questionsAnswers.getQuestion(tab, category, questionName).getType() === 'range-slider'">
            <label>{{ translateGender('profile_questions.' + questionName) }}</label>
            <div [id]="'detail-' + questionName" class="col-sm-12">
                {{ sliderModel[questionName] }}
                <nouislider (update)="rangeSliderUpdate(questionName)"
                            [(ngModel)]="sliderModel[questionName]"
                            [connect]="true" [min]="sliderConfig[questionName].min" [max]="sliderConfig[questionName].max" [step]="1"></nouislider>
            </div>
        </ng-container>
    </div>
</div>
