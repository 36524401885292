import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionService} from '../../services/profile/action/action.service';
import {CurrentUserService} from '../../services/user/current-user.service';
import {LoginService} from '../../services/login/login.service';
import {ModalService} from '../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {formatDate} from '../../services/helper/date.service';

@Component({
    template: ''
})
export class EmailActionResolverComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private actionService: ActionService,
        private currentUserService: CurrentUserService,
        private loginService: LoginService,
        private modalService: ModalService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        const action = this.route.snapshot.queryParamMap.get('action');

        switch (action) {
            case 'friendRequest':
                this.actionService.friendStatus(
                    this.route.snapshot.queryParamMap.get('profileId'),
                    +this.route.snapshot.queryParamMap.get('confirmed')
                ).subscribe();
                this.router.navigate(['/contacts']);
                break;
            case 'albumAccess':
                this.actionService.privateAlbumAccess(
                    +this.route.snapshot.queryParamMap.get('id'),
                    this.route.snapshot.queryParamMap.get('status')
                ).subscribe();
                this.router.navigate(['/profile'], {queryParams: {tab: 'albums'}});
                break;
            case 'suspensionConfirmed':
            case 'deletionConfirmed':
                const token = this.route.snapshot.queryParamMap.get('token');

                if (this.route.snapshot.queryParamMap.get('confirmed') === '1') {
                    this.currentUserService[action](token).subscribe(() => {
                        if (action === 'suspensionConfirmed') {
                            this.currentUserService.refresh().then(() => {
                                this.suspensionConfirmed();
                            });
                        } else {
                            this.deletionConfirmed();
                        }
                    });
                } else {
                    this.currentUserService.deleteStatusRequest(token).subscribe(() => {
                        this.router.navigate(['/home']);
                    });
                }
                break;
            default:
                this.router.navigate(['/home']);
        }
    }

    private deletionConfirmed() {
        this.modalService.openFullScreenConfirmation({
            title: 'account.my_account.delete_account.modal.title',
            faClass: 'fas fa-trash-alt text-success',
            faClassStack: 'fas fa-check-circle',
            sampleText: 'account.my_account.delete_account.modal_response',
            responses: [
                {
                    text: 'leave_website',
                    className: 'btn-primary',
                    result: false
                }
            ],
            asyncValidationCallback: async () => {
                this.loginService.logout();
                return true;
            }
        });
    }

    private suspensionConfirmed() {
        let pendingText = this.translate.instant('account.my_account.suspend_account.modal_response.done');

        const responses = [
            {
                text: 'leave_website',
                className: 'btn-primary',
                result: false
            }
        ];

        if (this.currentUserService.getAccount().willBeSuspended()) {
            pendingText = this.translate.instant(`account.my_account.suspend_account.modal_response.pending`, {
                date: formatDate(new Date(this.currentUserService.getAccount().suspension.from), 'd/m/Y')
            });

            responses[0].className = 'btn-light';
            responses.unshift({
                text: 'back_to_website',
                className: 'btn-primary',
                result: true
            });
        }

        this.modalService.openFullScreenConfirmation({
            title: 'account.my_account.suspend_account.modal.title',
            faClass: 'fas fa-hand-paper text-success',
            faClassStack: 'fas fa-check-circle',
            sampleText: pendingText + '<br>' + this.translate.instant('account.my_account.suspend_account.modal_response.description'),
            responses,
            asyncValidationCallback: async (result) => {
                if (result) {
                    this.router.navigate(['/home']);
                } else {
                    this.loginService.logout();
                }
                return true;
            }
        });
    }
}
