<ul class="nav nav-tabs onglets-profils mb-4 mt-4">
    <li class="nav-item">
        <a class="nav-link active">
            {{ 'notifications' | translate }}
            <span *ngIf="getNotifications() && getNotifications().length > 0" class="badge badge-primary ml-2">
                {{ getNotifications().length | number:'1.0-0':'fr' }}
            </span>
        </a>
    </li>
</ul>

<div *ngIf="getNotifications() !== null" class="row px-0">
    <div *ngIf="getNotifications().length === 0" class="col-12 text-center mt-4">
        <h1 class="text-uppercase">{{ 'no_notification' | translate }}</h1>
    </div>

    <ng-container *ngFor="let notification of getNotifications()">
        <app-notification-container *ngIf="!notification.isDeleted()" [notification]="notification" class="col-lg-6">
        </app-notification-container>
    </ng-container>
</div>

<div *ngIf="getNotifications() === null" class="col-12 text-center mx-auto mt-4">
    <i class="fas fa-spinner fa-spin text-muted loader"></i>
</div>
