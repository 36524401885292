import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../services/profile/profile.service';
import {Offer} from '../../../models/offer/offer';

@Component({
    selector: 'app-check',
    templateUrl: './check.component.html',
    styleUrls: ['./check.component.scss']
})
export class CheckComponent extends BaseComponent implements OnInit {

    public offer: Offer;
    public isLoveCoin: boolean = false;

    constructor(
        injector: Injector,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private profileService: ProfileService
    ) {
        super(injector);
        this.offer = new Offer();
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.siteService.getOffer(+params.get('id_offer')).subscribe((response: any) => {
                if (response.success) {
                    this.offer = new Offer().deserialize(response.data);

                    if (this.offer.isLoveCoin()) {
                        this.profileService.sendCoinAlternativePaymentEmail('check', this.offer);
                    }
                }
            });
        });
    }

    getMonthTranslated() {
        return this.translate.instant('account.purchases.months');
    }
}
