<div class="container ok-ko contenu mt-5" *ngIf="isLoaded">
    <div class="row">
        <div class="col-lg-8 mx-auto text-center">

            <div class="warning">
                <div id="hourglass" class="fa-stack fa-4x">
                    <i class="fa fa-stack-1x fa-hourglass-start"></i>
                    <i class="fa fa-stack-1x fa-hourglass-half"></i>
                    <i class="fa fa-stack-1x fa-hourglass-end"></i>
                    <i class="fa fa-stack-1x fa-hourglass-end"></i>
                    <i class="fa fa-stack-1x fa-hourglass-o"></i>
                </div>
            </div>

            <h1>
                {{ value }}<br/>
                {{ 'waiting_confirmation' | translate }}
            </h1>

            <p>
                {{ 'confirmation_email_sent' | translate }}<br/>
                <ng-container *ngIf="noLinkToClick()">{{ ('please_click_link_to_confirm_' + field) | translate }}<br/></ng-container>
                {{ 'hardbounced.check_your_spam' | translate }}
            </p>

            <p>{{ 'you_can_resend_confirmation_email' | translate }} </p>

            <a class="btn btn-sm btn-primary d-block mt-2 mx-auto" [class.disabled]="alreadyResend" (click)="resendConfirmation()">
                {{ 'resend_confirmation_email' | translate }}
            </a>

        </div>
    </div>
</div>
