import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Photo} from '../../../models/profile/photo';
import {TranslateService} from '@ngx-translate/core';
import {PhotoService} from '../../../services/profile/photo/photo.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Album} from '../../../models/profile/album';
import {Profile} from '../../../models/profile/profile';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-upload-photo',
    templateUrl: './upload-photo.component.html',
    styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {

    imageLoaded = false;
    isMain: boolean;
    albumType: number;
    pickPhotoOnAlbum: boolean;
    aspectRatio: number;
    step = 1;
    create = true;
    photoCropped: any;
    uploading = false;
    disabled = true;
    isPhotoProfile: boolean;
    photo = new Photo();
    album: Album;
    profile: Profile;
    imageChangedEvent: any = '';

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private photoService: PhotoService,
        private currentUser: CurrentUserService,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<UploadPhotoComponent>
    ) {
    }

    ngOnInit() {
        this.profile = this.currentUser.getAccount().getProfile();
        this.album = this.data.album ? this.data.album : new Album();
        this.isMain = this.data.isMain ? this.data.isMain : false;
        this.albumType = this.data.albumType ? this.data.albumType : false;
        this.aspectRatio = this.data.aspectRatio ? this.data.aspectRatio : 1;
        this.isPhotoProfile = this.data.isPhotoProfile ? this.data.isPhotoProfile : false;
        this.pickPhotoOnAlbum = this.data.pickPhotoOnAlbum || false;
    }

    loadImageFailed() {
        this.toastr.error(this.translate.instant('profile_picture.error_file_format'));
        this.clear();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.photoCropped = event.base64;
    }

    loadImage() {
        this.imageLoaded = true;
    }

    setImageChangedEvent(event) {
        this.photo.setName(event.target.files[0].name.replace(/\.[^/.]+$/, ''));
        this.imageChangedEvent = event;
        this.step = 2;
        this.disabled = false;
    }

    setPhotoSelectedEvent(photo: Photo) {
        this.photo = photo;
        this.create = false;
        this.step = 3;
        this.disabled = false;
    }

    clear() {
        this.step = 1;
        this.create = true;
        this.photoCropped = null;
        this.photo = new Photo();
        this.disabled = true;
    }

    submit() {
        if (this.uploading || this.disabled) {
            return;
        }

        if (this.step === 1) {
            this.create = true;
            this.step = 2;
        }
        if (this.step === 2) {
            this.step = 3;
        } else if (this.step === 3) {
            this.uploading = true;
            if (this.albumType) {
                this.album.setNameFromType(this.albumType);
            }
            this.create ? this.createPhoto() : this.setPhoto();
        }
    }

    createPhoto() {
        this.photo.setMain(this.isMain);
        this.photoService.create(this.photo, this.photoCropped, this.album).subscribe((response: any) => {
            if (response.success) {
                this.photo.setId(response.data.photoId);
                this.photo.setUrl(response.data.photoUrl);
                this.photo.setModerationStatus(Photo.MODERATION_STATUS_PENDING);
                this.changeMainPhoto();

                this.dialogRef.close({
                    success: true,
                    photo: this.photo
                });
            } else {
                this.toastr.error(this.translate.instant('error_list.technical_issue'));
            }
            this.uploading = false;
        }, () => {
            this.toastr.error(this.translate.instant('error_list.technical_issue'));
            this.uploading = false;
        });
    }

    setPhoto() {
        this.photo.setMain(this.isMain);
        this.photoService.update(this.photo)
            .subscribe(() => {
                this.changeMainPhoto();
                this.dialogRef.close({
                    success: true,
                    photo: this.photo
                });
            });
    }

    getPhotoPicture() {
        return this.create ? this.photoCropped : this.photo.getUrl();
    }

    changeMainPhoto() {
        const album = this.profile.getDefaultAlbumByType(this.albumType);
        const photos: {} = album.getPhotos();

        for (const id in photos) {
            const photo: Photo = photos[id];

            if (photo.isMain()) {
                photo.setMain(false);
            }
        }

        this.photo.setMain(this.isMain);
        if (this.isMain) {
            album.addPhoto(this.photo);

            if (this.albumType === Album.DEFAULT_PROFILE) {
                this.profile.setPhoto(this.photo.getUrl());
                this.profile.setPhotoIsModerated(!this.photo.isModerationPending());
            } else if (this.albumType === Album.DEFAULT_COVER) {
                this.profile.setCoverPhoto(this.photo.getUrl());
            }
        }
    }

    getDescription(photoStep) {
        let description = '';

        if (photoStep === 1) {
            description += this.translate.instant('profile_picture.use_formula_bottom');
        } else if (photoStep === 2) {
            description += this.translate.instant('profile_picture.account_use_form_step2');
        } else if (photoStep === 3) {
            description += this.translate.instant('profile_picture.account_use_form_step3');
        }

        return description;
    }
}
