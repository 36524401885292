<div class="chatZmMobile">
    <div class="chatMobileLayer"></div>
    <!-- Container -->
    <div class="chat-mobile">
        <div class="row">

            <!-- Chat mobile -->
            <div class="col-12 cadre">
                <!-- Header chat-->
                <div class="header row d-flex justify-content-between align-items-center no-gutters">
                    <h1 *ngIf="mobileTabIs('rooms')" class="mb-0">
                        {{ 'chat.talk' | translate }}
                        <div class="badge badge-primary">{{ chatRoomService.getRoomsCount() }}</div>
                    </h1>
                    <h1 *ngIf="mobileTabIs('users')" class="mb-0">
                        {{ 'online' | translate }}
                        <div class="badge badge-primary">{{ getFilteredUsers().length + '/' + chatUserService.getConnectedUserCount() }}</div>
                    </h1>
                    <h1 *ngIf="mobileTabIs('chat') && getCurrentRoom()" class="mb-0">
                        <a (click)="backClick();" class="text-dark mr-2">
                            <i class="fas fa-chevron-left"></i>
                        </a>
                        <div class="avatar">
                            <app-user-photo [user]="getCurrentRoom().getFriendUser()"></app-user-photo>
                        </div>
                        <span
                            [class.blurred]="hasRule('nicknameBlur')">{{ getCurrentRoom().getFriendUser().getUsername() }}</span>

                        <small>{{ getCurrentRoom().getFriendUser().getActivity().getDynamicText() | translate }}</small>
                    </h1>
                </div>
                <!-- Header chat-->

                <!-- Online user list -->
                <div *ngIf="mobileTabIs('users')" class="connectes">
                    <ul>
                        <li *ngFor="let user of getFilteredUsers()">
                            <div class="utilisateur">
                                <a (click)="chatService.setMobileTabFromConnectedUsers(); chatService.createRoom(user.getId());">
                                    <div class="users-img">
                                        <app-user-photo [user]="user"></app-user-photo>
                                        <span class="fa-stack" [ngClass]="user.getActivity().getDynamicClass()"></span>
                                        <span *ngIf="chatUserService.isBlockedByMe(user)" class="fa-stack bg-warning">
                                            <i class="fa fa-ban fa-stack-1x text-white"></i>
                                        </span>
                                    </div>
                                </a>
                                <div class="users-detail text-truncate">
                                    <a (click)="chatService.setMobileTabFromConnectedUsers(); chatService.createRoom(user.getId());">
                                        <p>
                                            <span
                                                [class.blurred]="hasRule('nicknameBlur')">{{user.getUsername()}}</span>
                                            <i class="fa pl-2" [ngClass]="{
                                                'fa-venus fem': user.isFemale(),
                                                'fa-mars hom': user.isMale(),
                                                'fa-venus-mars cpl': user.isCouple()
                                            }"></i><br>
                                            <small [class.blurred]="hasRule('detailBlur')">
                            <span *ngIf="user.getCity()" class="text-capitalize">{{user.getCity().toLowerCase()}}<span
                                *ngIf="user.getDistance()"> - {{user.getDistance() | number: '1.0-0'}}km</span></span>
                                                <span
                                                    *ngIf="user.getAge() >= 18">, {{user.getAge()}} {{ 'years_old' | translate }}</span>
                                            </small>
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="loadmoreusers-container">
                        <a [hidden]="loadMoreUsersLoading" (click)="loadMoreUsers()"
                           class="loadmoreusers">{{ 'chat.load_more_users' | translate }}</a>
                        <span [hidden]="!loadMoreUsersLoading" class="loadmoreusersloader"><i
                            class="fa fa-spinner fa-pulse"></i></span>
                    </div>
                </div>
                <!-- Online user list -->

                <!-- Rooms list -->
                <div *ngIf="mobileTabIs('rooms')" class="connectes disable-select"
                     (contextmenu)="onContextMenu($event)">
                    <div class="noRoom h-100 w-100 text-center" *ngIf="!chatRoomService.hasRoomsToDisplay()">
                        <div class="col-12 pr-0 pl-0">
                            <h2 class="h1 mt-5 pr-0">
                                <i class="far fa-comment ico-status"></i><br>
                                {{ 'chat.no_discussion' | translate }}
                            </h2>
                            <p>{{ 'chat.display_online_members_to_open_discussion' | translate }}</p>
                            <a (click)="setMobileTab('users')"
                               class="btn btn-sm btn-primary">{{ 'chat.show_members_list' | translate }}</a>
                        </div>
                    </div>
                    <app-chat-rooms-list isMobile=true></app-chat-rooms-list>
                </div>
                <!-- Room list -->

                <!-- Chat Messages -->
                <div *ngIf="mobileTabIs('chat')">
                    <div class="col-12 dialogue discussion" id="messagesMobile">
                        <div class="text-center d-none">
                            <span class="fa fa-spinner fa-pulse fa-2x"></span>
                        </div>

                        <div class="loadmoreusers-container" *ngIf="hasMoreMessages()">
                            <a [hidden]="loadMoreMessagesLoading" (click)="loadMoreMessages()"
                               class="loadmoreusers">{{ 'chat.load_more_messages' | translate }}</a>
                            <span [hidden]="!loadMoreMessagesLoading" class="loadmoreusersloader"><i
                                class="fa fa-spinner fa-pulse"></i></span>
                        </div>

                        <app-room-messages isMobile=true></app-room-messages>
                        <div *ngIf="mobileTabIs('chat') && getCurrentRoom() && getCurrentRoom().isTyping()">
                            <div class="clearfix"></div>
                            <div class="wow animated fadeIn infinite animated"
                                 style="margin-bottom: 10px; color: rgb(189, 189, 194); visibility: visible; animation-name: fadeIn;">
                                <span
                                    [class.blurred]="hasRule('nicknameBlur')">{{ getCurrentRoom().getFriendUser().getUsername()}}</span> {{ 'chat.is_writing' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Chat Messages -->
            </div>
            <!-- Chat mobile -->
        </div>
    </div>
    <!-- Container -->

    <!-- Footer chat-->
    <div (touchmove)="onContextMenu($event)" class="footer-mobile row d-flex align-items-center no-gutters">
        <app-restriction-banner *ngIf="chatRoomService.hasCurrentRoom()"></app-restriction-banner>

        <!-- CONVERSATION INPUT : -->
        <div *ngIf="mobileTabIs('chat') && getCurrentRoom() && !useBanner()" class="col-12">
            <app-chat-message-form isMobile=true></app-chat-message-form>
        </div>

        <!-- MENU -->
        <div *ngIf="!mobileTabIs('chat')" class="col-6 text-center">
            <a (click)="setMobileTab('rooms')" class="text-muted active">
                <i class="fas fa-comments">
                    <div class="badge badge-danger">{{ getUnreadCount() }}</div>
                </i>
                <br>
                <span>{{ 'chat.talk' | translate }}</span>
            </a>
        </div>
        <div *ngIf="!mobileTabIs('chat')" class="col-6 text-center">
            <a (click)="setMobileTab('users')" class="text-muted">
                <i class="fas fa-users">
                    <div
                        class="badge badge-danger">{{ getFilteredUsers().length > 9 ? '9+' : getFilteredUsers().length }}</div>
                </i>
                <br>
                <span>{{ 'online' | translate }}</span>
            </a>
        </div>
    </div>
    <!-- Footer chat-->
</div>
