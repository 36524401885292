<app-ad-space-display ids="11" *ngIf="!isMobile()" adClass="banner_top_user_list"></app-ad-space-display>
<div class="home ep1 mt-4">
    <div class="row d-block d-md-none" *ngIf="profileCount.online">
        <div class="col-12 text-center">
            <h4>{{ profileCount.online }} {{ 'members_online_now' | translate }}</h4>
        </div>
    </div>

    <ul class="nav nav-tabs mb-2 onglets-profils">
        <li class="nav-item d-none d-xl-block" *ngIf="isTab('all')">
            <a (click)="setTab('all')" [class.active]="isTab('all')" class="nav-link">
                <span>{{ 'search_filter.all_members' | translate }}</span>
                <span *ngIf="profileCount.total" class="badge badge-primary ml-2">{{ profileCount.total | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item pl-0 pr-1 px-md-1 d-block d-xl-none w-lg-100" *ngIf="isTab('all')">
            <a (click)="setTab('all')" [class.active]="isTab('all')" class="btn btn-light btn-sm d-inline-block w-100">
                <span>{{ 'search_filter.all_members' | translate }}</span>
                <span *ngIf="profileCount.total" class="badge badge-primary ml-2">{{ profileCount.total | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item d-none d-xl-block" *ngIf="!isTab('all') && !isTab('result')">
            <a (click)="setTab('online')" [class.active]="isTab('online')" class="nav-link">
                <span>{{ 'home.online_members' | translate }}</span>
                <span *ngIf="profileCount.online" class="badge badge-primary ml-2">{{ profileCount.online | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item pl-0 pr-1 px-md-1 d-block d-xl-none w-lg-50" *ngIf="!isTab('all') && !isTab('result')">
            <a (click)="setTab('online')" [class.active]="isTab('online')" class="btn btn-light btn-sm d-inline-block w-100">
                <span>{{ 'online' | translate }}</span>
                <span *ngIf="profileCount.online" class="badge badge-primary ml-2">{{ profileCount.online | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item d-none d-xl-block" *ngIf="isTab('result')">
            <a (click)="setTab('result')" [class.active]="isTab('result')" class="nav-link">
                <span>{{ 'home.search_result' | translate }}</span>
                <span *ngIf="profileCount.total" class="badge badge-primary ml-2">{{ profileCount.total | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item px-0 px-xl-1 d-block d-xl-none w-lg-100" *ngIf="isTab('result')">
            <a (click)="setTab('result')" [class.active]="isTab('result')" class="btn btn-light btn-sm d-inline-block w-100">
                <span>{{ 'result' | translate }}</span>
                <span *ngIf="profileCount.total" class="badge badge-primary ml-2">{{ profileCount.total | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item d-none d-xl-block" *ngIf="!isTab('all') && !isTab('result')">
            <a (click)="setTab('new')" [class.active]="isTab('new')" class="nav-link">
                <span>{{ 'home.new_members' | translate }}</span>
                <span *ngIf="profileCount.new" class="badge badge-secondary ml-2">{{ profileCount.new | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item d-block d-xl-none w-lg-50" *ngIf="!isTab('all') && !isTab('result')">
            <a (click)="setTab('new')" [class.active]="isTab('new')" class="btn btn-light off btn-sm d-inline-block w-100">
                <span>{{ 'new' | translate }}</span>
                <span *ngIf="profileCount.new" class="badge badge-secondary ml-2">{{ profileCount.new | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="nav-item-ad banner_top_user_list">
            <app-ad-space-display ids="12" *ngIf="isMobile()"></app-ad-space-display>
        </li>
        <li class="nav-item ml-auto li-filter-tag">
            <span *ngIf="getCityName() !== false && getCityName() !== null && getCityName() !== undefined" class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 alert">
                {{ getCityName() }}
                <button type="button" class="close ml-2" aria-label="Close" data-dismiss="alert" (click)="clearCity()">
                    <span aria-hidden="true">×</span>
                </button>
            </span>
        </li>
    </ul>

    <div class="card my-2" id="search-bar">
        <div class="card-body p-3">
            <form (ngSubmit)="submit()" class="form-inline d-flex justify-content-between">
                <div class="form-group mb-1 mb-xl-0">
                    <label class="font-weight-normal mb-0">{{ 'location' | translate }}</label>
                </div>
                <div class="form-group mb-2 mb-xl-0">
                    <div class="w-100">
                        <app-city-typeahead [selectedCityObject]="getFilters().city" [useLoader]=false [displayFromWall]="true" [marker]=true
                                            [useGeolocation]=true [geolocIcon]="'map-marker-alt'"
                                            [geolocTooltip]=true (onSelectCity)="onSelectCity($event)"
                                            (onErrorUnpicked)="onCityErrorUnpicked($event)"
                                            (onUnselectCity)="onUnselectCity()"></app-city-typeahead>
                    </div>

                </div>
                <div class="form-group mb-1 mb-xl-0">
                    <label class="font-weight-normal mb-0">{{ 'search_filter.distance' | translate }}</label>
                </div>
                <div class="form-group mb-2 mb-xl-0">
                    <select class="form-control custom-select custom-select-lg" name="distance" [(ngModel)]="getFilters().distance">
                        <option value="">{{ 'whatever' | translate }}</option>
                        <option [value]="distance" *ngFor="let distance of getDistanceOptions()">{{ distance }} km</option>
                    </select>
                </div>

                <div class="larger-age-select d-block d-xl-none">
                    <div class="form-group mb-1 mb-xl-0">
                        <label class="font-weight-normal mb-0">{{ 'search_filter.aged_from' | translate }}</label>
                    </div>
                    <div class="form-group mb-2 mb-xl-0">
                        <select [ngModel]="getFilters().getAgeMin()" (change)="getFilters().setAgeMin($event.target.value)" name="age_from" class="form-control custom-select custom-select-lg mr-2 select-age">
                            <ng-container *ngFor="let age of getAgeOptions()">
                                <option *ngIf="age <= getFilters().getAgeMax()" [value]="age">{{ age }}</option>
                            </ng-container>
                        </select>
                        {{ 'years_old' | translate }}
                    </div>
                </div>

                <div class="form-group mb-1 mb-xl-0 d-none d-xl-block">
                    <label class="font-weight-normal mb-0">{{ 'search_filter.aged_from' | translate }}</label>
                </div>
                <div class="form-group mb-2 mb-xl-0 d-none d-xl-block">
                    <select [ngModel]="getFilters().getAgeMin()" (change)="getFilters().setAgeMin($event.target.value)" name="age_from" class="form-control custom-select custom-select-lg mr-2 select-age">
                        <ng-container *ngFor="let age of getAgeOptions()">
                            <option *ngIf="age <= getFilters().getAgeMax()" [value]="age">{{ age }}</option>
                        </ng-container>
                    </select>
                    {{ 'years_old' | translate }}
                </div>

                <div>
                    <div class="form-group mb-1 mb-xl-0">
                        <label class="font-weight-normal mb-0 label-a">{{ 'search_filter.to' | translate }}</label>
                    </div>
                </div>

                <div class="larger-age-select">
                    <div class="form-group mb-1 mb-xl-0 d-block d-xl-none">
                        <label class="font-weight-normal mb-0"> </label>
                    </div>
                    <div class="form-group mb-2 mb-xl-0">
                        <select [ngModel]="getFilters().getAgeMax()" (change)="getFilters().setAgeMax($event.target.value)" name="age_to" class="form-control custom-select custom-select-lg mr-2 select-age">
                            <ng-container *ngFor="let age of getAgeOptions()">
                                <option *ngIf="age >= getFilters().getAgeMin()" [value]="age">{{ age }}</option>
                            </ng-container>
                        </select>
                        {{ 'years_old' | translate }}
                    </div>
                </div>
                <div class="form-group mb-1 mb-xl-0" style="margin-top: 3px;">
                    <div class="custom-control custom-checkbox">
                        <input [(ngModel)]="getFilters().withPhoto" type="checkbox" class="custom-control-input"
                               id="photo-ok" name="with_photo"/>
                        <label class="custom-control-label font-weight-normal" for="photo-ok">{{ 'search_filter.photo' | translate }}</label>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary">{{ 'chat.search' | translate }}</button>
            </form>
        </div>
    </div>

    <div *ngIf="boosterProfiles !== null && boosterProfiles.length > 0" class="row row-profils highlight">
        <div class="col-12 text-left">
            <span class="highlight-title">
                <a (click)="openBooster()" [class.cursor-pointer]="!isBoosted()"><i class="fas fa-info-circle"></i></a> {{ 'booster.profile_boosted' | translate }}
            </span>
        </div>
        <div *ngFor="let profile of boosterProfiles" class="col-xl-2 col-md-3 col-sm-4 col-6 lesprofils">
            <app-thumb-profile *ngIf="profile.getNickname()" [profile]="profile" [booster]="true"></app-thumb-profile>
        </div>
        <div class="col-12 text-right pt-1 pt-sm-4">
            <span class="boost" *ngIf="isBoosted() && getEndBooster() !== null">
                <i class="fas fa-rocket"></i>
                {{ 'booster.activated_to' | translate:{
                    date: getEndBooster() | date:'dd/MM',
                    time: getEndBooster() | date:'HH:mm'
                } }}
            </span>
            <a (click)="openBooster()" class="boost cursor-pointer" *ngIf="!isBoosted()">
                <i class="fas fa-rocket"></i> {{ 'booster.boost_your_profile' | translate }}
                <span class="d-none d-sm-inline-block">{{ 'booster.display_your_profile_here' | translate }}</span>
            </a>
        </div>
    </div>

    <div *ngIf="profiles" class="row row-profils">

        <div *ngIf="profiles.length === 0" class="col-12 text-center mt-4">
            <h1 class="text-uppercase">
                <span [innerHTML]="'search_empty_result.damn' | translate: getSmiley()"></span> <br class="d-block d-sm-none">{{ 'search_empty_result.no_result' | translate }}
            </h1>
            <p>
                {{ 'search_empty_result.no_match' | translate }}
                <br class="br-mobile">
                {{ 'search_empty_result.change_filter' | translate }}
            </p>
        </div>

        <div *ngFor="let profile of (profiles ? profiles.slice(0,12): [])" class="col-xl-2 col-md-3 col-sm-4 col-6 lesprofils">
            <app-thumb-profile *ngIf="profile.getNickname()" [profile]="profile"></app-thumb-profile>
        </div>

        <div class="col-12 mb-4">
            <app-ad-space-display ids="3,4" adClass="banner_user_list"></app-ad-space-display>
        </div>

        <div *ngFor="let profile of (profiles ? profiles.slice(12): [])" class="col-xl-2 col-md-3 col-sm-4 col-6 lesprofils">
            <app-thumb-profile *ngIf="profile.getNickname()" [profile]="profile"></app-thumb-profile>
        </div>

        <app-infinite-scroll
            *ngIf="profiles.length > 0"
            class="container-fluid mt-4"
            [currentPage]="getCurrentPage()"
            [maxPage]="getMaxPage()"
            [loading]="isLoading()"
            (pageChange)="loadInfiniteProfiles($event)"
        ></app-infinite-scroll>
    </div>

    <div *ngIf="isLoading() && profiles === null" class="loading text-center mt-5">
        <i class="fa fa-spinner fa-pulse fa-4x"></i>
    </div>

    <div class="col-12 mt-4">
        <app-ad-space-display ids="5,6" adClass="banner_bottom_list"></app-ad-space-display>
    </div>
</div>
