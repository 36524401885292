import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})

export class AccountComponent extends BaseComponent {

    tab: string;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.setTab(params.get('tab') || 'settings', true);
        });
    }

    setTab(tab, init = false) {
        if (this.tab !== tab && !init) {
            this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {tab},
                    queryParamsHandling: 'merge'
                }
            );
        }
        this.tab = tab;
    }
}
