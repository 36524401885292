import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Relationship implements Deserializable {

    friend: number;
    favorite: boolean;
    kissed: boolean;
    blocked: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    addedToFriend(): boolean {
        return this.friend !== null;
    }

    getFriend(): number {
        return this.friend;
    }

    isFriend(): boolean {
        return !!this.friend;
    }

    getFavorite(): boolean {
        return this.favorite;
    }

    getKissed(): boolean {
        return this.kissed;
    }

    getBlocked(): boolean {
        return this.blocked;
    }
}
