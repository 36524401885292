import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StorageService} from '../storage/storage.service';
import {Router} from '@angular/router';
import {SiteService} from '../site/site.service';
import {ChatService} from '../chat/chat.service';
import {WINDOW} from '../../app.config';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {HelperService} from '../helper/helper.service';
import {CurrentUserService} from '../user/current-user.service';
import {BrowserService} from '../browser/browser.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    /**
     * Auth token cookie's name.
     */
    private readonly authTokenCookieName = 'authToken';

    /**
     * Auth token cookie's time to live (90 days).
     */
    private readonly authTokenCookieTtl = 7776000;

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private siteService: SiteService,
        private translate: TranslateService,
        @Inject(WINDOW) private window: Window,
        private helperService: HelperService,
        private browserService: BrowserService,
        private injector: Injector
    ) {
    }

    get router(): Router {
        return this.injector.get(Router);
    }

    get chatService(): ChatService {
        return this.injector.get(ChatService);
    }

    get currentUserService(): CurrentUserService {
        return this.injector.get(CurrentUserService);
    }

    get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

    loginUserByAuthToken(token: string, support: string = null, template: string = null, subject: string = null) {
        return new Promise((resolve, reject) => {
            const browserData: any = this.browserService.getDeviceData();

            this.http.post(environment.backendUrl + '/auth/sso', {
                token,
                source: {
                    subject,
                    support,
                    template
                },
                session: {
                    browser: {
                        language: browserData.language,
                        obsolete: browserData.obsolete,
                        referer: document.referrer,
                        screen: browserData.screenResolution,
                        timezone: (Intl) ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
                        userAgent: browserData.userAgent,
                    }
                }
            }).subscribe((response: any) => {
                this.setToken(response.data.token);

                this.setAuthTokenCookie(token);

                resolve({
                    token: response.data.token
                });
            }, (error) => {
                reject(error.error);
            });
        });
    }

    /**
     * Login user to backbone
     * @param username
     * @param password
     */
    loginUserByUsernamePassword(username: string, password: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.backendUrl + '/auth/user', {
                username,
                password,
                domain: environment.domain
            }).subscribe((response: any) => {
                this.loginUserByAuthToken(response.data.token).then(() => {
                    resolve();
                });
            }, (error) => {
                reject(error.error);
            });
        });
    }

    /**
     * Login by Id Customer
     * @param idCustomer
     */
    loginUserByIdCustomer(idCustomer: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.backendUrl + '/auth/user-billing', {
                userId: idCustomer,
                domain: environment.domain
            }).subscribe((response: any) => {
                this.setToken(response.data.token);

                resolve({
                    token: response.data.token
                });
            }, (error) => {
                reject(error.error);
            });
        });
    }

    askBillingRedirection(username: string, password: string, mode: number, idOffer: number, tplPayment: number, ga: string) {
        return new Promise((resolve, reject) => {
            this.http.get(
                environment.backendUrl
                + '/billing?p=' + mode
                + '&id_offer=' + idOffer
                + '&tpl_payment=' + tplPayment
                + '&username=' + encodeURIComponent(username)
                + '&password=' + encodeURIComponent(password)
                + '&_ga=' + encodeURIComponent(ga)).subscribe((response: any) => {
                if (response.success) {
                    resolve(response.data.paymentUrl);
                } else {
                    reject(response.message);
                }
            }, (error) => {
                reject(error.error);
            });
        });
    }

    getCookieAuthToken() {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const nameValue = cookie.trim().split('=');
            if (this.authTokenCookieName === nameValue[0]) {
                if (nameValue.length > 1) {
                    return nameValue[1];
                }
            }
        }

        return null;
    }

    /**
     * Set or remove an auth token into a dedicated cookie for further auto logins.
     */
    setAuthTokenCookie(authToken: string) {
        if (authToken) {
            // Set cookie
            document.cookie = this.authTokenCookieName + '=' + authToken + ';Max-Age=' + this.authTokenCookieTtl + ';domain=.' + environment.domain + ';path=/;SameSite=Lax;Secure';
        } else {
            // Remove cookie
            document.cookie = this.authTokenCookieName + '=;Max-Age=0;domain=.' + environment.domain + ';path=/;SameSite=Lax;Secure';
            // Remove cookie (staging)
            document.cookie = this.authTokenCookieName + '=;Max-Age=0;domain=.' + environment.domain.replace('staging.', '') + ';path=/;SameSite=Lax;Secure';
        }
    }

    setToken(token) {
        this.storage.set('token', token);
    }

    getToken() {
        return this.storage.get('token');
    }

    setGa(token) {
        this.storage.set('ga', token);
    }

    getGa() {
        return this.storage.get('ga');
    }

    isConnected() {
        return this.getToken() !== null;
    }

    isReallyConnected(): boolean {
        return !!this.currentUserService.profileId;
    }

    removeAuthentication() {
        this.currentUserService.profileId = null;
        this.storage.delete('token');
        this.setAuthTokenCookie(null);
        if (this.chatService) {
            this.chatService.logout();
        }
    }

    navigateToLogin() {
        this.window.location.href = 'https://' + environment.domain + '/login/';
    }

    logout() {
        this.removeAuthentication();
        this.currentUserService.setAccountLoaded(false);

        if (environment.hasLoginPage) {
            this.router.navigate(['/login']);
        } else {
            this.navigateToLogin();
        }
    }

    /**
     * Autologin
     */
    autologin(): Promise<any> {
        return new Promise<any>((resolve) => {
            const username = this.helperService.getUrlParam('username');
            const password = this.helperService.getUrlParam('password');
            const authToken = this.helperService.getUrlParam('auth_token');
            const cookieAuthToken = this.getCookieAuthToken();
            const sessionToken = this.helperService.getUrlParam('session_token') || this.storage.get('token');
            const idCustomer = this.helperService.getUrlParam('id_customer');
            const ga = this.helperService.getUrlParam('_ga');

            this.removeAuthentication();

            if (username && password) {
                // Cast params into integer
                const p = +this.helperService.getUrlParam('p');
                const idOffer = +this.helperService.getUrlParam('id_offer');
                const tplPayment = +this.helperService.getUrlParam('tpl_payment');

                this.storage.delete('token');

                /**
                 * Username/password authentication
                 */
                this.loginUserByUsernamePassword(username, password).then(() => {
                    this.setGa(ga);
                    // (CB30)
                    if (p === 50) {
                        this.askBillingRedirection(username, password, p, idOffer, tplPayment, ga).then((url: string) => {
                            this.router.navigate(['/payment-redirect'], {queryParams: {url}});
                            resolve();
                        }).catch(() => {
                            if (this.toastr) {
                                this.toastr.error(this.translate.instant('error_list.technical_issue'));
                            }
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                }).catch((e) => {
                    console.error(e);
                    resolve();
                });
            } else if (authToken) {
                this.loginUserByAuthToken(
                    authToken,
                    this.helperService.getUrlParam('source_support'),
                    this.helperService.getUrlParam('source_template'),
                    this.helperService.getUrlParam('source_subject')
                ).then(() => {
                    this.setGa(ga);
                    this.currentUserService.resetSettingsAtLogin();

                    resolve();
                }).catch((e) => {
                    console.error(e);
                    // Remove potentially bad cookie between prod and staging
                    this.setAuthTokenCookie(null);
                    resolve();
                });
            } else if (cookieAuthToken) {
                this.loginUserByAuthToken(cookieAuthToken, 'login', 'cookie', 'member_zone').then(() => {
                    this.setGa(ga);
                    resolve();
                }).catch((e) => {
                    console.error(e);
                    // Remove potentially bad cookie between prod and staging
                    this.setAuthTokenCookie(null);
                    resolve();
                });
            } else if (sessionToken) {
                /**
                 * Continue already opened session
                 */
                this.setToken(sessionToken);
                resolve();
            } else if (idCustomer) {
                /**
                 * Id Customer
                 */
                this.loginUserByIdCustomer(idCustomer).then(() => {
                    resolve();
                }).catch((e) => {
                    console.error(e);
                    resolve();
                });
            } else if ((username && !password) || (!username && password)) {
                /**
                 * Wrong parameters, we disconnect user
                 */
                this.setToken(null);
                resolve();
            } else {
                /**
                 * No authentication provided
                 */
                resolve();
            }
        });
    }
}
