import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class CamModel implements Deserializable {

    public static readonly MALE = 'M';
    public static readonly FEMALE = 'F';
    public static readonly COUPLE = 'P';
    public static readonly TRANSGENDER = 'S';

    public static readonly BOOL_TRUE = 'Y';
    public static readonly BOOL_FALSE = 'N';
    public static readonly ONGOING = 'B';

    public static readonly FREE_SHOW = 'FREE';
    public static readonly VIP_SHOW = 'O2M';
    public static readonly PRIVATE_SHOW = 'O2O';
    public static readonly PEEPING_SHOW = 'V';

    public static readonly ONLINE_STATUS = 'ONLINE';
    public static readonly OFFLINE_STATUS = 'OFFLINE';

    public static readonly PHOTO_SIZE = '260x195';

    account: string;
    nickname: string;
    photo: string;
    sex: string;
    status: string;
    hd: string;
    new: string;
    modelOfTheMonth: string;
    audio: string;
    one2one: string;
    chatType: string;
    country: string;
    firstLanguage: string;
    language: string;
    snapshots: object = null;
    detailPhotos: string[] = [];
    coinsByMinute: any = null;
    isFavorite: boolean;
    ALLOW_VOYEURS: string;
    figure: string;
    hair: string;
    hairType: string;
    cup: string;
    age: number;
    piercing: string;
    tattoo: string;
    peepingShow = false;
    sign: string;
    origin: string;
    profiles: any;
    region: string;
    eyesColor: string;
    sexpref: string;
    pregnant: string;
    stockings: string;
    cockDescription: string;
    cockSize: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (this['first_language']) {
            delete this['first_language'];
            this.firstLanguage = input.first_language;
        }

        if (this['display_pictures']) {
            delete this['display_pictures'];
            this.photo = input.display_pictures[0] || null;
        }

        if (input.pictures && input.pictures.length) {
            this.detailPhotos = input.pictures.slice(0, 4);
        }

        if (this.snapshots[CamModel.PHOTO_SIZE]) {
            this.snapshots[CamModel.PHOTO_SIZE] = this.snapshots[CamModel.PHOTO_SIZE].replace('http://', 'https://');
        }

        return this;
    }

    getId(): string {
        return this.account;
    }

    getNickname(): string {
        return this.nickname;
    }

    getPhoto(): string {
        return this.photo || this.snapshots[CamModel.PHOTO_SIZE] || null;
    }

    hasHD(): boolean {
        return this.hd === CamModel.BOOL_TRUE;
    }

    isCouple(): boolean {
        return this.sex === CamModel.COUPLE;
    }

    isFemale(): boolean {
        return this.sex === CamModel.FEMALE;
    }

    isMale(): boolean {
        return this.sex === CamModel.MALE;
    }

    isTransgender(): boolean {
        return this.sex === CamModel.TRANSGENDER;
    }

    isOnline(): boolean {
        return this.status === CamModel.ONLINE_STATUS;
    }

    isFreeShow(): boolean {
        return this.chatType === CamModel.FREE_SHOW;
    }

    isPrivateShow(): boolean {
        return this.chatType === CamModel.PRIVATE_SHOW;
    }

    isPeepingShow(): boolean {
        return this.chatType === CamModel.PRIVATE_SHOW && this.peepingShow;
    }

    setVIPShow() {
        this.peepingShow = false;
        this.chatType = CamModel.VIP_SHOW;
    }

    setPrivateShow(isPeepingShow: boolean) {
        this.peepingShow = isPeepingShow;
        this.chatType = CamModel.PRIVATE_SHOW;
    }

    isVIPShow(): boolean {
        return this.chatType === CamModel.VIP_SHOW;
    }

    getDetailPhotos(): string[] {
        return this.detailPhotos;
    }

    getCoinsByMinute(index: string): number {
        return this.coinsByMinute ? this.coinsByMinute[index] : null;
    }

    allowsPrivate(): boolean {
        return this.one2one !== CamModel.BOOL_FALSE;
    }

    allowsPeeping(): boolean {
        return this.ALLOW_VOYEURS === CamModel.BOOL_TRUE;
    }

    getCurrentRating(): number {
        if (this.isPrivateShow()) {
            return this.getCoinsByMinute(CamModel.PEEPING_SHOW);
        } else {
            return this.getCoinsByMinute(CamModel.VIP_SHOW);
        }
    }

    hasBlondeHair() {
        return this.hair === 'blonde';
    }

    hasRedHair(): boolean {
        return this.hair === 'red';
    }

    hasBrownHair(): boolean {
        return this.hair === 'brunette';
    }

    hasBlackHair(): boolean {
        return this.hair === 'black';
    }

    getAge(): number {
        return this.age;
    }

    hasSmallTit(): boolean {
        return this.cup === 'A' || this.cup === 'B';
    }

    hasSlimBody(): boolean {
        return this.figure === 'S' || this.figure === 'M';
    }

    hasTattoo(): boolean {
        return this.tattoo === CamModel.BOOL_TRUE;
    }

    hasPiercing(): boolean {
        return this.piercing === CamModel.BOOL_TRUE;
    }

    canPayVIPShow(coins: number): boolean {
        return coins >= this.getCoinsByMinute(CamModel.VIP_SHOW);
    }

    canPayPrivateShow(coins: number): boolean {
        return coins >= this.getCoinsByMinute(CamModel.PRIVATE_SHOW);
    }

    canPayPeepingShow(coins: number): boolean {
        return coins >= this.getCoinsByMinute(CamModel.PEEPING_SHOW);
    }

    canPayShow(coins: number): boolean {
        if (this.isFreeShow()) {
            return true;
        }

        return coins >= this.getCurrentRating();
    }
}
