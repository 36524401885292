import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-virilblue-layout',
    templateUrl: './virilblue-layout.component.html',
    styleUrls: ['./virilblue-layout.component.scss']
})
export class VirilblueLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
