import {Component} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-suppression',
    templateUrl: './suppression.component.html',
    styleUrls: ['./suppression.component.scss']
})
export class SuppressionComponent {

    form = {
        reason: 'choose'
    };

    reasonList = [
        'choose',
        'meet_someone',
        'service_too_expensive',
        'no_fit',
        'other_reason',
        'dont_want_to_answer'
    ];

    constructor(
        private modal: ModalService,
        private currentUserService: CurrentUserService,
        private router: Router
    ) {}

    submit() {
        if (this.form.reason === 'choose') {
            return;
        }

        return this.modal.openFullScreenConfirmation({
            title: 'account.my_account.delete_account.modal.title',
            strongQuestion: true,
            faClass: 'fas fa-trash-alt',
            faClassStack: false,
            sampleText: 'account.my_account.delete_account.modal.description',
            question: 'account.my_account.delete_account.modal.question',
            responses: [
                {
                    text: 'account.my_account.delete_account.modal.answer',
                    className: 'btn-primary',
                    result: true
                },
                {
                    text: 'cancel',
                    className: 'btn-light',
                    result: false
                }
            ],
            asyncValidationCallback: async (result) => {
                if (result) {
                    this.currentUserService.requestDeletion(this.form.reason).subscribe();

                    this.router.navigate(['/pending-confirmation/deletion']);
                }
                return true;
            }
        });
    }
}
