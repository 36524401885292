<div class="container cover-pdp" [ngStyle]="{'background-image': 'url(' + getBackgroundImage() + ')'}">
</div>

<div class="container pdp-new pdp-new-2">
    <div class="row">
        <div class="col-12">
            <h1 class="text-white">
                {{ 'payment.become_vip' | translate }}
            </h1>

            <div class="blocked mb-4">
                <span class="mr-2 ico-blocked"><i class="fas fa-lock"></i></span>
                <span class="d-none d-md-inline-block text-white" [innerHTML]="group.description | nl2br"></span>
                <span class="d-inline-block d-md-none text-white" [innerHTML]="group.descriptionMobile | nl2br"></span>
            </div>

            <div class="card card-pdp-new-2 p-0 p-lg-4 border-0">
                <div class="card border-0 mb-3 shadow-sm rounded-left" [ngClass]="{'mb-3':!last}"
                     *ngFor="let offer of group.getOffers(); let index = index; let last=last">
                    <a [routerLink]="['/payment/offer/', offer.id]" [queryParams]="{tpl: 1}"
                       [queryParamsHandling]="'merge'" class="offer-link d-block">
                        <div class="d-flex align-items-center justify-content-between offer">
                            <div class="number-offer bg-dark order-1 rounded-left">
                                <i *ngIf="offer.icon"
                                   class="mr-1"
                                   [ngClass]="offer.icon"></i>
                                <span [innerHTML]="offer.name | formatHtml"></span>
                            </div>
                            <div class="duration text-primary order-md-2 order-3 mr-auto"
                                 [class.hidden]="!offer.formula" [innerHTML]="offer.formula | formatHtml"></div>
                            <div class="price order-md-3 order-1 mr-auto" [class.hidden]="!offer.price"
                                 [innerHTML]="offer.price | formatHtml">
                            </div>
                            <div class="discount text-primary order-md-4 mr-4" [class.hidden]="!offer.discount"
                                 [innerHTML]="offer.discount | formatHtml"></div>
                            <div class="cta order-md-5 order-2"><span
                                class="btn-primary btn btn-sm rounded-pill text-uppercase">{{ 'payment.select' | translate }}
                                <i
                                    class="fas fa-angle-right"></i></span></div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="pt-4 pt-lg-0 px-0 pb-0 px-lg-4 pb-lg-4">
                <hr class="mb-4">
                <h2>{{ 'payment.become_vip_content.title' | translate }}</h2>
                <div class="row mt-3">
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-comment text-primary ico-pdp"></i> {{ 'payment.become_vip_content.unlimited_chat' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-map-marker-alt text-primary ico-pdp"></i> {{ 'payment.become_vip_content.geolocation' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i class="fas fa-eye text-primary ico-pdp"></i>
                        {{ 'payment.become_vip_content.search_appearance' | translate }}</h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-user text-primary ico-pdp"></i> {{ 'payment.become_vip_content.all_profile_access' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-search text-primary ico-pdp"></i> {{ 'payment.become_vip_content.affinity_search' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-smile text-primary ico-pdp"></i> {{ 'payment.become_vip_content.no_ads' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-images text-primary ico-pdp"></i> {{ 'payment.become_vip_content.all_photo_access' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-book text-primary ico-pdp"></i> {{ 'payment.become_vip_content.ebooks' | translate }}
                    </h6></div>
                    <div class="col-md-4 col-sm-6 mb-3"><h6 class="my-0"><i
                        class="fas fa-headset text-primary ico-pdp"></i> {{ 'payment.become_vip_content.premium_customer_service' | translate }}
                    </h6></div>
                </div>
            </div>
        </div>
    </div>
</div>
