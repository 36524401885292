<div class="well certification d-none d-lg-block" *ngIf="getAccount().getCertifications()">
    <h3>
        {{ 'certif.level.title' | translate }}
        <strong *ngIf="getProgressBar() < 25" class="text-success">{{ 'certif.level.beginner' | translate }}</strong>
        <strong *ngIf="getProgressBar() >= 25 && getProgressBar() < 50" class="text-success">{{ 'certif.level.beginner' | translate }}</strong>
        <strong *ngIf="getProgressBar() >= 50 && getProgressBar() < 75" class="text-success">{{ 'certif.level.intermediate' | translate }}</strong>
        <strong *ngIf="getProgressBar() >= 75 && getProgressBar() < 100" class="text-success">{{ 'certif.level.advanced' | translate }}</strong>
        <strong *ngIf="getProgressBar() >= 100" class="text-success">{{ 'certif.level.expert' | translate }}</strong>
    </h3>

    <div class="progress" style="height: 30px;">
        <div class="niveau kdo" [class.ok]="getProgressBar() >= 100">
            <i class="fas fa-gift"></i>
            <div class="cadre_niveau card">
                <i class="fas fa-caret-up fleche2"></i>
                <div class="card-header text-center">
                    <i class="far fa-grin-stars"></i><br><h5 class="card-title m-0">{{ 'certif.level.expert' | translate }}</h5>
                    <p class="m-0">{{ 'certif.level.expert_description' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="niveau quart" [ngClass]="{'ok' : getProgressBar() > 25, 'ko' : getProgressBar() <= 25}">
            <i class="fas" [ngClass]="{'fa-check' : getProgressBar() > 25, 'fa-times' : getProgressBar() <= 25}"></i>
            <div class="cadre_niveau card">
                <i class="fas fa-caret-up fleche2"></i>
                <div class="card-header text-center"><i class="far fa-grimace"></i><br><h5 class="card-title m-0">{{ 'certif.level.beginner' | translate }}</h5>
                    <p class="m-0">{{ 'certif.level.beginner_description' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="niveau tquart" [ngClass]="{'ok' : getProgressBar() > 75, 'ko' : getProgressBar() <= 75}">
            <i class="fas" [ngClass]="{'fa-check' : getProgressBar() > 75, 'fa-times' : getProgressBar() <= 75}"></i>
            <div class="cadre_niveau card">
                <i class="fas fa-caret-up fleche2"></i>
                <div class="card-header text-center"><i class="far fa-grin-hearts"></i><br><h5 class="card-title m-0">{{ 'certif.level.advanced' | translate }}</h5>
                    <p class="m-0">{{ 'certif.level.advanced_description' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="niveau moitie" [ngClass]="{'ok' : getProgressBar() > 50, 'ko' : getProgressBar() <= 50}">
            <i class="fas" [ngClass]="{'fa-check' : getProgressBar() > 50, 'fa-times' : getProgressBar() <= 50}"></i>
            <div class="cadre_niveau card">
                <i class="fas fa-caret-up fleche2"></i>
                <div class="card-header text-center"><i class="far fa-grin"></i><br><h5 class="card-title m-0">{{ 'certif.level.intermediate' | translate }}</h5>
                    <p class="m-0">{{ 'certif.level.intermediate_description' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="progress-bar"
             [ngClass]="{'bg-primary': getProgressBar() > 25 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 25, 'bg-success': getProgressBar() > 99}"
             role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">25%
        </div>
        <div class="progress-bar"
             [ngClass]="{'bg-secondary': getProgressBar() > 50 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 50, 'bg-success': getProgressBar() > 99}"
             role="progressbar" style="width: 25%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">50%
        </div>
        <div class="progress-bar"
             [ngClass]="{'bg-secondary': getProgressBar() > 75 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 75, 'bg-success': getProgressBar() > 99}"
             role="progressbar" style="width: 25%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">75%
        </div>
        <div class="progress-bar"
             [ngClass]="{'bg-success': getProgressBar() > 99, 'bg-light text-dark': getProgressBar() < 100}"
             role="progressbar" style="width: 25%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">100%
        </div>
    </div>

    <hr class="small-colored-hr"/>
    <div class="fleche"><i class="fas fa-caret-down"></i></div>

    <h4 class="text-center"><strong>{{ 'certif.optimization' | translate }}</strong><br><small style="font-size:14px;">{{ 'certif.ways_of_success' | translate }}</small></h4>
    <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
        <div *ngIf="getAccount().getCertifications().email" class="tampon">
            <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getCertifications().emailDate | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="card-body">
            <div class="num">1</div>
            <i class="fas fa-envelope icon"></i><br><span>{{ 'certif.confirm_email' | translate }}</span>
        </div>
        <div class="card-footer">
            <div (click)="!getAccount().getCertifications().email && openEmailConfirmation()" [class.disabled]="getAccount().getCertifications().email" class="btn btn-primary btn-block">
                <span>{{ 'certif.go' | translate }}</span>
                <i class="fas fa-angle-right text-white ml-1"></i>
            </div>
        </div>
    </div>

    <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
        <div *ngIf="getAccount().getCertifications().mobile" class="tampon">
            <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getCertifications().mobileDate | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="card-body">
            <div class="num">2</div>
            <i class="fas fa-phone icon"></i><br><span>{{ 'certif.confirm_phone' | translate }}</span>
        </div>
        <div class="card-footer">
            <div (click)="!getAccount().getCertifications().mobile && openMobileConfirmation()" [class.disabled]="getAccount().getCertifications().mobile" class="btn btn-primary btn-block">
                <span>{{ 'certif.go' | translate }}</span>
                <i class="fas fa-angle-right text-white ml-1"></i>
            </div>
        </div>
    </div>

    <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
        <div *ngIf="getAccount().getProfile().isCertified()" class="tampon">
            <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getProfile().certifiedAt | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="card-body">
            <div class="num">3</div>
            <i class="fas fa-check-square icon"></i><br><span>{{ 'certif.confirm_profile' | translate }}</span>
        </div>
        <div class="card-footer">
            <div [routerLink]="['/payment/certification']" [class.disabled]="getAccount().getProfile().isCertified()" class="btn btn-primary btn-block">
                <span>{{ 'certif.go' | translate }}</span>
                <i class="fas fa-angle-right text-white ml-1"></i>
            </div>
        </div>
    </div>
</div>

<div class="well certification certification-mobile d-block d-lg-none">
    <div id="headingOne">
        <button class="bg-white p-0 collapsed btn-block text-left" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <i class="fas fa-angle-down fleche-collapse" aria-hidden="true"></i>
            <i class="fas fa-angle-up fleche-collapse" aria-hidden="true"></i>
            <h3>
                {{ 'certif.level.title' | translate }}
                <strong *ngIf="getProgressBar() < 25" class="text-success">{{ 'certif.level.beginner' | translate }}</strong>
                <strong *ngIf="getProgressBar() >= 25 && getProgressBar() < 50" class="text-success">{{ 'certif.level.beginner' | translate }}</strong>
                <strong *ngIf="getProgressBar() >= 50 && getProgressBar() < 75" class="text-success">{{ 'certif.level.intermediate' | translate }}</strong>
                <strong *ngIf="getProgressBar() >= 75 && getProgressBar() < 100" class="text-success">{{ 'certif.level.advanced' | translate }}</strong>
                <strong *ngIf="getProgressBar() >= 100" class="text-success">{{ 'certif.level.expert' | translate }}</strong>
            </h3>

            <div class="progress" style="height: 30px;">
                <div class="niveau kdo" [class.ok]="getProgressBar() >= 100">
                    <i class="fas fa-gift"></i>
                    <div class="cadre_niveau card">
                        <i class="fas fa-caret-up fleche2"></i>
                        <div class="card-header text-center"><i class="far fa-star"></i><br /><h5 class="card-title m-0">{{ 'certif.level.expert' | translate }}</h5>
                            <p class="m-0">{{ 'certif.level.expert_description' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="niveau quart" [ngClass]="{'ok' : getProgressBar() > 25, 'ko' : getProgressBar() <= 25}">
                    <i class="fas"[ngClass]="{'fa-check' : getProgressBar() > 25, 'fa-times' : getProgressBar() <= 25}"></i>
                    <div class="cadre_niveau card">
                        <i class="fas fa-caret-up fleche2"></i>
                        <div class="card-header text-center"><i class="far fa-star"></i><br /><h5 class="card-title m-0">{{ 'certif.level.beginner' | translate }}</h5>
                            <p class="m-0">{{ 'certif.level.beginner_description' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="niveau tquart" [ngClass]="{'ok' : getProgressBar() > 75, 'ko' : getProgressBar() <= 75}">
                    <i class="fas" [ngClass]="{'fa-check' : getProgressBar() > 75, 'fa-times' : getProgressBar() <= 75}"></i>
                    <div class="cadre_niveau card">
                        <i class="fas fa-caret-up fleche2"></i>
                        <div class="card-header text-center"><i class="far fa-star"></i><br /><h5 class="card-title m-0">{{ 'certif.level.advanced' | translate }}</h5>
                            <p class="m-0">{{ 'certif.level.advanced_description' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="niveau moitie" [ngClass]="{'ok' : getProgressBar() > 50, 'ko' : getProgressBar() <= 50}">
                    <i class="fas" [ngClass]="{'fa-check' : getProgressBar() > 50, 'fa-times' : getProgressBar() <= 50}"></i>
                    <div class="cadre_niveau card">
                        <i class="fas fa-caret-up fleche2"></i>
                        <div class="card-header text-center"><i class="far fa-star"></i><br /><h5 class="card-title m-0">{{ 'certif.level.expert' | translate }}</h5>
                            <p class="m-0">{{ 'certif.level.expert_description' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="progress-bar"
                     [ngClass]="{'bg-primary': getProgressBar() > 25 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 25, 'bg-success': getProgressBar() > 99}"
                     role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">25%
                </div>
                <div class="progress-bar"
                     [ngClass]="{'bg-secondary': getProgressBar() > 50 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 50, 'bg-success': getProgressBar() > 99}"
                     role="progressbar" style="width: 25%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">50%
                </div>
                <div class="progress-bar"
                     [ngClass]="{'bg-secondary': getProgressBar() > 75 && getProgressBar() < 100, 'bg-light text-dark': getProgressBar() <= 75, 'bg-success': getProgressBar() > 99}"
                     role="progressbar)" style="width: 25%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">75%
                </div>
                <div class="progress-bar"
                     [ngClass]="{'bg-light text-dark': getProgressBar() < 100, 'bg-success': getProgressBar() > 99}"
                     role="progressbar" style="width: 25%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">100%
                </div>
            </div>
        </button>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#headingOne">
        <hr />
        <div class="fleche">
            <i class="fas fa-caret-down"></i>
        </div>
        <h4 class="text-center">
            <strong>{{ 'certif.optimization' | translate }}</strong>
            <br>
            <small style="font-size:14px;">{{ 'certif.ways_of_success' | translate }}</small>
        </h4>

        <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
            <div *ngIf="getAccount().getCertifications().email" class="tampon">
                <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getCertifications().emailDate | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="card-body">
                <div class="num">1</div>
                <i class="fas fa-envelope icon"></i>
                <br>
                <span>{{ 'certif.confirm_email' | translate }}</span>
            </div>
            <div class="card-footer">
                <div (click)="!getAccount().getCertifications().email && openEmailConfirmation()" [class.disabled]="getAccount().getCertifications().email" class="btn btn-primary btn-block">
                    <span>{{ 'certif.go' | translate }}</span>
                    <i class="fas fa-angle-right text-white ml-1"></i>
                </div>
            </div>
        </div>

        <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
            <div *ngIf="getAccount().getCertifications().mobile" class="tampon">
                <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getCertifications().mobileDate | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="card-body">
                <div class="num">2</div>
                <i class="fas fa-phone icon"></i><br><span>{{ 'certif.confirm_phone' | translate }}</span>
            </div>
            <div class="card-footer">
                <div (click)="!getAccount().getCertifications().mobile && openMobileConfirmation()" [class.disabled]="getAccount().getCertifications().mobile" class="btn btn-primary btn-block">
                    <span>{{ 'certif.go' | translate }}</span>
                    <i class="fas fa-angle-right text-white ml-1"></i>
                </div>
            </div>
        </div>

        <div *ngIf="getAccount() && getAccount().getCertifications()" class="emailtel card mb-3 text-center">
            <div *ngIf="getAccount().getProfile().isCertified()" class="tampon">
                <span>{{ 'certif.validated_the' | translate }} {{ getAccount().getProfile().certifiedAt | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="card-body">
                <div class="num">3</div>
                <i class="fas fa-check-square icon"></i><br><span>{{ 'certif.confirm_profile' | translate }}</span>
            </div>
            <div class="card-footer">
                <div [routerLink]="['/payment/certification']" [class.disabled]="getAccount().getProfile().isCertified()" class="btn btn-primary btn-block">
                    <span>{{ 'certif.go' | translate }}</span>
                    <i class="fas fa-angle-right text-white ml-1"></i>
                </div>
            </div>
        </div>
    </div>
</div>
