import {Component} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Account} from '../../../models/account/account';

@Component({
    selector: 'app-certification-component',
    templateUrl: './certification.component.html',
    styleUrls: ['./certification.component.scss']
})
export class CertificationComponent {

    constructor(
        private modalService: ModalService,
        private user: CurrentUserService
    ) {}

    getAccount(): Account {
        return this.user.getAccount();
    }

    getProgressBar(): number {
        return this.user.getAccount().getProfile().getCompletionPercentage();
    }

    openEmailConfirmation(): void {
        this.modalService.openEmailConfirmation();
    }

    openMobileConfirmation(): void {
        this.modalService.openMobileConfirmation(false);
    }
}
