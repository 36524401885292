<div class="col-12 text-center mx-auto">
    <div class="pagination mt-4">
        <ul style="display: inherit;" *ngIf="maxPage>1" class="pagination">
            <li *ngIf="!isCurrentPage(1) && this.showArrows()">
                <a (click)="setCurrentPage(1)">
                    <i class="fas fa-angle-double-left"></i>
                </a>
            </li>
            <li *ngFor="let pageNumber of getPagesList()">
                <a (click)="setCurrentPage(pageNumber)"
                   [class.active]="isCurrentPage(pageNumber)">{{ pageNumber }}</a>
            </li>
            <li *ngIf="hasNext()">
                <a (click)="lastPage()">
                    <i class="fas fa-angle-double-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
