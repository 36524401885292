<ul>
    <li (click)="chatService.setMobileTabFromRooms(); chatService.openRoom(room);" *ngFor="let room of chatRoomService.getSortedRooms(this.limit)"
                [ngClass]="{'active': !isMobile && isCurrentRoom(room) }">
        <div class="utilisateur">
            <a *ngIf="!isMobile">
                <div class="users-img">
                    <app-user-photo [user]="room.getFriendUser()"></app-user-photo>

                    <span class="fa-stack" [ngClass]="room.getFriendUser().getActivity().getDynamicClass()"></span>

                    <span class="fa-stack bg-warning" *ngIf="chatUserService.isBlockedByMe(room.getFriendUser())">
                        <i class="fa fa-ban fa-stack-1x text-white"></i>
                    </span>
                </div>
            </a>
            <div *ngIf="isMobile" class="users-img">
                <app-user-photo [user]="room.getFriendUser()"></app-user-photo>

                <span class="fa-stack" [ngClass]="room.getFriendUser().getActivity().getDynamicClass()"></span>

                <span *ngIf="chatUserService.isBlockedByMe(room.getFriendUser())" class="fa-stack bg-warning">
                    <i class="fa fa-ban fa-stack-1x text-white"></i>
                </span>
            </div>

            <div class="users-detail text-truncate">
                <a *ngIf="!isMobile">
                    <p>
                        <span [class.blurred]="hasRule('nicknameBlur')">{{room.getFriendUser().getUsername()}}</span>

                        <i class="fas pl-2" [ngClass]="{
                            'fa-mars hom': room.getFriendUser().getGender() == 1,
                            'fa-venus fem': room.getFriendUser().getGender() == 2,
                            'fa-venus-mars cpl': room.getFriendUser().getGender() == 3
                        }"></i>

                        <br>
                        <small class="message-small">
                            <span [class.blurred]="hasRule('chatBlur')">{{room.getLastMessage()}}</span>
                            <br>
                            <span *ngIf="room.isTyping()"
                                  class="typing wow animated fadeIn infinite animated"
                                  style="visibility: visible; animation-name: fadeIn;">
                                                <i class="fas fa-circle"></i>
                                                <i class="fas fa-circle"></i>
                                                <i class="fas fa-circle"></i>
                                            </span>
                        </small>
                    </p>
                </a>
                <p *ngIf="isMobile">
                    <span [class.blurred]="hasRule('nicknameBlur')">{{room.getFriendUser().getUsername()}}</span>
                    <i class="fa pl-2" [ngClass]="{
                        'fa-venus fem': room.getFriendUser().getGender() == 2,
                        'fa-mars hom': room.getFriendUser().getGender() == 1,
                        'fa-venus-mars cpl': room.getFriendUser().getGender() == 3
                    }"></i>
                    <br>
                    <small>
                        <span [class.blurred]="hasRule('chatBlur')">{{room.getLastMessage()}}</span>
                        <br>
                        <span *ngIf="room.isTyping()" class="typing wow animated fadeIn infinite animated">
                            <i class="fas fa-circle"></i>
                            <i class="fas fa-circle"></i>
                            <i class="fas fa-circle"></i>
                        </span>
                    </small>
                </p>

                <div class="time-cnx">{{ getRoomDate(room) }}</div>
                <span *ngIf="room.getUnreadCount() > 0" [ngClass]="{'bg-primary': isMobile, 'bg-warning': !isMobile}" class="mess bg-primary">{{room.getUnreadCount()}}</span>
            </div>
        </div>
    </li>
</ul>
