import {Component, Input} from '@angular/core';
import {ProfileService} from '../../../services/profile/profile.service';
import {ModalService} from '../../../services/modal/modal.service';
import {Profile} from '../../../models/profile/profile';
import {Album} from '../../../models/profile/album';
import {CurrentUserService} from '../../../services/user/current-user.service';

@Component({
    selector: 'app-card-component',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {

    @Input() profile: Profile;
    @Input() currentProfile: boolean;

    constructor(
        private profileService: ProfileService,
        private modalService: ModalService,
        private currentUserService: CurrentUserService
    ) {
        this.profile = null;
    }

    isCurrentProfile() {
        return this.currentProfile;
    }

    openUploadPhoto(isCover: boolean = false): void {
        const data = {
            isMain: true,
            albumType: isCover ? Album.DEFAULT_COVER : Album.DEFAULT_PROFILE,
            aspectRatio: isCover ? 7 : 1,
            pickPhotoOnAlbum: true,
            title: isCover ? 'profile_picture.account_choose_cover_picture' : 'profile_picture.account_choose_profile_picture'
        };
        this.modalService.openUploadPhoto(null, data);
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule, this.profile.getId());
    }
}
