import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../services/profile/profile.service';
import {forkJoin} from 'rxjs';
import {Profile} from '../../models/profile/profile';
import {CurrentUserService} from '../../services/user/current-user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-contact-component',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    tab = 'friends';

    loading = true;

    idList = {
        friends: [],
        favorites: [],
        pendingFriends: [],
        requestedFriends: [],
        blockedProfiles: []
    };

    profiles = {
        friends: [],
        favorites: [],
        pendingFriends: [],
        requestedFriends: [],
        blockedProfiles: []
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private profileService: ProfileService,
        private currentUserService: CurrentUserService
    ) {}

    ngOnInit() {
        this.getContacts();
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.tab = params.get('tab') || 'friends';
            this.findProfiles();
        });
    }

    getProfiles(): Profile[] {
        if (this.currentUserService.refreshPage.contact) {
            this.currentUserService.refreshPage.contact = false;
            this.getContacts();
        }
        return this.profiles[this.tab];
    }

    countProfiles(index) {
        return this.idList[index].length;
    }

    getContacts() {
        const friends = this.profileService.getRelationIds('friend');
        const pendingFriends = this.profileService.getRelationIds('friend', {status: 0, side: 'user'});
        const requestedFriends = this.profileService.getRelationIds('friend', {status: 0, side: 'request'});
        const blockedProfiles = this.profileService.getRelationIds('blocked');
        const favorites = this.profileService.getRelationIds('favorite');

        forkJoin(friends, pendingFriends, requestedFriends, blockedProfiles, favorites).subscribe(
            ([friendsRes, pendingFriendsRes, requestedFriendsRes, blockedProfilesRes, favorites]: any) => {
                this.setLists(friendsRes, pendingFriendsRes, requestedFriendsRes, blockedProfilesRes, favorites);
                this.findProfiles();
            }
        );
    }

    isTab(tab: string): boolean {
        return this.tab === tab;
    }

    private findProfiles() {
        // This is to prevent loading profiles on the wrong tab if the user change before the request ends.
        const tab = this.tab;

        if (this.idList[tab].length === 0) {
            this.profiles[tab] = [];
            this.loading = false;
            return;
        }

        this.loading = true;
        this.profileService.findAll(
            1, this.idList[tab].length,
            {
                profileIds: this.idList[this.tab].join(',')
            }
        ).subscribe((result: any) => {
            this.profiles[tab] = [];
            for (const data of result.data) {
                this.profiles[tab].push(new Profile().deserialize(data));
            }
        }, error => {
            console.log(error);
        }, () => {
            this.loading = false;
        });
    }

    private setLists(friends, pendingFriends, requestedFriends, blockedProfiles, favorites) {
        // Removing the blockedProfiles from the other lists
        this.idList.friends = friends.data.filter( e => !blockedProfiles.data.includes(e));
        this.idList.pendingFriends = pendingFriends.data.filter( e => !blockedProfiles.data.includes(e));
        this.idList.requestedFriends = requestedFriends.data.filter( e => !blockedProfiles.data.includes(e));
        this.idList.favorites = favorites.data.filter( e => !blockedProfiles.data.includes(e));
        this.idList.blockedProfiles = blockedProfiles.data;
    }
}
