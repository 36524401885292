import {Injectable} from '@angular/core';
import {ModalService} from '../../modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../../login/login.service';
import {UserMessage} from '../../../models/chat/user-message/user-message';
import {ChatService} from '../chat.service';
import {SocketService} from '../../socket/socket.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ChatUserMessageService {

    private context: string;
    private userMessage: UserMessage;
    private showUserMessage: boolean;

    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        private loginService: LoginService,
        private chatService: ChatService,
        private socketService: SocketService,
        private router: Router
    ) {
        this.context = null;
        this.showUserMessage = false;
    }

    /*
     * Show the right user message
     * Eq. when user is banned or suspended...
     */
    showMessage(userMessage: UserMessage, context: string): Promise<any> {
        this.context = context;
        this.userMessage = userMessage;

        if (!this.userMessage) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }

        switch (this.userMessage.getType()) {
            case UserMessage.TYPE_USER_BANNED:
                return this.showUserBanned();
                break;
            case UserMessage.TYPE_USER_SUSPENDED:
                return this.showUserSuspended();
                break;
            case UserMessage.TYPE_USER_IS_CONNECTED:
                return this.showUserIsAlreadyConnected();
                break;
            case UserMessage.TYPE_SESSION_KILLED:
                return this.showSessionKilled();
                break;
            case UserMessage.TYPE_BAD_CHAT_VERSION:
                return this.showBadChatVersion();
                break;
            case UserMessage.TYPE_WARNING:
                return this.showWarning();
                break;
            default:
                return this.showDefault();
                break;
        }
    }

    /*
     * Show the user banned message
     */
    showUserBanned() {
        return new Promise((resolve, reject) => {
            if (this.userMessage.getContext() !== this.context) {
                reject();
            } else {
                this.showUserBannedModal(resolve);
            }
        });
    }

    showUserBannedModal(resolve = null) {
        this.showUserMessage = true;

        this.modalService.openConfirmation({
            data: {
                title: 'chat_notif.banned_title',
                question: 'chat_notif.banned_message',
                responses: [
                    {text: 'close'}
                ]
            }
        }).afterClosed().subscribe(() => {
            if (resolve !== null) {
                resolve();
            }
            this.showUserMessage = false;
            this.loginService.logout();
        });
    }

    /*
     * Show the user suspended message
     */
    showUserSuspended() {
        return new Promise((resolve, reject) => {
            const dateEnd: Date = (this.userMessage.getDateEnd()) ? new Date(this.userMessage.getDateEnd()) : null;

            if (!this.chatService.isConnectionfailed() && this.chatService.isConnected()) {
                // user is not suspended anymore, we can resolve this message
                resolve();
                return true;
            }

            if (this.context !== UserMessage.CONTEXT_CHAT || !dateEnd) {
                reject();
            } else {
                this.showUserMessage = true;

                let message = '';

                message = this.translate.instant('chat_notif.suspended_message') + ' '
                    + dateEnd.toLocaleString() + '. <br/>'
                    + this.translate.instant('chat_notif.reconnect_later');

                this.modalService.openFullScreenConfirmation({
                    title: 'chat_notif.suspended_title',
                    question: message,
                    responses: [
                        {
                            text: 'close',
                            className: 'btn-primary'
                        }
                    ],
                    faClass: 'fas fa-user',
                    validationCallback: () => {
                        // the user has read the message, we disconnect him from the chat and we route him on the homepage
                        this.chatService.logout();
                        this.showUserMessage = false;
                        this.router.navigate(['/home']);
                        return true;
                    }
                });
            }
        });
    }

    /*
     * Show the user user is already connected message
     */
    showUserIsAlreadyConnected() {
        return new Promise((resolve, reject) => {
            if (this.context !== UserMessage.CONTEXT_CHAT) {
                reject();
            } else {
                this.showUserMessage = true;

                this.modalService.openConfirmation({
                    data: {
                        title: 'chat_notif.already_connected_title',
                        question: 'chat_notif.already_connected',
                        responses: [
                            {text: 'close'}
                        ]
                    }
                }).afterClosed().subscribe(() => {
                    resolve();
                    this.showUserMessage = false;
                });
            }
        });
    }

    /*
     * Show the user session killed message
     */
    showSessionKilled() {
        return new Promise((resolve, reject) => {
            if (this.context !== UserMessage.CONTEXT_CHAT) {
                reject();
            } else {
                this.showUserMessage = true;

                this.modalService.openConfirmation({
                    data: {
                        title: 'chat_notif.session_killed_title',
                        question: 'chat_notif.session_killed',
                        responses: [
                            {text: 'chat_notif.reconnect'}
                        ]
                    }
                }).afterClosed().subscribe(() => {
                    resolve();
                    this.showUserMessage = false;
                    this.loginService.logout();
                });
            }
        });

    }

    /*
     * Show the user bad chat version message
     */
    showBadChatVersion() {
        return new Promise((resolve, reject) => {
            if (this.context !== UserMessage.CONTEXT_CHAT) {
                reject();
            } else {
                this.showUserMessage = true;

                this.modalService.openConfirmation({
                    data: {
                        title: 'chat_notif.bad_version_title',
                        question: 'chat_notif.bad_version_message',
                        responses: [
                            {text: 'close'}
                        ]
                    }
                }).afterClosed().subscribe(() => {
                    resolve();
                    this.showUserMessage = false;
                });
            }
        });
    }

    /*
     * Show the user warning message
     */
    showWarning() {
        return new Promise((resolve, reject) => {
            if (this.context !== UserMessage.CONTEXT_CHAT) {
                reject();
            } else {
                this.showUserMessage = true;

                const userMessageId = this.userMessage.getId();

                this.modalService.openConfirmation({
                    data: {
                        title: 'warning',
                        contentTitle: 'you_got_warning',
                        beforeContentTitleImage: '/assets/img/common/chat-warning.png',
                        sampleText: this.userMessage.getMessage(),
                        faClass: 'fa-exclamation-triangle',
                        callback: () => {
                            this.socketService.emit('user_message_read', userMessageId);
                        }
                    }
                }).afterClosed().subscribe(() => {
                    resolve();
                    this.showUserMessage = false;
                });
            }
        });
    }

    /*
     * Show the user default message
     */
    showDefault() {
        return new Promise((resolve, reject) => {
            if (this.context !== UserMessage.CONTEXT_CHAT) {
                reject();
            } else {
                this.showUserMessage = true;

                this.modalService.openConfirmation({
                    data: {
                        title: 'chat_notif.error_occured',
                        question: 'chat_notif.reconnect_later',
                        responses: [
                            {text: 'close'}
                        ]
                    }
                }).afterClosed().subscribe(() => {
                    resolve();
                    this.showUserMessage = false;
                });
            }
        });
    }

    isShown() {
        return this.showUserMessage;
    }
}
