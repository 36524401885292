import {Injectable} from '@angular/core';
import {Room} from '../../../models/chat/room/room';
import {Message} from '../../../models/chat/message/message';
import {CurrentUserService} from '../../user/current-user.service';
import {SocketService} from '../../socket/socket.service';
import {ChatUserService} from '../chat-user/chat-user.service';
import {ChatRoomService} from '../chat-room/chat-room.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService {

    private loadingMessages = [];

    constructor(
        private currentUserService: CurrentUserService,
        private socketService: SocketService,
        private chatUserService: ChatUserService,
        private chatRoomService: ChatRoomService,
        private router: Router
    ) {
    }

    onPrivateMessage() {
        this.socketService.on('private_message', (messageData: any) => {
            const missingUsers = [];

            const user = this.chatUserService.getUserById(messageData.userId);
            const recipient = this.chatUserService.getUserById(messageData.recipientId);

            if (user === null || recipient === null) {
                // User is missing ?
                if (user === null) {
                    if (!missingUsers.includes(messageData.userId)) {
                        missingUsers.push(messageData.userId);
                    }
                }

                // Recipient is missing ?
                if (recipient === null) {
                    if (!missingUsers.includes(messageData.recipientId)) {
                        missingUsers.push(messageData.recipientId);
                    }
                }

                this.loadingMessages.push(messageData);
            } else {
                // Add messageData
                const message = this.addMessageFromData(messageData);
                this.updateLastMessage(message);

                // Scroll on new message
                if (message.getRoom() === this.chatRoomService.getCurrentRoom()) {
                    message.getRoom().setScrollToBottom(true);
                }
            }

            // Load missing users
            if (missingUsers.length) {
                this.chatUserService.loadUsersByIds(missingUsers).then(() => {
                    this.retryLoadingMessages();
                    this.chatRoomService.sortRooms();
                });
            } else {
                this.chatRoomService.sortRooms();
            }
        });
    }

    /**
     * call chat-server to get room's messages
     * Messages will be push into the room
     * @param room
     * @param start
     * @param count
     */
    loadMessages(room: Room, start: number = 0, count: number = 10): Promise<void> {
        return new Promise<void>((resolve) => {
            const currentUserId = this.currentUserService.getAccount().getId();

            if (start === 0 && (room.isLoaded() || !room.getId())) {
                return;
            }

            this.socketService.emit('private_room_get_messages', {
                roomId: room.getId(),
                start,
                count,
                userId: currentUserId
            }).then((data: any) => {

                for (const i of Object.keys(data)) {
                    const messageData = data[i];
                    const messageUser = (room.getCurrentUser().getId() === messageData.userId) ?
                        room.getCurrentUser() : room.getFriendUser();

                    if (!room.getMessages().find((a: any) => {
                        return a.messageId === messageData.messageId;
                    })) {
                        // Si le message n'est pas dans la liste, alors l'ajouter !
                        const privateMessage = new Message().deserialize({
                            user: messageUser,
                            room,
                            messageId: messageData.messageId,
                            message: messageData.message,
                            time: messageData.time
                        });

                        room.addMessage(privateMessage);
                    }
                }

                room.sortMessages();

                room.setLoaded(true);

                // si nous avons moins de 10 messages,
                // c'est qu'il n'y a pas d'infinite scroll pour cette conversation :
                room.setInfiniteScroll(data.length >= 10);

                room.setScrollToBottom(start === 0);

                resolve();
            });
        });
    }

    /**
     * Try to load messages
     */
    retryLoadingMessages() {
        const messages = this.loadingMessages;
        this.loadingMessages = [];

        for (const messageData of messages) {
            const message = this.addMessageFromData(messageData);
            this.updateLastMessage(message);

            if (message === null) {
                this.loadingMessages.push(messageData);
            }
        }
    }

    /**
     * Add the message to the chat (or create it if not exists)
     * @param messageData
     */
    addMessageFromData(messageData) {
        const me = this.chatUserService.getMe();

        const user = this.chatUserService.getUserById(messageData.userId);

        if (user === null) {
            return;
        }

        const recipient = this.chatUserService.getUserById(messageData.recipientId);

        if (recipient === null) {
            return;
        }

        let room = this.chatRoomService.getRoomById(messageData.roomId);

        if (!room) {
            room = new Room();
        }

        const privateMessage = new Message().deserialize({
            user,
            room,
            messageId: messageData.messageId,
            message: messageData.message,
            time: messageData.time
        });

        // we only notify if the room is not open or if we're not on the chat
        privateMessage.setNotify(messageData.notify);

        // New chat ? Update the roomId :
        if (!room.getId() && messageData.roomId > 0) {
            room.setId(messageData.roomId);
            room.resetNewMessagesCount();
            room.setEnabled(true);
            room.setLoaded(true);
            if (user.getUid() === me.getUid()) {
                room.setCurrentUser(user);
                room.setFriendUser(recipient);
                this.chatRoomService.openRoom(room);
            } else {
                room.setCurrentUser(recipient);
                room.setFriendUser(user);
                this.chatRoomService.addRoom(room);
            }
        }

        room.addMessage(privateMessage);

        // No notify, we send to server that the messages have been read
        if (this.router.url.startsWith('/chat')) {
            if (!privateMessage.isNotify() ||
                (this.chatRoomService.getCurrentRoom() && this.chatRoomService.getCurrentRoom().getId() === room.getId())
            ) {
                this.chatRoomService.setRoomRead(room);
            }
        }

        return privateMessage;
    }

    updateLastMessage(message: Message) {
        const room: Room = message.getRoom();

        room.setLastMessage(message.getMessage());
        room.setLastMessageId(message.getMessageId());
        room.setLastMessageTime(message.getTime());
        room.setLastMessageUserId(message.getUser().getId());
    }

    sendMessage(room: Room, messageText: string) {
        this.socketService.emit('message', {
            recipientId: room.getFriendUser().getId(),
            message: messageText
        }).then();
    }

    onDeletedMessage(onDeleteCurrentRoomCallback) {
        this.socketService.on('deleted_message', (messageData: any) => {

            const me = this.chatUserService.getMe();

            const senderUser = this.chatUserService.getUserById(messageData.userId);
            const recipientUser = this.chatUserService.getUserById(messageData.recipientId);

            if (!(senderUser && recipientUser)) {
                return;
            } else if (senderUser.getId() === me.getId()) {
                // Je suis l'expéditeur du message,
                // Récupération de la room avec le récepteur pour supprimer le message :
                this.chatRoomService.deleteFriendRoomMessage(recipientUser, messageData).then((result: any) => {
                    if (result.success && result.deleteRoom && result.isCurrentRoom) {
                        onDeleteCurrentRoomCallback();
                    }
                });
            } else if (recipientUser.getId() === me.getId()) {
                // Je suis le récepteur du message,
                // Alors le "friend User" = "senderUser".
                // Récupération de la room avec lui en utilisant senderUser pour supprimer le message
                this.chatRoomService.deleteFriendRoomMessage(senderUser, messageData).then((result: any) => {
                    if (result.success && result.deleteRoom && result.isCurrentRoom) {
                        onDeleteCurrentRoomCallback();
                    }
                });
            }
        });
    }
}
