import {Component, Input, OnInit} from '@angular/core';
import {Account} from '../../../models/account/account';
import {ModalService} from '../../../services/modal/modal.service';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

    @Input() account: Account;

    constructor(
        private modalService: ModalService
    ) {
    }

    ngOnInit(): void {
    }

    openUnsubscribe() {
        this.modalService.openUnsubscribe();
    }

}
