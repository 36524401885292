import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CamModel} from '../../../models/cam-model/cam-model';

@Component({
    selector: 'app-sex-cam-confirmation',
    templateUrl: './sex-cam-confirmation.component.html',
    styleUrls: ['./sex-cam-confirmation.component.scss']
})
export class SexCamConfirmationComponent {

    title: string;
    message: string;
    question: string;
    detail: string;
    camModel: CamModel
    callback: any;

    constructor(
        public dialogRef: MatDialogRef<SexCamConfirmationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title;
        this.message = data.message;
        this.question = data.question;
        this.detail = data.detail || null;
        this.camModel = data.camModel;
        this.callback = data.callback;
    }

    getTranslationData(): any {
        return {
            nickname: this.camModel.nickname,
            peepingShowRate: this.camModel.getCoinsByMinute(CamModel.PEEPING_SHOW),
            vipShowRate: this.camModel.getCoinsByMinute(CamModel.VIP_SHOW)
        };
    }

    confirm(result) {
        this.dialogRef.close();

        this.callback(result);
    }
}
