import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Country implements Deserializable {

    code: string;
    indicative: number;
    authorized: boolean;
    name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getCode(): string {
        return this.code;
    }

    getIndicative(): number {
        return this.indicative;
    }

    getAuthorized(): boolean {
        return this.authorized;
    }

    getName(): string {
        return this.name;
    }
}
