import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Subscription implements Deserializable {

    id: number;
    start: any;
    end: any;
    active: boolean;
    unsubscribed: boolean;
    rejected: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId(): number {
        return this.id;
    }

    getStart(): any {
        return this.start;
    }

    getEnd(): any {
        return this.end;
    }

    setUnsubscribed(unsubscribed: boolean) {
        this.unsubscribed = unsubscribed;
    }

    hasUnsubscribed() {
        return this.unsubscribed;
    }

    isActive(): boolean {
        if (this.rejected) {
            return false;
        }
        const now = new Date();

        return (this.start && this.end && now > new Date(this.start) && now < new Date(this.end));
    }

    isRejected(): boolean {
        return this.rejected;
    }
}
