import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-live-payment-accepted',
    templateUrl: './live-payment-accepted.component.html',
    styleUrls: ['./live-payment-accepted.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LivePaymentAcceptedComponent implements OnInit {

    constructor(
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    redirectToHome() {
        this.router.navigate(['/home']).then();
    }
}
