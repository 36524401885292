<div class="card card-profile mt-4">
    <div class="card-body">
        <h1 class="font-weight-bold">{{ eBook.title }}</h1>

        <div class="mb-2 text-muted">Auteur <span class="ml-1">|</span> Éditeur <span class="ml-1">|</span> JJ/MM/AAAA <span class="ml-1">|</span> Thème <span class="ml-1">|</span> <i class="far fa-comment ml-1"></i> 985 commentaires <span class="ml-1">|</span> <i class="far fa-eye ml-1"></i> 985 vues</div>

        <div class="row">
            <div class="col-lg-3 col-sm-5 fiche-ebook mb-3 mb-sm-0">
                <div class="selection-ebook badge badge-warning">N°1 des ventes</div>
                <img class="img-fluid" [src]="imageUrl(eBook.id)" alt="" style="width:100%;">
                <div class="row mt-4">
                    <div class="col-4"><img class="img-fluid" [src]="imageUrl(eBook.id)" alt=""></div>
                    <div class="col-4"><img class="img-fluid" [src]="imageUrl(eBook.id)" alt=""></div>
                    <div class="col-4"><img class="img-fluid" [src]="imageUrl(eBook.id)" alt=""></div>
                </div>
            </div>
            <div class="col-lg-9 col-sm-7">
                <p class="lead font-weight-bold">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <div class="row">
                    <div class="col-12 text-right">
                        <p class="lead text-success">Prix : Inclus dans votre abonnement</p>
                        <a href="" class="btn btn-primary btn-sm"><i class="fas fa-download"></i> Télécharger</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<h2 class="h5 font-weight-bold mt-5">Les membres ayant consulté cet eBook ont également regardé :</h2>

<div id="photos" class="carousel slide carousel-multi-item" data-ride="carousel">
    <!--Controls-->
    <div class="controls-top">
        <a class="btn-left" href="#photos" data-slide="prev"><i class="fas fa-chevron-left"></i></a>
        <a class="btn-right" href="#photos" data-slide="next"><i class="fas fa-chevron-right"></i></a>
    </div>
    <!--/.Controls-->

    <!--Indicators-->
    <ol class="carousel-indicators">
        <li data-target="#photos" data-slide-to="0" class=""></li>
        <li data-target="#photos" data-slide-to="1" class=""></li>
        <li data-target="#photos" data-slide-to="2" class="active"></li>
    </ol>
    <!--/.Indicators-->

    <!--Slides-->
    <div class="carousel-inner" role="listbox">
        <!--Slide 1-->
        <div class="carousel-item">
            <div class="row">

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

            </div>
        </div>
        <!--Slide 1-->


        <!--Slide 2-->
        <div class="carousel-item">
            <div class="row">

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

            </div>
        </div>
        <!--Slide 2-->

        <!--Slide 3-->
        <div class="carousel-item active">
            <div class="row">
                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

                <div class="col-md-2 col-4 d-none d-md-block">
                    <a href="sesalbums.html"><img alt="" class="img-fluid w-100" [src]="imageUrl(eBook.id)"></a>
                </div>

            </div>
        </div>
        <!--Slide 3-->
    </div>
    <!--/.Slides-->
</div>

<h2 class="h5 font-weight-bold mt-5">Commentaires <span class="badge badge-primary">985</span> :</h2>

<div class="comment">
    <div class="comment-header d-flex justify-content-between">
        <div class="user d-flex align-items-center">
            <div class="user-comment position-relative"><img src="images/femme02.jpg" alt="..." class="img-fluid"><div class="status online"></div></div>

            <div class="user-pseudo">
                <strong><i class="fas fa-venus icon-female"></i> Lexane777</strong>
                <small class="text-muted">JJ/MM/AAA</small>
            </div>
        </div>
    </div>
    <div class="comment-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
    </div>
</div>

<div class="comment">
    <div class="comment-header d-flex justify-content-between">
        <div class="user d-flex align-items-center">
            <div class="user-comment position-relative"><img src="images/femme03.jpg" alt="..." class="img-fluid"><div class="status online"></div></div>
            <div class="user-pseudo">
                <strong><i class="fas fa-venus icon-female"></i> Lexane777</strong>
                <small class="text-muted">JJ/MM/AAA</small>
            </div>
        </div>
    </div>

    <div class="comment-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
    </div>
</div>

<div class="add-comment">
    <h2 class="h5 font-weight-bold mt-5">Laisser un commentaire :</h2>

    <form action="#" class="commenting-form">
        <div class="row">
            <div class="form-group col-12">
                <div class="comment">
                    <div class="comment-header d-flex justify-content-between">
                        <div class="user d-flex align-items-center">
                            <div class="user-comment position-relative"><img src="images/femme01.jpg" alt="..." class="img-fluid"><div class="status online"></div></div>
                            <div class="user-pseudo">
                                <strong><i class="fas fa-venus icon-female"></i> Lexane777</strong>
                                <small class="text-muted">JJ/MM/AAA</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group col-md-12">
                <textarea name="usercomment" id="usercomment" placeholder="Votre commentaire" class="form-control" style="min-height:250px;"></textarea>
            </div>
            <div class="form-group col-md-12 text-right">
                <button type="submit" class="btn btn-primary btn-sm">Poster un commentaire</button>
            </div>
        </div>
    </form>
</div>
