<div *ngIf="getFooterAlert()" class="alert alert-warning notification-fixed notification-bottom">
    <div class="container-fluid">
        <div class="row no-gutters get-subscription-container">
            <div class="row no-gutters get-subscription-body">
                <p>
                    <strong class="mr-2"><i class="fas fa-exclamation-circle"></i> <span
                            class="picto-margin">{{ getFooterAlert().title }}</span></strong>
                    <span>{{ getFooterAlert().content }}</span>
                </p>

                <a *ngIf="isSubscriptionRuleSet()"
                   [routerLink]="['/payment']" [queryParams]="{action: 'subscription_notification'}"
                   class="btn btn-success btn-lg btn-update text-uppercase mt-1">{{ getFooterAlert().button }}</a>

                <a *ngIf="!isSubscriptionRuleSet()" (click)="openLoveCoinModal()"
                   class="btn btn-success btn-lg btn-update text-uppercase mt-1">{{ getFooterAlert().button }}</a>
            </div>
            <a (click)="close()" class="close" data-dismiss="alert" aria-label="close" title="close">
                <i class="fas fa-times"></i>
            </a>
        </div>
    </div>
</div>
