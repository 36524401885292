import {Component, OnInit} from '@angular/core';
import {InitService} from './services/init/init.service';
import {LoginService} from './services/login/login.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
import {CurrentUserService} from './services/user/current-user.service';
import {ModalService} from './services/modal/modal.service';
import {BrowserService} from './services/browser/browser.service';
import {StorageService} from './services/storage/storage.service';
import {ProfileService} from './services/profile/profile.service';


declare var gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    obsoleteBrowser = false;

    constructor(
        private initService: InitService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private currentUserService: CurrentUserService,
        private modalService: ModalService,
        private browserService: BrowserService,
        private storageService: StorageService,
        private profileService: ProfileService,
        router: Router
    ) {
        const navEndEvent$ = router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        );
        navEndEvent$.subscribe((e: NavigationEnd) => {
            gtag('config', 'UA-149165622-1', {page_path:e.urlAfterRedirects});
        });
    }

    ngOnInit() {
        if (!this.initService.isLoaded()) {
            if (this.loginService.isConnected()) {
                this.initService.load().subscribe(success => {
                    if (!success) {
                        this.loginService.logout();
                    }
                    const data = this.currentUserService.suspensionModalData();
                    if (data !== false) {
                        this.modalService.openFullScreenConfirmation(data);
                    }

                    // sent session to profile (with browser data)
                    // only if browser is not compatible !
                    if (!this.browserService.isCompatible()) {
                        this.putProfileSession();
                    }
                });
            }
        }

        this.obsoleteBrowser = !this.browserService.isCompatible();
    }

    get isReady(): boolean {
        if (this.loginService.isConnected()) {
            return this.initService.isLoaded();
        } else {
            return true;
        }
    }

    get navigatorIsCompatible(): boolean {
        const ignoreObsoleteBrowser = (this.storageService.get('ignore_obsolete_browser') === 'ignore');

        return ignoreObsoleteBrowser || this.obsoleteBrowser === false;
    }

    ignoreOutOfDateBrowser() {
        this.storageService.set('ignore_obsolete_browser', 'ignore');
    }

    putProfileSession() {
        const browserData: any = this.browserService.getDeviceData();

        this.profileService.putProfileSession({
            session: {
                browser: {
                    language: browserData.language,
                    obsolete: browserData.obsolete,
                    referer: document.referrer,
                    screen: browserData.screenResolution,
                    timezone: (Intl) ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
                    userAgent: browserData.userAgent
                }
            }
        }).subscribe();
    }
}
