import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';

@Component({
    selector: 'app-restriction-banner',
    templateUrl: './restriction-banner.component.html',
    styleUrls: ['./restriction-banner.component.scss']
})
export class RestrictionBannerComponent {

    constructor(
        private router: Router,
        private currentUserService: CurrentUserService,
        private chatRoomService: ChatRoomService
    ) {}

    getBanner(): any {
        return this.currentUserService.getAccount().getRuleSet().isSubscription() &&
            this.currentUserService.getAccount().getRuleSet().banner;
    }

    useBanner(): boolean {
        return this.chatRoomService.hasCurrentRoom() && this.currentUserService.useBanner();
    }

    goToPayment() {
        this.router.navigate(['/payment'], {queryParams: {
            action: 'chat_message',
            profileId: this.chatRoomService.getCurrentRoom().getFriendUser().getUid()
        }});
    }
}
