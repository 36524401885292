import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../models/chat/user/user';
import {Profile} from '../../../models/profile/profile';
import {ModalService} from '../../../services/modal/modal.service';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {ChatService} from '../../../services/chat/chat.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-chat-user-action',
    templateUrl: './chat-user-action.component.html',
    styleUrls: ['./chat-user-action.component.scss']
})
export class ChatUserActionComponent implements OnInit {

    @Input() user: User;
    @Input() isRoom: boolean;
    profileId: number;

    constructor(
        public chatUserService: ChatUserService,
        private chatService: ChatService,
        private modalService: ModalService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.profileId = +this.route.snapshot.paramMap.get('id');
    }

    getProfileRoute(profileId) {
        return [(this.profileId > 0 ? '/..' : '') + '/profile', profileId];
    }

    reportProfile(user: User) {
        const profile = new Profile();
        profile.deserialize({
            id: user.getUid(),
            nickname: user.getUsername()
        });
        this.modalService.openReportProfile(profile);
    }

    blockProfile(user: User) {
        const isBlockedByMe = this.chatUserService.isBlockedByMe(user);
        const modalTitle = isBlockedByMe ? 'forbidden_profile.unblock' : 'chat.block_profile';
        const modalQuestion = isBlockedByMe ? 'contact.modal_content_unblock' : 'contact.modal_content_block';
        const responses = [
            {text: 'no', result: false},
            {text: 'yes', result: true}
        ];

        this.confirmModal(modalTitle, modalQuestion, responses)
            .afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.updateBlockProfile(user);
            }
        });
    }

    confirmModal(title: string, question: string, responses: any) {
        return this.modalService.openConfirmation({
            data: {
                title: title,
                question: question,
                responses,
                user: this.user
            }
        });
    }

    updateBlockProfile(user: User) {
        const isBlockedByMe = this.chatUserService.isBlockedByMe(user);
        const event = isBlockedByMe ? 'remove_blocking' : 'ignore_friend';
        const modalTitle = isBlockedByMe ? 'forbidden_profile.unblock' : 'chat.block_profile';
        let modalQuestion = isBlockedByMe ? 'contact.modal_content_unblocked' : 'contact.modal_content_blocked';
        const responses = [{text: 'ok', result: false}];

        modalQuestion += '';

        this.chatService.getSocketService().emit(event, user.getId()).then((response: object) => {
            if (this.chatUserService.getMe()) {
                this.chatUserService.getMe().setBlockedList(response);
            }
            this.confirmModal(modalTitle, modalQuestion, responses);
        });
    }
}
