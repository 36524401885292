import {Injectable} from '@angular/core';
import {Account} from '../../models/account/account';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from '../../models/account/subscription';
import {formatDate} from '../helper/date.service';
import {Router} from '@angular/router';
import {Profile} from '../../models/profile/profile';
import {RuleSet} from '../../models/account/rule-set/rule-set';
import {StorageService} from '../storage/storage.service';
import {CoinService} from '../coin/coin.service';
import {ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {

    private readonly allowedRoute = ['/home', '/account', '/email-action-resolver'];
    private profileEndpoint;
    private tags = {};
    private resendData = {
        suspension: null,
        deletion: null
    };
    private updateMobileSuccess: boolean;
    private accountLoadedSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    account: Account = new Account();

    ga = '';
    profileId: number;
    refreshPage = {
        home: false,
        profile: false,
        notification: false,
        contact: false
    };

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private storage: StorageService,
        private coinService: CoinService
    ) {}

    getAccountLoadedSubject(): ReplaySubject<boolean> {
        return this.accountLoadedSubject;
    }

    setAccountLoaded(loaded: boolean) {
        this.accountLoadedSubject.next(loaded);
    }

    setCoins(coins: number) {
        this.account.setCoins(coins);
    }

    freemiumProfileIsConfirmed(): boolean {
        let freemiumConfirmed = true;

        const fieldsStatus: any = this.account.profile.getFieldsStatus();

        if (
            !this.account.profile.getPhoto()
            || fieldsStatus.gender !== 'provided'
            || fieldsStatus.searchedGender !== 'provided'
            || fieldsStatus.nickname !== 'provided'
            || fieldsStatus.password !== 'provided'
            || fieldsStatus.city !== 'provided'
            || fieldsStatus.postalCode !== 'provided'
            || fieldsStatus.birthDate !== 'provided'
        ) {
            freemiumConfirmed = false;
        }

        return freemiumConfirmed || this.account.getSettings().finishPhotoLater;
    }

    unsubscribe(comment: string) {
        return this.http.post(this.profileEndpoint + '/unsubscribe', {comment});
    }

    setAccount(account, profile) {
        this.account.deserialize(account);
        this.account.setProfile(profile);

        this.profileEndpoint = `${environment.backendUrl}/profile/${this.account.getProfile().getId()}`;
    }

    getAccount(): Account {
        return this.account;
    }

    setGa(ga: string) {
        this.ga = ga;
    }

    getGa(): string {
        return this.ga;
    }

    findTransactions() {
        return this.http.get(this.profileEndpoint + '/transaction');
    }

    updateNotificationSettings(index) {
        this.account.notifications[index] = !this.account.notifications[index];
        const notifications = {};
        const notificationKeys = Object.keys(this.account.getNotifications());
        for (const key of notificationKeys) {
            notifications[key] = this.account.getNotifications()[key] ? 1 : 0;
        }
        return this.http.put(this.profileEndpoint + '/notification/settings', {notifications});
    }

    updateEmail(email, callback, callbackError = null) {
        return this.http.get(environment.backendUrl + '/profile/check-email?email=' + email)
            .subscribe((response: any) => {
                // Invalid email
                if (!response.data.details.dns || !response.data.details.syntax || !response.data.details.allowed) {
                    if (callbackError) {
                        callbackError();
                    }
                    this.toastr.error(this.translate.instant('on_boarding.invalid_email'));
                    // Email already used
                } else if (!response.data.details.available) {
                    if (callbackError) {
                        callbackError();
                    }
                    this.toastr.error(this.translate.instant('on_boarding.email_not_unique'));
                    // Ask confirmation code
                } else {
                    this.sendEmailConfirmation(email).then(() => {
                        callback();
                    });
                }
            }, () => {
                if (callbackError) {
                    callbackError();
                }
                this.displayDefaultError();
            });
    }

    sendCode(type, value) {
        const data: any = {};
        if (value) {
            data[type] = value;
        }
        return this.http.post(`${this.profileEndpoint}/${type}/send-code`, data);
    }

    updateMobile(mobile, callback, callbackError = null) {
        this.updateMobileSuccess = false;

        return this.sendCode('mobile', mobile).subscribe((response: any) => {
            if (response && !response.success) {
                if (callbackError) {
                    callbackError();
                }
                this.displayDefaultError();
            } else {
                this.updateMobileSuccess = true;
                this.account.certifications.mobile = false;
                callback();
            }
        }, (error: any) => {
            let showDefaultError = true;

            if (callbackError) {
                if (typeof error.code !== 'undefined') {
                    showDefaultError = false;
                    callbackError(error.code);
                } else {
                    callbackError();
                }
            }

            if (showDefaultError) {
                this.displayDefaultError();
            }
        }, () => {
            // for 403 error (restriction 10 tentatives)
            // need "updateMobileSuccess" to trigger callbackError only if not updated (not success) !
            if (!this.updateMobileSuccess && callbackError) {
                callbackError('sms_interval_limit');
            }
        });
    }

    sendEmailConfirmation(email = null) {
        return new Promise((resolve, reject) => {
            this.sendCode('email', email).subscribe(() => {
                if (email) {
                    this.account.certifications.email = false;
                }
                resolve();
            }, () => {
                this.displayDefaultError();
                reject();
            });
        });
    }

    updateNickname(nickname, callback) {
        return this.http.get(environment.backendUrl + '/profile/check-nickname?nickname=' + nickname)
            .subscribe((response: any) => {
                // Invalid nickname
                if (!response.data.valid) {
                    this.toastr.error(this.translate.instant('on_boarding.invalid_nickname'));
                    // Nickname already used
                } else if (!response.data.available) {
                    this.toastr.error(this.translate.instant('on_boarding.nickname_not_unique'));
                    // Ask confirmation code
                } else {
                    this.sendCode('nickname', nickname).subscribe((response2: any) => {
                        if (response2 && !response2.success) {
                            this.toastr.error(response2.message);
                        } else {
                            callback();
                        }
                    }, () => {
                        this.displayDefaultError();
                    });
                }
            }, () => {
                this.displayDefaultError();
            });
    }

    updatePassword(password, callback) {
        return this.sendCode('password', password).subscribe((response: any) => {
            if (response && !response.success) {
                this.displayDefaultError();
            } else {
                callback();
            }
        }, () => this.displayDefaultError());
    }

    updatePasswordFinish(value :string, callback) {
        return this.http.put(`${environment.backendUrl}/profile/${this.profileId}/password`, {value}).subscribe((response: any) => {
            if (response && !response.success) {
                this.displayDefaultError();
            } else {
                callback();
            }
        }, () => this.displayDefaultError());
    }

    finishSignupNotification() {
        return this.http.get(`${environment.backendUrl}/profile/${this.profileId}/finish-signup`).subscribe((response: any) => {
            if (response && !response.success) {
                this.displayDefaultError();
            }
        });
    }

    checkMobile(code) {
        return this.checkCode(code, 'mobile');
    }

    checkEmail(code) {
        return this.checkCode(code, 'email');
    }

    checkNickname(code) {
        return this.checkCode(code, 'nickname');
    }

    checkPassword(code) {
        return this.checkCode(code, 'password');
    }

    validPassword(password) {
        return this.http.get(`${environment.backendUrl}/user/valid-password`, {
            params: {
                password
            }
        });
    }

    upsellUnlover() {
        return this.http.get(environment.backendUrl + '/billing/upsell/unlover');
    }

    coinsPayment(lastOfferId: number = null) {
        const nextPageQueryParam = lastOfferId ? '?lastOfferId=' + lastOfferId : '';
        return this.http.get(`${environment.backendUrl}/billing/${this.getAccount().getProfile().getId()}/coins${nextPageQueryParam}`);
    }

    preAuthPayment() {
        return this.http.get(`${environment.backendUrl}/billing/${this.getAccount().getProfile().getId()}/pre-auth`);
    }

    isHardBounced(): boolean {
        return this.getAccount().isHardbounced();
    }

    isCurrentUser(id: number): boolean {
        return id === this.getAccount().getProfile().getId();
    }

    refresh() {
        return new Promise((resolve) => {
            this.http.get(environment.backendUrl + '/profile/refresh').subscribe((response: any) => {
                if (response && response.success && response.data) {
                    if (!this.account.subscription) {
                        this.account.subscription = new Subscription();
                    }
                    this.account.subscription.deserialize(response.data.account.subscription);

                    if (!this.account.ruleSet || !response.data.account.ruleSet) {
                        this.account.ruleSet = new RuleSet();
                    }
                    if (response.data.account.ruleSet) {
                        this.account.ruleSet.deserialize(response.data.account.ruleSet);
                    }
                    this.account.coins = response.data.account.coins;
                    this.account.suspension = response.data.account.suspension || null;
                    this.account.profile.deserialize(response.data.profile);
                    this.account.certifications = response.data.account.certifications;
                }
                resolve();
            });
        });
    }

    requestSuspension(data) {
        this.resendData.suspension = data;
        return this.http.post(environment.backendUrl + `/profile/${this.account.profile.id}/request-suspension`, data);
    }

    requestDeletion(reason) {
        this.resendData.deletion = {reason};
        return this.http.post(environment.backendUrl + `/profile/${this.account.profile.id}/request-deletion`, {reason});
    }

    retryStatusRequest(type: string) {
        if (!this.resendData[type]) {
            this.router.navigate(['/account'], {queryParams: {tab: 'account'}});
            return false;
        }
        return this.http.post(environment.backendUrl + `/profile/${this.account.profile.id}/request-${type}`, this.resendData[type]);
    }

    suspensionConfirmed(token) {
        return this.http.put(environment.backendUrl + `/profile/${this.account.profile.id}/suspend/${token}`, {});
    }

    deletionConfirmed(token) {
        return this.http.delete(environment.backendUrl + `/profile/${this.account.profile.id}/delete/${token}`);
    }

    deleteStatusRequest(token: string) {
        return this.http.delete(environment.backendUrl + `/profile/${this.account.profile.id}/status-request/${token}`);
    }

    abortSuspension() {
        return this.http.put(environment.backendUrl + `/profile/${this.account.profile.id}/abort-suspension`, {});
    }

    suspensionModalData(path = null) {
        if (!this.getAccount().isSuspended() || this.allowedRoute.includes(path || this.router.url)) {
            return false;
        }

        return {
            title: this.translate.instant('account.my_account.suspend_account.modal_abort.title', {
                date: formatDate(new Date(this.getAccount().suspension.to), 'd/m/Y')
            }),
            strongQuestion: true,
            faClass: 'fas fa-hand-paper',
            faClassExtra: false,
            sampleText: 'account.my_account.suspend_account.modal_abort.description',
            question: 'account.my_account.suspend_account.modal_abort.question',
            responses: [
                {
                    text: 'account.my_account.suspend_account.modal_abort.answer',
                    className: 'btn-primary',
                    result: true
                },
                {
                    text: 'xponsor.no_thanks',
                    className: 'btn-light',
                    result: false
                }
            ],
            asyncValidationCallback: async (result) => {
                if (result) {
                    this.abortSuspension().subscribe(() => {
                        this.account.suspension = null;
                        this.router.navigate(['/home']);
                    });
                } else {
                    this.router.navigate(['/home']);
                }
                return true;
            }
        };
    }

    hasRule(rule: string, profileId: number = null): boolean {
        // Rules do not apply for the current user.
        if (rule !== 'chatBlur' && profileId !== null && profileId === this.getAccount().getProfile().getId()) {
            return false;
        }

        if (!this.getAccount().getRuleSet() || !this.getAccount().getRuleSet().getRules()) {
            return false;
        }

        return this.getAccount().getRuleSet().getRules()[rule];
    }

    useBanner(): boolean {
        if (this.account.activeSubscription() || !this.account.getRuleSet().getRules()) {
            return false;
        }

        if (!this.account.getRuleSet().getBanner()) {
            return false;
        }

        if (this.account.getRuleSet().isCoin()) {
            return false;
        }

        return this.account.getRuleSet().getRules().requireSubscription;
    }

    successToaster(text: string, profile: Profile = null) {
        this.toastr.success(this.getTranslationWithNickname(text, profile), null, {enableHtml: true});
    }

    setClosedAlert(alert, value) {
        this.account.getSettings().closedAlert[alert] = value;
        this.updateSettings();
    }

    initTags() {
        this.tags = {
            '[[username]]': this.account.getProfile().getNickname(),
            '[[age]]': this.account.getProfile().getAge(),
            '[[city]]': this.account.getProfile().getCity(),
            '[[email]]': this.account.getEmail(),
            '[[birthdate]]': this.account.getProfile().getBirthDate(),
            '[[profile_id]]': this.account.getProfile().getId()
        };
    }

    replaceTag(content): string {
        if (!Object.keys(this.tags).length) {
            this.initTags();
        }

        for (const key in this.tags) {
            if (this.tags.hasOwnProperty(key)) {
                const regex = new RegExp(this.escapeRegex(key), 'g');
                content = content.replace(regex, this.tags[key]);
            }
        }

        return content;
    }

    escapeRegex(content: string): string {
        return content.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    updateRuleSet(ruleSet: any) {
        const mustRefresh = !this.account.getRuleSet().hasSameRulesAs(ruleSet);

        this.account.setRuleSet(ruleSet);

        if (mustRefresh) {
            this.coinService.fetchOffers();
        }

        if (this.router.url.startsWith('/profile/') && mustRefresh) {
            if (this.account.getRuleSet().getRules() && this.account.getRuleSet().getRules().blockProfileAccess) {
                if (this.account.getRuleSet().isSubscription()) {
                    this.router.navigate(['/payment'], {queryParams: {action: 'profile_view'}});
                } else {
                    this.router.navigateByUrl('/home');
                }
            } else {
                this.refreshPage.profile = true;
            }
        }

        if ((this.router.url === '/' || this.router.url.startsWith('/home')) && mustRefresh) {
            this.refreshPage.home = true;
        }

        if (this.router.url.startsWith('/notification') && mustRefresh) {
            this.refreshPage.notification = true;
        }

        if (this.router.url.startsWith('/contact') && mustRefresh) {
            this.refreshPage.contact = true;
        }
    }

    resetSettingsAtLogin() {
        this.account.getSettings().loginReset();

        this.http.put(environment.backendUrl + '/profile/settings', {
            settings: this.account.getSettings().getLoginSettings()
        }).subscribe();
    }

    updateSettings() {
        this.http.put(environment.backendUrl + '/profile/settings', {
            settings: this.account.getSettings()
        }).subscribe();
    }

    private getTranslationWithNickname(text: string, profile: Profile = null): string {
        const translation = this.translate.instant(text);

        if (translation.includes('[nickname]')) {
            profile = profile || this.getAccount().getProfile();
            const translations = translation.split('[nickname]');
            const nickname = this.hasRule('nicknameBlur') ? `<span class="blurred">${profile.getNickname()}</span>` : profile.getNickname();
            return translations[0] + nickname + translations[1];
        }

        return translation;
    }

    private displayDefaultError() {
        this.toastr.error(this.translate.instant('error_list.default'));
    }

    private checkCode(code, type: string) {
        return this.http.put(`${this.profileEndpoint}/${type}/check-code`, {code});
    }
}
