<div class="answer wow fadeInDown right"
     [ngStyle]="!isMobile ? {'visibility': 'visible', 'animation-name': 'fadeInDown'} : {}">

    <div class="avatar">
        <app-user-photo [user]="getProfile()" [alt]="getProfile().getNickname()"
                        [ngStyle]="isMobile ? {'width': '50px'} : {}"></app-user-photo>

        <div class="status bg-success"></div>
    </div>
    <div class="name">
        <span>{{ getProfile().getNickname() }}</span>
        <small *ngIf="getProfile().getCity()" [hidden]="isMobile"
               class="text-muted pl-2 text-capitalize">
            {{ getProfile().getCity().toLowerCase() }}
        </small>
        <i class="fas pl-2" [ngClass]="{
            'fa-venus fem': getProfile().isFemale(),
            'fa-mars hom': getProfile().isMale(),
            'fa-venus-mars cpl': getProfile().isCouple()
        }"></i>
    </div>
    <div class="text ko-lc">
        {{ pendingMessage.getMessage() }}
        <div class="alert alert-light text-center mb-0 mt-2">
            <a (click)="delete()" class="delete-action text-muted"><i [class.fa-spinner]="deleting" [class.fa-spin]="deleting" [class.fa-trash-alt]="!deleting" class="fas"></i></a>

            <i class="fas fa-comment-dots"></i> {{ 'pending_message_will_be_sent' | translate }} 
            <b><u><a (click)="openLoveCoinModal()" class="text-muted cursor-pointer">{{ 'click_here' | translate }}</a></u></b> {{ 'to_credit' | translate }}
        </div>
    </div>
    <div class="time" appElapsedTime [dateTime]="pendingMessage.getCreatedAt()"></div>
</div>
