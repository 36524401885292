<div class="home ep1 mt-4">
    <div [class.d-none]="isWatchingCam()" class="d-lg-none row">
        <div class="col-12 text-center">
            <h4>{{ getTotal() }} {{ (getTotal() > 1 ? 'total_cam_models' : 'total_cam_model') | translate }}</h4>
        </div>
    </div>

    <ul [class.d-none]="isWatchingCam()" class="nav nav-tabs mb-2 onglets-profils d-lg-flex">
        <li *ngIf="isWatchingCam()" class="nav-item d-none d-xl-block">
            <a class="nav-link active">{{ getCurrentCamModel().getNickname() }}</a>
        </li>

        <li class="nav-item d-none d-xl-block">
            <a [routerLink]="['/sex-cams']" [class.active]="isTab()" class="nav-link">
                {{ 'ebooks.categories.all_mobile' | translate }}
                <span [class.badge-primary]="isTab() || isWatchingCam()" class="badge badge-secondary ml-2">{{ getTotal() | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(FEMALE)" class="d-none d-xl-block nav-item">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: FEMALE}" [class.active]="isTab(FEMALE)" class="nav-link">
                <i class="fas fa-venus female-color"></i> {{ 'women' | translate }}
                <span [class.badge-primary]="isTab(FEMALE)" class="badge badge-secondary ml-2">{{ total.female | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(COUPLE)" class="d-none d-xl-block nav-item">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: COUPLE}" [class.active]="isTab(COUPLE)" class="nav-link">
                <i class="fas fa-venus couple-color"></i><i class="fas fa-mars couple-color"></i> {{ 'couples' | translate }}
                <span [class.badge-primary]="isTab(COUPLE)" class="badge badge-secondary ml-2">{{ total.couple | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(MALE)" class="d-none d-xl-block nav-item">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: MALE}" [class.active]="isTab(MALE)" class="nav-link">
                <i class="fas fa-mars male-color"></i> {{ 'men' | translate }}
                <span [class.badge-primary]="isTab(MALE)" class="badge badge-secondary ml-2">{{ total.male | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(TRANSGENDER)" class="d-none d-xl-block nav-item">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: TRANSGENDER}" [class.active]="isTab(TRANSGENDER)" class="nav-link">
                <i class="fas fa-transgender-alt transgender-color"></i> {{ 'transgender' | translate }}
                <span [class.badge-primary]="isTab(TRANSGENDER)" class="badge badge-secondary ml-2">{{ total.trans | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="d-none d-xl-block nav-item">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: FAVORITES}" [class.active]="isTab(FAVORITES)" class="nav-link">
                <i class="fas fa-heart text-warning"></i> {{ 'contact.favorites' | translate }}
                <span [class.badge-primary]="isTab(FAVORITES)" class="badge badge-secondary ml-2">{{ total.onlineFavorites | number:'1.0-0':'fr' }}/{{ total.favorites | number:'1.0-0':'fr' }}</span>
            </a>
        </li>

        <li class="d-block d-xl-none li-w-50 mt-55px nav-item px-1">
            <a [routerLink]="['/sex-cams']" [class.off]="!isTab()" class="btn btn-light btn-sm btn-block w-100">
                {{ 'ebooks.categories.all_mobile' | translate }}
                <span [class.badge-primary]="isTab() || isWatchingCam()" class="badge badge-secondary ml-2">{{ getTotal() | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li class="d-block d-xl-none li-w-50 mt-55px nav-item px-1">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: FAVORITES}" [class.off]="!isTab(FAVORITES)" class="btn btn-light btn-sm d-inline-block w-100">
                <i class="fas fa-heart text-warning"></i> {{ 'contact.favorites' | translate }}
                <span [class.badge-primary]="isTab(FAVORITES)" class="badge badge-secondary ml-2">
                    {{ total.onlineFavorites | number:'1.0-0':'fr' }}/{{ total.favorites | number:'1.0-0':'fr' }}
                </span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(FEMALE)" class="d-block d-xl-none li-w-50 nav-item px-1">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: FEMALE}" [class.off]="!isTab(FEMALE)" class="btn btn-light btn-sm d-inline-block w-100">
                <i class="fas fa-venus female-color"></i> {{ 'women' | translate }}
                <span [class.badge-primary]="isTab(FEMALE)" class="badge badge-secondary ml-2">{{ total.female | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(COUPLE)" class="d-block d-xl-none li-w-50 nav-item px-1">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: COUPLE}" [class.off]="!isTab(COUPLE)" class="btn btn-light btn-sm d-inline-block w-100">
                <i class="fas fa-venus couple-color"></i><i class="fas fa-mars couple-color"></i> {{ 'couples' | translate }}
                <span [class.badge-primary]="isTab(COUPLE)" class="badge badge-secondary ml-2">{{ total.couple | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(MALE)" class="d-block d-xl-none li-w-50 nav-item px-1">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: MALE}" [class.off]="!isTab(MALE)" class="btn btn-light btn-sm d-inline-block w-100">
                <i class="fas fa-mars male-color"></i> {{ 'men' | translate }}
                <span [class.badge-primary]="isTab(MALE)" class="badge badge-secondary ml-2">{{ total.male | number:'1.0-0':'fr' }}</span>
            </a>
        </li>
        <li *ngIf="isGenderAllowed(TRANSGENDER)" class="d-block d-xl-none li-w-50 nav-item px-1">
            <a [routerLink]="['/sex-cams']" [queryParams]="{tab: TRANSGENDER}" [class.off]="!isTab(TRANSGENDER)" class="btn btn-light btn-sm d-inline-block w-100">
                <i class="fas fa-transgender-alt transgender-color"></i> {{ 'transgender' | translate }}
                <span [class.badge-primary]="isTab(TRANSGENDER)" class="badge badge-secondary ml-2">{{ total.trans | number:'1.0-0':'fr' }}</span>
            </a>
        </li>

        <li class="nav-item ml-auto">
            <a (click)="toggleFilters()" class="btn btn-primary btn-sm filter-btn" data-toggle="collapse">
                <i class="fas fa-search"></i> {{ 'advanced_options' | translate }}
            </a>
        </li>
    </ul>

    <router-outlet></router-outlet>

    <div *ngIf="!isWatchingCam() && !isTab(FAVORITES)" class="tags d-none d-lg-flex">
        <a (click)="getFilters().setPrivateShow();setTab(tab)"
           [class.active]="getFilters().isPrivateShow()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'peeping_mode' | translate }}</a>

        <a (click)="getFilters().setVIPShow();setTab(tab)"
           [class.active]="getFilters().isVIPShow()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'vip_show' | translate }}</a>

        <a (click)="getFilters().setBlondeHair();setTab(tab)"
           [class.active]="getFilters().hasBlondeHair()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'profile_answers.blonde_hair' | translate }}</a>

        <a (click)="getFilters().setBrownHair();setTab(tab)"
           [class.active]="getFilters().hasBrownHair()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'profile_answers.brown_hair' | translate }}</a>

        <a (click)="getFilters().setRedHair();setTab(tab)"
           [class.active]="getFilters().hasRedHair()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'profile_answers.red_hair' | translate }}</a>

        <a (click)="getFilters().setSmallTit();setTab(tab)"
           [class.active]="getFilters().hasSmallTit()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'small_tits' | translate }}</a>

        <a (click)="getFilters().setBigTit();setTab(tab)"
           [class.active]="getFilters().hasBigTit()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'big_tits' | translate }}</a>

        <a (click)="getFilters().setSlimBody();setTab(tab)"
           [class.active]="getFilters().hasSlimBody()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'profile_answers.slender_body' | translate }}</a>

        <a (click)="getFilters().setPlumpBody();setTab(tab)"
           [class.active]="getFilters().hasPlumpBody()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#{{ 'profile_answers.curvy_body' | translate }}</a>

        <a (click)="getFilters().setYoung();setTab(tab)"
           [class.active]="getFilters().isYoung()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#18-25 {{ 'years_old' | translate }}</a>

        <a (click)="getFilters().setOld();setTab(tab)"
           [class.active]="getFilters().isOld()"
           class="btn hashtag btn-outline-secondary rounded-pill mr-1 px-4 mb-2 mb-lg-0">#+ 35 {{ 'years_old' | translate }}</a>
    </div>

    <div *ngIf="getCamModels() !== null" [class.d-none]="isWatchingCam()" class="row row-profils mt-2 d-lg-flex">

        <div *ngIf="isWatchingCam()" class="col-12">
            <h2 class="h5 font-weight-bold mt-5">{{ 'you_will_also_like' | translate }}</h2>
        </div>

        <div *ngIf="!isLoading() && getCamModels().length === 0" class="col-12 text-center">
            <h1 class="text-uppercase">
                <span [innerHTML]="'search_empty_result.damn' | translate: getSmiley()"></span> 
                <br class="d-block d-sm-none">
                {{ 'search_empty_result.no_result' | translate }}
            </h1>
            <p>
                {{ 'search_empty_result.no_match' | translate }}
                <br class="br-mobile">
                {{ 'search_empty_result.change_filter' | translate }}
            </p>
        </div>

        <ng-container *ngFor="let camModel of getCamModels()">
            <div *ngIf="canDisplayModel(camModel)"
                 class="col-xl-2 col-md-3 col-sm-4 col-6 lesprofils">
                <app-thumb-cam-model [camModel]="camModel" (removeEvent)="onRemoveModel($event)"></app-thumb-cam-model>
            </div>
        </ng-container>

        <app-infinite-scroll
            *ngIf="!hideLoader()"
            class="container-fluid mt-4"
            [currentPage]="getCurrentPage()"
            [maxPage]="getMaxPage()"
            [loading]="isLoading() && !hideLoader()"
            (pageChange)="loadMore($event)"
        ></app-infinite-scroll>
    </div>
</div>
