<div id="footer">
    <div>
        <div style="text-align: center;">
            <a [routerLink]="['/terms']">{{ 'terms_and_conditions' | translate }}</a> |
            <a [routerLink]="['/privacy']">{{ 'privacy_policy' | translate }}</a> |
            <a [routerLink]="['/about']">{{ 'legal_notice' | translate }}</a> |
            <a [routerLink]="['/cookies']">{{ 'cookies' | translate }}</a> |
            <a [routerLink]="['/faq']">{{ 'customer_service' | translate }}</a><br>
            <a [routerLink]="['/ccl']">{{ 'community_guidelines' | translate }}</a> |
            <a [routerLink]="['/cdc']">{{ 'safety_charter' | translate }}</a> |
            <a [routerLink]="['/tips']">{{ 'tips' | translate }}</a> |
            <a [routerLink]="['/faq']">{{ 'get_help' | translate }}</a> |
            <a href="https://www.realizeyourdreams.com" target="_blank">{{ 'affiliation' | translate }}</a>
        </div>
    </div>

    <br/>

    <div class="text-center">
        &copy; {{ currentYear }} - {{ site.getName() }}<br/>
        {{ 'footer.all_rights_reserved' | translate }}<br/>

        <div class="mt-3" *ngIf="site.getCompany()">
            {{ site.getCompany().getName() }}<br/>
            {{ getAddress() }}
            <br/>
            <span *ngIf="site.getCompany().getRegistrationNumber()">
                {{ site.getCompany().getRegistrationNumber() }}<br/>
            </span>
        </div>
    </div>

    <app-get-subscription *ngIf="showSubscriptionBanner()" (onCloseGetSubscription)="onCloseGetSubscription($event)"></app-get-subscription>
</div>
