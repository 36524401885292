import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Profile} from '../profile/profile';

@Injectable({
    providedIn: 'root'
})
export class PendingAction implements Deserializable {

    static readonly MESSAGE_TYPE = 'message';
    static readonly KISS_TYPE = 'kiss';

    id: number;
    type: string;
    toProfile: Profile;
    data: any = null;
    createdAt: Date;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (!(input.toProfile instanceof Profile)) {
            this.toProfile = new Profile().deserialize(input.toProfile);
        }

        if (input.createdAt) {
            this.createdAt = new Date(input.createdAt);
        }

        return this;
    }

    isKissAction(): boolean {
        return this.type === PendingAction.KISS_TYPE;
    }

    isMessageAction(): boolean {
        return this.type === PendingAction.MESSAGE_TYPE;
    }

    getId(): number {
        return this.id;
    }

    getMessage(): string {
        return this.isMessageAction() ? this.data.message : 'Kiss';
    }

    getChatUserId(): number {
        return this.data.chatUserId;
    }

    getToProfile(): Profile {
        return this.toProfile;
    }

    getCreatedAt(): Date {
        return this.createdAt;
    }
}
