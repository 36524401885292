import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {Question} from '../../../models/site/question';
import {HelperService} from '../../../services/helper/helper.service';

@Component({
    selector: 'app-choice-list',
    templateUrl: './choice-list.component.html'
})
export class ChoiceListComponent implements OnInit {

    @Input() question: Question;
    @Input() profile: Profile;

    answer: string = null;

    constructor(private helper: HelperService) {}

    ngOnInit() {
        this.answer = this.profile.getAnswers()[this.question.getId()] || null;
    }

    update() {
        /*
         * Set the value to null if not present in the list to prevent unexpected behaviour
         * and for selecting the "no choice data" in selector
         */
        if (this.question.hasAnswer(this.answer)) {
            this.profile.setAnswer(this.question.getId(), this.answer);
        } else {
            this.profile.setAnswer(this.question.getId(), null)
        }
    }

    translateGender(translationIndex: string): string {
        return this.helper.translateGender(this.profile, translationIndex, this.question.reverseGender());
    }
}
