import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class CoinPaymentTransactionNotificationType extends AbstractNotificationType {

    static readonly TYPE = 47;

    protected buttons:NotificationButton[] = [
        new NotificationButton('view', () => {
            this.goTo('account', null, 'purchase');
        })
    ];

    getIcon(): string {
        return 'fa-shopping-cart';
    }

    getMetaData() {
        return {
            amountCoin: this.getAmountCoin()
        };
    }

    private getAmountCoin() {
        return (this.notification && this.notification.getMetaData() && this.notification.getMetaData().amountCoin) ?
            this.notification.getMetaData().amountCoin : '';
    }
}
