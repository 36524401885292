import {Component, OnInit} from '@angular/core';
import {Profile} from '../../models/profile/profile';
import {ProfileService} from '../../services/profile/profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrentUserService} from '../../services/user/current-user.service';
import {FiltersService} from '../../services/filters/filters.service';
import {SiteService} from '../../services/site/site.service';
import {City} from '../../models/city/city';
import {ModalService} from '../../services/modal/modal.service';
import {SearchFilters} from '../../models/account/settings/search-filters';
import {ChatUserService} from '../../services/chat/chat-user/chat-user.service';
import {CoinWarningComponent} from '../modal/coin-warning/coin-warning.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    static tab = 'all';
    static firstRequest = true;
    hardSubmit: boolean;
    requestBoostedProfile = undefined;
    requestProfile = undefined;
    currentPage = 1;

    private readonly count = 30;

    canSubmit = true;

    profiles: Profile[] = null;
    boosterProfiles: Profile[] = null;
    loading: boolean;
    profileCount = {
        total: null,
        new: null,
        online: null
    };

    private refreshTimer = null;
    private refreshTimeout = 3000;
    private lastRefreshTime = null;

    private static numberGenerator(min: number, max: number, step = 1): number[] {
        const numberArray = [];

        for (let i = min; i <= max; i += step) {
            numberArray.push(i);
        }

        return numberArray;
    }

    constructor(
        protected currentUser: CurrentUserService,
        protected filtersService: FiltersService,
        protected profileService: ProfileService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected siteService: SiteService,
        protected modalService: ModalService,
        protected dialog: MatDialog,
        protected chatUserService: ChatUserService
    ) {
        // profile loading on search page
        this.filtersService.getSearchProfilesSubscriber().subscribe(() => {
            this.cleanAndSearchProfiles();
        });

        this.chatUserService.usersChanged.subscribe(users => {
            if (this.refreshTimer) {
                return;
            }

            let timeout = 3000;
            const minDelay = 60000;
            let refreshDelay = 0;

            if (this.lastRefreshTime) {
                const lastRefreshDelay = Date.now() - this.lastRefreshTime;
                if (lastRefreshDelay < minDelay) {
                    refreshDelay += minDelay - lastRefreshDelay;
                }
            }

            timeout += refreshDelay;

            this.refreshTimer = setTimeout(() => {
                this.refreshTimer = null;
                this.lastRefreshTime = Date.now();
                this.refreshProfiles();
            }, timeout);
        });
    }

    ngOnInit() {
        if (!this.isTab('result')) {
            // Using default param from current profile as filter.
            const idealAge = this.siteService.getQuestionsAnswers().getAnswer(this.currentUser.getAccount().getProfile(), 'ideal_age');
            const filters = this.filtersService.getSearchFilters();

            if (idealAge && filters.getAgeMin() !== 18 && filters.getAgeMax() !== 100) {
                this.filtersService.getSearchFilters().age = idealAge;
            }
            this.setTab('all');
        }
        this.filtersService.currentHardSubmit.subscribe(hardSubmit => this.hardSubmit = hardSubmit);

        this.loadInfiniteProfiles(1);
    }

    getAgeOptions(): number[] {
        return SearchComponent.numberGenerator(18, 100);
    }

    getDistanceOptions(): number[] {
        return SearchComponent.numberGenerator(10, 80, 10);
    }

    getFilters(): SearchFilters {
        return this.filtersService.getSearchFilters();
    }

    refreshProfiles() {
        this.loadProfiles({
            page: 1,
            count: this.count * this.currentPage,
            hideFilters: false,
            scrollTop: false,
            clear: true
        });
    }

    /**
     * Clean profiles list before to search.
     */
    cleanAndSearchProfiles() {
        this.profiles = null;
        this.boosterProfiles = null;
        this.loadInfiniteProfiles(1);
    }

    /**
     * Load profiles using infinite scroll
     * @param page
     */
    loadInfiniteProfiles(page) {
        this.loadProfiles({
            page,
            hideFilters: false,
            scrollTop: false,
            clear: false
        });
    }

    /**
     * Check permissions to load profiles.
     * @param options
     */
    canLoadProfiles(options: any) {
        /*if (this.currentUser.isHardBounced()) {
            // the user email is hardbounced, we can't load the profiles
            console.log('Email hardbounced - cannot load wall profiles');
            return false;
        }*/

        if (typeof options.page === 'undefined' || isNaN(options.page)) {
            console.log('Page not defined');
            return false;
        }

        // prevent double request when loading for the first time
        if (this.loading && SearchComponent.firstRequest) {
            SearchComponent.firstRequest = false;
            return false;
        }

        return true;
    }

    loadProfiles(options: any) {
        const tab = SearchComponent.tab;
        let count = this.count;

        if (!this.canLoadProfiles(options)) {
            return;
        }

        if (options.hideFilters) {
            this.filtersService.hide();
        }

        if (options.scrollTop) {
            window.scroll(0, 0);
        }

        if (options.count) {
            count = options.count;
        }

        this.filtersService.setCurrentPage(options.page);
        this.loading = true;

        if (options.page === 1) {
            this.loadBoostedProfiles(this.getParams());
        }

        if (this.requestProfile !== undefined) {
            this.requestProfile.unsubscribe();
        }

        this.currentPage = options.page;

        this.requestProfile = this.profileService.findAll(this.currentPage, count, this.getParams()).subscribe((result: any) => {
            this.loading = false;
            this.profileCount.total = result.total;
            this.profileCount.online = result.online;
            this.profileCount.new = result.new;

            if (tab !== SearchComponent.tab) {
                return;
            }

            if (options.clear || this.profiles === null) {
                this.profiles = [];
            }

            for (const data of result.data) {
                if (!this.profiles.find(d => d.id === data.id || (d.webcamId && d.webcamId === data.webcamId))) {
                    const profile = new Profile();
                    profile.deserialize(data);
                    this.profiles.push(profile);
                }
            }

            if (options.scrollTop) {
                // After profile loading: scrollTop (if the user ever re-scroll before we get the results)
                window.scroll(0, 0);
            }

            this.loadPreviousPageProfiles();
        });

    }

    loadBoostedProfiles(params: {}) {
        if (this.requestBoostedProfile !== undefined) {
            this.requestBoostedProfile.unsubscribe();
        }

        params['count'] = 6;
        params['page'] = 1;
        params['sort'] = 'rand';

        this.requestBoostedProfile = this.profileService.findBoosted(params).subscribe((result: any) => {
            this.boosterProfiles = [];
            for (const data of result.data) {
                this.boosterProfiles.push(new Profile().deserialize(data));
            }
        });
    }

    /**
     * If there is no profile after changing page, we try to load the previous page
     * this is done as UX in order to prevent a "No result" message along with a positive counter next to "Online members"
     * e.g. the wall is loaded and we have 33 online profiles, since we have 30 profiles per page, there is two pages available
     * after waiting for a while, you click on page 2, but we now only have 29 online profiles, so page 2 is empty
     */
    loadPreviousPageProfiles() {
        if (this.profiles && this.profiles.length === 0 && SearchComponent.tab === 'online' && this.profileCount.online > 0) {
            this.loadProfiles({
                page: Math.floor(this.profileCount.online / 30) + 1,
                scrollTop: true,
                clear: true
            });
        }
    }

    getMaxPage() {
        return Math.floor(this.profileCount.total / this.count) + 1;
    }

    isTab(tab) {
        return SearchComponent.tab === tab;
    }

    setTab(tab) {
        if (SearchComponent.tab !== tab) {
            SearchComponent.tab = tab;
            if (this.isActivityTab()) {
                this.cleanAndSearchProfiles();
            }
        }

        if (['new', 'online'].includes(tab)) {
            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams: {tab},
                    queryParamsHandling: 'merge'
                }
            );
        }
    }

    getCurrentPage() {
        return this.filtersService.currentPage;
    }

    isActivityTab() {
        return ['online', 'new'].includes(SearchComponent.tab);
    }

    isOnlineTab() {
        return SearchComponent.tab === 'online';
    }

    clearCity() {
        this.filtersService.cleanFilters();
        this.filtersService.changeHardSubmit(true);
        this.router.navigate(['/home']);
    }

    getCityName() {
        if (!this.router.url.startsWith('/search') || !this.filtersService.getSearchFilters().city) {
            return false;
        }
        return this.filtersService.getSearchFilters().city.getFullName();
    }

    isBoosted() {
        return this.currentUser.getAccount().getProfile().getBoosted();
    }

    getEndBooster() {
        return this.currentUser.getAccount().getProfile().getEndBooster();
    }

    protected getParams() {
        const params: any = {
            minAge: this.filtersService.getSearchFilters().getAgeMin(),
            maxAge: this.filtersService.getSearchFilters().getAgeMax()
        };

        if (this.filtersService.getSearchFilters().withPhoto) {
            params.withPhoto = 1;
        }

        if (this.filtersService.getSearchFilters().distance) {
            params.distance = this.filtersService.getSearchFilters().distance;
        }

        if (this.filtersService.getSearchFilters().city) {
            params.latitude = this.filtersService.getSearchFilters().city.latitude;
            params.longitude = this.filtersService.getSearchFilters().city.longitude;
        }

        // Do not show LiveParadise profile when we search by nickname
        if (!this.filtersService.getSearchFilters().nickname || this.filtersService.getSearchFilters().nickname.trim().length === 0) {
            params.liveParadiseCount = this.profileService.liveParadiseCount;
        } else {
            params.nickname = this.filtersService.getSearchFilters().nickname;
        }

        if (this.isActivityTab()) {
            params.activity = SearchComponent.tab;
        }

        return params;
    }

    getSmiley() {
        return {
            o: '<i class="far fa-sad-tear"></i>'
        };
    }

    openBooster() {
        if (!this.isBoosted()) {
            this.modalService.openBooster().afterClosed().subscribe(result => {
                if (result && result.offer) {
                    this.dialog.open(CoinWarningComponent, {data: {offer: result.offer}});
                }
            });
        }
    }

    isLoading(): boolean {
        return this.loading;
    }

    isMobile(): boolean {
        return window.innerWidth < 992;
    }

    onSelectCity(city: City) {
        this.filtersService.getSearchFilters().city = city;
    }

    onUnselectCity() {
        this.filtersService.getSearchFilters().distance = '';
        this.filtersService.getSearchFilters().city = null;
    }

    onCityErrorUnpicked(cityErrorUnpicked: boolean) {
        this.canSubmit = !cityErrorUnpicked;
    }

    submit() {
        if (!this.canSubmit)
            return;

        this.filtersService.submit();

        SearchComponent.tab = 'result';

        if (this.router.url.includes('/search')) {
            this.filtersService.searchProfiles();
        } else {
            this.router.navigate(['/search']);
        }
    }
}
