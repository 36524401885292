export function formatDate(date: Date, format: string = 'Y-m-d H:i:s', utc = false): string {
    const startWithZero = (datePart) => {
        const part: string = datePart.toString();
        return +part < 10 ? '0' + part : part;
    };
    const day = startWithZero(utc ? date.getUTCDate() : date.getDate());
    const month = startWithZero((utc ? date.getUTCMonth() : date.getMonth()) + 1);
    const year = utc ? date.getUTCFullYear() : date.getFullYear();

    switch (format) {
        case 'd/m/Y':
            return `${day}/${month}/${year}`;
        case 'Y-m-d':
            return `${year}-${month}-${day}`;
        case 'Y-m-d H:i:s':
        default:
            const hours = startWithZero(utc ? date.getUTCHours() : date.getHours());
            const minutes = startWithZero(utc ? date.getUTCMinutes() : date.getMinutes());
            const seconds = startWithZero(utc ? date.getUTCSeconds() : date.getSeconds());
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}

export function getTomorrowFrom(date: Date) {
    if (!date) {
        return new Date();
    }
    const tomorrow = new Date(date.toISOString());
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
}
