import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-unpaid-subscription',
    templateUrl: './unpaid-subscription.component.html',
    styleUrls: ['./unpaid-subscription.component.scss']
})
export class UnpaidSubscriptionComponent {

    @Output() onClose = new EventEmitter<boolean>();

    close() {
        this.onClose.emit(true);
    }
}
