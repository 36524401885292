<a (click)="favorite()" [matTooltip]="'contact.add_to_favorite' | translate" [class.btn-active]="profile.relationship.favorite" class="btn btn-light btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i class="fas fa-star" [class.text-warning]="profile.relationship.favorite"></i>
</a>
<a (click)="kiss()" [matTooltip]="(profile.relationship.kissed ? 'contact.kiss_already_sent' : 'contact.send_kiss') | translate" [class.active]="profile.relationship.kissed" class="btn btn-light btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i [class.text-muted]="profile.relationship.kissed" class="fas fa-kiss-wink-heart"></i>
</a>
<a *ngIf="!profile.relationship.addedToFriend()" (click)="friend()" [matTooltip]="'contact.add_friend' | translate" class="btn btn-light btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i class="fas fa-user-plus"></i>
</a>
<a *ngIf="profile.relationship.addedToFriend()" (click)="friend()" [matTooltip]="'contact.remove_from_friends' | translate" class="btn btn-light btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i class="fas fa-user-minus"></i>
</a>
<a (click)="report()" [matTooltip]="'contact.report_profile' | translate" class="btn btn-light text-danger btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i class="fas fa-flag"></i>
</a>
<a (click)="block()" [matTooltip]="(profile.relationship.blocked ? 'unblock' : 'block') | translate" [class.btn-active]="profile.relationship.blocked" class="btn btn-light text-danger btn-sm btn-fonction d-none d-xl-inline-block mr-1">
    <i class="fas fa-ban"></i>
</a>
<a [routerLink]="['../../chat', profile.getId()]" [matTooltip]="'contact.send_private_message' | translate" class="btn btn-primary btn-sm btn-message d-inline-block btn-compte btn-contact btn-tchat-desktop mr-1">
    <i class="fas fa-comment-alt"></i> {{ 'contact.send_private_message' | translate }}
</a>
<a [routerLink]="['../../chat', profile.getId()]" [matTooltip]="'contact.send_private_message' | translate" class="btn btn-primary btn-sm btn-message btn-compte btn-contact btn-tchat-mobile mr-1">
    <i class="fas fa-comment-alt"></i> {{ 'contact.send_private_message' | translate }}
</a>
<a *ngIf="!getAccount().activeSubscription() && getAccount().getRuleSet().isSubscription()"
   [routerLink]="['/payment']"
   [queryParams]="{action: 'profile_view'}"
   [matTooltip]="'profile.activate_access' | translate"
   class="btn btn-success btn-sm btn-access d-none d-md-inline-block">
    <i class="fas fa-crown"></i> {{ 'profile.activate_access' | translate }}
</a>
