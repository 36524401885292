import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Offer} from '../offer/offer';
import {CoinOffer} from "../offer/coin-offer";

@Injectable({
    providedIn: 'root'
})
export class Transaction implements Deserializable {

    id: number;
    date: any;
    title: string;
    offer: Offer;
    type: boolean;
    coins: number = 0;
    method: string = null;
    coinOffer: CoinOffer;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.date = new Date(this.date);

        if (input.offer) {
            this.offer = new Offer().deserialize(input.offer);
        }

        if (input.coinOffer) {
            this.coinOffer = new CoinOffer().deserialize(input.coinOffer);
        }

        if (!['cc', 'wire', 'check', 'coins'].includes(this.method)) {
            this.method = null;
        }
        return this;
    }

    getId(): number {
        return this.id;
    }

    getDate(): Date {
        return this.date || new Date();
    }

    getFormattedCoins(): string {
        const coins = this.isMessage() ? this.coins * this.getCoinOffer().getMessageCount() : this.coins;

        let value: any = parseFloat(coins + '');

        value = value.toFixed(2);

        const sign = coins >= 0 ? '+' : '-';

        const x = value.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1\'$2');
        }

        return sign + ' ' + (x1 + x2 +  ' LC').replace('-', '');
    }

    getTitle(): string {
        return this.title;
    }

    getCoinOffer(): CoinOffer {
        return this.coinOffer;
    }

    getCoins(): number {
        return this.coins;
    }

    getMethod(): string {
        return this.method;
    }

    getOffer(): Offer {
        return this.offer;
    }

    isBooster(): boolean {
        return this.isCoinOffer() && this.coinOffer.isBoosterType();
    }

    isMessage(): boolean {
        return this.isCoinOffer() && this.coinOffer.isMessageType();
    }

    isKiss(): boolean {
        return this.isCoinOffer() && this.coinOffer.isKissType();
    }

    isLive(): boolean {
        return this.isCoinOffer() && this.coinOffer.isLiveType();
    }

    isCoinOffer(): boolean {
        return this.coinOffer instanceof CoinOffer;
    }
}
