<div class="col-12 text-center">
    <h1 class="mb-3 mt-4 pr-0">
        <i class="fas fa-info-circle text-primary"></i> {{ 'support.title' | translate }}
    </h1>
</div>
<div class="col-12 text-left">
    <p>{{ getSupportDescription() }}</p>
    <app-support-mail-component></app-support-mail-component>
</div>
<div class="col-12">
    <div class="row h-100">
        <div class="col-lg-6 mb-4">
            <app-support-chat-component></app-support-chat-component>
        </div>
        <div class="col-lg-6 mb-4">
            <app-support-phone-component></app-support-phone-component>
        </div>
    </div>
</div>











<!--<div class="row main profile">-->
<!--    <div class="col-12 text-center mb-4">-->
<!--        <ul class="nav nav-tabs onglets-profils mt-4">-->
<!--            <li class="nav-item">-->
<!--                <a class="nav-link active" href="service.html">Service client</a>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </div>-->
<!--    <div class="col-lg-4 order-2 order-lg-1">-->
<!--        <app-support-phone-component></app-support-phone-component>-->
<!--    </div>-->
<!--    <div class="col-lg-8 order-1 order-lg-2">-->
<!--        <p>Le service client est à votre disposition de 9h00 à 18h00 sans interruption, du lundi au samedi. En dehors des heures d'ouverture, vous pouvez nous envoyer un message. Le service client prendra alors contact avec vous dès sa prochaine ouverture.</p>-->

<!--        <app-support-mail-component></app-support-mail-component>-->

<!--        <app-support-chat-component></app-support-chat-component>-->
<!--    </div>-->
<!--</div>-->

