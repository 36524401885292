import {Component, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {SexCamsService} from '../../services/sex-cams/sex-cams.service';

@Component({
    selector: 'app-header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {
    constructor(
        public router: Router,
        private sexCamsService: SexCamsService,
        injector: Injector
    ) {
        super(injector);
    }

    hasCurrentCamModel(): boolean {
        return !!this.sexCamsService.currentCamModel;
    }

    isChatRoute(): boolean {
        return this.router.url.startsWith('/chat');
    }

    isUpsellRoute(): boolean {
        return this.router.url.startsWith('/upsell');
    }

    canShowAlert(): boolean {
        return !this.isUpsellRoute();
    }
}
