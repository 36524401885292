import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from '../loader/loader.service';
import {CurrentUserService} from '../user/current-user.service';
import {SiteService} from '../site/site.service';
import {SocketService} from '../socket/socket.service';
import {ChatService} from '../chat/chat.service';
import {Observable} from 'rxjs';
import {SupportService} from '../support/support.service';
import {LoginService} from '../login/login.service';
import {ChatUserMessageService} from '../chat/chat-user-message/chat-user-message.service';
import {UserMessage} from '../../models/chat/user-message/user-message';
import {TranslateService} from '@ngx-translate/core';
import {CountryService} from '../country/country.service';
import {Meta, Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {FiltersService} from '../filters/filters.service';
import {WINDOW} from '../../app.config';
import {ModalService} from '../modal/modal.service';
import {Profile} from '../../models/profile/profile';
import {Album} from '../../models/profile/album';
import {AlbumService} from '../profile/album/album.service';
import {PhotoService} from '../profile/photo/photo.service';
import {ChatAutoReconnectService} from '../chat/chat-auto-reconnect/chat-auto-reconnect.service';
import {User} from '../../models/chat/user/user';
import {SiteContentService} from '../site/content/site-content.service';
import {CoinService} from '../coin/coin.service';
import {SexCamsService} from '../sex-cams/sex-cams.service';
import {PendingActionResolverService} from '../pending-action-resolver/pending-action-resolver.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {

    private static trackerLoaded;

    private loaded = false;

    private renderer: Renderer2;

    constructor(
        private http: HttpClient,
        private currentUserService: CurrentUserService,
        private siteService: SiteService,
        private loaderService: LoaderService,
        private socketService: SocketService,
        private chatUserMessageService: ChatUserMessageService,
        private chatService: ChatService,
        private loginService: LoginService,
        private supportService: SupportService,
        private translate: TranslateService,
        private countryService: CountryService,
        private title: Title,
        private meta: Meta,
        rendererFactory: RendererFactory2,
        private router: Router,
        private filtersService: FiltersService,
        private modalService: ModalService,
        private albumService: AlbumService,
        private photoService: PhotoService,
        private chatAutoReconnectService: ChatAutoReconnectService,
        private siteContentService: SiteContentService,
        private coinService: CoinService,
        private sexCamsService: SexCamsService,
        private pendingActionResolver: PendingActionResolverService,
        @Inject(WINDOW) private window: Window
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.onChangeRoute();
    }

    load(): Observable<boolean> {
        this.loaded = false;
        this.loaderService.setLoading(true);
        this.loaderService.setMaxOpacity(true);

        return new Observable<boolean>(observer => {
            this.http.get(
                environment.backendUrl + '/profile/id'
            ).subscribe((res: any) => {
                this.currentUserService.profileId = res.data.id;
                this.http.get(environment.backendUrl + '/profile/' + res.data.id)
                    .subscribe((results: any) => {
                        if (!results.success) {
                            observer.next(false);
                        }

                        this.siteService.setSite(results.data.site);
                        this.translate.use(this.siteService.getSite().getLocale());

                        this.loadAllCountries(this.siteService.getSite().getLocale()).subscribe(() => {
                            this.loadEuropeanCountries(results.data.europeanCountry);
                        });

                        this.siteService.setQuestionsAnswers(results.data.questionsAnswers);

                        this.sexCamsService.init();

                        /** Set the meta title */
                        this.title.setTitle(this.siteService.getSite().getName());

                        /** Set the favicon */
                        if (this.siteService.getSite().getFaviconUrl()) {
                            const favicon: HTMLLinkElement = document.querySelector('#favicon');
                            favicon.href = this.siteService.getSite().getFaviconUrl();
                        }

                        if (results.data.session) {
                            results.data.account.session = results.data.session;
                        }

                        this.currentUserService.setAccount(results.data.account, results.data.profile);
                        this.currentUserService.getAccount().getProfile().setFieldsStatus(results.data.fieldsStatus);
                        this.currentUserService.setGa(this.loginService.getGa());
                        this.filtersService.initCity();

                        this.coinService.fetchOffers();

                        this.loadSocket();

                        this.loadSupport();

                        this.loaderService.setLoaded('init');
                        this.loaded = true;

                        this.loadAlbums();
                        this.loadTracker(results.data.account.id);

                        this.currentUserService.setAccountLoaded(true);

                        observer.next(true);
                    }, (error: any) => {
                        console.log(error);
                        this.chatUserMessageService.showUserBannedModal();
                    });
            }, (error: any) => {
                // We are connected but /profile/id return false to redirect to login page (or login url)
                observer.next(false);
            });
        });
    }

    loadSocket() {
        if (this.currentUserService.getAccount().isSuspended()) {
            return;
        }

        this.chatAutoReconnectService.setAutoReconnect(true);

        this.socketService.connectToServer();

        this.socketService.on('connect', () => {
            this.chatService.reset();

            this.socketService
                .loginUser(this.currentUserService.getAccount(), this.loginService.getToken())
                .then((response) => {
                    const user = new User().deserialize(response.user);
                    user.setMe(true);

                    this.chatService.setMe(user);
                    this.chatService.onConnect();

                    // this.pendingActionResolver.resolve();
                })
                .catch(error => {
                    const userMessageData = {
                        type: error.errorMessage,
                        dateEnd: error.endDate,
                        context: UserMessage.CONTEXT_ALL
                    };

                    const userMessage = new UserMessage().deserialize(userMessageData);
                    this.chatService.onConnectionFailed();
                    this.chatService.addUserMessage(userMessage);
                    this.chatUserMessageService.showMessage(userMessage, UserMessage.CONTEXT_ALL)
                        .then()
                        .catch(() => {
                        });
                });
        });
    }

    loadSupport() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=8f48469c-04ef-46b6-8799-577a6e3fe06e';
        script.async = true;
        script.defer = true;
        script.id = 'ze-snippet';
        this.renderer.appendChild(document.body, script);

        script.onload = () => {
            this.supportService.initChat();
        };
    }

    loadAllCountries(locale: string) {
        return new Observable<boolean>(observer => {
            this.http.get(environment.backendUrl + '/static/country?language=' + locale)
                .subscribe((results: any) => {
                    if (results.success) {
                        this.countryService.setDialingCodes(results.data);
                        this.siteContentService.initTags();
                    }

                    observer.next(true);
                });
        });
    }

    loadEuropeanCountries(europeanCountryCodes: any[]) {
        this.countryService.setEuropeanCountries(europeanCountryCodes);
    }

    loadAlbums() {
        this.albumService.findAll(this.currentUserService.getAccount().getProfile(), true).subscribe((profile: Profile) => {
            const albums: {} = profile.getAlbums();

            for (const idAlbum in albums) {
                if (albums[idAlbum]) {
                    this.photoService.findByAlbum(albums[idAlbum]).subscribe((album: Album) => {
                        albums[idAlbum] = album;
                    });
                }
            }
        });
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    onChangeRoute() {
        this.router.events.subscribe((event: NavigationEnd) => {
            // Scroller vers le haut de page :
            window.scroll(0, 0);

            // fermer la recherche avancée
            this.filtersService.hide();
        });
    }

    loadTracker(accountId) {
        if (!environment.trackerUrl && !InitService.trackerLoaded) {
            return;
        }
        InitService.trackerLoaded = true;

        const build = require('../../../build.json');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = environment.trackerUrl + '/loader.js?v=' + build.version;

        // IE supports readyState
        if (script['readyState']) {
            script['onreadystatechange'] = () => {
                if (script['readyState'] === 'loaded' || script['readyState'] === 'complete') {
                    script['onreadystatechange'] = null;
                    this.resolveTracker(accountId)
                }
            };
        } else {
            script.onload = () => this.resolveTracker(accountId);
        }

        document.getElementsByTagName('head')[0].appendChild(script);
    }

    resolveTracker(accountId) {
        if (this.window['tracker']) {
            this.window['tracker'].login(accountId).catch(response => {
                console.log('No response, something went wrong', response);
            });
        } else {
            setTimeout(() => this.resolveTracker(accountId), 500);
        }
    }
}
