import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActionService} from '../../../services/profile/action/action.service';
import {Profile} from '../../../models/profile/profile';
import {CurrentUserService} from '../../../services/user/current-user.service';

@Component({
    selector: 'app-report-profile',
    templateUrl: './report-profile.component.html',
    styleUrls: ['./report-profile.component.scss']
})
export class ReportProfileComponent {

    step = 1;
    profile: Profile;
    readonly reasons = [
        1, 2, 3, 4, 5, 6
    ];
    reasonId: number;
    description: string;
    error: string;

    constructor(
        public dialogRef: MatDialogRef<ReportProfileComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private action: ActionService,
        private currentUserService: CurrentUserService
    ) {
        this.profile = data.profile;
    }

    addReport() {
        if (!this.reasons.includes(this.reasonId)) {
            return false;
        }
        this.action.addReport(this.profile.getId(), this.reasonId, this.description).subscribe((result: any) => {
            this.step = 2;
        }, () => {
            this.dialogRef.close();
        });
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule, this.profile.getId());
    }
}
