<div class="card border-0 box mb-4">
    <div class="card-header">
        <h2 class="d-none d-lg-block pr-0 text-left">
            <i class="fa fa-envelope mr-2 text-primary"></i>{{ 'support_mail.send_us_message' | translate }}
        </h2>
        <h2 class="d-block d-lg-none pr-0 text-left">
            <i class="fas fa-user-edit mr-2 text-primary"></i>{{ 'support_mail.send_us_message' | translate }}
        </h2>
    </div>
    <div class="card-body">
        <small *ngIf="success == false" class="alert alert-danger my-2">{{ 'error_list.technical_issue' | translate }}</small>
        <small *ngIf="success == true" class="alert alert-success my-2">{{ 'support_mail.send_message_success' | translate }}</small>
        <form name="contact" id="contactForm">
            <div class="form-group">
                <select [(ngModel)]="subject" name="subject" class="form-control custom-select">
                    <option value="" disabled="" selected="selected">{{ 'support_mail.subject.default' | translate }}</option>
                    <option value="useService">{{ 'support_mail.subject.service' | translate }}</option>
                    <option value="troubleMember">{{ 'support_mail.subject.member' | translate }}</option>
                    <option value="technicalProblem">{{ 'support_mail.subject.technical' | translate }}</option>
                    <option value="businessProblem">{{ 'support_mail.subject.business' | translate }}</option>
                    <option value="subscriptionCancellation">{{ 'support_mail.subject.subscription' | translate }}</option>
                    <option value="accountDeletion">{{ 'support_mail.subject.deletion' | translate }}</option>
                    <option value="other">{{ 'support_mail.subject.other' | translate }}</option>
                </select>
            </div>
            <div class="form-group">
                <textarea [(ngModel)]="message" name="message" rows="5" class="form-control" [placeholder]="'support_mail.message_ph' | translate"></textarea>
            </div>
            <div class="w-100 text-right mt-2">
                <button (click)="sendMessageReportCustomerService()" [disabled]="formDisabled" type="submit" class="btn btn-sm btn-primary same-w">
                    <i *ngIf="formDisabled" class="fa fa-spinner fa-pulse fa-1x"></i>
                    {{ 'send' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
