import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Company} from '../company/company';
import {Ebook} from '../ebook/ebook';
import {Profile} from '../profile/profile';
import {formatNumber} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class Site implements Deserializable {

    static readonly LOVE = 1;
    static readonly CASUAL = 2;
    static readonly SEXY = 3;
    static readonly GAY = 4;
    static readonly FRIEND = 5;
    static readonly SUGAR = 6;

    static readonly LOVE_LABEL = 'love';
    static readonly CASUAL_LABEL = 'casual';
    static readonly SEXY_LABEL = 'sexy';
    static readonly GAY_LABEL = 'gay';
    static readonly FRIEND_LABEL = 'friend';
    static readonly SUGAR_LABEL = 'sugar';

    subsite: number;
    name: string;
    url: string;
    folder: string;
    logoUrl: string;
    logoWebUrl: string;
    faviconUrl: string;
    framework: string;
    ticketUrl: string;
    phoneSupport: any[] = [];
    termsUrl: string;
    privacyUrl: string;
    supportUrl: string;
    segmentId: number;
    isWebCam: boolean;
    mailTechnical: string;
    mailContact: string;
    footCompany: string;
    shopUrl: string;
    eBooks: Ebook[] = [];
    css: object;
    company: Company;
    locale: string;
    countries: string;
    groupId: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.locale && input.locale.length === 2) {
            this.locale = input.locale;
        } else {
            this.locale = 'en';
        }

        if (input.company) {
            this.company = new Company();
            this.company.deserialize(input.company);
        }

        if (input.eBooks) {
            this.eBooks = [];
            for (const data of input.eBooks) {
                const eBook = new Ebook();
                eBook.deserialize(data);
                this.eBooks.push(eBook);
            }
        }
        return this;
    }

    getSubsite(): number {
        return this.subsite;
    }

    getName(): string {
        return this.name;
    }

    getUrl(): string {
        return this.url;
    }

    getFolder(): string {
        return this.folder;
    }

    getLogoUrl(): string {
        return this.logoUrl;
    }

    getMobileLogoUrl(): string {
        if (!this.logoUrl) {
            return '';
        }
        let to = this.logoUrl.lastIndexOf('/');
        to = to === -1 ? this.logoUrl.length : to + 1;
        return this.logoUrl.substring(0, to) + 'logo_mobile.svg';
    }

    getLogoWebUrl(): string {
        return this.logoWebUrl;
    }

    getFaviconUrl(): string {
        return this.faviconUrl;
    }

    getFramework(): string {
        return this.framework;
    }

    getTicketUrl(): string {
        return this.ticketUrl;
    }

    getPhoneSupport(): any[] {
        return this.phoneSupport;
    }

    getTermsUrl(): string {
        return this.termsUrl;
    }

    getPrivacyUrl(): string {
        return this.privacyUrl;
    }

    getSupportUrl(): string {
        return this.supportUrl;
    }

    getSegmentId(): number {
        return this.segmentId;
    }

    getSegmentLabel(): string {
        switch (this.segmentId) {
            case 1:
                return Site.LOVE_LABEL;
            case 2:
                return Site.CASUAL_LABEL;
            case 3:
                return Site.SEXY_LABEL;
            case 4:
                return Site.GAY_LABEL;
            case 5:
                return Site.FRIEND_LABEL;
            case 6:
                return Site.SUGAR_LABEL;
        }
    }

    getMosaicUrl(profile: Profile): string {
        const gender = (profile.isMale() && !this.isGay()) ? 'women' : 'men';
        const segment = this.isGay() ? Site.CASUAL_LABEL : this.getSegmentLabel();
        return `assets/img/notification/mosaic-${gender}-${segment}.jpg`;
    }

    getIsWebCam(): boolean {
        return this.isWebCam;
    }

    getMailTechnical(): string {
        return this.mailTechnical;
    }

    getMailContact(): string {
        return this.mailContact;
    }

    getFootCompany(): string {
        return this.footCompany;
    }

    getShopUrl(): string {
        return this.shopUrl;
    }

    getEBooks(): Ebook[] {
        return this.eBooks;
    }

    getCss(): object {
        return this.css;
    }

    getCompany(): Company {
        return this.company;
    }

    getLocale(): string {
        return this.locale;
    }

    getCountries(): string {
        return this.countries;
    }

    isLove(): boolean {
        return this.segmentId === Site.LOVE;
    }

    isCasual(): boolean {
        return this.segmentId === Site.CASUAL;
    }

    isSexy(): boolean {
        return this.segmentId === Site.SEXY;
    }

    isGay(): boolean {
        return this.segmentId === Site.GAY;
    }

    isFriend(): boolean {
        return this.segmentId === Site.FRIEND;
    }

    isSugar(): boolean {
        return this.segmentId === Site.SUGAR;
    }

    getStaticImgUrl(): string {
        return `https://static.${this.framework}/`;
    }

    getMailSupport() {
        for (const phoneSupport of this.getPhoneSupport()) {
            if (phoneSupport.default) {
                return phoneSupport.email;
            }
        }

        return null;
    }

    getMailPrivacy(): string {
        return this.getMailSupport().replace(/([^@]+)/i, 'privacy');
    }

    getDefaultPhoneSupport() {
        for (const phoneSupport of this.getPhoneSupport()) {
            if (phoneSupport.default) {
                return phoneSupport.number;
            }
        }

        return null;
    }

    getGroupId(): number {
        return this.groupId;
    }
}
