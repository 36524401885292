import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CamModel} from '../../../models/cam-model/cam-model';
import {SiteService} from '../../../services/site/site.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-cam-model-detail',
    templateUrl: './cam-model-detail.component.html',
    styleUrls: ['./cam-model-detail.component.scss']
})
export class CamModelDetailComponent {

    /**
     * All theses "private static readonly" variables are used to interface our data with DNX data.
     */
    private static readonly zodiacSign = {
        aries: '♈',
        taurus: '♉',
        gemini: '♊',
        cancer: '♋',
        leo: '♌',
        virgo: '♍',
        libra: '♎',
        scorpio: '♏',
        sagittarius: '♐',
        capricornus: '♑',
        aquarius: '♒',
        pisces: '♓'
    };

    private static readonly sexuality = {
        straight: 'heterosexual',
        bisexual: 'bisexual',
        gay: 'homosexual'
    }

    private static readonly hair = {
        black: 'black_hair',
        blonde: 'blonde_hair',
        brunette: 'brown_hair',
        red: 'red_hair',
        other: 'other'
    };

    private static readonly haircut = {
        crew_cut: 'short_hair',
        bald: 'bald',
        short: 'short_hair',
        long: 'long_hair'
    };

    private static readonly eyes = {
        blue: 'blue_eyes',
        brown: 'brown_eyes',
        green: 'green_eyes',
        grey: 'grey_eyes',
        other: 'other'
    };

    private static readonly origin = {
        european: 'origin_european',
        coloured: 'origin_mixed',
        asian: 'origin_asiatic',
        latin: 'origin_hispanic',
        african: 'origin_african',
        other: 'other'
    };

    private static readonly figure = {
        S: 'slender_body',
        M: 'average_body',
        L: 'curvy_body',
        XL: 'curvy_body'
    }

    camModel: CamModel;
    currentPhotoIndex = 0;

    constructor(
        public dialogRef: MatDialogRef<CamModelDetailComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private siteService: SiteService,
        private translate: TranslateService
    ) {
        this.camModel = data.camModel;
    }

    getHair(): string {
        if (CamModelDetailComponent.hair[this.camModel.hair]) {
            return 'profile_answers.' + CamModelDetailComponent.hair[this.camModel.hair];
        }
        return '';
    }

    getHaircut(): string {
        if (CamModelDetailComponent.haircut[this.camModel.hairType]) {
            return 'profile_answers.' + CamModelDetailComponent.haircut[this.camModel.hairType];
        }
        return '';
    }

    getEyes(): string {
        if (CamModelDetailComponent.eyes[this.camModel.eyesColor]) {
            return 'profile_answers.' + CamModelDetailComponent.eyes[this.camModel.eyesColor];
        }
        return '';
    }

    getCountry(): string {
        return 'country_list.' + this.camModel.country;
    }

    getOrigin(): string {
        if (CamModelDetailComponent.origin[this.camModel.origin]) {
            return 'profile_answers.' + CamModelDetailComponent.origin[this.camModel.origin];
        }
        return '';
    }

    getFigure(): string {
        if (CamModelDetailComponent.figure[this.camModel.figure]) {
            return 'profile_answers.' + CamModelDetailComponent.figure[this.camModel.figure];
        }
        return '';
    }

    getSexuality(): string {
        if (CamModelDetailComponent.sexuality[this.camModel.sexpref]) {
            return 'profile_answers.' + CamModelDetailComponent.sexuality[this.camModel.sexpref];
        }
        return '';
    }

    getTranslatedLanguages(): string {
        if (!this.camModel.language) {
            return '';
        }

        const languages = this.camModel.language.split('-');
        const languageTranslations = [];
        for (const language of languages) {
            const index = 'profile_answers.language_' + language;
            const translation = this.translate.instant(index);
            if (translation !== index) {
                languageTranslations.push(translation);
            }
        }

        return languageTranslations.join(', ');
    }

    getPresentations(): any {
        if (!this.camModel.profiles) {
            return false;
        }
        const locale = this.siteService.getSite().getLocale().toUpperCase();
        return this.camModel.profiles[locale] ? this.camModel.profiles[locale] : this.camModel.profiles['EN'];
    }

    getZodiacSign(): string {
        return CamModelDetailComponent.zodiacSign[this.camModel.sign];
    }
}
