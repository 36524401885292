import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class AcceptedReceivedFriendRequestNotificationType extends AbstractNotificationType {

    static readonly TYPE = 5;

    protected buttons:NotificationButton[] = [
        new NotificationButton('menu.my_contacts', () => {
            this.goTo('contacts');
        })
    ];
}
