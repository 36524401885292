import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';
import {Notification} from '../models/notification/notification';

export class NewMessageNotificationType extends AbstractNotificationType {

    static readonly TYPE = 1;

    protected buttons:NotificationButton[] = [];

    setNotification(notification: Notification) {
        super.setNotification(notification);

        this.buttons.push(
            new NotificationButton(this.getTotalMessage() > 1 ? 'view_messages' : 'view_message', () => {
                if (this.notification.getFromProfile()) {
                    this.goTo('chat', this.notification.getFromProfile().getId());
                } else {
                    this.goTo('chat');
                }
                this.notificationService.delete(this.notification);
            })
        );
    }

    getText() {
        return super.getText() + (this.getTotalMessage() > 1 ? '' : '_only_one');
    }

    getIcon(): string {
        return 'fa-comment';
    }

    getMetaData() {
        return {
            totalMessage: this.getTotalMessage()
        };
    }

    private getTotalMessage() {
        return this.notification && this.notification.getMetaData() && this.notification.getMetaData().totalMessage ?
            this.notification.getMetaData().totalMessage : 1;
    }
}
