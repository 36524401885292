<div class="card border-0 box mb-4 alert alert-success alert-access">
    <div class="card-body">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="font-weight-bold">{{ 'full_access.activate_access' | translate }}</h5>
                <a [routerLink]="['/payment']" [queryParams]="{action: 'profile_view'}" class="btn btn-success btn-lg d-inline-block"><i class="fas fa-crown" [matTooltip]="'full_access.activate_access_for_profile' | translate"></i> {{ 'full_access.activate_access_for_profile' | translate }}</a>
            </div>
        </div>
    </div>
</div>
