<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center mb-0">
        <h1 class="mb-0">
            <br>
            <br>
        </h1>
    </div>
</div>

<app-live-layout>
    <div class="card border-0 rounded shadow-lg my-5 upsell-xp-okko">
        <i class="far fa-check-circle text-success fleche-upsell" aria-hidden="true"></i>
        <div class="card-body px-4 pb-4 px-md-5 pb-md-5 pt-4">
            <h3 class="mb-0"><span class="text-success d-none d-md-inline-block">
Bravo !<br>
Vos love coins sont crédités 😊<br>
</span>
                <span class="text-success d-inline-block d-md-none">
Bravo !<br>
Vos love coins sont crédités 😊<br></span>
                <br><br>
                Utilisez-les maintenant ou gardez-les pour plus tard !<br>
                Vous pouvez à présent retourner sur le service de rencontres...
            </h3>
            <br>
            <a (click)="redirectToHome()"
               class="btn btn-lg rounded-pill py-3 btn-success btn-block shadow-sm text-uppercase font-weight-bold"><i
                class="fas fa-hand-point-right mr-2" aria-hidden="true"></i> <span class="d-none d-sm-inline-block">Retour sur le service</span><span
                class="d-inline-block d-sm-none">Retour</span></a>
        </div>
    </div>
</app-live-layout>
