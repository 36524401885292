import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HelperService} from '../helper/helper.service';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    constructor(private http: HttpClient, private helperService: HelperService) {
    }

    find(latitude:number, longitude:number) {
        const searchParams = this.helperService.httpBuildQuery({
                lat: latitude,
                lng: longitude
            });

        return this.http.get(environment.backendUrl + '/geolocation?' + searchParams);
    }

}
