import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Answer} from './answer';

@Injectable({
    providedIn: 'root'
})
export class Question implements Deserializable {
    id: number;
    name: string;
    type: string;
    answers: Answer[];

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.answers) {
            this.answers = [];
            for (const answer of input.answers) {
                this.answers.push(new Answer().deserialize(answer));
            }
        }

        return this;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getType(): string {
        return this.type;
    }

    getAnswers(): Answer[] {
        return this.answers;
    }

    getAnswer(id): any {
        return this.answers.find((answer: Answer) => +answer.id === +id);
    }

    hasAnswer(id): boolean {
        return !!this.getAnswer(id);
    }

    reverseGender(): boolean {
        return this.name.includes('ideal_');
    }
}
