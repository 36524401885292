<!-- MemberZone stylesheet -->
<app-template-style-component *ngIf="isShowStyle()"></app-template-style-component>

<div [id]="getClassLang()" [class.peeping-show]="peepingShowOngoing()">
    <!-- <div class="sticky" *ngIf="isReady()"> -->
    <app-ad-space-display ids="1,2" adClass="banner_top_menu" *ngIf="isShowHeaderAdspace()"
                          [refreshOnRouteChanged]="true"></app-ad-space-display>
    <app-header-component *ngIf="isShowHeader()"></app-header-component>
    <!-- </div> -->

    <div [ngClass]="{'contenu': !isFullWidth(), 'h-100': isFullWidth()}" class="container-fluid">
        <app-ad-space-display ids="9,10" adClass="banner_bottom_menu"
                              *ngIf="canShowBannerBottomMenu()"></app-ad-space-display>
        <app-search-appearance-bar *ngIf="canShowSearchAppearanceBar()"></app-search-appearance-bar>

        <div [ngClass]="{'row': isFullWidth(), 'h-100': isFullWidth()}">
            <div [class.w-100]="isFullWidth()" class="main" autoscroll="true">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <app-message-notification></app-message-notification>

    <app-panic-button></app-panic-button>

    <app-footer-component *ngIf="isShowFooter()"></app-footer-component>
</div>
