import {Injectable} from '@angular/core';
import {Photo} from '../../../models/profile/photo';
import {Album} from '../../../models/profile/album';
import {Profile} from '../../../models/profile/profile';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PhotoService {

    constructor(private http: HttpClient) {}

    /**
     * Get all photos of an album profile
     * @param album Album
     * @return Observable<Album>
     */
    findByAlbum(album: Album): Observable<Album> {
        return new Observable<Album>(observer => {
            this.http.get(environment.backendUrl + '/photo?albumId=' + album.getId()).subscribe((response: any) => {
                if (response.success) {
                    observer.next(album.deserialize({ photos: response.data, totalPhoto: response.total }));
                } else {
                    observer.error('404 Album profile not found');
                }
            });
        });
    }

    /**
     * Create photo of an album profile
     * @param photo Photo
     * @param photoData string
     * @param album Album
     * @return Observable<Object>
     */
    create(photo: Photo, photoData: string, album: Album) {
        const data: any = {
            data: photoData,
            name: photo.getName(),
            private: false,
            albumId: null,
            isMain: photo.isMain()
        };

        if (album.getId()) {
            data.albumId = album.getId();
        } else {
            data.albumType = album.getType();
        }
        return this.http.post(environment.backendUrl + '/photo', data);
    }

    /**
     * Edit photo of an album profile.
     */
    update(photo: Photo) {
        return this.http.put(environment.backendUrl + '/photo/' + photo.getId(), {
            name: photo.getName(),
            private: photo.getPrivate(),
            isMain: photo.isMain()
        });
    }

    /**
     * Delete photo of an profile
     * @param photo
     * @param profile
     */
    delete(photo: Photo, profile: Profile) {
        return this.http.delete(environment.backendUrl + '/photo/' + photo.getId());
    }
}
