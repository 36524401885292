<div *ngIf="result" class="text-success text-center mt-5">
    <i class="fas fa-check-circle fa-4x"></i>
    <br><br>
    <span><strong>{{ 'email_conf.thanks_done' | translate }}</strong></span>
    <br><br>
    <button [routerLink]="['/home']" class="btn btn-primary mb-2">{{ 'menu.home' | translate }}</button>
</div>

<div *ngIf="!result && error" class="text-danger text-center">
    <i class="fas fa-exclamation-circle fa-4x"></i>
    <br><br>
    <span><strong>{{ error }}</strong></span>
    <br><br>
    <button (click)="openCheckEmailDialog()" class="btn btn-primary mb-2">
        {{ 'email_conf.check_my_email' | translate }}
    </button>
</div>
