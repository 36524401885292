<ul class="nav nav-tabs onglets-contacts mb-4 justify-content-center justify-content-lg-start mt-3">
    <li class="nav-item d-none d-xl-block">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'friends'}" [class.active]="isTab('friends')" class="nav-link">
            {{ 'contact.header.friends' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('friends')" [class.badge-secondary]="!isTab('friends')" class="badge ml-2">
                {{ countProfiles('friends') }}
            </span>
        </a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'favorites'}" [class.active]="isTab('favorites')" class="nav-link">
            {{ 'contact.my_favorites' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('favorites')" [class.badge-secondary]="!isTab('favorites')" class="badge ml-2">
                {{ countProfiles('favorites') }}
            </span>
        </a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'blockedProfiles'}" [class.active]="isTab('blockedProfiles')" class="nav-link">
            {{ 'contact.header.blockedProfiles' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('blockedProfiles')" [class.badge-secondary]="!isTab('blockedProfiles')" class="badge ml-2">
                {{ countProfiles('blockedProfiles') }}
            </span>
        </a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'pendingFriends'}" [class.active]="isTab('pendingFriends')" class="nav-link">
            {{ 'contact.header.pendingFriends' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('pendingFriends')" [class.badge-secondary]="!isTab('pendingFriends')" class="badge ml-2">
                {{ countProfiles('pendingFriends') }}
            </span>
        </a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'requestedFriends'}" [class.active]="isTab('requestedFriends')" class="nav-link">
            {{ 'contact.header.requestedFriends' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('requestedFriends')" [class.badge-secondary]="!isTab('requestedFriends')" class="badge ml-2">
                {{ countProfiles('requestedFriends') }}
            </span>
        </a>
    </li>

    <li class="nav-item px-1 d-block d-xl-none li-w-20 mb-1">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'friends'}" [class.active]="isTab('friends')" class="btn btn-light btn-sm d-inline-block w-100">
            {{ 'contact.friends' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('friends')" [class.badge-secondary]="!isTab('friends')" class="badge ml-2">
                {{ countProfiles('friends') }}
            </span>
        </a>
    </li>
    <li class="nav-item px-1 d-block d-xl-none li-w-20 mb-1">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'favorites'}" [class.active]="isTab('favorites')" class="btn btn-light btn-sm d-inline-block w-100">
            {{ 'contact.favorites' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('favorites')" [class.badge-secondary]="!isTab('favorites')" class="badge ml-2">
                {{ countProfiles('favorites') }}
            </span>
        </a>
    </li>
    <li class="nav-item px-1 d-block d-xl-none li-w-20 mb-1">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'blockedProfiles'}" [class.active]="isTab('blockedProfiles')" class="btn btn-light btn-sm d-inline-block w-100">
            {{ 'contact.blocked' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('blockedProfiles')" [class.badge-secondary]="!isTab('blockedProfiles')" class="badge ml-2">
                {{ countProfiles('blockedProfiles') }}
            </span>
        </a>
    </li>
    <li class="nav-item px-1 d-block d-xl-none li-w-20 mb-1">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'pendingFriends'}" [class.active]="isTab('pendingFriends')" class="btn btn-light btn-sm d-inline-block w-100">
            {{ 'contact.header.pendingFriends_short' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('pendingFriends')" [class.badge-secondary]="!isTab('pendingFriends')" class="badge ml-2">
                {{ countProfiles('pendingFriends') }}
            </span>
        </a>
    </li>
    <li class="nav-item px-1 d-block d-xl-none li-w-20 mb-1">
        <a [routerLink]="['/contacts']" [queryParams]="{tab: 'requestedFriends'}" [class.active]="isTab('requestedFriends')" class="btn btn-light btn-sm d-inline-block w-100">
            {{ 'contact.header.requestedFriends_short' | translate }}
            <span *ngIf="!loading" [class.badge-primary]="isTab('requestedFriends')" [class.badge-secondary]="!isTab('requestedFriends')" class="badge ml-2">
                {{ countProfiles('requestedFriends') }}
            </span>
        </a>
    </li>
</ul>

<div *ngIf="!loading && getProfiles().length > 0" class="row px-0">
    <div *ngFor="let profile of getProfiles()" class="col-xl-4 col-md-6">
        <app-contact-thumb-profile [status]="tab" [profile]="profile" (profileChange)="getContacts()"></app-contact-thumb-profile>
    </div>
</div>

<div *ngIf="!loading && getProfiles().length == 0" class="row px-0">
    <div class="col-12 text-center">
        {{ 'contact.list_empty' | translate }}
    </div>
</div>

<div *ngIf="loading" class="col-12 text-center mx-auto mt-4">
    <i class="fas fa-spinner fa-spin text-muted loader"></i>
</div>
