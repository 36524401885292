import {Component, ElementRef, EventEmitter, Input, Output, OnInit, ViewChild} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {ProfileService} from '../../../../services/profile/profile.service';

@Component({
    selector: 'app-finish-signup-birthdate',
    templateUrl: './finish-signup-birthdate.component.html',
    styleUrls: ['./finish-signup-birthdate.component.scss']
})
export class FinishSignupBirthdateComponent implements OnInit {

    @Output() previousStep = new EventEmitter<number>();
    @Output() nextStep = new EventEmitter<number>();
    @Output() setStepCache = new EventEmitter<any>();
    @Input() stepCacheData;
    @Input() currentStep: number;
    @ViewChild('birthdateDayField') inputDay: ElementRef;
    @ViewChild('birthdateMonthField') inputMonth: ElementRef;
    @ViewChild('birthdateYearField') inputYear: ElementRef;

    birthdateDay = '';

    birthdateMonth = '';

    birthdateYear = '';

    constructor(
        private currentUserService: CurrentUserService,
        private profileService: ProfileService
    ) {
    }

    ngOnInit() {
        if (typeof this.stepCacheData !== 'undefined' && typeof this.stepCacheData.birthdate !== 'undefined') {
            this.birthdateYear = this.stepCacheData.birthdate.year;
            this.birthdateMonth = this.stepCacheData.birthdate.month;
            this.birthdateDay = this.stepCacheData.birthdate.day;
        } else {
            const birthdate: any = this.currentUserService.getAccount().getProfile().getBirthDate();

            if (birthdate) {
                this.birthdateDay = birthdate.getDate();
                this.birthdateMonth = birthdate.getMonth() + 1;
                this.birthdateYear = birthdate.getYear();
            }
        }
    }

    updateCacheData() {
        let cacheData: any = this.stepCacheData;

        if (typeof cacheData === 'undefined') {
            cacheData = {};
        }

        cacheData.birthdate = {
            day: this.birthdateDay,
            month: this.birthdateMonth,
            year: this.birthdateYear
        };

        this.setStepCache.emit(cacheData);
    }

    previous() {
        this.updateCacheData();
        this.previousStep.emit();
    }

    next() {
        if (this.birthdateYear && this.birthdateMonth && this.birthdateDay) {
            if (this.isAdult()) {
                this.profileService.putProfile({
                    birthDate: {
                        year: this.birthdateYear,
                        month: this.birthdateMonth,
                        day: this.birthdateDay
                    }
                }).subscribe(() => {
                    this.currentUserService.getAccount().getProfile().getFieldsStatus().birthDate = 'provided';
                    this.currentUserService.getAccount().getProfile().setBirthDate(new Date(`${this.birthdateYear}-${this.birthdateMonth}-${this.birthdateDay}`));

                    this.updateCacheData();
                    this.nextStep.emit();
                });
            }
        }
    }

    isAdult(): boolean {
        const diff = Date.now() - new Date(
            parseInt(this.birthdateYear, 0),
            parseInt(this.birthdateMonth, 0) - 1,
            parseInt(this.birthdateDay, 0)
        ).getTime();

        const ageDate = new Date(diff);

        return Math.abs(ageDate.getUTCFullYear() - 1970) >= 18;
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }

    hasCompletedBirthDate(): boolean {
        return !!(this.birthdateDay && this.birthdateMonth && this.birthdateYear);
    }

    isCorrectBirthDate(): boolean {
        const currentYear = (new Date()).getFullYear();

        const day = parseInt(this.birthdateDay, 0);
        const month = parseInt(this.birthdateMonth, 0);
        const year = parseInt(this.birthdateYear, 0);

        return (
            day >= 1 && day <= 31
            && month >= 1 && month <= 12
            && year >= (currentYear - 100) && year <= currentYear
        );
    }

    onDayKeyPress() {
        if (this.birthdateDay && this.birthdateDay.toString().length === 2) {
            this.inputMonth.nativeElement.focus();
        }
    }

    onMonthKeyPress() {
        if (this.birthdateMonth && this.birthdateMonth.toString().length === 2) {
            this.inputYear.nativeElement.focus();
        }
    }

    checkDayFormat() {
        if (this.birthdateDay && this.birthdateDay.toString().length === 1) {
            this.birthdateDay = '0' + this.birthdateDay.toString();
        }
    }

    checkMonthFormat() {
        if (this.birthdateMonth && this.birthdateMonth.toString().length === 1) {
            this.birthdateMonth = '0' + this.birthdateMonth.toString();
        }
    }
}
