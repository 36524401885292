import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Profile} from '../../../models/profile/profile';

@Component({
    selector: 'app-finish-signup',
    templateUrl: './finish-signup.component.html',
    styleUrls: ['./finish-signup.component.scss']
})
export class FinishSignupComponent extends BaseComponent {

    currentProfile: Profile;
    fieldsStatus: {
        gender: string,
        searchedGender: string,
        nickname: string,
        password: string,
        birthDate: string,
        city: string,
        postalCode: string
    };

    hasPhoto = false;

    onboardingStep = 0;
    nextStep = 1;

    genderStepLabel;
    searchedGenderStepLabel;
    nicknameStepLabel;
    passwordStepLabel;
    birthdateStepLabel;
    localisationStepLabel;
    photoStepLabel;

    stepCacheData;


    constructor(
        injector: Injector
    ) {
        super(injector);
        this.currentProfile = this.account.getProfile();

        this.fieldsStatus = Object.assign({}, this.currentProfile.getFieldsStatus());

        if (this.currentProfile.getPhoto()) {
            this.hasPhoto = true;
        }

        this.loadStepsLabel();
        this.next();
    }

    previous() {
        if (this.nextStep === this.onboardingStep) {
            // using for pagination button.
            this.nextStep--;
        }

        const previousStep = this.nextStep;

        const success = this.loadStep(previousStep);

        if (!success && previousStep > 1) {
            this.nextStep--;
            this.previous();
        }
    }

    next() {
        if (this.nextStep === this.onboardingStep) {
            // using for pagination button.
            this.nextStep++;
        }

        const nextStep = this.nextStep;

        const success = this.loadStep(nextStep);

        if (!success && nextStep < 7) {
            this.nextStep++;
            this.next();
        } else if (!success && nextStep >= 7) {
            this.showFinalStep();
        }
    }

    showFinalStep() {
        this.onboardingStep = 8;
    }

    loadStep(nextStep) {
        let success = false;

        if (typeof this.fieldsStatus === 'undefined') {
            return false;
        }

        switch (nextStep) {
            case 1:
                if (this.fieldsStatus.gender !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 2:
                if (this.fieldsStatus.searchedGender !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 3:
                if (this.fieldsStatus.nickname !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 4:
                if (this.fieldsStatus.password !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 5:
                if (this.fieldsStatus.birthDate !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 6:
                if (this.fieldsStatus.postalCode !== 'provided' || this.fieldsStatus.city !== 'provided') {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
            case 7:
                if (!this.currentProfile.getPhoto()) {
                    this.onboardingStep = nextStep;
                    success = true;
                }
                break;
        }

        return success;
    }

    showGenderStep(): boolean {
        return this.onboardingStep === 1;
    }

    showSearchGenderStep(): boolean {
        return this.onboardingStep === 2;
    }

    showNicknameStep(): boolean {
        return this.onboardingStep === 3;
    }

    showPasswordStep(): boolean {
        return this.onboardingStep === 4;
    }

    showBirthdateStep(): boolean {
        return this.onboardingStep === 5;
    }

    showLocalisationStep(): boolean {
        return this.onboardingStep === 6;
    }

    showPhotoStep(): boolean {
        return this.onboardingStep === 7;
    }

    showConfirmation(): boolean {
        return this.onboardingStep === 8;
    }

    loadStepsLabel() {
        let step = 0;

        if (typeof this.fieldsStatus === 'undefined') {
            return;
        }

        if (this.fieldsStatus.gender !== 'provided') {
            step++;
            this.genderStepLabel = step;
        }

        if (this.fieldsStatus.searchedGender !== 'provided') {
            step++;
            this.searchedGenderStepLabel = step;
        }

        if (this.fieldsStatus.nickname !== 'provided') {
            step++;
            this.nicknameStepLabel = step;
        }

        if (this.fieldsStatus.password !== 'provided') {
            step++;
            this.passwordStepLabel = step;
        }

        if (this.fieldsStatus.birthDate !== 'provided') {
            step++;
            this.birthdateStepLabel = step;
        }

        if (this.fieldsStatus.city !== 'provided' || this.fieldsStatus.postalCode !== 'provided') {
            step++;
            this.localisationStepLabel = step;
        }

        if (!this.hasPhoto) {
            step++;
            this.photoStepLabel = step;
        }
    }

    setStepCache(stepCache) {
        this.stepCacheData = stepCache;
    }
}
