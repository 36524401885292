import {Component, OnInit} from '@angular/core';
import {InitService} from '../../../services/init/init.service';

@Component({
    selector: 'app-chat-disconnected',
    templateUrl: './chat-disconnected.component.html',
    styleUrls: ['./chat-disconnected.component.scss']
})
export class ChatDisconnectedComponent implements OnInit {

    constructor(
        private initService: InitService
    ) {
    }

    ngOnInit(): void {
    }

    reconnect() {
        this.initService.loadSocket();
    }
}
