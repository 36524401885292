import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'formatHtml'
})
export class FormatHtmlPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    transform(value: string, ...args: any[]): SafeHtml {
        if (args.length) {
            const replacements: any = args[0];

            for (const tag in replacements) {
                if (replacements.hasOwnProperty(tag)) {
                    value = value.replace(tag, replacements[tag]);
                }
            }
        }

        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
