<div class="container container-upsell mb-5 pb-5">
    <div class="row">
        <div class="col-lg-6">
            <span class="boite-offre position-relative">
            <img src="assets/img/virilblue/boite.png" alt="" class="img-fluid boite">
            <span class="badge badge-danger"><i class="fas fa-gift"
                                                aria-hidden="true"></i><br><span>OFFERT !</span></span>
            <span class="badge badge-success"><i class="fas fa-plus" aria-hidden="true"></i></span>
            <img src="assets/img/virilblue/boite2.png" alt="" class="img-fluid boite-offerte">
            </span>

            <h2 class="font-weight-bolder mb-0 mt-5 mt-lg-3"><b>Vous</b> allez <span
                class="text-primary text-uppercase">bientôt</span> faire de <br><span
                class="bg-primary text-white text-uppercase px-2">nombreuses rencontres</span><br>
                <small class="text-primary mt-4">Est-ce que lors de votre premier rendez-vous, vous voulez ?</small>
            </h2>
            <ul class="list-group list-group-flush text-left m-0 w-100 p-0 my-3">
                <li class="list-group-item">
                    <img src="assets/img/virilblue/check.svg" class="img-fluid icon" alt=""> Pénétrer <b>plus longuement</b> et <b>plus
                    profondément</b> votre partenaire
                </li>
                <li class="list-group-item">
                    <img src="assets/img/virilblue/check.svg" class="img-fluid icon" alt=""> Avoir des érections <b>plus dures</b> et
                    <b>plus puissantes</b>
                </li>
                <li class="list-group-item">
                    <img src="assets/img/virilblue/check.svg" class="img-fluid icon" alt=""> Expérimenter <b>plus de sensations</b>
                    durant l'acte sexuel
                </li>
                <li class="list-group-item">
                    <img src="assets/img/virilblue/check.svg" class="img-fluid icon" alt=""> Vous <b>épanouir totalement</b>
                    sexuellement et faire jouir votre partenaire
                </li>
                <li class="list-group-item">
                    <img src="assets/img/virilblue/check.svg" class="img-fluid icon" alt=""> Avoir des <b>coïts à répétition</b> en
                    restant ferme et endurant
                </li>
            </ul>
            <p class="lead font-weight-bold" style="font-size:14px;">Avec VirilBlue, développez votre <u>vrai
                potentiel</u> sexuel !<br>
                Découvrez le temps d'un rendez-vous, un plaisir sexuel inégalé.</p>
        </div>

        <div class="col-lg-6">
            <div class="card border-0 rounded shadow-lg mt-5 mt-lg-4">
                <ng-content></ng-content>
            </div>
        </div>

        <div class="col-12 text-center mt-5">
            <p class="lead"><span class="text-uppercase font-weight-bold">Tout cela est vraiment possible avec une cure de <b>Viril<span
                class="blue">Blue</span></b></span><br>
                <b>Viril<span class="blue">Blue</span></b> est un <b>produit révolutionnaire</b> enfin disponible chez
                vous...<br>
                Profitez d'une <b>offre exceptionnelle</b> réservée aux membres pour <b>débuter votre cure</b> !<br>
            </p>
        </div>

    </div>

</div>

<section class="bg-primary py-4 avantages mt-5">

    <div class="container">

        <div class="row text-center">

            <div class="col-6 col-md-3">

                <div class="ico-fond"><span class="ico"><i class="fas fa-truck" aria-hidden="true"></i></span></div>
                <h5 class="text-white font-weight-bold mb-5 mb-md-0">Livraison <br class="d-none d-md-block d-lg-none">Gratuite
                </h5>

            </div>

            <div class="col-6 col-md-3">

                <div class="ico-fond"><span class="ico"><i class="fas fa-box" aria-hidden="true"></i></span></div>
                <h5 class="text-white font-weight-bold mb-5 mb-md-0">Colis <br class="d-none d-md-block d-lg-none">Discret
                </h5>

            </div>

            <div class="col-6 col-md-3">

                <div class="ico-fond"><span class="ico"><i class="fas fa-leaf" aria-hidden="true"></i></span></div>
                <h5 class="text-white font-weight-bold mb-0">Produit <br class="d-none d-md-block d-lg-none">Naturel
                </h5>

            </div>

            <div class="col-6 col-md-3">

                <div class="ico-fond"><span class="ico"><img src="assets/img/virilblue/caduceus.png" class="img-fluid"></span>
                </div>
                <h5 class="text-white font-weight-bold mb-0">Testé en <br class="d-none d-md-block d-lg-none">Laboratoire
                </h5>

            </div>

        </div>
    </div>

</section>
