import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';
import {Notification} from '../models/notification/notification';

export class PasswordChangeNotificationType extends AbstractNotificationType {

    // require delete
    static readonly TYPE = 8;

    protected buttons:NotificationButton[] = [];

    setNotification(notification: Notification) {
        super.setNotification(notification);
        this.buttons.push(
            new NotificationButton('resend_confirmation_email', () => {
                if (this.notification.metaData.password) {
                    this.currentUserService.sendCode('password', this.notification.metaData.password).subscribe(() => {
                        this.goTo('pending-confirmation/password/' + this.notification.metaData.password);
                    }, () => {});
                } else {
                    this.goTo('account', null, 'account');
                }
            })
        )
    }

    getIcon(): string {
        return 'fa-lock';
    }
}
