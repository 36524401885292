import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {ClosedAlert} from './closed-alert';
import {SearchFilters} from './search-filters';
import {SexCamsFilters} from './sex-cams-filters';

@Injectable({
    providedIn: 'root'
})
export class Settings implements Deserializable {

    closedAlert = new ClosedAlert();
    searchFilters = new SearchFilters();
    sexCamFilters = new SexCamsFilters();
    finishPhotoLater = false;
    billingTemplate;

    deserialize(data: any): this {
        if (data.closedAlert) {
            this.closedAlert.deserialize(data.closedAlert);
        }

        if (data.searchFilters) {
            this.searchFilters.deserialize(data.searchFilters);
        }

        if (data.sexCamFilters) {
            this.sexCamFilters.deserialize(data.sexCamFilters);
        }

        if (data.finishPhotoLater) {
            this.finishPhotoLater = data.finishPhotoLater;
        }

        if (data.billingTemplate) {
            this.billingTemplate = data.billingTemplate;
        } else {
            this.setRandomBillingTemplate();
        }

        return this;
    }

    loginReset() {
        this.finishPhotoLater = false;
        this.closedAlert = new ClosedAlert();
        this.setRandomBillingTemplate();
    }

    getClosedAlert(): ClosedAlert {
        return this.closedAlert;
    }

    isClosedAlert(alert: string): boolean {
        return this.closedAlert !== null && this.closedAlert[alert] || false;
    }

    getSearchFilters(): SearchFilters {
        return this.searchFilters;
    }

    getSexCamsFilters(): SexCamsFilters {
        return this.sexCamFilters;
    }

    setRandomBillingTemplate() {
        this.billingTemplate = Math.floor(Math.random() * (261 - 256 + 1)) + 256;
    }

    getLoginSettings(): object {
        return {
            finishPhotoLater: this.finishPhotoLater,
            closedAlert: this.closedAlert,
            billingTemplate: this.billingTemplate
        };
    }
}
