import {Component, Injector} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {BaseComponent} from '../../base/base.component';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProfileService} from '../../../services/profile/profile.service';
import {FiltersService} from '../../../services/filters/filters.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-menu-component',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent {

    constructor(
        private modalService: ModalService,
        private filtersService: FiltersService,
        private profileService: ProfileService,
        private router: Router,
        private http: HttpClient,
        injector: Injector
    ) {
        super(injector);
    }

    goTo(page) {
        if (page === '/profile') {
            this.currentUserService.refresh();
        }
        this.filtersService.setCurrentPage(1);
        this.router.navigate([page]);
    }

    webcamsSexy() {
        if (this.currentUserService.getAccount().isSuspended()) {
            const data = this.currentUserService.suspensionModalData('/webcamsSexy');
            if (data !== false) {
                this.modalService.openFullScreenConfirmation(data);
            }
            return false;
        }

        this.http.get(environment.backendUrl + '/webcam/redirect-url')
            .subscribe((result: any) => {
                if (result.success && result.data.redirectUrl) {
                    window.open(decodeURIComponent(result.data.redirectUrl), '_blank');
                }
            }, (error: any) => {
                window.open('https://liveparadise.com');
            });
    }

    isActive(page) {
        return this.router.routerState.snapshot.url === page;
    }

    hasUpsellUnlover(): boolean {
        return this.account.hasSubscription() && this.account.hasUpsellUnlover();
    }

    openUpsellXponsorUnlover() {
        this.modalService.openUpsellXponsorUnlover();
    }

    showEbooks(): boolean {
        return false;

        return (
            this.site.getEBooks().length > 0 &&
            this.account.activeSubscription()
        );
    }

    canShowWebcamMenu(): boolean {
        return this.currentUserService.getAccount().showSexCamMenu();
    }
}
