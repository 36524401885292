import {Injectable} from '@angular/core';
import {SocketService} from '../../socket/socket.service';
import {Room} from '../../../models/chat/room/room';
import {CurrentUserService} from '../../user/current-user.service';
import {ChatUserService} from '../chat-user/chat-user.service';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {User} from '../../../models/chat/user/user';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatRoomService {

    private rooms: Room[] = [];
    private sortedRooms: Room[] = [];
    private currentRoom: Room;
    private loadingProfileRoom: boolean;

    room = new BehaviorSubject(null);

    constructor(
        private socketService: SocketService,
        private currentUserService: CurrentUserService,
        private chatUserService: ChatUserService,
        private router: Router
    ) {
        this.loadingProfileRoom = false;
    }

    onPrivateRooms() {
        this.socketService.on('private_rooms', (data: any) => {
            this.rooms = data.map((roomData: any) => {
                let user1 = this.chatUserService.getUserById(roomData.user1.id);
                let user2 = this.chatUserService.getUserById(roomData.user2.id);

                if (!user1) {
                    user1 = new User().deserialize(roomData.user1);
                }

                if (!user2) {
                    user2 = new User().deserialize(roomData.user2);
                }

                const room: Room = new Room().deserialize({
                    currentProfileId: this.currentUserService.getAccount().getProfile().getId(),
                    id: roomData.id,
                    lastMessage: roomData.lastMessage,
                    lastMessageTime: new Date(roomData.time),
                    lastMessageId: roomData.lastMessageId,
                    lastMessageUserId: roomData.lastMessageUserId,
                    lastReadAt: roomData.lastReadAt,
                    unreadCount: roomData.unreadCount,
                    user1,
                    user2
                });

                this.chatUserService.addUser(room.getFriendUser());

                return room;
            });

            this.sortRooms();

            this.loadingProfileRoom = true;
        });
    }

    /**
     * Set room messages as read
     * @param room
     */
    setRoomRead(room: Room) {
        if (this.chatUserService.getMe() && room.getUnreadCount() > 0) {
            room.resetNewMessagesCount();
            this.socketService.emit('set_room_read', {
                roomId: room.getId(),
                userId: this.chatUserService.getMe().getId()
            }).then((response: any) => {
            });
        }
    }

    getCurrentRoom(): Room {
        return this.currentRoom;
    }

    hasCurrentRoom(): boolean {
        return typeof this.getCurrentRoom() !== 'undefined' && this.getCurrentRoom() !== null;
    }

    unselectRoom() {
        this.currentRoom = null;
        this.room.next(null);
    }

    setCurrentRoom(currentRoom: Room) {
        // ajouter la currentRoom dans ChatMessageService afin de savoir si on recoit un message
        // dans currentRoom ou pas.
        // si on reçoit dans currentRoom, alors ne pas incrémenter le nombre de message
        // si on n'a pas currentRoom dans ce service, on ne peut pas faire la vérification
        // (impossible d'inclure ChatRoomService dans ChatMessageService)
        this.currentRoom = currentRoom;
        this.room.next(this.currentRoom);
    }

    getRooms() {
        return this.rooms.filter((room: Room) => {
            return room.getId() > 0;
        });
    }

    getRoomByFriendUserId(friendUserId: number): Room {
        return this.rooms.find((room: Room) => {
            return room.getFriendUser().getId() === friendUserId;
        });
    }

    getRoomById(roomId: number): Room {
        return this.rooms.find((room: Room) => {
            return room.getId() === roomId;
        });
    }

    getLoadingProfileRoom(): boolean {
        return this.loadingProfileRoom;
    }

    openRoom(room: Room) {
        if (!this.getRoomById(room.getId())) {
            // Room doesn't exists :
            this.addRoom(room);
        }

        // Open existed room :
        room.setOpened(true);
        room.setEnabled(true);
        this.setCurrentRoom(room);
        this.setRoomRead(this.getCurrentRoom());
    }

    addRoom(room: Room) {
        this.rooms.push(room);
        this.sortRooms();
    }

    hasRoomsToDisplay(): boolean {
        return this.sortedRooms.length > 0;
    }

    getSortedRooms(limit: number = null) {
        if (limit) {
            return this.sortedRooms.slice(0, limit);
        } else {
            return this.sortedRooms;
        }
    }

    /**
     * Count the total of unread message
     */
    countTotalMessages(): number {
        let total = 0;

        for (const room of this.getRooms()) {
            total += room.getUnreadCount();
        }

        return total;
    }

    scrollTopMobile() {
        const screenWidth = Math.max((document.documentElement ? document.documentElement.clientWidth : 0), window.innerWidth || 0);

        if (screenWidth > 0 && screenWidth < 992 && this.router.url.startsWith('/chat')) {
            const html = $('html');
            if (html[0]) {
                html.stop().animate({scrollTop: html[0].scrollHeight}, 500, 'swing', () => {
                    // fix for old devices
                    window.scrollTo(0, html[0].scrollHeight);
                });
            }
        }
    }

    scrollReset() {
        $('html')[0].scrollTop = 0;
    }

    reset() {
        this.rooms = [];
        this.currentRoom = null;
        this.loadingProfileRoom = false;
        this.room.next(null);
    }

    deleteFriendRoomMessage(friendUser: User, messageData: any) {
        return new Promise((resolve) => {
            const result = {
                success: false,
                deleteRoom: false,
                isCurrentRoom: false
            };
            const room: Room = this.getRoomByFriendUserId(friendUser.getId());
            if (room) {
                room.deleteMessage(+messageData.id);
                if (messageData.roomDeleted) {
                    room.setEnabled(false);
                    room.setOpened(false);

                    this.deleteRoom(room);

                    result.success = true;
                    result.deleteRoom = true;

                    if (this.currentRoom && this.currentRoom.getId() === room.getId()) {
                        this.unselectRoom();
                        result.isCurrentRoom = true;
                    }
                } else if (this.currentRoom && this.currentRoom.getId() === room.getId()) {
                    this.setCurrentRoom(room);
                    result.success = true;
                }
                resolve(result);
            }
        });
    }

    deleteRoom(room: Room) {
        const roomIndex = this.getRooms().findIndex(r => r.getId() === room.getId());

        if (roomIndex !== 1) {
            this.rooms.splice(roomIndex, 1);
        }
    }

    sortRooms() {
        this.sortedRooms = this.getRooms().sort((room1, room2) => {
            if (room2.getLastMessage() && room1.getLastMessage()) {
                return room2.getLastMessageTime().getTime() - room1.getLastMessageTime().getTime();
            }
            // if the room doesn't have a last message, we sort it last
            return 1000000000;
        });
    }

    getRoomsCount(): number {
        return this.sortedRooms.length;
    }
}
