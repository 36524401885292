<app-modal (close)="confirm(null)">
    <div class="modal-body">
        <div class="container">
            <div *ngIf="beforeContentTitleImage" class="text-center">
                <img alt="" class="img-fluid w-100 illu" [src]="beforeContentTitleImage">
                <br/>
            </div>

            <h1 *ngIf="contentTitle" class="text-danger text-center">
                <app-translate [text]="contentTitle" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
            </h1>

            <p *ngIf="question.length > 0" class="infos w-100 text-center font-weight-bold">
                <app-translate [text]="question" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
            </p>

            <p *ngIf="sampleText.length > 0" class="infos w-100 text-center">
                <app-translate [text]="sampleText" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
            </p>
        </div>
    </div>

    <div class="modal-footer">
        <div *ngFor="let response of responses; let first = first;" class="">
            <button (click)="confirm(response.result)"
                    [ngClass]="{ 'btn-primary': (!first || responses.length === 1), 'btn-dark': (first && responses.length > 1) }"
                    class="btn btn-sm text-uppercase">
                {{ response.text | translate }}
            </button>
        </div>
    </div>
</app-modal>
