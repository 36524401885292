import {Injectable} from '@angular/core';
import {CamModel} from '../../models/cam-model/cam-model';

@Injectable({
    providedIn: 'root'
})
export class SexCamShowStepService {

    public static readonly STARTING = 0;
    public static readonly READY = 1;
    public static readonly REQUIRE_CONFIRMATION = 2;

    private chatType = CamModel.FREE_SHOW;
    private currentStep = SexCamShowStepService.STARTING;
    private loading = true;
    private pendingConfirmation = false;
    private playing = false;

    init() {
        this.chatType = CamModel.FREE_SHOW;
        this.currentStep = SexCamShowStepService.STARTING;
        this.loading = true;
        this.pendingConfirmation = false;
        this.playing = false;
    }

    isLoading(): boolean {
        return this.loading;
    }

    isPendingConfirmation(): boolean {
        return this.pendingConfirmation;
    }

    isPlaying(): boolean {
        return this.playing;
    }

    nextStep() {
        this.playing = true;
        this.loading = false;

        ++this.currentStep;
    }

    requestPayedShow() {
        this.currentStep = SexCamShowStepService.STARTING;
    }

    requireConfirmationModal(): boolean {
        return this.currentStep > SexCamShowStepService.STARTING;
    }

    setLoading(status: boolean) {
        this.loading = status;
    }

    setPendingConfirmation(status: boolean) {
        this.pendingConfirmation = status;
    }

    setPlaying(status: boolean) {
        this.playing = status;
    }
}
