import {Injectable} from '@angular/core';
import * as DeviceDetector from 'device-detector-js';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {

    constructor() {
    }

    getDeviceData() {
        const userAgent = navigator.userAgent;
        const deviceDetector = new DeviceDetector();
        const deviceData: any = deviceDetector.parse(userAgent);

        return {
            language: typeof navigator.language !== 'undefined' ? navigator.language : null,
            screenResolution: {
                width: window.screen.width,
                height: window.screen.height
            },
            obsolete: !this.checkCompatibility(deviceData),
            userAgent
        };
    }

    checkCompatibility(browserData): boolean {
        if (!browserData.client) {
            return false;
        }

        let compatible = true;

        const browser = browserData.client.name;
        const version = parseInt(browserData.client.version.trim(), 0);

        switch (browser) {
            case 'Chrome':
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.chrome;
                break;
            case 'Opera' :
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.opera;
                break;
            case 'Firefox':
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.firefox;
                break;
            case 'Microsoft Edge':
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.edge;
                break;
            case 'IE':
            case 'Internet Explorer':
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.ie;
                break;
            case 'Safari':
                compatible = !isNaN(version) && version >= environment.browser.supportedVersion.safari;
                break;
            case 'Firefox Mobile':
                // compatible = !isNaN(version) && version >= environment.browser.supportedVersion.firefoxMobile;
                break;
            case 'Safari Mobile':
                // compatible = !isNaN(version) && version >= environment.browser.supportedVersion.safariMobile;
                break;
            case 'IE Mobile':
                // compatible = !isNaN(version) && version >= environment.browser.supportedVersion.ieMobile;
                break;
        }

        return compatible;
    }

    isCompatible(): boolean {
        return !this.getDeviceData().obsolete;
    }

}
