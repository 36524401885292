<div class="lightbox wow animated fadeIn show" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content d-flex justify-content-center" style="height: calc(100vh - 3.5rem);">

            <div class="modal-header">
                <h5 class="modal-titre text-truncate">
                    <i *ngIf="pendingActions.length" class="fas fa-exclamation-triangle"></i>
                    <i *ngIf="!pendingActions.length" class="fas fa-coins"></i> {{ (pendingActions.length ? 'you_have_pending_actions' : 'booster.credit_my_account') | translate }}
                </h5>
                <button (click)="close()" type="button" class="close"><i class="fas fa-times-circle"></i></button>
            </div>

            <!-- step 1 -->
            <div class="modal-body">
                <!-- @feature "pending actions" disabled
                <div class="container text-center">
                    <ng-container *ngIf="pendingActions.length && !isPurchaseStep">
                        {{ 'pending_action_modal_desc.part1' | translate }} 
                        <b>{{ 'pending_action_modal_desc.part2' | translate: {action: pendingActions.length} }}</b> 
                        <span class="whitespace">{{ 'pending_action_modal_desc.part3' | translate }}</span> 
                        <b>{{ 'pending_action_modal_desc.part4' | translate }}</b>

                        <ngx-slick-carousel class="carousel"
                                            #slick
                                            #slickModal="slick-carousel"
                                            [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let pendingAction of pendingActions" class="slide gallery mt-4">
                                <div class="position-relative" style="height: 100%; width: 100%; display: inline-block;">
                                    <div class="delete-photo text-right">
                                        <span (click)="delete(pendingAction)">
                                            <i [matTooltip]="'delete_pending_action' | translate"
                                               [class.fa-trash-alt]="deletedId !== pendingAction.getId()"
                                               [class.fa-spinner]="deletedId === pendingAction.getId()"
                                               [class.fa-spin]="deletedId === pendingAction.getId()"
                                               class="fas"></i>
                                        </span>
                                    </div>
                                    <div class="icon-action">
                                        <i [class.fa-kiss-wink-heart]="pendingAction.isKissAction()"
                                           [class.fa-envelope]="pendingAction.isMessageAction()"
                                           [matTooltip]="pendingAction.getMessage()"
                                           class="fas"></i>
                                    </div>

                                    <div class="img-container">
                                        <img *ngIf="pendingAction.getToProfile().getPhoto()" [src]="pendingAction.getToProfile().getPhoto()" class="img-fluid"/>
                                        <img *ngIf="!pendingAction.getToProfile().getPhoto()" src="assets/img/150x150/default_user_{{ pendingAction.getToProfile().gender }}.png" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>

                        <p *ngIf="coinsRequired" class="infos w-100 text-left font-weight-bold">{{ 'you_need_x_coins' | translate: {coins: coinsRequired} }}</p>
                    </ng-container>

                    <p *ngIf="!pendingActions.length && !isPurchaseStep" class="infos w-100 text-left font-weight-bold">{{ 'select_a_pack_of_your_choice' | translate }}</p>
                    <p *ngIf="isPurchaseStep" class="infos w-100 text-left font-weight-bold">{{ 'complete_the_form_below' | translate }}</p>
                    <hr>
                </div>
                -->

                <div [hidden]="!loaded || paymentStatus">
                    <iframe #iframe
                            id="redirectPdp"
                            [src]="paymentUrl | sanitize"
                            sandbox="allow-forms allow-scripts allow-popups allow-same-origin allow-modals allow-top-navigation allow-top-navigation-by-user-activation"
                            width="100%"
                            height="100%"
                            data-scrolling="no"
                            scrolling="no"
                            style="overflow:hidden; display:block;  height: 100%; border: none; min-height: 550px;"
                    ></iframe>
                </div>

                <div [hidden]="loaded || paymentStatus" id="error403">
                    <h2 class="well text-center">
                        {{ 'secure_payment_redirecting' | translate }}
                    </h2>

                    <div class="modal-body text-center">
                        <div id="spin">
                            <i class="fa fa-spinner fa-pulse fa-5x"></i>
                        </div>

                        <p class="mt-5">
                            {{ 'if_payment_page_broken' | translate }},
                            <a [href]="paymentDirectLink | sanitize" target="_blank">
                                {{ 'click_here_for_secured_page' | translate }}
                            </a>
                        </p>
                    </div>
                </div>

                <div *ngIf="paymentStatus" class="container text-center h-100">
                    <div class="row h-100">
                        <div *ngIf="paymentStatus === 'paymentAccepted'" class="col-12 h-100">
                            <br>
                            <h1>
                                <i class="far fa-smile text-success smiley"></i><br>Super !<br>{{ 'post_payment.payment_accepted' | translate }}
                            </h1>
                            <br>
                            <p>{{ 'post_payment.thanks_for_trust' | translate }}<br>{{ 'post_payment.payment_in_history' | translate }}</p>
                            <button (click)="close()" class="btn btn-primary btn-sm text-uppercase btn-block btn-block">{{ 'back' | translate }}</button>
                        </div>
                        <div *ngIf="paymentStatus === 'paymentRefused'" class="col-12 h-100">
                            <br>
                            <h1>
                                <i class="far fa-frown text-danger smiley"></i><br>Oupsssss !<br>{{ 'post_payment.payment_not_working' | translate }}
                            </h1>
                            <br>
                            <p>{{ 'post_payment.payment_refused' | translate }}<br>{{ 'post_payment.you_can_try_again' | translate }}</p>
                            <br>

                            <button (click)="tryAgain()" class="btn btn-primary btn-sm text-uppercase btn-block btn-block">
                                <i class="fas fa-undo mr-2"></i>{{ 'post_payment.new_attempt' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showModalFooter && !paymentStatus" class="modal-footer modal-mobile-footer">
                <div class="row w-100">
                    <div class="col-6 pl-0">
                        <div *ngIf="isPurchaseStep" (click)="step('previous')" class="btn btn-dark btn-sm text-uppercase btn-block">
                            <i class="fas fa-arrow-left"></i> {{ 'change_pack' | translate }}
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <div *ngIf="!isPurchaseStep" (click)="step('next')" class="btn btn-primary btn-sm text-uppercase btn-block">
                            {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

