import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentUserService} from '../../services/user/current-user.service';
import {SexCamsService} from '../../services/sex-cams/sex-cams.service';
import {CamModel} from '../../models/cam-model/cam-model';
import {SexCamsFilters} from '../../models/account/settings/sex-cams-filters';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-sex-cams',
    templateUrl: './sex-cams.component.html',
    styleUrls: ['./sex-cams.component.scss']
})
export class SexCamsComponent implements OnInit, OnDestroy {

    private loading = false;
    private refreshTimeout;

    tab: string = null;

    currentPage = 1;

    total: any = {};

    constructor(
        private currentUser: CurrentUserService,
        private sexCamsService: SexCamsService,
        private route: ActivatedRoute
    ) {
        this.sexCamsService.currentCamModel = null;

        this.sexCamsService.onSubmitObservable.subscribe(value => {
           if (value) {
               this.setTab(this.tab);
           }
        });

        this.route.queryParamMap.subscribe(param => {
            this.sexCamsService.currentCamModel = null;
            this.setTab(param.get('tab'));
        });
    }

    ngOnInit(): void {
        this.submit();
    }

    isGenderAllowed(gender: string): boolean {
        return this.currentUser.getAccount().getSexCamRule().genderAllowed.includes(gender);
    }

    get MALE(): string {
        return CamModel.MALE;
    }

    get FEMALE(): string {
        return CamModel.FEMALE;
    }

    get COUPLE(): string {
        return CamModel.COUPLE;
    }

    get TRANSGENDER(): string {
        return CamModel.TRANSGENDER;
    }

    get FAVORITES(): string {
        return 'favorites';
    }

    canDisplayModel(camModel: CamModel): boolean {
        if (camModel.isPrivateShow() && !camModel.allowsPeeping()) {
            return false;
        }
        if (this.isWatchingCam()) {
            return true;
        }

        return (this.isTab() || this.isTab(camModel.sex) || (this.isTab('favorites') && camModel.isFavorite));
    }

    getCurrentCamModel(): CamModel {
        return this.sexCamsService.currentCamModel;
    }

    getCamModels(): CamModel[] {
        return this.sexCamsService.camModels;
    }

    getCurrentPage(): number {
        return this.currentPage;
    }

    getFilters(): SexCamsFilters {
        return this.currentUser.getAccount().getSettings().sexCamFilters;
    }

    isWatchingCam(): boolean {
        return (this.sexCamsService.currentCamModel && this.sexCamsService.currentCamModel instanceof CamModel);
    }

    getMaxPage() {
        let total;
        switch (this.tab) {
            case this.MALE:
                total = this.total.male || 0;
                break;
            case this.FEMALE:
                total = this.total.female || 0;
                break;
            case this.COUPLE:
                total = this.total.couple || 0;
                break;
            case this.TRANSGENDER:
                total = this.total.trans || 0;
                break;
            case this.FAVORITES:
                total = this.total.favorites || 0;
                break;
            default:
                total = this.getTotal();
        }
        return Math.floor(total / SexCamsService.SEX_CAM_PER_PAGE) + 1;
    }

    getTotal(): number {
        let total = 0;
        if (this.isGenderAllowed(this.MALE) && this.total.male) {
            total += this.total.male;
        }
        if (this.isGenderAllowed(this.FEMALE) && this.total.female) {
            total += this.total.female;
        }
        if (this.isGenderAllowed(this.COUPLE) && this.total.couple) {
            total += this.total.couple;
        }
        if (this.isGenderAllowed(this.TRANSGENDER) && this.total.trans) {
            total += this.total.trans;
        }
        return total;
    }

    getSmiley() {
        return {
            o: '<i class="far fa-sad-tear"></i>'
        };
    }

    isLoading(): boolean {
        return this.loading;
    }

    hideLoader(): boolean {
        return this.sexCamsService.hideLoader;
    }

    isTab(tab: string = null): boolean {
        return this.tab === tab && !this.sexCamsService.currentCamModel;
    }

    loadMore(page) {
        this.currentPage = page;
        this.submit();
    }

    toggleFilters() {
        this.sexCamsService.showFilters = !this.sexCamsService.showFilters;
    }

    setTab(tab: string = null) {
        this.tab = tab;
        this.currentPage = 1;
        this.sexCamsService.camModels = null;
        this.currentUser.updateSettings();
        this.submit();
    }

    submit(count = SexCamsService.SEX_CAM_PER_PAGE) {
        this.loading = true;
        this.clearRefresh();

        let filters = this.getFilters().serialize();

        this.sexCamsService.countAll(filters).subscribe((result: any) => {
            this.total = result.data;
        }, () => {
            console.log('Error: cannot fetch models count.');
        });

        filters.online = 1;
        if (this.tab !== null) {
            if (this.tab === 'favorites') {
                filters = {favorites: 1};
            } else {
                filters.sexes = [this.tab];
            }
        }

        this.sexCamsService.fetchAll(count * this.currentPage, filters).then(fetchMoreModel => {
            if (fetchMoreModel > 0) {
                this.currentPage = 1;
                this.submit(fetchMoreModel);
            } else {
                this.loading = false;
                this.refreshTimeout = setTimeout(_ => this.refreshModel(), 5000);
            }
        });
    }

    onRemoveModel(camModelId: string) {
        const index = this.getCamModels().findIndex((camModel: CamModel) => camModel.getId() === camModelId);
        this.sexCamsService.camModels.splice(index, 1);
    }

    ngOnDestroy() {
        this.clearRefresh();
    }

    private refreshModel() {
        this.sexCamsService.refreshModel(this.getFilters().serialize()).then((data: any) => {
            if (data !== false) {
                this.total = data;
            }
            if (!this.loading) {
                this.clearRefresh();
                this.refreshTimeout = setTimeout(() => this.refreshModel(), 5000);
            }
        });
    }

    private clearRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }
}
