import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../../services/modal/modal.service';
import {LoaderService} from '../../../../services/loader/loader.service';
import {BillingService} from '../../../../services/billing/billing.service';

@Component({
    selector: 'app-unlover',
    templateUrl: './unlover.component.html',
    styleUrls: ['./unlover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnloverComponent implements OnInit {

    terms = true;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private loaderService: LoaderService,
        private billingService: BillingService
    ) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(true);

        this.billingService.canUpsellUnlover().subscribe((available) => {
            this.loaderService.setLoading(false);
            if (!available) {
                this.router.navigate(['/home']).then();
            }
        }, () => {
            this.loaderService.setLoading(false);
            this.router.navigate(['/home']).then();
        });
    }

    accept() {
        if (!this.terms) {
            this.modalService.openConfirmation({
                data: {
                    title: 'error',
                    question: 'accept_cgv',
                    responses: [
                        {text: 'ok', result: true},
                    ],
                    faClass: 'fa-lock',
                }
            }).afterClosed().subscribe();
            return;
        }

        this.loaderService.setLoading(true);

        this.billingService.upsellUnlover().subscribe(success => {
            if (success) {
                this.router.navigate(['/upsell/unlover/payment-accepted']).then(() => {
                    this.loaderService.setLoading(false);
                });
            } else {
                this.router.navigate(['/upsell/unlover/payment-refused']).then(() => {
                    this.loaderService.setLoading(false);
                });
            }
        }, (error) => {
            this.router.navigate(['/upsell/unlover/payment-refused']).then(() => {
                this.loaderService.setLoading(false);
            });
        });
    }

    refuse() {
        this.router.navigate(['/home']).then();
    }
}
