import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Room} from '../../../models/chat/room/room';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatMessageService} from '../../../services/chat/chat-message/chat-message.service';
import {ChatTypingService} from '../../../services/chat/chat-typing/chat-typing.service';
import {ChatEmojiService} from '../../../services/chat/chat-emoji/chat-emoji.service';
import {ChatService} from '../../../services/chat/chat.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
// import {PendingActionService} from '../../../services/pending-action/pending-action.service';
// import {PendingAction} from '../../../models/pending-action/pending-action';
import {CoinWarningComponent} from '../../modal/coin-warning/coin-warning.component';
import {CoinService} from '../../../services/coin/coin.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-chat-message-form',
    templateUrl: './chat-message-form.component.html',
    styleUrls: ['./chat-message-form.component.scss']
})
export class ChatMessageFormComponent implements OnInit {

    @Input() isMobile = false;
    @ViewChild('message') messageElement: ElementRef;

    messages = {};

    toggleEmojisSubject: Subject<void> = new Subject<void>();

    constructor(
        public chatRoomService: ChatRoomService,
        public chatService: ChatService,
        private chatMessageService: ChatMessageService,
        private currentUserService: CurrentUserService,
        private chatTypingService: ChatTypingService,
        private chatEmojiService: ChatEmojiService,
        private router: Router,
        protected dialog: MatDialog,
        protected coinService: CoinService,
        // private pendingActionService: PendingActionService
    ) {}

    ngOnInit(): void {
        this.messages = this.chatService.messages;

        this.chatRoomService.room.subscribe(() => {
            setTimeout(() => {
                this.messageElement.nativeElement.focus();
            }, 0);
        });
    }

    sendMessage() {
        const room: Room = this.chatRoomService.getCurrentRoom();
        const messageId = this.chatService.getMessageId(room);
        const message: string = this.messages[messageId];

        if (!message || message.length === 0) {
            return;
        }

        this.messages[messageId] = '';

        const currentUser = this.currentUserService.getAccount();

        if (!currentUser.activeSubscription() && this.currentUserService.hasRule('requireSubscription')) {
            if (this.currentUserService.getAccount().getRuleSet().isCoin()) {
                this.dialog.open(CoinWarningComponent, {data: {offer: this.coinService.getMessageOffer()}});
                /* @feature "pending actions" disabled
                this.pendingActionService.create(
                    PendingAction.MESSAGE_TYPE,
                    room.getFriendUser().getUid(),
                    room.getFriendUser().getId(),
                    message
                ).subscribe((response: any) => {
                    this.pendingActionService.pendingActions.push(new PendingAction().deserialize(response.data));
                });
                */
            } else {
                this.router.navigate(['/payment'], {queryParams: {
                    action: 'chat_message',
                    profileId: room.getFriendUser().getUid()
                }}).then();
            }
            return;
        }

        this.chatMessageService.sendMessage(room, message);
        this.noTyping();
    }

    typing(event) {
        this.chatTypingService.startTyping(event);
    }

    noTyping() {
        this.chatTypingService.clearTyping();
    }

    replaceShortcutByEmoji() {
        const room: Room = this.chatRoomService.getCurrentRoom();
        const messageId = this.chatService.getMessageId(room);
        this.messages[messageId] = this.chatEmojiService.replaceShortcutByEmoji(this.messages[messageId]);
    }

    toggleEmojis() {
        this.toggleEmojisSubject.next();
    }

    onContextMenu(event: MouseEvent) {
        // do not allow right click
        event.preventDefault();
    }

    onPaste(event: ClipboardEvent) {
        event.preventDefault();
    }
}
