<app-modal>
    <div class="modal-body" style="min-height:254px;">
        <div *ngIf="step === 1" class="container" class="container verif-tel">
            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'mobile_conf.receive' | translate }}</span>, {{ 'mobile_conf.you_must' | translate }}
            </p>

            <app-mobile-form (form)="updateData($event)"></app-mobile-form>
        </div>

        <div *ngIf="step === 2" class="container verif-tel">
            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'mobile_conf.input_code' | translate }}</span>, {{ 'mobile_conf.received_from' | translate }}
            </p>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-text monemail"><i class="fas fa-mobile-alt"></i></span>
                    <input [(ngModel)]="activationCode" type="number" maxlength="6" class="form-control form-control-lg" [placeholder]="'mobile_conf.sms_code' | translate">
                </div>
            </div>
            <hr>
            <p class="infos w-100 text-center">
                <small *ngIf="timing == 0 && !dailyRestriction">
                    {{ 'mobile_conf.no_sms' | translate }}<br>
                    <a (click)="updateMobile()" class="text-primary">{{ 'click_here' | translate }}</a> {{ 'mobile_conf.receive_another' | translate }}
                </small>
                <span *ngIf="timing > 0" class="no-sms-text-space"></span>
            </p>
            <p class="infos w-100 text-center">
                <small>
                    {{ 'mobile_conf.wrong_num' | translate: replace('fullMobile')}}<br>
                    <a (click)="back()" class="text-primary">{{ 'click_here' | translate }}</a> {{ 'mobile_conf.input_another' | translate }}
                </small>
            </p>
        </div>

        <div *ngIf="step === 3">
            <h4 class="h6 w-100 text-center my-2 font-weight-bold">
                <span class="mb-1">{{ 'mobile_conf.confirmed' | translate }}</span>
                <br>
                <span>{{ 'mobile_conf.settings' | translate }}</span>
            </h4>
        </div>
    </div>

    <div class="modal-footer">
        <button *ngIf="step === 1 || step === 2" [mat-dialog-close]="true" class="btn btn-dark btn-sm mr-2 text-uppercase" [ngClass]="{'btn-xs-min': timing > 0}">
            {{ 'cancel' | translate }}
        </button>
        <a *ngIf="step === 1 && timing == 0" (click)="updateMobile()" [class.form-disabled]="formDisabled || !hasMobileNumber() || dailyRestriction" class="btn btn-primary btn-sm text-uppercase">
            <span *ngIf="formDisabled">
                <i class="fa fa-spinner fa-pulse fa-1x"></i>
            </span>
            <span>{{ 'send' | translate }}</span>
        </a>
        <a *ngIf="(step === 1 || step === 2) && !formDisabled && timing > 0 && timingMessage" class="btn btn-primary btn-sm text-uppercase disabled">
            <span>{{ timingMessage }}</span>
        </a>

        <a *ngIf="step === 2 && timing == 0" (click)="checkMobile()" [class.form-disabled]="!validActivationCode()" class="btn btn-primary btn-sm text-uppercase">
            <span>{{ 'validate' | translate }}</span>
        </a>
        <a *ngIf="step === 3" [mat-dialog-close]="true" class="btn btn-primary btn-sm text-uppercase">
            <i class="fas fa-check"></i>
            <span>{{ 'ok' | translate }}</span>
        </a>
    </div>
</app-modal>
