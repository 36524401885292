<div class="card-header text-center" *ngIf="booster">
    <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
</div>
<div class="card card-profile text-center">
    <div *ngIf="!profile.getActivity().isOffline() && !profile.getWebcamId()"
         class="badge badge-success enligne">{{ 'online' | translate }}</div>
    <div *ngIf="profile.getActivity().isOffline() && !profile.getWebcamId()"
         class="badge badge-secondary enligne">{{ 'offline' | translate }}</div>
    <div *ngIf="profile.getWebcamId()" class="badge badge-success enligne">{{ 'live' | translate }}</div>
    <div class="profil wrapper">
        <div class="wrappercontent">
            <a (click)="goTo('profile')" class="itemsprofil">
                <img (load)="onLoadImage()" *ngIf="profile.getPhoto() && !(this.profile.getWebcamId() || this.showVisited)"
                     [src]="profile | userImage: '250x250'"
                     [error-src]="profile.getGender()" [error-src-size]="250" class="card-img-profile"/>
                <img (load)="onLoadImage()" *ngIf="profile.getPhoto() && (this.profile.getWebcamId() || this.showVisited)"
                     [src]="profile.getPhoto()"
                     [error-src]="profile.getGender()" [error-src-size]="250" class="card-img-profile"/>
                <img (load)="onLoadImage()" *ngIf="!profile.getPhoto()" src="assets/img/250x250/default_user_{{ profile.getGender() }}.png"
                     class="card-img-profile"/>

                <span *ngIf="profile.isCertified()" class="icon-certified"><i class="fas fa-certificate"></i><i class="fas fa-check"></i></span>
                <div *ngIf="profile.isCertified()" class="badge badge-certified">{{ 'certified' | translate }}</div>

                <span *ngIf="profile.getPhotoCount()" class="badge nb-photos"><i class="fas fa-camera" aria-hidden="true"></i> {{ profile.getPhotoCount() }}</span>
            </a>
        </div>
    </div>
    <div class="card-body">
        <a (click)="goTo('profile')" class="itemsprofil">
            <h4 class="card-title">
                <i class="mr-1 fas"
                   [ngClass]="{'fa-mars': profile.getGender() === 1, 'fa-venus': profile.getGender() === 2}"></i> <span
                [class.blurred]="hasRule('nicknameBlur')" class="d-none d-lg-inline">{{ profile.getNickname() | maxStringSize:15 }}</span>
                <span [class.blurred]="hasRule('nicknameBlur')" class="d-lg-none">{{ profile.getNickname() | maxStringSize:7 }}</span>
                <br>
                <small *ngIf="!profile.getWebcamId()" [class.blurred]="hasRule('detailBlur')" class="text-muted">
                    {{ profile.getAge() }} <span *ngIf="profile.getAge()">{{ 'years_old' | translate }}</span>
                    <span class="d-none d-lg-inline" *ngIf="profile.getCity()">
                        <i class="fas fa-map-marker-alt ml-2"></i> {{ profile.getCity() | maxStringSize:11 }}
                    </span>
                    <span class="d-lg-none" *ngIf="profile.getCity()">
                        <i class="fas fa-map-marker-alt ml-2"></i> {{ profile.getCity() | maxStringSize:6 }}
                    </span>
                </small>
                <small *ngIf="profile.getWebcamId()" class="text-muted">{{ 'online_model' | translate }}</small>
            </h4>
        </a>
        <p class="text-muted small" *ngIf="showVisited">{{ 'search_appearance.visited_at' | translate:{
            date: profile.visitedAt | date:'dd/MM',
            time: profile.visitedAt | date:'HH:mm'
        } }}</p>
        <div class="card-links">
            <a *ngIf="!profile.getWebcamId()" (click)="goTo('chat')" [title]="'menu.chat' | translate"
               [ngClass]="{'btn-defaut': !booster, 'btn-primary': booster}" class="btn action w-50 d-none d-inline-block">
                <i class="fas fa-comment-alt" [matTooltip]="'menu.chat' | translate"></i>
                <span class="d-none d-xl-inline text-uppercase"> {{ 'menu.chat' | translate }}</span>
            </a>
            <a *ngIf="!profile.getWebcamId()" (click)="goTo('profile')" [title]="'menu.profile' | translate"
               [ngClass]="{'btn-defaut': !booster, 'btn-primary': booster}" class="btn action w-50">
                <i class="fas fa-user" [matTooltip]="'menu.profile' | translate"></i> <span class="d-none d-xl-inline text-uppercase">{{ 'menu.profile' | translate }}</span>
            </a>

            <a *ngIf="profile.getWebcamId()" (click)="goTo('sex-cams')" [title]="'menu.chat' | translate"
               class="btn btn-defaut action w-100">
                <img [matTooltip]="'menu.chat' | translate" alt="webcam" class="ico-live" src="assets/img/common/512x512/webcam.svg"> <span
                class="d-none d-xl-inline">{{ 'go_live' | translate }}</span>
            </a>
        </div>
        <div class="infinite-trigger"></div>
    </div>
</div>
