<div class="alert alert-info notification-confirmation">
    <div class="container-fluid">
        <a (click)="close()" class="close" data-dismiss="alert" aria-label="close" title="close">
            <i class="fas fa-times"></i>
        </a>
        <div class="row no-gutters">
            <a (click)="openMobileConfirmation()" href="" data-toggle="modal" class="mr-2"><i
                class="fas fa-exclamation-circle"></i> <span
                class="picto-margin">{{ 'phone_confirm' | translate }}</span></a><span>{{ 'confirm_desc' | translate }}</span>
        </div>
    </div>
</div>
