<ng-container *ngFor="let translation of translations">
    <span *ngIf="!isNickname(translation)" [innerHTML]="translation"></span>

    <a *ngIf="isNickname(translation) && linkNickname"
       [routerLink]="['/profile', profile.getId()]"
       [innerHTML]="formatNickname()"></a>

    <span *ngIf="isNickname(translation) && !linkNickname"
          [innerHTML]="formatNickname()"></span>
</ng-container>
