import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {Serializable} from '../../serializable/serializable';
import {CamModel} from '../../cam-model/cam-model';

@Injectable({
    providedIn: 'root'
})
export class SexCamsFilters implements Serializable, Deserializable {

    ages: string[] = [];
    cups: string[] = [];
    figures: string[] = [];
    hairs: string[] = [];
    languages: string[] = [];
    hd = true;
    piercing = false;
    tattoo = false;
    chatTypes = [];

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    hasSmallTit(): boolean {
        return this.cups.length && this.cups.includes('A') || this.cups.includes('B');
    }

    setSmallTit() {
        if (this.hasSmallTit()) {
            if (this.cups.includes('A')) {
                this.cups.splice(this.cups.indexOf('A'), 1);
            }
            if (this.cups.includes('B')) {
                this.cups.splice(this.cups.indexOf('B'), 1);
            }
        } else {
            if (!this.cups.includes('A')) {
                this.cups.push('A');
            }
            if (!this.cups.includes('B')) {
                this.cups.push('B');
            }
        }
    }

    hasBigTit(): boolean {
        return this.cups.length && this.cups.includes('C') || this.cups.includes('D') || this.cups.includes('E') || this.cups.includes('F');
    }

    setBigTit() {
        if (this.hasBigTit()) {
            if (this.cups.includes('C')) {
                this.cups.splice(this.cups.indexOf('C'), 1);
            }
            if (this.cups.includes('D')) {
                this.cups.splice(this.cups.indexOf('D'), 1);
            }
            if (this.cups.includes('E')) {
                this.cups.splice(this.cups.indexOf('E'), 1);
            }
            if (this.cups.includes('F')) {
                this.cups.splice(this.cups.indexOf('F'), 1);
            }
        } else {
            if (!this.cups.includes('C')) {
                this.cups.push('C');
            }
            if (!this.cups.includes('D')) {
                this.cups.push('D');
            }
            if (!this.cups.includes('E')) {
                this.cups.push('E');
            }
            if (!this.cups.includes('F')) {
                this.cups.push('F');
            }
        }
    }

    hasSlimBody(): boolean {
        return this.figures.length && this.figures.includes('S') || this.figures.includes('M');
    }

    setSlimBody() {
        if (this.hasSlimBody()) {
            if (this.figures.includes('S')) {
                this.figures.splice(this.figures.indexOf('S'), 1);
            }
            if (this.figures.includes('M')) {
                this.figures.splice(this.figures.indexOf('M'), 1);
            }
        } else {
            if (!this.figures.includes('S')) {
                this.figures.push('S');
            }
            if (!this.figures.includes('M')) {
                this.figures.push('M');
            }
        }
    }

    hasPlumpBody(): boolean {
        return this.figures.length && this.figures.includes('L') || this.figures.includes('XL');
    }

    setPlumpBody() {
        if (this.hasPlumpBody()) {
            if (this.figures.includes('L')) {
                this.figures.splice(this.figures.indexOf('L'), 1);
            }
            if (this.figures.includes('XL')) {
                this.figures.splice(this.figures.indexOf('XL'), 1);
            }
        } else {
            if (!this.figures.includes('L')) {
                this.figures.push('L');
            }
            if (!this.figures.includes('XL')) {
                this.figures.push('XL');
            }
        }
    }

    isYoung(): boolean {
        return this.hasAge('18*25');
    }

    setYoung() {
        this.addAge('18*25');
    }

    isOld(): boolean {
        return this.hasAge('35*45') || this.hasAge('45*55') || this.hasAge('55*99');
    }

    setOld() {
        this.addAge('35*45');
        this.addAge('45*55');
        this.addAge('55*99');
    }

    hasAge(age: string): boolean {
        return this.ages.length && this.ages.includes(age);
    }

    addAge(age: string) {
        if (this.hasAge(age)) {
            this.ages.splice(this.ages.indexOf(age), 1);
        } else {
            this.ages.push(age);
        }
    }

    hasBlondeHair(): boolean {
        return this.hairs.includes('blonde');
    }

    setBlondeHair() {
        if (this.hasBlondeHair()) {
            this.hairs.splice(this.hairs.indexOf('blonde'), 1);
        } else {
            this.hairs.push('blonde');
        }
    }

    hasBrownHair(): boolean {
        return this.hairs.includes('brunette');
    }

    setBrownHair() {
        if (this.hasBrownHair()) {
            this.hairs.splice(this.hairs.indexOf('brunette'), 1);
        } else {
            this.hairs.push('brunette');
        }
    }

    hasRedHair(): boolean {
        return this.hairs.includes('red');
    }

    setRedHair() {
        if (this.hasRedHair()) {
            this.hairs.splice(this.hairs.indexOf('red'), 1);
        } else {
            this.hairs.push('red');
        }
    }

    hasBlackHair(): boolean {
        return this.hairs.includes('black');
    }

    setBlackHair() {
        if (this.hasBlackHair()) {
            this.hairs.splice(this.hairs.indexOf('black'), 1);
        } else {
            this.hairs.push('black');
        }
    }

    hasPiercing(): boolean {
        return this.piercing;
    }

    togglePiercing() {
        this.piercing = !this.piercing;
    }

    hasTattoo(): boolean {
        return this.tattoo;
    }

    toggleTattoo() {
        this.tattoo = !this.tattoo;
    }

    isPrivateShow(): boolean {
        return this.chatTypes.includes(CamModel.PRIVATE_SHOW);
    }

    isVIPShow(): boolean {
        return this.chatTypes.includes(CamModel.VIP_SHOW);
    }

    setVIPShow() {
        if (this.isVIPShow()) {
            this.chatTypes.splice(this.chatTypes.indexOf(CamModel.VIP_SHOW));
        } else {
            this.chatTypes.push(CamModel.VIP_SHOW);
        }
    }

    setPrivateShow() {
        if (this.isPrivateShow()) {
            this.chatTypes.splice(this.chatTypes.indexOf(CamModel.PRIVATE_SHOW));
        } else {
            this.chatTypes.push(CamModel.PRIVATE_SHOW);
        }
    }

    serialize(): any {
        const filters: any = {};

        if (this.hd) {
            filters.hd = 1;
        }

        if (this.piercing) {
            filters.piercing = 1;
        }

        if (this.tattoo) {
            filters.tattoo = 1;
        }

        if (this.ages.length) {
            filters.ages = this.ages;
        }

        if (this.cups.length) {
            filters.cups = this.cups;
        }

        if (this.figures.length) {
            filters.figures = this.figures;
        }

        if (this.hairs.length) {
            filters.hairs = this.hairs;
        }

        if (this.languages.length) {
            filters.languages = this.languages;
        }

        if (this.chatTypes.length) {
            filters.chatTypes = this.chatTypes;
            if (filters.chatTypes.includes(CamModel.PRIVATE_SHOW)) {
                filters.allowVoyeurs = 1;
            }
        }

        return filters;
    }
}
