import {Injectable} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Album} from '../../../models/profile/album';
import {AskedMorePhotoNotificationType} from '../../../notifications/asked-more-photo-notification-type';

@Injectable({
    providedIn: 'root'
})
export class AlbumService {

    constructor(
        private http: HttpClient
    ) {}

    /**
     * Get all album profile
     * @param profile Profile
     * @param currentProfile bool
     * @return Observable<Profile>
     */
    findAll(profile: Profile, currentProfile: boolean): Observable<Profile> {
        return new Observable<Profile>(observer => {
            this.http.get(environment.backendUrl + '/album?profileId=' + profile.getId()).subscribe((response: any) => {
                if (response.success) {
                    profile.setAlbums(response.data);
                    observer.next(profile);
                } else {
                    observer.error('404 Album profile not found');
                }
            });
        });
    }

    /**
     * Create album profile
     * @param album Album
     * @param profile Profile
     * @return Observable<Object>
     */
    create(album: Album, profile: Profile) {
        return this.http.post(environment.backendUrl + '/album', {
            name: album.getName(),
            privacy: album.getPrivacy()
        });
    }

    /**
     * Edit album profile
     * @param album Album
     * @param profile Profile
     * @return Observable<Object>
     */
    update(album: Album, profile: Profile) {
        return this.http.put(environment.backendUrl + '/album/' + album.getId(), {
            name: album.getName(),
            privacy: album.getPrivacy()
        });
    }

    /**
     * Delete album profile
     * @param album
     * @param profile
     */
    delete(album: Album, profile: Profile) {
        return this.http.delete(environment.backendUrl + '/album/' + album.getId());
    }

    requestAlbumAccess(album: Album) {
        return this.http.post(environment.backendUrl + '/private-album-access', {
            albumId: album.getId()
        });
    }

    requestMorePhoto(profileId: number) {
        return this.http.post(environment.backendUrl + '/notification', {
            profileId,
            type: AskedMorePhotoNotificationType.TYPE
        });
    }
}
