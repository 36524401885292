<div class="container-fluid cover-upsell px-0">
</div>

<app-virilblue-layout class="upsell-3">
    <i class="far fa-check-circle text-success fleche-upsell" aria-hidden="true"></i>
    <div class="card-body p-4 p-sm-5 text-center">
        <h3 class="mb-0"><span class="text-success">C'est terminé ! 😊 </span><br><br>
            L'adresse de livraison de votre commande est enregistrée.<br class="d-none d-sm-block">
            Vous recevrez votre colis discret d'ici quelques jours à cette adresse.
        </h3>
        <br>
        <a [routerLink]="['/home']"
           class="btn btn-success btn-block btn-lg rounded-pill font-weight-bold shadow-sm py-3 text-uppercase"><i
            class="fas fa-hand-point-right mr-2" aria-hidden="true"></i>Retour au service</a>
    </div>
    <div class="card-footer text-center text-muted"><i class="fas fa-star mr-1" aria-hidden="true"></i><i
        class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i> Satisfait ou
        remboursé <i class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star mr-1" aria-hidden="true"></i><i
            class="fas fa-star" aria-hidden="true"></i></div>
</app-virilblue-layout>
