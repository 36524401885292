<div class="bg-white contact" [class.suspended]="profile.isSuspended()">
    <div *ngIf="profile.isSuspended()" class="badge badge-secondary enligne">
        {{ 'suspended' | translate }}
    </div>

    <div *ngIf="!profile.isSuspended()" [class.badge-success]="!profile.getActivity().isOffline()" [class.badge-secondary]="profile.getActivity().isOffline()" class="badge enligne">
        {{ (profile.getActivity().isOffline() ? 'offline' : 'online') | translate }}
    </div>

    <a [routerLink]="['/profile/' + profile.id]" class="img-contact">
        <img *ngIf="profile.photo" [src]="profile.photo" class="img-fluid w-100"/>
        <img *ngIf="!profile.photo" src="assets/img/150x150/default_user_{{ profile.gender }}.png" class="img-fluid w-100">
    </a>
    <div class="row d-flex h-100 align-items-center">
        <div class="col-12">
            <div class="titre-contact">
                <h4 class="card-title">
                    <a [routerLink]="['/profile/' + profile.id]">
                        <i class="fas" [ngClass]="{'fa-mars icon-male': profile.isMale(), 'fa-venus icon-female': profile.isFemale() }"></i> <span [class.blurred]="getRule('nicknameBlur', profile.nickname)">{{ profile.nickname }}</span>
                        <small class="text-muted"><ng-container *ngIf="profile.getAge()">{{ profile.getAge() }} {{ 'years_old' | translate }} </ng-container><i class="fas fa-map-marker-alt ml-2"></i> <span [class.blurred]="getRule('detailBlur', profile.city)">{{ profile.city }}</span></small>
                    </a>
                </h4>

                <p [class.blurred]="getRule('detailBlur', profile.presentation)" class="d-none d-sm-block font-weight-italic text-truncate">{{ profile.presentation || ' ' }}</p>

                <ul class="nav nav-tabs border-0 float-right">
                    <li class="nav-item">
                        <a (click)="favorite()" [matTooltip]="'contact.add_to_favorite' | translate" class="btn btn-dark btn-sm btn-fonction btn-contact mr-1">
                            <i [class.text-warning]="profile.getRelationship().getFavorite()" class="fas fa-star"></i>
                        </a>
                        <a [routerLink]="['/chat/' + profile.id]" [matTooltip]="'contact.send_private_message' | translate" class="btn btn-dark btn-sm btn-fonction btn-contact btn-tchat-desktop mr-1">
                            <i class="fas fa-comment-alt"></i>
                        </a>
                        <a [routerLink]="['/profile/' + profile.id]" [matTooltip]="'view_profile' | translate" class="btn btn-dark btn-sm btn-fonction btn-contact mr-1">
                            <i class="fas fa-user"></i>
                        </a>
                        <div class="dropdown d-inline-block plus-contact d-inline-block d-lg-none mr-1">
                            <a class="dropdown-toggle btn btn-dark btn-sm" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Plus</a>
                            <div class="dropdown-menu">
                                <a (click)="favorite()" class="dropdown-item">
                                    <i class="fas fa-star text-warning"></i> {{ (profile.getRelationship().getFavorite() ? 'contact.add_to_favorite' : 'contact.remove_from_favorite') | translate }}
                                </a>
                                <a [routerLink]="['/chat/' + profile.id]" class="dropdown-item">
                                    <i class="fas fa-comment-alt"></i> {{ 'contact.send_private_message' | translate }}
                                </a>
                                <a [routerLink]="['/profile/' + profile.id]" class="dropdown-item">
                                    <i class="fas fa-user"></i> {{ 'view_profile' | translate }}
                                </a>
                            </div>
                        </div>

                        <ng-container *ngIf="status !== 'pendingFriends' && status !== 'requestedFriends'">
                            <a *ngIf="!profile.getRelationship().getBlocked()" (click)="friend()" class="dropdown-toggle btn btn-sm btn-primary btn-amis">
                                <i [class.fa-plus]="!profile.getRelationship().getFriend()" [class.fa-check]="profile.getRelationship().getFriend()" class="fas"></i> {{ 'contact.friends' | translate }}
                            </a>
                            <a *ngIf="profile.getRelationship().getBlocked()" (click)="block()" class="text-light dropdown-toggle btn btn-sm btn-danger btn-amis">
                                <i class="fas fa-ban"></i> {{ 'blocked' | translate }}
                            </a>
                        </ng-container>

                        <a *ngIf="status === 'pendingFriends'" (click)="friend()" [matTooltip]="'cancel' | translate" class="btn btn-dark btn-sm btn-fonction mr-1">
                            <i class="fas fa-times text-danger"></i>
                        </a>

                        <a *ngIf="status === 'requestedFriends'" (click)="friendStatus(2)" [matTooltip]="'refuse' | translate" class="btn btn-dark btn-sm btn-fonction mr-1">
                            <i class="fas fa-times text-danger"></i>
                        </a>

                        <a *ngIf="status === 'requestedFriends'" (click)="friendStatus(1)" [matTooltip]="'accept' | translate" class="btn btn-dark btn-sm btn-fonction mr-1">
                            <i class="fas fa-check text-success"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
