import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Profile} from '../profile/profile';

@Injectable({
    providedIn: 'root'
})
export class Notification implements Deserializable {

    id: number = null;
    type: number = null;
    fromProfile: Profile = null;
    createdAt: Date = null;
    wasRead = false;
    metaData: any = {};
    deleted = false;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.fromProfile) {
            const profile = new Profile();
            profile.deserialize(input.fromProfile);
            this.fromProfile = profile;
        }
        return this;
    }

    getId() {
        return this.id;
    }

    getType(): number {
        return this.type;
    }

    getFromProfile(): Profile {
        return this.fromProfile;
    }

    getMetaData(): any {
        return this.metaData;
    }

    showButton(index: number): boolean {
        if (this.metaData && this.metaData.showButton && typeof this.metaData.showButton[index] !== 'undefined') {
            return this.metaData.showButton[index];
        }
        return true;
    }

    getWasRead(): boolean {
        return this.wasRead;
    }

    getCreatedAt(): Date {
        return this.createdAt;
    }

    delete() {
        this.deleted = true;
    }

    isDeleted(): boolean {
        return this.deleted;
    }
}
