import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SiteContentService} from '../../services/site/content/site-content.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentUserService} from '../../services/user/current-user.service';
import {Account} from '../../models/account/account';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-ad-space-display',
    templateUrl: './ad-space.component.html',
    styleUrls: ['./ad-space.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdSpaceComponent implements OnInit {

    @Input() ids: string;
    @Input() adClass = '';
    @Input() deleteBtn = true;
    @Input() refreshOnRouteChanged = false;
    alreadyRefresh = false;
    content: any = '';
    hidden = true;
    account: Account;
    deleteString: string;

    constructor(
        private siteContentService: SiteContentService,
        private currentUserService: CurrentUserService,
        private translate: TranslateService,
        private sanitizer: DomSanitizer,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.account = this.currentUserService.getAccount();
        this.deleteString = this.translate.instant('delete_ad');
        this.getAdSpace();
        this.router.events.subscribe(() => {
            if (this.refreshOnRouteChanged && !this.alreadyRefresh) {
                this.alreadyRefresh = true;
                this.getAdSpace();
            }
        });
    }

    showAdSpace(): boolean {
        return !this.hidden && !this.router.url.startsWith('/sex-cams');
    }

    getAdSpace() {
        this.siteContentService.getAdSpace(this.ids, this.isMobile()).then((html: string) => {
            if (html !== '') {
                this.content = this.siteContentService.replaceTag(html);
                this.content = this.currentUserService.replaceTag(this.content);
                this.content = this.sanitizer.bypassSecurityTrustHtml(this.content);
                this.hidden = false;
            }
            this.alreadyRefresh = false;
        });
    }

    isMobile(): boolean {
        return window.innerWidth < 992;
    }
}
