import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';
import {Notification} from '../models/notification/notification';

export class NicknameChangeNotificationType extends AbstractNotificationType {

    static readonly TYPE = 7;

    protected buttons:NotificationButton[] = [];

    setNotification(notification: Notification) {
        super.setNotification(notification);
        this.buttons.push(
            new NotificationButton('resend_confirmation_email', () => {
                if (this.notification.metaData.nickname) {
                    this.currentUserService.sendCode('nickname', this.notification.metaData.nickname).subscribe(() => {
                        this.goTo('pending-confirmation/nickname/' + this.notification.metaData.nickname);
                    }, () => {});
                } else {
                    this.goTo('account', null, 'account');
                }
            })
        )
    }

    getIcon(): string {
        return 'fa-file-alt';
    }
}
