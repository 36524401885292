import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {User} from '../user/user';
import {Room} from '../room/room';

@Injectable({
    providedIn: 'root'
})
export class Message implements Deserializable {

    private messageId: number;

    private user: User;

    private room: Room;

    private message: string;

    private time: Date;

    private notify = false;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.time = new Date(input.time || Date.now());

        if (parseInt(input.messageId, 0) > 0) {
            this.setMessageId(input.messageId);
        }

        return this;
    }

    setMessageId(messageId: number) {
        this.messageId = messageId;
    }

    getMessageId(): number {
        return this.messageId;
    }

    getUser(): User {
        return this.user;
    }

    getMessage(): string {
        return this.message;
    }

    getTime(): Date {
        return this.time;
    }

    setNotify(notify: boolean) {
        this.notify = notify;
    }

    isNotify(): boolean {
        return this.notify;
    }

    setRoom(room: Room) {
        this.room = room;
    }

    getRoom(): Room {
        return this.room;
    }
}
