import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class CamMessage implements Deserializable {

    public static readonly TYPE_TEXT = 'TEXT';
    public static readonly TYPE_ONE2ONE_REQUESTED = 'ONE2ONE_REQUESTED';
    public static readonly TYPE_ONE2ONE_DENIED = 'ONE2ONE_DENIED';
    public static readonly TYPE_ONE2ONE_STARTED= 'ONE2ONE_STARTED';
    public static readonly TYPE_ONE2ONE_STOPPED = 'ONE2ONE_STOPPED';
    public static readonly TYPE_MUTED = 'MUTED';
    public static readonly TYPE_KICKED = 'KICKED';

    nickname: string;
    type: string;
    message: string;
    publicVisibility: boolean;
    isVisitor: boolean;
    createdAt: Date;

    deserialize(message: Map<string, string>): this {
        this.nickname = message.get('SENDER_NICKNAME');
        this.publicVisibility = message.get('ROOM_TYPE') === 'PUBLIC';
        this.isVisitor = message.get('SENDER_ROLE') === 'VISITOR';
        this.createdAt = new Date();
        this.type = message.get('MESSAGE_TYPE');

        switch (this.type) {
            case CamMessage.TYPE_TEXT:
                this.message = message.get('TEXT');
                break;
            case CamMessage.TYPE_ONE2ONE_REQUESTED:
                this.message = 'cam_message.private_request';
                break;
            case CamMessage.TYPE_ONE2ONE_DENIED:
                this.message = 'cam_message.private_denied';
                break;
            case CamMessage.TYPE_ONE2ONE_STARTED:
                this.message = 'cam_message.private_started';
                break;
            case CamMessage.TYPE_ONE2ONE_STOPPED:
                this.message = 'cam_message.private_stopped';
                break;
            case CamMessage.TYPE_MUTED:
                this.message = 'cam_message.muted';
                break;
            case CamMessage.TYPE_KICKED:
                this.message = 'cam_message.kicked';
                break;
            default:
                console.error('Message type not implemented: ', message.get('MESSAGE_TYPE'));
        }

        return this;
    }

    isPublic(): boolean {
        return this.publicVisibility;
    }

    isPrivate(): boolean {
        return !this.publicVisibility;
    }

    isText(): boolean {
        return this.type === CamMessage.TYPE_TEXT;
    }
}
