import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage/storage.service';
import {SiteService} from '../../services/site/site.service';

/**
 * Unused anymore > see if we should just delete it or keep it around in case of
 */
@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

    currentLocale = {
        countryCode: 'FR',
        languageCode: 'fr'
    };

    constructor(
        private translateService: TranslateService,
        private storageService: StorageService,
        private site: SiteService
    ) {}

    ngOnInit() {
        this.initCurrentLocale();
    }

    initCurrentLocale() {
        const countryCode = this.storageService.get('country');
        if (countryCode) {
            const index = this.site.getLocales().findIndex(locale => locale.countryCode === countryCode);
            this.setCurrentLocale(index);
        }
    }

    getLocales(): any[] {
        return this.site.getLocales();
    }

    setCurrentLocale(index) {
        this.currentLocale = this.getLocales()[index];
        this.translateService.use(this.currentLocale.languageCode);
        this.storageService.set('country', this.currentLocale.countryCode);
    }
}
