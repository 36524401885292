<app-modal (close)="onRefuse()">
    <div class="modal-body">
        <div class="container changer-photo" [class.xponsor-modal-message]="!show">
            <app-xponsor-unlover-choice *ngIf="show" (onSuccess)="successEmit($event)"></app-xponsor-unlover-choice>
            <app-xponsor-unlover-success *ngIf="!show && success"></app-xponsor-unlover-success>
            <app-xponsor-unlover-error *ngIf="!show && !success"></app-xponsor-unlover-error>
        </div>
    </div>

    <div class="modal-footer">
        <button *ngIf="show" (click)="onRefuse()" data-dismiss="modal" class="btn btn-dark btn-sm mr-2" [mat-dialog-close]="true">
            {{ 'xponsor.no_thanks' | translate }}
        </button>

        <button *ngIf="!show" class="btn btn-success btn-sm mr-2" [mat-dialog-close]="true">
            <i class="fas fa-check" aria-hidden="true"></i>
            <span>{{ 'close' | translate }}</span>
        </button>
    </div>
</app-modal>
