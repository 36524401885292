import {Component, Inject, Injector, OnInit, Optional} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-email-confirmation',
    templateUrl: './email-confirmation.component.html',
    styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent extends BaseComponent implements OnInit {

    step = 1;
    activationCode: number;
    newEmail: string;
    formDisabled: boolean;

    constructor(
        injector: Injector,
        private toastr: ToastrService,
        private translate: TranslateService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(injector);
        this.formDisabled = false;
    }

    ngOnInit() {
        this.newEmail = this.account.getEmail();
    }

    getUrlWebMail() {
        const domain = this.account.getEmail().split('@')[1];

        switch (domain) {
            case 'online.fr':
                return 'https://webmail.online.net/';
            case 'free.fr':
                return 'https://webmail.free.fr';
            case 'aliceadsl.fr':
                return 'https://webmail.aliceadsl.fr/';
            case 'sfr.fr':
            case 'club-internet.fr':
            case 'numericable.fr':
                return 'https://webmail.sfr.fr/';
            case 'gmx.fr':
                return 'https://www.gmx.fr/';
            case 'laposte.net':
                return 'https://www.laposte.net/accueil';
            case 'facebook.com':
                return 'https://www.facebook.com';
            case 'yahoo.fr':
            case 'yahoo.it':
            case 'yahoo.com':
            case 'yahoo.ca':
            case 'rocketmail.com':
            case 'ymail.com':
            case 'geocities.com':
                return 'https://login.yahoo.com/';
            case 'orange.fr':
            case 'wanadoo.fr':
                return 'https://login.orange.fr/';
            case 'nordnet.fr':
                return 'https://www.nordnet.com/messagerie';
            case 'hotmail.com':
            case 'msn.com':
            case 'msn.fr':
            case 'hotmail.co.uk':
            case 'hotmail.fr':
            case 'hotmail.it':
            case 'hotmail.de':
            case 'hotmail.ca':
            case 'live.com':
            case 'live.fr':
            case 'live.ca':
            case 'live.be':
            case 'email.msn.com':
            case 'outlook.fr':
            case 'outlook.com':
                return 'https://outlook.live.com/mail/inbox';
            case 'aol.com':
            case 'aol.de':
            case 'aol.fr':
                return 'https://login.aol.com';
            case 'gmail.com':
            case 'googlemail.com':
                return 'https://mail.google.com';
            default:
                return null;
        }
    }

    updateEmail() {
        if (!this.newEmail) {
            return;
        }

        this.formDisabled = true;

        this.currentUserService.updateEmail(this.newEmail, () => {
            this.toastr.success(this.translate.instant('email_confirm_sent'));
        });
    }

    validActivationCode() {
        return (
            this.activationCode &&
            !isNaN(this.activationCode) &&
            this.activationCode > 99999 &&
            this.activationCode < 1000000
        );
    }

    checkEmail() {
        if (!this.validActivationCode()) {
            return;
        }

        this.currentUserService.checkEmail(this.activationCode).subscribe((response: any) => {
            if (response.success && typeof response.data.valid === 'undefined') {
                this.account.email = response.data.email;
                this.account.certifications.email = true;
                this.account.certifications.emailDate = new Date();
                this.step = 2;
            } else if (response.success && !response.data.valid) {
                const errorIndex = 'error_list.' + response.message;
                let translatedError = this.translate.instant(errorIndex);
                if (translatedError === errorIndex) {
                    translatedError = this.translate.instant('error_list.default');
                }
                this.toastr.error(translatedError);
            } else {
                const errorIndex = 'error_list.' + response.error;
                let translatedError = this.translate.instant(errorIndex);
                if (translatedError === errorIndex) {
                    translatedError = this.translate.instant('error_list.default');
                }
                this.toastr.error(translatedError);
            }
        }, () => {
            this.toastr.error(this.translate.instant('error_list.default'));
        });
    }

    sendEmailConfirmation() {
        if (this.formDisabled) {
            return;
        }

        this.formDisabled = true;

        this.currentUserService.sendEmailConfirmation().then(() => {
            this.formDisabled = false;
        }, () => {
            this.formDisabled = false;
        });
    }

    showChangeAndSendButton() {
        return (this.newEmail && this.newEmail != this.account.getEmail());
    }
}
