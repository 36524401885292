<div class="drop dropdown-toggle pull-right">
    <button class="btn btn-defaut" role="button" id="lang-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img class="flag-header" [src]="'/assets/img/countries/flag-' + currentLocale.countryCode + '.png'" alt="Country"/>
        <span id="text_lang">{{ 'country_list.' + currentLocale.countryCode | translate }} - {{ 'language_list.' + currentLocale.languageCode | translate }}</span>
        <span class="caret"></span>
    </button>

    <ul class="countries dropdown-menu" aria-labelledby="lang-btn">
        <li *ngFor="let locale of getLocales() ; let i = index" [attr.data-index]="i" role="menuitem">
            <a (click)="setCurrentLocale(i)">
                <img class="flag-header" [src]="'/assets/img/countries/flag-' + locale.countryCode + '.png'" alt="Country"/>
                {{ 'country_list.' + locale.countryCode | translate }} - {{ 'language_list.' + locale.languageCode | translate }}
            </a>
        </li>
    </ul>
</div>
