import {
    Component,
    DoCheck,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatService} from '../../services/chat/chat.service';
import {ChatRoomService} from '../../services/chat/chat-room/chat-room.service';
import {ChatUserService} from '../../services/chat/chat-user/chat-user.service';
import {ChatUserMessageService} from '../../services/chat/chat-user-message/chat-user-message.service';
import {UserMessage} from '../../models/chat/user-message/user-message';
import {CurrentUserService} from '../../services/user/current-user.service';
import {SocketService} from '../../services/socket/socket.service';

@Component({
    selector: 'app-chat-component',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, DoCheck {

    private chatUserMessageDiffer: KeyValueDiffer<string, any>;
    protected profileId: number;
    private loadingProfileRoom = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private chatService: ChatService,
        private chatRoomService: ChatRoomService,
        private chatUserService: ChatUserService,
        private chatUserMessageService: ChatUserMessageService,
        private currentUserService: CurrentUserService,
        private differs: KeyValueDiffers,
        private socketService: SocketService
    ) {
    }

    ngOnInit() {
        this.chatUserMessageDiffer = this.differs.find(this.chatService.getUserMessages()).create();
        this.profileId = +this.route.snapshot.paramMap.get('id');

        // unselect the previous room if we come from the wall while a "different" room was previously open
        // if we ask for the same room as before, obviously we want it to stay open
        if (this.profileId > 0 && (
            !this.chatRoomService.getCurrentRoom() ||
            this.chatRoomService.getCurrentRoom().getFriendUser().getUid() !== this.profileId
        )) {
            this.chatRoomService.unselectRoom();
        } else if (this.chatRoomService.getCurrentRoom()) {
            this.chatRoomService.setRoomRead(this.chatRoomService.getCurrentRoom());
        }
    }

    ngDoCheck(): void {
        const chatUserMessageChanges = this.chatUserMessageDiffer.diff(this.chatService.getUserMessages());

        if (chatUserMessageChanges && !this.chatUserMessageService.isShown()) {
            this.processUserMessage(this.chatService.shiftUserMessage());
        }

        if (this.chatService.isConnected() && !this.chatRoomService.getCurrentRoom() && !this.loadingProfileRoom) {
            if (this.profileId > 0) {
                if (this.chatRoomService.getLoadingProfileRoom()) {
                    this.loadingProfileRoom = true;

                    this.chatService.setMobileTabFromOthersPage();
                    this.chatService.createRoom(this.profileId);
                }
            }
        }
    }

    processUserMessage(userMessage: UserMessage) {
        if (!userMessage) {
            return;
        }

        this.chatUserMessageService.showMessage(userMessage, UserMessage.CONTEXT_CHAT)
            .then(() => {
                this.chatService.deleteUserMessage(userMessage);
                this.processUserMessage(this.chatService.shiftUserMessage());
            }).catch(() => {
        });
    }

    isMobile(): boolean {
        return window.innerWidth < 992;
    }

    isDisconnected(): boolean {
        return this.socketService.isDisconnected();
    }
}
