export class Gender {
    static get MALE(): number {
        return 1;
    }

    static get FEMALE(): number {
        return 2;
    }

    static get COUPLE(): number {
        return 3;
    }

    static get MALE_LABEL(): string {
        return 'male';
    }

    static get FEMALE_LABEL(): string {
        return 'female';
    }

    static get COUPLE_LABEL(): string {
        return 'couple';
    }
}
