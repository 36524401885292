<p class="infos w-100 text-center">
    <span *ngIf="!isCover()">
        {{ 'profile_picture.define_photo_profile' | translate }}
    </span>
    <span *ngIf="isCover()">
        {{ 'profile_picture.dress_up_profile' | translate }}
    </span>
    <br class="br-mobile"> {{ 'profile_picture.use_formula_bottom' | translate }}
</p>
<form>
    <div class="form-group files row d-flex align-items-center">
        <input (change)="fileChangeEvent($event)" type="file" class="form-control" multiple="">
        <div class="col-12">
            <i class="fas fa-download text-muted mb-2"></i>
            <p>{{ 'profile_picture.upload_picture_here' | translate }}</p>
            <span class="btn btn-light btn-lg text-uppercase">{{ 'profile_picture.upload_2' | translate }}</span>
        </div>
    </div>
    <small class="form-text text-muted">{{ 'profile_picture.file_max_weight' | translate }} / {{ 'profile_picture.file_authorized_formats' | translate }}</small>
</form>
<div class="alert alert-light mt-4">
    <small class="mt-2">
        <span class="text-success font-weight-bold"><i class="fas fa-check"></i> {{ 'authorized' | translate }}</span> {{ 'profile_picture.authorized' | translate }}<br>
        <span class="text-danger font-weight-bold"><i class="fas fa-times"></i> {{ 'not_authorized' | translate }}</span> {{ 'profile_picture.not_authorized' | translate }}
    </small>
</div>