<div class="row">
    <div class="col-lg-4">
        <app-certification-component></app-certification-component>
    </div>
    <div class="col-lg-8">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2>
                    <i class="fas fa-bullhorn mr-2 text-primary"></i>
                    <span>
                        <span class="d-none d-xl-inline-block">{{ 'settings.lp_authorized' | translate }}</span>
                        <span class="d-inline-block d-xl-none">{{ 'settings.lp_authorized_mobile' | translate }}</span>
                    </span>
                </h2>
            </div>
            <div class="card-body">
                <label>
                    <span>{{ 'settings.lp_authorized_desc_1' | translate }}&nbsp;</span>
                    <br class="d-none d-md-block">
                    <span>{{ 'settings.lp_authorized_desc_2' | translate }}</span>
                </label>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">
                        {{ 'settings.lp_authorized_data' | translate }}
                    </label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeLandingPageAuthorization()" [ngModel]="account.landingPageAuthorized" class="form-check-input" type="checkbox" checked="checked">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="pr-0">
                    <i class="fas fa-exclamation-triangle mr-2 text-primary"></i>
                    <span>{{ 'panic.settings.title' | translate }}</span>
                </h2>
            </div>
            <div class="card-body">
                <div class="form-group position-relative d-flex align-items-center">
                    <label class="font-weight-normal prefs checkprefs">{{ 'panic.settings.description' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="updatePanicMode()" [ngModel]="account.panicMode" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2>
                    <i class="fas fa-bell mr-2 text-primary"></i>
                    <span>{{ 'settings.preferences' | translate }}</span>
                </h2>
            </div>
            <div *ngIf="account && account.notifications" class="card-body">
                <div class="form-group">
                    <label>{{ 'settings.email_notif' | translate }}</label>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_visit_profile' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('visitProfile')" [ngModel]="account.notifications.visitProfile" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_friend' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('requestFriend')" [ngModel]="account.notifications.requestFriend" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_kiss' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('sendKiss')" [ngModel]="account.notifications.sendKiss" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_friend_login' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('friendConnection')" [ngModel]="account.notifications.friendConnection" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_new_message' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('newMessage')" [ngModel]="account.notifications.newMessage" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.when_promotion' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('promote')" [ngModel]="account.notifications.promote" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.get_free_offers' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('receivePartnerOffer')" [ngModel]="account.notifications.receivePartnerOffer" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.how_to' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('howTo')" [ngModel]="account.notifications.howTo" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.who_are_you' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('whoAreYou')" [ngModel]="account.notifications.whoAreYou" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>

                <div class="form-group position-relative">
                    <label class="font-weight-normal prefs checkprefs">{{ 'settings.site_activities' | translate }}</label>
                    <div class="form-check">
                        <label class="form-check-label form-check-toggle">
                            <input (change)="changeSettings('siteActivities')" [ngModel]="account.notifications.siteActivities" class="form-check-input" type="checkbox" checked="checked"><span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
