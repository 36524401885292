import {Component, OnInit} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-hardbounced-modal-trigger',
    templateUrl: './hardbounced-modal-trigger.component.html',
    styleUrls: ['./hardbounced-modal-trigger.component.scss']
})
export class HardbouncedModalTriggerComponent implements OnInit {

    emailChanged: boolean;
    newEmail: string;
    alreadyResendEmail: boolean;
    showHardbounceNotification = false;

    constructor(
        private currentUserService: CurrentUserService,
        private modalService: ModalService,
        private translate: TranslateService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit() {
        this.emailChanged = false;
        this.newEmail = '';

        if (this.currentUserService.isHardBounced()) {
            // this.showHardbouncedStep1();
            this.showHardbounceNotification = true;
        }
    }

    showHardbouncedStep1() {
        this.modalService.openFullScreenConfirmation({
            title: 'hardbounced.invalid_email_title',
            strongQuestion: true,
            sampleText:
                this.translate.instant('hardbounced.detect_invalid_email')
                + '<br/>'
                + this.translate.instant('hardbounced.must_use_personal_valid_email'),
            question: 'hardbounced.please_correct_your_email',
            responses: [
                {
                    input: {
                        icon: 'fa-envelope',
                        name: 'email',
                        type: 'email',
                        value: this.currentUserService.getAccount().getEmail(),
                        onChange: (event) => {
                            this.emailChanged = event.target.value !== this.currentUserService.getAccount().getEmail();
                            this.newEmail = event.target.value;
                        }
                    }
                },
                {
                    text: 'validate',
                    className: 'btn-primary',
                    control: () => {
                        return this.newEmail.length > 0 && this.isEmail(this.newEmail);
                    }
                },
                {
                    text: 'hardbounced.back_to_site',
                    className: 'btn-secondary',
                    result: {
                        action: 'close'
                    }
                }
            ],
            faClass: 'far fa-envelope',
            asyncValidationCallback: async (response) => {
                if (typeof response !== 'undefined' && typeof response.action !== 'undefined') {
                    if (response.action === 'close') {
                        return true;
                    }
                }

                const newEmail = this.newEmail;

                if (newEmail === this.currentUserService.getAccount().getEmail()) {
                    this.resendValidationEmail();
                    this.showHardbouncedStep2(newEmail);
                    return true;
                } else if (newEmail !== this.currentUserService.getAccount().getEmail() && this.isEmail(newEmail)) {
                    const success = await this.changeHardbouncedEmail(newEmail);

                    if (success) {
                        this.showHardbouncedStep2(newEmail);
                        return true;
                    }

                }
                return false;
            }
        });
    }

    changeHardbouncedEmail(newEmail: string) {
        return new Promise<any>((resolve, reject) => {
            this.currentUserService.updateEmail(newEmail, () => {
                resolve(true);
            });
        });
    }


    showHardbouncedStep2(newEmail: string) {
        this.modalService.openFullScreenConfirmation({
            title: newEmail + '<br/>' + this.translate.instant('hardbounced.is_waiting_validation'),
            strongQuestion: false,
            sampleText:
                this.translate.instant('hardbounced.we_send_you_verif_email')
                + '<br/>'
                + this.translate.instant('hardbounced.please_click_on_email_link')
                + '<br/>'
                + this.translate.instant('hardbounced.check_your_spam'),
            question: 'hardbounced.change_email_or_resend_confirmation',
            waitingAnimation: true,
            responses: [
                {
                    text: 'hardbounced.change_email',
                    className: 'btn-primary',
                    result: 'change_email'
                },
                {
                    text: 'hardbounced.resend_confirmation',
                    className: 'btn-light',
                    result: 'resend_confirmation',
                    control: () => {
                        return !this.alreadyResendEmail;
                    }
                }
            ],
            validationCallback: (response) => {
                if (response === 'change_email') {
                    this.showHardbouncedStep1();
                    return true;
                } else if (response === 'resend_confirmation') {
                    if (!this.alreadyResendEmail) {
                        this.resendValidationEmail();
                    }
                }

                return false;
            }
        });
    }

    resendValidationEmail() {
        this.currentUserService.sendCode('email', this.newEmail).subscribe((response: any) => {
            if (response && !response.success) {
                this.toastr.error(response.message);
            } else {
                this.toastr.success(this.translate.instant('hardbounced.we_send_you_verif_email'));
                this.alreadyResendEmail = true;

                setInterval(() => {
                    this.alreadyResendEmail = false;
                }, 60000);
            }
        }, () => {
            this.toastr.error(this.translate.instant('error_list.default'));
        });
    }

    isEmail(email: string) {
        return email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
    }

}
