<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center">
        <h1 class="mb-0"><i class="far fa-check-circle text-success" aria-hidden="true"></i> Bravo ! Votre profil est
            certifié et vérifié !
        </h1>
    </div>
</div>

<app-virilblue-layout>
    <i class="far fa-arrow-alt-circle-down text-primary fleche-upsell" aria-hidden="true"></i>
    <div class="card-body p-4 p-sm-5">
        <div class="row justify-content-center">
            <div class="col-12 text-center mb-4">
                <h3 class="mb-0"><span>Essayez <b>Viril<span class="blue">Blue</span></b></span><br>
                    Offre réservée aux nouveaux membres</h3>
                <div class="alert alert-light mt-3 mb-0">
                    <h4 class="text-uppercase my-3 font-weight-bold">
                        <del class="text-muted font-weight-normal">79,90<small>€</small></del>
                        <mark class="bg-success text-white px-3">64,90<small>€</small></mark>
                        <br> <span class="text-success">économisez 15€</span></h4>
                </div>
                <div class="badge badge-warning px-3 text-uppercase w-100 border-0">
                    <i class="fas fa-gift" aria-hidden="true"></i>
                    1 boîte offerte = 2 semaines de cure
                </div>
            </div>
            <div class="custom-control custom-checkbox mb-2 text-center">
                <input type="checkbox" class="custom-control-input checkbox" id="conditions" checked=""
                       [(ngModel)]="terms">
                <label class="custom-control-label" for="conditions"> J'accepte les <a href="#"
                                                                                       data-target="#cgu"
                                                                                       data-toggle="modal"
                                                                                       class="text-dark">conditions
                    générales de vente</a>.</label>
            </div>
            <div class="col-12 text-center">
                <a class="btn btn-success btn-block btn-lg rounded-pill font-weight-bold shadow-sm py-3 text-uppercase"
                   (click)="accept()"><i
                    class="fas fa-hand-point-right mr-2" aria-hidden="true"></i>Valider cette offre</a>
            </div>
            <div class="col-12 text-center mt-3 h6 refuse"><a (click)="refuse()">Refuser l'offre promotionnelle
                et
                accéder au service
            </a></div>
        </div>
    </div>
    <div class="card-footer text-center text-muted"><i class="fas fa-star mr-1" aria-hidden="true"></i><i
        class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i>
        SATISFAIT OU REMBOURSÉ <i class="fas fa-star mr-1" aria-hidden="true"></i><i
            class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i>
    </div>
</app-virilblue-layout>
