import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class FaqQuestion implements Deserializable {

    id: number;
    question: string;
    answer: string;
    order: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId(): number {
        return this.id;
    }

    getQuestion(): string {
        return this.question;
    }

    getAnswer(): string {
        return this.answer;
    }

    getOrder(): number {
        return this.order;
    }
}
