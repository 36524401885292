import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryService} from '../../../../services/country/country.service';
import {Country} from '../../../../models/country/country';
import {LoaderService} from '../../../../services/loader/loader.service';
import {Router} from '@angular/router';
import {BillingService} from '../../../../services/billing/billing.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-payment-accepted',
    templateUrl: './virilblue-payment-accepted.component.html',
    styleUrls: ['./virilblue-payment-accepted.component.scss']
})
export class VirilbluePaymentAcceptedComponent implements OnInit {

    form: FormGroup = null;
    submitted = false;
    countries: Country[] = [];

    constructor(
        private countryService: CountryService,
        private loaderService: LoaderService,
        private billingService: BillingService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(true);

        this.form = new FormGroup({
            lastname: new FormControl('', [Validators.required]),
            firstname: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required]),
            postalCode: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required]),
            country: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required])
        });

        this.countryService.getCountries().subscribe((countries: Country[]) => {
            this.countries = countries;
            this.loaderService.setLoading(false);
        });
    }

    get lastname() {
        return this.form.get('lastname');
    }

    get firstname() {
        return this.form.get('firstname');
    }

    get address() {
        return this.form.get('address');
    }

    get postalCode() {
        return this.form.get('postalCode');
    }

    get city() {
        return this.form.get('city');
    }

    get country() {
        return this.form.get('country');
    }

    get phone() {
        return this.form.get('phone');
    }

    submit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.loaderService.setLoading(true)

        this.billingService.upsellVirilblueForm(this.form.getRawValue()).subscribe(() => {
            this.router.navigate(['/upsell/virilblue/finish']).then(() => {
                this.loaderService.setLoading(false);
            });
        }, (error) => {
            console.error(error);
            this.loaderService.setLoading(false);
            this.toastr.error(this.translate.instant('post_payment.error'));
        });
    }
}
