<form class="navbar-form" role="search" (ngSubmit)="sendMessage()">
    <div class="input-group">
        <input #message class="form-control form-control-lg message-input disable-select" (contextmenu)="onContextMenu($event)"
               placeholder="{{ 'chat.set_message' | translate }}"
               autofocus="autofocus"
               [disabled]="!chatRoomService.getCurrentRoom().isEnabled()"
               (blur)="noTyping()"
               (keyup)="typing($event); replaceShortcutByEmoji()"
               (paste)="onPaste($event)"
               [(ngModel)]="chatService.messages[chatService.getMessageId(chatRoomService.getCurrentRoom())]"
               [ngModelOptions]="{standalone: true}"
        >

        <span class="input-group-btn">
            <button *ngIf="isMobile" (click)="toggleEmojis()" class="btn btn-link pr-0" type="button">
                <i class="fa fa-smile" aria-hidden="true"></i>
            </button>

            <button [ngClass]="{'btn-link': isMobile, 'btn-primary': !isMobile, 'p-3': !isMobile}" class="btn"
                [disabled]="!chatRoomService.getCurrentRoom().isEnabled()">
              <i [class.text-white]="!isMobile" class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
        </span>
    </div>
    <app-emoji [toggleEvent]="toggleEmojisSubject.asObservable()"></app-emoji>
</form>
