import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {StorageService} from '../../../services/storage/storage.service';
import {SiteService} from '../../../services/site/site.service';
import {Ebook} from '../../../models/ebook/ebook';

@Component({
    selector: 'app-ebook-page-component',
    templateUrl: './ebook-page.component.html',
    styleUrls: ['./ebook-page.component.scss']
})
export class EbookPageComponent implements OnInit {
    eBook: Ebook;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        const ebookId = +this.route.snapshot.paramMap.get('id');
        this.eBook = this.siteService.getSite().getEBooks().find(ebook => ebook.id === ebookId);

        if (!this.eBook) {
            this.router.navigate(['/ebooks']);
        }
    }

    imageUrl(id: number) {
        return `/assets/img/common/ebook/${this.eBook.id}.jpg`;
    }

    downloadFile() {
        const url = environment.backendUrl + '/ebook/' + this.eBook.id + '?auth_token=' + encodeURIComponent(this.storageService.get('token'));
        window.open(url);
    }
}
