<div class="row">
    <div class="col-12 text-center">
        <h1 class="mb-0 mt-4">
            <i class="fas fa-money-check text-primary ico-status"></i><br>
            <span class="d-none d-lg-block">{{ 'alternative_payment.check_payment' | translate }}</span>
            <span class="d-block d-lg-none">{{ 'alternative_payment.check_payment' | translate }}</span>
        </h1>
        <p class="lead">
            {{ 'alternative_payment.check_payment_title' | translate }}
        </p>
    </div>
</div>
<div class="row">
    <div class="col-lg-10 col-xl-7 mx-auto">
        <div *ngIf="offer" class="card box border-0">
            <div class="card-body">
                <p *ngIf="!offer.isLoveCoin()" class="text-center">
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_check' | translate : {renewalPeriod: '<b>6 ' + getMonthTranslated() + '</b>', price: '<b>84 €</b>'}"></span>
                        <br>
                    </span>
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_check' | translate : {renewalPeriod: '<b>3 ' + getMonthTranslated() + '</b>', price: '<b>57 €</b>'}"></span>
                        <br>
                    </span>
                    <br>
                    <span>{{ 'alternative_payment.check_to' | translate : {offer1: 57, offer2: 84} }} <b>Urbipay Sàrl</b></span>
                </p>
                <p *ngIf="offer.isLoveCoin()" class="text-center">
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_check_lc' | translate : {price: '<b>' + offer.getPrice() + '€</b>' }"></span>
                        <br>
                    </span>
                    <br>
                    <span>{{ 'alternative_payment.check_to_lc' | translate : {offer: offer.getPrice()} }} <b>Urbipay Sàrl</b></span>
                </p>
                <p class="text-center text-danger">
                    {{ 'alternative_payment.important_check' | translate }} <b>{{ account.getProfile().getId() }}</b>
                </p>
                <div class="row mb-2">
                    <div class="col-md-10 col-lg-8 mx-auto mt-2 mb-3 text-center ">
                        {{ 'alternative_payment.envelope' | translate }}
                        <br>
                        <div class="card shadow bg-light">
                            <div class="card-body">
                                <strong>Urbipay Sàrl<br>
                                    30 Rue Auguste Piccard<br>
                                    01630 Saint-Genis-Pouilly<br>
                                    France
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="alert alert-success mb-0 text-center">
                    <span *ngIf="!offer.isLoveCoin()">{{ 'alternative_payment.check_reception' | translate }}<br></span>
                    <span *ngIf="offer.isLoveCoin()">
                        <span>{{ 'alternative_payment.check_reception_lc' | translate : { loveCoin: offer.getCoinAmount()} }}</span>
                        <span *ngIf="offer.getCoinFreeAmount()"> {{ 'alternative_payment.check_reception_lc_bonus' | translate : { bonus: offer.getCoinFreeAmount()} }}</span>.
                        <br>
                    </span>
                    {{ 'alternative_payment.notification' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 col-md-9 mb-4 mx-auto text-center" style="margin-top:100px;">
        <div class="card assistance border-0">
            <div class="card-body d-flex justify-content-between align-items-center">
                <h3 class="h6 mb-0 w-100">
                    {{ 'alternative_payment.faq_consult' | translate }}
                </h3>
                <a [routerLink]="['/faq']" class="btn btn-sm btn-primary ml-auto">
                    {{ 'alternative_payment.faq_see' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
