import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Serializable} from '../serializable/serializable';

@Injectable({
    providedIn: 'root'
})
export class City implements Serializable, Deserializable {

    name: string;
    postalCode: string;
    country: string;
    latitude: number;
    longitude: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getName(): string {
        return this.name;
    }

    getPostalCode(): string {
        return this.postalCode;
    }

    getCountry(): string {
        return this.country;
    }

    getLatitude(): number {
        return this.latitude;
    }

    getLongitude(): number {
        return this.longitude;
    }

    getFullName(): string {
        if (!this.name) {
            return '';
        }

        let fullName = this.name;

        if (this.postalCode || this.country) {
            fullName += ' (';
            if (this.postalCode) {
                fullName += this.postalCode;
            }
            if (this.country) {
                if (this.postalCode) {
                    fullName += ', ';
                }
                fullName += this.country;
            }
            fullName += ')';
        }

        return fullName;
    }

    serialize(): object {
        return {
            name: this.name,
            postalCode: this.postalCode,
            country: this.country,
            latitude: this.latitude,
            longitude: this.longitude
        };
    }
}
