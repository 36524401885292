import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BoosterComponent} from '../booster.component';

@Component({
    selector: 'app-booster-photo',
    templateUrl: './booster-photo.component.html',
    styleUrls: ['./booster-photo.component.scss']
})
export class BoosterPhotoComponent {

    constructor(
        public dialogRef: MatDialogRef<BoosterComponent>,
        private router: Router
    ) {
    }

    linkProfile() {
        this.router.navigate(['/profile']);
        this.dialogRef.close();
    }
}
