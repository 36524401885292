import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Profile} from '../../../../models/profile/profile';
import {SiteService} from '../../../../services/site/site.service';
import {Site} from '../../../../models/site/site';
import {HelperService} from '../../../../services/helper/helper.service';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-profile-tab-component',
    templateUrl: './profile-tab.component.html'
})
export class ProfileTabComponent implements OnInit {

    constructor(private siteService: SiteService, private helper: HelperService, private currentUserService: CurrentUserService) {
    }

    @Output() save = new EventEmitter<any>();
    @Input() tab: string;
    @Input() profile: Profile;
    @Input() currentProfile: boolean;
    profileDraft: Profile;
    birthDate = {
        day: null,
        month: null,
        year: null
    };

    private static numberGenerator(min, max): number[] {
        const numberArray = [];

        for (let i = min; i <= max; i++) {
            numberArray.push(i);
        }

        return numberArray;
    }

    ngOnInit(): void {
        this.profileDraft = this.profile.clone();
    }

    get site(): Site {
        return this.siteService.getSite();
    }

    getCategories(): string[] {
        return this.siteService.getQuestionsAnswers().getCategories(this.tab);
    }

    hasCategory(category): boolean {
        return this.siteService.getQuestionsAnswers().hasCategory(this.tab, category);
    }

    getCategoryTitle(category: string) {
        const right = this.currentProfile ? 'edit' : 'view';
        return this.translateGender(`profile_question_category.${right}.${category}`);
    }

    translateGender(translationIndex: string): string {
        return this.helper.translateGender(this.profile, translationIndex);
    }

    getPresentationLabel(): string {
        let segmentLabel = 'default';

        if (this.site.isLove() || this.site.isSugar() || this.site.isFriend()) {
            segmentLabel = this.site.getSegmentLabel();
        }

        return this.translateGender(`profile.about.description_label_${segmentLabel}`);
    }

    getDays(): number[] {
        return ProfileTabComponent.numberGenerator(1, 31);
    }

    getMonths(): number[] {
        return ProfileTabComponent.numberGenerator(1, 12);
    }

    getYears(): number[] {
        return ProfileTabComponent.numberGenerator(1930, new Date().getFullYear() - 18);
    }

    init(category: string, saving = false) {
        const profileTarget: Profile = saving ? this.profile : this.profileDraft;
        const profile: Profile = saving ? this.profileDraft.clone() : this.profile.clone();

        if (category === 'description') {
            profileTarget.setPresentation(profile.getPresentation());
            profileTarget.setCountry(profile.getCountry());
            profileTarget.setCity(profile.getCity());
            profileTarget.setPostalCode(profile.getPostalCode());
            if (saving && this.isAdult() && this.birthDate.day && this.birthDate.month && this.birthDate.year) {
                profileTarget.setBirthDate(new Date(`${this.birthDate.year}-${this.birthDate.month}-${this.birthDate.day}`));
            }
        }
        if (category === 'search_criteria') {
            profileTarget.setSearchedGender(profile.getSearchedGender());
        }

        const questions = this.siteService.getQuestionsAnswers().getQuestions(this.tab, category);
        for (const questionName of questions) {
            const question = this.siteService.getQuestionsAnswers().getQuestion(this.tab, category, questionName);
            profileTarget.setAnswer(question.getId(), profile.getAnswers()[question.getId()]);
        }
    }

    onSave(category: string) {
        this.init(category, true);

        this.save.emit();
    }

    hasRule(rule: string): boolean {
        return !this.currentProfile && this.currentUserService.hasRule(rule, this.profile.getId());
    }

    showPhotoSlider(category: string): boolean {
        return !this.currentProfile && this.tab === 'about' && category === 'description';
    }

    isAdult(): boolean {
        const diff = Date.now() - new Date(
            parseInt(this.birthDate.year, 0),
            parseInt(this.birthDate.month, 0) - 1,
            parseInt(this.birthDate.day, 0)
        ).getTime();

        const ageDate = new Date(diff);

        return Math.abs(ageDate.getUTCFullYear() - 1970) >= 18;
    }
}
