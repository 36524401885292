import {Component, Inject, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-sex-cams-coin-warning',
    templateUrl: './sex-cams-coin-warning.component.html',
    styleUrls: ['./sex-cams-coin-warning.component.scss']
})
export class SexCamsCoinWarningComponent {

    message: string;
    detail: string;
    nickname: string;

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<SexCamsCoinWarningComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.message = data.message;
        this.detail = data.detail;
        this.nickname = data.nickname;
    }

    confirm(result) {
        this.dialogRef.close(result);
    }
}
