<!--<div class="row main profile">-->
    <div *ngIf="!loading">
        <div class="row my-4 statistiques" *ngIf="hasStats()">
            <div class="col-6 stats">
                <div class="card border-0 stats-shadow stats h-100">
                    <div class="card-body">
                        <a class="text-dark" (click)="showVisits('last_7days')">
                            <div class="metric-label badge badge-success"
                                 [ngClass]="{'badge-success':getVisitsEvolution() >= 0, 'badge-danger': getVisitsEvolution() < 0}">
                                <i class="fa fa-fw fa-arrow-up"
                                   [ngClass]="{'fa-arrow-up':getVisitsEvolution() >= 0, 'fa-arrow-down': getVisitsEvolution() < 0}"></i>{{ getVisitsEvolution() }}
                                %
                            </div>
                            <div class="row no-gutters h-100">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                    <div class="icon-qui">
                                        <img alt="" class="img-fluid qui-icon"
                                             src="assets/img/search-appearance/search-appearance-visit-icon.png">
                                    </div>
                                    <h5 class="mb-0 w-100 d-none d-lg-block">
                                        <span
                                            [innerHTML]="'search_appearance.members_has_visited' | translate:getCountTranslateHtml(getTotalVisitsCount())"></span>
                                        <br
                                            class="br-mobile">
                                        <span *ngIf="getNearVisitsCount()"
                                              [innerHTML]="'search_appearance.members_at_proximity' | translate:getCountTranslateHtml(getNearVisitsCount())"></span>
                                    </h5>
                                    <h5 class="mb-0 w-100 d-block d-lg-none">
                                        <span class="text-primary">{{ getTotalVisitsCount() }}</span>
                                    </h5>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-6 stats" data-toggle="tooltip" data-placement="top"
                 data-original-title="Votre profil est apparu dans 100 recherches">
                <div class="card border-0 stats-shadow stats h-100">
                    <div class="card-body">
                        <a class="text-dark" (click)="showSearchs('last_7days')">
                            <div class="metric-label badge badge-success"
                                 [ngClass]="{'badge-success':getSearchsEvolution() >= 0, 'badge-danger':getSearchsEvolution() < 0}">
                                <i class="fa fa-fw fa-arrow-up"
                                   [ngClass]="{'fa-arrow-up':getSearchsEvolution() >= 0, 'fa-arrow-down':getSearchsEvolution() < 0}"></i>{{ getSearchsEvolution() }}
                                %
                            </div>
                            <div class="row no-gutters h-100">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                    <div class="icon-qui">
                                        <img alt="" class="img-fluid qui-icon"
                                             src="assets/img/search-appearance/search-appearance-search-icon.png">
                                    </div>
                                    <h5 class="mb-0 w-100 d-none d-lg-block">
                                        <span
                                            [innerHTML]="'search_appearance.members_has_searched' | translate: getCountTranslateHtml(getTotalSearchsCount())"></span>
                                        <br class="br-mobile">
                                        <span *ngIf="getNearSearchsCount()"
                                              [innerHTML]="'search_appearance.members_at_proximity' | translate: getCountTranslateHtml(getNearSearchsCount())"></span>
                                    </h5>
                                    <h5 class="mb-0 w-100 d-block d-lg-none">
                                        <span class="text-primary">{{ getTotalSearchsCount() }}</span>
                                    </h5>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ul class="nav nav-tabs mb-2 onglets-profils mb-4" [class.blurred]="blurred">
            <li class="nav-item">
                <a class="nav-link" (click)="showVisits()" [class.active]="currentType === 'visit'">
                    <span class="d-none d-lg-inline-block">{{ 'search_appearance.who_has_visited_my_profile' | translate }}</span>
                    <span
                        class="d-inline-block d-lg-none">{{ 'search_appearance.who_has_visited_my_profile_mobile' | translate }}</span>
                    <span class="badge ml-2"
                          [ngClass]="{'badge-primary': currentType === 'visit', 'badge-secondary': currentType !== 'visit'}"
                    >{{ visitedCount | number:'1.0-0':'fr' }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="showSearchs()" [class.active]="currentType === 'search'">
                    <span class="d-none d-lg-inline-block">{{ 'search_appearance.who_has_searched_my_profile' | translate }}</span>
                    <span
                        class="d-inline-block d-lg-none">{{ 'search_appearance.who_has_searched_my_profile_mobile' | translate }}</span>
                    <span class="badge ml-2"
                          [ngClass]="{'badge-primary': currentType === 'search', 'badge-secondary': currentType !== 'search'}">
                        {{ searchedCount | number:'1.0-0':'fr' }}</span>
                </a>
            </li>
            <li class="nav-item ml-auto">
                <select class="form-control custom-select" (change)="changeDate()" [(ngModel)]="currentDate">
                    <option value="today">{{ 'today' | translate }}</option>
                    <option value="yesterday">{{ 'yesterday' | translate }}</option>
                    <option value="last_7days">{{ 'last_7days' | translate }}</option>
                    <option value="this_week">{{ 'this_week' | translate }}</option>
                    <option value="last_week">{{ 'last_week' | translate }}</option>
                    <option value="this_month">{{ 'this_month' | translate }}</option>
                    <option value="last_month">{{ 'last_month' | translate }}</option>
                </select>
            </li>
        </ul>

        <div *ngIf="!partialLoading" [class.blurred]="blurred">
            <div *ngIf="profiles[currentType].length === 0">
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <h1 class="text-uppercase vide noresulttitle">{{ 'search_appearance.no_visit' | translate }} <br
                            class="d-block d-sm-none">{{ 'search_appearance.no_visit_2' | translate }}</h1>
                        <p>{{ 'search_appearance.no_visit_3' | translate }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="card border-0 box mb-4">
                            <div class="card-body text-center">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="noresulttitle">{{ 'search_appearance.follow_advice' | translate }}
                                            :</h2>
                                    </div>
                                    <div
                                        class="col-lg-6 text-center border-right border-bottom p-5 col-certif uploadez">
                                        <img alt="" class="img-fluid w-100 certifier"
                                             src="assets/img/search-appearance/upload-photo.png"><br>
                                        <h2 class="noresulttitle">
                                            1. <span
                                            [innerHTML]="'search_appearance.upload_profile_photo' | translate | formatHtml:getHtmlTags()"></span>
                                        </h2>
                                        <p>{{ 'search_appearance.upload_profile_photo_text' | translate }}</p>
                                        <a [routerLink]="['/profile']"
                                           class="btn btn-lg btn-primary">{{ 'button_go' | translate }}</a>
                                    </div>
                                    <div class="col-lg-6 text-center border-bottom p-5 col-certif completez">
                                        <img alt="" class="img-fluid w-100 certifier"
                                             src="assets/img/search-appearance/fill-profile.png"><br>
                                        <h2 class="noresulttitle">2. <span
                                            [innerHTML]="'search_appearance.fill_your_profile' | translate | formatHtml:getHtmlTags()"></span>
                                        </h2>
                                        <p>{{ 'search_appearance.fill_your_profile_text' | translate }}</p>
                                        <a [routerLink]="['/profile']"
                                           class="btn btn-lg btn-primary">{{ 'button_go' | translate }}</a>
                                    </div>
                                    <div class="col-lg-6 text-center border-bottom border-right p-5 col-certif visitez">
                                        <img alt="" class="img-fluid w-100 certifier"
                                             src="assets/img/search-appearance/visit-profile.png"><br>
                                        <h2 class="noresulttitle">3. <span
                                            [innerHTML]="'search_appearance.visit_profiles' | translate | formatHtml:getHtmlTags()"></span>
                                        </h2>
                                        <p>{{ 'search_appearance.visit_profiles_text' | translate }}</p>
                                        <a [routerLink]="['/home']"
                                           class="btn btn-lg btn-primary">{{ 'button_go' | translate }}</a>
                                    </div>
                                    <div class="col-lg-6 text-center p-5 col-certif dialoguez">
                                        <img alt="" class="img-fluid w-100 certifier"
                                             src="assets/img/search-appearance/chat.png"><br>
                                        <h2 class="noresulttitle">4. <span
                                            [innerHTML]="'search_appearance.chat_members' | translate | formatHtml:getHtmlTags()"></span>
                                        </h2>
                                        <p>{{ 'search_appearance.chat_members_text' | translate }}</p>
                                        <a [routerLink]="['/chat']"
                                           class="btn btn-lg btn-primary">{{ 'button_go' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="profiles[currentType].length" class="row row-profils">
                <app-thumb-profile *ngFor="let profile of profiles[currentType]" [profile]="profile"
                                   class="col-xl-2 col-lg-3 col-sm-4 col-6 lesprofils"
                                   [showVisited]="true"></app-thumb-profile>

                <app-pagination-component *ngIf="profiles[currentType].length > 0" class="container-fluid mt-4"
                                          [class.d-none]="loading"
                                          [currentPage]="getCurrentPage()" [maxPage]="getMaxPage()"
                                          (pageChange)="changePage($event)">
                </app-pagination-component>
            </div>
        </div>

        <div *ngIf="partialLoading" class="loading">
            <i class="fa fa-spinner fa-pulse fa-4x"></i>
        </div>

        <div class="row h-100 qui-ko mx-0" *ngIf="blurred">
            <div class="col-lg-8  h-100 d-flex align-items-center text-center justify-content-center mx-auto">
                <div class="box bg-white">
                    <img alt="" class="img-fluid qui-icon"
                         src="assets/img/search-appearance/search-appearance-icon.png"><br>
                    <h1>{{ 'search_appearance.who_has_visited_my_profile' | translate }}</h1>
                    <p>{{ (account.getRuleSet().isSubscription() ? 'search_appearance.activate_access_who_has_visited_my_profile' : 'not_enough_lc') | translate }}</p>

                    <a *ngIf="account.getRuleSet().isSubscription()" (click)="redirectToPayment()"
                       data-toggle="tooltip" data-placement="top"
                       [title]="'search_appearance.activate_access' | translate"
                       class="btn btn-success btn-sm btn-access activer"><i class="fas fa-crown"></i> {{ 'search_appearance.activate_access' | translate }}</a>

                    <a *ngIf="account.getRuleSet().isCoin()" (click)="redirectToPayment()"
                       data-toggle="tooltip" data-placement="top"
                       [title]="'booster.credit_my_account' | translate"
                       class="btn btn-primary btn-sm cursor-pointer">{{ 'booster.credit_my_account' | translate }}</a>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="loading" class="loading">
        <i class="fa fa-spinner fa-pulse fa-4x"></i>
    </div>
<!--</div>-->
