import {Notification} from '../models/notification/notification';
import {Injector} from '@angular/core';
import {ActionService} from '../services/profile/action/action.service';
import {NotificationService} from '../services/notification/notification.service';
import {SiteService} from '../services/site/site.service';
import {Router} from '@angular/router';
import {ModalService} from '../services/modal/modal.service';
import {NotificationButton} from '../models/notification/notification-button';
import {CurrentUserService} from '../services/user/current-user.service';
import {MatDialog} from '@angular/material/dialog';

export abstract class AbstractNotificationType {

    protected notification: Notification = null;
    protected abstract buttons: NotificationButton[] = [];

    protected actionService: ActionService;
    protected notificationService: NotificationService;
    protected siteService: SiteService;
    protected router: Router;
    protected modalService: ModalService;
    protected dialog: MatDialog;
    protected currentUserService: CurrentUserService;

    constructor(private injector: Injector) {
        this.actionService = injector.get(ActionService);
        this.notificationService = injector.get(NotificationService);
        this.siteService = injector.get(SiteService);
        this.router = injector.get(Router);
        this.modalService = injector.get(ModalService);
        this.dialog = injector.get(MatDialog);
        this.currentUserService = injector.get(CurrentUserService);
    }

    getButtons(): NotificationButton[] {
        return this.buttons;
    }

    getText(): string {
        return 'notification_type.' + this.notification.type;
    }

    getIcon(): string {
        return null;
    }

    getImage(): string {
        return null;
    }

    getMetaData() {
        return {};
    }

    setNotification(notification: Notification) {
        this.notification = notification;
    }

    goTo(page, profileId = null, tab = null) {
        if (tab) {
            return this.router.navigate(profileId ? [page, profileId] : [page], {queryParams: {tab}});
        } else {
            return this.router.navigate((profileId ? [page, profileId] : [page]));
        }
    }

    openModal(title, sampleText, faClass = null) {
        return this.modalService.openConfirmation({
            data: {
                title,
                sampleText,
                responses: [{
                    text: 'close'
                }],
                faClass,
                profile: this.notification.getFromProfile()
            }
        });
    }
}
