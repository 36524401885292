<div class="container text-center">
    <img alt="" class="img-fluid w-100 illu illu-boost" src="assets/img/modal/booster-visibilite.gif"><br>
    <h1>{{ 'booster.boost_your_profile' | translate }}</h1>
    <p class="infos w-100 text-center mb-4">
        {{ 'booster.increase_meet_chance' | translate }}<br class="br-mobile">
        {{ 'booster.display_on_top' | translate }}
        <br><br>
        <b>{{ 'booster.choose_duration' | translate }}</b>
    </p>
    <div class="row no-gutters">
        <div *ngIf="getBooster12()" class="col-4 px-1 mb-2">
            <a (click)="sendOffer(getBooster12())" class="btn btn-dark btn-sm btn-block cursor-pointer">
                <span class="boost-pack-title">12<small class="d-inline-block">h</small><br>
                <small>BOOST 12</small></span>
                {{ getBooster12().getPrice() }} Love Coins<br>
                {{ 'booster.xHours' | translate: {duration: 12} }}
            </a>
        </div>

        <div *ngIf="getBooster24()" class="col-4 px-1 mb-2">
            <a (click)="sendOffer(getBooster24())" class="btn btn-primary pack-boost-active btn-sm btn-block cursor-pointer">
                <span><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><br></span>
                <span class="boost-pack-title">24<small class="d-inline-block">h</small><br>
                <small>BOOST 24</small></span>
                {{ getBooster24().getPrice() }} Love Coins<br>
                {{ 'booster.xHours' | translate: {duration: 24} }}
            </a>
        </div>

        <div *ngIf="getBooster48()" class="col-4 px-1 mb-2">
            <a (click)="sendOffer(getBooster48())" class="btn btn-dark btn-sm btn-block cursor-pointer">
                <span class="boost-pack-title">48<small class="d-inline-block">h</small><br>
                <small>BOOST 48</small></span>
                {{ getBooster48().getPrice() }} Love Coins<br>
                {{ 'booster.xHours' | translate: {duration: 48} }}
            </a>
        </div>
    </div>

    <p>
        {{ 'booster.any_lc' | translate }}
        <br class="br-mobile">
        <a (click)="goPayment()" class="cursor-pointer">{{ 'click_here' | translate }}</a> {{ 'booster.credit_account' | translate }}
    </p>
</div>
