<app-support-contact-component></app-support-contact-component>

<!--<div class="col-12 text-center">-->
<!--    <h1 class="mb-3 mt-4">-->
<!--        <i class="far fa-question-circle text-primary "></i> Foire Aux Questions (FAQ)-->
<!--    </h1>-->
<!--</div>-->
<!--<div class="col-12" id="faq">-->
<!--    <div class="card border-0 box mb-4 p-0">-->
<!--        <div class="row text-center no-gutters">-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme border-right h-100">-->
<!--                    <i class="fas fa-lock text-primary"></i>-->
<!--                    <span>INSCRIPTION AU SERVICE</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme h-100">-->
<!--                    <i class="fas fa-key text-primary"></i>-->
<!--                    <span>CONNEXION ET IDENTIFICATION</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme border-right h-100">-->
<!--                    <i class="fas fa-euro-sign text-primary"></i>-->
<!--                    <span class="d-none d-lg-block">GESTION DE MES ACHATS ET DE MON ABONNEMENT</span>-->
<!--                    <span class="d-block d-lg-none">GESTION ACHATS/ABONNEMENT</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme h-100">-->
<!--                    <i class="fas fa-user-cog text-primary"></i>-->
<!--                    <span class="titre-desktop">GESTION DE MON PROFIL ET DE MON COMPTE</span>-->
<!--                    <span class="titre-mobile">GESTION PROFIL/COMPTE</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme border-right h-100">-->
<!--                    <i class="fas fa-search text-primary"></i>-->
<!--                    <span>RECHERCHER ET ÊTRE RECHERCHÉ</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme h-100">-->
<!--                    <i class="fas fa-users text-primary"></i>-->
<!--                    <span>INTERACTIONS AVEC LES MEMBRES</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme border-right h-100">-->
<!--                    <i class="fas fa-shield-alt text-primary"></i>-->
<!--                    <span>SÉCURITÉ ET MODÉRATION</span>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <a href="faq2.html" class="theme h-100">-->
<!--                    <i class="fas fa-info-circle text-primary"></i>-->
<!--                    <span class="titre-desktop">CONSEILS POUR FAIRE DES RENCONTRES</span>-->
<!--                    <span class="titre-mobile">CONSEILS RENCONTRES</span>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->





<!--<div class="row main profile">-->
<!--    <div class="col-12 text-center mb-4">-->
<!--        <ul class="nav nav-tabs onglets-profils pt-3">-->
<!--            <li class="nav-item">-->
<!--                <a class="nav-link active" [routerLink]="['/customer-service']">{{ 'customer_service' | translate }}</a>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </div>-->

<!--    <div class="col-12">-->
<!--        <app-support-faq-component></app-support-faq-component>-->
<!--    </div>-->
<!--</div>-->





<!--<div id="customer-service" class="container-fluid mt-4">-->
<!--    <div class="main profile">-->
<!--        <div class="row">-->
<!--            <div class="col-lg-7 wow fadeInUp">-->
<!--                <app-support-faq-component></app-support-faq-component>-->
<!--            </div>-->

<!--            <div class="col-lg-5">-->
<!--                <app-support-chat-component></app-support-chat-component>-->

<!--                <app-support-phone-component></app-support-phone-component>-->

<!--                <app-support-mail-component></app-support-mail-component>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
