<div class="s01">
    <form>
        <fieldset>
            <legend>{{ 'panic.modal.find_hotel' | translate }}</legend>
        </fieldset>
        <div class="inner-form">
            <div class="input-field first-wrap">
                <input id="search" type="text" placeholder="{{ 'panic.modal.form_hotel_name' | translate }}" />
            </div>
            <div class="input-field second-wrap">
                <input id="location" type="text" placeholder="{{ 'panic.modal.form_hotel_city' | translate }}" />
            </div>
            <div class="input-field third-wrap">
                <button class="btn-search" type="button">{{ 'search' | translate }}</button>
            </div>
        </div>
    </form>
    <a id="panic-button" class="panic-button__button text-uppercase"
       title="Google"
       href="javascript:;" data-dismiss="modal" aria-label="Close" [mat-dialog-close]="true"
    >
        <i class="fas fa-exclamation-triangle mr-2"></i> {{ 'back' | translate }}
    </a>
    <link rel="stylesheet" property="stylesheet" href="https://cdn.rawgit.com/studiothick/panic-button/v1.2.0/dist/panic.css" />
    <script async src="https://cdn.rawgit.com/studiothick/panic-button/v1.2.0/dist/panic.min.js"></script>
</div>