import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage/storage.service';
import {OfferService} from '../../services/offer/offer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Group} from '../../models/offer/group';
import {CurrentUserService} from '../../services/user/current-user.service';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

    loaded = false;

    constructor(
        private currentUserService: CurrentUserService,
        private storageService: StorageService,
        private offerService: OfferService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.offerService.getGroups().subscribe((groups: Group[]) => {
            if (groups && groups.length) {
                const group = groups[Math.floor(Math.random() * groups.length)];
                const action = this.activatedRoute.snapshot.queryParamMap.get('action');
                this.router.navigate(['/payment/offers/', group.id], {queryParams: {action}}).then();
                return;
            }
            this.loaded = true;
        });
    }

    getCurrentTpl(): number {
        return this.currentUserService.getAccount().getSettings().billingTemplate;
    }
}
