import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class ProfileVisitNotificationType extends AbstractNotificationType {

    static readonly TYPE = 46;

    protected buttons:NotificationButton[] = [
        new NotificationButton('view_profile', () => {
            this.goTo('search-appearance');
        })
    ];

    getImage(): string {
        return this.siteService.getSite().getMosaicUrl(this.currentUserService.getAccount().getProfile());
    }

    getText() {
        return super.getText() + (this.getTotalVisit() > 1 ? '' : '_only_one');
    }

    getMetaData() {
        return {
            totalVisit: this.getTotalVisit()
        };
    }

    private getTotalVisit(): number {
        return this.notification && this.notification.getMetaData() && this.notification.getMetaData().totalVisit ?
            this.notification.getMetaData().totalVisit : 1;
    }
}
