import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class AlbumAccessRequestNotificationType extends AbstractNotificationType {

    static readonly TYPE = 13;

    protected buttons:NotificationButton[] = [
        new NotificationButton('accept', () => {
            this.requestStatus('accepted');
        }),
        new NotificationButton('refuse', () => {
            this.requestStatus('rejected');
        }),
    ];

    requestStatus(status: string) {
        this.actionService.privateAlbumAccess(this.notification.getMetaData().id, status).subscribe(() => {
            this.notification.delete();
        });
    }

    getMetaData(): {} {
        return {
            albumTitle: this.notification.getMetaData().albumTitle
        };
    }
}
