<div [class.offline-thumb]="!camModel.isOnline()" class="card card-profile text-center">
    <div *ngIf="camModel.isOnline()" class="badge badge-success enligne">{{ 'available_model' | translate }}</div>
    <div *ngIf="camModel.isOnline() && camModel.isVIPShow()" class="badge badge-primary enligne">{{ 'vip_show' | translate }}</div>
    <div *ngIf="camModel.isOnline() && camModel.isPrivateShow()" class="badge badge-warning enligne">{{ 'private_show' | translate }}</div>
    <div *ngIf="!camModel.isOnline()" class="badge badge-secondary enligne">{{ 'offline' | translate }}</div>

    <div class="profil wrapper">
        <div class="wrappercontent">
            <a (click)="openSexCam()" class="itemsprofil">
                <img (load)="onLoadImage()" [src]="camModel.getPhoto()" (error)="removeModel()"/>
                <img *ngIf="camModel.isOnline() && camModel.isPrivateShow()" src="assets/img/common/keyhole.png" class="peeping-tom-keyhole w-100"/>
                <div *ngIf="camModel.hasHD()" class="badge badge-info hd">HD</div>
            </a>
        </div>
    </div>

    <div class="card-body">
        <a (click)="openSexCam()" class="itemsprofil">
            <h4 class="card-title">
                <i *ngIf="!camModel.isCouple()" [ngClass]="{
                    'fa-mars male-color': camModel.isMale(),
                    'fa-venus female-color': camModel.isFemale(),
                    'fa-transgender-alt transgender-color': camModel.isTransgender()
                }" class="mr-1 fas"></i>
                <i *ngIf="camModel.isCouple()" class="fas fa-venus couple-color"></i>
                <i *ngIf="camModel.isCouple()" class="mr-1 fas fa-mars couple-color"></i><span class="d-none d-lg-inline">{{ camModel.getNickname() | maxStringSize:15 }}</span>
                <span class="d-lg-none">{{ camModel.getNickname() | maxStringSize:7 }}</span>
            </h4>
        </a>

        <div class="card-links">
            <a (click)="openSexCam()" [class.disabled]="!camModel.isOnline()" [title]="'menu.chat' | translate"
               class="btn btn-defaut action w-100">
                <img alt="webcam" class="ico-live" src="assets/img/common/512x512/webcam.svg"> <span
                class="d-none d-xl-inline">{{ (camModel.isPrivateShow() ? 'peeping_mode' : 'join_the_live') | translate }}</span>
            </a>
        </div>
        <div class="infinite-trigger"></div>
    </div>
</div>
