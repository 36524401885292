import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CurrentUserService} from '../../user/current-user.service';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    constructor(private http: HttpClient, private user: CurrentUserService) {
    }

    addReport(targetId, reasonId, description) {
        return this.http.post(`${this.getEndpoint()}/report`, {targetId, reasonId, description});
    }

    addKiss(targetId) {
        return this.http.post(`${this.getEndpoint()}/kiss`, {targetId});
    }

    addBlocked(targetId) {
        return this.http.post(`${this.getEndpoint()}/blocked`, {targetId});
    }

    removeBlocked(targetId) {
        return this.http.delete(`${this.getEndpoint()}/blocked/${targetId}`);
    }

    addFavorite(targetId) {
        return this.http.post(`${this.getEndpoint()}/favorite`, {targetId});
    }

    removeFavorite(targetId) {
        return this.http.delete(`${this.getEndpoint()}/favorite/${targetId}`);
    }

    addFriend(targetId) {
        return this.http.post(`${this.getEndpoint()}/friend`, {targetId});
    }

    removeFriend(targetId) {
        return this.http.delete(`${this.getEndpoint()}/friend/${targetId}`);
    }

    friendStatus(targetId, status) {
        return this.http.put(`${this.getEndpoint()}/friend/${targetId}`, {status});
    }

    privateAlbumAccess(id: number, status: string) {
        return this.http.put(`${environment.backendUrl}/private-album-access/${id}`, {status});
    }

    private getEndpoint(): string {
        return environment.backendUrl + '/profile/' + this.user.getAccount().getProfile().getId();
    }
}
