<div *ngIf="showHardbounceNotification" class="alert alert-danger notification-confirmation">
    <div class="container-fluid">
        <a class="close" data-dismiss="alert" aria-label="close" title="close">
            <i class="fas fa-times"></i>
        </a>
        <div class="row no-gutters">
            <a  href="" data-toggle="modal" class="mr-2">
                <i class="fas fa-exclamation-circle"></i>
            </a>
            <span>{{ 'hardbounced.invalide_email_address' | translate }} <a href="javascript:;" (click)="showHardbouncedStep1()">{{ 'hardbounced.more_information' | translate }}</a></span>
        </div>
    </div>
</div>
