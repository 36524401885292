import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Gender} from '../models/user/gender';

@Directive({
    selector: '[error-src]'
})
export class ErrorSrcDirective implements OnInit, OnDestroy {

    @Input('error-src') gender = Gender.FEMALE;
    @Input('error-src-size') size = 75;

    private el: HTMLElement;
    private isApplied = false;

    constructor(el: ElementRef) {
        this.el = el.nativeElement;
        this.el.addEventListener('error', this.onError.bind(this))
    }

    ngOnInit() {
        if (![75, 150, 250].includes(this.size)) {
            this.size = 75;
        }
        if (this.gender !== Gender.MALE && this.gender !== Gender.FEMALE) {
            this.gender = Gender.FEMALE;
        }
    }

    private onError() {
        this.removeEvents();

        if (!this.isApplied) {
            this.isApplied = true;
            this.el.setAttribute('src', `assets/img/${this.size}x${this.size}/default_user_${this.gender}.png`);
        }
    }

    private removeEvents() {
        this.el.removeEventListener('error', this.onError);
    }

    ngOnDestroy() {
        this.removeEvents();
    }
}
