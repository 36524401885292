<div *ngIf="showEmojis" id="emojis" class="emojis-container">
    <div class="card">
        <div>
            <ul class="emojis-category">
                <li [class.active]="chatEmojiService.getCategory() === category" *ngFor="let category of chatEmojiService.getCategories()">
                    <a (click)="switchCategory(category)"><i class="fas fa-{{ chatEmojiService.getCategoryClass(category) }}"></i></a>
                </li>
            </ul>
        </div>
        <div class="emojis">
            <h2>{{ 'chat.emoji.' + chatEmojiService.getCategory() | translate }}</h2>
            <ul>
                <li *ngFor="let emoji of chatEmojiService.getEmojis()">
                    <a (click)="insertEmoji(emoji)" [title]="chatEmojiService.getEmojiShortcut(emoji)">
                        <span>{{ emoji }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
