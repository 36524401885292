<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center">
        <h1 class="mb-0"><i class="far fa-check-circle text-success" aria-hidden="true"></i> Bravo ! Votre profil est
            certifié et vérifié !
        </h1>
    </div>
</div>

<app-virilblue-layout>
    <i class="far fa-arrow-alt-circle-down text-primary fleche-upsell" aria-hidden="true"></i>
    <div class="card-body p-4 p-sm-5  text-center">
        <h3 class="mb-0"><span>Essayez <b>Viril<span class="blue">Blue</span></b></span><br>
            Offre réservée aux nouveaux membres</h3>
        <br>
        <app-payment-page [urlMode]="true" [displayRedirect]="false" [url]="paymentUrl"></app-payment-page>
    </div>
    <div class="card-footer text-center text-muted"><i class="fas fa-star mr-1" aria-hidden="true"></i><i
        class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i>
        SATISFAIT OU REMBOURSÉ <i class="fas fa-star mr-1" aria-hidden="true"></i><i
            class="fas fa-star mr-1" aria-hidden="true"></i><i class="fas fa-star" aria-hidden="true"></i>
    </div>
</app-virilblue-layout>
