<div class="row">
    <div class="col-lg-4">
        <app-certification-component></app-certification-component>
    </div>
    <div class="col-lg-8 order-1 order-lg-2">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="pr-0"><i class="fas fa-user-edit mr-2 text-primary"></i>{{ 'account.purchases.history_debits' | translate }}</h2>
            </div>

            <div class="card-body">
                <table class="table table-striped table-hover historique">
                    <thead>
                    <tr>
                        <th scope="col" style="width:180px">{{ 'account.purchases.date' | translate }}</th>
                        <th scope="col">{{ 'account.purchases.entitled' | translate }}</th>
                        <th scope="col" style="width:150px" class="text-right">{{ 'account.purchases.amount' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of this.transactions">
                            <td>{{ transaction.getDate() | date: (transaction.isMessage() ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm') }}</td>
                            <td>
                                <span class="texte-intitule text-truncate">
                                    {{ getTitle(transaction) }}
                                    <span *ngIf="!transaction.getOffer().isLoveCoin()">({{ getPeriod(transaction) }})</span>
                                    <ng-container *ngIf="transaction.getMethod()">
                                        <br>
                                        <small *ngIf="!transaction.isCoinOffer()">{{ ('your_payment_' + transaction.getMethod()) | translate: {amount: transaction.getOffer().getFormattedPrice()} }}</small>
                                        <small *ngIf="transaction.isBooster()">{{ 'your_payment_booster' | translate: {duration: transaction.getCoinOffer().getOption('duration')} }}</small>
                                        <small *ngIf="transaction.isMessage() && transaction.getCoinOffer().getMessageCount() > 1">{{ 'your_payment_messages' | translate: {messageCount: transaction.getCoinOffer().getMessageCount()} }}</small>
                                        <small *ngIf="transaction.isMessage() && transaction.getCoinOffer().getMessageCount() < 2">{{ 'your_payment_message' | translate }}</small>
                                        <small *ngIf="transaction.isKiss()">{{ 'your_payment_kiss' | translate }}</small>
                                        <small *ngIf="transaction.isLive()">
                                            <ng-container *ngIf="transaction.getCoinOffer().getOption('chatType') === 'O2M'">{{ 'vip_show' | translate }}</ng-container>
                                            <ng-container *ngIf="transaction.getCoinOffer().getOption('chatType') === 'O2O'">{{ 'private_show' | translate }}</ng-container>
                                            <ng-container *ngIf="transaction.getCoinOffer().getOption('chatType') === 'V'">{{ 'peeping_show' | translate }}</ng-container>
                                             - {{ transaction.getCoinOffer().getOption('duration') }} 
                                            {{ (transaction.getCoinOffer().getOption('duration') > 1 ? 'account.purchases.minutes' : 'account.purchases.minute') | translate }} - 
                                            <a [routerLink]="['/sex-cams/', transaction.getCoinOffer().getOption('model')['account']]">{{ transaction.getCoinOffer().getOption('model')['nickname'] }}</a>
                                        </small>
                                    </ng-container>
                                </span>
                            </td>
                            <td class="{{ transaction.getCoins() >= 0 ? 'text-success' : 'text-danger'}} text-right">{{ transaction.getFormattedCoins() }}</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-striped table-hover historique">
                    <tbody><tr class="table-primary">
                        <td>{{ 'account.purchases.balance' | translate }}</td>
                        <td class="text-right"><span class="d-none d-sm-inline-block">{{ 'account.purchases.balance_amount' | translate }}</span> {{ getCoins() }} Love Coins</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <app-pagination-component *ngIf="this.transactions.length > 0" class="container-fluid mt-4" [class.d-none]="loading" [currentPage]="getCurrentPage()" [maxPage]="getMaxPage()" (pageChange)="loadTransactions($event)">
            </app-pagination-component>
        </div>
    </div>
</div>
