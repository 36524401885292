import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {LoginService} from '../login/login.service';
import {CurrentUserService} from '../user/current-user.service';
import {ModalService} from '../modal/modal.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(
        private loginService: LoginService,
        private currentUserService: CurrentUserService,
        private modalService: ModalService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.loginService.isConnected()) {
            this.loginService.logout();

            return false;
        }

        const data = this.currentUserService.suspensionModalData('/' + route.url[0].path);
        if (data !== false) {
            this.modalService.openFullScreenConfirmation(data);
        }

        return true;
    }
}
