import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ProfileService} from '../../../services/profile/profile.service';
import {CoinService} from '../../../services/coin/coin.service';
import {CoinOffer} from '../../../models/offer/coin-offer';
import {formatDate} from '../../../services/helper/date.service';
import {SocketService} from '../../../services/socket/socket.service';

@Component({
    selector: 'app-booster',
    templateUrl: './booster.component.html',
    styleUrls: ['./booster.component.scss']
})
export class BoosterComponent {

    coinOffer?: CoinOffer = null;
    error: string;
    confirmation = false;
    endDate: string;

    constructor(
        public dialogRef: MatDialogRef<BoosterComponent>,
        private currentUser: CurrentUserService,
        private translate: TranslateService,
        private router: Router,
        private profileService: ProfileService,
        private coinService: CoinService,
        private socketService: SocketService
    ) {}

    getDuration() {
        if (this.coinOffer !== null) {
            return this.coinOffer.getOptions().duration;
        } else {
            return 0;
        }
    }

    checkEligibility(offer: CoinOffer) {
        this.coinService.checkEligibility(this.currentUser.profileId, offer.getId()).subscribe((result: any) => {
            if (result.data.eligible !== true) {
                if (result.data.reason === 'insufficient_coins') {
                    this.dialogRef.close({offer});
                } else if (result.data.reason === 'no_photo' || result.data.reason === 'no_moderated_photo') {
                    this.error = 'photo';
                } else if (result.data.reason === 'already_boosted') {
                    this.error = 'already';
                    const endTimestamp = new Date(result.data.end * 1000);
                    this.endDate = formatDate(endTimestamp, 'Y-m-d H:i:s');
                }
            } else {
                this.coinService.buyOffer(this.currentUser.profileId, offer.getId()).subscribe(() => {
                    this.currentUser.refresh().then(() => {
                        this.socketService.emit('update_coins', {coins: this.currentUser.getAccount().getCoins()});
                    });
                    this.coinOffer = offer;
                    this.confirmation = true;
                });
            }
        });
    }
}
