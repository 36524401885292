import {Component, Input} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {SiteService} from '../../../services/site/site.service';
import {QuestionsAnswers} from '../../../models/site/questions-answers';

@Component({
    selector: 'app-selected-choice-group',
    templateUrl: './selected-choice-group.component.html'
})
export class SelectedChoiceGroupComponent {

    @Input() tab: string;
    @Input() category: string;
    @Input() profile: Profile;
    @Input() currentProfile: boolean;

    constructor(private siteService: SiteService) {
    }

    get questionsAnswers(): QuestionsAnswers {
        return this.siteService.getQuestionsAnswers();
    }
}
