<div class="card border-0 box mb-4 alert alert-warning">
    <div class="card-header"><h2>{{ 'profile_question_category.view.' + category | translate }}</h2> </div>
    <div class="card-body">
        <div class="row">
            <div *ngIf="profile.isPrivacyFriends(category)" class="col-12 text-center">
                <h5 class="font-weight-bold">
                    <i [ngClass]="{'fa-mars icon-male': profile.isMale(), 'fa-venus icon-female': profile.isFemale() }" class="fas"></i> {{ profile.nickname }} {{ 'privacy.share.friends' | translate }}
                </h5>
                <a *ngIf="!profile.relationship.addedToFriend()" (click)="friend()" class="btn btn-light btn-lg d-inline-block">
                    <i class="fas fa-user-plus" [matTooltip]="'contact.add_friend' | translate"></i> {{ 'contact.add_friend' | translate }}
                </a>
            </div>
            <div *ngIf="profile.isPrivacyNotShare(category)" class="col-12 text-center">
                <h5 class="font-weight-bold">
                    <i [ngClass]="{'fa-mars icon-male': profile.isMale(), 'fa-venus icon-female': profile.isFemale() }" class="fas"></i> {{ profile.nickname }} {{ 'privacy.share.not_share' | translate }}
                </h5>
            </div>
        </div>
    </div>
</div>
