<div *ngFor="let message of getMessages(); let isFirst = first;" [class.blurred]="hasRule('chatBlur', message)">

    <div *ngIf="hasDividerDate(message) || isFirst" [class.mb-2]="isMobile" class="post-divider mt-2">
        <hr>
        <span>{{ getDividerDate(message) }}</span>
    </div>

    <div class="answer wow fadeInDown"
         [ngClass]="{'left': !isMyMessage(message), 'right': isMyMessage(message)}"
         [ngStyle]="!isMobile ? {'visibility': 'visible', 'animation-name': 'fadeInDown'} : {}">

        <div class="avatar">
            <app-user-photo [user]="message.getUser()" [alt]="getUsername(message)"
                            [ngStyle]="isMobile ? {'width': '50px'} : {}"></app-user-photo>

            <div *ngIf="message && message.getUser()" class="status" [ngClass]="message.getUser().getActivity().getDynamicClass()"></div>

            <div *ngIf="chatUserService.isBlockedByMe(message.getUser())" class="status fa-stack bg-warning">
                <i class="fa fa-ban fa-stack-1x text-white"></i>
            </div>
        </div>
        <div class="name">
            <span [class.blurred]="hasRule('nicknameBlur', message)">{{ getUsername(message) }}</span>
            <small *ngIf="getCity(message)" [class.blurred]="hasRule('detailBlur', message)" [hidden]="isMobile && isMyMessage(message)"
                   class="text-muted pl-2 text-capitalize">
                {{ getCity(message).toLowerCase() }}
            </small>
            <i class="fas pl-2" [ngClass]="{
                'fa-venus fem': message.getUser().isFemale(),
                'fa-mars hom': message.getUser().isMale(),
                'fa-venus-mars cpl': message.getUser().isCouple()
            }"></i>
        </div>
        <div class="text">{{ message.getMessage() }}</div>
        <div class="time" appElapsedTime [dateTime]="message.getTime()"></div>
    </div>
</div>

<ng-container *ngFor="let pendingMessage of getPendingActions()">
    <app-pending-message [pendingMessage]="pendingMessage"></app-pending-message>
</ng-container>
