import {Component, Input, OnInit} from '@angular/core';
import {ChatEmojiService} from '../../../services/chat/chat-emoji/chat-emoji.service';
import {ChatService} from '../../../services/chat/chat.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {Observable} from 'rxjs';
import {SexCamsService} from '../../../services/sex-cams/sex-cams.service';

@Component({
    selector: 'app-emoji',
    templateUrl: './emoji.component.html',
    styleUrls: ['./emoji.component.scss']
})
export class EmojiComponent implements OnInit {

    showEmojis = false;

    @Input() toggleEvent: Observable<void>;
    @Input() sexCamChat = false;

    constructor(
        public chatEmojiService: ChatEmojiService,
        private chatService: ChatService,
        private chatRoomService: ChatRoomService,
        private sexCamsService: SexCamsService
    ) {}

    ngOnInit() {
        this.toggleEvent.subscribe(() => {
            this.showEmojis = !this.showEmojis;
        });
    }

    switchCategory(category: string) {
        this.chatEmojiService.setCategory(category);
    }

    insertEmoji(emoji: string) {
        // we can only concat if messages is defined, to prevent this behavior: "undefined😀"
        if (this.sexCamChat) {
            if (this.sexCamsService.chatMessage) {
                this.sexCamsService.chatMessage += emoji;
            } else {
                this.sexCamsService.chatMessage = emoji;
            }
        } else {
            if (this.chatService.messages[this.getMessageId()]) {
                this.chatService.messages[this.getMessageId()] += emoji;
            } else {
                this.chatService.messages[this.getMessageId()] = emoji;
            }
        }
        this.showEmojis = false;
    }

    private getMessageId() {
        const room = this.chatRoomService.getCurrentRoom();
        return (room.getId() > 0)
            ? room.getId()
            : room.getFriendUser().getUsername();
    }
}
