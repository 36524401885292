<menu class="menu">
    <div class="navbar secondary d-none d-xl-block" role="navigation">
        <div class="container-fluid" id="menu">
            <ul class="nav" id="nav">
                <li [routerLinkActive]="['selected']" [routerLink]="['home']">
                    <a (click)="goTo('/home')" class="nav-link cursor-pointer">
                        <i class="fas fa-home"></i>
                        <p>{{ 'menu.home' | translate }}</p>
                    </a>
                </li>

                <li [routerLinkActive]="['selected']" [routerLink]="['profile']">
                    <a (click)="goTo('/profile')" class="nav-link cursor-pointer">
                        <i class="fas fa-user"></i>
                        <span>{{ 'menu.my_profile' | translate }}</span>
                    </a>
                </li>

                <li [routerLinkActive]="['selected']" [routerLink]="['chat']">
                    <a [routerLink]="['chat']" class="nav-link">
                        <i class="fas fa-comment"></i>
                        <span>{{ 'menu.chat' | translate }}</span>
                    </a>
                </li>

                <li [routerLinkActive]="['selected']" [routerLink]="['contacts']">
                    <a [routerLink]="['contacts']" class="nav-link">
                        <i class="fas fa-address-book" aria-hidden="true"></i>
                        <span>{{ 'menu.my_contacts' | translate }}</span>
                    </a>
                </li>

<!--                <li *ngIf="canShowWebcamMenu()" [routerLinkActive]="['selected']" [routerLink]="['sex-cams']">-->
<!--                    <a [routerLink]="['sex-cams']" class="nav-link nogal">-->
<!--                        <i class="far fa-eye"></i>-->
<!--                        <span>Sex Cams</span>-->
<!--                    </a>-->
<!--                </li>-->

                <li *ngIf="canShowWebcamMenu()">
                    <a href="https://liveparadise.com/" target="_blank" class="nav-link nogal">
                        <i class="far fa-eye"></i>
                        <span>Sex Cams</span>
                    </a>
                </li>

                <li *ngIf="hasUpsellUnlover()">
                    <a (click)="openUpsellXponsorUnlover()" class="nav-link cursor-pointer">
                        <i class="fas fa-film"></i>
                        <span>{{ 'menu.flirting_tips' | translate }}</span>
                    </a>
                </li>

                <li *ngIf="showEbooks()" [routerLinkActive]="['selected']" [routerLink]="['ebooks']">
                    <a [routerLink]="['ebooks']" class="nav-link">
                        <i class="fas fa-book"></i>
                        <span>{{ 'menu.e_books' | translate }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</menu>
