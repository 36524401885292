<!-- step 1 -->
<div class="modal-body step-nickname">

    <div class="container text-center">

        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/pseudo.png"><br>
        <h1>{{ 'on_boarding.choose_pseudo' | translate }}</h1>
        <p class="infos w-100 text-center" [innerHTML]="getDescription()"></p>
        <div class="form-group mb-0">
            <div class="input-group w-100">
                <span class="input-group-text" style="min-width:42px;"><i class="fas fa-user mx-auto"></i></span>
                <input (keyup)="checkNickname(false)" type="text" [(ngModel)]="nickname" name="nickname" class="form-control"
                       placeholder="{{ 'on_boarding.choose_pseudo_input' | translate }}">
            </div>
            <div class="errorSpace mt-2">
                <small *ngIf="!suggestion && !(nickname && nickname.length >= 6)" class="fileHelp form-text text-muted text-left">{{ 'on_boarding.min_length' | translate }}</small>
                <small class="alert alert-danger m-0" *ngIf="errorMessage && !suggestion && nickname && nickname.length > 0">
                    {{errorMessage}}
                </small>
                <div *ngIf="suggestion">
                    <small class="suggestion-pseudo form-text text-left text-muted">
                        {{errorMessage}} <br class="br-mobile">{{ 'on_boarding.available_nickname' | translate }} : <a href="javascript:;" class="username-ok" (click)="useSuggestion()">{{ suggestion }}</a>
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton()" (click)="previous()" class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a href="#finish-photo" (click)="updateNickname()" data-toggle="modal" data-dismiss="modal"
       class="btn btn-primary btn-sm text-uppercase">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
