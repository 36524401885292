import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maxStringSize'
})
export class MaxStringSizePipe implements PipeTransform {
    transform(name: string, maxLength: number): any {
        if (!name) {
            return '';
        }
        if (!isNaN(maxLength) && name.length > maxLength) {
            return name.substring(0, maxLength - 3) + '...';
        }

        return name;
    }
}
