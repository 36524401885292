import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class SexCamRule implements Deserializable {

    public static readonly DISABLED = 'disabled';
    public static readonly NO_RESTRICTION = 'no_restriction';
    public static readonly SUBSCRIBED_ONLY = 'subscribed_only';

    wallStatus: string;
    menuStatus: string;
    genderAllowed: string[];

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    isWallEnabled(): boolean {
        return this.wallStatus !== SexCamRule.DISABLED;
    }

    isWallNotRestricted(): boolean {
        return this.wallStatus === SexCamRule.NO_RESTRICTION;
    }

    isMenuEnabled(): boolean {
        return this.menuStatus !== SexCamRule.DISABLED;
    }

    isMenuNotRestricted(): boolean {
        return this.menuStatus === SexCamRule.NO_RESTRICTION;
    }
}
