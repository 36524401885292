import {Component, EventEmitter, Output} from '@angular/core';
import {ProfileService} from '../../../../services/profile/profile.service';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {Gender} from '../../../../models/user/gender';


@Component({
    selector: 'app-finish-signup-gender',
    templateUrl: './finish-signup-gender.component.html',
    styleUrls: ['./finish-signup-gender.component.scss']
})
export class FinishSignupGenderComponent {

    @Output() nextStep = new EventEmitter<number>();

    gender: number;

    constructor(
        private currentUserService: CurrentUserService,
        private profileService: ProfileService
    ) {
        this.gender = this.currentUserService.getAccount().getProfile().getGender();
    }

    setGender(gender) {
        this.gender = gender;
    }

    get MALE(): number {
        return Gender.MALE;
    }

    get FEMALE(): number {
        return Gender.FEMALE;
    }

    isMale(): boolean {
        return this.gender === Gender.MALE;
    }

    isFemale(): boolean {
        return this.gender === Gender.FEMALE;
    }

    next() {
        const searchedGender = this.isFemale() ? Gender.MALE : Gender.FEMALE;

        this.profileService.putProfile({
            gender: this.gender,
            searchedGender
        }).subscribe(() => {
            if (this.gender !== this.currentUserService.getAccount().getProfile().getGender()) {
                this.currentUserService.getAccount().getProfile().setGender(this.gender);
                this.currentUserService.getAccount().getProfile().setSearchedGender(searchedGender);
            }

            this.currentUserService.getAccount().getProfile().getFieldsStatus().gender = 'provided';
            this.currentUserService.getAccount().getProfile().getFieldsStatus().searchedGender = 'provided';

            this.nextStep.emit();
        });
    }
}
