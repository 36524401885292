import {Component, Inject, Injector, OnInit} from '@angular/core';
import {ProfileService} from '../../services/profile/profile.service';
import {Profile} from '../../models/profile/profile';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {ModalService} from '../../services/modal/modal.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../modal/coin-payment/coin-payment.component';

@Component({
    selector: 'app-search-appearance',
    templateUrl: './search-appearance.component.html',
    styleUrls: ['./search-appearance.component.scss']
})
export class SearchAppearanceComponent extends BaseComponent implements OnInit {

    private readonly countByPage = 30;

    public loading = false;
    public partialLoading = false;
    public visitedCount = 0;
    public searchedCount = 0;
    public profiles = {
        visit: [],
        search: []
    };
    public currentDate = 'last_7days';
    public currentType = 'visit';
    public currentPage = 1;
    public blurred = false;
    private stats: any = null;

    constructor(
        private profileService: ProfileService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
        private dialog: MatDialog,
        private injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        let firstLoad = true;

        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.currentType = params.get('type') ? params.get('type') : 'visit';
            this.currentDate = params.get('date') ? params.get('date') : 'last_7days';
            this.currentPage = params.get('page') ? +params.get('page') : 1;

            this.loadProfiles(1, firstLoad);

            firstLoad = false;
        });
    }

    generateNickname() {
        const length = Math.round(Math.random() * 8 + 3);
        const charset = 'abcdefghijklmnopqrstuvwxyz';
        let retVal = '';

        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        return retVal;
    }

    loadProfiles(page: number = 1, loadStats = false) {
        this.loading = loadStats;
        this.partialLoading = !loadStats;

        const params = {
            date: this.currentDate,
            type: this.currentType,
            stats: loadStats
        };

        this.profileService.findAppearanceAll(page, this.countByPage, params).subscribe((result: any) => {
            this.loading = false;
            this.partialLoading = false;

            this.visitedCount = result.visitedCount;
            this.searchedCount = result.searchedCount;
            this.blurred = result.blurred;
            this.profiles[params.type] = [];

            if (loadStats) {
                this.stats = result.stats;
            }

            for (const data of result.data) {
                const profile = new Profile().deserialize(data);

                if (this.blurred) {
                    const now = new Date();
                    profile.nickname = this.generateNickname();
                    profile.birthDate = new Date(
                        Math.round(Math.random() * 20 + now.getFullYear()),
                        Math.round(Math.random() * 12 + 1),
                        Math.round(Math.random() * 28 + 1),
                        0, 0, 0, 0
                    );
                }

                this.profiles[params.type].push(profile);
            }

            // After profile loading, scrollTop (in case the user scroll again before the results are here)
            window.scroll(0, 0);
        });
    }

    getCount(): number {
        switch (this.currentType) {
            case 'visit':
                return this.visitedCount;
            case 'search':
                return this.searchedCount;
            default:
                return 0;
        }
    }

    getMaxPage() {
        return Math.floor(this.getCount() / this.countByPage) + 1;
    }

    getCurrentPage() {
        return this.currentPage;
    }

    changePage(page: number) {
        this.changeUrlParams({page});
    }

    changeDate() {
        this.changeUrlParams({date: this.currentDate});
    }

    hasStats(): boolean {
        return !!this.stats;
    }

    getTotalVisitsCount(): number {
        return this.stats ? this.stats.visits.now.total : 0;
    }

    getNearVisitsCount(): number {
        return this.stats ? this.stats.visits.now.near : 0;
    }

    getVisitsEvolution(): number {
        return this.stats ? this.stats.visits.evolution : 0;
    }

    getTotalSearchsCount(): number {
        return this.stats ? this.stats.searchs.now.total : 0;
    }

    getNearSearchsCount(): number {
        return this.stats ? this.stats.searchs.now.near : 0;
    }

    getSearchsEvolution(): number {
        return this.stats ? this.stats.searchs.evolution : 0;
    }

    showVisits(date: string = null) {
        const params = {type: 'visit'};

        if (date) {
            params['date'] = date;
        }

        this.changeUrlParams(params);
    }

    showSearchs(date: string = null) {
        const params = {type: 'search'};

        if (date) {
            params['date'] = date;
        }

        this.changeUrlParams(params);
    }

    getCountTranslateHtml(count: number) {
        return {
            count,
            countHtml: '<span class="text-primary">' + count + '</span>'
        }
    }

    getHtmlTags() {
        return {
            '[': '<span class="text-primary">',
            ']': '</span>'
        }
    }

    redirectToPayment() {
        if (this.account.getRuleSet().isSubscription()) {
            this.router.navigate(['/payment'], {
                queryParams: {
                    action: 'search_appearance'
                }
            });
        } else {
            this.router.navigateByUrl('/home').then(() => {
                this.dialog.open(CoinPaymentComponent, {
                    panelClass: 'modal-box',
                    maxWidth: '100%',
                    data: {action: 'search_appearance'}
                });
            });
        }
    }

    changeUrlParams(params: any) {
        if (!params.page) {
            params['page'] = 1;
        }

        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: params,
                queryParamsHandling: 'merge'
            }
        );
    }
}
