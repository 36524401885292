import {Component, Inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Profile} from '../../models/profile/profile';
import {ProfileService} from '../../services/profile/profile.service';
import {CurrentUserService} from '../../services/user/current-user.service';
import {ToastrService} from 'ngx-toastr';
import {WINDOW} from '../../app.config';
import {TranslateService} from '@ngx-translate/core';
import {Album} from '../../models/profile/album';
import {AlbumService} from '../../services/profile/album/album.service';
import {PhotoService} from '../../services/profile/photo/photo.service';

@Component({
    selector: 'app-profile-component',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    @Input() profile: Profile;

    private profileId;
    currentProfile = false;

    constructor(
        private profileService: ProfileService,
        private albumService: AlbumService,
        private photoService: PhotoService,
        private currentUserService: CurrentUserService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(WINDOW) private window: Window,
    ) {
    }

    ngOnInit() {
        this.profileId = +this.route.snapshot.paramMap.get('id');

        if (this.profileId && this.profileId !== this.currentUserService.getAccount().getProfile().getId()) {
            if (this.currentUserService.hasRule('blockProfileAccess')) {
                return this.router.navigate(['/payment'], {queryParams: {action: 'profile_view'}});
            }
            this.findProfile();
        } else {
            this.currentProfile = true;
            this.profile = this.currentUserService.getAccount().getProfile();
        }
    }

    getProfile() {
        if (!this.currentProfile && this.currentUserService.refreshPage.profile) {
            this.currentUserService.refreshPage.profile = false;
            this.findProfile();
        }
        return this.profile;
    }

    isCurrentProfile() {
        return this.currentProfile;
    }

    private findProfile() {
        this.profileService.findById(this.profileId).subscribe(
            (profile: Profile) => {
                this.findAlbums(profile);
            },
            () => {
                this.router.navigate(['/home']).then(() => {
                    if (this.toastr) {
                        this.toastr.error(this.translate.instant('error_list.unauthorized_profile'));
                    }
                });
            }
        );
    }

    private findAlbums(profile: Profile) {
        this.albumService.findAll(profile, this.currentProfile).subscribe(async (profile: Profile) => {
            const albums: {} = profile.getAlbums();
            const albumsKey = Object.keys(albums);

            for (const albumKey of albumsKey) {
                const album = albums[albumKey];
                await this.findPhotos(album);
            }

            this.profile = profile;
        });
    }

    private findPhotos(album: Album) {
        return new Promise((resolve, reject) => {
            this.photoService.findByAlbum(album).subscribe((albumUpdated: Album) => {
                if (this.currentProfile || album.getPhotosLength() > 0) {
                    album = albumUpdated;
                }
                resolve();
            });
        });
    }
}
