import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Company implements Deserializable {

    name: string;
    country: string;
    postalCode: string;
    city: string;
    address: string;
    registrationNumber: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getName(): string {
        return this.name;
    }

    getCountry(): string {
        return this.country;
    }

    getPostalCode(): string {
        return this.postalCode;
    }

    getCity(): string {
        return this.city;
    }

    getAddress(): string {
        return this.address;
    }

    getRegistrationNumber(): string {
        return this.registrationNumber;
    }
}
