import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class WarningModerationNotificationType extends AbstractNotificationType {

    static readonly TYPE = 44;

    protected buttons:NotificationButton[] = [
        new NotificationButton('menu.my_account', () => {
            this.goTo('account', null, 'settings');
        })
    ];

    getIcon(): string {
        return 'fa-exclamation-triangle';
    }
}
