<div class="container mb-5 pb-5 upsell-xp upsell-xp-4" id="upsell">
    <div class="row">
        <div class="col-12 text-center">
            <h2 class="my-3 d-none d-lg-block">
                <span class="text-primary text-uppercase"><i class="fas fa-star text-warning star-1"
                                                             aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-2" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-3" aria-hidden="true"></i> Promotion exceptionnelle <i
                    class="fas fa-star text-warning star-3" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-2" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-1" aria-hidden="true"></i></span><br>
                <small>Des <span class="bg-primary text-white text-uppercase px-2">jolies filles</span> disponibles
                    24h/24 - 7j/7 pour un dial chaud ! *</small>
            </h2>
            <h2 class="my-3 d-block d-lg-none">
                <span class="text-primary text-uppercase"><i class="fas fa-star text-warning star-1"
                                                             aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-2" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-3" aria-hidden="true"></i> Offre spéciale <i
                    class="fas fa-star text-warning star-3" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-2" aria-hidden="true"></i><i
                    class="fas fa-star text-warning star-1" aria-hidden="true"></i></span><br>
                <small>Des <span class="bg-primary text-white text-uppercase px-2">jolies filles</span> dispo 24h/24 -
                    7j/7 pour un dial chaud !</small>
            </h2>
        </div>
        <div class="col mx-auto text-center">

            <div class="row my-1 my-sm-3 align-items-center">
                <div class="col">
                    <div class="position-relative">
                        <img src="assets/img/live/camgirl01.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl01.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
                <div class="col">
                    <div class="position-relative">
                        <img src="assets/img/live/camgirl02.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl02.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
                <div class="col d-none d-sm-block">
                    <div class="position-relative">
                        <img src="assets/img/live/camgirl03.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl03.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
                <div class="col d-none d-md-block">
                    <div class="position-relative">
                        <img src="assets/img/live/camgirl04.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl04.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
                <div class="col d-none d-lg-block">
                    <div class="position-relative">
                        <img src="assets/img/live/camgirl05.webp" alt="" class="img-fluid w-100"
                             onerror="this.onerror=null; this.src='assets/img/live/camgirl05.gif'">
                        <div class="webcam-upsell"><img src="assets/img/live/webcam2.png" alt="">EN DIRECT</div>
                    </div>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
    <br><br>

    <div class="row mt-4 mb-5">
        <div class="col-sm-6 col-md-3 text-center mb-4 mb-md-0">
            <h6 class="font-weight-bold"><i class="fas fa-gift text-primary" aria-hidden="true"></i><br>
                15% OFFERTS</h6>
            <p class="mb-0">
                15% de love coins offerts c'est 15% de temps en plus à partager avec des filles qui vous attendent pour
                un dial chaud en direct live.</p>
        </div>
        <div class="col-sm-6 col-md-3 text-center mb-4 mb-md-0">
            <h6 class="font-weight-bold"><i class="fas fa-star text-primary" aria-hidden="true"></i><br>
                FILLES AU TOP</h6>
            <p class="mb-0">
                Les filles vous attendent pour un dial dédié à votre plaisir. Leur webcam est branchée et allumée. Il ne
                manque plus que vous !</p>
        </div>
        <div class="col-sm-6 col-md-3 text-center mb-4 mb-sm-0">
            <h6 class="font-weight-bold"><i class="fas fa-thumbs-up text-primary" aria-hidden="true"></i><br>
                ACCÈS SÉCURISÉ</h6>
            <p class="mb-0">
                Avec vos love coins, accédez à la liste des filles connectées et choisissez celles qui vous plaisent
                pour débuter l'expérience...</p>
        </div>
        <div class="col-sm-6 col-md-3 text-center">
            <h6 class="font-weight-bold"><i class="fas fa-smile text-primary" aria-hidden="true"></i><br>
                0 PRISE DE TÊTE</h6>
            <p class="mb-0">
                Un seul objectif : prendre du bon temps sans se prendre la tête... Les filles sont libérées et ouvertes
                à toutes vos propositions.</p>
        </div>
    </div>

    <br><br><br><br>
</div>
