import {Injectable} from '@angular/core';
import * as emojis from '../../../../assets/chat/emojis.json';

@Injectable({
    providedIn: 'root'
})
export class ChatEmojiService {

    private readonly defaultEmoji = 'default';

    private category = null;
    private categories = [];
    private emojisJson = emojis[this.defaultEmoji];
    private emojisCategories = {};
    private emojisShortcut = {};
    private emojis = {};

    constructor() {
        const emojisKey = Object.keys(this.emojisJson);
        for (const category of emojisKey) {
            this.emojisCategories[category] = [];
            this.categories.push(category);

            if (!this.category) {
                this.category = category;
            }

            const emojisByCategory = this.emojisJson[category].emojis;

            for (const emoji of emojisByCategory) {
                this.emojis[emoji.emoji] = emoji;
                this.emojisShortcut[emoji.str] = emoji.emoji;
                this.emojisCategories[category].push(emoji.emoji);
            }
        }
    }

    getCategory(): string {
        return this.category;
    }

    setCategory(category: string) {
        this.category = category;
    }

    getCategories(): any {
        return this.categories;
    }

    getCategoryClass(category: string): string {
        return this.emojisJson[category].class;
    }

    getEmojis() {
        return this.emojisCategories[this.category];
    }

    getEmojiShortcut(emoji: string): string {
        return this.emojis[emoji].str;
    }

    replaceShortcutByEmoji(text) {
        if (!text) {
            return '';
        }

        // regex for getting all the shortcuts ( pattern :string:)
        const shortcuts = text.match(/(:[^:]*:)/g) || [];

        for (const shortcut of shortcuts) {
            if (this.emojisShortcut[shortcut]) {
                text = text.replace(shortcut, this.emojisShortcut[shortcut]);
            }
        }

        return text;
    }
}
