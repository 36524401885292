import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';

@Component({
    selector: 'app-confirmation-mail',
    templateUrl: './confirmation-mail.component.html',
    styleUrls: ['./confirmation-mail.component.scss']
})
export class ConfirmationMailComponent {

    @Output() onClose = new EventEmitter<boolean>();

    constructor(private modalService: ModalService) {
    }

    openEmailConfirmation() {
        this.modalService.openEmailConfirmation();
    }

    close() {
        this.onClose.emit(true);
    }
}
