import {Component, DoCheck} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SiteContentService} from '../../services/site/content/site-content.service';

@Component({
    selector: 'app-static-page',
    templateUrl: './static-page.component.html',
    styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements DoCheck {

    public slug: string;
    public content: string;

    constructor(
        private siteContentService: SiteContentService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngDoCheck(): void {
        if (this.slug !== this.activatedRoute.snapshot.paramMap.get('slug')) {
            this.content = '';
            this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
            this.siteContentService.getStaticPage(this.slug).then((content: string) => {
                this.content = this.siteContentService.replaceTag(content);
            });
        }
    }
}
