import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
    selector: 'app-panic-button',
    templateUrl: './panic-button.component.html',
    styleUrls: ['./panic-button.component.scss']
})
export class PanicButtonComponent implements OnInit {

    private showPanicButton = false;

    constructor(
        private modalService: ModalService,
        private currentUserService: CurrentUserService,
        private profileService: ProfileService
    ) {
    }

    ngOnInit(): void {
        this.currentUserService.getAccount().panicModeObserver().subscribe((response) => {
            this.showPanicButton = response;
        });
    }

    showPanicMode() {
        this.modalService.openPanicMode();
    }

    canShowPanicMode() {
        return this.showPanicButton;
    }

    disabledPanicMode() {
        this.currentUserService.getAccount().setPanicMode(false);

        this.profileService.putProfile({
            panicMode: false
        }).subscribe();
    }
}
