import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {CurrentUserService} from '../../services/user/current-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-pending-confirmation',
    templateUrl: './pending-confirmation.component.html',
    styleUrls: ['./pending-confirmation.component.scss']
})
export class PendingConfirmationComponent extends BaseComponent implements OnInit {

    field: string = null;
    value: string = null;
    isLoaded = false;
    alreadyResend = true;

    constructor(
        private route: ActivatedRoute,
        private currentUser: CurrentUserService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        setTimeout(() => {
            $(window).scrollTop(0);
        }, 500);

        this.field = this.route.snapshot.paramMap.get('field');
        this.value = this.route.snapshot.paramMap.get('value');
        if (this.field === 'suspension') {
            this.value = this.translate.instant('account.my_account.suspend_account.modal.title');
        } else if (this.field === 'deletion') {
            this.value = this.translate.instant('account.my_account.delete_account.modal.title');
        }

        if (this.field) {
            this.isLoaded = true;
            this.releaseLock();
        } else {
            this.router.navigate(['/home']).then(() => {
                if (this.toastr) {
                    this.toastr.success(this.translate.instant('already_confirmed_' + this.field));
                }
            });
        }
    }

    resendConfirmation() {
        if (this.alreadyResend) {
            return;
        }
        this.alreadyResend = true;

        switch (this.field) {
            case 'suspension':
            case 'deletion':
                const subject = this.currentUser.retryStatusRequest(this.field);
                if (subject !== false) {
                    subject.subscribe();
                    this.releaseLock();
                }
                break;
            default:
                this.currentUser.sendCode(this.field, this.value).subscribe((response: any) => {
                    if (response && !response.success) {
                        this.alreadyResend = false;
                        this.toastr.error(response.message);
                    } else {
                        this.toastr.success(this.translate.instant('confirmation_email_sent'));

                        this.releaseLock();
                    }
                }, () => {
                    this.toastr.error(this.translate.instant('error_list.default'));
                });
        }
    }

    noLinkToClick() {
        return this.field !== 'suspension' && this.field !== 'deletion';
    }

    private releaseLock() {
        setInterval(() => {
            this.alreadyResend = false;
        }, 60000);
    }
}
