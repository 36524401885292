import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ElapsedTimeService {

    private timers: ElapsedTimeInterface[] = [];

    constructor() {
        setInterval(() => {
            this.refresh();
        }, 10000);
    }

    private refresh() {
        for (const timer of this.timers) {
            timer.refresh();
        }
    }

    public addTimer(timer: ElapsedTimeInterface) {
        this.timers.push(timer);
    }

    public removeTimer(timer: ElapsedTimeInterface) {
        const i = this.timers.indexOf(timer);
        if (i) {
            this.timers.splice(i, 1);
        }
    }
}

export declare interface ElapsedTimeInterface {
    refresh(): void;
}
