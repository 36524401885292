import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Profile} from '../../../models/profile/profile';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {AlbumService} from '../../../services/profile/album/album.service';
import {PhotoService} from '../../../services/profile/photo/photo.service';

@Component({
    selector: 'app-albums',
    templateUrl: './albums.component.html',
    styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent implements OnChanges {

    @Input() profile: Profile;
    @Input() currentProfile: Profile;
    albums: any;
    albumSelected;
    personalProfile = false;

    constructor(
        private route: ActivatedRoute,
        private albumService: AlbumService,
        private photoService: PhotoService,
        private currentUserService: CurrentUserService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.profile && changes.profile.currentValue !== undefined) {
            this.currentProfile = this.currentUserService.getAccount().getProfile();

            if (changes.profile.currentValue.getId() === this.currentProfile.getId()) {
                this.profile = this.currentProfile;
                this.personalProfile = true;
            }
        }
    }

    onSelectAlbum(album: object) {
        this.albumSelected = album;
    }

    onViewAlbums() {
        this.albumSelected = null;
    }

    onUpdateAlbums() {
        if (this.albums) {
            const keys = Object.keys(this.albums);
            for (const key of keys) {
                this.albums[key].unsetMainPhoto();
            }
        }
    }
}
