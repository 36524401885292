import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {OfferTemplate1Component} from './templates/offer-template1/offer-template1.component';
import {OfferTemplateComponent} from './templates/offer-template.component';
import {ActivatedRoute, Router} from '@angular/router';
import {OfferService} from '../../../services/offer/offer.service';
import {GroupOffer} from '../../../models/offer/group-offer';
import {Group} from '../../../models/offer/group';
import {LoaderService} from '../../../services/loader/loader.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {BillingService} from '../../../services/billing/billing.service';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

    group: Group;
    groupOffer: GroupOffer;
    groupTemplateId: number;
    loaded = false;
    action: string;

    @ViewChild('templateHost', {read: ViewContainerRef, static: true}) templateHost: ViewContainerRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private offerService: OfferService,
        private loaderService: LoaderService,
        private router: Router,
        private currentUserService: CurrentUserService,
        private billingService: BillingService
    ) {
    }

    ngOnInit(): void {
        this.action = this.activatedRoute.snapshot.queryParamMap.get('action');

        if (this.currentUserService.getAccount() && this.currentUserService.getAccount().activeSubscription()) {
            this.router.navigate(['/home']).then();
            return;
        }

        this.loaderService.setLoading(true);

        this.activatedRoute.params.subscribe((params) => {
            this.offerService.getGroupOffer(params['id']).subscribe((groupOffer) => {
                this.groupOffer = groupOffer;

                this.offerService.getGroup(groupOffer.groupId).subscribe((group) => {
                    this.group = group;

                    // Is one-click payment is available ?
                    this.billingService.isOneClickAvailable().subscribe(available => {
                        if (available) {
                            // Yes, process the one click payment
                            this.billingService.oneClickPayment(this.groupOffer.offerId).subscribe(paymentResult => {
                                window.location.href = paymentResult.redirectUrl;
                                return;
                            }, (error) => {
                                this.redirectToPaymentPage(group);
                                return;
                            });
                        } else {
                            // Otherwise, show the payment page
                            this.redirectToPaymentPage(group);
                        }
                    });

                }, (error) => {
                    this.redirectToPayment();
                });
            }, (error) => {
                this.redirectToPayment();
            });
        });
    }

    private redirectToPaymentPage(group: Group) {
        const templateId = this.loadTemplate(this.getTemplateId(group));
        this.offerService.hitOffer(group, this.groupOffer, templateId, this.action).subscribe();
        this.loaderService.setLoading(false);
    }

    private getTemplateId(group: Group): string {
        if (this.activatedRoute.snapshot.queryParams['tpl']) {
            return this.activatedRoute.snapshot.queryParams['tpl'];
        }

        if (group.templateId) {
            return group.templateId.toString();
        }

        return null;
    }

    redirectToPayment() {
        this.loaderService.setLoading(false);
        this.router.navigate(['/payment'], {queryParams: {action: this.action}}).then();
    }

    /**
     * Load the template
     * @param templateId
     */
    loadTemplate(templateId: string): number {
        let component = null;

        switch (templateId) {
            default:
            case '1':
            case '2':
                component = OfferTemplate1Component;
                this.groupTemplateId = 1;
                break;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<OfferTemplateComponent>(component);
        const componentRef = this.templateHost.createComponent<OfferTemplateComponent>(componentFactory);
        componentRef.instance.group = this.group;
        componentRef.instance.groupOffer = this.groupOffer;
        componentRef.instance.backButton = this.activatedRoute.snapshot.queryParams['back'] !== '0';

        this.loaded = true;

        return this.groupTemplateId;
    }

    getGroupOffer(): GroupOffer {
        return this.groupOffer;
    }

    /**
     * Get the payment page template
     */
    getPaymentPageTemplate(): number {
        return this.group ? this.group.billingTemplateId : null;
    }

    /**
     * Get the offer id
     */
    getPaymentOffer(): number {
        return this.groupOffer ? this.groupOffer.offerId : null;
    }

    getGroupTemplateId(): number {
        return this.groupTemplateId;
    }

    /**
     * Can load payment page ?
     */
    canLoadPaymentPage(): boolean {
        return this.loaded;
    }
}
