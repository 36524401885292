import {Deserializable} from '../deserializable/deserializable';

export class GroupOffer implements Deserializable {
    id: number;
    icon: string;
    name: string;
    formula: string;
    price: string;
    description: string;
    discount: string;
    offerId: number;
    groupId: number;
    benefits: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getGroupId(): number {
        return this.groupId;
    }

    getBenefitsList(): string[] {
        if (this.benefits) {
            return this.benefits.split('\n');
        }

        return [];
    }
}
