<nav class="navbar navbar-dark navbar-expand-xl" id="top">
    <div class="container-fluid">
        <a class="navbar-brand mr-0 mr-sm-2 p-0" [routerLink]="['home']">
            <img class="img-fluid d-none d-sm-block w-100 logo" [src]="site.getLogoUrl()" [alt]="site.getName()">
            <img class="img-fluid d-block d-sm-none w-100 logo-resp" [src]="site.getMobileLogoUrl()" [alt]="site.getName()" style="max-width: 40px;">
        </a>

        <ul class="navbar-nav ml-auto d-xl-none mini-menu-resp">
            <li class="nav-item">
                <a class="ico-header" (click)="goTo('/account')">
                    <i [matTooltip]="'menu.account_settings' | translate" class="fas fa-cog"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="ico-header" (click)="toggleSearchFilters()">
                    <i [matTooltip]="'advanced_search' | translate" class="fas fa-search"></i>
                </a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['notification']" [matTooltip]="'notifications' | translate" class="ico-header">
                    <i class="fas fa-bell"></i>
                    <span *ngIf="getUnreadNotificationCount()" class="fa-layers-counter animated bounce infinite">
                        <span>{{ getUnreadNotificationCount() }}</span>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a class="ico-header" (click)="goToChat()">
                    <i [matTooltip]="'menu.new_messages' | translate" class="fas fa-comment"></i>
                    <span *ngIf="getUnreadCount()" class="fa-layers-counter animated bounce infinite">
                        <span>{{ getUnreadCount() }}</span>
                    </span>
                </a>
            </li>
            <li class="nav-item d-none d-lg-block">
                <a class="ico-header" href title="">
                    <i class="fas fa-times-circle"></i>
                </a>
            </li>
        </ul>

        <button (click)="hideSearchFilter()" class="navbar-toggler collapsed" id="navbarDropdownMenuLink" type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto align-items-xl-center">

                <!-- include the menu for mobile version -->
                <app-menu-mobile></app-menu-mobile>

                <li class="nav-item d-none d-xl-block">
                    <a (click)="openLoveCoinModal()">
                        <div [matTooltipClass]="getLoveCoinClass()" [matTooltip]="'lovecoins.balance' | translate: {coins: getCoins()}" class="love-coin row no-gutters d-flex align-items-center">
                            <div class="bg-white text-primary text-center p-1">
                                <img class="img-fluid coin" src="assets/img/lovecoin.png">
                            </div>
                            <div class="text-center px-2 solde p-1"><h6 class="mb-0">{{ getCoins() }} LC</h6></div>
                            <div class="bg-white text-primary text-center p-1 text-uppercase px-2 credit">
                                {{ 'lovecoins.credit' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <app-ad-space-display ids="13" [deleteBtn]="false" *ngIf="isMobile()" adClass="btn_18plus_resp"></app-ad-space-display>
                <app-ad-space-display ids="14" [deleteBtn]="false" *ngIf="isMobile()" adClass="btn_gift_resp"></app-ad-space-display>
                <li class="d-block d-xl-none" data-toggle="collapse" data-target="#navbarSupportedContent">
                    <a title="{{ 'menu.logout' | translate }}" class="nav-link" (click)="logout()">
                        <i class="fas fa-times-circle"></i>
                        <span aria-hidden="true" class="lien">{{ 'menu.logout' | translate }}</span>
                    </a>
                </li>
                <app-ad-space-display ids="7" [deleteBtn]="false" *ngIf="!isMobile()" adClass="btn_18plus"></app-ad-space-display>
                <li class="nav-item d-none d-xl-block">
                    <a class="ico-header" (click)="goTo('/account')">
                        <i [matTooltip]="'menu.account_settings' | translate" class="fas fa-cog"></i>
                    </a>
                </li>
                <li class="nav-item d-none d-xl-block">
                    <a class="ico-header" (click)="toggleSearchFilters()">
                        <i [matTooltip]="'advanced_search' | translate" class="fas fa-search"></i>
                    </a>
                </li>
                <li class="nav-item d-none d-xl-block">
                    <a [routerLink]="['notification']" [matTooltip]="'notifications' | translate" class="ico-header">
                        <i class="fas fa-bell"></i>
                        <span *ngIf="getUnreadNotificationCount()" class="fa-layers-counter animated bounce infinite">
                            <span>{{ getUnreadNotificationCount() }}</span>
                        </span>
                    </a>
                </li>
                <li class="nav-item d-none d-xl-block">
                    <a class="ico-header" (click)="goToChat()">
                        <i [matTooltip]="'menu.new_messages' | translate" class="fas fa-comment"></i>
                        <span *ngIf="getUnreadCount()" class="fa-layers-counter animated bounce infinite">
                            <span>{{ getUnreadCount() }}</span>
                        </span>
                    </a>
                </li>
                <app-ad-space-display ids="8" [deleteBtn]="false" *ngIf="!isMobile()" adClass="btn_gift"></app-ad-space-display>
                <li class="nav-item d-none d-xl-block">
                    <a class="ico-header" (click)="logout()">
                        <i [matTooltip]="'menu.logout' | translate" class="fas fa-times-circle"></i>
                    </a>
                </li>

                <li (click)="hideMenu()" class="d-block d-xl-none mt-2 mb-3">
                    <a (click)="openLoveCoinModal()" id="coin-mobile">
                        <div [matTooltip]="'lovecoins.balance' | translate: {coins: getCoins()}" class="love-coin row no-gutters d-flex align-items-center">
                            <div class="bg-white text-primary text-center p-1 px-2">
                                <img class="img-fluid coin mobile" src="assets/img/lovecoin.png">
                            </div>
                            <div class="text-center px-4 solde p-1"><h6 class="mb-0">{{ getCoins() }} LC</h6></div>
                            <div class="bg-white text-primary text-center text-uppercase px-3 credit">
                                {{ 'lovecoins.credit' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
