import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {formatNumber} from '@angular/common';
import {LoginService} from '../../../services/login/login.service';
import {ChatService} from '../../../services/chat/chat.service';
import {Router} from '@angular/router';
import {FiltersService} from '../../../services/filters/filters.service';
import {NotificationService} from '../../../services/notification/notification.service';
import {ModalService} from '../../../services/modal/modal.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../../modal/coin-payment/coin-payment.component';
declare let $ : any;

@Component({
    selector: 'app-top-header-component',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent extends BaseComponent {

    constructor(
        private loginService: LoginService,
        private chatService: ChatService,
        private filtersService: FiltersService,
        private router: Router,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private dialog: MatDialog,
        injector: Injector
    ) {
        super(injector);
        this.notificationService.fetchUnreadCount();
    }

    private static formatUnreadCount(count: number) {
        return (count > 9) ? '9+' : count;
    }

    goTo(page) {
        this.currentUserService.refresh();
        this.router.navigate([page]);
    }

    logout() {
        this.loginService.logout();
    }

    getUnreadCount() {
        return TopHeaderComponent.formatUnreadCount(this.chatService.getChatRoomService().countTotalMessages());
    }

    getUnreadNotificationCount() {
        return TopHeaderComponent.formatUnreadCount(this.notificationService.getUnreadNotification());
    }

    goToChat() {
        this.chatService.mobileTab = 'rooms';
        this.router.navigate(['chat']);
    }

    toggleSearchFilters() {
        this.filtersService.toggle();
        $('#navbarSupportedContent').collapse('hide');
    }

    hideSearchFilter() {
        this.filtersService.hide();
    }

    hideMenu() {
        $('#navbarSupportedContent').collapse('hide');
    }

    getCoins(): string {
        const account = this.currentUserService.getAccount();
        const coins = typeof account.getCoins() === 'undefined' ? 0 : account.getCoins();
        return formatNumber(coins, 'en', '1.2-2').replace(/,/g, ' ');
    }

    getLoveCoinClass() {
        return 'lovecoin-tooltip tooltip-' + this.site.getLocale();
    }

    isMobile(): boolean {
        return window.innerWidth < 992;
    }

    openLoveCoinModal() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'credit_account'}
        });
    }
}
