import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {Account} from '../../../models/account/account';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

    @Input() account: Account;
    passwordInputType = 'password';

    constructor(
        private modalService: ModalService
        ) {
    }

    ngOnInit(): void {
    }

    openNicknameModification() {
        this.openModificationModal('nickname', 'user');
    }

    openEmailModification() {
        this.openModificationModal('email', 'envelope');
    }

    openPasswordModification() {
        this.openModificationModal('password', 'lock');
    }

    openMobileModification() {
        this.modalService.openMobileConfirmation(true);
    }

    private openModificationModal(field, icon) {
        this.modalService.openModificationSettings(field, icon);
    }

}
