import {Component, OnInit} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {ProfileService} from '../../../services/profile/profile.service';
import {FiltersService} from '../../../services/filters/filters.service';
import {SearchComponent} from '../search.component';
import {City} from '../../../models/city/city';
import {Gender} from '../../../models/user/gender';
import {SearchFilters} from '../../../models/account/settings/search-filters';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {

    canSubmit = true;
    hardSubmit: boolean;

    private static numberGenerator(min: number, max: number, step = 1): number[] {
        const numberArray = [];

        for (let i = min; i <= max; i += step) {
            numberArray.push(i);
        }

        return numberArray;
    }

    constructor(
        private filtersService: FiltersService,
        protected currentUser: CurrentUserService,
        protected profileService: ProfileService,
        protected router: Router
    ) {
        this.filtersService.currentHardSubmit.subscribe(value => {
            this.hardSubmit = value;
            if (this.hardSubmit) {
                if (!this.filtersService.getSearchFilters().city) {
                    this.onUnselectCity();
                }
                this.submit();
            }
        });
    }

    ngOnInit() {
        // this.filtersService.currentHardSubmit.subscribe(hardSubmit => this.hardSubmit = hardSubmit);
    }

    getDistanceOptions(): number[] {
        return SearchFilterComponent.numberGenerator(10, 80, 10);
    }

    getFilters(): SearchFilters {
        return this.filtersService.getSearchFilters();
    }

    rangeUpdate() {
        const minAge = this.getFilters().age ? this.getFilters().age[0] : 18;
        const maxAge = this.getFilters().age ? this.getFilters().age[1] : 70;

        $('.noUi-handle.noUi-handle-lower').attr('data-value', minAge);
        $('.noUi-handle.noUi-handle-upper').attr('data-value', maxAge);
    }

    translateGender() {
        return {gender: (this.currentUser.getAccount().getProfile().getGender() === Gender.MALE ? 'e' : '')};
    }

    submit() {
        if (!this.canSubmit)
            return;

        this.hide();

        this.filtersService.submit();

        SearchComponent.tab = 'result';

        if (this.router.routerState.snapshot.url !== '/search') {
            this.router.navigate(['/search']);
        } else {
            this.filtersService.searchProfiles();
        }
    }

    isVisible() {
        return this.filtersService.showFilters;
    }

    hide() {
        this.filtersService.hide();
    }

    onSelectCity(city: City) {
        this.filtersService.getSearchFilters().city = city;
    }

    onUnselectCity() {
        this.filtersService.getSearchFilters().distance = '';
        this.filtersService.getSearchFilters().city = null;
    }

    onCityErrorUnpicked(cityErrorUnpicked: boolean) {
        this.canSubmit = !cityErrorUnpicked;
    }
}
