import {Injectable} from '@angular/core';
import {SocketService} from '../../socket/socket.service';
import {User} from '../../../models/chat/user/user';
import {CurrentUserService} from '../../user/current-user.service';
import {Activity} from '../../../models/user/activity';
import {ModalService} from '../../modal/modal.service';
import {ChatAutoReconnectService} from '../chat-auto-reconnect/chat-auto-reconnect.service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatUserService {

    private users = {};
    private onlineCount = 0;

    private me: User = null;

    usersChanged = new Subject();

    constructor(
        private socketService: SocketService,
        private currentUserService: CurrentUserService,
        private modalService: ModalService,
        private chatAutoReconnectService: ChatAutoReconnectService
    ) {}

    onUsers() {
        this.socketService.on('users', (data: any) => {
            const users = [];

            for (const userData of data.users) {
                let user = new User();

                if (this.users[userData.id]) {
                    user = this.users[userData.id];

                    if (user.getActivity().isOffline()) {
                        this.onlineCount++;
                    }
                } else {
                    this.onlineCount++;
                }

                user.deserialize(userData);

                this.users[user.getId()] = user;

                users.push(user);
            }

            if (data.count) {
                this.onlineCount = data.count;
            }

            this.usersChanged.next(users);
        });
    }

    onUserDisconnected() {
        this.socketService.on('user_disconnected', (data: any) => {
            const users = [];

            if (data.id in this.users) {
                const user: User = this.users[data.id];
                user.setActivity(Activity.OFFLINE);
                this.onlineCount--;

                users.push(user);
            }

            this.usersChanged.next(users);
        });
    }

    onSessionKilled() {
        this.socketService.on('session_killed', (data: any) => {
            this.chatAutoReconnectService.setAutoReconnect(false);
        });
    }

    loadUsersByIds(usersIds) {
        return new Promise((resolve, reject) => {
            this.socketService.emit('get_users', usersIds).then((usersData) => {
                const usersList: User[] = Object.values(this.initializeUsers(usersData));

                for (const user of usersList) {
                    this.addUser(user);
                }

                resolve();
            });
        });
    }

    loadUserByProfileId(profileId: number) {
        return new Promise((resolve, reject) => {
            this.socketService.emit('get_user_by_profile_id', {profileId}).then((userData) => {
                const user: User = this.initializeUser(userData);

                if (user) {
                    this.addUser(user);
                }

                resolve(user);
            });
        });
    }

    getUsers(): User[] {
        return Object.values(this.users);
    }

    getUserById(userId: number): User | null {
        if (this.me && this.me.getId() === userId) {
            return this.me;
        }

        if (userId in this.users) {
            return this.users[userId];
        }

        return null;
    }

    addUser(user: User) {
        if (!this.getUserById(user.getId())) {
            this.users[user.getId()] = user;
        }
    }

    /**
     * Init the users
     * @param usersData
     */
    initializeUsers(usersData) {
        const usersList = {};

        for (const u of usersData) {

            let user = this.getUserById(u.id);

            if (user === null) {
                // New user
                user = new User().deserialize({
                    id: u.id
                });

                usersList[u.id] = user;
            }

            this.hydrateUserFromData(user, u);
        }

        return usersList;
    }

    initializeUser(userData) {
        let user = this.getUserById(userData.id);

        if (user === null) {
            // New user
            user = new User().deserialize({
                id: userData.id
            });
        }

        this.hydrateUserFromData(user, userData);

        return user;
    }

    hydrateUserFromData(user: User, data: any) {
        user.setUid(data.uid);
        user.setUsername(data.username);
        user.setCity(data.city);
        user.setGender(data.gender);
        user.setBirthdate(data.birthdate);
        user.setActivity(data.status);
        user.setPhoto(data.photo);
        user.setBlockedList(data.blockedList);
        user.setLastConnection(data.last_connection);
    }

    setMe(me: User) {
        this.me = me;
    }

    getMe(): User {
        return this.me;
    }

    reset() {
        this.users = {};
        this.me = null;
    }

    isBlockedByMe(user: User): boolean {
        return (this.getMe())
            ? this.getMe().isBlockedByMe(user.getId())
            : false;
    }

    loadMoreUsers() {
        return new Promise((resolve) => {
            const users = this.getConnectedUsers();
            const count = users.length;
            this.socketService.emit('get_more_online_users', {count}).then(() => {
                resolve();
            });
        });
    }

    getConnectedUsers(): User[] {
        const connectedUsers = [];

        for (const user of this.getUsers()) {
            if (this.currentUserService.isCurrentUser(user.getUid())) {
                continue;
            }

            if (user.getActivity().isOffline()) {
                continue;
            }

            if (this.currentUserService.getAccount().getProfile().getSearchedGender() !== user.getGender()) {
                continue;
            }

            connectedUsers.push(user);
        }

        return connectedUsers;
    }

    getConnectedUserCount(): number {
        return this.onlineCount;
    }
}
