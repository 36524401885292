import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../services/profile/profile.service';
import {Offer} from '../../../models/offer/offer';

@Component({
    selector: 'app-bank-transfer',
    templateUrl: './bank-transfer.component.html',
    styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent extends BaseComponent implements OnInit {

    public offer: Offer;

    constructor(
        injector: Injector,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private profileService: ProfileService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.siteService.getOffer(+params.get('id_offer')).subscribe((response: any) => {
                if (response.success) {
                    this.offer = new Offer().deserialize(response.data);

                    if (this.offer.isLoveCoin()) {
                        this.profileService.sendCoinAlternativePaymentEmail('wire', this.offer);
                    }
                }
            });
        });
    }

    getMonthTranslated() {
        return this.translate.instant('account.purchases.months');
    }
}
