<div class="row">
    <div class="col-12 text-center">
        <h1 class="mb-0 mt-4">
            <i class="fas fa-credit-card text-primary ico-status"></i><br>
            <span class="d-none d-lg-block">{{ 'alternative_payment.bank_transfer_payment' | translate }}</span>
            <span class="d-block d-lg-none">{{ 'alternative_payment.bank_transfer_payment' | translate }}</span>
        </h1>
        <p class="lead">
            {{ 'alternative_payment.bank_transfer_payment_title' | translate }}
        </p>
    </div>
</div>
<div class="row">
    <div class="col-lg-10 col-xl-7 mx-auto">
        <div *ngIf="offer" class="card box">
            <div class="card-body">
                <p *ngIf="!offer.isLoveCoin()" class="text-center">
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_transfer' | translate : {renewalPeriod: '<b>6 ' + getMonthTranslated() + '</b>', price: '<b>84 €</b>'}"></span>
                        <br>
                    </span>
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_transfer' | translate : {renewalPeriod: '<b>3 ' + getMonthTranslated() + '</b>', price: '<b>57 €</b>'}"></span>
                        <br>
                    </span>
                </p>
                <p *ngIf="offer.isLoveCoin()" class="text-center">
                    <span>
                        <i class="fas fa-hand-point-right text-primary"></i>
                        <span [innerHtml]="'alternative_payment.months_payment_transfer_lc' | translate : {price: '<b>' + offer.getPrice() + ' €</b>' }"></span>
                        <br>
                    </span>
                </p>
                <p class="text-center text-danger">
                    {{ 'alternative_payment.important_transfer' | translate }} <b>{{ account.getProfile().getId() }}</b>
                </p>

                <div class="row mb-2">
                    <div class="col-md-10 col-lg-8 mx-auto mt-2 mb-3 text-center">
                        <span *ngIf="!offer.isLoveCoin()">{{ 'alternative_payment.transfer_to' | translate : {offer1: 57, offer2: 84} }}</span>
                        <span *ngIf="offer.isLoveCoin()">{{ 'alternative_payment.transfer_to_lc' | translate : {offer: offer.getPrice()} }}</span>
                        <br>
                        <div class="card shadow bg-light">
                            <div class="card-body">
                                <strong>{{ 'alternative_payment.holder' | translate }} </strong>Urbipay Sàrl<br>
                                <strong>{{ 'alternative_payment.iban' | translate }} </strong>FR76 3000 3043 3600 1200 0635 035<br>
                                <strong>{{ 'alternative_payment.swift' | translate }} </strong>SOGEFRPPXXX
                            </div>
                        </div>
                    </div>
                </div>

                <div class="alert alert-success mb-0 text-center">
                    <span *ngIf="!offer.isLoveCoin()">{{ 'alternative_payment.transfer_reception' | translate }}<br></span>
                    <span *ngIf="offer.isLoveCoin()">
                        <span>{{ 'alternative_payment.transfer_reception_lc' | translate : { loveCoin: offer.getCoinAmount() } }}</span>
                        <span *ngIf="offer.getCoinFreeAmount()"> {{ 'alternative_payment.transfer_reception_lc_bonus' | translate : { bonus: offer.getCoinFreeAmount() } }}</span>.
                        <br>
                    </span>
                    {{ 'alternative_payment.notification' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 col-md-9 mb-4 mx-auto text-center" style="margin-top:100px;">
        <div class="card assistance border-0">
            <div class="card-body d-flex justify-content-between align-items-center">
                <h3 class="h6 mb-0 w-100">
                    {{ 'alternative_payment.faq_consult' | translate }}
                </h3>
                <a [routerLink]="['/faq']" class="btn btn-sm btn-primary ml-auto">
                    {{ 'alternative_payment.faq_see' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
