import {Component, Input} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
    selector: 'app-edit-privacy',
    templateUrl: './edit-privacy.component.html',
    styleUrls: ['./edit-privacy.component.scss']
})
export class EditPrivacyComponent {

    @Input() profile: Profile;
    @Input() group: string;

    constructor(private profileService: ProfileService) {
    }

    setEveryone() {
        this.setPrivacy(Profile.PRIVACY_EVERYONE);
    }

    setFriendsOnly() {
        this.setPrivacy(Profile.PRIVACY_FRIENDS_ONLY);
    }

    setNeverShow() {
        this.setPrivacy(Profile.PRIVACY_DO_NOT_SHARE);
    }

    showEveryone() {
        return this.profile.getPrivacy()[this.group] === Profile.PRIVACY_EVERYONE;
    }

    showFriendsOnly() {
        return this.profile.getPrivacy()[this.group] === Profile.PRIVACY_FRIENDS_ONLY;
    }

    neverShow() {
        return this.profile.getPrivacy()[this.group] === Profile.PRIVACY_DO_NOT_SHARE;
    }

    private setPrivacy(status) {
        if (this.profile.privacy[this.group] !== status) {
            this.profile.privacy[this.group] = status;
            const pickOnePrivacy = {};
            pickOnePrivacy[this.group] = status;
            this.profileService.putProfile({privacy: pickOnePrivacy}).subscribe();
        }
    }
}
