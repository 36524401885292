import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CoinOffer} from '../../models/offer/coin-offer';

@Injectable({
    providedIn: 'root'
})
export class CoinService {

    messageOffer: CoinOffer;
    kissOffer: CoinOffer;
    booster12: CoinOffer;
    booster24: CoinOffer;
    booster48: CoinOffer;

    constructor(private http: HttpClient) {}

    fetchOffers() {
        this.http.get(environment.backendUrl + `/coin-offer`).subscribe((result: any) => {
            for (const offer of result.data) {
                const coinOffer = new CoinOffer().deserialize(offer);

                if (coinOffer.isBoosterType()) {
                    switch (coinOffer.getOption('duration')) {
                        case 12:
                            this.booster12 = coinOffer;
                            break;
                        case 24:
                            this.booster24 = coinOffer;
                            break;
                        case 48:
                            this.booster48 = coinOffer;
                            break;
                    }
                } else if (coinOffer.isMessageType()) {
                    this.messageOffer = coinOffer;
                } else if (coinOffer.isKissType()) {
                    this.kissOffer = coinOffer;
                }
            }
        });
    }

    getMessageOffer(): CoinOffer {
        return this.messageOffer;
    }

    getKissOffer(): CoinOffer {
        return this.kissOffer;
    }

    getBooster12(): CoinOffer {
        return this.booster12;
    }

    getBooster24(): CoinOffer {
        return this.booster24;
    }

    getBooster48(): CoinOffer {
        return this.booster48;
    }

    checkEligibility(profileId: number, offerId: number): Observable<any> {
        return this.http.get(environment.backendUrl + `/profile/${profileId}/coin-offer/check/${offerId}`);
    }

    buyOffer(profileId: number, offerId: number): Observable<any> {
        return this.http.post(environment.backendUrl + `/profile/${profileId}/coin-offer/buy/${offerId}`, {});
    }
}
