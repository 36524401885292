import {Component, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {StorageService} from '../../services/storage/storage.service';

@Component({
    selector: 'app-footer-component',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent {

    constructor(
        public router: Router,
        public storage: StorageService,
        injector: Injector
    ) {
        super(injector);
    }

    showSubscriptionBanner(): boolean {
        return (
            !this.currentUserService.getAccount().getSubscription().isRejected() &&
            this.currentUserService.freemiumProfileIsConfirmed() &&
            this.currentUserService.getAccount().getRuleSet().getFooterAlert() &&
            !this.account.activeSubscription() &&
            // do not display on /payment or /account page
            !this.router.url.startsWith('/payment') &&
            !this.router.url.startsWith('/account') &&
            !this.router.url.startsWith('/sex-cams') &&
            !this.router.url.startsWith('/upsell') &&
            !this.currentUserService.getAccount().getSettings().isClosedAlert('subscription')
        );
    }

    getAddress(): string {
        const company = this.site.getCompany();

        let address = '';

        if (company) {
            if (company.getAddress()) {
                address += company.getAddress() + ', ';
            }

            if (company.getPostalCode() || company.getCity()) {
                if (company.getPostalCode()) {
                    address += company.getPostalCode();
                }
                if (company.getCity()) {
                    address += ' ' + company.getCity();
                }
                address += ', ';
            }

            if (company.getCountry()) {
                address += company.getCountry();
            }
        }

        return address;
    }

    onCloseGetSubscription(close: boolean) {
        this.currentUserService.setClosedAlert('subscription', close);
    }
}
