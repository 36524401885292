<div class="card border-0 box mb-4">
    <div class="card-header">
        <h2>
            <i class="fas fa-hand-paper mr-2 text-primary"></i>
            <ng-container *ngIf="!showSuspension()">
                <span class="d-none d-xl-inline-block">{{ 'account.my_account.suspend_account.title' | translate }}</span>
                <span class="d-inline-block d-xl-none">{{ 'account.my_account.suspend_account.title_mobile' | translate }}</span>
            </ng-container>
            <span *ngIf="showSuspension()">{{ 'chat_notif.suspended_short_title' | translate }}</span>
        </h2>
    </div>
    <div class="card-body">
        <p *ngIf="!showSuspension()" class="text-justify">{{ 'account.my_account.suspend_account.description' | translate }}</p>
        <form class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ 'starting_date' | translate }}</label>
                    <span *ngIf="showSuspension()">{{ getSuspension().from }}</span>
                    <div *ngIf="!showSuspension()" class="input-group" mat-raised-button (click)="startDate.open()">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="far fa-calendar-alt" aria-hidden="true"></i></span>
                        </div>
                        <input matInput [min]="minSuspendedFrom" [matDatepicker]="startDate" (dateChange)="changeSuspendedFrom()" [(ngModel)]="form.suspendedFrom" [placeholder]="'starting_date' | translate" name="startDate" class="form-control"/>
                        <mat-datepicker #startDate></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ 'end_date' | translate }}</label>
                    <span *ngIf="showSuspension()">{{ getSuspension().to }}</span>
                    <div *ngIf="!showSuspension()" class="input-group" mat-raised-button (click)="endDate.open()">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="far fa-calendar-alt" aria-hidden="true"></i></span>
                        </div>
                        <input matInput [min]="minSuspendedTo" [matDatepicker]="endDate" [(ngModel)]="form.suspendedTo" [placeholder]="'end_date' | translate" name="endDate" class="form-control">
                        <mat-datepicker #endDate></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div *ngIf="showSuspension()" class="form-group">
                    <!-- TODO translate -->
                    <label>{{ 'confirmation_date' | translate }}</label>
                    <span>{{ getSuspension().confirmedAt }}</span>
                </div>

                <div *ngIf="!showSuspension()" class="form-group">
                    <label>{{ 'account.my_account.suspend_account.reason' | translate }}</label>
                    <select [(ngModel)]="form.reason" name="reason" class="form-control custom-select">
                        <option *ngFor="let reason of reasonList" [value]="reason">{{ ('reason_list.' + reason) | translate }}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="!showSuspension()" class="col-12">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input [(ngModel)]="form.autoEnabling" type="checkbox" class="custom-control-input w-100" id="reactivate" name="reactivate">
                        <label class="custom-control-label" for="reactivate">{{ 'account.my_account.suspend_account.reactivate' | translate }}</label>
                    </div>
                </div>
            </div>

            <div *ngIf="showSuspension()" class="col-12 text-justify mt-2">
                <label>{{ 'account.my_account.suspend_account.modal_abort.question' | translate }}</label>
                <button *ngIf="showSuspension()" (click)="abortSuspension()" type="button" class="btn btn-primary btn-sm">{{ 'account.my_account.suspend_account.modal_abort.answer' | translate }}</button>
            </div>

            <div *ngIf="!showSuspension()" class="col-12 text-right">
                <button [disabled]="!checkForm()" (click)="submit()" type="button" class="btn btn-primary btn-sm">{{ 'validate' | translate }}</button>
            </div>
        </form>
    </div>
</div>
