import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BoosterComponent} from '../booster.component';

@Component({
    selector: 'app-booster-already',
    templateUrl: './booster-already.component.html',
    styleUrls: ['./booster-already.component.scss']
})
export class BoosterAlreadyComponent {

    @Input() end: string;

    constructor(
        public dialogRef: MatDialogRef<BoosterComponent>,
    ) {
    }

    close() {
        this.dialogRef.close();
    }
}
