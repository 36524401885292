<ul class="list-group text-left">
    <li [class.pr-3]="isRoom" class="list-group-item p-2">
        <a [routerLink]="getProfileRoute(user.getUid())"
           class="btn" [title]="'chat.display_profile' | translate">
            <i class="fas fa-user"></i>
            <span>{{ 'chat.display_profile' | translate }}</span>
        </a>
    </li>
    <li [class.pr-3]="isRoom" class="list-group-item p-2">
        <a (click)="reportProfile(user)" class="btn">
            <i class="fas fa-exclamation-triangle"></i>
            <span *ngIf="isRoom">{{ 'chat.report_profile' | translate }}</span>
            <span *ngIf="!isRoom">{{ 'report' | translate }}</span>
        </a>
    </li>
    <li [class.pr-3]="isRoom" class="list-group-item p-2">
        <a (click)="blockProfile(user)" class="btn">
            <span *ngIf="!chatUserService.isBlockedByMe(user)">
                <i class="fas fa-hand-paper"></i>
                <span *ngIf="isRoom">{{ 'chat.block_profile' | translate }}</span>
                <span *ngIf="!isRoom">{{ 'block' | translate }}</span>
            </span>
            <span *ngIf="chatUserService.isBlockedByMe(user)">
                <i class="fas fa-hand-paper"></i>
                <span *ngIf="isRoom">{{ 'unblock' | translate }}</span>
                <span *ngIf="!isRoom">{{ 'unblock' | translate }}</span>
            </span>
        </a>
    </li>
</ul>
