import {Component, EventEmitter, Inject, Optional, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Profile} from '../../models/profile/profile';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    @Output() close = new EventEmitter<boolean>();

    modalId: string;
    modalClass: [];
    title: string;
    titleMobile: string;
    faClass: string;
    profile: Profile = null;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.modalId = data.id || 'modal';
            this.modalClass = data.modalClass || [];
            this.title = data.title || '';
            this.titleMobile = data.titleMobile || this.title;
            this.faClass = data.faClass || '';
            this.profile = data.profile || null;
        }
    }

    onClose() {
        this.close.emit(true);
    }
}
