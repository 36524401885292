import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    loading: boolean;

    maxOpacity: boolean;

    private toLoad = {
        init: false,
        css: false
    };

    constructor() {}

    isLoading(): boolean {
        return this.loading;
    }

    setLoaded(index: string): void {
        this.toLoad[index] = true;

        if (this.checkLoaded()) {
            this.setLoading(false);
        }
    }

    checkLoaded(): boolean {
        for (const index in this.toLoad) {
            if (!this.toLoad[index]) {
                return false;
            }
        }

        return true;
    }

    setLoading(loading: boolean): void {
        setTimeout(() => {
            if (!loading) {
                this.maxOpacity = false;
            }
            this.loading = loading;
        });
    }

    setMaxOpacity(maxOpacity: boolean) {
        this.maxOpacity = maxOpacity;
    }

    hasMaxOpacity(): boolean {
        return this.maxOpacity;
    }
}
