import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {CurrentUserService} from '../user/current-user.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FinishSignupAuthGuardService implements CanActivate {

    constructor(private currentUserService: CurrentUserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.currentUserService.getAccountLoadedSubject().subscribe((loaded) => {
                if (loaded) {
                    if (!this.currentUserService.freemiumProfileIsConfirmed()) {
                        this.router.navigateByUrl('/home');
                        observer.next(false);
                        return;
                    }

                    observer.next(true);
                } else {
                    observer.next(false);
                }
            });
        });
    }
}
