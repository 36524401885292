import {Component, Injector, Input, OnInit} from '@angular/core';
import {Notification} from '../../../models/notification/notification';
import {ReceivedFriendRequestNotificationType} from '../../../notifications/received-friend-request-notification-type';
import {AbstractNotificationType} from '../../../notifications/abstract-notification-type';
import {NotificationService} from '../../../services/notification/notification.service';
import {Router} from '@angular/router';
import {SiteService} from '../../../services/site/site.service';
import {ReceivedKissNotificationType} from '../../../notifications/received-kiss-notification-type';
import {RegistrationNotificationType} from '../../../notifications/registration-notification-type';
import {NewMessageNotificationType} from '../../../notifications/new-message-notification-type';
import {PaymentTransactionNotificationType} from '../../../notifications/payment-transaction-notification-type';
import {UpgradePremiumNotificationType} from '../../../notifications/upgrade-premium-notification-type';
import {CustomerServiceNotificationType} from '../../../notifications/customer-service-notification-type';
import {AcceptedReceivedFriendRequestNotificationType} from '../../../notifications/accepted-received-friend-request-notification-type';
import {AcceptedSentFriendRequestNotificationType} from '../../../notifications/accepted-sent-friend-request-notification-type';
import {OtherNotificationType} from '../../../notifications/other-notification-type';
import {DiscoverCompatibilityTestNotificationType} from '../../../notifications/discover-compatibility-test-notification-type';
import {InvitedCompatibilityTestNotificationType} from '../../../notifications/invited-compatibility-test-notification-type';
import {SpeedDatingNotificationType} from '../../../notifications/speed-dating-notification-type';
import {ActivatedSpeedDatingNotificationType} from '../../../notifications/activated-speed-dating-notification-type';
import {AddedToFavoriteNotificationType} from '../../../notifications/added-to-favorite-notification-type';
import {RegularVisitNotificationType} from '../../../notifications/regular-visit-notification-type';
import {GotCrushNotificationType} from '../../../notifications/got-crush-notification-type';
import {BirthdayNotificationType} from '../../../notifications/birthday-notification-type';
import {AskedMorePhotoNotificationType} from '../../../notifications/asked-more-photo-notification-type';
import {UploadProfilePhotoNotificationType} from '../../../notifications/upload-profile-photo-notification-type';
import {WeeklyVisitNotificationType} from '../../../notifications/weekly-visit-notification-type';
import {HasAddedPhotoNotificationType} from '../../../notifications/has-added-photo-notification-type';
import {WarningModerationNotificationType} from '../../../notifications/warning-moderation-notification-type';
import {AcceptedProfilePhotoNotificationType} from '../../../notifications/accepted-profile-photo-notification-type';
import {RefusedProfilePhotoNotificationType} from '../../../notifications/refused-profile-photo-notification-type';
import {AcceptedPhotoNotificationType} from '../../../notifications/accepted-photo-notification-type';
import {RefusedPhotoNotificationType} from '../../../notifications/refused-photo-notification-type';
import {NicknameChangeNotificationType} from '../../../notifications/nickname-change-notification-type';
import {NicknameChangeConfirmedNotificationType} from '../../../notifications/nickname-change-confirmed-notification-type';
import {AcceptedPresentationNotificationType} from '../../../notifications/accepted-presentation-notification-type';
import {RefusedPresentationNotificationType} from '../../../notifications/refused-presentation-notification-type';
import {ProfileCertifiedNotificationType} from '../../../notifications/profile-certified-notification-type';
import {PasswordChangeNotificationType} from '../../../notifications/password-change-notification-type';
import {PasswordChangeConfirmedNotificationType} from '../../../notifications/password-change-confirmed-notification-type';
import {EmailChangeNotificationType} from '../../../notifications/email-change-notification-type';
import {EmailChangeConfirmedNotificationType} from '../../../notifications/email-change-confirmed-notification-type';
import {UnsubscribedNotificationType} from '../../../notifications/unsubscribed-notification-type';
import {MobileChangeConfirmedNotificationType} from '../../../notifications/mobile-change-confirmed-notification-type';
import {FillProfileNotificationType} from '../../../notifications/fill-profile-notification-type';
import {AcceptedVideoNotificationType} from '../../../notifications/accepted-video-notification-type';
import {RefusedVideoNotificationType} from '../../../notifications/refused-video-notification-type';
import {AlbumAccessRequestNotificationType} from '../../../notifications/album-access-request-notification-type';
import {AcceptedAlbumAccessRequestNotificationType} from '../../../notifications/accepted-album-access-request-notification-type';
import {SentFriendRequestNotificationType} from '../../../notifications/sent-friend-request-notification-type';
import {MobileChangeNotificationType} from '../../../notifications/mobile-change-notification-type';
import {NotificationButton} from '../../../models/notification/notification-button';
import {TranslateService} from '@ngx-translate/core';
import {CoinPaymentTransactionNotificationType} from '../../../notifications/coin-payment-transaction-notification-type';
import {environment} from '../../../../environments/environment';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {AskingMorePhotoNotificationType} from '../../../notifications/asking-more-photo-notification-type';
import {AlbumAccessNotificationType} from '../../../notifications/album-access-notification-type';
import {ProfileVisitNotificationType} from '../../../notifications/profile-visit-notification-type';
import {NicknameChangeRefusedNotificationType} from '../../../notifications/nickname-change-refused-notification-type';
import {AcceptedCoverPhotoNotificationType} from '../../../notifications/accepted-cover-photo-notification-type';
import {RefusedCoverPhotoNotificationType} from '../../../notifications/refused-cover-photo-notification-type';
import {BoosterExpiredNotificationType} from '../../../notifications/booster-expired-notification-type';
import {BoosterActivatedNotificationType} from '../../../notifications/booster-activated-notification-type';
import {EmailCertifiedNotificationType} from '../../../notifications/email-certified-notification-type';
import {EmailCertifiedConfirmedNotificationType} from '../../../notifications/email-certified-confirmed-notification-type';
import {MobileChangeCertifiedNotificationType} from '../../../notifications/mobile-change-certified-notification-type';
import {BillingReminderNotificationType} from '../../../notifications/billing-reminder-notification-type';

@Component({
    selector: 'app-notification-container',
    templateUrl: './notification-container.component.html',
    styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent implements OnInit {

    @Input() notification: Notification;

    notificationType: AbstractNotificationType;

    translations: any[];

    metaData: any;

    constructor(
        private injector: Injector,
        private notificationService: NotificationService,
        private router: Router,
        private siteService: SiteService,
        private translate: TranslateService,
        private currentUserService: CurrentUserService
    ) {}

    ngOnInit(): void {
        this.notificationType = this.getNotification();
        this.notificationType.setNotification(this.notification);

        this.translations = [
            this.translate.instant(this.notificationType.getText(), this.getMetaData())
        ];
        const nickname = this.notification.getFromProfile() ? this.notification.getFromProfile().getNickname() : null;
        if (nickname && this.translations[0].includes('[nickname]')) {
            this.translations = this.translations[0].split('[nickname]');
            this.translations.splice(1, 0, nickname);
        }
    }

    delete() {
        this.notificationService.delete(this.notification);
    }

    getMetaData(): any {
        if (!this.metaData) {
            const params: any = {
                site: this.siteService.getSite().getName(),
                nickname: this.notification.getFromProfile() ? this.notification.getFromProfile().getNickname() : ''
            };

            this.metaData = {...params, ...this.notificationType.getMetaData()};
        }

        return this.metaData;
    }

    btnClick(button: NotificationButton) {
        button.click();
        this.notificationService.update(this.notification);
    }

    btnClass() {
        return this.notification.getWasRead() ? 'btn-dark' : 'btn-light';
    }

    private getNotification() {
        switch (this.notification.type) {
            case RegistrationNotificationType.TYPE:
                return new RegistrationNotificationType(this.injector);
            case NewMessageNotificationType.TYPE:
                return new NewMessageNotificationType(this.injector);
            case ReceivedKissNotificationType.TYPE:
                return new ReceivedKissNotificationType(this.injector);
            case ReceivedFriendRequestNotificationType.TYPE:
                return new ReceivedFriendRequestNotificationType(this.injector);
            case PaymentTransactionNotificationType.TYPE:
                return new PaymentTransactionNotificationType(this.injector);
            case UpgradePremiumNotificationType.TYPE:
                return new UpgradePremiumNotificationType(this.injector);
            case CustomerServiceNotificationType.TYPE:
                return new CustomerServiceNotificationType(this.injector);
            case AcceptedReceivedFriendRequestNotificationType.TYPE:
                return new AcceptedReceivedFriendRequestNotificationType(this.injector);
            case SentFriendRequestNotificationType.TYPE:
                return new SentFriendRequestNotificationType(this.injector);
            case AcceptedSentFriendRequestNotificationType.TYPE:
                return new AcceptedSentFriendRequestNotificationType(this.injector);
            case DiscoverCompatibilityTestNotificationType.TYPE:
                return new DiscoverCompatibilityTestNotificationType(this.injector);
            case InvitedCompatibilityTestNotificationType.TYPE:
                return new InvitedCompatibilityTestNotificationType(this.injector);
            case SpeedDatingNotificationType.TYPE:
                return new SpeedDatingNotificationType(this.injector);
            case ActivatedSpeedDatingNotificationType.TYPE:
                return new ActivatedSpeedDatingNotificationType(this.injector);
            case AddedToFavoriteNotificationType.TYPE:
                return new AddedToFavoriteNotificationType(this.injector);
            case RegularVisitNotificationType.TYPE:
                return new RegularVisitNotificationType(this.injector);
            case GotCrushNotificationType.TYPE:
                return new GotCrushNotificationType(this.injector);
            case BirthdayNotificationType.TYPE:
                return new BirthdayNotificationType(this.injector);
            case AskedMorePhotoNotificationType.TYPE:
                return new AskedMorePhotoNotificationType(this.injector);
            case UploadProfilePhotoNotificationType.TYPE:
                return new UploadProfilePhotoNotificationType(this.injector);
            case WeeklyVisitNotificationType.TYPE:
                return new WeeklyVisitNotificationType(this.injector);
            case HasAddedPhotoNotificationType.TYPE:
                return new HasAddedPhotoNotificationType(this.injector);
            case WarningModerationNotificationType.TYPE:
                return new WarningModerationNotificationType(this.injector);
            case AcceptedPhotoNotificationType.TYPE:
                return new AcceptedPhotoNotificationType(this.injector);
            case RefusedPhotoNotificationType.TYPE:
                return new RefusedPhotoNotificationType(this.injector);
            case AcceptedProfilePhotoNotificationType.TYPE:
                return new AcceptedProfilePhotoNotificationType(this.injector);
            case RefusedProfilePhotoNotificationType.TYPE:
                return new RefusedProfilePhotoNotificationType(this.injector);
            case NicknameChangeNotificationType.TYPE:
                return new NicknameChangeNotificationType(this.injector);
            case NicknameChangeConfirmedNotificationType.TYPE:
                return new NicknameChangeConfirmedNotificationType(this.injector);
            case AcceptedPresentationNotificationType.TYPE:
                return new AcceptedPresentationNotificationType(this.injector);
            case RefusedPresentationNotificationType.TYPE:
                return new RefusedPresentationNotificationType(this.injector);
            case ProfileCertifiedNotificationType.TYPE:
                return new ProfileCertifiedNotificationType(this.injector);
            case PasswordChangeNotificationType.TYPE:
                return new PasswordChangeNotificationType(this.injector);
            case PasswordChangeConfirmedNotificationType.TYPE:
                return new PasswordChangeConfirmedNotificationType(this.injector);
            case MobileChangeConfirmedNotificationType.TYPE:
                return new MobileChangeConfirmedNotificationType(this.injector);
            case EmailChangeNotificationType.TYPE:
                return new EmailChangeNotificationType(this.injector);
            case EmailChangeConfirmedNotificationType.TYPE:
                return new EmailChangeConfirmedNotificationType(this.injector);
            case UnsubscribedNotificationType.TYPE:
                return new UnsubscribedNotificationType(this.injector);
            case FillProfileNotificationType.TYPE:
                return new FillProfileNotificationType(this.injector);
            case AcceptedVideoNotificationType.TYPE:
                return new AcceptedVideoNotificationType(this.injector);
            case RefusedVideoNotificationType.TYPE:
                return new RefusedVideoNotificationType(this.injector);
            case AlbumAccessRequestNotificationType.TYPE:
                return new AlbumAccessRequestNotificationType(this.injector);
            case AcceptedAlbumAccessRequestNotificationType.TYPE:
                return new AcceptedAlbumAccessRequestNotificationType(this.injector);
            case MobileChangeNotificationType.TYPE:
                return new MobileChangeNotificationType(this.injector);
            case CoinPaymentTransactionNotificationType.TYPE:
                return new CoinPaymentTransactionNotificationType(this.injector);
            case AskingMorePhotoNotificationType.TYPE:
                return new AskingMorePhotoNotificationType(this.injector);
            case AlbumAccessNotificationType.TYPE:
                return new AlbumAccessNotificationType(this.injector);
            case ProfileVisitNotificationType.TYPE:
                return new ProfileVisitNotificationType(this.injector);
            case NicknameChangeRefusedNotificationType.TYPE:
                return new NicknameChangeRefusedNotificationType(this.injector);
            case AcceptedCoverPhotoNotificationType.TYPE:
                return new AcceptedCoverPhotoNotificationType(this.injector);
            case RefusedCoverPhotoNotificationType.TYPE:
                return new RefusedCoverPhotoNotificationType(this.injector);
            case BoosterActivatedNotificationType.TYPE:
                return new BoosterActivatedNotificationType(this.injector);
            case BoosterExpiredNotificationType.TYPE:
                return new BoosterExpiredNotificationType(this.injector);
            case EmailCertifiedNotificationType.TYPE:
                return new EmailCertifiedNotificationType(this.injector);
            case EmailCertifiedConfirmedNotificationType.TYPE:
                return new EmailCertifiedConfirmedNotificationType(this.injector);
            case MobileChangeCertifiedNotificationType.TYPE:
                return new MobileChangeCertifiedNotificationType(this.injector);
            case BillingReminderNotificationType.TYPE:
                return new BillingReminderNotificationType(this.injector);
            default:
                if (!environment.production) {
                    console.log('No proper component for notification type: ' + this.notification.type);
                }
                return new OtherNotificationType(this.injector);
        }
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }
}
