<div class="container ok-ko contenu mt-4">
    <div class="row">
        <div class="col-lg-8 mx-auto text-center">
            <h1>
                <i class="far fa-frown text-danger smiley"></i>
                <br>
                Oupsssss !
                <br>
                <div [ngSwitch]="getType()">
                    <span *ngSwitchCase="'payment'">{{ 'post_payment.payment_not_working' | translate }}</span>
                    <span *ngSwitchCase="'trial'">{{ 'post_payment.try_refused' | translate }}</span>
                    <span *ngSwitchCase="'preauth'">{{ 'post_payment.preauth_refused' | translate }}</span>
                </div>
            </h1>
            <div [ngSwitch]="getType()">
                <p *ngSwitchCase="'payment'">
                    {{ 'post_payment.payment_refused' | translate }}<br>
                    <ng-container *ngIf="offer && offer.isAlternativePayment()">{{ 'post_payment.you_can_try_again_another_method' | translate }}</ng-container>
                </p>
                <p *ngSwitchCase="'trial'">
                    {{ 'post_payment.you_can_try_again' | translate }}
                </p>
                <p *ngSwitchCase="'preauth'">
                    {{ 'post_payment.preauth_refused_line1' | translate }}<br>
                    {{ 'post_payment.preauth_refused_line2' | translate }}<br>
                    <a [routerLink]="getUpsellRoute()"
                       class="btn btn-sm btn-primary m-2">{{ 'post_payment.back_homepage' | translate }}</a>
                </p>
            </div>
            <a *ngIf="offer" (click)="goToPayment()" class="btn btn-lg btn-primary d-block mx-auto">
                <i class="fas fa-undo mr-2"></i>
                <span class="text-uppercase d-none d-lg-inline">{{ 'post_payment.new_attempt' | translate }}</span>
                <span class="text-uppercase d-inline d-lg-none">{{ 'post_payment.new_attempt_mobile' | translate }}</span>
            </a>
        </div>
    </div>
    <div *ngIf="offer && offer.isAlternativePayment()" class="row mt-5">
        <div class="col-12 text-center">
            <h2 class="h1">{{ 'post_payment.alternative_payment' | translate }}</h2>
        </div>

        <div class="offset-sm-3 col-sm-6 offset-lg-2 col-lg-4 text-right">
            <a [routerLink]="'/payment/check'" [queryParams]="{id_offer: offer.getId()}" class="btn btn-lg btn-primary d-block mt-2 mx-auto btn-payment">
                <i class="fas fa-money-check mr-2"></i>
                <span class="d-none d-xl-inline-block text-uppercase">{{ 'post_payment.check_payment' | translate }}</span>
                <span class="d-inline-block d-xl-none text-uppercase">{{ 'post_payment.check_payment_mobile' | translate }}</span>
            </a>
        </div>
        <div class="offset-sm-3 col-sm-6 offset-lg-0 col-lg-4 text-left">
            <a [routerLink]="'/payment/bank-transfer'" [queryParams]="{id_offer: offer.getId()}" class="btn btn-lg btn-primary d-block mt-2 mx-auto btn-payment">
                <i class="fas fa-credit-card mr-2"></i>
                <span class="d-none d-xl-inline-block text-uppercase">{{ 'post_payment.bank_transfer_payment' | translate }}</span>
                <span class="d-inline-block d-xl-none text-uppercase">{{ 'post_payment.bank_transfer_payment_mobile' | translate }}</span>
            </a>
        </div>
    </div>
</div>
