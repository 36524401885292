import {
    AfterViewChecked,
    Component,
    DoCheck,
    ElementRef,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatService} from '../../../services/chat/chat.service';
import {ChatRoomService} from '../../../services/chat/chat-room/chat-room.service';
import {ChatUserService} from '../../../services/chat/chat-user/chat-user.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ChatTypingService} from '../../../services/chat/chat-typing/chat-typing.service';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../services/profile/profile.service';
import {User} from '../../../models/chat/user/user';
import {Room} from '../../../models/chat/room/room';
import {Subject} from 'rxjs';
import {ChatMessageService} from '../../../services/chat/chat-message/chat-message.service';

@Component({
    selector: 'app-chat-desktop',
    templateUrl: './chat-desktop.component.html',
    styleUrls: ['./chat-desktop.component.scss']
})
export class ChatDesktopComponent implements DoCheck, AfterViewChecked {

    friends: number[];
    filteredUsers: User[];
    filteredContacts: User[];
    messages = {};
    search = '';
    collapsed = {
        navLeft: false,
        navRight: false
    };
    showActionConversation = false;
    showChangeStatus = false;
    showFilterGender = false;
    showFilters = false;
    status = 1;
    maxRooms = 20;
    loadMoreUsersLoading = false;
    loadMoreMessagesLoading = false;
    private lastScrollHeight: number = null;
    private lastScrollMessagesPosition: number = null;
    private scrollNeeded = false;

    toggleEmojisSubject: Subject<void> = new Subject<void>();

    @ViewChild('messageContainer') messageContainer: ElementRef;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        public chatService: ChatService,
        public chatRoomService: ChatRoomService,
        public chatUserService: ChatUserService,
        protected chatMessageService: ChatMessageService,
        protected currentUserService: CurrentUserService,
        protected chatTypingService: ChatTypingService,
        protected modalService: ModalService,
        protected translate: TranslateService,
        protected profileService: ProfileService
    ) {
        this.filteredUsers = [];
        this.filteredContacts = [];
        this.friends = [];
        this.profileService.getRelationIds('friend', {}).subscribe(
            (friendsRes: any) => {
                this.friends = Object.values(friendsRes.data);
            }
        );
        this.messages = this.chatService.messages;
    }

    ngDoCheck(): void {
        this.filterUsers();

        if (this.chatUserService.getMe()) {
            this.status = this.chatUserService.getMe().getActivity().getStatus();
        }
    }

    filterUsers() {
        const users = [];
        const contacts = [];

        for (const user of this.chatUserService.getUsers()) {
            if (!this.currentUserService.isCurrentUser(user.getUid())
                && (user.getUsername().toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) || this.search === '')
                && !user.getActivity().isOffline()
                && this.currentUserService.getAccount().getProfile().getSearchedGender() === user.getGender()
            ) {
                (this.friends.includes(user.getUid())) ? contacts.push(user) : users.push(user);
            }
        }

        users.sort((a, b) => {
            return a.getUsername().localeCompare(b.getUsername());
        });
        contacts.sort((a, b) => {
            return a.getUsername().localeCompare(b.getUsername());
        });

        this.filteredUsers = users;
        this.filteredContacts = contacts;
    }

    getFilteredUsers() {
        return this.filteredUsers;
    }

    getFilteredContacts() {
        return this.filteredContacts;
    }

    toggleCollapse(index) {
        this.collapsed[index] = !this.collapsed[index];
    }

    getUsers(): User[] {
        return this.chatUserService.getUsers();
    }

    getCurrentRoom(): Room {
        return this.chatRoomService.getCurrentRoom();
    }

    typing(event) {
        this.chatTypingService.startTyping(event);
    }

    toggleActionConversation() {
        this.showActionConversation = !this.showActionConversation;
    }

    toggleChangeStatus() {
        this.showChangeStatus = !this.showChangeStatus;
    }

    toggleEmojis() {
        this.toggleEmojisSubject.next();
    }

    shutdownActionConversation(event: Event) {
        const target = event.target as HTMLElement;

        if (target.parentElement.id !== 'button-action-conversation') {
            this.showActionConversation = false;
        }
    }

    shutdownChangeStatus(event: Event) {
        const target = event.target as HTMLElement;

        if (target.id !== 'button-action-change-status' && target.parentElement.id !== 'button-action-change-status') {
            this.showChangeStatus = false;
        }
    }

    changeStatus() {
        if (this.chatUserService.getMe()) {
            this.chatUserService.getMe().setActivity(this.status);
            this.chatService.getSocketService().emit('change_status', {status: this.status}).then();
        }
    }

    updateSearch() {
        this.filterUsers();
    }

    ngAfterViewChecked(): void {
        const room: Room = this.chatRoomService.getCurrentRoom();

        /**
         * Auto scroll to bottom when scrollheight changed
         */
        if (this.messageContainer) {
            if (this.lastScrollHeight !== this.messageContainer.nativeElement.scrollHeight && room && room.isScrollToBottom()) {
                this.lastScrollHeight = this.messageContainer.nativeElement.scrollHeight;
                this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
                this.scrollNeeded = false;
            }
        }
    }

    onContextMenu(event: MouseEvent) {
        // do not allow right click
        event.preventDefault();
    }

    onScrollMessages(event) {
        if (event.target.scrollTop === 0 && this.lastScrollMessagesPosition > 0) {
            this.loadMoreMessages();
        }

        this.lastScrollMessagesPosition = event.target.scrollTop;
    }

    onScrollRooms(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.maxRooms += 10;
        }
    }

    onScrollUsers(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadMoreUsers();
        }
    }

    hasMoreMessages(): boolean {
        const room: Room = this.chatRoomService.getCurrentRoom();
        return room ? room.hasInfiniteScroll() : false;
    }

    loadMoreMessages() {
        const room: Room = this.chatRoomService.getCurrentRoom();
        if (room === null) {
            return;
        }

        if (this.loadMoreMessagesLoading) {
            return;
        }

        this.loadMoreMessagesLoading = true;

        const count = room.getMessages().length;
        this.chatMessageService.loadMessages(room, count, 10).then(() => {
            this.loadMoreMessagesLoading = false;
        });
    }

    loadMoreUsers() {
        if (this.loadMoreUsersLoading) {
            return;
        }

        this.loadMoreUsersLoading = true;
        this.chatUserService.loadMoreUsers().then(() => {
            setTimeout(() => {
                this.loadMoreUsersLoading = false;
            }, 1000);
        });
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule);
    }

    useBanner(): boolean {
        return this.currentUserService.useBanner();
    }

    getCurrentRoomsCount(): number {
        return Math.min(this.chatRoomService.getRoomsCount(), this.maxRooms);
    }
}
