import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Profile} from '../profile/profile';
import {Transaction} from './transaction';
import {Subscription} from './subscription';
import {Observable} from 'rxjs';
import {Settings} from './settings/settings';
import {RuleSet} from './rule-set/rule-set';
import {SexCamRule} from './sex-cam-rule';

@Injectable({
    providedIn: 'root'
})
export class Account implements Deserializable {

    id: number;
    email: string;
    mobile: string;
    idSearch: number;
    notifications: {
        visitProfile: boolean,
        requestFriend: boolean,
        sendKiss: boolean,
        friendConnection: boolean,
        newMessage: boolean,
        promote: boolean,
        receivePartnerOffer: boolean,
        howTo: boolean,
        whoAreYou: boolean,
        siteActivities: boolean
    };
    profile: Profile = new Profile();
    certifications: {
        email: boolean,
        emailToConfirm: string,
        emailBounced: boolean,
        emailDate: any,
        mobile: boolean,
        mobileDate: any
    };
    upsell: {
        unlover: {
            eligible: boolean,
            displayPopup: boolean
        },
        virilblue: {
            eligible: boolean,
            offerId: number,
            templateId: number
        }
        live: {
            eligible: boolean
            offerId: number,
            templateId: number
        }
    };
    homepageUpsellUnlover: boolean;
    freemiumStep: number;
    freemiumProfileConfirmed: boolean;
    optin: number;
    tracker: string;
    landingPageAuthorized: boolean;
    session: {
        country: string
        city: string
    };

    subscription: Subscription = new Subscription();

    transactions: Transaction[] = [];

    panicMode: boolean;

    panicObserver;

    coins = 0;

    suspension: any = null;

    ruleSet: RuleSet = new RuleSet();

    sexCamRule: SexCamRule = new SexCamRule();

    settings = new Settings();

    deserialize(input: any): this {
        Object.assign(this, input);

        this.subscription = new Subscription();
        if (input.subscription) {
            this.subscription.deserialize(input.subscription);
        }

        if (input.transactions) {
            this.setTransactions(input.transactions);
        }

        this.ruleSet = new RuleSet();
        if (input.ruleSet) {
            this.ruleSet.deserialize(input.ruleSet);
        }

        if (input.sexCamRule) {
            this.sexCamRule = new SexCamRule().deserialize(input.sexCamRule);
        }

        this.settings = new Settings();
        if (input.settings) {
            this.settings.deserialize(input.settings);
        }

        if (!this.upsell) {
            this.upsell.unlover.eligible = false;
            this.upsell.unlover.displayPopup = false;
            this.upsell.virilblue.eligible = false;
        }

        this.homepageUpsellUnlover = this.upsell.unlover.eligible && this.upsell.unlover.displayPopup;

        return this;
    }

    getId(): number {
        return this.id;
    }

    getEmail(): string {
        return this.email;
    }

    isHardbounced(): boolean {
        return this.certifications ? this.certifications.emailBounced : false;
    }

    getMobile(): string {
        return this.mobile;
    }

    getProfile(): Profile {
        return this.profile;
    }

    setProfile(profileData) {
        this.profile.deserialize(profileData);
    }

    getNotifications() {
        return this.notifications;
    }

    getCertifications() {
        return this.certifications;
    }

    hasCertifications(type): boolean {
        return this.certifications && this.certifications[type];
    }

    hasUpsellUnlover(): boolean {
        return (typeof this.upsell !== 'undefined'
            && typeof this.upsell.unlover !== 'undefined'
            && typeof this.upsell.unlover.eligible !== 'undefined')
            ? this.upsell.unlover.eligible : false;
    }

    hasUpsellVirilblue(): boolean {
        return (typeof this.upsell !== 'undefined'
            && typeof this.upsell.virilblue !== 'undefined'
            && typeof this.upsell.virilblue.eligible !== 'undefined')
            ? this.upsell.virilblue.eligible : false;
    }

    getUpsellVirilblueOfferId(): number {
        return this.upsell.virilblue.offerId;
    }

    getUpsellVirilblueTemplateId(): number {
        return this.upsell.virilblue.templateId;
    }

    hasUpsellLive(): boolean {
        return (typeof this.upsell !== 'undefined'
            && typeof this.upsell.live !== 'undefined'
            && typeof this.upsell.live.eligible !== 'undefined')
            ? this.upsell.live.eligible : false;
    }


    getUpsellLiveOfferId(): number {
        return this.upsell.live.offerId;
    }

    getUpsellLiveTemplateId(): number {
        return this.upsell.live.templateId;
    }

    showHomepageUpsellUnlover(): boolean {
        return (this.hasSubscription()
            && this.homepageUpsellUnlover
            && this.upsell.unlover.eligible
            && this.upsell.unlover.displayPopup);
    }

    setHomepageUpsellUnlover(homepageUpsellUnlover: boolean) {
        this.homepageUpsellUnlover = homepageUpsellUnlover;
    }

    setFreemiumProfileConfirmed(freemiumProfileConfirmed: boolean) {
        this.freemiumProfileConfirmed = freemiumProfileConfirmed;
    }

    getSubscription(): Subscription {
        return this.subscription;
    }

    getOptin(): number {
        return this.optin;
    }

    setTransactions(transactions) {
        this.transactions = [];

        for (const transaction of transactions) {
            this.transactions.push(new Transaction().deserialize(transaction));
        }
    }

    activeSubscription(): boolean {
        return this.subscription ? this.subscription.isActive() : false;
    }

    hasSubscription(): boolean {
        return this.subscription ? (this.subscription.isActive() || this.subscription.hasUnsubscribed()) : false;
    }

    getTracker(): string {
        return this.tracker;
    }

    hasPanicMode(): boolean {
        return this.panicMode === true;
    }

    panicModeObserver() {
        return new Observable<boolean>(observer => {
            observer.next(this.panicMode);

            this.panicObserver = observer;
        });
    }

    setPanicMode(panicMode: boolean) {
        this.panicMode = panicMode;
        this.panicObserver.next(panicMode);

        return this;
    }

    getSession() {
        return this.session;
    }

    setCoins(coins: number) {
        this.coins = coins;
    }

    getCoins(): number {
        return this.coins;
    }

    isSuspended(): boolean {
        if (!this.suspension || !this.suspension.suspended || !this.suspension.from || !this.suspension.to) {
            return false;
        }
        const now = new Date();
        const from = new Date(this.suspension.from);
        from.setHours(0);
        const to = new Date(this.suspension.to);
        to.setHours(0);
        return now >= from && now <= to;
    }

    willBeSuspended(): boolean {
        if (!this.suspension || !this.suspension.from || !this.suspension.to) {
            return false;
        }
        const now = new Date();
        const from = new Date(this.suspension.from);
        from.setHours(0);
        return now <= from;
    }

    getRuleSet(): RuleSet {
        return this.ruleSet;
    }

    getSettings(): Settings {
        return this.settings;
    }

    setRuleSet(ruleSet: any) {
        if (!ruleSet) {
            this.ruleSet = new RuleSet();
        } else {
            this.ruleSet.deserialize(ruleSet);
        }
    }

    getSexCamRule(): SexCamRule {
        return this.sexCamRule;
    }

    showSexCamMenu(): boolean {
        if (!this.sexCamRule.isMenuEnabled()) {
            return false;
        }

        return this.sexCamRule.isMenuNotRestricted() || this.hasSubscription();
    }

    showModelOnWall(): boolean {
        if (!this.sexCamRule.isWallEnabled()) {
            return false;
        }

        return this.sexCamRule.isWallNotRestricted() || this.hasSubscription();
    }
}
