import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CamModel} from '../../../models/cam-model/cam-model';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ModalService} from '../../../services/modal/modal.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../../modal/coin-payment/coin-payment.component';

@Component({
    selector: 'app-thumb-cam-model',
    templateUrl: './thumb-cam-model.component.html',
    styleUrls: ['./thumb-cam-model.component.scss']
})
export class ThumbCamModelComponent {

    @Input() camModel: CamModel;
    @Output() removeEvent = new EventEmitter<string>();

    constructor(
        private router: Router,
        private currentUser: CurrentUserService,
        private modalService: ModalService,
        private dialog: MatDialog
    ) {}

    removeModel() {
        this.removeEvent.emit(this.camModel.getId());
    }

    onLoadImage() {
        const camModel = $('app-thumb-cam-model .card').not('.in').get(0);

        $(camModel).addClass('in');
    }

    openSexCam() {
        if (!this.camModel.isOnline()) {
            return;
        }

        if (this.camModel.canPayShow(this.currentUser.getAccount().getCoins())) {
            return this.router.navigate(['/sex-cams', this.camModel.getId()]);
        } else {
            const modal = this.modalService.openSexCamCoinWarning(this.camModel);
            if (!modal) {
                return;
            }

            modal.afterClosed().subscribe((result) => {
                if (result) {
                    this.router.navigateByUrl('/sex-cams').then(() => {
                        if (result) {
                            this.dialog.open(CoinPaymentComponent, {
                                panelClass: 'modal-box',
                                maxWidth: '100%',
                                data: {action: 'coin_warning'}
                            });
                        }
                        setTimeout(() => {
                            window.scroll(0, 0);
                        }, 500);
                    });
                }
                this.modalService.closeSexCamConfirmation();
            });
        }
    }
}
