<!-- TODO implement this part when the ebook category is ready
<ul class="nav nav-tabs mb-2 onglets-ebooks">
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link active d-none d-xl-block" href="">{{ 'ebooks.categories.all' | translate }} <span class="badge badge-primary ml-2">24</span></a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link" href="">{{ 'ebooks.categories.couple' | translate }} <span class="badge badge-secondary ml-2">24</span></a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link" href="">{{ 'ebooks.categories.men' | translate }} <span class="badge badge-secondary ml-2">24</span></a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link" href="">{{ 'ebooks.categories.women' | translate }} <span class="badge badge-secondary ml-2">24</span></a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link" href="">{{ 'ebooks.categories.sexuality' | translate }} <span class="badge badge-secondary ml-2">24</span></a>
    </li>
    <li class="nav-item d-none d-xl-block">
        <a class="nav-link" href="">{{ 'ebooks.categories.seduction' | translate }} <span class="badge badge-secondary ml-2">24</span></a>
    </li>
    <li class="nav-item pl-0 pr-1 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light btn-sm d-inline-block w-100" href="">
            <span class="titre-desktop">{{ 'ebooks.categories.all' | translate }}</span>
            <span class="titre-mobile">{{ 'ebooks.categories.all_mobile' | translate }}</span> <span class="badge badge-primary ml-2">24</span>
        </a>
    </li>
    <li class="nav-item pl-1 pr-0 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light off btn-sm d-inline-block w-100" href="">
            <span class="titre-desktop">{{ 'ebooks.categories.couple' | translate }}</span>
            <span class="titre-mobile">{{ 'ebooks.categories.couple_mobile' | translate }}</span> <span class="badge badge-secondary ml-2">24</span>
        </a>
    </li>
    <li class="nav-item pl-0 pr-1 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light off btn-sm d-inline-block w-100" href="">
            <span class="titre-desktop">{{ 'ebooks.categories.men' | translate }}</span>
            <span class="titre-mobile">{{ 'ebooks.categories.men_mobile' | translate }}</span> <span class="badge badge-secondary ml-2">24</span>
        </a>
    </li>
    <li class="nav-item pl-1 pr-0 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light off btn-sm d-inline-block w-100" href="">
            <span class="titre-desktop">{{ 'ebooks.categories.women' | translate }}</span>
            <span class="titre-mobile">{{ 'ebooks.categories.women_mobile' | translate }}</span> <span class="badge badge-secondary ml-2">24</span>
        </a>
    </li>
    <li class="nav-item pl-0 pr-1 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light off btn-sm d-inline-block w-100" href="">
            {{ 'ebooks.categories.sexuality' | translate }} <span class="badge badge-secondary ml-2">24</span>
        </a>
    </li>
    <li class="nav-item pl-1 pr-0 px-lg-1 d-block d-xl-none col-6 col-lg-4 mb-1">
        <a class="btn btn-light off btn-sm d-inline-block w-100" href="">
            {{ 'ebooks.categories.seduction' | translate }} <span class="badge badge-secondary ml-2">24</span>
        </a>
    </li>
</ul>
-->
<div class="row row-ebooks mt-3">
    <div class="col-xl-2 col-md-3 col-sm-4 col-6 lesebooks" *ngFor="let eBook of eBooks">
        <div class="card card-profile text-center">
            <!-- TODO implement this part when badge is ready
            <div class="selection-ebook badge badge-warning">{{ 'ebooks.number_one' | translate }}</div>
            <div class="selection-ebook badge badge-info">{{ 'ebooks.new' | translate }}</div>
            -->
            <div class="profil">
                <a (click)="downloadFile(eBook)">
                    <img class="img-fluid card-img-profile" [src]="imageUrl(eBook.id)" alt="">
                </a>
            </div>
            <div class="card-body">
                <a (click)="downloadFile(eBook)">
                    <h5 class="card-title text-truncate">{{ eBook.title }}</h5>
                </a>
                <p>
                    <!-- TODO implement this part when the author/date is ready
                    <strong>{{ 'ebooks.author' | translate }}</strong><br>
                    JJ/MM/AAAA
                    <br>
                    -->
                    <span class="text-muted">{{ 'ebooks.price' | translate }}</span>
                </p>
                <!-- TODO implement this part when the ebook page is ready
                <div class="card-links">
                    <a [routerLink]="['/ebook/' + eBook.id]" class="btn btn-sm btn-primary btn-block mt-2 text-uppercase">{{ 'ebooks.see' | translate }}</a>
                </div>
                -->
            </div>
        </div>
    </div>
</div>
