import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {ProfileService} from '../../../../services/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-finish-signup-nickname',
  templateUrl: './finish-signup-nickname.component.html',
  styleUrls: ['./finish-signup-nickname.component.scss']
})
export class FinishSignupNicknameComponent implements OnInit {

    @Output() nextStep = new EventEmitter<number>();
    @Output() previousStep = new EventEmitter<number>();
    @Output() setStepCache = new EventEmitter<any>();
    @Input() stepCacheData;
    @Input() currentStep: number;

    nickname: string;
    invalidLengthNickname = false;
    errorMessage: string;
    suggestion: string;

    constructor(
        private currentUser: CurrentUserService,
        private profileService: ProfileService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        if (typeof this.stepCacheData !== 'undefined' && typeof this.stepCacheData.nickname !== 'undefined') {
            this.nickname = this.stepCacheData.nickname.value;
            this.invalidLengthNickname = this.stepCacheData.nickname.invalidLengthNickname;
            this.errorMessage = this.stepCacheData.nickname.errorMessage;
            this.suggestion = this.stepCacheData.nickname.suggestion;
        }
    }

    updateCacheData() {
        let cacheData: any = this.stepCacheData;

        if (typeof cacheData === 'undefined') {
            cacheData = {};
        }

        cacheData.nickname = {
            value: this.nickname,
            invalidLengthNickname: this.invalidLengthNickname,
            errorMessage: this.errorMessage,
            suggestion: this.suggestion
        };

        this.setStepCache.emit(cacheData);
    }

    next() {
        this.errorMessage = '';
        this.currentUser.getAccount().getProfile().getFieldsStatus().nickname = 'provided';
        this.updateCacheData();
        this.nextStep.emit();
    }

    previous() {
        this.updateCacheData();
        this.errorMessage = '';
        this.previousStep.emit();
    }

    updateNickname() {
        this.invalidLengthNickname = false;

        if (!this.nickname || this.nickname.length < 6) {
            this.invalidLengthNickname = true;
            this.errorMessage = this.translate.instant('on_boarding.invalid_nickname');
            return false;
        }

        if (this.nickname === this.currentUser.getAccount().getProfile().getNickname()) {
            this.next();
            return true;
        }

        this.checkNickname(true);
    }

    checkNickname(update: boolean = false) {
        this.errorMessage = '';
        this.suggestion = '';
        if (this.nickname && this.nickname.length < 6) {
            this.errorMessage = this.translate.instant('on_boarding.invalid_nickname');
            return false;
        }

        this.profileService.checkNickname(this.nickname).subscribe((response: any) => {
            if (!response.data.valid && !response.data.details.noSpecialChars) {
                this.errorMessage = this.translate.instant('on_boarding.invalid_nickname_special_character');
            } else if (!response.data.valid && !response.data.details.noAccents) {
                this.errorMessage = this.translate.instant('on_boarding.invalid_nickname_accent');
            } else if (!response.data.valid) {
                this.errorMessage = this.translate.instant('on_boarding.invalid_nickname');
            } else if (!response.data.available) {
                this.errorMessage = this.translate.instant('on_boarding.nickname_not_unique');
                if (typeof response.data.suggestion !== 'undefined' && response.data.suggestion.length > 0) {
                    this.suggestion = response.data.suggestion;
                }
            } else if (update) {
                this.update();
            }
        }, () => {
            this.errorMessage = this.translate.instant('error_list.technical_issue');
        });
    }

    update() {
        const nickname = this.nickname;
        this.profileService.putProfile({nickname}).subscribe(() => {
            this.currentUser.getAccount().getProfile().setNickname(nickname);
            this.next();
        });
    }

    getDescription() {
        let description = this.translate.instant('on_boarding.pseudo_desc');
        description += '<br class="br-mobile"> ';
        description += this.translate.instant('on_boarding.use_pseudo_form');

        return description;
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }

    useSuggestion() {
        if (this.suggestion) {
            this.nickname = this.suggestion;
            this.suggestion = '';
            this.errorMessage = '';
        }
    }
}
