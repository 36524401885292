import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-payment-refused',
    templateUrl: './virilblue-payment-refused.component.html',
    styleUrls: ['./virilblue-payment-refused.component.scss']
})
export class VirilbluePaymentRefusedComponent implements OnInit {

    constructor(
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    redirectToHome() {
        this.router.navigate(['/home']).then();
    }
}
