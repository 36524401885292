import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class SentFriendRequestNotificationType extends AbstractNotificationType {

    static readonly TYPE = 2;

    protected buttons:NotificationButton[] = [
        new NotificationButton('cancel', () => {
            this.handleFriend('remove');
            this.notification.delete();
        }),
        new NotificationButton('resend', () => {
            this.handleFriend('add');
            if (!this.notification.metaData) {
                this.notification.metaData = {
                    showButton: [true, false]
                };
            } else {
                this.notification.metaData.showButton = [true, false];
            }
        })
    ];

    private handleFriend(action: string) {
        this.actionService[action + 'Friend'](this.notification.getFromProfile().getId()).subscribe(() => {
            const text = action === 'add' ? 'friend' : 'unfriend';
            this.openModal(`contact.friend.${text}`, `contact.friend.${text}_done`, 'fa-user-plus');
        });
    }
}
