import {Component, Input} from '@angular/core';
import {Profile} from '../../../models/profile/profile';
import {ProfileService} from '../../../services/profile/profile.service';
import {Question} from '../../../models/site/question';
import {HelperService} from '../../../services/helper/helper.service';

@Component({
    selector: 'app-accordion-responses',
    templateUrl: './accordion-responses.component.html',
    styleUrls: ['./accordion-responses.component.scss']
})
export class AccordionResponsesComponent {

    @Input() question: Question;
    @Input() profile: Profile;

    collapsed = true;

    private readonly limit = 3;
    private collapseEventSubscription;

    constructor(
        private helper: HelperService,
        private profileService: ProfileService
    ) {
        this.collapseEventSubscription = this.profileService.collapseAccordions$.subscribe(() => {this.collapsed = true});
    }

    toggle() {
        this.collapsed = !this.collapsed;
    }

    shutdown() {
        if (!this.collapsed) {
            this.collapsed = true;
        }
    }

    translateGender(translationIndex: string): string {
        return this.helper.translateGender(this.profile, translationIndex, this.question.reverseGender());
    }

    getAnswers() {
        if (!this.profile.getAnswers()[this.question.getId()]) {
            this.profile.setAnswer(this.question.getId(), []);
        } else if (!Array.isArray(this.profile.getAnswers()[this.question.getId()])) {
            this.profile.setAnswer(this.question.getId(), [this.profile.getAnswers()[this.question.getId()]]);
        }

        return this.profile.getAnswers()[this.question.getId()];
    }

    updateModel($event) {
        const selectedAnswerId = isNaN($event.target.value) ? $event.target.value : +$event.target.value;
        const profileAnswerIndex = this.getAnswers().indexOf(selectedAnswerId);

        if ($event.target.checked) {
            // Checking if the value does not already exists to avoid duplicates
            if (profileAnswerIndex === -1) {
                const selectedAnswer = this.question.getAnswer(selectedAnswerId);

                if (selectedAnswer.exclusive()) {
                    this.profile.getAnswers()[this.question.getId()] = [selectedAnswerId];
                } else {
                    for (const answerIndex in this.profile.getAnswers()[this.question.getId()]) {
                        if (!this.profile.getAnswers()[this.question.getId()].hasOwnProperty(answerIndex)) {
                            continue;
                        }
                        const profileAnswer = this.question.getAnswer(this.profile.getAnswers()[this.question.getId()][answerIndex]);
                        if (profileAnswer && profileAnswer.exclusive()) {
                            this.profile.getAnswers()[this.question.getId()].splice(answerIndex, 1);
                        }
                    }
                    this.profile.getAnswers()[this.question.getId()].push(selectedAnswerId);
                }
            }
        } else if (profileAnswerIndex > -1) {
            this.profile.getAnswers()[this.question.getId()].splice(profileAnswerIndex, 1);
        }
    }

    hasSelectedValue(): boolean {
        return this.getAnswers().length > 0;
    }

    getTranslatedValueList() {
        const translatedValues = [];

        if (!this.hasSelectedValue()) {
            return ' ';
        }

        for (const value of this.getAnswers()) {
            const answer = this.question.getAnswer(value);
            if (answer) {
                translatedValues.push(
                    this.translateGender('profile_answers.' + answer.name)
                );
            }
        }

        // If the answer id are corrupted for this question we fix the question on the fly.
        if (!translatedValues.length) {
            this.profile.getAnswers()[this.question.getId()] = [];
        }

        return translatedValues.length ? translatedValues.join(', ') : this.translateGender('select_an_answer');
    }

    isChecked(answerId) {
        return this.getAnswers().includes(answerId);
    }

    canPickMore() {
        return this.getAnswers().length < this.limit;
    }
}
