import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Photo} from '../../../../models/profile/photo';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {PhotoService} from '../../../../services/profile/photo/photo.service';
import {TranslateService} from '@ngx-translate/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Album} from '../../../../models/profile/album';
import {MatDialogRef} from '@angular/material/dialog';
import {FinishSignupComponent} from '../finish-signup.component';
import {StorageService} from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-finish-signup-photo',
  templateUrl: './finish-signup-photo.component.html',
  styleUrls: ['./finish-signup-photo.component.scss']
})
export class FinishSignupPhotoComponent {

    @Output() previousStep = new EventEmitter<number>();
    @Output() finalStep = new EventEmitter<number>();
    @Input() currentStep: number;

    photoStep = 1;
    photo = new Photo();
    photoCropped: any;
    errorMessage = '';
    imageChangedEvent: any;
    imageLoaded = false;
    uploading = false;

    searchLabel: string;

    constructor(
        private currentUser: CurrentUserService,
        private photoService: PhotoService,
        private translate: TranslateService,
        private storage: StorageService,
        public dialogRef: MatDialogRef<FinishSignupComponent>
    ) {
        if (this.currentUser.getAccount().getProfile().getPhoto()) {
            this.photo.setUrl(this.currentUser.getAccount().getProfile().getPhoto());
        }

        this.searchLabel = this.translate.instant('browse');
    }

    previous() {
      this.previousStep.emit();
    }

    next() {
        this.imageLoaded = false;
        this.setPhotoStep();
        this.finalStep.emit();
    }

    setPhotoStep(step = 1) {
        this.imageLoaded = false;
        this.photoStep = step;
        this.errorMessage = '';
    }

    fileChangeEvent(event: any): void {
        if (event.target.files && event.target.files[0]) {
            this.photo.setName(event.target.files[0].name.replace(/\.[^/.]+$/, ''));
            this.imageChangedEvent = event;
            this.setPhotoStep(2);
        } else {
            console.error('onBoarding error with fileChangeEvent', event);
        }
    }

    loadImage() {
        this.imageLoaded = true;
    }

    loadImageFailed() {
        this.setPhotoStep();
        this.errorMessage = this.translate.instant('profile_picture.error_file_format');
    }

    imageCropped(event: ImageCroppedEvent) {
        this.photoCropped = event.base64;
    }

    acceptCrop() {
        if (this.uploading || !this.photoCropped) {
            return false;
        }

        this.photoStep = 3;
    }

    accept() {
        if (this.uploading || !this.photoCropped) {
            return false;
        }
        this.uploading = true;
        this.photo.setMain(true);
        const album = this.currentUser.getAccount().getProfile().getDefaultAlbumByType(Album.DEFAULT_PROFILE);
        this.photoService.create(this.photo, this.photoCropped, album).subscribe((response: any) => {
            this.uploading = false;
            if (response && response.success) {
                this.photo.setId(response.data.photoId);
                this.photo.setUrl(response.data.photoUrl);
                this.photo.setModerationStatus(Photo.MODERATION_STATUS_PENDING);
                this.currentUser.getAccount().getProfile().setPhoto(response.data.photoUrl);
                this.currentUser.getAccount().getProfile().setPhotoIsModerated(false);
                album.addPhoto(this.photo);
                setTimeout(() => {
                    this.next();
                }, 200);
            } else {
                this.errorMessage = this.translate.instant('error_list.default');
            }
        }, () => {
            this.uploading = false;
            this.errorMessage = this.translate.instant('error_list.default');
        });
    }

    getDescription(photoStep) {
        let description = this.translate.instant('profile_picture.define_photo_profile');
        description += '<br class="br-mobile"> ';
        if (photoStep === 1) {
            description += this.translate.instant('profile_picture.use_formula_bottom');
        } else if (photoStep === 2) {
            description += this.translate.instant('profile_picture.use_form_step2');
        } else if (photoStep === 3) {
            description += this.translate.instant('profile_picture.use_form_step3');
        }

        return description;
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }

    completeLater() {
        this.currentUser.getAccount().getSettings().finishPhotoLater = true;
        this.currentUser.updateSettings();
        this.currentUser.finishSignupNotification();
        this.dialogRef.close();
    }
}
