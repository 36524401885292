import {Deserializable} from '../deserializable/deserializable';
import {GroupOffer} from './group-offer';

export class Group implements Deserializable {
    id: number;
    description: string;
    descriptionMobile: string;
    billingTemplateId: number;
    templateId: number;
    offers: GroupOffer[] = [];
    image: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getOffers(): GroupOffer[] {
        return this.offers;
    }
}
