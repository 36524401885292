import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUserService} from '../user/current-user.service';
import {NotificationService} from '../notification/notification.service';

@Injectable({
    providedIn: 'root'
})
export class SearchAppearanceService {

    freemiumConfirmed = false;

    constructor(
        private router: Router,
        private currentUserService: CurrentUserService,
        private notificationService: NotificationService
    ) {
        this.freemiumConfirmed = this.currentUserService.freemiumProfileIsConfirmed();
    }

    canShowBar(): boolean {
        if (this.currentUserService.getAccount().getSettings().isClosedAlert('searchAppearance') ||
            !this.notificationService.getHasBeenVisited()) {
            return false;
        }

        // get url without parameters
        const urlTree = this.router.parseUrl(this.router.url);

        if (!urlTree.root.children['primary'] ||
            !urlTree.root.children['primary'].segments ||
            !urlTree.root.children['primary'].segments[0]) {
            return false;
        }

        const routerUrl = urlTree.root.children['primary'].segments[0].path;

        switch (routerUrl) {
            case 'home':
            case 'contacts':
            case 'ebooks':
                return true;
        }

        return false;
    }

    hideBar() {
        this.currentUserService.setClosedAlert('searchAppearance', true);
    }
}
