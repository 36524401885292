import {Photo} from '../../../../models/profile/photo';

declare let lightGallery: any;

import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalService} from '../../../../services/modal/modal.service';
import {Profile} from '../../../../models/profile/profile';
import {AlbumService} from '../../../../services/profile/album/album.service';
import {PhotoService} from '../../../../services/profile/photo/photo.service';
import {Album} from '../../../../models/profile/album';
import * as $ from 'jquery';
import {ToastrService} from 'ngx-toastr';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-album-view',
    templateUrl: './album-view.component.html',
    styleUrls: ['./album-view.component.scss']
})
export class AlbumViewComponent implements AfterViewInit {

    @Input() album: Album;
    @Input() personalProfile: boolean;
    @Input() profile: Profile;
    @Output() viewAlbums = new EventEmitter();
    @Output() updateAlbums = new EventEmitter();

    @ViewChild('lightGallery') lightGallery: ElementRef;

    constructor(
        private modalService: ModalService,
        private albumService: AlbumService,
        private photoService: PhotoService,
        private toastr: ToastrService,
        private currentUserService: CurrentUserService
    ) {}

    ngAfterViewInit() {
        const firstPhoto = $('ul.lightGallery li > a.photo-link')[0];
        if (firstPhoto) {
            firstPhoto.click();
        }
    }

    getPhotos(album: Album): Photo[] {
        return Object.values(album.getPhotos());
    }

    setPhotoProfile(photo: Photo, e: MouseEvent) {
        e.stopPropagation();
        this.modalService.openConfirmation({
            data: {
                title: this.album.isDefaultCover() ? 'albums.default_cover' : 'albums.default_profile',
                question: this.album.isDefaultCover() ? 'albums.add_photo_cover' : 'albums.add_photo_profile',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fas fa-camera'
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.updateAlbums.emit();
                this.resetMainPhoto();
                photo.setMain(result);
                this.photoService.update(photo)
                    .subscribe(() => {
                        if (this.album.isDefaultProfile()) {
                            this.profile.setPhoto(photo.getUrl());
                            this.profile.setPhotoIsModerated(!photo.isModerationPending());
                        } else {
                            this.profile.setCoverPhoto(photo.getUrl());
                        }
                    });
            }
        });
    }

    removePhoto(photo: Photo, e: MouseEvent) {
        e.stopPropagation();
        this.modalService.openConfirmation({
            data: {
                title: 'albums.delete_photo',
                question: 'albums.delete_photo_confirmation',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-trash-alt'
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.photoService
                    .delete(photo, this.profile)
                    .subscribe((response: any) => {
                        if (response && !response.success) {
                        } else {
                            if (this.album.isDefaultProfile() && photo.url === this.profile.photo) {
                                this.profile.setPhoto(null);
                                this.profile.setPhotoIsModerated(true);
                                this.currentUserService.refresh();
                            }
                            if (this.album.isDefaultCover() && photo.url === this.profile.coverPhoto) {
                                this.profile.setCoverPhoto(null);
                                this.currentUserService.refresh();
                            }
                            this.album.removePhoto(photo);
                        }
                    });
            }
        });
    }

    unSelectAlbum() {
        this.viewAlbums.emit();
    }

    addPhoto() {
        const data: object = {
            aspectRatio: this.album.isDefaultCover() ? 7 : 1
        };

        this.modalService.openUploadPhoto(this.album, data).afterClosed().subscribe((result: any) => {
            if (result && result.success && result.photo) {
                this.album.addPhoto(result.photo);
            }
        });
    }

    openGallery() {
        lightGallery(this.lightGallery.nativeElement, {
            selector: '.gallery',
            download: false
        });
    }

    isPersonal(): boolean {
        return this.personalProfile && this.album.isDefault();
    }

    samePhoto(photo: Photo): boolean {
        if (this.album.isDefaultCover()) {
            return photo.url === this.profile.coverPhoto;
        } else {
            return photo.url === this.profile.photo;
        }
    }

    openAddMorePhotoModal() {
        this.modalService.openConfirmation({
            data: {
                title: 'albums.request_more_photo',
                question: 'albums.request_more_photo_question',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-camera plus-photos',
                profile: this.profile
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.albumService.requestMorePhoto(this.profile.getId()).subscribe();
            }
        });
    }

    resetMainPhoto() {
        const photos: {} = this.album.getPhotos();

        for (let id in photos) {
            let photo: Photo = photos[id];

            if (photo.isMain()) {
                photo.setMain(false);
            }
        }
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule, this.profile.getId());
    }
}
