import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as io from 'socket.io-client';
import {Account} from '../../models/account/account';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    private io: SocketIOClient.Socket = null;

    connectToServer() {
        if (!this.io) {
            this.io = io(environment.socket.host + ':' + environment.socket.port, {
                secure: environment.socket.secure,
                forceNew: true,
                reconnection: false,
                autoConnect: false,
                query: {
                    chatVersion: environment.chatVersion.version,
                    chatType: environment.chatVersion.type
                }
            });
        }
        if (this.isDisconnected()) {
            this.removeAllListener();
            this.io.connect();
        }
    }

    disconnect() {
        this.removeAllListener();
        this.io = null;
    }

    loginUser(account: Account, token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.io) {
                this.io.emit('login', {
                    uid: account.getProfile().id,
                    token,
                    navigator: navigator.userAgent
                }, (response) => {
                    if (response.success) {
                        resolve(response);
                    } else if (typeof response.errorMessage !== 'undefined') {
                        reject({
                            errorMessage: response.errorMessage,
                            endDate: response.endDate
                        });
                    }
                });
            } else {
                reject();
            }
        });
    }

    emit(eventName, parameters) {
        return new Promise((resolve, reject) => {
            if (this.io) {
                this.io.emit(eventName, parameters, (response) => {
                    resolve(response);
                });
            } else {
                reject();
            }
        });
    }

    on(eventName, callback) {
        return this.io ? this.io.on(eventName, callback) : false;
    }

    removeAllListener() {
        return this.io ? this.io.removeAllListeners() : false;
    }

    isDisconnected() {
        return this.io ? this.io.disconnected : true;
    }

}
