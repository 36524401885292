import {Component, EventEmitter, Output} from '@angular/core';
import {Banner} from '../../../models/account/rule-set/banner';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {MatDialog} from '@angular/material/dialog';
import {CoinPaymentComponent} from '../../modal/coin-payment/coin-payment.component';

@Component({
  selector: 'app-get-subscription',
  templateUrl: './get-subscription.component.html',
  styleUrls: ['./get-subscription.component.scss']
})
export class GetSubscriptionComponent {

    @Output() onCloseGetSubscription = new EventEmitter<boolean>();

    constructor(private currentUserService: CurrentUserService, private dialog: MatDialog) {
    }

    getFooterAlert(): Banner {
        return this.currentUserService.getAccount().getRuleSet().getFooterAlert();
    }

    isSubscriptionRuleSet(): boolean {
        return this.currentUserService.getAccount().getRuleSet().isSubscription();
    }

    openLoveCoinModal() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'subscription_notification'}
        });
    }

    close() {
        this.onCloseGetSubscription.emit(true);
    }
}
