import {Component, Inject, OnInit, Optional} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-mobile-confirmation',
    templateUrl: './mobile-confirmation.component.html',
    styleUrls: ['./mobile-confirmation.component.scss']
})
export class MobileConfirmationComponent implements OnInit {

    step = 1;
    mobile: string;
    fullMobile: string;
    activationCode: number;

    editionMode: boolean;
    formDisabled = false;
    private retryTimer: any;
    timing = 0;
    timingMessage = null;
    dailyRestriction = false;

    constructor(
        private user: CurrentUserService,
        private userService: CurrentUserService,
        private translate: TranslateService,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<MobileConfirmationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
        this.mobile = this.user.getAccount().getMobile();
        this.fullMobile = null;
        this.editionMode = this.data.editionMode;
    }

    replace(index) {
        return {value: this[index]};
    }

    updateData(data = null) {
        if (data) {
            this.fullMobile = '+' + data.indicative + data.mobile;
            this.mobile = data.mobile;
        } else {
            this.fullMobile = null;
        }
    }

    updateMobile() {
        if (this.formDisabled || !this.hasMobileNumber() || this.dailyRestriction) {
            return;
        }
        this.formDisabled = true;

        this.userService.updateMobile(this.fullMobile, () => {
            this.formDisabled = false;
            this.step = 2;
            this.timing = 0;
        }, (errorCode: any) => {
            this.formDisabled = false;
            this.mobile = this.user.getAccount().getMobile();

            if (errorCode === 'sms_daily_limit') {
                this.dailyRestriction = true;
            } else if (errorCode === 'sms_interval_limit') {
                this.initRestrictionTimer(10);
            } else {
                this.initRetryTimer();
            }
        });
    }

    hasMobileNumber(): boolean {
        return this.fullMobile !== null && this.fullMobile.toString().trim() !== '';
    }

    back() {
        this.activationCode = null;
        this.step = 1;
        this.timing = 0;
        this.timingMessage = null;
    }

    validActivationCode() {
        return (
            this.activationCode &&
            !isNaN(this.activationCode) &&
            this.activationCode > 99999 &&
            this.activationCode < 1000000
        );
    }

    checkMobile() {
        if (!this.validActivationCode()) {
            return;
        }

        this.userService.checkMobile(this.activationCode).subscribe((response: any) => {
            if (response.success && typeof response.data.valid === 'undefined') {
                this.user.getAccount().mobile = response.data.mobile;
                this.user.getAccount().certifications.mobile = true;
                this.user.getAccount().certifications.mobileDate = new Date();
                this.step = 3;
            } else if (response.success && !response.data.valid) {
                const errorIndex = 'error_list.' + response.message;
                let translatedError = this.translate.instant(errorIndex);
                if (translatedError === errorIndex) {
                    translatedError = this.translate.instant('error_list.default');
                }
                this.toastr.error(translatedError);
            } else {
                const errorIndex = 'error_list.' + response.error;
                let translatedError = this.translate.instant(errorIndex);
                if (translatedError === errorIndex) {
                    translatedError = this.translate.instant('error_list.default');
                }
                this.toastr.error(translatedError);
            }
        }, () => {
            this.toastr.error(this.translate.instant('error_list.default'));
        });
    }

    isEditionMode(): boolean {
        return this.editionMode;
    }

    private initRetryTimer() {
        this.timing = 10;

        this.countDownTiming();
    }

    private initRestrictionTimer(minutes: number) {
        this.timing = 60 * minutes;

        this.countDownTiming();
    }

    private countDownTiming() {
        if (this.timing === 1) {
            this.timingMessage = this.translate.instant('mobile_conf.resend_after_1_second');
        } else if (this.timing > 1 && this.timing <= 120) {
            this.timingMessage = this.translate.instant('mobile_conf.resend_after_x_seconds', {number: this.timing});
        } else if (this.timing > 120) {
            const minutes = Math.ceil(this.timing / 60);
            this.timingMessage = this.translate.instant('mobile_conf.resend_after_x_minutes', {number: minutes});
        } else {
            this.timingMessage = null;
        }

        this.retryTimer = setTimeout(() => {
            if (this.timing > 0) {
                this.timing--;

                this.countDownTiming();
            } else {
                clearTimeout(this.retryTimer);
                this.retryTimer = null;
                this.timingMessage = null;
            }
        }, 1000);
    }
}
