import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {Router} from '@angular/router';
import {Ebook} from '../../models/ebook/ebook';
import {environment} from '../../../environments/environment';
import {StorageService} from '../../services/storage/storage.service';

@Component({
    selector: 'app-ebooks-component',
    templateUrl: './ebooks.component.html',
    styleUrls: ['./ebooks.component.scss']
})
export class EbooksComponent extends BaseComponent implements OnInit {
    eBooks: Ebook[];

    private alreadyDownloaded = [];

    constructor(
        private router: Router,
        private storageService: StorageService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        this.router.navigate(['/home']);
        return;

        this.eBooks = this.siteService.getSite().getEBooks();

        if (this.eBooks.length === 0 || !this.account.activeSubscription()) {
            this.router.navigate(['/payment'], {queryParams: {action: 'ebook_view'}});
        }
    }

    imageUrl(id: number) {
        return `/assets/img/common/ebook/${id}.jpg`;
    }

    downloadFile(ebook: Ebook) {
        if (this.alreadyDownloaded.includes(ebook.id)) {
            return;
        }
        this.alreadyDownloaded.push(ebook.id);

        const url = environment.backendUrl + '/ebook/' + ebook.id + '?auth_token=' + encodeURIComponent(this.storageService.get('token'));
        window.open(url);
    }
}
