import {Component, Injector} from '@angular/core';
import {Site} from '../../models/site/site';
import {SiteService} from '../../services/site/site.service';
import {Account} from '../../models/account/account';
import {CurrentUserService} from '../../services/user/current-user.service';
import {QuestionsAnswers} from '../../models/site/questions-answers';

@Component({
    template: ''
})
export class BaseComponent {

    /**
     * @number: currentYear
     * @description: eq.: 2020
     */
    currentYear: number;

    /**
     * Site service
     */
    protected siteService: SiteService;

    /**
     * Current user service
     */
    protected currentUserService: CurrentUserService;

    /**
     * Constructor
     */
    constructor(
        injector: Injector
    ) {
        this.siteService = injector.get(SiteService);
        this.currentUserService = injector.get(CurrentUserService);

        this.currentYear = (new Date()).getFullYear();
    }

    get site(): Site {
        return this.siteService.getSite();
    }

    get account(): Account {
        return this.currentUserService.getAccount();
    }

    get questionsAnswers(): QuestionsAnswers {
        return this.siteService.getQuestionsAnswers();
    }
}
