<app-modal>
    <ul *ngIf="step === 1 && pickPhotoOnAlbum" class="nav nav-tabs mt-2">
        <li class="nav-item w-50 text-center">
            <a class="nav-link active show" id="nav-telecharger-tab" data-toggle="tab" href="#nav-telecharger" role="tab" aria-controls="nav-telecharger" aria-selected="true">
                <i class="fas fa-download"></i> {{ 'profile_picture.upload_1' | translate }}
            </a>
        </li>
        <li class="nav-item w-50 text-center">
            <a class="nav-link" id="nav-photos-tab" data-toggle="tab" href="#nav-photos" role="tab" aria-controls="nav-photos" aria-selected="false">
                <i class="fas fa-image"></i> {{ 'my_photos' | translate }}
            </a>
        </li>
    </ul>
    <div [class.first-step]="step === 1" [class.other-step]="step !== 1" class="modal-body">
        <div *ngIf="step === 1" class="container">
            <div class="tab-pane fade active show" id="nav-telecharger" role="tabpanel" aria-labelledby="nav-telecharger-tab">
                <app-upload-photo-create [albumType]="albumType" [album]="album" (imageChangedEvent)="setImageChangedEvent($event)"></app-upload-photo-create>
            </div>
            <div class="tab-pane fade" id="nav-photos" role="tabpanel" aria-labelledby="nav-photos-tab">
                <app-pick-on-album [albumType]="albumType" (photoSelectedEvent)="setPhotoSelectedEvent($event)"></app-pick-on-album>
            </div>
        </div>
        <div *ngIf="step === 2" class="container mt-4">

            <p class="infos w-100 text-center" [innerHTML]="getDescription(2)"></p>

            <div class="crop-area text-center">
                <div *ngIf="!imageLoaded" class="loading mt-5">
                    <i class="fa fa-spinner fa-pulse fa-6x"></i>
                </div>

                <image-cropper
                    *ngIf="!uploading"
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="aspectRatio"
                    format="png"
                    [imageQuality]="90"
                    [resizeToWidth]="aspectRatio === 7 ? 1400 : 600"
                    [resizeToHeight]="600"
                    (imageLoaded)="loadImage()"
                    (imageCropped)="imageCropped($event)"
                    (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
            </div>
        </div>
        <div *ngIf="step==3" class="container changer-photo text-center  mt-4">

            <div class="col-12">
                <p class="infos w-100 text-center" [innerHTML]="getDescription(3)"></p>
            </div>
            <div class="col-12">
                <div class="crop-photo">
                    <img [src]="getPhotoPicture()" [ngClass]="{'w-80': albumType == 1, 'w-100': albumType == 2}"/>
                    <a (click)="clear()" class="btn btn-dark webcam"><i class="fas fa-camera"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button [mat-dialog-close]="true" class="btn btn-dark btn-sm mr-2 text-uppercase">
            {{ 'cancel' | translate }}
        </button>

        <a (click)="submit()" [class.disabled]="disabled" class="btn btn-primary btn-sm text-uppercase">
            <i *ngIf="uploading" class="fa fa-spinner fa-pulse fa-1x"></i>
            <span *ngIf="step === 1">{{ 'send' | translate }}</span>
            <span *ngIf="step === 2">{{ 'confirm' | translate }}</span>
            <span *ngIf="step === 3">{{ 'validate' | translate }}</span>
        </a>
    </div>
</app-modal>
