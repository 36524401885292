import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoaderService} from '../../services/loader/loader.service';

@Component({
    selector: 'app-navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {

    @Output() onIgnore = new EventEmitter<object>();

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(false);
    }

    ignoreOutOfDateBrowser() {
        this.onIgnore.emit();
    }

}
