import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class SpeedDatingNotificationType extends AbstractNotificationType {

    static readonly TYPE = 35;

    protected buttons:NotificationButton[] = [
        new NotificationButton('participate', () => {
            this.goTo('speed-dating');
        })
    ];

    getIcon(): string {
        return 'fa-stopwatch';
    }
}
