<div class="mb-2 row d-flex justify-content-between">
    <div class="col-12 col-lg-6 breadcrumb-photo">
        <a [routerLink]="['/profile' + (personalProfile ? '' : '/' + profile.getId())]" [class.blurred]="hasRule('nicknameBlur')">
            {{ personalProfile ? ('menu.my_profile' | translate) : profile.getNickname() }}
        </a> › 
        <a (click)="unSelectAlbum()">{{ 'album' | translate }}</a>
         › {{ album.getTranslatedName() | translate }}
    </div>
    <div class="col-12 col-lg-6 text-lg-right" *ngIf="personalProfile">{{ 'albums.last_update' | translate }} : {{ album.updatedAt | date: 'dd/MM/yyyy - HH:mm:ss' }}</div>
</div>

<div class="demo-gallery">
    <ul #lightGallery class="lightGallery list-unstyled row mb-0">
        <li *ngFor="let photo of getPhotos(album)" class="col-xl-2 col-md-3 col-sm-4 col-6 gal gallery" [attr.data-src]="photo.url" [attr.data-sub-html]="photo.name">
            <div class="card card-profile text-center">
                <div class="profil wrapper">
                    <div class="wrappercontent">
                        <a (click)="openGallery()" class="itemsprofil cursor-pointer">
                            <img class="img-fluid w-100" [src]="photo.url" [alt]="photo.name">
                        </a>
                        <div *ngIf="photo.isModerationPending()" class="badge badge-warning badge-status">{{ 'albums.waiting_moderation' | translate }}</div>
                        <div *ngIf="photo.isModerationRejected()" class="badge badge-danger badge-status">{{ 'albums.rejected' | translate }}</div>
                        <div class="edit-photo text-right">
                            <span *ngIf="personalProfile" (click)="removePhoto(photo, $event);" class="nogal">
                                <i class="fas fa-trash-alt" [matTooltip]="'albums.delete_photo' | translate"></i>
                            </span>
                        </div>
                        <div class="photo-defaut text-right">
                            <span class="badge nogal" *ngIf="isPersonal() && !samePhoto(photo) && !photo.isModerationRejected()" (click)="setPhotoProfile(photo, $event)">
                                <i class="far fa-image text-muted"
                                   [matTooltip]="(album.isDefaultCover() ? 'albums.set_cover_photo' : 'albums.set_profile_photo') | translate"
                                ></i>
                            </span>
                            <span class="badge nogal" *ngIf="isPersonal() && samePhoto(photo)">
                                <i class="far fa-image text-success"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="col-xl-2 col-md-3 col-sm-4 col-6">
            <div class="card card-profile text-center">
                <a *ngIf="!personalProfile" class="nogal">
                    <div class="row d-flex justify-content-center align-items-center request more no-gutters">
                        <div class="col-12 text-center">
                            <i class="fas fa-camera text-primary plus-photos"></i><br>
                            <div (click)="openAddMorePhotoModal()" class="btn btn-sm btn-primary">
                                <span class="d-none d-lg-block">{{ 'albums.add_more_photo' | translate }}</span>
                                <span class="d-block d-lg-none">{{ 'albums.add_more_photo_mobile' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </a>
                <a class="nogal" *ngIf="personalProfile">
                    <div class="row d-flex justify-content-center align-items-center request more no-gutters">
                        <div class="col-12 text-center">
                            <i class="fas fa-camera text-primary plus-photos"></i><br>
                            <div (click)="addPhoto()" class="btn btn-sm btn-primary">
                                <span class="d-none d-lg-block">{{ 'albums.add_photo' | translate }}</span>
                                <span class="d-block d-lg-none">{{ 'albums.add_photo_mobile' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </a>
                <div class="profil wrapper">
                    <div class="wrappercontent">
                        <div class="itemsprofil">
                            <img class="img-fluid w-100" src="assets/img/common/blank.gif">
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
