<div class="row">
    <div class="col-lg-4">
        <app-certification-component></app-certification-component>
    </div>
    <div class="col-lg-8 order-1 order-lg-2">
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="d-none d-lg-block pr-0">
                    <i class="fas fa-ticket-alt mr-2 text-primary"></i>{{ 'account.subscription.my_formula' | translate }}
                </h2>
                <h2 class="d-block d-lg-none pr-0">
                    <i class="fas fa-ticket-alt mr-2 text-primary"></i>{{ 'account.subscription.my_formula_mobile' | translate }}
                </h2>
            </div>
            <div class="card-body">
                <div class="row mx-0">
                    <div *ngIf="account.subscription && account.subscription.isActive()" class="col-lg-4 bg-warning borderRadius text-center formule">
                        <div class="row d-flex align-items-center h-100">
                            <div class="col-12">
                                <i class="fas fa-ticket-alt"></i>
                                <br>
                                {{ 'account.subscription.my_forfait' | translate }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!account.subscription || !account.subscription.isActive()" class="col-lg-4 bg-warning borderRadius text-center formule">
                        <div class="row d-flex align-items-center h-100">
                            <div class="col-12">
                                <i class="fas fa-crown"></i>
                                <br>
                                <span >{{ 'settings.become_vip' | translate }}</span>
                                <br>
                                <a [routerLink]="['/payment']" [queryParams]="{action: 'become_vip'}" class="btn btn-secondary text-uppercase">{{ 'click_here' | translate }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 mt-4 mt-lg-0" *ngIf="account.hasSubscription()" id="subscription-infos">
                        <div class="form-group row">
                            <label class="col-sm-6 mb-0">{{ 'starting_date' | translate }}</label>
                            <div class="text-left text-sm-right col-sm-6">
                                <span>{{ account.subscription.getStart() | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                            </div>
                        </div>

                        <div *ngIf="account.subscription.isActive() && !account.subscription.hasUnsubscribed()" class="form-group row">
                            <label class="col-sm-6 mb-0">{{ 'renewal_date' | translate }}</label>
                            <div class="text-left text-sm-right col-sm-6">
                                <span>{{ account.subscription.getEnd() | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                            </div>
                        </div>

                        <div *ngIf="!account.subscription.isActive() || account.subscription.hasUnsubscribed()" class="form-group row">
                            <label class="col-sm-6 mb-0">{{ 'accessible_until' | translate }}</label>
                            <div class="text-left text-sm-right col-sm-6">
                                <span>{{ account.subscription.getEnd() | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-6 mb-0">{{ 'status' | translate }}</label>
                            <div class="ttext-left text-sm-right col-sm-6"
                                 *ngIf="account.subscription.isActive() && !account.subscription.hasUnsubscribed()">
                                <span class="text-success font-weight-bold">{{ 'active' | translate }}</span>
                                <a class="btn btn-sm btn-dark ml-2 btn-resiliation" (click)="openUnsubscribe()"
                                   data-toggle="modal"><i class="fas fa-times"></i></a>
                            </div>
                            <div class="ttext-left text-sm-right col-sm-6"
                                 *ngIf="account.subscription.isActive() && account.subscription.hasUnsubscribed()">
                                <span class="text-success font-weight-bold">{{ 'active' | translate }}
                                    , {{ 'settings.unsubscription' | translate }}</span>
                            </div>
                            <div class="ttext-left text-sm-right col-sm-6" *ngIf="!account.subscription.isActive()">
                                <span class="text-danger font-weight-bold">{{ 'stopped' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TODO: implement this section when Premium is ready
        <div class="card border-0 box mb-4">
            <div class="card-header">
                <h2 class="d-none d-lg-block pr-0"><i class="fas fa-crown mr-2 text-primary"></i>{{ 'account.subscription.premium_title' | translate }}</h2>
                <h2 class="d-block d-lg-none pr-0"><i class="fas fa-crown mr-2 text-primary"></i>{{ 'account.subscription.premium_title_mobile' | translate }}</h2>
            </div>
            <div class="card-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="w-100 text-right mt-2">
                    <a href="pdp-premium.html" class="btn btn-primary btn-sm">{{ 'account.subscription.premium_button' | translate }}</a>
                </div>
            </div>
        </div>
        -->
    </div>
</div>
