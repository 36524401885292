<ul>
    <li class="p-0">
        <div class="post-divider">
            <hr>
            <span>{{ title }} (<span>{{ users.length }}/{{ chatUserService.getConnectedUserCount() }}</span>)</span>
        </div>
    </li>
    <li *ngFor="let user of getSortedUsers()">
        <div class="utilisateur">
            <a (click)="chatService.createRoom(user.getId())">
                <div class="users-img">
                    <app-user-photo [user]="user"></app-user-photo>
                    <span class="fa-stack" [ngClass]="user.getActivity().getDynamicClass()">
                        <i *ngIf="user.getActivity().isActive()" class="fas fa-check fa-stack-1x text-white"></i>
                    </span>
                    <span *ngIf="chatUserService.isBlockedByMe(user)" class="fa-stack bg-warning">
                        <i class="fa fa-ban fa-stack-1x text-white"></i>
                    </span>
                </div>
            </a>
            <div class="users-detail text-truncate">
                <a (click)="chatService.createRoom(user.getId())">
                    <p>
                        <span [class.blurred]="hasRule('nicknameBlur')">{{user.getUsername()}}</span>
                        <i class="fas pl-2" [ngClass]="{
                            'fa-venus fem': user.isFemale(),
                            'fa-mars hom': user.isMale(),
                            'fa-venus-mars cpl': user.isCouple()
                        }"></i><br>
                        <small [class.blurred]="hasRule('detailBlur')">
                            <span *ngIf="user.getCity()" class="text-capitalize">{{user.getCity().toLowerCase()}}<span
                                *ngIf="user.getDistance()"> - {{user.getDistance() | number: '1.0-0'}}km</span></span>
                            <span *ngIf="user.getAge() >= 18">, {{user.getAge()}} {{ 'years_old' | translate }}</span>
                        </small>
                    </p>
                </a>
            </div>
            <div class="icons text-right">
                <button id="button-action-connected-{{ user.getUid() }}"
                        (click)="toggleActionConnected(user)" class="btn mr-2"><i
                    class="fas fa-ellipsis-h"></i>
                </button>
                <div (clickOutside)="shutdownActionConnected(user, $event)"
                     *ngIf="showActionConnected[user.getUid()]" id="menu-user-dial2"
                     class="menu-user-dial in connected-action"
                     style="height: auto;">
                    <app-chat-user-action [user]="user"></app-chat-user-action>
                </div>
            </div>
        </div>
    </li>
</ul>
