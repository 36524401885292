<div class="container text-center">
    <img alt="" class="img-fluid w-100 illu illu-boost" src="assets/img/modal/lc-ko.png"><br>
    <h1 class="text-danger">{{ 'beware' | translate }}</h1>
    <p class="infos w-100 text-center mb-4">
        {{ 'not_enough_lc' | translate }}<br>
        {{ 'credit_before_' + getOffer().getType() | translate }}<br>
        <br>
        <span [innerHTML]="'your_credit_amount' | translate: {coins: getCoins()}"></span><br>
        <span [innerHTML]="'coins_needed' | translate: {coinsNeeded: getOffer().getPrice()}"></span><br>
    </p>
    <a (click)="goPayment()" class="btn btn-primary btn-sm cursor-pointer">{{ 'booster.credit_my_account' | translate }}</a>
</div>
