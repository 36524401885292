import {Component, Injector, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {SupportService} from '../../../services/support/support.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-support-chat-component',
    templateUrl: './support-chat.component.html',
    styleUrls: ['./support-chat.component.scss']
})
export class SupportChatComponent extends BaseComponent implements OnDestroy {

    private openingHour = '';
    private openingMinutes = '00';
    private closingHour = '';
    private closingMinutes = '00';

    constructor(
        private translate: TranslateService,
        private supportService: SupportService,
        private injector: Injector
    ) {
        super(injector);
        this.getOpenedHourByCountry();
    }

    getOpenedHourByCountry() {
        for (const support of this.site.getPhoneSupport()) {
            if (support.default) {
                this.openingHour = this.getHourByCountry(support.openingHour);
                this.closingHour = this.getHourByCountry(support.closingHour);
            }
        }
    }

    getHourByCountry(hour: string): string {
        let hourByCountry = hour;

        if (this.site.getLocale() === 'en') {
            let hourNumber: number = parseInt(hour, 10);
            if (hourNumber > 12) {
                hourNumber -= 12;
            }
            hourByCountry = hourNumber.toString();
        }

        return hourByCountry;
    }

    getSupportChatContact(): string {
        return this.translate.instant(
            'support_chat.contact_desc',
            {
                openingHour: this.openingHour,
                openingMinutes: this.openingMinutes,
                closingHour: this.closingHour,
                closingMinutes: this.closingMinutes
            });
    }

    public ngOnDestroy() {
        this.supportService.closeChat();
    }

    chatIsLoaded() {
        return this.supportService.chatIsLoaded;
    }

    chatIsOnline() {
        return this.supportService.chatIsOnline;
    }

    chatIsOpen() {
        return this.supportService.chatIsOpen;
    }

    openChat() {
        this.supportService.openChat(this.account);
    }

    closeChat() {
        this.supportService.closeChat();
    }
}
