<div class="barrequi alert mt-4">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">×</span>
    </button>
    <a [routerLink]="['/search-appearance']" class="text-dark">
        <div class="row no-gutters">
            <div class="card col-12 border-0">
                <div class="card-body">
                    <div class="d-bloc d-sm-flex align-items-center justify-content-center">
                        <div class="quiprofils">
                            <img alt="" class="img-fluid qui-icon d-none d-md-block"
                                 src="assets/img/search-appearance/search-appearance-icon.png">
                        </div>
                        <h5 class="mb-0 w-100 text-center text-sm-left">
                            {{ 'search_appearance.who_has_visited_my_profile' | translate }}
                            <small class="d-none d-sm-block">
                                {{ 'search_appearance.discover_who_has_visited_my_profile' | translate }}
                                <i class="fas fa-angle-double-right"></i>
                            </small>
                        </h5>
                        <span class="btn btn-success btn-lg">{{ 'search_appearance.display_profiles' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>
