<div class="card border-0 box mb-4 h-100">
    <div class="card-header">
        <h2 class="d-none d-lg-block pr-0"><i class="fas fa-phone mr-2 text-primary flip"></i>{{ 'support_phone.contact_us' | translate }}</h2>
        <h2 class="d-block d-lg-none pr-0"><i class="fas fa-phone mr-2 text-primary flip"></i>{{ 'support_phone.contact_us' | translate }}</h2>
    </div>

    <div class="card-body">
        <p>{{ getSupportPhoneContact() }}</p>
        <ul class="list-group list-group-flush text-left">
            <li *ngFor="let phoneSupport of site.getPhoneSupport()" class="list-group-item pl-0">
                <img src="assets/img/common/blank.gif" class="flag-icon flag-icon-{{ getFlag(phoneSupport.country) }}" alt="getCountryTranslate(phoneSupport.country)">
                <strong>{{ getCountryTranslate(phoneSupport.country) }} :</strong> {{ phoneSupport.number }}
            </li>
        </ul>
    </div>
</div>
