import {Directive, OnInit, Input, ElementRef, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ElapsedTimeInterface, ElapsedTimeService} from '../services/elapsed-time/elapsed-time.service';

@Directive({
    selector: '[appElapsedTime]'
})
export class ElapsedTimeDirective implements OnInit, OnDestroy, ElapsedTimeInterface {

    @Input() dateTime: any;
    @Input() seen = false;

    difference: number;

    constructor(
        private elementRef: ElementRef,
        private translate: TranslateService,
        private elapsedTimeService: ElapsedTimeService
    ) {
    }

    ngOnInit() {
        this.refresh();
        this.elapsedTimeService.addTimer(this);
    }

    ngOnDestroy(): void {
        this.elapsedTimeService.removeTimer(this);
    }

    getElapsedTime(date) {
        if (!date) {
            return;
        }

        const time: number = Date.parse(date);
        this.difference = Math.round(((new Date()).getTime() - (new Date(time)).getTime()) / 1000);

        // Seconds (e.g. 32s)
        if (this.difference < 60) {
            return this.formatElapsedTime('few_moments_ago');
        }

        // Minutes (e.g. 12m)
        this.difference = Math.floor(this.difference / 60);

        if (this.difference < 60) {
            return this.formatElapsedTime('x_minute{s}_ago');
        }

        // Hours (e.g. 5h)
        this.difference = Math.floor(this.difference / 60);
        if (this.difference < 24) {
            return this.formatElapsedTime('x_hour{s}_ago');
        }

        // Date (e.g. Dec 2)
        this.difference = Math.floor(this.difference / 24);

        return this.formatElapsedTime('x_day{s}_ago');
    }

    formatElapsedTime(index) {
        const s = this.difference > 1 ? 's' : '';
        const translation = this.translate.instant('chat.' + index.replace('{s}', s), {number: this.difference});

        if (this.seen) {
            return this.translate.instant('seen') + ' ' + translation.charAt(0).toLowerCase() + translation.substr(1);
        }
        return translation;
    }

    refresh(): void {
        this.elementRef.nativeElement.innerHTML = this.getElapsedTime(this.dateTime);
    }
}
