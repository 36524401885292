import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends BaseComponent {

    constructor(injector: Injector, private router: Router) {
        super(injector);
    }

    isBasicRoute(): boolean {
        return !this.router.url.startsWith('/chat') && !this.router.url.startsWith('/payment') && !this.isSexCamsRoute();
    }

    isSexCamsRoute(): boolean {
        return this.router.url.startsWith('/sex-cams');
    }

    isHardBounced(): boolean {
        return this.currentUserService.isHardBounced();
    }

    isSuspended(): boolean {
        return this.currentUserService.getAccount().isSuspended();
    }

    showConfirmation(type: string): boolean {
        return (
            !this.showUnpaidSubscription() &&
            this.isBasicRoute() &&
            !this.isHardBounced() &&
            !this.isSuspended() &&
            !this.account.hasCertifications(type) &&
            this.currentUserService.freemiumProfileIsConfirmed() &&
            !this.currentUserService.getAccount().getSettings().isClosedAlert(type)
        );
    }

    onCloseConfirmation(close: boolean, type: string) {
        this.currentUserService.setClosedAlert(type, close);
    }

    showUnpaidSubscription(): boolean {
        return this.currentUserService.getAccount().getSubscription().isRejected();
    }
}
