import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HelperService} from '../helper/helper.service';
import {PendingAction} from '../../models/pending-action/pending-action';

@Injectable({
    providedIn: 'root'
})
export class PendingActionService {

    private readonly baseUrl = environment.backendUrl + '/pending-action';
    pendingActions: PendingAction[] = [];

    constructor(private http: HttpClient, private helperService: HelperService) {}

    storeMessageCollectionForCurrentChatUser(toProfileId: number) {
        this.pendingActions = [];
        this.http.get(`${this.baseUrl}?type=${PendingAction.MESSAGE_TYPE}&toProfileId=${toProfileId}`)
            .subscribe((response: any) => {
            if (Array.isArray(response.data)) {
                for (const data of response.data) {
                    this.pendingActions.push(new PendingAction().deserialize(data));
                }
            }
        });
    }

    getCollection(params = null) {
        let searchParams = '';
        if (params !== null) {
            searchParams = '?' + this.helperService.httpBuildQuery(params);
        }
        return this.http.get(`${this.baseUrl}${searchParams}`);
    }

    create(type: string, toProfileId: number, chatUserId: number = null, message: string = null) {
        return this.http.post(this.baseUrl, {type, toProfileId, chatUserId, message});
    }

    delete(pendingAction: PendingAction) {
        return this.http.delete(this.baseUrl + '/' + pendingAction.getId());
    }
}
