import {Component, Input, OnInit} from '@angular/core';
import {Site} from '../../../../models/site/site';
import {Album} from '../../../../models/profile/album';
import {AlbumService} from '../../../../services/profile/album/album.service';
import {Profile} from '../../../../models/profile/profile';
import {MatDialogRef} from '@angular/material/dialog';
import {AlbumModalComponent} from '../album.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-album-update-modal',
    templateUrl: './album-update.component.html',
    styleUrls: ['./album-update.component.scss']
})
export class AlbumUpdateModalComponent implements OnInit {

    @Input() site: Site;
    @Input() album: Album;
    @Input() profile: Profile;
    albumUpdated: Album;
    privacy;
    errorMessage: string;

    constructor(
        private albumService: AlbumService,
        public dialogRef: MatDialogRef<AlbumModalComponent>,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.albumUpdated = Object.assign(new Album(), this.album);

        // Radio don't support integer
        this.privacy = this.album.getPrivacy() + '';
    }

    updateAlbum() {
        if (!this.checkAlbumName()) {
            return false;
        }

        this.albumUpdated.setPrivacy(+this.privacy);

        this.albumService.update(this.albumUpdated, this.profile).subscribe((response: any) => {
            if (response && !response.success) {
                this.errorMessage = this.translate.instant('error_list.technical_issue');
            } else {
                this.album = Object.assign(this.album, this.albumUpdated);
                this.dialogRef.close();
            }
        }, () => {
            this.errorMessage = this.translate.instant('error_list.technical_issue');
        });
    }

    checkAlbumName(): boolean {
        return this.albumUpdated.getName() && !['DEFAULT_PROFILE_ALBUM', 'COVER_ALBUM'].includes(this.albumUpdated.getName());
    }
}
