import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';
import {Notification} from '../models/notification/notification';

export class EmailCertifiedNotificationType extends AbstractNotificationType {

    static readonly TYPE = 15;

    protected buttons:NotificationButton[] = [];

    setNotification(notification: Notification) {
        super.setNotification(notification);
        this.buttons.push(
            new NotificationButton('resend_confirmation_email', () => {
                if (this.notification.metaData.email) {
                    this.currentUserService.sendCode('email', this.notification.metaData.email).subscribe(() => {
                        this.goTo('pending-confirmation/email/' + this.notification.metaData.email);
                    }, () => {});
                } else {
                    this.modalService.openEmailConfirmation();
                }
            })
        );
    }

    getIcon(): string {
        return 'fa-at';
    }
}
