import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class Photo implements Deserializable {

    static readonly MODERATION_STATUS_PENDING = 0;
    static readonly MODERATION_STATUS_ACCEPTED = 1;
    static readonly MODERATION_STATUS_REJECTED = 2;

    id: number;
    name: string;
    private = false;
    url: string;
    date: Date;
    moderationStatus: number;
    status: boolean;
    main: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId(): number {
        return this.id;
    }

    setId(id) {
        this.id = +id;
    }

    getName(): string {
        return this.name;
    }

    setName(name: string) {
        this.name = name;
    }

    getPrivate(): boolean {
        return this.private;
    }

    getUrl(): string {
        return this.url;
    }

    setUrl(url: string) {
        this.url = url;
    }

    getDate(): Date {
        return this.date;
    }

    isModerationPending(): boolean {
        return this.moderationStatus === Photo.MODERATION_STATUS_PENDING;
    }

    isModerationAccepted(): boolean {
        return this.moderationStatus === Photo.MODERATION_STATUS_ACCEPTED;
    }

    isModerationRejected(): boolean {
        return this.moderationStatus === Photo.MODERATION_STATUS_REJECTED;
    }

    setModerationStatus(moderationStatus: number) {
        this.moderationStatus = moderationStatus;
    }

    getStatus(): boolean {
        return this.status;
    }

    isMain(): boolean {
        return this.main;
    }

    setMain(main: boolean) {
        this.main = main;
    }
}
