import {AfterViewInit, Component, ElementRef, Input, KeyValueDiffers, OnInit, ViewChild} from '@angular/core';
import {AlbumService} from '../../../../services/profile/album/album.service';
import {ModalService} from '../../../../services/modal/modal.service';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {Photo} from '../../../../models/profile/photo';
import {Album} from '../../../../models/profile/album';
import * as $ from 'jquery';
import {Profile} from '../../../../models/profile/profile';

declare let lightGallery: any;

@Component({
    selector: 'app-photo-slider',
    templateUrl: './photo-slider.component.html'
})
export class PhotoSliderComponent implements OnInit, AfterViewInit {

    @Input() profile: Profile;
    @ViewChild('slick') slick: SlickCarouselComponent;
    @ViewChild('lightGallery') lightGallery: ElementRef;
    @ViewChild('sizer') sizer: ElementRef;
    slideConfig = {};
    photos: Photo[];

    constructor(
        private differs: KeyValueDiffers,
        private albumService: AlbumService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.photos = this.getPhotos();
    }

    ngAfterViewInit() {
        this.initSlickConfig();
    }

    getPhotos(): Photo[] {
        const photos: Photo[] = [];

        for (const idAlbum in this.profile.getAlbums()) {
            if (this.profile.getAlbums().hasOwnProperty(idAlbum)) {
                const album: Album = this.profile.albums[idAlbum];

                for (const idPhoto in album.getPhotos()) {
                    if (album.getPhotos().hasOwnProperty(idPhoto)) {
                        const photo: Photo = album.photos[idPhoto];
                        photos.push(photo);
                    }
                }
            }
        }

        return photos;
    }

    openGallery() {
        lightGallery(this.lightGallery.nativeElement, {
            selector: '.gallery',
            download: false
        });
    }

    initSlickConfig() {
        const device: string = $(this.sizer.nativeElement).find('div:visible').data('size');
        let slidesToShow: number = this.slideConfig['slidesToShow'];

        switch (device) {
            case 'lg':
                slidesToShow = 5;
                break;
            case 'md':
                slidesToShow = 3;
                break;
            case 'sm':
                slidesToShow = 1;
                break;
        }

        if (this.slideConfig['slidesToShow'] !== slidesToShow) {
            this.slideConfig = this.getSlickConfig((this.photos.length > slidesToShow), slidesToShow);
            if (this.slick && this.slick.$instance) {
                this.slick.$instance.slick('slickSetOption', this.slideConfig, true);
            }
        }
    }

    getSlickConfig(centerMode = true, slidesToShow = 5) {
        return {
            dots: true,
            infinite: true,
            centerMode,
            slidesToShow,
            slidesToScroll: 1
        }
    }

    openAddMorePhotoModal() {
        this.modalService.openConfirmation({
            data: {
                title: 'albums.request_more_photo',
                question: 'albums.request_more_photo_question',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-camera plus-photos',
                profile: this.profile
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.albumService.requestMorePhoto(this.profile.getId()).subscribe();
            }
        });
    }
}
