import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Country} from '../models/country/country';

@Pipe({
    name: 'filterCountry'
})
export class FilterCountryPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(countries: any[], search: string = null): Country[] {
        const filteredCountries = search ? countries.filter(
            data => {
                const countryName = this.translate.instant('country_list.' + data.code.toUpperCase());
                const formattedCountryName = countryName
                                                .toLowerCase()
                                                .replace(/[\u0300-\u036f]/g, '');

                return formattedCountryName.includes(search.toLowerCase());
            }
        ) : countries;

        filteredCountries.sort((a, b) => {
            const countryNameA = this.translate.instant('country_list.' + a.code.toUpperCase());
            const countryNameB = this.translate.instant('country_list.' + b.code.toUpperCase());
            return countryNameA.localeCompare(countryNameB);
        });

        return filteredCountries;
    }
}
