import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class InvitedCompatibilityTestNotificationType extends AbstractNotificationType {

    static readonly TYPE = 39;

    protected buttons:NotificationButton[] = [
        new NotificationButton('do_test', () => {
            this.goTo('compatibility-test');
        })
    ];
}
