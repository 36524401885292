<app-modal (close)="confirm(false)">
    <div class="modal-body">
        <div class="container text-center">
            <img class="img-fluid w-100 illu illu-boost" src="assets/img/modal/lc-ko.png"><br>
            <h1 class="text-danger">Outch...<br>{{ message | translate: {nickname: nickname} }}</h1>
            <p class="infos w-100 text-center mb-4">
                {{ detail | translate: {nickname: nickname} }}<br>
            </p>
        </div>
    </div>

    <div class="modal-footer">
        <div class="row w-100">
            <div class="col-sm-6 d-none d-sm-block">
                <a (click)="confirm(false)" class="btn btn-dark btn-sm">{{ 'cancel' | translate }}</a>
            </div>

            <div class="col-sm-6">
                <a (click)="confirm(true)" class="btn btn-primary btn-sm cursor-pointer">{{ 'booster.credit_my_account' | translate }}</a>
            </div>

            <div class="col-sm-6 d-sm-none mt-3">
                <a (click)="confirm(false)" class="btn btn-dark btn-sm">{{ 'cancel' | translate }}</a>
            </div>
        </div>
    </div>
</app-modal>
