import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';
import {CoinPaymentComponent} from '../components/modal/coin-payment/coin-payment.component';

export class BillingReminderNotificationType extends AbstractNotificationType {

    static readonly TYPE = 50;

    static readonly OFFER_TYPE = ['coin', 'subscription'];

    protected buttons:NotificationButton[] = [
        new NotificationButton('new_attempt', () => {
            if (this.isCoin()) {
                this.dialog.open(CoinPaymentComponent, {
                    panelClass: 'modal-box',
                    maxWidth: '100%',
                    data: {action: 'notification'}
                });
            } else {
                this.router.navigate(['/payment'], {queryParams: {action: 'notification'}});
            }
        })
    ];

    getIcon(): string {
        return 'fa-shopping-cart';
    }

    getText(): string {
        return 'notification_type.50_' + this.getOfferType();
    }

    getOfferType(): string {
        if (!this.notification.getMetaData() || !this.notification.getMetaData()['offerType'] ||
            !BillingReminderNotificationType.OFFER_TYPE.includes(this.notification.getMetaData()['offerType'])) {
            return 'subscription';
        }

        return this.notification.getMetaData()['offerType'];
    }

    isCoin(): boolean {
        return this.getOfferType() === 'coin';
    }
}
