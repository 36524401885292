import {AfterContentChecked, Component} from '@angular/core';
import {LoginService} from '../../services/login/login.service';
import {Router} from '@angular/router';
import {InitService} from '../../services/init/init.service';
import {SearchAppearanceService} from '../../services/search-appearance/search-appearance.service';
import {SiteService} from '../../services/site/site.service';
import {SexCamsService} from '../../services/sex-cams/sex-cams.service';
import {CamModel} from '../../models/cam-model/cam-model';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterContentChecked {

    private peepingShowStatus = false;

    constructor(
        public router: Router,
        private loginService: LoginService,
        private initService: InitService,
        private searchAppearanceService: SearchAppearanceService,
        private siteService: SiteService,
        private sexCamsService: SexCamsService
    ) {
    }

    peepingShowOngoing(): boolean {
        return this.peepingShowStatus;
    }

    isChatRoute(): boolean {
        return this.router.url.startsWith('/chat');
    }

    isPaymentRoute(): boolean {
        return this.router.url.startsWith('/payment');
    }

    isUpsellRoute(): boolean {
        return this.router.url.startsWith('/upsell');
    }

    isUpsellUnloverRoute(): boolean {
        return this.router.url.startsWith('/unlover');
    }

    isFullWidth(): boolean {
        if (this.isChatRoute()) {
            return true;
        }

        if (this.isUpsellRoute()) {
            return true;
        }

        if (this.isUpsellUnloverRoute()) {
            return true;
        }

        return false;
    }

    isShowHeader(): boolean {
        if (!this.loginService.isReallyConnected())
            return false;

        return true;
    }

    isShowFooter(): boolean {
        if (!this.loginService.isReallyConnected())
            return false;

        if (this.isChatRoute()) {
            return false;
        }

        if (this.isWatchingCam()) {
            return false;
        }

        return true;
    }

    isShowHeaderAdspace(): boolean {
        if (!this.loginService.isReallyConnected())
            return false;

        if (this.isChatRoute()) {
            return false;
        }

        if (this.isPaymentRoute()) {
            return false;
        }

        if (this.isUpsellRoute()) {
            return false;
        }

        return true;
    }

    isShowStyle(): boolean {
        return this.loginService.isReallyConnected();
    }

    canShowSearchAppearanceBar(): boolean {
        return this.searchAppearanceService.canShowBar();
    }

    canShowBannerBottomMenu(): boolean {
        if (!this.loginService.isReallyConnected()) {
            return false;
        }

        return !this.router.url.startsWith('/home');
    }

    getClassLang(): string {
        return 'lang-' + this.siteService.getSite().getLocale();
    }

    isWatchingCam(): boolean {
        return (this.sexCamsService.currentCamModel && this.sexCamsService.currentCamModel instanceof CamModel);
    }

    ngAfterContentChecked() {
        this.peepingShowStatus = !!(this.sexCamsService.currentCamModel && this.sexCamsService.currentCamModel.isPeepingShow());
    }
}
