import {Component, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WINDOW} from '../../app.config';
import {CurrentUserService} from '../../services/user/current-user.service';
import {InitService} from '../../services/init/init.service';
import {ChatService} from '../../services/chat/chat.service';
import {BillingService} from '../../services/billing/billing.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    template: ''
})
export class AbstractPaymentComponent {

    loaded = false;
    paymentUrl = '';
    paymentDirectLink = '';
    isPurchaseStep = false;
    showModalFooter = true;
    paymentStatus: string = null;
    lastOfferId: number = null;
    protected document: Document;

    constructor(
        protected router: Router,
        @Inject(WINDOW) protected window: Window,
        protected currentUserService: CurrentUserService,
        protected activatedRoute: ActivatedRoute,
        protected initService: InitService,
        protected chatService: ChatService,
        protected billingService: BillingService,
        protected dialog: MatDialog
    ) {
        this.document = window.document;
    }

    protected static readQueryString(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results || !results[2]) {
            return null;
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    protected static getParams(urlRedirect) {
        return {
            freetrial: AbstractPaymentComponent.readQueryString('freetrial', urlRedirect),
            id_offer: AbstractPaymentComponent.readQueryString('id_offer', urlRedirect),
            billing_token: AbstractPaymentComponent.readQueryString('billing_token', urlRedirect),
            preauth: AbstractPaymentComponent.readQueryString('preauth', urlRedirect)
        };
    }

    protected iFrameEvent() {
        const eventMethod = this.window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventMaker = this.window[eventMethod];
        const eventMessage = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        // Listen to message from child window
        eventMaker(eventMessage, (e) => {
            switch (e.data.action) {
                case 'nextTab':
                    this.isPurchaseStep = true;
                    this.showModalFooter = e.data.showModalFooter;
                    break;
                case 'previousTab':
                    this.isPurchaseStep = false;
                    this.showModalFooter = true;
                    break;
                case 'modal':
                case 'redirect':
                    const urlRedirect = e.data.url;
                    let route = '';

                    if (urlRedirect.includes('paymentAccepted')) {
                        route = 'paymentAccepted';
                    } else if (urlRedirect.includes('paymentRefused')) {
                        route = 'paymentRefused';
                    } else if (urlRedirect.includes('paymentAlreadyAccepted')) {
                        route = 'paymentAlreadyAccepted';
                    }

                    if (route === 'paymentAccepted') {
                        this.currentUserService.refresh().then(() => {
                            this.chatService.logout(true);
                            this.initService.loadSocket();
                        });
                    }

                    if (e.data.action === 'modal') {
                        this.paymentStatus = route;
                        this.lastOfferId = +new URLSearchParams(urlRedirect).get('id_offer');
                    } else {
                        this.dialog.closeAll();
                        this.router.navigate(['/' + route], {queryParams: AbstractPaymentComponent.getParams(urlRedirect)});
                    }
                    return;
                case 'redirectHome':
                    this.dialog.closeAll();
                    this.router.navigate(['/home']);
                    break;
                case 'iframeUrl':
                    console.log(e.data.url);
                    window.location.href = e.data.url;
                    break;
                case 'resize':
                    const redirectPdpElem = this.document.getElementById('redirectPdp');
                    if (redirectPdpElem && redirectPdpElem.style) {
                        redirectPdpElem.style.height = 20 + e.data.height + 'px';
                    }

                    if (!this.loaded) {
                        this.loaded = true;
                    }
                    break;
                default:
                    break;
            }
        }, false);
    }

    setPaymentUrl(url: string) {
        this.paymentUrl = url;
        this.paymentDirectLink = url.replace('/authorize', '/secure');
    }
}
