<div class="alert alert-unpaid notification-confirmation">
    <div class="container-fluid">
        <div class="row no-gutters">
            <span class="mr-2">
                <i class="fas fa-exclamation-triangle"></i>
                <span class="picto-margin">
                    {{ 'alert_unpaid_subscription.warning' | translate }} 
                    <a [routerLink]="['/payment']" [queryParams]="{action: 'retry_unpaid'}">{{ 'alert_unpaid_subscription.click_here' | translate }}</a> 
                    {{ 'alert_unpaid_subscription.more_info' | translate }}
                </span>
            </span>
        </div>
    </div>
</div>
