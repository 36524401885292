import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HelperService} from '../helper/helper.service';
import {Notification} from '../../models/notification/notification';
import {Router} from '@angular/router';
import {CurrentUserService} from '../user/current-user.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private unreadCount: number;
    private hasBeenVisited: boolean;
    private notifications: Notification[] = null;
    private deleted = [];

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private router: Router,
        private currentUserService: CurrentUserService
    ) {}

    getNotifications(): Notification[] {
        return this.notifications;
    }

    /**
     * Fetch all Notification for the current Profile
     */
    findAll(forceReload = false) {
        // We already had results so the timeout is already running and we should not run it again.
        if (this.notifications !== null && !forceReload) {
            return;
        }

        this.fetchAllNotification();
    }

    update(notification: Notification) {
        if (!notification.wasRead) {
            notification.wasRead = true;
            if (this.unreadCount > 0) {
                --this.unreadCount;
            }
        }
        const body: any = {};
        if (notification.getMetaData() && notification.getMetaData().showButton) {
            body.showButton = notification.getMetaData().showButton;
        }

        this.http.put(environment.backendUrl + `/notification/${notification.getId()}`, body).subscribe();
    }

    getUnreadNotification(): number {
        return this.unreadCount;
    }

    delete(notification: Notification) {
        if (this.deleted.includes(notification.getId())) {
            return;
        }
        this.deleted.push(notification.getId());
        notification.delete();
        this.http.delete(environment.backendUrl + `/notification/${notification.getId()}`).subscribe(() => {
            const index = this.notifications.findIndex((data: Notification) => data.getId() === notification.getId());
            this.notifications.splice(index, 1);
        });
    }

    fetchUnreadCount() {
        this.http.get(environment.backendUrl + `/profile/status`).subscribe((response: any) => {
            this.unreadCount = response.data ? response.data.unreadCount : 0;
            this.hasBeenVisited = response.data ? response.data.hasBeenVisited : false;
            this.currentUserService.setCoins(response.data.coins);
            this.currentUserService.updateRuleSet(response.data.ruleSet);
            if (response.data && response.data.haxExpiredBooster) {
                this.currentUserService.refresh();
            }
        }, error => {
            console.error('Heartbeat error', error);
        }, () => {
            setTimeout(() => this.fetchUnreadCount(), 10000);
        });
    }

    getHasBeenVisited() {
        return this.hasBeenVisited;
    }

    private fetchAllNotification() {
        this.http.get(environment.backendUrl + `/notification`).subscribe((result :any) => {
            if (this.router.url.startsWith('/notification')) {
                const notifications = [];
                this.unreadCount = 0;
                for (const data of result.data) {
                    notifications.push(new Notification().deserialize(data));
                }
                this.notifications = notifications;

                setTimeout(() => this.fetchAllNotification(), 10000);
            }
        });
    }
}
