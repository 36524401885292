import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {Serializable} from '../../serializable/serializable';
import {City} from '../../city/city';

@Injectable({
    providedIn: 'root'
})
export class SearchFilters implements Serializable, Deserializable {

    public static MIN = 0;
    public static MAX = 1;

    nickname = '';
    city: City;
    distance = '';
    withPhoto = true;
    age = [18, 100];

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.city) {
            this.city = new City().deserialize(input.city);
        }

        return this;
    }

    serialize(): object {
        return {
            nickname: this.nickname,
            age: this.age,
            city: this.city ? this.city.serialize() : null,
            distance: this.distance,
            withPhoto: this.withPhoto
        };
    }

    setAgeMin(ageMin) {
        this.age = [+ageMin, this.getAgeMax()];
    }

    setAgeMax(ageMax) {
        this.age = [this.getAgeMin(), +ageMax];
    }

    getAgeMin(): number {
        return this.age[SearchFilters.MIN];
    }

    getAgeMax(): number {
        return this.age[SearchFilters.MAX];
    }
}
