import {Injectable} from '@angular/core';
import {Country} from '../../models/country/country';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    private europeanCountries: Country[] = [];

    private countriesDialingCodes: Country[] = [];

    constructor(
        private http: HttpClient
    ) {
    }

    setEuropeanCountries(europeanCountries: any[]) {
        for (const countryCode of europeanCountries) {
            const europeanCountry = this.getCountryDialingByCode(countryCode);

            if (europeanCountry) {
                this.europeanCountries.push(europeanCountry);
            }
        }

        return this;
    }

    setDialingCodes(dialingCodes: any[]) {
        for (const code in dialingCodes) {
            if (!!dialingCodes[code]) {
                const country = dialingCodes[code];

                this.countriesDialingCodes.push((new Country()).deserialize({
                    code: country.code,
                    indicative: country.dialingCode,
                    authorized: true,
                    name: country.name
                }));
            }
        }

        return this;
    }

    getEuropeanCountries(): Country[] {
        return this.europeanCountries;
    }

    getCountriesDialingCodes(): Country[] {
        return this.countriesDialingCodes;
    }

    getCountryDialingByCode(countryCode): Country {
        if (!countryCode) {
            return null;
        }
        return this.countriesDialingCodes.find(c => c.getCode().toUpperCase() === countryCode.toUpperCase());
    }

    getCountryNameByCode(countryCode): string {
        const siteCountry = this.getCountryDialingByCode(countryCode);

        return siteCountry ? siteCountry.getName() : '';
    }

    getCountries(): Observable<Country[]> {
        return new Observable<Country[]>((observer) => {
            this.http.get(environment.backendUrl + '/static/country').subscribe((response: any) => {
                observer.next(Object.values(response.data).map(country => new Country().deserialize(country)));
            });
        });
    }
}
