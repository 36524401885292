import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OffersTemplate1Component} from './templates/offers-template1/offers-template1.component';
import {OfferService} from '../../../services/offer/offer.service';
import {OffersTemplateComponent} from './templates/offers-template.component';
import {Group} from '../../../models/offer/group';
import {LoaderService} from '../../../services/loader/loader.service';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {OffersTemplate2Component} from './templates/offers-template2/offers-template2.component';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

    @ViewChild('templateHost', {read: ViewContainerRef, static: true}) templateHost: ViewContainerRef;

    private action: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private offerService: OfferService,
        private loaderService: LoaderService,
        private router: Router,
        private currentUserService: CurrentUserService
    ) {
    }

    ngOnInit(): void {
        this.action = this.activatedRoute.snapshot.queryParamMap.get('action');

        if (this.currentUserService.getAccount() && this.currentUserService.getAccount().activeSubscription()) {
            this.router.navigate(['/home']).then();
            return;
        }

        this.loaderService.setLoading(true);

        this.activatedRoute.params.subscribe((params) => {
            this.offerService.getGroup(params['group']).subscribe((group) => {
                this.offerService.getGroupOffers(params['group']).subscribe((offers) => {
                    group.offers = offers;

                    const templateId = this.loadTemplate(this.getTemplateId(group), group);

                    this.offerService.hitOffer(group, null, templateId, this.action).subscribe();

                    this.loaderService.setLoading(false);
                }, (error) => {
                    this.redirectToPayment();
                });
            }, (error) => {
                this.redirectToPayment();
            });
        });
    }

    private getTemplateId(group: Group): string {
        if (this.activatedRoute.snapshot.queryParams['tpl']) {
            return this.activatedRoute.snapshot.queryParams['tpl'];
        }

        if (group.templateId) {
            return group.templateId.toString();
        }

        return null;
    }

    redirectToPayment() {
        this.loaderService.setLoading(false);
        this.router.navigate(['/payment'], {queryParams: {action: this.action}}).then();
    }

    /**
     * Load the template
     * @param templateId
     * @param group
     */
    loadTemplate(templateId: string, group: Group): number {
        let component = null;

        switch (templateId) {
            default:
                component = OffersTemplate1Component;
                templateId = '1';
                break;
            case '1':
                component = OffersTemplate1Component;
                break;
            case '2':
                component = OffersTemplate2Component;
                break;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<OffersTemplateComponent>(component);
        const componentRef = this.templateHost.createComponent<OffersTemplateComponent>(componentFactory);
        componentRef.instance.group = group;

        return parseInt(templateId, 10);
    }
}
