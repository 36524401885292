<div class="container template">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <a *ngIf="backButton" [routerLink]="['/payment/offers/', group.id]" [queryParamsHandling]="'merge'"
                   class="d-inline d-sm-none btn btn-sm btn-light text-dark btn-back text-uppercase w-100 mb-2">
                    {{ 'payment.others_offers' | translate }}
                </a>

                <h1 class="w-100">
                    {{ 'payment.become_vip' | translate }}
                    <a *ngIf="backButton" [routerLink]="['/payment/offers/', group.id]" [queryParamsHandling]="'merge'"
                       class="d-none d-sm-inline btn btn-sm btn-light text-dark btn-back text-uppercase float-right">
                        {{ 'payment.others_offers' | translate }}
                    </a>
                </h1>
            </div>

            <div class="row">
                <div class="blocked">
                    <span class="mr-2 ico-blocked"><i class="fas fa-lock"></i></span>
                    <span *ngIf="group.description" class="d-none d-md-inline-block"
                          [innerHTML]="group.description | nl2br">
                    </span>
                    <span class="d-inline-block d-md-none" [innerHTML]="group.descriptionMobile | nl2br"></span>
                </div>

                <div class="card border-0 mt-3 shadow-sm rounded-left w-100">
                    <span class="offer-link d-block">
                        <div class="d-flex align-items-center justify-content-between offer">
                            <div class="number-offer order-1 rounded-left bg-success">
                                <i *ngIf="groupOffer.icon" class="mr-1" [ngClass]="groupOffer.icon"></i>
                                <span [innerHTML]="groupOffer.name | formatHtml"></span>
                            </div>
                            <div *ngIf="groupOffer.formula"
                                 class="duration text-primary order-md-2 order-3 mr-auto mt-4 mt-sm-0"
                                 [innerHTML]="groupOffer.formula | formatHtml"></div>
                            <div *ngIf="groupOffer.price" class="price order-md-3 order-1 mr-auto"
                                 [innerHTML]="groupOffer.price | formatHtml"></div>
                            <div *ngIf="groupOffer.discount"
                                 class="discount text-primary order-md-4 mr-auto"
                                 [innerHTML]="groupOffer.discount | formatHtml"></div>
                            <div class="d-none d-sm-block cta-hidden order-md-5 order-2"><span
                                class="btn-primary btn btn-sm rounded-pill text-uppercase">{{ 'payment.select' | translate }}
                                <i
                                    class="fas fa-angle-right"></i></span></div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
