import {Component, Input, OnInit} from '@angular/core';
import {Account} from '../../../models/account/account';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Transaction} from '../../../models/account/transaction';
import {TranslateService} from '@ngx-translate/core';
import {formatNumber} from '@angular/common';
import {Offer} from '../../../models/offer/offer';

@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

    @Input() account: Account;
    public transactions: Transaction[] = [];
    public loading = true;
    private readonly count = 10;
    private currentPage = 1;
    private maxPage = 0;

    constructor(
        private currentUser: CurrentUserService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.currentUser.findTransactions().subscribe((result: any) => {
            this.currentUser.account.setTransactions(result.data);
            this.maxPage = Math.ceil(result.data.length / this.count);
            this.loadTransactions();
        });
    }

    getMaxPage() {
        return this.maxPage;
    }

    getCurrentPage() {
        return this.currentPage;
    }

    loadTransactions(page = 1) {
        this.loading = true;
        this.currentPage = page;

        this.transactions = this.currentUser.account.transactions.slice(this.count * (this.currentPage - 1), this.count * this.currentPage);
        this.loading = false;
    }

    getTitle(transaction: Transaction) {
        if (transaction.getOffer().getType() === Offer.FREE_TRIAL) {
            return this.translate.instant('transaction_type.free_trial');
        }
        if (transaction.getOffer().getType() === Offer.TRIAL) {
            return this.translate.instant('transaction_type.trial');
        }
        if (transaction.getOffer().getType() === Offer.UNIQUE_PAYMENT) {
            if (transaction.getOffer().isLoveCoin()) {
                const sign = transaction.coins >= 0 ? 'credit' : 'debit';
                const coins = transaction.isMessage() ?
                    transaction.coins * transaction.getCoinOffer().getMessageCount() :
                    transaction.coins;
                return this.translate.instant('transaction_type.love_coin_' + sign, {coins});
            } else {
                return this.translate.instant('transaction_type.unique_payment');
            }
        }

        return this.translate.instant('transaction_type.' + transaction.getTitle());
    }

    getPeriod(transaction: Transaction): string {
        return (transaction.getOffer().isTrial())
            ? this.getTrialPeriod(transaction)
            : this.getRenewalPeriod(transaction);
    }

    getTrialPeriod(transaction: Transaction): string {
        let trialTime: number = transaction.getOffer().getTrialPeriod();

        if (trialTime%60 === 0 && trialTime >= 60 && trialTime < 1440) {
            // period is in hour
            trialTime = (trialTime / 60);
            const hourTranslate = +trialTime === 1 ? 'hour' : 'hours';
            return trialTime + ' ' + this.translate.instant('account.purchases.' + hourTranslate);
        }
        if (trialTime%1440 === 0 && trialTime >= 1440) {
            // period is in days
            trialTime = (trialTime / 1440);
            const dayTranslate = +trialTime === 1 ? 'day' : 'days';
            return trialTime + ' ' + this.translate.instant('account.purchases.' + dayTranslate);
        }

        return trialTime + ' ' + this.translate.instant(trialTime > 1 ? 'account.purchases.minutes' : 'account.purchases.minute');
    }

    getRenewalPeriod(transaction: Transaction) {
        const monthTranslate = +transaction.getOffer().getRenewalPeriod() === 1 ? 'month' : 'months';

        return transaction.getOffer().getRenewalPeriod() + ' ' + this.translate.instant('account.purchases.' + monthTranslate);
    }

    getCoins(): string {
        return formatNumber(this.currentUser.getAccount().getCoins(), 'en', '1.2-2').replace(/,/g, ' ');
    }
}
