import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class HasAddedPhotoNotificationType extends AbstractNotificationType {

    static readonly TYPE = 12;

    protected buttons:NotificationButton[] = [
        new NotificationButton('view_profile', () => {
            this.goTo('profile', this.notification.getFromProfile().getId());
        })
    ];

    getText() {
        return (this.notification && this.notification.getMetaData().totalPhoto > 1) ?
            'notification_type.12' :
            'notification_type.12_only_one';
    }

    getMetaData() {
        if (!this.notification) {
            return {};
        }

        return {
            totalPhoto: this.notification.getMetaData().totalPhoto
        };
    }
}
