import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ProfileComponent} from './components/profile/profile.component';
import {AccountComponent} from './components/account/account.component';
import {SearchComponent} from './components/search/search.component';
import {ChatComponent} from './components/chat/chat.component';
import {ContactComponent} from './components/contact/contact.component';
import {CoachingSeductionComponent} from './components/coaching-seduction/coaching-seduction.component';
import {EbooksComponent} from './components/ebooks/ebooks.component';
import {SupportComponent} from './components/support/support.component';
import {PaymentComponent} from './components/payment/payment.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from './services/authguard/auth-guard.service';
import {PaymentAcceptedComponent} from './components/payment-accepted/payment-accepted.component';
import {PaymentRefusedComponent} from './components/payment-refused/payment-refused.component';
import {PaymentAlreadyAcceptedComponent} from './components/payment-already-accepted/payment-already-accepted.component';
import {ConfirmEmailComponent} from './components/confirm-email/confirm-email.component';
import {NotificationComponent} from './components/notification/notification.component';
import {SearchAppearanceComponent} from './components/search-appearance/search-appearance.component';
import {PaymentRedirectComponent} from './components/payment-redirect/payment-redirect.component';
import {EbookPageComponent} from './components/ebooks/ebook-page/ebook-page.component';
import {SupportContactComponent} from './components/support/support-contact/support-contact.component';
import {ConfirmNicknameComponent} from './components/confirm-nickname/confirm-nickname.component';
import {ConfirmPasswordComponent} from './components/confirm-password/confirm-password.component';
import {PendingConfirmationComponent} from './components/pending-confirmation/pending-confirmation.component';
import {CheckComponent} from './components/payment/check/check.component';
import {BankTransferComponent} from './components/payment/bank-transfer/bank-transfer.component';
import {EmailActionResolverComponent} from './components/email-action-resolver/email-action-resolver.component';
import {FaqComponent} from './components/faq/faq.component';
import {StaticPageComponent} from './components/static-page/static-page.component';
import {OfferComponent} from './components/payment/offer/offer.component';
import {OffersComponent} from './components/payment/offers/offers.component';
import {SexCamsComponent} from './components/sex-cams/sex-cams.component';
import {ShowComponent} from './components/sex-cams/show/show.component';
import {VirilblueOneClickComponent} from './components/upsell/virilblue/virilblue-one-click/virilblue-one-click.component';
import {VirilbluePaymentAcceptedComponent} from './components/upsell/virilblue/payment-accepted/virilblue-payment-accepted.component';
import {VirilblueFinishComponent} from './components/upsell/virilblue/finish/virilblue-finish.component';
import {VirilbluePaymentRefusedComponent} from './components/upsell/virilblue/payment-refused/virilblue-payment-refused.component';
import {FinishSignupAuthGuardService} from './services/finish-signup-auth-guard/finish-signup-auth-guard.service';
import {LogoutComponent} from './components/logout/logout.component';
import {PreAuthorizationComponent} from './components/payment/pre-authorization/pre-authorization.component';
import {VirilblueCbComponent} from './components/upsell/virilblue/virilblue-cb/virilblue-cb.component';
import {VirilblueComponent} from './components/upsell/virilblue/virilblue/virilblue.component';
import {LiveOneClickComponent} from './components/upsell/live/live-one-click/live-one-click.component';
import {LivePaymentAcceptedComponent} from './components/upsell/live/live-payment-accepted/live-payment-accepted.component';
import {LivePaymentRefusedComponent} from './components/upsell/live/live-payment-refused/live-payment-refused.component';
import {LiveCbComponent} from './components/upsell/live/live-cb/live-cb.component';
import {LiveComponent} from './components/upsell/live/live/live.component';
import {UnloverComponent} from './components/upsell/unlover/unlover/unlover.component';
import {UnloverPaymentAcceptedComponent} from './components/upsell/unlover/unlover-payment-accepted/unlover-payment-accepted.component';
import {UnloverPaymentRefusedComponent} from './components/upsell/unlover/unlover-payment-refused/unlover-payment-refused.component';

const routes: Routes = [
    // pages :
    {path: 'home', component: HomeComponent, canActivate: [AuthGuardService]},
    {
        path: 'sex-cams',
        component: SexCamsComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: ':account',
                component: ShowComponent,
                canActivate: [AuthGuardService]
            }
        ]
    },
    {path: 'profile/:id', component: ProfileComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'account', component: AccountComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'search', component: SearchComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'search-appearance', component: SearchAppearanceComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'chat/:id', component: ChatComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'chat', component: ChatComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'contacts', component: ContactComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'xponsor', component: CoachingSeductionComponent, canActivate: [AuthGuardService]},
    {path: 'ebooks', component: EbooksComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'ebook/:id', component: EbookPageComponent, canActivate: [AuthGuardService, FinishSignupAuthGuardService]},
    {path: 'customer-service', component: SupportComponent, canActivate: [AuthGuardService]},
    {path: 'customer-service-contact', component: SupportContactComponent, canActivate: [AuthGuardService]},
    {path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService]},
    {path: 'payment/certification', component: PreAuthorizationComponent, canActivate: [AuthGuardService]},
    {path: 'payment/bank-transfer', component: BankTransferComponent, canActivate: [AuthGuardService]},
    {path: 'payment/check', component: CheckComponent, canActivate: [AuthGuardService]},
    {path: 'payment/offers/:group', component: OffersComponent, canActivate: [AuthGuardService]},
    {path: 'payment/offer/:id', component: OfferComponent, canActivate: [AuthGuardService]},
    {path: 'confirm-email-code/:code', component: ConfirmEmailComponent, canActivate: [AuthGuardService]},
    {path: 'confirm-nickname-code/:code', component: ConfirmNicknameComponent, canActivate: [AuthGuardService]},
    {path: 'confirm-password-code/:code', component: ConfirmPasswordComponent, canActivate: [AuthGuardService]},
    {path: 'pending-confirmation/:field', component: PendingConfirmationComponent, canActivate: [AuthGuardService]},
    {path: 'pending-confirmation/:field/:value', component: PendingConfirmationComponent, canActivate: [AuthGuardService]},
    {path: 'email-action-resolver', component: EmailActionResolverComponent, canActivate: [AuthGuardService]},
    {path: 'paymentAccepted', component: PaymentAcceptedComponent, canActivate: [AuthGuardService]},
    {path: 'paymentAlreadyAccepted', component: PaymentAlreadyAcceptedComponent, canActivate: [AuthGuardService]},
    {path: 'paymentRefused', component: PaymentRefusedComponent, canActivate: [AuthGuardService]},
    {path: 'notification', component: NotificationComponent, canActivate: [AuthGuardService]},
    {path: 'faq/:id', component: FaqComponent, canActivate: [AuthGuardService]},
    {path: 'faq', component: FaqComponent, canActivate: [AuthGuardService]},
    {path: 'payment-redirect', component: PaymentRedirectComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue', component: VirilblueComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue/offer', component: VirilblueOneClickComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue/offer-cb', component: VirilblueCbComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue/payment-accepted', component: VirilbluePaymentAcceptedComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue/payment-refused', component: VirilbluePaymentRefusedComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/virilblue/finish', component: VirilblueFinishComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/live', component: LiveComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/live/offer', component: LiveOneClickComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/live/offer-cb', component: LiveCbComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/live/payment-accepted', component: LivePaymentAcceptedComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/live/payment-refused', component: LivePaymentRefusedComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/unlover', component: UnloverComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/unlover/payment-accepted', component: UnloverPaymentAcceptedComponent, canActivate: [AuthGuardService]},
    {path: 'upsell/unlover/payment-refused', component: UnloverPaymentRefusedComponent, canActivate: [AuthGuardService]},
    {path: ':slug', component: StaticPageComponent, canActivate: [AuthGuardService]},
    // Redirection :
    {path: '**', redirectTo: '/home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
