import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class ClosedAlert implements Deserializable {

    subscription: false;
    email: false;
    mobile: false;

    deserialize(data: any): this {
        Object.assign(this, data);

        return this;
    }
}
