import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';
import {AbstractUser} from '../../user/abstract-user';
import {Activity} from '../../user/activity';

@Injectable({
    providedIn: 'root'
})
export class User extends AbstractUser implements Deserializable {

    private id: number;
    private uid: number;
    private username: string;
    private birthdate: string;
    private blockedList: object;
    private city: string;
    private lastConnection: string;
    private me = false;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.setActivity(input.status || Activity.OFFLINE);

        return this;
    }

    getId(): number {
        return this.id;
    }

    getUid(): number {
        return this.uid;
    }

    setUid(uid: number) {
        this.uid = uid;
    }

    // Alias of getUsername()
    getNickname(): string {
        return this.username;
    }

    getUsername(): string {
        return this.username;
    }

    setUsername(username: string) {
        this.username = username;
    }

    getBlockedList(): object {
        return this.blockedList;
    }

    setBlockedList(blockedList: object) {
        this.blockedList = blockedList;
    }

    isBlockedByMe(userId) {
        let isBlockedByMe = false;

        if (typeof this.blockedList === 'object' && this.blockedList !== null) {
            if (userId in this.blockedList) {
                isBlockedByMe = true;
            }
        }

        return isBlockedByMe;
    }

    getCity(): string {
        return this.city;
    }

    setCity(city: string) {
        this.city = city;
    }

    setLastConnection(lastConnection: string) {
        this.lastConnection = lastConnection;
    }

    setMe(me) {
        this.me = me;
    }

    setBirthdate(birthdate: string) {
        this.birthdate = birthdate;
    }

    getAge(): number {
        const today = new Date();
        const birthdate = new Date(this.birthdate);
        let age = today.getFullYear() - birthdate.getFullYear();
        const month = today.getMonth() - birthdate.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }

        return age;
    }
}
