import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Profile} from '../../../models/profile/profile';
import {User} from '../../../models/chat/user/user';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

    title: string;
    shortTitle: string;
    contentTitle: string;
    beforeContentTitleImage: string;
    faClass: string;
    faClassStack: any;
    question: string;
    sampleText: string;
    responses: any;
    callback;
    profile: Profile = null;
    user: User = null;
    format: any = null;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title || '';
        this.shortTitle = data.short_title || this.title;
        this.contentTitle = data.contentTitle || '';
        this.beforeContentTitleImage = data.beforeContentTitleImage || '';
        this.faClass = data.faClass || '';
        this.faClassStack = data.faClassStack;
        this.question = data.question || '';
        this.sampleText = data.sampleText || '';
        this.responses = data.responses || [];
        this.profile = data.profile || null;
        this.user = data.user || null;
        this.format = data.format || null;

        this.callback = data.callback || null;
    }

    confirm(result) {
        this.dialogRef.close(result);

        if (this.callback !== null && typeof this.callback === 'function') {
            this.callback(result);
        }
    }
}
