import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WINDOW} from '../../app.config';

@Component({
    selector: 'app-payment-redirect',
    templateUrl: './payment-redirect.component.html',
    styleUrls: ['./payment-redirect.component.scss']
})
export class PaymentRedirectComponent implements OnInit {

    redirectUrl: string = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(WINDOW) private window: Window
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.redirectUrl = params.get('url');
            this.window.location.replace(this.redirectUrl);
        });
    }
}
