import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalService} from '../../../../services/modal/modal.service';
import {Profile} from '../../../../models/profile/profile';
import {Album} from '../../../../models/profile/album';
import {AlbumService} from '../../../../services/profile/album/album.service';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-album-list',
    templateUrl: './album-list.component.html',
    styleUrls: ['./album-list.component.scss']
})
export class AlbumListComponent  {

    @Input() profile: Profile;
    @Input() personalProfile: boolean;
    @Output() album = new EventEmitter<object>();

    constructor(
        private albumService: AlbumService,
        private modalService: ModalService,
        private currentUserService: CurrentUserService
    ) {}

    getAlbums(profile: Profile): Album[] {
        const albums: Album[] = [];

        let defaultProfile;
        let defaultCover;
        const privateAlbums = [];

        for (const index in profile.getAlbums()) {
            const album = profile.getAlbums()[index];
            if (album.isDefaultProfile()) {
                defaultProfile = album;
            } else if (album.isDefaultCover()) {
                defaultCover = album;
            } else if (album.isPrivate()) {
                privateAlbums.push(album);
            } else {
                albums.push(album);
            }
        }

        if (defaultCover) {
            albums.unshift(defaultCover);
        }

        if (defaultProfile) {
            albums.unshift(defaultProfile);
        }

        return [...albums, ...privateAlbums];
    }

    selectAlbum(album: object) {
        this.album.emit(album);
    }

    openAccessRequestModal(album: Album) {
        this.modalService.openConfirmation({
            data: {
                title: 'albums.private_album_access',
                question: 'albums.private_album_access_question',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-lock',
                profile: this.profile
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.albumService.requestAlbumAccess(album).subscribe(() => {
                    this.currentUserService.successToaster('albums.private_album_access_request_success', this.profile);
                });
            }
        });
    }

    openAlbumModal(album: Album, update: boolean) {
        this.modalService.openAlbum({
            data: {
                title: update ? 'albums.modal_album.title_edit' : 'albums.modal_album.title_create',
                faClass: update ? 'fa-edit' : 'fa-images',
                update,
                album,
                profile: this.profile
            }
        });
    }

    removeAlbum(album: Album) {
        this.modalService.openConfirmation({
            data: {
                title: 'albums.delete_album_title',
                question: 'albums.delete_album_question',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-trash-alt'
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.albumService
                    .delete(album, this.profile)
                    .subscribe((response: any) => {
                        if (response && !response.success) {
                        } else {
                            this.profile.removeAlbum(album);
                        }
                    });
            }
        });
    }

    openAddMorePhotoModal() {
        this.modalService.openConfirmation({
            data: {
                title: 'albums.request_more_photo',
                question: 'albums.request_more_photo_question',
                responses: [
                    {text: 'no', result: false},
                    {text: 'yes', result: true}
                ],
                faClass: 'fa-camera plus-photos',
                profile: this.profile
            }
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.albumService.requestMorePhoto(this.profile.getId()).subscribe();
            }
        });
    }

    hasRule(rule: string): boolean {
        return this.currentUserService.hasRule(rule, this.profile.getId());
    }
}
