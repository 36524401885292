import {Component, Injector} from '@angular/core';
import {ModalService} from '../../../services/modal/modal.service';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ChatService} from '../../../services/chat/chat.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FiltersService} from '../../../services/filters/filters.service';

@Component({
    selector: 'app-menu-mobile',
    templateUrl: './menu-mobile.component.html',
    styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent extends BaseComponent {

    constructor(
        private modalService: ModalService,
        private router: Router,
        private chatService: ChatService,
        private http: HttpClient,
        private filtersService: FiltersService,
        injector: Injector
    ) {
        super(injector);
    }

    goTo(page) {
        if (page === '/profile') {
            this.currentUserService.refresh();
        }
        this.filtersService.setCurrentPage(1);
        this.router.navigate([page]);
    }

    hasUpsellUnlover(): boolean {
        return this.account.hasSubscription() && this.account.hasUpsellUnlover();
    }

    openUpsellXponsorUnlover() {
        this.modalService.openUpsellXponsorUnlover();
    }

    goToChat() {
        this.chatService.mobileTab = 'rooms';
        this.router.navigate(['chat']);
    }

    showEbooks(): boolean {
        return false;

        return (
            this.site.getEBooks().length > 0 &&
            this.account.activeSubscription()
        );
    }

    canShowWebcamMenu(): boolean {
        return this.currentUserService.getAccount().showSexCamMenu();
    }
}
