import {Component} from '@angular/core';
import {OfferTemplateComponent} from '../offer-template.component';
import {GroupOffer} from '../../../../../models/offer/group-offer';
import {Group} from '../../../../../models/offer/group';

@Component({
    selector: 'app-offer-template1',
    templateUrl: './offer-template1.component.html',
    styleUrls: ['./offer-template1.component.scss']
})
export class OfferTemplate1Component implements OfferTemplateComponent {

    group: Group;
    groupOffer: GroupOffer;
    backButton: boolean;
}
