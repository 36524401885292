import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FaqTheme} from '../../../models/faq/faq-theme';
import {SiteService} from '../site.service';
import {CountryService} from '../../country/country.service';

@Injectable({
    providedIn: 'root'
})
export class SiteContentService {

    private faqs = [];
    private tags = {};

    constructor(
        private http: HttpClient,
        private site: SiteService,
        private countryService: CountryService
    ) {}

    getFaq(): Promise<FaqTheme[]> {
        return new Promise(resolve => {
            if (this.faqs.length > 0) {
                resolve(this.faqs);
                return;
            }
            this.http.get(environment.backendUrl + '/site/content/faq').subscribe((result: any) => {
                this.faqs = [];
                for (const index in result.data.faqs) {
                    const faq = result.data.faqs[index];
                    this.faqs.push(new FaqTheme().deserialize(faq));
                }
                resolve(this.faqs);
            });
        });
    }

    sendFaqLog(questionId: number, action: string) {
        this.http.put(environment.backendUrl + '/site/content/faq/log', {questionId, action}).subscribe();
    }

    getStaticPage(slug: string): Promise<string> {
        return new Promise(resolve => {
            this.http.get(environment.backendUrl + '/site/content/static-page?slug=' + slug).subscribe((result: any) => {
                resolve(result.data.content);
            });
        });
    }

    getAdSpace(ids: string, mobile: boolean): Promise<string> {
        return new Promise(resolve => {
            this.http.get(environment.backendUrl + '/site/content/ad-space?ids=' + ids + '&mobile=' + mobile).subscribe((result: any) => {
                resolve(result && result.data ? result.data : '');
            });
        });
    }

    initTags() {
        this.tags = {
            '[[static_subsite_url]]': this.site.getSite().getStaticImgUrl(),
            '[[sitename]]': this.site.getSite().getName(),
            '[[mailcs]]': this.site.getSite().getMailSupport(),
            '[[maildp]]': this.site.getSite().getMailPrivacy(),
            '[[siteurl]]': 'www.' + this.site.getSite().getFramework(),
            '[[sitecompany]]': this.site.getSite().getCompany().getName(),
            '[[siteaddress]]': this.site.getSite().getCompany().getAddress(),
            '[[sitezip]]': this.site.getSite().getCompany().getPostalCode(),
            '[[sitecity]]': this.site.getSite().getCompany().getCity(),
            '[[sitedefaultphonenumber]]': this.site.getSite().getDefaultPhoneSupport(),
            '[[sitecompanynumber]]': this.site.getSite().getCompany().getRegistrationNumber(),
            '[[sitecustomerserviceemail]]': this.site.getSite().getMailSupport(),
            '[[sitedatapersonalemail]]': this.site.getSite().getMailPrivacy(),
            '[[siteprivacyemail]]': this.site.getSite().getMailPrivacy(),
            '[[sitecountry]]': this.countryService.getCountryNameByCode(this.site.getSite().getCompany().getCountry())
        };
    }

    replaceTag(content): string {
        for (const key in this.tags) {
            if (this.tags.hasOwnProperty(key)) {
                const regex = new RegExp(this.escapeRegex(key), 'g');
                content = content.replace(regex, this.tags[key]);
            }
        }

        return content;
    }

    escapeRegex(content: string): string {
        return content.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
}
