import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {WINDOW} from '../../app.config';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-payment-accepted',
    templateUrl: './payment-accepted.component.html',
    styleUrls: ['./payment-accepted.component.scss']
})
export class PaymentAcceptedComponent extends BaseComponent implements OnInit {

    isFreeTrial = false;
    isPreAuthorization = false;

    constructor(
        injector: Injector,
        @Inject(WINDOW) private window: Window,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const freeTrial = params.has('freetrial') ? params.get('freetrial') : '0';
            this.isFreeTrial = freeTrial === '1';
            this.isPreAuthorization = params.has('preauth') && params.get('preauth') === '1';

            if (this.isPreAuthorization) {
                this.currentUserService.account.profile.certifiedAt = new Date();
            }

            if (window.top.location !== location) {
                this.window.parent.postMessage({
                    action: 'redirect',
                    url: `paymentAccepted?freetrial=${freeTrial}`
                }, '*');
            } else {
                this.currentUserService.refresh();
            }

            if (this.isPreAuthorization) {
                const upsells = [
                    // 'unlover',
                    'live',
                    // 'virilblue'
                ];

                const upsellCascadings = upsells.sort(() => Math.random() - 0.5);

                for (const upsell of upsellCascadings) {
                    switch (upsell) {
                        case 'unlover':
                            if (this.currentUserService.getAccount().hasUpsellUnlover()) {
                                this.router.navigate(['/upsell/unlover']).then();
                                return;
                            }
                            break;
                        case 'live':
                            if (this.currentUserService.getAccount().hasUpsellLive()) {
                                this.router.navigate(['/upsell/live']).then();
                                return;
                            }
                            break;
                        case 'virilblue':
                            if (this.currentUserService.getAccount().hasUpsellVirilblue()) {
                                this.router.navigate(['/upsell/virilblue']).then();
                                return;
                            }
                            break;
                    }
                }
            }
        });
    }

    getType(): string {
        if (this.isPreAuthorization) {
            return 'preauth';
        }

        if (this.isFreeTrial) {
            return 'trial';
        }

        return 'payment';
    }
}
