import {Injectable} from '@angular/core';
import {CurrentUserService} from '../../user/current-user.service';
import {SocketService} from '../../socket/socket.service';
import {Router} from '@angular/router';
import {$t} from 'codelyzer/angular/styles/chars';

@Injectable({
    providedIn: 'root'
})
export class ChatRuleSetService {

    constructor(
        private currentUserService: CurrentUserService,
        private socketService: SocketService
    ) {}

    onUpdateRuleSet() {
        this.socketService.on('update_rule_set', (data: any) => {
            if (data.coins) {
                this.currentUserService.setCoins(data.coins);
            }
            this.currentUserService.updateRuleSet(data.ruleSet);
        });
    }
}
