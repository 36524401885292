<div class="verif show" tabindex="-1" role="dialog" id="compte-ko" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-header">
            <img class="img-fluid w-100 logo" [src]="site.getLogoUrl()" [alt]="site.getName()">
        </div>
        <div class="modal-content">
            <div class="modal-body text-center">
                <div class="warning wow animated flash pt-4 text-danger">
                    <i *ngIf="faClass" [ngClass]="faClass" class="ico-status"></i>
                    <i *ngIf="faClassStack || faClassStack !== false" [ngClass]="faClassStack || 'far fa-times-circle'"></i>
                </div>

                <div *ngIf="waitingAnimation" class="warning">
                    <div id="hourglass" class="fa-stack fa-4x">
                        <i class="fa fa-stack-1x fa-hourglass-start"></i>
                        <i class="fa fa-stack-1x fa-hourglass-half"></i>
                        <i class="fa fa-stack-1x fa-hourglass-end"></i>
                        <i class="fa fa-stack-1x fa-hourglass-end"></i>
                        <i class="fa fa-stack-1x fa-hourglass-o"></i>
                    </div>
                </div>

                <h1 class="mt-4">
                    <app-translate [text]="title" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
                </h1>

                <p *ngIf="sampleText">
                    <app-translate [text]="sampleText" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
                </p>

                <p *ngIf="question.length > 0">
                    <app-translate *ngIf="!strongQuestion" [text]="question" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>

                    <strong *ngIf="strongQuestion">
                        <app-translate [text]="question" [profile]="profile" [user]="user" [format]="format" [linkNickname]="false"></app-translate>
                    </strong>
                </p>

                <div *ngFor="let response of responses; let first = first;">
                    <div *ngIf="response.input" class="form-group">
                        <div class="input-group">
                            <span *ngIf="response.input.icon" class="input-group-text">
                                <i [ngClass]="response.input.icon" class="fas"></i>
                            </span>
                            <input
                                    [type]="response.input.type"
                                    [name]="response.input.name"
                                    class="form-control form-control-lg"
                                    [value]="response.input.value"
                                    (blur)="response.input.onChange && response.input.onChange($event)"
                                    (keyup)="response.input.onChange && response.input.onChange($event)"
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 mx-auto mb-2 px-2">
                        <a *ngIf="response.text" (click)="validation(response.result)"
                            [class.disabled]="response.control && !response.control()"
                            class="btn btn-sm btn-block mx-auto {{response.className}}"
                        >
                            {{ response.text | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
