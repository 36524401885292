<div class="modal-body">
    <div class="container verif-tel px-0">
        <p class="infos w-100 text-center">
            <span class="font-weight-bold">{{ 'albums.modal_album.moderate' | translate }}</span> {{ 'albums.modal_album.respect' | translate }}
        </p>
        <div class="form-group">
            <label>{{ 'albums.modal_album.name_label' | translate }} :</label>
            <input class="form-group form-control" placeholder="{{ 'albums.modal_album.name_placeholder' | translate }}" type="text" name="name" id="name" [(ngModel)]="albumUpdated.name" maxlength="15" required>
        </div>
        <div class="form-group">
            <label>{{ 'albums.modal_album.album_privacy' | translate }}</label>

            <div class="custom-control custom-radio">
                <input [(ngModel)]="privacy" value="1" type="radio" id="albumPrivacyPrivate" name="customRadio" class="custom-control-input" checked="">
                <label class="custom-control-label font-weight-normal" for="albumPrivacyPrivate">{{ 'albums.lock_private' | translate }}</label>
            </div>
            <div class="custom-control custom-radio">
                <input [(ngModel)]="privacy" value="2" type="radio" id="albumPrivacyFriendsOnly" name="customRadio" class="custom-control-input">
                <label class="custom-control-label font-weight-normal" for="albumPrivacyFriendsOnly">{{ 'albums.lock_friends' | translate }}</label>
            </div>

            <div class="custom-control custom-radio">
                <input [(ngModel)]="privacy" value="0" type="radio" id="albumPrivacyPublic" name="customRadio" class="custom-control-input">
                <label class="custom-control-label font-weight-normal" for="albumPrivacyPublic">{{ 'albums.lock_public' | translate }}</label>
            </div>
        </div>
        <small class="alert alert-danger mt-2" *ngIf="errorMessage">
            {{errorMessage}}
        </small>
    </div>
</div>
<div class="modal-footer">
    <button data-dismiss="modal" [mat-dialog-close]="true" class="btn btn-dark btn-sm mr-2 text-uppercase">{{ 'cancel' | translate }}</button>
    <button [disabled]="!checkAlbumName()" (click)="updateAlbum()" class="btn btn-primary btn-sm text-uppercase">{{ 'save' | translate }}</button>
</div>








<!--<div class="container-fluid">-->
<!--    <i class="fas fa-camera ico-bg"></i>-->
<!--    <div class="form-group layer">-->
<!--        <p class="w-100 text-center">-->
<!--            <i class="fas fa-info-circle text-muted"></i>-->
<!--            <span>-->
<!--                {{ 'albums.chart1' | translate }} <a href="{{ site.getTermsUrl() }}" target="_blank">{{ 'albums.chart2' | translate }}</a>.-->
<!--                {{ 'albums.chart3' | translate }} <a href="{{ site.getTermsUrl() }}" target="_blank">{{ 'albums.chart2' | translate }}</a>.-->
<!--            </span>-->
<!--        </p>-->
<!--        <div class="col-12 border p-4 bg-light" style="border-style:dashed !important;">-->
<!--            <form (submit)="createAlbum()" name="createAlbum">-->
<!--                <div class="form-group">-->
<!--                    <label for="name">{{ 'albums.modal_album.name_label' | translate }} :</label>-->
<!--                    <input class="form-group form-control" placeholder="{{ 'albums.modal_album.name_placeholder' | translate }}"-->
<!--                           type="text" name="name" id="name" [(ngModel)]="album.name" maxlength="15" required/>-->
<!--                    <label for="lockSelect">{{ 'albums.modal_album.lock_label' | translate }} :</label>-->
<!--                    <select id="lockSelect" name="lock" class="form-group form-control" [(ngModel)]="album.private">-->
<!--                        <option value="false">{{ 'albums.lock_public' | translate }}</option>-->
<!--                        <option value="true">{{ 'albums.lock_private' | translate }}</option>-->
<!--                    </select>-->
<!--                </div>-->

<!--                <p class="alert alert-danger mt-2" *ngIf="errorMessage">-->
<!--                    {{errorMessage}}-->
<!--                </p>-->

<!--                <div>-->
<!--                    <button type="submit" class="btn btn-primary btn-lg btn-block font-weight-bold text-uppercase">-->
<!--                        <i class="fas fa-check" aria-hidden="true"></i>-->
<!--                        <span>{{ 'create' | translate }}</span>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </form>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->










<!--<div class="container-fluid">-->
<!--    <i class="fas fa-camera ico-bg"></i>-->
<!--    <div class="form-group layer">-->
<!--        <p class="w-100 text-center">-->
<!--            <i class="fas fa-info-circle text-muted"></i>-->
<!--            <span>-->
<!--                {{ 'albums.chart1' | translate }} <a href="{{ site.getTermsUrl() }}" target="_blank">{{ 'albums.chart2' | translate }}</a>.-->
<!--                {{ 'albums.chart3' | translate }} <a href="{{ site.getTermsUrl() }}" target="_blank">{{ 'albums.chart2' | translate }}</a>.-->
<!--            </span>-->
<!--        </p>-->
<!--        <div class="col-12 border p-4 bg-light" style="border-style:dashed !important;">-->
<!--            <div class="form-group">-->
<!--                <label for="name">{{ 'albums.modal_album.name_label' | translate }} :</label>-->
<!--                <input class="form-group form-control" placeholder="{{ 'albums.modal_album.name_placeholder' | translate }}"-->
<!--                       type="text" id="name" [(ngModel)]="albumUpdated.name" maxlength="15"/>-->
<!--                <label for="lockSelect">{{ 'albums.modal_album.lock_label' | translate }} :</label>-->
<!--                <select id="lockSelect" class="form-group form-control" [(ngModel)]="albumUpdated.private">-->
<!--                    <option value=""></option>-->
<!--                    <option value="false">{{ 'albums.lock_public' | translate }}</option>-->
<!--                    <option value="true">{{ 'albums.lock_private' | translate }}</option>-->
<!--                </select>-->
<!--            </div>-->

<!--            <p class="alert alert-danger mt-2" *ngIf="errorMessage">-->
<!--                {{errorMessage}}-->
<!--            </p>-->

<!--            <div>-->
<!--                <a (click)="updateAlbum()" class="btn btn-primary btn-lg btn-block font-weight-bold text-uppercase">-->
<!--                    <i class="fas fa-check" aria-hidden="true"></i>-->
<!--                    <span>{{ 'edit' | translate }}</span>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
