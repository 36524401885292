import {Injectable} from '@angular/core';
import {Site} from '../../models/site/site';
import {CountryService} from '../country/country.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {QuestionsAnswers} from '../../models/site/questions-answers';

@Injectable({
    providedIn: 'root'
})
export class SiteService {

    constructor(
        private countryService: CountryService,
        private translate: TranslateService,
        private http: HttpClient
    ) {}

    private site = new Site();
    private questionsAnswers = new QuestionsAnswers();

    setSite(site) {
        this.site.deserialize(site);
    }

    setQuestionsAnswers(questionsAnswers) {
        this.questionsAnswers.deserialize(questionsAnswers);
    }

    getSite(): Site {
        return this.site;
    }

    getQuestionsAnswers(): QuestionsAnswers {
        return this.questionsAnswers;
    }

    getLocales(): any[] {
        // todo remove along with the language selector
        return [];
    }

    getOffer(idOffer: number) {
        return this.http.get(environment.backendUrl + '/offer/' + idOffer);
    }
}
