<div class="container">
    <div class="row">
        <header class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 mx-auto">
            <h1 class="colored-text">{{ site.getName() }}</h1>
            <hr class="colored-border">
            <p class="colored-text"><span class="glyphicon glyphicon-star colored-text"></span></p>
        </header>
    </div>
    <div class="content">
        <h2 class="colored-text text-center"><span>{{ 'post_payment.warn' | translate }}</span> {{ 'post_payment.already' | translate }}</h2>
        <p class="talk text-center">{{ 'post_payment.talk_profile' | translate }}<br>{{ 'post_payment.id_reminder' | translate }}</p>
        <div class="row">
            <div class="col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 info_box mx-auto">
                <div class="infos">
                    <h3>{{ 'post_payment.id_info' | translate }}</h3>
                    <div class="row">
                        <p class="col-sm-4 email">{{ 'post_payment.email' | translate }}</p>
                        <p class="col-sm-8 email_example">{{ account.getEmail() }}</p>
                    </div>
                    <div class="row">
                        <p class="col-sm-4 mdp">{{ 'post_payment.password' | translate }}</p>
                        <p class="col-sm-8 mdp_example">* * * * *</p>
                    </div>
                </div>
                <p class="last">{{ 'post_payment.connect' | translate }} <span>{{ 'post_payment.profile' | translate: {siteName: site.getName()} }},</span> {{ 'post_payment.click_here' | translate }}</p>
                <img src="/assets/img/common/tplXXX/shadow.png" alt="shadow"/>
                <div class="row">
                    <a class="col-xs-6 col-xs-offset-6 col-sm-6 col-sm-offset-3 button-green" [routerLink]="['/chat']">
                        <div style="margin-right: 70px; text-align: center;">{{ 'post_payment.button_connect' | translate }}</div>
                    </a>
                </div>
            </div>
        </div>
        <p class="text-center">
            {{ 'post_payment.faq' | translate }} <a target="_blank" [href]="site.getUrl() + 'faq_' + site.getLocale() + '.html'">FAQ</a>.
        </p>
        <h4 class="colored-text text-center">{{ 'post_payment.wish' | translate }}</h4>
    </div>
</div>
