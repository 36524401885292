<div *ngIf="canShowNotificationBox()" class="alert card new-message wow animated fadeInLeft bounce">
    <div class="card-header font-weight-bold p-0 mb-2">
        <span [innerHTML]="getTranslationText() | translate: getTranslationData()"></span>
        <button (click)="close(true)" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="card-body p-0 d-flex justify-content-start align-items-center">
        <div class="users-img mr-3">
            <app-user-photo [user]="getLastRoom().getFriendUser()"></app-user-photo>
            <span class="fa-stack bg-success"></span>
        </div>
        <div class="infos-new-message">
            <span [class.blurred]="isBlurred('nicknameBlur')">{{ getLastRoom().getFriendUser().getUsername() }}</span><br>
            <small [class.blurred]="isBlurred('detailBlur')" class="text-muted">
                {{ getLastRoom().getFriendUser().getAge() }} {{ 'years_old' | translate }}
                <i class="fas fa-map-marker-alt ml-2"></i>
                {{ getLastRoom().getFriendUser().getCity() | maxStringSize:15 }}
            </small>
        </div>
    </div>

    <div class="card-footer p-0 text-right">
        <a (click)="goTo()" class="btn btn-sm btn-primary wow animated infinite flash text-uppercase">{{ 'read_the_message' | translate }}</a>
    </div>
</div>
