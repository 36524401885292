import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteContentService} from '../../services/site/content/site-content.service';
import {FaqTheme} from '../../models/faq/faq-theme';
import {FaqQuestion} from '../../models/faq/faq-question';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    private static logs = {
        view: [],
        click_yes: [],
        click_no: []
    };

    loading = true;
    themes: FaqTheme[];
    currentTheme = null;
    currentQuestion = null;
    private themeId = null;

    constructor(
        private siteContentService: SiteContentService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((param) => {
            if (param.has('id')) {
                this.themeId = +param.get('id');
                this.setThemeIndex();
            } else {
                this.themeId = null;
                this.currentTheme = null;
            }
        });

        this.siteContentService.getFaq().then((themes: FaqTheme[]) => {
            this.themes = themes;
            this.setThemeIndex();
            this.loading = false;
        });
    }

    next() {
        if (this.currentTheme < (this.themes.length - 1)) {
            return ['/faq/' + this.themes[this.currentTheme + 1].getId()];
        }
    }

    usefulAnswer(action) {
        this.sendLog(this.currentQuestion, action);
        if (action === 'click_no') {
            this.router.navigate(['/customer-service']);
        } else {
            this.currentQuestion = null;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    getTheme(): FaqTheme {
        return this.currentTheme !== null ? this.themes[this.currentTheme] : null;
    }

    getAnswer(question: FaqQuestion): string {
        return this.siteContentService.replaceTag(question.getAnswer());
    }

    setCurrentQuestion(question: FaqQuestion) {
        if (this.currentQuestion !== question.getId()) {
            this.currentQuestion = question.getId();
            this.sendLog(question.getId(), 'view');
        } else {
            this.currentQuestion = null;
        }
    }

    private sendLog(questionId, action) {
        if (!FaqComponent.logs[action].includes(questionId)) {
            FaqComponent.logs[action].push(questionId);
            this.siteContentService.sendFaqLog(questionId, action);
        }
    }

    private setThemeIndex() {
        const themeId = this.themeId;
        if (themeId !== null && this.themes && this.themes.length > 0) {
            const index = this.themes.findIndex((theme: FaqTheme) => theme.id === themeId);

            if (index > -1) {
                this.currentTheme = index;
            }
        }
    }
}
