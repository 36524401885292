import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {TranslateService} from '@ngx-translate/core';
import {CountryService} from '../../../services/country/country.service';

@Component({
    selector: 'app-support-phone-component',
    templateUrl: './support-phone.component.html',
    styleUrls: ['./support-phone.component.scss']
})
export class SupportPhoneComponent extends BaseComponent {

    private openingHour = '';
    private openingMinutes = '00';
    private closingHour = '';
    private closingMinutes = '00';

    constructor(
        private translate: TranslateService,
        private injector: Injector,
        private countryService: CountryService
    ) {
        super(injector);
        this.getOpenedHourByCountry();
    }

    getOpenedHourByCountry() {
        for (const support of this.site.getPhoneSupport()) {
            if (support.default) {
                this.openingHour = this.getHourByCountry(support.openingHour);
                this.closingHour = this.getHourByCountry(support.closingHour);
            }
        }
    }

    getHourByCountry(hour: string): string {
        let hourByCountry = hour;

        if (this.site.getLocale() === 'en') {
            let hourNumber: number = parseInt(hour, 10);
            if (hourNumber > 12) {
                hourNumber -= 12;
            }
            hourByCountry = hourNumber.toString();
        }

        return hourByCountry;
    }

    getFlag(countryCode): string {
        return countryCode === 'other' ? 'eu' : countryCode.toLowerCase();
    }

    getSupportPhoneContact(): string {
        return this.translate.instant(
            'support_phone.contact_desc',
            {
                openingHour: this.openingHour,
                openingMinutes: this.openingMinutes,
                closingHour: this.closingHour,
                closingMinutes: this.closingMinutes
            });
    }

    getCountryTranslate(countryCode) {
        let countryName;

        switch (countryCode) {
            case 'other':
            case 'eu':
                // Particular case, we want EU logo but with International text
                countryName = this.translate.instant('international');
                break;
            default:
                const country = this.countryService.getCountryDialingByCode(countryCode.toUpperCase());

                if (country) {
                    countryName = country.getName();
                } else {
                    this.translate.instant('country_list.' + countryCode);
                }
                break;
        }

        return countryName;
    }
}
