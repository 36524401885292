import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class PasswordChangeConfirmedNotificationType extends AbstractNotificationType {

    static readonly TYPE = 19;

    protected buttons:NotificationButton[] = [
        new NotificationButton('menu.my_account', () => {
            this.goTo('account', null, 'account');
        })
    ];

    getIcon(): string {
        return 'fa-lock';
    }
}
