<div *ngIf="result" class="text-success text-center mt-5">
    <i class="fas fa-check-circle fa-4x"></i>
    <br><br>
    <span><strong>{{ 'notification_type.18' | translate }}</strong></span>
    <br><br>
    <button [routerLink]="['/home']" class="btn btn-primary mb-2">{{ 'menu.home' | translate }}</button>
</div>

<div *ngIf="!result && error" class="text-danger text-center">
    <i class="fas fa-exclamation-circle fa-4x"></i>
    <br><br>
    <span><strong>{{ error }}</strong></span>
</div>
