import {Component} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {SexCamsService} from '../../../services/sex-cams/sex-cams.service';
import {SexCamsFilters} from '../../../models/account/settings/sex-cams-filters';

@Component({
    selector: 'app-sex-cams-filter',
    templateUrl: './sex-cams-filter.component.html',
    styleUrls: ['./sex-cams-filter.component.scss']
})
export class SexCamsFilterComponent {

    constructor(
        private currentUser: CurrentUserService,
        private sexCamsService: SexCamsService
    ) {}

    changeFlag(lang) {
        const index = this.getFilters().languages.indexOf(lang);
        if (index > -1) {
            this.getFilters().languages.splice(index, 1);
        } else {
            this.getFilters().languages.push(lang);
        }
    }

    getFilterList(index: string): string[] {
        return this.sexCamsService.getConstantList(index);
    }

    getFilters(): SexCamsFilters {
        return this.currentUser.account.settings.sexCamFilters;
    }

    submit() {
        this.hide();

        this.currentUser.updateSettings();
        this.sexCamsService.onSubmitChange(true);
    }

    isVisible(): boolean {
        return this.sexCamsService.showFilters;
    }

    hide() {
        this.sexCamsService.showFilters = false;
    }
}
