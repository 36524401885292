import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {City} from '../../models/city/city';
import {CurrentUserService} from '../user/current-user.service';
import {SearchFilters} from '../../models/account/settings/search-filters';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    currentPage = 1;
    showFilters = false;
    search = false;
    hardSubmit = new BehaviorSubject(false);
    currentHardSubmit = this.hardSubmit.asObservable();

    private searchProfileSubscriber = new Subject<any>();

    constructor(private currentUserService: CurrentUserService)  {}

    getSearchFilters(): SearchFilters {
        return this.currentUserService.getAccount().getSettings().getSearchFilters();
    }

    initCity() {
        const profile = this.currentUserService.getAccount().getProfile();

        if (!this.getSearchFilters().city && profile.getCity() && profile.getLatitude() && profile.getLongitude()) {
            this.getSearchFilters().city = new City().deserialize({
                name: profile.getCity(),
                country: profile.getCountry(),
                postalCode: profile.getPostalCode(),
                latitude: profile.getLatitude(),
                longitude: profile.getLongitude()
            });
        }
    }

    changeHardSubmit(value) {
        this.hardSubmit.next(value);
    }

    searchProfiles() {
        this.searchProfileSubscriber.next();
    }

    getSearchProfilesSubscriber() {
        return this.searchProfileSubscriber;
    }

    setCurrentPage(currentPage: number) {
        this.currentPage = currentPage;
        return this;
    }

    cleanFilters() {
        this.getSearchFilters().city = null;
        this.getSearchFilters().distance = '';

        this.currentUserService.updateSettings();
    }

    toggle() {
        if (this.showFilters) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this.showFilters = true;
    }

    hide() {
        this.showFilters = false;
    }

    submit() {
        this.setCurrentPage(1);

        this.currentUserService.updateSettings();
    }
}
