import {Component, OnInit} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {Router} from '@angular/router';
import {ModalService} from '../../../../services/modal/modal.service';
import {LoaderService} from '../../../../services/loader/loader.service';
import {BillingService} from '../../../../services/billing/billing.service';

@Component({
    selector: 'app-virilblue-cb',
    templateUrl: './virilblue-cb.component.html',
    styleUrls: ['./virilblue-cb.component.scss']
})
export class VirilblueCbComponent implements OnInit {

    paymentUrl: string = null;

    constructor(
        private currentUserService: CurrentUserService,
        private router: Router,
        private billingService: BillingService
    ) {
    }

    ngOnInit(): void {
        if (!this.currentUserService.getAccount().hasUpsellVirilblue()) {
            this.router.navigate(['/home']).then();
        }

        this.billingService.getUpsellVirilbluePaymentPageUrl(
            location.origin + '/upsell/virilblue/payment-accepted',
            location.origin + '/upsell/virilblue/payment-refused'
        ).subscribe((url) => {
            this.paymentUrl = url;
        });
    }
}
