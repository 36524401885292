import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {ChatService} from '../../../services/chat/chat.service';
import {Room} from '../../../models/chat/room/room';
import {CurrentUserService} from '../../../services/user/current-user.service';

@Component({
    selector: 'app-message-notification',
    templateUrl: './message-notification.component.html',
    styleUrls: ['./message-notification.component.scss']
})
export class MessageNotificationComponent implements OnInit {

    lastTotalMessages = 0;
    closedMessages = 0;
    showNotification = false;
    lastRoom: Room;
    isAvailableRoute = false;
    isNotificationDisabled = false;
    lastPageVisited = '/chat';

    constructor(
        private router: Router,
        private chatService: ChatService,
        private currentUserService: CurrentUserService
    ) {
    }

    ngOnInit() {
        this.isNotificationDisabled = 'Notification' in window && Notification.permission !== 'granted';
        this.lastTotalMessages = this.chatService.getChatRoomService().countTotalMessages();
        this.closedMessages = this.lastTotalMessages;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Navigation started.
                this.isAvailableRoute = !event.url.startsWith('/payment') && !event.url.startsWith('/chat') && !event.url.startsWith('/upsell');

                if (!this.isAvailableRoute || this.lastPageVisited.startsWith('/chat')) {
                    this.showNotification = false;
                    this.lastTotalMessages = this.chatService.getChatRoomService().countTotalMessages();
                    this.closedMessages = this.lastTotalMessages;
                }

                this.lastPageVisited = event.url;
            }
        });
    }

    getUnreadCount(): number {
        const that = this;
        const lastUnreadCount = this.chatService.getChatRoomService().countTotalMessages();
        if (this.lastTotalMessages < lastUnreadCount) {
            this.close();
            setTimeout(() => that.open(), 1000);
        }

        return lastUnreadCount;
    }

    getUnreadNotification() {
        const total = this.getUnreadCount() - this.closedMessages;

        return total > 9 ? '+9' : total;
    }

    close(isUserAction = false) {
        this.showNotification = false;
        this.lastTotalMessages = this.chatService.getChatRoomService().countTotalMessages();
        if (isUserAction) {
            this.closedMessages = this.lastTotalMessages;
        }
    }

    open() {
        const rooms = this.chatService.getChatRoomService().getSortedRooms();

        this.lastRoom = null;

        if (rooms && rooms.length > 0) {
            this.lastRoom = rooms[0];
        }

        this.showNotification = true;
    }

    canShowNotificationBox(): boolean {
        return this.getUnreadCount() > 0 && this.showNotification && this.isAvailableRoute &&
            this.hasChatRoom() && this.isNotificationDisabled;
    }

    hasChatRoom(): boolean {
        return this.lastRoom !== null;
    }

    getLastRoom(): Room {
        return this.lastRoom;
    }

    isBlurred(rule: string): boolean {
        if (rule === 'nicknameBlur' && this.getLastRoom().getFriendUser().getUsername().includes('*')) {
            return true;
        }
        return this.currentUserService.hasRule(rule);
    }

    goTo() {
        this.close();
        if (this.currentUserService.getAccount().hasSubscription()) {
            return this.router.navigate(['/chat/' + this.getLastRoom().getFriendUser().getUid()]);
        } else {
            return this.router.navigate(['/payment'], {queryParams: {action: 'new_message_pop'}});
        }
    }

    getTranslationText(): string {
        return 'you_have_new_message' + (this.getUnreadNotification() > 1 ? 's' : '');
    }

    getTranslationData(): {} {
        return {
            totalMessage: this.getUnreadNotification()
        }
    }
}
