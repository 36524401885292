import {Component, Input, OnInit} from '@angular/core';
import {Album} from '../../../../models/profile/album';
import {Site} from '../../../../models/site/site';
import {AlbumService} from '../../../../services/profile/album/album.service';
import {Profile} from '../../../../models/profile/profile';
import {MatDialogRef} from '@angular/material/dialog';
import {AlbumModalComponent} from '../album.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-album-create-modal',
    templateUrl: './album-create.component.html',
    styleUrls: ['./album-create.component.scss']
})
export class AlbumCreateModalComponent implements OnInit {

    @Input() site: Site;
    @Input() profile: Profile;
    album = new Album();
    privacy;
    errorMessage: string;

    constructor(
        private albumService: AlbumService,
        public dialogRef: MatDialogRef<AlbumModalComponent>,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        // Radio don't support integer
        this.privacy = this.album.getPrivacy() + '';
    }

    createAlbum() {
        if (!this.checkAlbumName()) {
            return false;
        }

        this.album.setPrivacy(+this.privacy);

        this.albumService.create(this.album, this.profile).subscribe((response: any) => {
            if (response.success) {
                this.album.setId(response.data.albumId);
                this.profile.addAlbum(this.album);
                this.dialogRef.close();
            } else {
                this.errorMessage = this.translate.instant('error_list.technical_issue');
            }
        }, () => {
            this.errorMessage = this.translate.instant('error_list.technical_issue');
        });
    }

    checkAlbumName(): boolean {
        return this.album.getName() && !['DEFAULT_PROFILE_ALBUM', 'COVER_ALBUM'].includes(this.album.getName());
    }
}
