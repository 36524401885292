<div class="container text-center">
    <img alt="" class="img-fluid w-100 illu illu-boost" src="assets/img/modal/booster-visibilite.gif"><br>
    <h1 class="text-success">{{ 'booster.activated' | translate }}</h1>
    <p class="infos w-100 text-center mb-4">
        <span [innerHTML]="'booster.congrats_you_re_boosted' | translate"></span><br>
        <span [innerHTML]="'booster.be_available_next_hours' | translate: {duration: duration}"></span><br>
        <br>
        <span [innerHTML]="'booster.more_visible' | translate"></span><br>
        {{ 'booster.more_visits_more_contacts' | translate }}<br>
    </p>
    <a (click)="close()" class="btn btn-primary btn-sm">{{ 'booster.close_window' | translate }}</a>
</div>
