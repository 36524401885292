import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class AcceptedPresentationNotificationType extends AbstractNotificationType {

    static readonly TYPE = 27;

    protected buttons:NotificationButton[] = [
        new NotificationButton('menu.my_profile', () => {
            this.goTo('profile');
        })
    ];

    getIcon(): string {
        return 'fa-file-alt';
    }
}
