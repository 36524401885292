import {Component, Input} from '@angular/core';
import {ActionComponent} from '../../profile/action/action.component';

@Component({
    selector: 'app-contact-thumb-profile',
    templateUrl: './contact-thumb-profile.component.html',
    styleUrls: ['./contact-thumb-profile.component.scss']
})
export class ContactThumbProfileComponent extends ActionComponent {

    @Input() status: string;

    friendStatus(status: number) {
        this.profile.relationship.friend = status;
        this.actionService.friendStatus(this.profile.getId(), status).subscribe(() => {
            this.profileChange.emit();
        });
    }

    getRule(rule: string, data: string): boolean {
        if (data && data.includes('***')) {
            return true;
        }

        return this.currentUserService.getAccount().getRuleSet() && this.currentUserService.getAccount().getRuleSet()[rule];
    }
}
