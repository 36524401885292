import {Injectable} from '@angular/core';
import {Deserializable} from '../deserializable/deserializable';
import {Album} from './album';
import {Relationship} from './relationship';
import * as clone from 'clone';
import {AbstractUser} from '../user/abstract-user';
import {Activity} from '../user/activity';
import {Gender} from '../user/gender';

@Injectable({
    providedIn: 'root'
})
export class Profile extends AbstractUser implements Deserializable {

    static readonly PRIVACY_EVERYONE = 1;
    static readonly PRIVACY_FRIENDS_ONLY = 2;
    static readonly PRIVACY_DO_NOT_SHARE = 3;

    privacy: {
        physique: number,
        temper: number,
        situation: number,
        personal_life: number
    };

    id: number = null;
    status = 1;
    webcamId: string = null;
    nickname: string = null;
    searchedGender: number = null;
    city: string = null;
    country: string;
    postalCode: string;
    photoIsModerated: boolean = null;
    isFirstLogin: boolean = null;
    boosted: boolean = null;
    endBooster: Date = null;
    photoCount = 0;
    coverPhoto: string = null;
    language = 'fr';
    latitude: number;
    longitude: number;
    birthDate: Date = null;

    presentation: string;

    answers = {};
    relationship: Relationship;
    albums = {};

    // Date for search appearance
    visitedAt: Date = null;

    fieldsStatus: {
        city: string,
        country: string,
        gender: string,
        password: string,
        postalCode: string,
        nickname: string,
        searchedGender: string,
        birthDate: string
    };

    completionPercentage = 0;
    certifiedAt: Date = null;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.birthDate && input.birthDate.full) {
            this.birthDate = new Date(input.birthDate.full);
        }

        this.setActivity(input.activity || Activity.OFFLINE);

        if (input.relationship) {
            this.setRelationship(input.relationship);
        }
        if (input.albums) {
            this.setAlbums(input.albums);
        }

        if (input.visitedAt) {
            this.visitedAt = new Date(input.visitedAt);
        }

        if (!this.answers) {
            this.answers = {};
        }

        if (input.endBooster) {
            this.endBooster = new Date(input.endBooster);
        }

        if (input.certifiedAt) {
            this.certifiedAt = new Date(input.certifiedAt);
        }

        return this;
    }

    clone(): Profile {
        return clone(this);
    }

    copy(profileToCopy: Profile) {
        Object.assign(this, profileToCopy.clone());
    }

    getId(): number {
        return this.id;
    }

    getWebcamId(): string {
        return this.webcamId;
    }

    getNickname(): string {
        return this.nickname;
    }

    getSearchedGender(): number {
        return this.searchedGender;
    }

    getCity(): string {
        return this.city;
    }

    setCity(city: string) {
        this.city = city;
    }

    getCoverPhoto(): string {
        return this.coverPhoto;
    }

    setCoverPhoto(coverPhoto: string) {
        this.coverPhoto = coverPhoto;
    }

    getIsFirstLogin() {
        return this.isFirstLogin;
    }

    getBoosted() {
        return this.boosted;
    }

    getEndBooster() {
        return this.endBooster;
    }

    getPhotoIsModerated() {
        return this.photoIsModerated;
    }

    setPhotoIsModerated(photoIsModerated: boolean) {
        this.photoIsModerated = photoIsModerated;
    }

    getLanguage(): string {
        return this.language;
    }

    getBirthDate(): Date {
        return this.birthDate;
    }

    setBirthDate(birthDate: Date) {
        this.birthDate = birthDate;
    }

    getRelationship(): Relationship {
        return this.relationship;
    }

    getAlbums(): object {
        return this.albums;
    }

    getDefaultAlbumByType(albumType: number): Album {
        for (const id in this.albums) {
            if (this.albums[id]) {
                const album: Album = this.albums[id];
                if (album.getType() === albumType) {
                    return album;
                }
            }
        }
        return new Album();
    }

    addAlbum(album: Album) {
        this.albums[album.getId()] = album;
    }

    removeAlbum(album: Album) {
        delete this.albums[album.getId()];
    }

    setRelationship(relationship) {
        this.relationship = new Relationship();
        this.relationship.deserialize(relationship);
    }

    setAlbums(albumsData) {
        const albums = {};

        for (const albumData of albumsData) {
            let album = new Album();
            album = album.deserialize(albumData);
            albums[album.getId()] = album;
        }
        this.albums = albums;
    }

    getPhotoCount(): number {
        return this.photoCount;
    }

    setNickname(nickname: string) {
        this.nickname = nickname;
    }

    resolvePrivacy(category: string): boolean {
        if (category === 'description') {
            return true;
        }
        if (this.isPrivacyPublic(category)) {
            return true;
        }

        return this.isPrivacyFriends(category) && this.getRelationship().isFriend();
    }

    isSuspended(): boolean {
        return this.status === 6;
    }

    setFieldsStatus(fieldsStatus) {
        this.fieldsStatus = fieldsStatus;
    }

    getFieldsStatus() {
        return this.fieldsStatus;
    }

    setSearchedGender(searchedGender: number) {
        this.searchedGender = searchedGender;
    }

    getAge(): number {
        if (this.birthDate) {
            const today = new Date();
            let age = today.getFullYear() - this.birthDate.getFullYear();
            const month = today.getMonth() - this.birthDate.getMonth();

            if (month < 0 || (month === 0 && today.getDate() < this.birthDate.getDate())) {
                age--;
            }

            return age;
        }

        return null;
    }

    isSearchedGenderMale(): boolean {
        return this.getSearchedGender() === Gender.MALE;
    }

    isSearchedGenderFemale(): boolean {
        return this.getSearchedGender() === Gender.FEMALE;
    }

    getAnswers(): {} {
        return this.answers;
    }

    setAnswer(questionId, answerId) {
        this.answers[questionId] = answerId;
    }

    getPresentation(): string {
        return this.presentation;
    }

    setPresentation(presentation: string) {
        this.presentation = presentation;
    }

    getCountry(): string {
        return this.country;
    }

    getPostalCode(): string {
        return this.postalCode;
    }

    getPrivacy() {
        return this.privacy;
    }

    setPostalCode(postalCode: string) {
        this.postalCode = postalCode;
    }

    setCountry(country: string) {
        this.country = country;
    }

    isPrivacyPublic(section: string): boolean {
        if (typeof this.privacy[section] === 'undefined') {
            return true;
        }

        return this.privacy[section] === Profile.PRIVACY_EVERYONE;
    }

    isPrivacyFriends(section: string): boolean {
        if (typeof this.privacy[section] === 'undefined') {
            return false;
        }

        return this.privacy[section] === Profile.PRIVACY_FRIENDS_ONLY;
    }

    isPrivacyNotShare(section: string): boolean {
        if (typeof this.privacy[section] === 'undefined') {
            return false;
        }

        return this.privacy[section] === Profile.PRIVACY_DO_NOT_SHARE;
    }

    getCompletionPercentage(): number {
        return this.completionPercentage;
    }

    getSearchedGenderLabel(): string {
        switch (this.searchedGender) {
            case Gender.MALE:
                return Gender.MALE_LABEL;
            case Gender.FEMALE:
                return Gender.FEMALE_LABEL;
            case Gender.COUPLE:
                return Gender.COUPLE_LABEL;
        }
    }

    setLatitude(latitude: number) {
        this.latitude = latitude;
    }

    getLatitude(): number {
        return this.latitude;
    }

    setLongitude(longitude: number) {
        this.longitude = longitude;
    }

    getLongitude(): number {
        return this.longitude;
    }

    isCertified(): boolean {
        return !!this.certifiedAt;
    }
}
