import {Component} from '@angular/core';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {Router} from '@angular/router';
import {formatDate, getTomorrowFrom} from '../../../services/helper/date.service';
import {ModalService} from '../../../services/modal/modal.service';

@Component({
    selector: 'app-suspension',
    templateUrl: './suspension.component.html',
    styleUrls: ['./suspension.component.scss']
})
export class SuspensionComponent {

    minSuspendedFrom = new Date();
    minSuspendedTo = new Date();

    form = {
        suspendedFrom: null,
        suspendedTo: null,
        reason: 'choose',
        autoEnabling: true
    };

    reasonList = [
        'choose',
        'meet_someone',
        'holidays',
        'moving_around',
        'other_reason',
        'dont_want_to_answer'
    ];

    constructor(private currentUserService: CurrentUserService, private router: Router, private modal: ModalService) {
        this.minSuspendedTo.setDate(this.minSuspendedTo.getDate() + 1);
    }

    showSuspension(): boolean {
        return this.currentUserService.getAccount().isSuspended() ||
            this.currentUserService.getAccount().willBeSuspended();
    }

    changeSuspendedFrom() {
        this.minSuspendedTo = getTomorrowFrom(this.form.suspendedFrom);
        if (this.minSuspendedTo > this.form.suspendedTo) {
            this.form.suspendedTo = null;
        }
    }

    checkForm(): boolean {
        return this.form.reason !== 'choose' && this.form.suspendedTo && this.form.suspendedFrom;
    }

    abortSuspension() {
        this.currentUserService.abortSuspension().subscribe();
        this.currentUserService.account.suspension = null;
    }

    getSuspension() {
        const suspension = this.currentUserService.getAccount().suspension;
        return {
            confirmedAt: formatDate(new Date(suspension.dateConfirmation), 'd/m/Y'),
            from: formatDate(new Date(suspension.from), 'd/m/Y'),
            to: formatDate(new Date(suspension.to), 'd/m/Y')
        };
    }

    submit() {
        if (!this.checkForm()) {
            return;
        }

        return this.modal.openFullScreenConfirmation({
            title: 'account.my_account.suspend_account.modal.title',
            strongQuestion: true,
            faClass: 'fas fa-hand-paper',
            faClassStack: false,
            sampleText: 'account.my_account.suspend_account.modal.description',
            question: 'account.my_account.suspend_account.modal.question',
            responses: [
                {
                    text: 'account.my_account.suspend_account.modal.answer',
                    className: 'btn-primary',
                    result: true
                },
                {
                    text: 'cancel',
                    className: 'btn-light',
                    result: false
                }
            ],
            asyncValidationCallback: async (result) => {
                if (result) {
                    const date = new Date();
                    this.form.suspendedFrom.setHours(date.getHours());
                    this.form.suspendedFrom.setMinutes(date.getMinutes());
                    this.form.suspendedTo.setHours(date.getHours());
                    this.form.suspendedTo.setMinutes(date.getMinutes());

                    this.currentUserService.requestSuspension({
                        suspendedFrom: formatDate(this.form.suspendedFrom, 'Y-m-d', true) + ' 00:00:00',
                        suspendedTo: formatDate(this.form.suspendedTo, 'Y-m-d', true) + ' 00:00:00',
                        reason: this.form.reason,
                        autoEnabling: this.form.autoEnabling,
                    }).subscribe();

                    this.router.navigate(['/pending-confirmation/suspension']);
                }
                return true;
            }
        });
    }
}
