<div class="row">
    <ng-container *ngFor="let photo of photos">
        <div *ngIf="!photo.isModerationRejected() && !photo.isMain()" class="choix-photo profil col-4 col-sm-3 col-md-6 mb-2">
            <a (click)="selectPhoto(photo)">
                <div *ngIf="photo.isModerationPending()" class="badge badge-warning badge-status ml-3">{{ 'albums.waiting_moderation' | translate }}</div>
                <img class="img-fluid w-100 card-img-profile" [src]="photo.getUrl()">
                <span class="btn btn-light btn-sm btn-choisir-photo">{{ 'profile_picture.choose_picture' | translate }}</span>
            </a>
        </div>
    </ng-container>

    <p *ngIf="photos.length === 0" class="infos w-100 text-center">
        <span>{{ 'profile_picture.no_available_photo' | translate }}</span>
    </p>
</div>
