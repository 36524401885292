import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html'
})
export class InfiniteScrollComponent {

    @Input('maxPage') maxPageInput = 1;
    @Input('currentPage') currentPageInput = 1;
    @Input() loading = false;
    @Output() pageChange = new EventEmitter();

    // Method checking if the element is in the visibility threshold.
    private static elementInViewport(el): boolean {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }

    @HostListener('window:scroll', ['$event'])
    scrollHandler() {
        const nextPage = this.currentPageInput + 1;
        const canScrollMore = nextPage <= this.maxPageInput && !this.loading;
        const lastProfileElement = $('app-thumb-profile:last .infinite-trigger, app-thumb-cam-model:last .infinite-trigger').get(0);

        if (lastProfileElement) {
            if (canScrollMore && InfiniteScrollComponent.elementInViewport(lastProfileElement)) {
                this.pageChange.emit(nextPage);
            }
        }
    }

    showLoader(): boolean {
        return this.currentPageInput <= this.maxPageInput && this.loading;
    }
}
