import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Profile} from '../../models/profile/profile';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CurrentUserService} from '../user/current-user.service';
import {HelperService} from '../helper/helper.service';
import {Offer} from '../../models/offer/offer';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    readonly liveParadiseCount = Math.floor(Math.random() * 4 + 4);
    private collapseAccordionsSource = new Subject<boolean>();

    collapseAccordions$ = this.collapseAccordionsSource.asObservable();

    constructor(
        private http: HttpClient,
        private user: CurrentUserService,
        private helperService: HelperService
    ) {
    }

    collapseAccordions() {
        this.collapseAccordionsSource.next();
    }

    isValidNickname(nickname) {
        return nickname && nickname.match(/^[A-Za-z0-9_\-.]{6,25}$/) !== null;
    }

    /**
     * Get id collection for /profile/:id/:endpoint
     * @param endpoint string e.g. friend, blocked
     * @param params object e.g. profileIds
     */
    getRelationIds(endpoint: string, params = null) {
        let searchParams = '';
        if (params !== null) {
            searchParams = '?' + this.helperService.httpBuildQuery(params);
        }
        return this.http.get(
            environment.backendUrl + '/profile/' +
            this.user.getAccount().getProfile().getId() +
            '/' + endpoint + searchParams
        );
    }

    /**
     * Retrieve profile by id from backend
     * @param id
     * @return Observable<Profile>
     */
    findById(id: number): Observable<Profile> {
        return new Observable<Profile>(observer => {
            this.http.get(environment.backendUrl + '/profile/' + id).subscribe((response: any) => {
                if (response.success) {
                    observer.next(new Profile().deserialize(response.data.profile));
                } else {
                    observer.error('404 Profile not found');
                }
            }, (error: any) => {
                observer.error(error);
            });
        });
    }

    /**
     * Get all Profile for the current subsite
     * @param page number
     * @param count number
     * @param params
     */
    findAll(page: number, count: number, params = null) {
        let searchParams = `?page=${page}&count=${count}`;
        if (params !== null) {
            searchParams += '&' + this.helperService.httpBuildQuery(params);
        }
        return this.http.get(environment.backendUrl + '/profile' + searchParams);
    }

    /**
     * Get all others Profile for the current subsite
     * @param count number
     */
    findOthers(count: number) {
        const searchParams = `?count=${count}`;
        return this.http.get(environment.backendUrl + '/profile/' +
            this.user.getAccount().getProfile().getId() +
            '/others' + searchParams);
    }

    /**
     * Get boosted Profile for the current subsite
     */
    findBoosted(params = {}) {
        return this.http.get(environment.backendUrl + '/profile/' + this.user.getAccount().getProfile().getId() +
            '/boosted?' + this.helperService.httpBuildQuery(params));
    }

    /**
     * Get all profiles who has visited the current profile
     * @param page number
     * @param count number
     * @param params
     */
    findAppearanceAll(page: number, count: number, params = null) {
        let searchParams = `?page=${page}&count=${count}`;
        if (params !== null) {
            searchParams += '&' + this.helperService.httpBuildQuery(params);
        }
        return this.http.get(environment.backendUrl + '/profile/visited' + searchParams);
    }

    update() {
        const profile = this.user.getAccount().getProfile();
        const profileData: any = {
            gender: profile.getGender(),
            searchedGender: profile.getSearchedGender(),
            city: profile.getCity(),
            country: profile.getCountry(),
            postalCode: profile.getPostalCode(),
            presentation: profile.getPresentation(),
            answers: profile.getAnswers(),
        };

        const birthDate: any = profile.getBirthDate();
        if (birthDate) {
            profileData.birthDate = {
                year: birthDate.getFullYear(),
                month: birthDate.getMonth() + 1,
                day: birthDate.getDate()
            };
        }

        return this.putProfile(profileData);
    }

    checkNickname(nickname) {
        const nicknameParameter = this.helperService.httpBuildQuery({
            nickname
        });

        return this.http.get(environment.backendUrl + '/profile/check-nickname?' + nicknameParameter);
    }

    cityCompletion(city, useCountry) {
        const cityParameters: any = {
            city
        };

        if (useCountry) {
            cityParameters.countries = this.user.getAccount().getProfile().getCountry();
        }

        const parameters = this.helperService.httpBuildQuery(cityParameters);

        return this.http.get(environment.backendUrl + '/completion/city?' + parameters);
    }

    sendMessageToSupport(profile: Profile, subject, message) {
        return this.http.post(environment.backendUrl + '/support/message', {
            subject,
            message
        });
    }

    putProfile(data) {
        return this.http.put(
            environment.backendUrl + '/profile/' + this.user.getAccount().getProfile().getId(),
            data
        );
    }

    putProfileSession(sessionData) {
        return this.http.put(
            environment.backendUrl + '/profile/' + this.user.getAccount().getProfile().getId() + '/session',
            sessionData
        );
    }

    sendCoinAlternativePaymentEmail(method: string, offer: Offer) {
        this.http.post(
            environment.backendUrl + '/profile/' + this.user.getAccount().getProfile().getId() + '/send-email',
            {
                template: 'coin_alternative_payment',
                price: offer.getPrice(),
                lovecoin_amount: offer.getCoinAmount(),
                lovecoin_free_amount: offer.getCoinFreeAmount(),
                method
            }
        ).subscribe();
    }
}
