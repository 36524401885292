const domain = window.location.hostname.replace(/members./, '');

export const environment = {
    production: true,
    backendUrl: `https://bb.${domain}`,
    domain,
    imageTools: 'https://medias.meetaff.com/resize/',
    socket: {
        host: 'https://chatserver.meetaff.com',
        port: 3000,
        secure: true
    },
    chatVersion: {
        version: '1.0.5',
        type: 'zm'
    },
    hasLoginPage: false,
    sentryDsn: 'https://5277367955da495d884d6574c2646da4@sentry.sopeople.net/42',
    browser: {
        supportedVersion: {
            chrome: 57,
            opera: 44,
            firefox: 67,
            ie: 11,
            edge: 87,
            safari: 12,
            firefoxMobile: null,
            safariMobile: null,
            ieMobile: null
        }
    },
    trackerUrl: 'https://supernotif.com',
    dnx: {
        clientUrl: 'https://svcrpclient.dnx.lu',
        janusUrl: 'https://rtc.dnx.lu',
        pingInterval: 4000,
        comFrom: 1016732,
        testAccount: ''
    }
};
