import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
    selector: 'app-modification-settings',
    templateUrl: './modification-settings.component.html',
    styleUrls: ['./modification-settings.component.scss']
})
export class ModificationSettingsComponent {

    icon: string;
    field: string;

    messageError: string;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModificationSettingsComponent>,
        private currentUser: CurrentUserService,
        private translate: TranslateService,
        private router: Router,
        private profileService: ProfileService
    ) {
        this.icon = data.icon;
        this.field = data.field;
        this.clearError();
    }

    onSubmit(f: NgForm) {
        this.clearError();

        if (!f.valid) {
            this.showErrorInvalidField();
        } else if (f.value.newField.toString().trim() === '' && f.value.newFieldConfirm.toString().trim() === '') {
            this.showErrorEmptyField();
        } else if (f.value.newField !== f.value.newFieldConfirm) {
            this.showErrorNotSameField();
        } else if (f.value.newField === f.value.newFieldConfirm) {

            if (this.field === 'password' && !this.isValidPassword(f.value.newField)) {
                this.messageError = this.translate.instant('error_list.password_restriction_invalid', {
                    minChar: 6
                });
            } else if (this.field === 'nickname' && !this.isValidNickname(f.value.newField)) {
                this.messageError = this.translate.instant('on_boarding.invalid_nickname');
            } else {
                this.updateField(this.field, f.value.newField);
            }
        }
    }

    isValidPassword(password): boolean {
        return password && password.toString().trim().length >= 6;
    }

    isValidNickname(nickname): boolean {
        return this.profileService.isValidNickname(nickname);
    }

    updateField(field, value) {
        const method = 'update' + field.charAt(0).toUpperCase() + field.slice(1);

        if (typeof this.currentUser[method] === 'function') {
            this.currentUser[method](value, () => {
                this.dialogRef.close();
                this.router.navigate([`/pending-confirmation/${field}/${value}`]);
            });
        } else {
            throw Error('CurrentUserService does not implement the following method: ' + method);
        }
    }

    showErrorInvalidField() {
        this.messageError = this.translate.instant('error_list.invalid_' + this.field);
    }

    showErrorEmptyField() {
        this.messageError = this.translate.instant(`error_list.${this.field}_cannot_be_empty`);
    }

    showErrorNotSameField() {
        this.messageError = this.translate.instant('error_list.not_same_' + this.field);
    }

    clearError() {
        this.messageError = null;
    }

    getInputType(field: string): string {
        return field === 'nickname' ? 'text' : field;
    }
}
