import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../services/loader/loader.service';
import {BillingService} from '../../../../services/billing/billing.service';

@Component({
    selector: 'app-live-one-click',
    templateUrl: './live-one-click.component.html',
    styleUrls: ['./live-one-click.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LiveOneClickComponent implements OnInit {

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private billingService: BillingService
    ) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(true);

        this.billingService.canUpsellLive().subscribe((available) => {
            this.loaderService.setLoading(false);
            if (!available) {
                this.router.navigate(['/home']).then();
            }
        }, () => {
            this.router.navigate(['/home']).then();
        });
    }

    accept() {
        this.loaderService.setLoading(true);
        this.billingService.upsellLive(
            location.origin + '/upsell/live/payment-accepted',
            location.origin + '/upsell/live/payment-refused'
        ).subscribe(response => {
            switch (response.transactionStatus) {
                /**
                 * 3DS Redirection
                 */
                case 101:
                    window.location.href = response.redirectUrl;
                    break;
                /**
                 * Paiement accepted
                 */
                case 1:
                    this.router.navigate(['/upsell/live/payment-accepted']).then(() => {
                        this.loaderService.setLoading(false);
                    });
                    break;
                /**
                 * Paiement refused
                 */
                default:
                    this.router.navigate(['/upsell/live/payment-refused']).then(() => {
                        this.loaderService.setLoading(false);
                    });
                    break;
            }
        }, (error) => {
            this.router.navigate(['/upsell/live/payment-refused']).then(() => {
                this.loaderService.setLoading(false);
            });
        });
    }

    refuse() {
        this.router.navigate(['/home']).then();
    }
}
