<div class="container text-center">
    <img alt="" class="img-fluid w-100 illu illu-boost" src="assets/img/modal/photo-ko.png"><br>
    <h1 class="text-danger">{{ 'beware' | translate }}</h1>
    <p class="infos w-100 text-center mb-4">
        <span [innerHTML]="'booster.photo_before' | translate"></span><br>
        <br>
        <span [innerHTML]="'booster.access_with_photo' | translate"></span>
    </p>
    <a (click)="linkProfile()" class="btn btn-primary btn-sm">{{ 'booster.add_photo' | translate }}</a>
</div>
