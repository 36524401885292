import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, throwError} from 'rxjs';
import {CamMessage} from '../../models/sex-cams/cam-message/cam-message';
import {CurrentUserService} from '../user/current-user.service';
import {CamModel} from '../../models/cam-model/cam-model';
declare const chatOptions: Map<string, any>;
declare function Connector(): void;

@Injectable({
    providedIn: 'root'
})
export class DnxService {

    onErrorSubject = new BehaviorSubject('ok');
    onErrorObservable = this.onErrorSubject.asObservable();
    error = null;
    privateChatRoom = false;
    messages: CamMessage[] = [];
    unreadMessage = 0;
    visitorCount = 1;
    nextEvent = true;
    initRetryCount = 0;

    constructor(private currentUser: CurrentUserService) {
        this.init();
    }

    init() {
        if (chatOptions) {
            chatOptions.set('socket_url', environment.dnx.clientUrl);
            chatOptions.set('comfrom', environment.dnx.comFrom);
            chatOptions.set('ping_interval', environment.dnx.pingInterval);
            chatOptions.set('janus_url', environment.dnx.janusUrl);
            chatOptions.set('use_rtc', true);
            chatOptions.set('startVideo', true);

            chatOptions.set('nickname', this.currentUser.getAccount().getProfile().getNickname());

            chatOptions.set('updateHandler', (message, data) => {

                let event: any;
                switch (message) {
                    case 'visitor_count':
                        this.visitorCount = data;
                        return;
                    case 'force_next_event':
                        this.nextEvent = true;
                        break;
                    default:
                        event = message;
                }

                if (event) {
                    this.nextEvent = true;
                    this.onErrorChange(event);
                }
            });

            chatOptions.set('textMessageReceivedFunction', (message: Map<string, string>) => {
                const camMessage = new CamMessage().deserialize(message);

                this.messages.push(camMessage);

                if ((this.privateChatRoom && camMessage.isPublic()) || (!this.privateChatRoom && camMessage.isPrivate())) {
                    ++this.unreadMessage;
                }
            });

            chatOptions.set('errorHandler', (errorMethod, errorCode) => {
                let event: any;

                if (errorCode === 'NO_CLIENT_FOUND' || errorCode === 'STOP_CLIENT') {
                    event = 'refresh_hash';
                } else if (['CAMMER_NOT_ONLINE', 'CAMMER_OFFLINE', 'VISITOR_BANNED'].includes(errorCode)) {
                    event = 'offline';
                } else if (errorCode === 'CAMMER_TO_GROUP' || errorCode === 'CAMMER_NO_FREE_ALLOWED') {
                    event = 'going_vip';
                } else if (errorMethod === 'pingFailed') {
                    event = 'retry';
                }

                if (event) {
                    this.nextEvent = false;
                    setTimeout(() => {
                        if (!this.nextEvent) {
                            this.onErrorChange(event);
                        }
                    }, 1000);
                }
            });

            const connector = new Connector();
        } else {
            setTimeout(() => {
                if (this.initRetryCount > 3) {
                    throwError('Max retry for DnxService init method reached, chatOptions is still undefined.');
                } else {
                    ++this.initRetryCount;
                    this.init();
                }
            }, 1000);
        }
    }

    connectModel(camModel: CamModel, visitorHash: string): boolean {
        chatOptions.set('privateStatus', false);
        chatOptions.set('visitor_hash', visitorHash);
        chatOptions.set('service', camModel.getId());

        if (camModel.isPrivateShow() && !camModel.allowsPeeping()) {
            return false;
        }

        chatOptions.set('chatType', camModel.isFreeShow() ? 'FREE' : 'ONE2MANY');

        return true;
    }

    onErrorChange(value) {
        this.onErrorSubject.next(value);
    }
}
