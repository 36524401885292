import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FinishSignupComponent} from '../finish-signup.component';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-finish-signup-confirmation',
    templateUrl: './finish-signup-confirmation.component.html',
    styleUrls: ['./finish-signup-confirmation.component.scss']
})
export class FinishSignupConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<FinishSignupComponent>,
        private currentUser: CurrentUserService
    ) {
    }

    close() {
        this.currentUser.finishSignupNotification();
        this.dialogRef.close();
    }
}
