import {Injectable} from '@angular/core';
import {Deserializable} from '../../deserializable/deserializable';

@Injectable({
    providedIn: 'root'
})
export class UserMessage implements Deserializable {

    static readonly TYPE_USER_BANNED = 'USER_BANNED';
    static readonly TYPE_USER_SUSPENDED = 'USER_SUSPENDED';
    static readonly TYPE_SESSION_KILLED = 'SESSION_KILLED';
    static readonly TYPE_USER_IS_CONNECTED = 'USER_IS_CONNECTED';
    static readonly TYPE_BAD_CHAT_VERSION = 'BAD_CHAT_VERSION';
    static readonly TYPE_WARNING = 'warning';

    static readonly CONTEXT_ALL = 'CONTEXT_ALL';
    static readonly CONTEXT_CHAT = 'CONTEXT_CHAT';

    id: number;
    type: string;
    message: string;
    context: string;
    dateEnd: Date;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

    getId(): number {
        return this.id;
    }

    getType(): string {
        return this.type;
    }

    getMessage(): string {
        return this.message;
    }

    getContext(): string {
        return this.context;
    }

    setContext(context: string) {
        this.context = context;
    }

    getDateEnd(): Date {
        return this.dateEnd;
    }
}
