<app-modal>
    <div *ngIf="step == 1">
        <div class="modal-body">
            <div class="container">
                <div class="form-group">
                    <label>{{ 'report_profile.please_report' | translate }}</label>
                </div>

                <div class="form-group" *ngFor="let reason of reasons">
                    <div class="custom-control custom-checkbox choix-prefs">
                        <input type="radio" class="custom-control-input" name="reason_{{reason}}" [value]="reason" id="reason_{{reason}}" [(ngModel)]="reasonId">
                        <label class="custom-control-label font-weight-normal prefs" for="reason_{{reason}}">
                            <span [class.blurred]="hasRule('nicknameBlur')">{{ profile.getNickname() }}</span> {{ ('reason_list.' + reason) | translate}}
                        </label>
                    </div>
                </div>

                <div class="form-group mt-4">
                    <label>{{ 'report_profile.report_to_moderation' | translate }}</label>
                    <textarea class="form-control"  rows="4" [(ngModel)]="description" style="height:90px; resize: none;"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <span [mat-dialog-close]="true" data-dismiss="modal" class="btn btn-dark btn-sm mr-2 text-uppercase">{{ 'cancel' | translate }}</span>
            <a (click)="addReport()" class="btn btn-primary btn-sm text-uppercase">
                <i class="fas fa-check"></i>
                <span class="ml-1">{{ 'send' | translate }}</span>
            </a>
        </div>
    </div>
    <div *ngIf="step == 2">
        <div class="modal-body">
            <div class="container">
                <p class="mb-3">{{ 'report_profile.was_saved' | translate }}</p>
            </div>
        </div>
        <div class="modal-footer">
            <a [mat-dialog-close]="true" class="btn btn-primary btn-lg btn-block font-weight-bold text-uppercase">
                <i class="fas fa-check"></i>
                <span class="ml-1">{{ 'ok' | translate }}</span>
            </a>
        </div>
    </div>
    <div *ngIf="step == 3">
        <div class="modal-body">
            <div class="container">
                <p class="mb-3">{{ 'error_list.' + error | translate }}</p>
            </div>
        </div>
        <div class="modal-footer">
            <a [mat-dialog-close]="true" class="btn btn-primary btn-lg btn-block font-weight-bold text-uppercase">
                <i class="fas fa-check"></i>
                <span class="ml-1">{{ 'ok' | translate }}</span>
            </a>
        </div>
    </div>
</app-modal>
