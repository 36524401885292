import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SiteService} from '../../../../services/site/site.service';

@Component({
    selector: 'app-unlover-layout',
    templateUrl: './unlover-layout.component.html',
    styleUrls: ['./unlover-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnloverLayoutComponent implements OnInit {

    @Input()
    showTerms = false;

    constructor(
        private siteService: SiteService
    ) {
    }

    ngOnInit(): void {
    }

    getSiteName(): string {
        return this.siteService.getSite().getName();
    }
}
