import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {City} from '../../models/city/city';
import {ProfileService} from '../../services/profile/profile.service';
import {Profile} from '../../models/profile/profile';
import {GeolocationService} from '../../services/geolocation/geolocation.service';

@Component({
    selector: 'app-city-typeahead',
    templateUrl: './city-typeahead.component.html',
    styleUrls: ['./city-typeahead.component.scss']
})
export class CityTypeaheadComponent implements OnInit, OnChanges {

    @Input() marker = false;
    @Input() useLoader = false;
    @Input() useGeolocation = false;
    @Input() profile: Profile;
    @Input() currentCityString = '';
    @Input() selectedCityObject: City;
    @Input() geolocIcon = 'bullseye';
    @Input() geolocTooltip = false;
    @Input() displayFromWall = false;
    @Output() onUnselectCity = new EventEmitter();
    @Output() onSelectCity = new EventEmitter();
    @Output() onErrorUnpicked = new EventEmitter();

    currentCity = '';
    cities: City[] = [];
    geolocatedCity: City = null;
    position;
    canLoadMoreCities = true;
    loadingCity = false;
    waitingGeoloc = false;
    isChecked = false;
    errorUnpicked = false;

    constructor(private profileService: ProfileService, private geolocationService: GeolocationService) {
    }

    ngOnInit() {
        if (this.selectedCityObject && typeof this.selectedCityObject !== 'undefined') {
            this.setCity(this.selectedCityObject);
        } else if (this.currentCityString !== '') {
            this.currentCity = this.currentCityString;
        }
    }

    cityAutoCompletion(moreCities = false) {
        this.onUnselectCity.emit();
        this.loadingCity = true;
        this.cities = [];
        this.isChecked = true;

        const currentCity = this.currentCity;
        if (!currentCity || currentCity.trim().length === 0) {
            this.checkCity();
            this.onUnselectCity.emit();
        }
        if (!currentCity || currentCity.trim().length < 2) {
            this.loadingCity = false;
            return false;
        }

        this.canLoadMoreCities = !moreCities;

        this.profileService.cityCompletion(currentCity, this.canLoadMoreCities).subscribe((response: any) => {
            this.loadingCity = false;
            if (!response.success || this.currentCity !== currentCity) {
                return false;
            }

            if (response.data.length === 0 && !moreCities) {
                this.cityAutoCompletion(true);
            } else {
                for (const city of response.data) {
                    this.cities.push((new City()).deserialize(city));
                }
            }
        });
    }

    setCity(city: City) {
        if (this.profile) {
            this.profile.setCountry(city.getCountry());
            this.profile.setPostalCode(city.getPostalCode());
            this.profile.setCity(city.getName());
            this.setCurrentCity(this.profile.getCity(), this.profile.getPostalCode(), this.profile.getCountry());
        } else {
            this.setCurrentCity(city.getName(), city.getPostalCode(), city.getCountry());
        }

        // reset cities to close auto-completion list:
        this.cities = [];

        this.isChecked = false;
        this.checkCity();
        this.onSelectCity.emit(city);
    }

    private setCurrentCity(city, postalCode, country) {
        let currentCity = city || '';

        if (this.marker && postalCode && country) {
            currentCity += ' (' + postalCode + ', ' + country + ')';
        }
        this.currentCity = currentCity;
    }

    geolocation() {
        if (this.geolocatedCity) {
            this.setCity(this.geolocatedCity);
        } else if (navigator.geolocation) {
            this.cities = [];
            this.waitingGeoloc = true;
            navigator.geolocation.getCurrentPosition((position) => {
                this.position = position;

                this.geolocationService.find(position.coords.latitude, position.coords.longitude)
                    .subscribe((response: any) => {
                        this.waitingGeoloc = false;
                        if (!response.error && typeof response.data !== 'undefined') {
                            this.geolocatedCity = (new City()).deserialize({
                                name: response.data.city,
                                country: response.data.country,
                                postalCode: response.data.postalCode,
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });

                            this.setCity(this.geolocatedCity);
                        }
                    });
            });
        }
    }

    canShowLoader(): boolean {
        let showLoader = false;
        if (this.useLoader) {
            if (this.loadingCity && this.cities.length === 0 && this.currentCity.length > 0) {
                showLoader = true;
            } else if (this.waitingGeoloc) {
                showLoader = true;
            }
        }
        return showLoader;
    }

    checkCity() {
        this.errorUnpicked = (this.isChecked && (this.currentCity.length > 0 || this.cities.length > 0));
        this.onErrorUnpicked.emit(this.errorUnpicked);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedCityObject && changes.selectedCityObject.currentValue) {
            this.setCity(changes.selectedCityObject.currentValue);
        }
    }
}
