import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'cancel-button',
    templateUrl: './cancel-button.component.html',
    styleUrls: ['./cancel-button.component.scss']
})
export class CancelButtonComponent {

    @Input() dataTarget: string;
    @Input() ariaControls: string;
    @Output() cancel = new EventEmitter<object>();

    constructor(
    ) {
    }

    cancelButton() {
        this.cancel.emit({});
    }

}
