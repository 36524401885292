import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-pagination-component',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

    @Input('maxPage') maxPageInput = 1;
    @Input('currentPage') currentPageInput = 1;
    @Output() pageChange = new EventEmitter();

    pages: any[];
    page = 1;
    maxPage = 1;

    ngOnChanges(changes: SimpleChanges) {

        if (changes.maxPageInput && changes.maxPageInput.currentValue >= 1) {
            this.maxPage = +changes.maxPageInput.currentValue;
        }

        if (changes.currentPageInput && changes.currentPageInput.currentValue >= 1) {
            this.page = +changes.currentPageInput.currentValue;
        }

        if (this.page > this.maxPage) {
            this.page = this.maxPage;
        }

        this.initializePagination();
    }

    initializePagination() {
        const pages = [];

        const start = this.page - 2;
        const max = this.page + 2;

        // before :
        if (this.page > 4) {
            for (let p = start; p < this.page; p++) {
                pages.push(p);
            }
        } else if (this.page > 1) {
            for (let p = 1; p < this.page; p++) {
                pages.push(p);
            }
        }

        pages.push(this.page);

        // after :
        if (this.page + 1 < this.maxPage - 4) {
            for (let p = (this.page + 1); p <= max; p++) {
                pages.push(p);
            }
        } else {
            for (let p = (this.page + 1); p <= this.maxPage; p++) {
                pages.push(p);
            }
        }

        this.pages = pages;
    }

    getPagesList() {
        return this.pages;
    }

    isCurrentPage(page: number) {
        return page === this.page;
    }

    hasNext(): boolean {
        return this.page < this.maxPage && this.showArrows();
    }

    setCurrentPage(page: number) {
        if (page !== this.page) {
            this.page = +page;
            this.pageChange.emit(this.page);
        }
    }

    lastPage() {
        this.page = this.maxPage;
        this.pageChange.emit(this.page);
    }

    showArrows(): boolean {
        return this.maxPage > 5;
    }
}
