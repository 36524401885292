import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../../services/modal/modal.service';
import {LoaderService} from '../../../../services/loader/loader.service';
import {BillingService} from '../../../../services/billing/billing.service';

@Component({
    selector: 'app-virilblue-one-click',
    templateUrl: './virilblue-one-click.component.html',
    styleUrls: ['./virilblue-one-click.component.scss']
})
export class VirilblueOneClickComponent implements OnInit {

    terms = true;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private loaderService: LoaderService,
        private billingService: BillingService
    ) {
    }

    ngOnInit(): void {
        this.loaderService.setLoading(true);

        this.billingService.canUpsellVirilblue().subscribe((available) => {
            this.loaderService.setLoading(false);
            if (!available) {
                this.router.navigate(['/home']).then();
            }
        });
    }

    accept() {
        if (!this.terms) {
            this.modalService.openConfirmation({
                data: {
                    title: 'error',
                    question: 'accept_cgv',
                    responses: [
                        {text: 'ok', result: true},
                    ],
                    faClass: 'fa-lock',
                }
            }).afterClosed().subscribe();
            return;
        }

        this.loaderService.setLoading(true);
        this.billingService.upsellVirilblue(
            location.origin + '/upsell/virilblue/payment-accepted',
            location.origin + '/upsell/virilblue/payment-refused'
        ).subscribe(response => {
            switch (response.transactionStatus) {
                /**
                 * 3DS Redirection
                 */
                case 101:
                    window.location.href = response.redirectUrl;
                    break;
                /**
                 * Paiement accepted
                 */
                case 1:
                    this.router.navigate(['/upsell/virilblue/payment-accepted']).then(() => {
                        this.loaderService.setLoading(false);
                    });
                    break;
                /**
                 * Paiement refused
                 */
                default:
                    this.router.navigate(['/upsell/virilblue/payment-refused']).then(() => {
                        this.loaderService.setLoading(false);
                    });
                    break;
            }
        }, (error) => {
            this.router.navigate(['/upsell/virilblue/payment-refused']).then(() => {
                this.loaderService.setLoading(false);
            });
        });
    }

    refuse() {
        this.router.navigate(['/home']).then();
    }
}
