import {Component, Injector} from '@angular/core';
import {ProfileService} from '../../../services/profile/profile.service';
import {BaseComponent} from '../../base/base.component';
import {ModalService} from '../../../services/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-support-mail-component',
    templateUrl: './support-mail.component.html',
    styleUrls: ['./support-mail.component.scss']
})
export class SupportMailComponent extends BaseComponent {

    subject = '';
    message = '';
    success: boolean;
    formDisabled: boolean;

    constructor(
        private profileService: ProfileService,
        private modalService: ModalService,
        private translate: TranslateService,
        injector: Injector
    ) {
        super(injector);
    }

    sendMessageReportCustomerService() {
        if (this.formDisabled) {
            return;
        }

        this.formDisabled = true;
        
        this.profileService.sendMessageToSupport(this.account.getProfile(), this.subject, this.message).subscribe((response: any) => {
            this.success = response.success;
            this.message = '';
            this.subject = '';
            this.formDisabled = false;
        }, () => {
            this.success = false;
            this.formDisabled = false;
        });
    }
}
