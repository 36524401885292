import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CurrentUserService} from '../../../../services/user/current-user.service';
import {Gender} from '../../../../models/user/gender';

@Component({
    selector: 'app-finish-signup-searched-gender',
    templateUrl: './finish-signup-searched-gender.component.html',
    styleUrls: ['./finish-signup-searched-gender.component.scss']
})
export class FinishSignupSearchedGenderComponent {

    @Output() previousStep = new EventEmitter<number>();
    @Output() nextStep = new EventEmitter<number>();
    @Input() currentStep: number;

    searchedGender: number;

    constructor(
        private currentUserService: CurrentUserService
    ) {
        this.searchedGender = this.currentUserService.getAccount().getProfile().getSearchedGender();
    }

    isMale(): boolean {
        return this.searchedGender === Gender.MALE;
    }

    isFemale(): boolean {
        return this.searchedGender === Gender.FEMALE;
    }

    previous() {
        this.previousStep.emit();
    }

    next() {
        this.nextStep.emit();
    }

    hasPreviousButton() {
        return this.currentStep >= 2;
    }
}
