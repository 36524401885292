import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    status: boolean;
    localStorage: boolean;
    data = {};

    constructor() {
        this.status = StorageService.checkStorage();
        this.localStorage = StorageService.localStorageIsSupported();
    }

    /**
     * Set a local data
     * @param name string
     * @param value string
     */
    set(name: string, value): void {
        if (!this.status) {
            this.data[name] = value;
        } else if (this.localStorage) {
            window.localStorage.setItem(name, value);
        } else {
            const d = new Date();
            d.setTime(d.getTime() + 3600 * 24 * 1000);
            document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';path=/';
        }
    }

    /**
     * Get a local data
     * @param name string
     */
    get(name: string): string {
        if (!this.status) {
            return this.data[name] || null;
        } else if (this.localStorage && window.localStorage.getItem(name) !== null) {
            return window.localStorage.getItem(name);
        } else {
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                const nameValue = cookie.trim().split('=');
                if (name === nameValue[0]) {
                    if (nameValue.length > 1) {
                        return nameValue[1];
                    } else {
                        return null;
                    }
                }
            }

            return null;
        }
    }

    /**
     * Delete a local data
     * @param name string
     */
    delete(name: string): void {
        if (!this.status) {
            delete this.data[name];
        } else if (this.localStorage) {
            window.localStorage.removeItem(name);
        } else {
            const d = new Date();
            d.setTime(0);
            document.cookie = name + '=;expires=' + d.toUTCString() + ';path=/';
        }
    }

    /**
     * Check if localStorage is supported.
     * Also check if it's not bugged or broken (e.g. firefox NS_ERROR_STORAGE_IOERR).
     * https://caniuse.com/#search=localStorage
     */
    private static localStorageIsSupported(): boolean {
        try {
            window.localStorage.setItem('localStorageTest', 'test');
            window.localStorage.removeItem('localStorageTest');
            return (typeof (Storage) !== 'undefined' && !!window.localStorage);
        } catch {
            return false;
        }
    }

    private static checkStorage(): boolean {
        try {
            // Quick test if browser has cookieEnabled host property
            if (navigator.cookieEnabled) {
                return true;
            }

            // Create cookie
            document.cookie = "cookieCheck=1";
            const cookieExists = document.cookie.includes("cookieCheck=");
            // Delete cookie
            document.cookie = "cookieCheck=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";

            return cookieExists;
        } catch {
            return false;
        }
    }
}
