import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injectable, NgModule} from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {ToastrModule} from 'ngx-toastr';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ClickOutsideModule} from 'ng-click-outside';
import {SlickCarouselModule} from 'ngx-slick-carousel';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import * as Sentry from '@sentry/angular';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatDateFormats,
    MatNativeDateModule,
    NativeDateAdapter
} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';

/* ELEMENTS COMPONENTS */
import {TopHeaderComponent} from './components/header/top-header/top-header.component';
import {MenuComponent} from './components/header/menu/menu.component';
import {HeaderComponent} from './components/header/header.component';
import {TemplateStyleComponent} from './components/template-style/template-style.component';

/* PAGES COMPONENTS */
import {AbstractPaymentComponent} from './components/payment/abstract-payment.component';
import {LayoutComponent} from './components/layout/layout.component';
import {ProfileComponent} from './components/profile/profile.component';
import {HomeComponent} from './components/home/home.component';
import {SearchComponent} from './components/search/search.component';
import {SupportComponent} from './components/support/support.component';
import {FooterComponent} from './components/footer/footer.component';
import {SupportChatComponent} from './components/support/support-chat/support-chat.component';
import {SupportPhoneComponent} from './components/support/support-phone/support-phone.component';
import {SupportMailComponent} from './components/support/support-mail/support-mail.component';
import {ChatComponent} from './components/chat/chat.component';
import {ChatMobileComponent} from './components/chat/mobile/chat-mobile.component';
import {ContactComponent} from './components/contact/contact.component';
import {CardComponent} from './components/profile/card/card.component';
import {CertificationComponent} from './components/profile/certification/certification.component';
import {ProfileTabComponent} from './components/profile/detail/profile-tab/profile-tab.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {CoachingSeductionComponent} from './components/coaching-seduction/coaching-seduction.component';
import {EbooksComponent} from './components/ebooks/ebooks.component';
import {ThumbProfileComponent} from './components/search/thumb-profile/thumb-profile.component';
import {SaveButtonComponent} from './components/profile/save-button/save-button.component';
import {NotificationComponent} from './components/notification/notification.component';
import {NotificationContainerComponent} from './components/notification/notification-container/notification-container.component';
import {EbookPageComponent} from './components/ebooks/ebook-page/ebook-page.component';
import {SupportContactComponent} from './components/support/support-contact/support-contact.component';
import {PendingConfirmationComponent} from './components/pending-confirmation/pending-confirmation.component';

import {AccordionResponsesComponent} from './components/profile/accordion-responses/accordion-responses.component';
import {ChoiceListComponent} from './components/profile/choice-list/choice-list.component';
import {SelectedChoiceComponent} from './components/profile/selected-choice/selected-choice.component';
import {DetailComponent} from './components/profile/detail/detail.component';
import {AlbumsComponent} from './components/profile/albums/albums.component';
import {AlertComponent} from './components/alert/alert.component';
import {ChatAlertComponent} from './components/alert/chat-alert/chat-alert.component';
import {ConfirmationMailComponent} from './components/alert/confirmation-mail/confirmation-mail.component';
import {ConfirmationMobileComponent} from './components/alert/confirmation-mobile/confirmation-mobile.component';
import {GetSubscriptionComponent} from './components/alert/get-subscription/get-subscription.component';
import {HardbouncedModalTriggerComponent} from './components/alert/hardbounced-modal-trigger/hardbounced-modal-trigger.component';
import {SettingComponent} from './components/account/setting/setting.component';
import {TchatWebcamComponent} from './components/tchat-webcam/tchat-webcam.component';
import {MenuMobileComponent} from './components/header/menu-mobile/menu-mobile.component';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {SupportFaqComponent} from './components/support/support-faq/support-faq.component';
import {EditPrivacyComponent} from './components/profile/edit-privacy/edit-privacy.component';
import {AccountComponent} from './components/account/account.component';
import {ModalComponent} from './components/modal/modal.component';
import {UploadPhotoComponent} from './components/modal/upload-photo/upload-photo.component';
import {EmailConfirmationComponent} from './components/modal/email-confirmation/email-confirmation.component';
import {MobileConfirmationComponent} from './components/modal/mobile-confirmation/mobile-confirmation.component';
import {MobileFormComponent} from './components/modal/mobile-form/mobile-form.component';
import {ConfirmationComponent} from './components/modal/confirmation/confirmation.component';
import {FullScreenConfirmationComponent} from './components/modal/full-screen-confirmation/full-screen-confirmation.component';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NouisliderModule} from 'ng2-nouislider';
import {TranslateLoader, TranslateModule, TranslateService, TranslateCompiler} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {ModificationSettingsComponent} from './components/modal/modification-settings/modification-settings.component';
import {UnsubscribeComponent} from './components/modal/unsubscribe/unsubscribe.component';
import {UpsellXponsorUnloverComponent} from './components/modal/upsell/xponsor/unlover/unlover.component';
import {FinishSignupComponent} from './components/modal/finish-signup/finish-signup.component';
import {ReportProfileComponent} from './components/modal/report-profile/report-profile.component';
import {BaseComponent} from './components/base/base.component';
import {AlbumModalComponent} from './components/modal/album/album.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';

import {LoaderComponent} from './components/loader/loader.component';
import {AlbumListComponent} from './components/profile/albums/list/album-list.component';
import {AlbumViewComponent} from './components/profile/albums/view/album-view.component';
import {CityTypeaheadComponent} from './components/city-typeahead/city-typeahead.component';
import {RestrictionBannerComponent} from './components/chat/restriction-banner/restriction-banner.component';
import {TranslateComponent} from './components/element/translate/translate.component';

/* Directives */
import {AutofocusDirective} from './directives/autofocus.directive';
import {ElapsedTimeDirective} from './directives/elapsed-time.directive';
import {ErrorSrcDirective} from './directives/error-src.directive';

/* Filters */
import {FilterCountryPipe} from './pipes/filter-country.pipe';
import {MaxStringSizePipe} from './pipes/max-string-size.pipe';
import {UserImagePipe} from './pipes/user-image.pipe';
import {UploadPhotoCreateComponent} from './components/modal/upload-photo/create/upload-photo-create.component';
import {AlbumUpdateModalComponent} from './components/modal/album/update/album-update.component';
import {AlbumCreateModalComponent} from './components/modal/album/create/album-create.component';
import {FinishSignupGenderComponent} from './components/modal/finish-signup/gender/finish-signup-gender.component';
import {
    FinishSignupSearchedGenderComponent
} from './components/modal/finish-signup/searched-gender/finish-signup-searched-gender.component';
import {FinishSignupNicknameComponent} from './components/modal/finish-signup/nickname/finish-signup-nickname.component';
import {FinishSignupPasswordComponent} from './components/modal/finish-signup/password/finish-signup-password.component';
import {FinishSignupBirthdateComponent} from './components/modal/finish-signup/birthdate/finish-signup-birthdate.component';
import {FinishSignupLocationComponent} from './components/modal/finish-signup/location/finish-signup-location.component';
import {FinishSignupPhotoComponent} from './components/modal/finish-signup/photo/finish-signup-photo.component';
import {FinishSignupConfirmationComponent} from './components/modal/finish-signup/confirmation/finish-signup-confirmation.component';
import {LoginComponent} from './components/login/login.component';
import {UpsellXponsorUnloverChoiceComponent} from './components/modal/upsell/xponsor/unlover/choice/unlover-choice.component';
import {UpsellXponsorUnloverSuccessComponent} from './components/modal/upsell/xponsor/unlover/success/unlover-success.component';
import {UpsellXponsorUnloverErrorComponent} from './components/modal/upsell/xponsor/unlover/error/unlover-error.component';
import {ActionComponent} from './components/profile/action/action.component';
import {ContactThumbProfileComponent} from './components/contact/contact-thumb-profile/contact-thumb-profile.component';
import {EmojiComponent} from './components/chat/emoji/emoji.component';
import {PaymentComponent} from './components/payment/payment.component';
import {SanitizePipe} from './pipes/sanitize.pipe';
import {PaymentAcceptedComponent} from './components/payment-accepted/payment-accepted.component';
import {PaymentRefusedComponent} from './components/payment-refused/payment-refused.component';
import {PaymentAlreadyAcceptedComponent} from './components/payment-already-accepted/payment-already-accepted.component';
import {ConfirmEmailComponent} from './components/confirm-email/confirm-email.component';
import {ConfirmNicknameComponent} from './components/confirm-nickname/confirm-nickname.component';
import {ConfirmPasswordComponent} from './components/confirm-password/confirm-password.component';
import {SearchFilterComponent} from './components/search/search-filter/search-filter.component';
import {LoginService} from './services/login/login.service';
import {WINDOW} from './app.config';
import {environment} from '../environments/environment';
import {InfiniteScrollComponent} from './components/infinite-scroll/infinite-scroll.component';
import {PanicComponent} from './components/panic/panic.component';
import {PanicButtonComponent} from './components/panic/panic-button/panic-button.component';

/* Locale */
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {SearchAppearanceComponent} from './components/search-appearance/search-appearance.component';
import {SearchAppearanceBarComponent} from './components/search-appearance/search-appearance-bar/search-appearance-bar.component';
import {PaymentRedirectComponent} from './components/payment-redirect/payment-redirect.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from '@angular/material/tooltip';
import {FormatHtmlPipe} from './pipes/format-html.pipe';
import {PurchaseComponent} from './components/account/purchase/purchase.component';
import {MobileComponent} from './components/profile/action/mobile/mobile.component';
import {FullAccessComponent} from './components/profile/detail/full-access/full-access.component';
import {PrivacyInformationComponent} from './components/profile/detail/privacy-information/privacy-information.component';
import {CancelButtonComponent} from './components/profile/cancel-button/cancel-button.component';
import {NavigationStart, Router} from '@angular/router';
import {SubscriptionComponent} from './components/account/subscription/subscription.component';
import {MyAccountComponent} from './components/account/my-account/my-account.component';
import {ChatDesktopComponent} from './components/chat/desktop/chat-desktop.component';
import {ChatUsersConnectedComponent} from './components/chat/users-connected/chat-users-connected.component';
import {RoomMessagesComponent} from './components/chat/room-messages/room-messages.component';
import {ChatMessageFormComponent} from './components/chat/message-form/chat-message-form.component';
import {ChatRoomsListComponent} from './components/chat/rooms-list/chat-rooms-list.component';
import {ChatUserActionComponent} from './components/chat/user-action/chat-user-action.component';
import {BankTransferComponent} from './components/payment/bank-transfer/bank-transfer.component';
import {CheckComponent} from './components/payment/check/check.component';
import {PickOnAlbumComponent} from './components/modal/upload-photo/pick-on-album/pick-on-album.component';
import {EmailActionResolverComponent} from './components/email-action-resolver/email-action-resolver.component';
import {SuspensionComponent} from './components/account/suspension/suspension.component';
import {SuppressionComponent} from './components/account/suppression/suppression.component';
import {formatDate} from './services/helper/date.service';
import {NavigatorComponent} from './components/navigator/navigator.component';
import {FaqComponent} from './components/faq/faq.component';
import {StaticPageComponent} from './components/static-page/static-page.component';
import {MessageNotificationComponent} from './components/chat/message-notification/message-notification.component';
import {UserPhotoComponent} from './components/chat/user-photo/user-photo.component';

import {OffersComponent} from './components/payment/offers/offers.component';
import {OfferComponent} from './components/payment/offer/offer.component';
import {OfferTemplate1Component} from './components/payment/offer/templates/offer-template1/offer-template1.component';
import {OffersTemplate1Component} from './components/payment/offers/templates/offers-template1/offers-template1.component';
import {PaymentPageComponent} from './components/payment/payment-page/payment-page.component';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {ChatDisconnectedComponent} from './components/chat/disconnected/chat-disconnected.component';
import {AdSpaceComponent} from './components/ad-space/ad-space.component';
import {QuestionGroupComponent} from './components/profile/question-group/question-group.component';
import {SelectedChoiceGroupComponent} from './components/profile/selected-choice-group/selected-choice-group.component';
import {PhotoSliderComponent} from './components/profile/detail/photo-slider/photo-slider.component';
import {A11yModule} from '@angular/cdk/a11y';
import {BoosterComponent} from './components/modal/booster/booster.component';
import {CoinWarningComponent} from './components/modal/coin-warning/coin-warning.component';
import {BoosterPhotoComponent} from './components/modal/booster/photo/booster-photo.component';
import {BoosterConfirmationComponent} from './components/modal/booster/confirmation/booster-confirmation.component';
import {BoosterListComponent} from './components/modal/booster/list/booster-list.component';
import {BoosterAlreadyComponent} from './components/modal/booster/already/booster-already.component';
import {OffersTemplate2Component} from './components/payment/offers/templates/offers-template2/offers-template2.component';
import {SexCamsComponent} from './components/sex-cams/sex-cams.component';
import {ThumbCamModelComponent} from './components/sex-cams/thumb-cam-model/thumb-cam-model.component';
import {SexCamsFilterComponent} from './components/sex-cams/sex-cams-filter/sex-cams-filter.component';
import {ShowComponent} from './components/sex-cams/show/show.component';
import {CamModelDetailComponent} from './components/modal/cam-model-detail/cam-model-detail.component';
import {SexCamConfirmationComponent} from './components/modal/sex-cam-confirmation/sex-cam-confirmation.component';
import {SexCamsCoinWarningComponent} from './components/modal/sex-cams-coin-warning/sex-cams-coin-warning.component';
import {SexCamsService} from './services/sex-cams/sex-cams.service';
import {UnpaidSubscriptionComponent} from './components/alert/unpaid-subscription/unpaid-subscription.component';

import {VirilblueOneClickComponent} from './components/upsell/virilblue/virilblue-one-click/virilblue-one-click.component';
import {VirilblueFinishComponent} from './components/upsell/virilblue/finish/virilblue-finish.component';
import {VirilbluePaymentAcceptedComponent} from './components/upsell/virilblue/payment-accepted/virilblue-payment-accepted.component';
import {VirilbluePaymentRefusedComponent} from './components/upsell/virilblue/payment-refused/virilblue-payment-refused.component';
import {VirilblueLayoutComponent} from './components/upsell/virilblue/virilblue-layout/virilblue-layout.component';
import {LogoutComponent} from './components/logout/logout.component';
import {PendingMessageComponent} from './components/chat/pending-message/pending-message.component';
import {PreAuthorizationComponent} from './components/payment/pre-authorization/pre-authorization.component';
import {VirilblueCbComponent} from './components/upsell/virilblue/virilblue-cb/virilblue-cb.component';
import {VirilblueComponent} from './components/upsell/virilblue/virilblue/virilblue.component';
import {LiveOneClickComponent} from './components/upsell/live/live-one-click/live-one-click.component';
import {LiveLayoutComponent} from './components/upsell/live/live-layout/live-layout.component';
import {LivePaymentAcceptedComponent} from './components/upsell/live/live-payment-accepted/live-payment-accepted.component';
import {LivePaymentRefusedComponent} from './components/upsell/live/live-payment-refused/live-payment-refused.component';
import {LiveCbComponent} from './components/upsell/live/live-cb/live-cb.component';
import {LiveComponent} from './components/upsell/live/live/live.component';
import {UnloverComponent} from './components/upsell/unlover/unlover/unlover.component';
import {UnloverLayoutComponent} from './components/upsell/unlover/unlover-layout/unlover-layout.component';
import {UnloverPaymentAcceptedComponent} from './components/upsell/unlover/unlover-payment-accepted/unlover-payment-accepted.component';
import {UnloverPaymentRefusedComponent} from './components/upsell/unlover/unlover-payment-refused/unlover-payment-refused.component';
import {CoinPaymentComponent} from './components/modal/coin-payment/coin-payment.component';

registerLocaleData(localeFr, 'fr');

const MatTooltipDefaults = {
    position: 'above'
};

// loader module
export function HttpLoaderFactory(http: HttpClient) {
    const build = require('../build.json');
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + build.version);
}

export function Autologin(loginService: LoginService) {
    return (): Promise<any> => {
        return loginService.autologin();
    };
}

export function windowFactory(): Window {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'input') {
            return formatDate(date, 'd/m/Y');
        }
        return date.toDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    },
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'numeric'},
        dateA11yLabel: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
};

if (environment.sentryDsn) {
    const ignoreErrors = [
        /Non-Error exception captured/,
        /Non-Error promise rejection captured with value: Object Not Found Matching Id/,
        /Http failure response/,
        /target.className.indexOf/,
        /removeChild/,
        /split/,
        /trim/,
        /null is not an object/,
        /Use of const in strict mode/,
        /isTrusted/,
        /Unexpected token </,
        /Unexpected token ';'/,
        /NS_ERROR_/,
        /document.querySelector/,
        /TypeError: Failed to fetch/,
        /AbortError: Error retrieving push subscription/
    ];

    Sentry.init({
        dsn: environment.sentryDsn,
        beforeSend(event, hint) {
            const originalException = hint.originalException ? hint.originalException['message'] : null;

            for (const ignoreError of ignoreErrors) {
                if (event.exception && event.exception.values[0].value.match(ignoreError) ||
                    originalException && originalException.match(ignoreError)) {
                    console.error('Ignoring error', event);
                    return null;
                }
            }

            return event;
        },
        allowUrls: [
            environment.domain
        ]
    });
}

@NgModule({
    declarations: [
        /* ELEMENTS */
        TopHeaderComponent,
        MenuComponent,
        HeaderComponent,
        TemplateStyleComponent,
        SearchComponent,
        FooterComponent,
        /* PAGES */
        AppComponent,
        AbstractPaymentComponent,
        LayoutComponent,
        HomeComponent,
        ProfileComponent,
        SupportComponent,
        SupportChatComponent,
        SupportPhoneComponent,
        SupportMailComponent,
        ChatComponent,
        ChatMobileComponent,
        ContactComponent,
        CardComponent,
        CertificationComponent,
        PaginationComponent,
        CoachingSeductionComponent,
        EbooksComponent,
        ThumbProfileComponent,
        SaveButtonComponent,
        NotificationComponent,
        NotificationContainerComponent,
        AccordionResponsesComponent,
        ChoiceListComponent,
        SelectedChoiceComponent,
        DetailComponent,
        AlbumsComponent,
        AlertComponent,
        ChatAlertComponent,
        ConfirmationMailComponent,
        ConfirmationMobileComponent,
        GetSubscriptionComponent,
        SettingComponent,
        TchatWebcamComponent,
        MenuMobileComponent,
        LanguageSelectorComponent,
        EditPrivacyComponent,
        SupportFaqComponent,
        AccountComponent,
        ModalComponent,
        UploadPhotoComponent,
        EmailConfirmationComponent,
        MobileConfirmationComponent,
        MobileFormComponent,
        ConfirmationComponent,
        ModificationSettingsComponent,
        UnsubscribeComponent,
        UpsellXponsorUnloverComponent,
        FinishSignupComponent,
        ReportProfileComponent,
        AlbumModalComponent,
        BaseComponent,
        LoaderComponent,
        AutofocusDirective,
        ElapsedTimeDirective,
        ErrorSrcDirective,
        FilterCountryPipe,
        MaxStringSizePipe,
        UserImagePipe,
        SanitizePipe,
        AlbumListComponent,
        AlbumViewComponent,
        UploadPhotoCreateComponent,
        PickOnAlbumComponent,
        AlbumUpdateModalComponent,
        AlbumCreateModalComponent,
        LoginComponent,
        FinishSignupConfirmationComponent,
        UpsellXponsorUnloverChoiceComponent,
        UpsellXponsorUnloverSuccessComponent,
        UpsellXponsorUnloverErrorComponent,
        ActionComponent,
        ContactThumbProfileComponent,
        EmojiComponent,
        PaymentComponent,
        CityTypeaheadComponent,
        PaymentAcceptedComponent,
        PaymentRefusedComponent,
        PaymentAlreadyAcceptedComponent,
        ConfirmEmailComponent,
        ConfirmNicknameComponent,
        ConfirmPasswordComponent,
        SearchFilterComponent,
        FullScreenConfirmationComponent,
        HardbouncedModalTriggerComponent,
        SearchAppearanceComponent,
        SearchAppearanceBarComponent,
        PaymentRedirectComponent,
        FormatHtmlPipe,
        PurchaseComponent,
        MobileComponent,
        FullAccessComponent,
        PrivacyInformationComponent,
        EbookPageComponent,
        SupportContactComponent,
        CancelButtonComponent,
        PendingConfirmationComponent,
        InfiniteScrollComponent,
        SubscriptionComponent,
        MyAccountComponent,
        ChatDesktopComponent,
        ChatUsersConnectedComponent,
        RoomMessagesComponent,
        ChatMessageFormComponent,
        ChatRoomsListComponent,
        ChatUserActionComponent,
        BankTransferComponent,
        CheckComponent,
        PanicComponent,
        PanicButtonComponent,
        EmailActionResolverComponent,
        SuspensionComponent,
        SuppressionComponent,
        NavigatorComponent,
        FinishSignupGenderComponent,
        FinishSignupSearchedGenderComponent,
        FinishSignupNicknameComponent,
        FinishSignupPasswordComponent,
        FinishSignupBirthdateComponent,
        FinishSignupLocationComponent,
        FinishSignupPhotoComponent,
        FaqComponent,
        StaticPageComponent,
        MessageNotificationComponent,
        UserPhotoComponent,
        RestrictionBannerComponent,
        TranslateComponent,
        OffersComponent,
        OfferComponent,
        OfferTemplate1Component,
        OffersTemplate1Component,
        PaymentPageComponent,
        Nl2brPipe,
        ChatDisconnectedComponent,
        AdSpaceComponent,
        QuestionGroupComponent,
        SelectedChoiceGroupComponent,
        ProfileTabComponent,
        PhotoSliderComponent,
        BoosterComponent,
        CoinWarningComponent,
        BoosterPhotoComponent,
        BoosterConfirmationComponent,
        BoosterListComponent,
        BoosterAlreadyComponent,
        OffersTemplate2Component,
        SexCamsComponent,
        ThumbCamModelComponent,
        SexCamsFilterComponent,
        ShowComponent,
        CamModelDetailComponent,
        SexCamConfirmationComponent,
        SexCamsCoinWarningComponent,
        UnpaidSubscriptionComponent,
        VirilblueOneClickComponent,
        VirilblueFinishComponent,
        VirilbluePaymentAcceptedComponent,
        VirilbluePaymentRefusedComponent,
        VirilblueLayoutComponent,
        LogoutComponent,
        PendingMessageComponent,
        CoinPaymentComponent,
        PreAuthorizationComponent,
        VirilblueCbComponent,
        VirilblueComponent,
        LiveOneClickComponent,
        LiveLayoutComponent,
        LivePaymentAcceptedComponent,
        LivePaymentRefusedComponent,
        LiveCbComponent,
        LiveComponent,
        UnloverComponent,
        UnloverLayoutComponent,
        UnloverPaymentAcceptedComponent,
        UnloverPaymentRefusedComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NouisliderModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        BrowserAnimationsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        FormsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        ImageCropperModule,
        ClickOutsideModule,
        ReactiveFormsModule,
        MatTooltipModule,
        SlickCarouselModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        A11yModule
    ],
    exports: [TranslateModule],
    providers: [
        TranslateService,
        LoginService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: WINDOW, useFactory: windowFactory},
        {provide: APP_INITIALIZER, useFactory: Autologin, deps: [LoginService], multi: true},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {provide: Sentry.TraceService, deps: [Router]},
        {
            provide: APP_INITIALIZER, useFactory: () => () => {
            }, deps: [Sentry.TraceService], multi: true,
        },
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MatTooltipDefaults},
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ],
    entryComponents: [
        UploadPhotoComponent,
        EmailConfirmationComponent,
        MobileConfirmationComponent,
        ConfirmationComponent,
        FullScreenConfirmationComponent,
        ModificationSettingsComponent,
        UnsubscribeComponent,
        UpsellXponsorUnloverComponent,
        FinishSignupComponent,
        ReportProfileComponent,
        AlbumModalComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private translate: TranslateService, private router: Router, private sexCamsService: SexCamsService) {
        translate.addLangs(['de', 'en', 'es', 'fr', 'it', 'pt']);
        translate.setDefaultLang('fr');
        translate.use('fr');

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (!event.url.startsWith('/sex-cams/c') && this.sexCamsService.currentCamModel) {
                    this.sexCamsService.stopCamShow();
                }
            }
        });
    }
}
