import {Component, Inject, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CurrentUserService} from '../../../services/user/current-user.service';
import {CoinOffer} from '../../../models/offer/coin-offer';
import {CoinPaymentComponent} from '../coin-payment/coin-payment.component';

@Component({
    selector: 'app-coin-warning',
    templateUrl: './coin-warning.component.html',
    styleUrls: ['./coin-warning.component.scss']
})
export class CoinWarningComponent {

    offer: CoinOffer;

    constructor(
        private router: Router,
        private currentUserService: CurrentUserService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CoinWarningComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.offer = data.offer;
    }

    getOffer(): CoinOffer {
        return this.offer;
    }

    getCoins(): number {
        return this.currentUserService.getAccount().getCoins();
    }

    goPayment() {
        this.dialog.open(CoinPaymentComponent, {
            panelClass: 'modal-box',
            maxWidth: '100%',
            data: {action: 'coin_warning'}
        });
        this.dialogRef.close();
    }
}
