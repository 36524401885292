<app-modal>
    <div class="modal-body">
        <div  *ngIf="step === 1" class="container changer-photo">
            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'email_conf.check' | translate }}</span>{{ 'email_conf.click_thanks' | translate }} {{ 'email_conf.click_in_email' | translate }} {{ site.getName() }}.
                <br class="br-mobile">
                {{ 'email_conf.check_spam' | translate }}
            </p>

            <button (click)="sendEmailConfirmation()" [disabled]="formDisabled" class="btn btn-primary btn-lg btn-block font-weight-bold text-uppercase">
                <i *ngIf="formDisabled" class="fa fa-spinner fa-pulse fa-1x"></i>
                <i *ngIf="!formDisabled" class="fa fa-check" aria-hidden="true"></i>
                {{ 'email_conf.check_my_email' | translate }}
            </button>

            <hr>

            <p class="infos w-100 text-center">
                <br>{{ 'email_conf.paste_the_code' | translate }} <span class="font-weight-bold">{{ 'email_conf.activation_code' | translate }}</span> {{ 'email_conf.paste_from_email' | translate }}
            </p>

            <div class="input-group">
                <span class="input-group-text monemail"><i class="fas fa-key"></i></span>
                <input type="text" name="activationCode" [(ngModel)]="activationCode" maxlength="6" class="form-control form-control-lg" placeholder="{{ 'email_conf.activation_code_placeholder' | translate }}">
            </div>

            <hr>

            <p class="infos w-100 text-center">
                <span class="font-weight-bold">{{ 'email_conf.type_error' | translate }}</span> {{ 'email_conf.no_worry' | translate }}
            </p>

            <div class="input-group">
                <span class="input-group-text monemail"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                <input [(ngModel)]="newEmail" name="email" type="text" class="form-control form-control-lg" placeholder="{{ account.getEmail() }}">
                <button *ngIf="showChangeAndSendButton()" (click)="updateEmail()" class="btn btn-secondary modren renvoyer col-12 mt-2">{{ 'email_conf.change_and_send' | translate }}</button>
            </div>
        </div>
        <div *ngIf="step === 2" class="container">
            <h4 class="h6 w-100 text-center my-2 font-weight-bold">
                <span class="mb-1">{{ 'email_conf.thanks_done' | translate }}</span><br>
                <span>{{ 'email_conf.profile_notif' | translate }}</span><br>
                <span>{{ 'email_conf.change_params' | translate }}</span>
            </h4>
        </div>
    </div>

    <div class="modal-footer">
        <button *ngIf="step === 1" class="btn btn-dark btn-sm mr-2 text-uppercase" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
        <button *ngIf="step === 1" [disabled]="!validActivationCode() || showChangeAndSendButton()" (click)="checkEmail()" class="btn btn-primary btn-sm text-uppercase">{{ 'validate' | translate }}</button>

        <button *ngIf="step === 2" [mat-dialog-close]="true" class="btn btn-primary btn-sm text-uppercase">
            <i class="fas fa-check"></i>
            <span>Ok</span>
        </button>
    </div>
</app-modal>
