import { Component } from '@angular/core';
import {ActionComponent} from '../action.component';

@Component({
  selector: 'app-profile-action-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent extends ActionComponent {
}
