<div class="modal-body step-photo">

    <div class="container text-center">

        <div *ngIf="photoStep === 1">
            <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/ajouter-photo.png">
            <br>
        </div>
        <h1>{{ 'profile_picture.choose_profile_picture' | translate }}</h1>
        <p class="infos w-100 text-center" [innerHTML]="getDescription(photoStep)"></p>
        <div class="form-group mb-0">
            <div class="input-group w-100" *ngIf="photoStep === 1">
                <span class="input-group-text" style="min-width:42px;">
                    <i class="fas fa-camera mx-auto"></i>
                </span>
                <div class="custom-file">
                    <input type="file" class="custom-file-input" id="customFile" (change)="fileChangeEvent($event)">
                    <label class="custom-file-label text-left" title="{{ searchLabel }}" for="customFile">{{ 'research' | translate }}</label>
                </div>
            </div>
            <small *ngIf="photoStep === 1" class="fileHelp form-text text-muted text-left">
                {{ 'profile_picture.file_max_weight' | translate }}
                /
                {{ 'profile_picture.file_authorized_formats' | translate }}
            </small>

            <div *ngIf="photoStep === 2" class="form-group photo-box">
                <div *ngIf="!uploading" class="crop-area">
                    <div *ngIf="!imageLoaded" class="loading pt-5">
                        <i class="fa fa-spinner fa-pulse fa-6x"></i>
                    </div>

                    <image-cropper
                            [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true"
                            [aspectRatio]="1"
                            format="png"
                            [imageQuality]="90"
                            [resizeToWidth]="320"
                            [resizeToHeight]="320"
                            [cropperMinHeight]="150"
                            [cropperMinWidth]="150"
                            (imageLoaded)="loadImage()"
                            (imageCropped)="imageCropped($event)"
                            (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div>
            </div>

            <div *ngIf="photoStep === 3" class="mx-auto text-center position-relative crop-photo">
                <img [src]="photoCropped" class="img-fluid border" alt="">
                <!--<a class="btn btn-dark mb-2 gauche"><i class="fas fa-angle-left"></i></a>-->
                <a (click)="setPhotoStep(1)" class="btn btn-dark mb-2 webcam">
                    <i class="fas fa-camera"></i>
                </a>
                <!--<a href="" class="btn btn-dark mb-2 droite"><i class="fas fa-angle-right"></i></a>-->

                <div *ngIf="uploading" class="loading pt-5">
                    <i class="fa fa-spinner fa-pulse fa-6x"></i>
                </div>
            </div>

            <div class="errorSpace" *ngIf="errorMessage || photoStep === 1">
                <small *ngIf="errorMessage" class="alert alert-danger pt-2">
                    {{errorMessage}}
                </small>
            </div>

        </div>

    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton() && photoStep === 1" (click)="previous()" data-toggle="modal" data-dismiss="modal"
       class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="setPhotoStep(1)" *ngIf="photoStep === 2 && !uploading" data-toggle="modal" data-dismiss="modal"
       class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="setPhotoStep(1)" *ngIf="photoStep === 3" data-toggle="modal" data-dismiss="modal"
       class="btn btn-dark btn-sm text-uppercase btn-back-edit-photo">
        <i class="fas fa-arrow-left"></i> {{ 'on_boarding.back_edit_photo' | translate }}
    </a>

    <button *ngIf="photoStep === 1 && !imageLoaded" (click)="completeLater()" data-toggle="modal" data-dismiss="modal"
        class="btn btn-dark btn-sm text-uppercase btn-complete-later">
        {{ 'on_boarding.complete_later' | translate }}
    </button>

    <button (click)="setPhotoStep(2)" *ngIf="photoStep === 1 && imageLoaded" data-toggle="modal" data-dismiss="modal"
            class="btn btn-primary btn-sm text-uppercase">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </button>

    <a (click)="acceptCrop()" *ngIf="photoStep === 2" data-toggle="modal" data-dismiss="modal"
       class="btn btn-primary btn-sm text-uppercase btn-validate-profile-picture">
        {{ 'on_boarding.validate_profile_picture' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
    <a (click)="accept()" *ngIf="photoStep === 3" data-toggle="modal" data-dismiss="modal"
       class="btn btn-primary btn-sm text-uppercase" [ngClass]="{'disabled': uploading}">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
