import {Component, OnInit} from '@angular/core';
import {SearchAppearanceService} from '../../../services/search-appearance/search-appearance.service';

@Component({
    selector: 'app-search-appearance-bar',
    templateUrl: './search-appearance-bar.component.html',
    styleUrls: ['./search-appearance-bar.component.scss']
})
export class SearchAppearanceBarComponent implements OnInit {

    constructor(
        private searchAppearanceService: SearchAppearanceService
    ) {
    }

    ngOnInit() {
    }

    hide() {
        this.searchAppearanceService.hideBar();
    }
}
