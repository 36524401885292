<app-modal (close)="confirm(false)">
    <div class="modal-body">
        <div class="container">
            <div class="text-center">
                <img class="img-fluid w-100 illu" [src]="camModel.getPhoto()">
                <br/>
                <div class="h1">{{ message | translate: getTranslationData() }}</div>
                <p class="infos w-100 text-center">
                    <strong>{{ question | translate: getTranslationData() }}</strong>
                    <br *ngIf="detail"/>
                    <span *ngIf="detail">{{ detail | translate: getTranslationData() }}</span>
                </p>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button (click)="confirm(false)" class="btn btn-dark btn-sm text-uppercase">
            {{ 'no' | translate }}
        </button>

        <button (click)="confirm(true)" class="btn btn-primary btn-sm text-uppercase">
            {{ 'yes' | translate }}
        </button>
    </div>
</app-modal>
