import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../services/login/login.service';
import {InitService} from '../../services/init/init.service';
import {ProfileService} from '../../services/profile/profile.service';
import {CurrentUserService} from '../../services/user/current-user.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../base/base.component';

@Component({
    selector: 'app-confirm-password',
    templateUrl: './confirm-password.component.html',
    styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent extends BaseComponent implements OnInit {

    result: boolean = null;
    error: string = null;

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private initService: InitService,
        private profileService: ProfileService,
        private userService: CurrentUserService,
        private translate: TranslateService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.checkPasswordCode();
    }

    checkPasswordCode() {
        const code = this.route.snapshot.paramMap.get('code');
        this.userService.checkPassword(code).subscribe((response: any) => {
            if (response.success && typeof response.data.valid === 'undefined') {
                this.result = true;
            } else if (response.success && !response.data.valid) {
                this.result = false;
                this.setError(response.message);
            } else {
                this.result = false;
                this.setError(response.error);
            }
        }, () => {
            this.result = false;
            this.error = this.translate.instant('error_list.default');
        });
    }

    private setError(message) {
        const errorIndex = 'error_list.' + message;
        let translatedError = this.translate.instant(errorIndex);
        if (translatedError === errorIndex) {
            translatedError = this.translate.instant('error_list.default');
        }
        this.error = translatedError;
    }
}
