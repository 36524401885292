<!-- step 3 -->
<div class="modal-body step-location">

    <div class="container text-center">
        <img alt="" class="img-fluid w-100 illu" src="assets/img/onboarding/localisation.png">
        <br>
        <h1>{{ 'on_boarding.localization' | translate }}</h1>
        <p class="infos w-100 text-center" [innerHTML]="getDescription()"></p>
        <div class="form-group mb-0">
            <div class="errorSpace mt-2">
                <app-city-typeahead [selectedCityObject]="selectedCityObject" (onUnselectCity)="onUnselectCity()" (onSelectCity)="onSelectCity($event)" [profile]="getProfile()" [marker]="true" [useLoader]="true" [useGeolocation]="true"></app-city-typeahead>

                <small class="alert alert-danger mt-2" *ngIf="errorMessage">
                    {{errorMessage}}
                </small>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer modal-mobile-footer">
    <a *ngIf="hasPreviousButton()" (click)="previous()" data-toggle="modal" data-dismiss="modal"  class="btn btn-dark btn-sm text-uppercase">
        <i class="fas fa-arrow-left"></i> {{ 'back' | translate }}
    </a>
    <a (click)="updateCity()" data-toggle="modal" data-dismiss="modal"  class="btn btn-primary btn-sm text-uppercase" [ngClass]="{'disabled':!isSelectedCity()}">
        {{ 'on_boarding.next_step' | translate }} <i class="fas fa-arrow-right"></i>
    </a>
</div>
