import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Photo} from '../../../../models/profile/photo';
import {Profile} from '../../../../models/profile/profile';
import {CurrentUserService} from '../../../../services/user/current-user.service';

@Component({
    selector: 'app-pick-on-album',
    templateUrl: './pick-on-album.component.html',
    styleUrls: ['./pick-on-album.component.scss']
})
export class PickOnAlbumComponent implements OnInit {

    @Input() albumType: number;
    @Output() photoSelectedEvent = new EventEmitter<Photo>();
    photos: Photo[] = [];
    profile: Profile;

    constructor(private currentUser: CurrentUserService) {
    }

    ngOnInit() {
        this.profile = this.currentUser.getAccount().getProfile();
        this.photos = Object.values(this.profile.getDefaultAlbumByType(this.albumType).getPhotos());
    }

    selectPhoto(photo: Photo) {
        this.photoSelectedEvent.emit(photo);
    }
}
