<div class="main" autoscroll="true">
    <div class="" id="container">
        <div class="row main profile">
            <div class="col-12 text-center mb-4">
                <div class="cover">
                    <app-card-component [profile]="account.getProfile()" [currentProfile]="true"></app-card-component>
                </div>

                <ul class="nav nav-tabs onglets-profils">
                    <li class="nav-item d-none d-xl-block">
                        <a (click)="setTab('settings')" [class.active]="tab === 'settings'" class="nav-link">{{ 'account.my_settings' | translate }}</a>
                    </li>
                    <li class="nav-item d-none d-xl-block">
                        <a (click)="setTab('purchase')" [class.active]="tab === 'purchase'" class="nav-link">{{ 'account.my_purchases' | translate }}</a>
                    </li>
                    <li *ngIf="account.activeSubscription() || account.getRuleSet().isSubscription()" class="nav-item d-none d-xl-block">
                        <a (click)="setTab('subscription')" [class.active]="tab === 'subscription'" class="nav-link">{{ 'account.my_subscription' | translate }}</a>
                    </li>
                    <li class="nav-item d-none d-xl-block">
                        <a (click)="setTab('account')" [class.active]="tab === 'account'" class="nav-link">{{ 'account.my_account.title' | translate }}</a>
                    </li>

                    <li class="nav-item px-1 d-block d-xl-none li-w-50">
                        <a (click)="setTab('settings')" [class.active]="tab === 'settings'" class="btn btn-light btn-sm d-inline-block w-100">{{ 'account.my_settings' | translate }}</a>
                    </li>
                    <li class="nav-item px-1 d-block d-xl-none li-w-50">
                        <a (click)="setTab('purchase')" [class.active]="tab === 'purchase'" class="btn btn-light off btn-sm d-inline-block w-100">{{ 'account.my_purchases' | translate }}</a>
                    </li>
                    <li *ngIf="account.activeSubscription() || account.getRuleSet().isSubscription()" class="nav-item px-1 d-block d-xl-none li-w-50">
                        <a (click)="setTab('subscription')" [class.active]="tab === 'subscription'" class="btn btn-light off btn-sm d-inline-block w-100">{{ 'account.my_subscription' | translate }}</a>
                    </li>
                    <li class="nav-item px-1 d-block d-xl-none li-w-50">
                        <a (click)="setTab('account')" [class.active]="tab === 'account'" class="btn btn-light off btn-sm d-inline-block w-100">{{ 'account.my_account.title' | translate }}</a>
                    </li>

                    <li *ngIf="!account.activeSubscription() && account.getRuleSet().isSubscription()" class="nav-item ml-auto px-1 li-compte-access">
                        <a [routerLink]="['/payment']" [queryParams]="{action: 'account'}" class="btn btn-success btn-sm btn-access d-none d-md-inline-block">
                            <i [matTooltip]="'search_appearance.activate_access' | translate" class="fas fa-crown"></i> {{ 'search_appearance.activate_access' | translate }}
                        </a>
                    </li>
                    <li *ngIf="!account.activeSubscription() && account.getRuleSet().isSubscription()" class="nav-item d-block d-md-none mt-2 w-100">
                        <a [routerLink]="['/payment']" [queryParams]="{action: 'account'}" class="btn btn-success btn-lg d-inline-block btn-access btn-block">
                            <i [matTooltip]="'search_appearance.activate_access' | translate" class="fas fa-crown"></i> {{ 'search_appearance.activate_access' | translate }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <app-setting *ngIf="tab === 'settings'" [account]="account"></app-setting>
                <app-purchase *ngIf="tab === 'purchase'" [account]="account"></app-purchase>
                <app-subscription *ngIf="tab === 'subscription'" [account]="account"></app-subscription>
                <app-my-account *ngIf="tab === 'account'" [account]="account"></app-my-account>
            </div>
        </div>
    </div>
</div>
