import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../app.config';
import {Profile} from '../../models/profile/profile';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import * as $ from 'jquery';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    private canvas = document.createElement('canvas');

    constructor(
        private translate: TranslateService,
        private sanitizer: DomSanitizer,
        @Inject(WINDOW) private window: Window
    ) {}

    getVideoSnapshotData(elementId): SafeResourceUrl | null {
        const video = document.getElementById(elementId) as HTMLVideoElement;
        if (!video) {
            return null;
        }

        this.canvas.width = video.videoWidth;
        this.canvas.height = video.videoHeight;
        const ctx = this.canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);

        return this.sanitizer.bypassSecurityTrustResourceUrl(this.canvas.toDataURL('image/png'));
    }

    translateGender(profile: Profile, translationIndex: string, reverseWithSearchedGender = false): string {
        const genderLabel = reverseWithSearchedGender ? profile.getSearchedGenderLabel() : profile.getGenderLabel();
        const genderTranslationIndex = translationIndex + '_' + genderLabel;
        const translation = this.translate.instant(genderTranslationIndex);

        if (translation === genderTranslationIndex) {
            return this.translate.instant(translationIndex);
        }

        return translation;
    }

    /**
     * Get parameter from URL
     * @param name
     */
    getUrlParam(name: string): string {
        const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(this.window.location.href);
        if (results == null) {
            return null;
        } else {
            return decodeURIComponent(results[1]) || null;
        }
    }

    /**
     * Create query string from key/value object
     * @param params
     */
    httpBuildQuery(params: object): string {
        const query = [];
        for (const name in params) {
            if (params.hasOwnProperty(name)) {
                if (Array.isArray(params[name])) {
                    for (const param of params[name]) {
                        query.push(encodeURIComponent(name) + '[]=' + encodeURIComponent(param));
                    }
                } else {
                    query.push(encodeURIComponent(name) + '=' + encodeURIComponent(params[name]));
                }
            }
        }

        return query.join('&');
    }
}
