<div class="navigateur">
    <div class="container-fluid contenu page-contenu mt-2">

        <div class="row no-gutters">

            <div class="col-12">
                <div class="card border-0 box mb-4 p-0 ">
                    <div class="card-body">
                        <i class="fas fa-exclamation-triangle text-warning big text-center"></i>
                        <h1 class="mb-3 text-center">{{ 'navigator.browser_out_of_date' | translate }}</h1>
                        <hr class="hr-title">
                        <p class="text-center">
                            {{ 'navigator.service_cannot_used_under_conditions_of_browser' | translate }}
                        </p>
                        <p class="lead font-weight-bold text-center mb-0">
                            {{ 'navigator.your_browser_is_not_up_to_date' | translate }}
                        </p>

                        <div class="text-center mt-2">
                            <a class="btn btn-primary" (click)="ignoreOutOfDateBrowser()">{{ 'navigator.ignore_browser_message' | translate }}</a>
                        </div>

                        <div align="center"><h2>{{ 'navigator.please_download_browser' | translate }}</h2></div>

                        <div class="row engagements">

                            <div class="col-sm-6 col-lg-3 text-center mb-4">
                                <a href="https://www.microsoft.com/fr-fr/edge" target="_blank">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <img src="assets/img/navigator/edge.png" alt="" class="browser">
                                            <h3>{{ 'navigator.edge' | translate }}</h3>
                                            <p>{{ 'navigator.edge_provider' | translate }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-sm-6 col-lg-3 text-center mb-4">
                                <a href="https://www.mozilla.org/firefox/new" target="_blank">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <img src="assets/img/navigator/firefox.png" alt="" class="browser">
                                            <h3>{{ 'navigator.firefox' | translate }}</h3>
                                            <p>{{ 'navigator.firefox_provider' | translate }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-sm-6 col-lg-3 text-center mb-4">
                                <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <img src="assets/img/navigator/chrome.png" alt="" class="browser">
                                            <h3>{{ 'navigator.chrome' | translate }}</h3>
                                            <p>{{ 'navigator.chrome_provider' | translate }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-sm-6 col-lg-3 text-center mb-4">
                                <a href="https://www.opera.com/fr" target="_blank">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <img src="assets/img/navigator/opera.png" alt="" class="browser">
                                            <h3>{{ 'navigator.opera' | translate }}</h3>
                                            <p>{{ 'navigator.opera_provider' | translate }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>

                        <div align="center"><h2>{{ 'navigator.why_up_to_date_browser_so_important' | translate }}</h2></div>

                        <div class="row engagements">

                            <div class="col-lg-6 text-center mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <div class="corner">
                                            <i class="fas fa-check text-success"></i>
                                        </div>
                                        <i class="fas fa-lock text-primary big"></i>
                                        <h3>{{ 'navigator.security' | translate }}</h3>
                                        <p>{{ 'navigator.security_description' | translate }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 text-center mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <div class="corner">
                                            <i class="fas fa-check text-success"></i>
                                        </div>
                                        <i class="fas fa-tachometer-alt text-primary big"></i>
                                        <h3>{{ 'navigator.quickly' | translate }}</h3>
                                        <p>{{ 'navigator.quickly_description' | translate }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 text-center mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <div class="corner">
                                            <i class="fas fa-check text-success"></i>
                                        </div>
                                        <i class="fas fa-cogs text-primary big"></i>
                                        <h3>{{ 'navigator.technology' | translate }}</h3>
                                        <p>{{ 'navigator.technology_description' | translate }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 text-center mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <div class="corner">
                                            <i class="fas fa-check text-success"></i>
                                        </div>
                                        <i class="fas fa-grin-hearts text-primary big"></i>
                                        <h3>{{ 'navigator.experience' | translate }}</h3>
                                        <p>{{ 'navigator.experience_description' | translate }}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</div>