import {AbstractNotificationType} from './abstract-notification-type';
import {NotificationButton} from '../models/notification/notification-button';

export class ActivatedSpeedDatingNotificationType extends AbstractNotificationType {

    static readonly TYPE = 36;

    protected buttons:NotificationButton[] = [
        new NotificationButton('participate', () => {
            this.goTo('speed-dating');
        })
    ];

    getIcon(): string {
        return 'fa-stopwatch';
    }
}
