import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-live-layout',
    templateUrl: './live-layout.component.html',
    styleUrls: ['./live-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LiveLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
