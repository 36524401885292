import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelperService} from '../helper/helper.service';
import {CamModel} from '../../models/cam-model/cam-model';
import {SafeResourceUrl} from '@angular/platform-browser';

declare function Connector(): any;
declare function stopVideo(): any;

@Injectable({
    providedIn: 'root'
})
export class SexCamsService {

    constructor(
        private http: HttpClient,
        private helperService: HelperService
    ) {}

    private static CONSTANT_LIST: any = null;
    public static readonly SEX_CAM_PER_PAGE = 30;
    private connectionStatus = false;
    private fetchCollectionType = CamModel.FREE_SHOW;

    onSubmitSubject = new BehaviorSubject(false);
    onSubmitObservable = this.onSubmitSubject.asObservable();

    currentCamModel: CamModel;
    camModels: CamModel[] = null;
    lastVideoFrame: SafeResourceUrl = null;
    showFilters = false;
    hideLoader = false;
    chatMessage = '';

    startCamShow() {
        if (this.connectionStatus) {
            Connector().enterVisitor();
        } else {
            this.connectionStatus = true;
            Connector().connect();
        }
    }

    stopCamShow(keepModel = false) {
        if (!keepModel) {
            this.currentCamModel = null;
        }
        stopVideo();
        Connector().closeConnection();
        this.connectionStatus = false;
    }

    addToFavorite(camModel: CamModel) {
        this.http.post(environment.backendUrl + '/live/favorite', {account: camModel.getId()}).subscribe();
    }

    removeFromFavorite(camModel: CamModel) {
        this.http.delete(environment.backendUrl + '/live/favorite/' + camModel.getId()).subscribe();
    }

    getConstantList(index: string): string[] {
        return SexCamsService.CONSTANT_LIST[index];
    }

    findNext(camModel: CamModel): CamModel {
        if (!camModel) {
            return this.camModels[0];
        }
        const index = this.camModels.findIndex((data: CamModel) => data.getId() === camModel.getId());
        const nextCamModel = this.camModels[index + 1];

        // Eventually, fetch more models if we reach end of list, but there is only a limited number of models anyway.
        return nextCamModel ? nextCamModel : this.camModels[0];
    }

    fetchAll(count: number, filters: any): Promise<number> {
        return new Promise(resolve => {
            if (this.camModels === null) {
                this.fetchCollectionType = CamModel.FREE_SHOW;
                this.hideLoader = false;
            }

            const useChatTypeFilter = (filters.chatTypes && filters.chatTypes.length);
            if (!useChatTypeFilter) {
                if (this.fetchCollectionType === CamModel.OFFLINE_STATUS) {
                    filters.online = 0;
                } else {
                    filters.chatTypes = [this.fetchCollectionType];
                }
            }
            const queryParams = `page=1&count=${count}&${this.helperService.httpBuildQuery(filters)}`;

            this.http.get(`${environment.backendUrl}/live/model?${queryParams}`).subscribe((result: any) => {
                if (this.camModels === null) {
                    this.camModels = [];
                }
                let numberFetched = 0;
                for (const data of result.data) {
                    const index = this.camModels.findIndex((camModel: CamModel) => camModel.account === data.account);
                    if (index === -1) {
                        this.camModels.push(new CamModel().deserialize(data));
                        ++numberFetched;
                    } else {
                        this.camModels[index].deserialize(data);
                    }
                }
                const missingModels = count - result.data.length;
                const notEnoughCamModel = missingModels > 0;
                if (!useChatTypeFilter && notEnoughCamModel) {
                    switch (this.fetchCollectionType) {
                        case CamModel.FREE_SHOW:
                            this.fetchCollectionType = CamModel.VIP_SHOW;
                            break;
                        case CamModel.VIP_SHOW:
                            this.fetchCollectionType = CamModel.PRIVATE_SHOW;
                            break;
                        case CamModel.PRIVATE_SHOW:
                            this.fetchCollectionType = CamModel.OFFLINE_STATUS;
                            break;
                        case CamModel.OFFLINE_STATUS:
                            this.hideLoader = true;
                            resolve(0);
                    }
                }
                this.hideLoader = useChatTypeFilter && notEnoughCamModel;
                resolve((!useChatTypeFilter && notEnoughCamModel) ? missingModels : 0);
            });
        });
    }

    refreshModel(filters: any): Promise<any> {
        return new Promise<any>(resolve => {
            if (!this.camModels || !this.camModels.length) {
                resolve(false);
                return;
            }

            filters.accounts = [];
            for (const model of this.camModels) {
                filters.accounts.push(model.getId());
            }
            const queryParams = this.helperService.httpBuildQuery(filters);
            this.http.get(`${environment.backendUrl}/live/model/refresh?${queryParams}`).subscribe((result: any) => {
                for (const data of result.data.models) {
                    if (!this.camModels) {
                        break;
                    }
                    const index = this.camModels.findIndex((camModel: CamModel) => camModel.account === data.account);
                    if (index > -1) {
                        this.camModels[index].deserialize(data);
                    }
                }

                resolve(result.data.count);
            }, () => {
                console.log('Error 500: could not refresh models.');
            });
        });
    }

    countAll(filters: any): Observable<any> {
        return this.http.get(`${environment.backendUrl}/live/model/count?${this.helperService.httpBuildQuery(filters)}`);
    }

    fetchByAccount(account: string): Observable<any> {
        return this.http.get(environment.backendUrl + '/live/model/' + account);
    }

    init() {
        this.http.get(environment.backendUrl + '/live/static').subscribe((result: any) => {
            SexCamsService.CONSTANT_LIST = result.data;
        });
    }

    onSubmitChange(value) {
        this.onSubmitSubject.next(value);
    }

    refreshHash(): Observable<any> {
        return this.http.post(environment.backendUrl + '/live/refresh-visitor-hash', {});
    }
}
