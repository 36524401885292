<div class="container-fluid cover-upsell px-0">
    <div class="alert alert-success text-center mb-0">
        <h1 class="mb-0">
            <br>
            <br>
        </h1>
    </div>
</div>

<app-unlover-layout>
    <div class="col mx-auto text-center">
        <div class="card border-0 rounded shadow-lg mt-4 mb-5 upsell-xp-okko">
            <i class="far fa-times-circle text-danger fleche-upsell" aria-hidden="true"></i>
            <div class="card-body px-4 pb-4 px-md-5 pb-md-5 pt-4">
                <h3 class="mb-0"><span class="text-danger d-none d-md-inline-block">
Oups...<br>
Impossible d'activer votre accès 😥<br></span>

                    <span class="text-danger d-inline-block d-md-none">
Oups...<br>
Accès non activé ! 😥 </span><br><br>

                    Impossible d'activer votre accès gratuit...<br>
                    Ce n'est pas grave, une prochaine fois peut-être !<br>
                </h3>
                <br>
                <a (click)="redirectToHome()"
                   class="btn btn-lg rounded-pill py-3 btn-success btn-block shadow-sm text-uppercase font-weight-bold"><i
                    class="fas fa-hand-point-right mr-2" aria-hidden="true"></i> <span class="d-none d-sm-inline-block">Retour au service</span><span
                    class="d-inline-block d-sm-none">Retour</span></a>
            </div>
        </div>
    </div>
</app-unlover-layout>
